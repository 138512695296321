import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { ILocation } from '../../../components/common/location/interfaces'
import { getStore } from '../../../store/configureStore'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
}

export const assembleLocation = (props: Props): ILocation => {
  const store = props.store || getStore().getState()
  const location = oc(store).location[props.id]()

  if (location) {
    const contactIds = location.contactIds || (location.primaryContactId ? [location.primaryContactId] : [])
    const contacts = contactIds.length ? contactIds.map(id => store.contact[id]).filter(Boolean) : []

    return {
      ...location,
      contacts,
      contactIds
    }
  }

  return null
}
