import { callAPI, dispatchDeliveryOrderAPI } from '../../../api/api'
import { leaveChatChannelIfNoEditing } from './leaveChatChannelIfNoEditing'
import { generateDispatchDeliveryOrderChatChannel } from './index'
import { ChatTab } from '../../../store/reducers/communicationHub/interfaces'
import { getDispatch } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'

export const openCommunicationHubChannelByDDOId = async (dispatchDeliveryOrderId: string, chatTab?: ChatTab) => {
  const dispatchDeliveryOrder = await callAPI(
    dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders,
    `id%%${dispatchDeliveryOrderId}`
  )
    .toPromise()
    .then(data => data[0])
    .catch(() => null)

  if (!dispatchDeliveryOrder) {
    return
  }

  leaveChatChannelIfNoEditing(() =>
    getDispatch()(
      communicationHubActions.pushActiveChannel({
        channel: generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder),
        options: { chatTab }
      })
    )
  )
}
