import * as R from 'remeda'
import { oc } from 'ts-optchain'
import {
  ActivitiesViewDTO,
  DeliveryOrderViewDTO,
  DocumentationActivityDTO,
  LocationNameDTO,
  OngoingActivityGroupDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { getStore } from '../../../store/configureStore'
import { getSchedulerTabs } from '../../../components/common/tabs/selectors'
import { filterUsefulTransportationActivities } from './index'
import * as activityActions from '../../../components/common/activity/action'
import { deleteTabViewingObject } from '../../viewingObjects/actions'
import { TransportationActivityGroup } from './interfaces'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { getTemplateTypes } from './controller'
import { WebsocketEvent } from '../../websocket/interfaces'
import { filterLatestObjects } from '../../websocket/functions'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { tryAddActivitiesToStore } from '../../../components/common/activity/epics'
import { requestDispatchDeliveryOrdersByIds } from '../../../components/common/dispatchDeliveryOrder/epics'
import { listsActions } from '../../../store/reducers/lists'
import { getCommunicationHubState, getDispatch } from '../../../store'
import { getListOfUsedIdsOnSpecificDate } from '../../../components/common/scheduler/epics'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { communicationHubActions } from '../../../store/reducers/communicationHub'
import { ListEntityType } from '../../../store/reducers/lists/interfaces'
import { isUnsuccessfulActivityGroup } from '../../functions/test/isUnsuccessfulActivity'

export const getNeededActivityTypesByStages = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  strict?: boolean
): Record<TransportationActivityViewDTO.StageEnum, TransportationActivityViewDTO.TypeEnum[]> => {
  const deliveryOrderType = oc(dispatchDeliveryOrder).deliveryOrder.type()
  const getStageDocumentationActivityGroups = (stage: TransportationActivityViewDTO.StageEnum) => {
    return oc(dispatchDeliveryOrder)
      .activityGroups([])
      .filter(
        group =>
          'documentationActivity' in group &&
          group.documentationActivity.stage === stage &&
          (group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.SUBMITTED ||
            group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.APPROVED)
      )
  }
  const getStageTransportationActivityGroups = (stage: TransportationActivityViewDTO.StageEnum) => {
    return oc(dispatchDeliveryOrder)
      .activityGroups([])
      .filter(
        group =>
          'gotoActivity' in group &&
          'businessActivity' in group &&
          group.gotoActivity.stage === stage &&
          !isUnsuccessfulActivityGroup(group) &&
          oc(group).gotoActivity.destination.type() !== LocationNameDTO.TypeEnum.CONTAINERYARD
      )
  }

  const stages = [
    TransportationActivityViewDTO.StageEnum.PICKUP,
    TransportationActivityViewDTO.StageEnum.DELIVERY,
    TransportationActivityViewDTO.StageEnum.RETURN
  ]

  return stages.reduce((acc, stage) => {
    acc[stage] = ((): TransportationActivityViewDTO.TypeEnum[] => {
      if (getStageDocumentationActivityGroups(stage).length) {
        return []
      }

      const stageTransportationActivityGroups = getStageTransportationActivityGroups(stage)

      if (!strict) {
        if (
          (stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
            deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION) ||
          stageTransportationActivityGroups.length === 0
        ) {
          return []
        }
      }

      const requiredStageTypes = getTemplateTypes({
        deliveryOrderType,
        loadType: oc(dispatchDeliveryOrder).loadType(),
        stage
      })

      let dispatchDeliveryOrderLocationId: string = undefined

      switch (stage) {
        case TransportationActivityViewDTO.StageEnum.PICKUP:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.DELIVERY:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
          break
        case TransportationActivityViewDTO.StageEnum.RETURN:
          dispatchDeliveryOrderLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
          break
        default:
      }

      return requiredStageTypes.filter(
        stageTemplateType =>
          !stageTransportationActivityGroups.some(
            group =>
              'businessActivity' in group &&
              group.businessActivity.template &&
              group.businessActivity.type === stageTemplateType &&
              group.businessActivity.destinationId === dispatchDeliveryOrderLocationId
          )
      )
    })()

    return acc
  }, {})
}

export const isInProcessOrCompletedGroup = (activityGroup: TransportationActivityGroup): boolean => {
  return (
    !isUnsuccessfulActivityGroup(activityGroup) &&
    (oc(activityGroup).gotoActivity.status() === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
      oc(activityGroup).businessActivity.status() === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
      oc(activityGroup).gotoActivity.status() === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
      oc(activityGroup).businessActivity.status() === TransportationActivityViewDTO.StatusEnum.COMPLETED)
  )
}

export const activityListCollector = () => {
  let ddoIds: string[] = []
  let ddoIdsOfUpdatedActivityDeliveryStage: string[] = []
  let removeActivities: string[] = []
  let forceUpdateActivities: ActivitiesViewDTO = {
    transportationActivities: [],
    documentationActivities: []
  }
  let timer: any

  return (data: ActivityViewDTO | string, eventType: string) => {
    const activity = typeof data === 'string' ? undefined : data
    const activityId = typeof data === 'string' ? data : undefined

    switch (eventType) {
      case WebsocketEvent.DELETED: {
        const store = getStore().getState()
        const storeActivity =
          store.activity.transportationActivities[activityId] || store.activity.documentationActivities[activityId]

        if (storeActivity) {
          ddoIds.push(storeActivity.dispatchDeliveryOrderId)
          removeActivities.push(activityId)
        }
        break
      }
      case WebsocketEvent.UPDATED_FORCE: {
        const store = getStore().getState()

        const storeTransportationActivity = store.activity.transportationActivities[activity.id]
        const storeDocumentationActivity = store.activity.documentationActivities[activity.id]

        if (storeTransportationActivity) {
          forceUpdateActivities.transportationActivities.push({
            ...(activity as TransportationActivityViewDTO),
            forceUpdate: true
          } as TransportationActivityViewDTO)
        }
        if (storeDocumentationActivity) {
          forceUpdateActivities.documentationActivities.push({
            ...(activity as DocumentationActivityDTO),
            forceUpdate: true
          } as DocumentationActivityDTO)
        }

        break
      }
      default:
        ddoIds.push(activity.dispatchDeliveryOrderId)

        if (activity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
          ddoIdsOfUpdatedActivityDeliveryStage.push(activity.dispatchDeliveryOrderId)
        }
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      const { dispatch, getState } = getStore()
      const store = getState()
      const { schedulerTabData } = getCommunicationHubState()
      ddoIds = R.uniq(ddoIds)
      ddoIdsOfUpdatedActivityDeliveryStage = R.uniq(ddoIdsOfUpdatedActivityDeliveryStage)

      if (
        forceUpdateActivities.transportationActivities.length ||
        forceUpdateActivities.documentationActivities.length
      ) {
        tryAddActivitiesToStore(forceUpdateActivities)
      }

      if (
        ddoIdsOfUpdatedActivityDeliveryStage.length &&
        schedulerTabData &&
        ddoIdsOfUpdatedActivityDeliveryStage.some(id => schedulerTabData.ongingDispatchDeliveryOrderIds.includes(id))
      ) {
        getListOfUsedIdsOnSpecificDate(schedulerTabData.specificDate).then(activeDeliveryStageVendorIds => {
          if (schedulerTabData === oc(getCommunicationHubState()).schedulerTabData()) {
            communicationHubActions.updateActiveRouteBuilderSchedulerTabData({ activeDeliveryStageVendorIds })
          }
        })
      }

      const usedDDOIds = ddoIds.filter(id => {
        const storeDdo = store.dispatchDeliveryOrder[id]
        return storeDdo && storeDdo.fullObject
      })

      const activitiesToDelete = [...removeActivities]
      if (usedDDOIds.length) {
        requestDispatchDeliveryOrdersByIds(usedDDOIds).then(() => {
          if (activitiesToDelete.length) {
            dispatch(activityActions.removeIds(activitiesToDelete))
          }
        })
      }

      ddoIds = []
      ddoIdsOfUpdatedActivityDeliveryStage = []
      removeActivities = []
      forceUpdateActivities = {
        transportationActivities: [],
        documentationActivities: []
      }
    }, 3000)
  }
}

export const ongoingActivityGroupsCollector = () => {
  let deletedGroupActivitiesIds: string[] = []
  let putGroupActivities: OngoingActivityGroupDTO[] = []
  let timer: any

  return (activityRow: OngoingActivityGroupDTO | string, eventType: string) => {
    switch (eventType) {
      case WebsocketEvent.DELETED: {
        const activityRowId = activityRow as string
        deletedGroupActivitiesIds.push(activityRowId)
        putGroupActivities = putGroupActivities.filter(({ id }) => id !== activityRowId)
        break
      }
      case WebsocketEvent.UPDATED_FORCE: {
        putGroupActivities.push({
          ...(activityRow as OngoingActivityGroupDTO),
          forceUpdate: true
        } as OngoingActivityGroupDTO)

        break
      }
      default: {
        putGroupActivities.push(activityRow as OngoingActivityGroupDTO)
      }
    }

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      const filteredLatestObjects = filterLatestObjects(putGroupActivities)
      updateSchedulerTabs({ deletedGroupActivitiesIds, putGroupActivities: filteredLatestObjects })

      deletedGroupActivitiesIds = []
      putGroupActivities = []
    }, 3000)
  }
}

const updateSchedulerTabs = ({
  deletedGroupActivitiesIds,
  putGroupActivities
}: {
  deletedGroupActivitiesIds: string[]
  putGroupActivities: OngoingActivityGroupDTO[]
}) => {
  if (!deletedGroupActivitiesIds.length && !putGroupActivities.length) {
    return
  }

  const { getState, dispatch } = getStore()
  const updateIds = R.uniq(putGroupActivities.map((_: any) => _.id))
  const idsToClose = deletedGroupActivitiesIds.filter(id => !updateIds.includes(id))
  const ongoingActivityDateStage: OngoingActivityGroupDTO[] = []
  const ongoingActivityDateStageNow: OngoingActivityGroupDTO[] = []
  const ongoingActivityDateDriver: OngoingActivityGroupDTO[] = []

  putGroupActivities.forEach(item => {
    if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE) {
      ongoingActivityDateStage.push(item)
    } else if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGENOW) {
      ongoingActivityDateStageNow.push(item)
    } else if (item.grouping === OngoingActivityGroupDTO.GroupingEnum.DRIVER) {
      ongoingActivityDateDriver.push(item)
    }
  })

  getDispatch()(
    listsActions.update({
      update: {
        [ListEntityType.ongoingActivityDateStage]: ongoingActivityDateStage,
        [ListEntityType.ongoingActivityDateStageNow]: ongoingActivityDateStageNow,
        [ListEntityType.ongoingActivityDateDriver]: ongoingActivityDateDriver
      },
      delete: {
        [ListEntityType.ongoingActivityDateStage]: deletedGroupActivitiesIds,
        [ListEntityType.ongoingActivityDateStageNow]: deletedGroupActivitiesIds,
        [ListEntityType.ongoingActivityDateDriver]: deletedGroupActivitiesIds
      }
    })
  )

  getSchedulerTabs(getState()).map(tab => {
    const viewingObjectId = oc(getState()).viewingObjects[tab.id].id()

    if (viewingObjectId && idsToClose.includes(viewingObjectId)) {
      dispatch(deleteTabViewingObject({ tabId: tab.id }))
    }
  })
}

export type TAssignedDrivers = { vendorId: string; authUserId: string }[]

export const getAssignedDrivers = (
  previousTransportationActivities: TransportationActivityViewDTO[],
  updatedTransportationActivities: TransportationActivityViewDTO[]
): TAssignedDrivers => {
  if (!previousTransportationActivities.length || !updatedTransportationActivities.length) {
    return []
  }

  return filterUsefulTransportationActivities(updatedTransportationActivities)
    .filter(activity => {
      return isGotoActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
    })
    .filter(activity => {
      const prevActivityState = previousTransportationActivities.find(_ => _.id === activity.id)

      if (!prevActivityState) {
        // is new activity
        return true
      }

      if (activity.vendorId && oc(prevActivityState).vendorId() !== activity.vendorId) {
        // driver changed
        return true
      }

      // prev activity status wasn't DRIVERASSIGNED but now it is
      return oc(prevActivityState).status() !== TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
    })
    .reduce((acc, activity) => {
      if (acc.some(({ vendorId }) => vendorId === activity.vendorId)) {
        return acc
      }

      acc.push({ vendorId: activity.vendorId, authUserId: activity.vendor.authUserId })
      return acc
    }, [])
}
