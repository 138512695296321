import { ILocation } from '../../../components/common/location/interfaces'
import { oc } from 'ts-optchain'
import { LocationDetailsDto } from '../../../api/origin/location'
import { LocationDTO } from '../../../api/origin/business-logic'
import { getStore } from '../../../store/configureStore'
import { getStateByCodes } from '../../addressService'

export const combineLocations = (databaseLocation: ILocation, googleLocation: LocationDetailsDto) => ({
  ...databaseLocation,
  address: {
    ...databaseLocation.address,
    postalCode: oc(googleLocation).address.postalCode(null),
    street: oc(googleLocation).address.street(null),
    street2: oc(googleLocation).address.street2(null),
    stateId: getStateByCodes({
      stateCode: oc(googleLocation).address.stateCode(null),
      countryCode: oc(googleLocation).address.countryCode(null)
    }).id,
    city: oc(googleLocation).address.city(null)
  },
  latitude: oc(googleLocation).latitude(null),
  longitude: oc(googleLocation).longitude(null),
  placeId: oc(googleLocation).placeId(null)
})

export const makeLocationTitle = (location: ILocation | LocationDTO) => {
  if (!location) {
    return ''
  }

  const { fullObject, name, shortName, code, address } = location
  const { city, stateId, postalCode } = address || {}
  const stateCode = stateId ? oc(getStore().getState().state)[stateId].code('') : ''

  const result = [fullObject ? code || name : shortName, city, stateCode + ' ' + (postalCode || '')]
    .filter(Boolean)
    .join(', ')

  return result.length > 1 ? result : ''
}

export const makeLocationLabel = (location: ILocation | LocationDTO, isShortName?: boolean): string => {
  if (!location) {
    return ''
  }

  if (isShortName) {
    return location.fullObject
      ? location.code || location.name || makeLocationTitle(location)
      : location.shortName || location.longName || location.name
  } else {
    return location.fullObject
      ? makeLocationTitle(location)
      : [location.shortName, location.cityStateZip].filter(Boolean).join(', ')
  }
}
