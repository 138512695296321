import { oc } from 'ts-optchain'
import { IDriver } from '../../../components/common/drivers/interfaces'
import { DriverViewDTO, PowerUnitSearchDTO } from '../../../api/origin/business-logic'
import { schemaDriverView } from '../../yupScheme'
import { debuggingMode } from '../../debug'
import { DeductionDTO, DeductionStatus, DeductionType } from '../../../api/origin/vendor-accounting-service'
import { isWeeklyDeduction } from '../../functions/test/isWeeklyDeduction'
import { isDeductionAmountValid } from '../../functions/test/isDeductionAmountValid'
import { testVendorAccounting } from '../../functions/test/testVendorAccounting'
import { isAddressValid } from '../../functions/test/isAddressValid'
import { isDriverFieldRequired } from '../../functions/test/isDriverFieldRequired'

export const transformPowerUnit = (value: PowerUnitSearchDTO | ''): string => {
  return value
    ? `${value.licensePlate ? value.licensePlate + ',' : ''}${value.year ? ' ' + value.year : ''}${
        value.make ? ' ' + value.make : ''
      }${value.model ? ' ' + value.model : ''}`
    : ''
}

export const checkDriverValidation = (driver: IDriver): boolean => {
  const requiredFields = isDriverFieldRequired(driver)
  const isDriver =
    driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR ||
    driver.driverType === DriverViewDTO.DriverTypeEnum.COMPANY
  const isCompanyFilled = Boolean(driver.parentId)
  const driverType = driver.driverType
  const context = {
    driverType,
    isDriver,
    isCompanyFilled
  }

  if (debuggingMode.common) {
    schemaDriverView.validate(driver, { context }).catch(e =>
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkDriverValidation', e)
    )
  }

  const testDeduction = (deduction: DeductionDTO): boolean => {
    if (isWeeklyDeduction(deduction)) {
      return true
    }

    switch (deduction.status) {
      case DeductionStatus.NEW:
      case DeductionStatus.READY:
        return isDeductionAmountValid(deduction)
      default: {
        if (deduction.type === DeductionType.ESCROW) {
          return (
            deduction.startDate &&
            deduction.type &&
            typeof deduction.principle === 'number' &&
            typeof deduction.deductionAmount === 'number'
          )
        }

        return (
          deduction.startDate &&
          deduction.type &&
          deduction.checkType &&
          deduction.checkNumber &&
          typeof deduction.principle === 'number' &&
          typeof deduction.interest === 'number' &&
          typeof deduction.deductionAmount === 'number' &&
          isDeductionAmountValid(deduction)
        )
      }
    }
  }

  return (
    schemaDriverView.isValidSync(driver, { context }) &&
    (driver.licenseAddress ? isAddressValid(driver.licenseAddress) : !requiredFields.licenseAddress) &&
    (driver.mailingAddress ? isAddressValid(driver.mailingAddress) : isDriver) &&
    (oc(driver).temporaryData.updatedVendorAccounting() && isDriverFieldRequired(driver).bankingDetails
      ? testVendorAccounting(driver.temporaryData.updatedVendorAccounting)
      : true) &&
    oc(driver)
      .temporaryData.updatedDeductions([])
      .every(testDeduction)
  )
}
