import { oc } from 'ts-optchain'
import { createAddressLine } from '../../../../services/addressService'
import { ISubClient } from '../interfaces'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { getPrimaryContactNameFormContacts } from '../../../../services/DTO/contact'

export const headers = {
  Name: { width: 160, active: true },
  Address: { width: 260, active: true },
  Customer: { width: 200, active: true },
  'Primary Contact': { width: 160, active: true }
}

export const adaptRowValues = (subClient: ISubClient): TColumns => {
  const columnValues = {
    Name: oc(subClient).businessPartner.legalName(''),
    Address: createAddressLine(oc(subClient).businessPartner.legalAddress()),
    Customer: oc(subClient).customer.businessPartner.legalName(''),
    'Primary Contact': getPrimaryContactNameFormContacts(oc(subClient).businessPartner.contacts([]))
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
