import { oc } from 'ts-optchain'
import { IPowerUnit } from '../interfaces'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { getStateInfo } from '../../../../services/addressService'
import { powerUnitDirectory } from '../../../../services/DTO/powerUnit/directory'
import { getDateFromISOString } from '../../../../services/timeService/dateUtils'
import { DriverNameDTO } from '../../../../api/api'

export const headers = {
  'Power Unit (Truck) #': { width: 160, active: true },
  'Active / Availability': { width: 260, active: true },
  Drivers: { width: 260, active: true },
  Type: { width: 180, active: true },
  Year: { width: 160, active: true },
  Make: { width: 160, active: true },
  Model: { width: 160, active: true },
  GVW: { width: 160, active: true },
  Ownership: { width: 160, active: true },
  'Port Pass RFID': { width: 160, active: true },
  'License Plate #': { width: 160, active: true },
  'Registration State': { width: 160, active: true },
  'Registration Date': { width: 160, active: true },
  'Registration Exp. Date': { width: 160, active: true },
  'Registration GVW': {
    width: 160,
    active: true
  },
  'Registration Restrictions': {
    width: 170,
    active: true
  },
  'DOT Safety Inspection Date': { width: 200, active: true },
  'DOT Safety Inspection Annual Exp. Date': {
    width: 210,
    active: true
  },
  'Maintenance Inspection Frequency': {
    width: 160,
    active: true
  },
  'Insurance Type': {
    width: 160,
    active: true
  },
  'Insurance Carrier': { width: 160, active: true },
  'Insurance Exp. Date': { width: 160, active: true },
  'Insurance Policy #': { width: 160, active: true }
}

export const adaptRowValues = (unit: IPowerUnit): TColumns => {
  const columnValues = {
    'Power Unit (Truck) #': unit.number,
    'Active / Availability': unit,
    Drivers: getDriversLine(oc(unit).drivers([])),
    Type: unit.powerUnitType,
    Year: unit.year || '',
    Make: unit.make || '',
    Model: unit.model || '',
    GVW: unit.weight && unit.weightUnit ? unit.weight + ' ' + unit.weightUnit : '',
    Ownership: unit.ownership ? powerUnitDirectory.ownership[unit.ownership] : '',
    'Port Pass RFID': unit.portPassRfid ? unit.rfid || 'Yes' : 'No',
    'License Plate #': oc(unit).licensePlate(''),
    'Registration State': getStateInfo(unit.registration.stateId).name,
    'Registration Date': getDateFromISOString(unit.registration.registeredDate),
    'Registration Exp. Date': getDateFromISOString(unit.registration.expirationDate),
    'Registration GVW':
      unit.registration.weight === null ? '' : unit.registration.weight + ' ' + unit.registration.weightUnit,
    'Registration Restrictions': oc(unit).registration.restrictions()
      ? powerUnitDirectory.restrictions[unit.registration.restrictions]
      : '',
    'DOT Safety Inspection Date': getDateFromISOString(unit.dotSafetyInspection.performedDate),
    'DOT Safety Inspection Annual Exp. Date': getDateFromISOString(unit.dotSafetyInspection.expirationDate),
    'Maintenance Inspection Frequency': unit.maintenanceInspectionFrequency
      ? powerUnitDirectory.maintenanceInspectionFrequency[unit.maintenanceInspectionFrequency]
      : '',
    'Insurance Type': oc(unit).insurance.insuranceType()
      ? powerUnitDirectory.insuranceType[unit.insurance.insuranceType]
      : '',
    'Insurance Carrier': oc(unit).insurance.carrier(''),
    'Insurance Exp. Date': getDateFromISOString(unit.insurance.expirationDate),
    'Insurance Policy #': oc(unit).insurance.policyNumber('')
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}

const getDriversLine = (drivers: DriverNameDTO[]) =>
  drivers
    .map(driver => oc(driver).name())
    .filter(Boolean)
    .join(', ')
