import { oc } from 'ts-optchain'
import { IStore } from '../../../store/store.interface'
import { IDeliveryOrder } from '../../../components/common/deliveryOrder/interfaces'
import { assembleDTO } from './index'
import { getStore } from '../../../store/configureStore'
import { getNewStoreState, RootState } from '../../../store'

type Props = {
  id: string
  store?: IStore
  newStore?: RootState
  simplify?: boolean
}

export const assembleDeliveryOrder = (props: Props): IDeliveryOrder => {
  const store = props.store || getStore().getState()
  const newStore = props.newStore || getNewStoreState()
  const deliveryOrder = oc(store).deliveryOrder[props.id]()

  return deliveryOrder
    ? {
        ...deliveryOrder,
        customer: assembleDTO.customer({ store, id: deliveryOrder.customerId }) || deliveryOrder.customer,
        pickupLocation:
          assembleDTO.location({ store, id: deliveryOrder.pickupLocationId }) || deliveryOrder.pickupLocation,
        deliveryLocation:
          assembleDTO.location({ store, id: deliveryOrder.deliveryLocationId }) || deliveryOrder.deliveryLocation,
        returnLocation:
          assembleDTO.location({ store, id: deliveryOrder.returnLocationId }) || deliveryOrder.returnLocation,
        steamShipLine:
          assembleDTO.steamShipLine({ store, id: deliveryOrder.steamShipLineId }) || deliveryOrder.steamShipLine,
        dispatchDeliveryOrders: props.simplify
          ? []
          : oc(deliveryOrder)
              .dispatchDeliveryOrderIds([])
              .map(id =>
                assembleDTO.dispatchDeliveryOrder({ store, newStore, id, clearDeliveryOrder: true, simplify: true })
              )
              .filter(Boolean)
      }
    : null
}
