import { ColumnTitle } from '../../grid/views/interfaces'
import { IFilterItem } from '../../grid/views/header/column'

export enum TabType {
  scheduler = 'scheduler',
  powerUnit = 'powerUnit',
  equipment = 'equipment',
  container = 'container',
  location = 'location',
  driver = 'driver',
  customer = 'customer',
  steamShipLine = 'steamShipLine',
  subClient = 'subClient',
  surcharge = 'surcharge',
  users = 'users',
  dispatchDeliveryOrder = 'dispatchDeliveryOrder',
  deliveryOrder = 'deliveryOrder',
  QMP = 'QMP'
}

export enum ListRequestType {
  streetTurn = 'streetTurn',
  droppedAtDeliveryLocation = 'droppedAtDeliveryLocation',
  deliveryStageCompletedNoReturned = 'deliveryStageCompletedNoReturned',
  droppedAtYard = 'droppedAtYard',
  pickedUpNoDelivery = 'pickedUpNoDelivery',
  recentDispatchDeliverOrders = 'recentDispatchDeliverOrders'
}

export enum TabActionType {
  streetTurn = 'streetTurn'
}

export interface ITabState {
  id: string
  title: string
  spinner?: boolean
  type: TabType
  storeTab: boolean
  visited: boolean
  actionsType?: TabActionType
  uiSettings: ITabSettings
  gridItemIds?: string[]
  alwaysDoListRequestOnTabEnter?: boolean
  detailsRequestType?: TabType | ListRequestType
  listRequestType?: ListRequestType
  listRequestProps?: any
  enableEditing?: boolean
  enableSorting?: boolean
  enableFiltering?: boolean
  ignoreWS?: boolean
  data?: TTabData
}

export type TTabData = {
  selectedIds?: string[]
  pinnedIds?: string[]
  disabledSelectIds?: string[]
  hideUnselectedGridItems?: string[]
} & any

export type TGridColumns = Record<string, ColumnTitle>

export interface ITabSettings {
  columns: TGridColumns
  filter?: IFilterItem[]
  sorting?: any
}

export interface ITabStateOptions {
  callback: TabType
  name: string
  icon: string
}

export interface ITabsStoreState {
  activeMainTabId: string
  main: ITabState[]
  admin: ITabState[]
}
