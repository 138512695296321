export namespace ModalWindow {
  export enum Type {
    SUCCESS = 'SUCCESS',
    NOTIFICATION = 'NOTIFICATION',
    WARNING = 'WARNING',
    ALERT = 'ALERT'
  }

  export type Button = {
    label: string | JSX.Element
    disabled?: boolean
    outline?: true
    style?: React.CSSProperties
    onClick?: () => void
  }
}
export interface IModalWindow {
  title: string | JSX.Element
  type?: ModalWindow.Type
  hasMaxHeight?: boolean
  content?: string | JSX.Element
  buttons?: ModalWindow.Button[]
  closeButton?: boolean
  width?: number
  styleTopSetion?: React.CSSProperties
}
