import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { StateTypeState } from './type'
import { businessLogicApiActions } from '../../api/businessLogicApi'

const initialState: StateTypeState = {}

export const stateReducer = reducerWithInitialState<StateTypeState>(initialState)
  .case(businessLogicApiActions.stateFindAllUsingGetAction.done, (store, { result }) =>
    result.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {})
  )
  .build()
