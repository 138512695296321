import { oc } from 'ts-optchain'
import { getStore } from '../../../store/configureStore'
import { IMove, IMoveDTO } from '../../../store/reducers/communicationHub/interfaces'
import { isDDOStatusCancelled } from './isDDOStatusCancelled'

export const isMoveDisabled = (move: IMove | IMoveDTO): boolean => {
  const dispatchDeliveryOrder = getStore().getState().dispatchDeliveryOrder[move.dispatchDeliveryOrderId]
  const disable = isDDOStatusCancelled(oc(dispatchDeliveryOrder).status()) || move._isNextDayMove || move._isPrevDayMove

  return disable
}
