import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { connect } from 'react-redux'
import { MessagesButton } from '../OngoingActivityRow/MessagesButton'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { IDispatchDeliveryOrder } from '../../../../../dispatchDeliveryOrder/interfaces'
import { IStore } from '../../../../../../../store/store.interface'
import { CommunicationHubConstants } from '../../../../../../../constants/CommunicationHubConstants'
import { MoveRowActivity } from './MoveRowActivity'
import { MoveRowSpecs, Specs } from './MoveRowSpecs'
import { MoveRowQuoteColumn } from './MoveRowQuoteColumn'
import { setTabViewingObject } from '../../../../../../../services/viewingObjects/actions'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../../../../services/debug'
import { getDispatchDeliveryOrderById } from '../../../../../dispatchDeliveryOrder/epics'
import { dispatchDeliveryOrderStatusIndex } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../../api/api'
import { isDispatchDeliveryOrderGoodForMoves } from '../../../../functions/isDispatchDeliveryOrderGoodForMoves'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectRouteBuilderIncludesMoveId } from '../../../../../../../store/select/routeBuilderSelect'
import { CommunicationHubTab, IMoveDTO } from '../../../../../../../store/reducers/communicationHub/interfaces'

type OwnProps = {
  currentTabId: string
  move: IMoveDTO
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

type DispatchProps = {
  setTabViewingObject: typeof setTabViewingObject
}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { currentTabId, move, dispatchDeliveryOrder } = props
  const presentsInRouteBuilder = useAppSelector(selectRouteBuilderIncludesMoveId(move.id))

  React.useEffect(() => {
    if (move && !dispatchDeliveryOrder) {
      getDispatchDeliveryOrderById(move.dispatchDeliveryOrderId)
    }
  }, [])

  if (!(move && dispatchDeliveryOrder)) {
    return null
  }

  const dispatchDeliveryOrderStatusIsWorkable =
    dispatchDeliveryOrderStatusIndex[dispatchDeliveryOrder.status] >
    dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]

  const openDetails = (event: any) => {
    // >>> Debugging
    if (isShowDetailsOnClick(event)) {
      showDetailsOnClick({
        title: 'Move',
        data: move,
        blink: { class: 'move', event }
      })
      return
    }
    // <<<

    props.setTabViewingObject({
      tabId: currentTabId,
      viewingObject: {
        id: move.dispatchDeliveryOrderId,
        data: {
          moveId: move.id
        }
      }
    })
  }

  return (
    <Container className={cn('move', { presentsInRouteBuilder })} onClick={openDetails}>
      <MessagesButton
        dispatchDeliveryOrderId={move.dispatchDeliveryOrderId}
        hasAlerts={Boolean(dispatchDeliveryOrder.troubleTicketId)}
      />
      <MoveRowActivities className={'move-activities'}>
        {move.items.map(moveItem => (
          <MoveRowActivity
            key={moveItem.id}
            moveDay={move.plannedDate}
            moveItem={moveItem}
            dispatchDeliveryOrderStatusIsWorkable={dispatchDeliveryOrderStatusIsWorkable}
            isConfirmedAppointmentDate={oc(
              dispatchDeliveryOrder
            ).deliveryStage.plannedAppointmentDateTimeRange.confirmed()}
            pickupStageShuttle={oc(dispatchDeliveryOrder).pickupStage.shuttle(false)}
            returnStageShuttle={oc(dispatchDeliveryOrder).returnStage.shuttle(false)}
          />
        ))}
      </MoveRowActivities>
      <Quotes>
        {move.items.map(moveItem => (
          <MoveRowQuoteColumn
            key={moveItem.id}
            gotoActivityId={moveItem.gotoActivityId}
            businessActivityId={moveItem.businessActivityId}
          />
        ))}
      </Quotes>
      <MoveRowSpecs dispatchDeliveryOrder={dispatchDeliveryOrder} />
    </Container>
  )
}

export const MoveRow = connect(
  (store: IStore, { move }: OwnProps) => {
    const dispatchDeliveryOrder = move && store.dispatchDeliveryOrder[move.dispatchDeliveryOrderId]

    return {
      dispatchDeliveryOrder: isDispatchDeliveryOrderGoodForMoves(dispatchDeliveryOrder)
        ? dispatchDeliveryOrder
        : undefined
    }
  },
  { setTabViewingObject }
)(React.memo(Component))

const {
  mediaQueriesBreakpoints: { large, medium, small }
} = SchedulerConstants
const { navigationViewWidth, channelViewWidth } = CommunicationHubConstants

const MoveRowActivities = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 1fr;
`
const Quotes = styled.div`
  order: 1;
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  border: none;
  border-radius: 2px;
  outline: 1px solid #dce6f5;
  overflow: hidden;
`
const Container = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 35px 2fr 3fr 80px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 6px;
  cursor: pointer;

  &:has(.move-activities:empty) {
    display: none;
  }

  &.presentsInRouteBuilder {
    box-shadow: 0 0 0 1px rgb(227, 232, 234), 0 0 0 3px #5cd8ff;
  }

  .communicationHubNotExpanded & {
    // prettier-ignore
    @media screen 
    and (max-width: ${medium + navigationViewWidth}px) {
      grid-template-columns: 35px 1fr 80px;

      .messagesButton {
        grid-row: 1/3;
      }

      ${Quotes} {
        order: unset;
      }

      ${Specs} {
        order: 1;
        grid-column: 2/4;
      }
    }
  }
  
  .communicationHubExpanded & {
    // prettier-ignore
    @media screen 
    and (max-width: ${medium + navigationViewWidth + channelViewWidth}px) {
      grid-template-columns: 35px 1fr 80px;

      .messagesButton {
        grid-row: 1/3;
      }

      ${Quotes} {
        order: unset;
      }

      ${Specs} {
        order: 1;
        grid-column: 2/4;
      }
    }
  }
`
