import { ICustomerView } from '../../../components/common/customer/interfaces'
import { schemaCustomerView, schemaCustomer } from '../../yupScheme'
import { checkContactsValidation } from '../contact'
import { debuggingMode } from '../../debug'
import { oc } from 'ts-optchain'

export const checkCustomerValidation = (customer: ICustomerView, notDeep?: boolean): boolean => {
  const schema = notDeep ? schemaCustomer : schemaCustomerView

  if (debuggingMode.common) {
    schema.validate(customer).catch(e => {
      //   getStore().dispatch(
      //     addServerMessage({
      //       type: 'error',
      //       message: e.message
      //     })
      //   )
      // tslint:disable-next-line:no-console
      console.log('checkCustomerValidation', e)
    })
  }
  return schema.isValidSync(customer) && checkContactsValidation(oc(customer).businessPartner.contacts([]))
}
