import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { ISubClient } from '../../../interfaces'
import { StyledGeneral } from './styledComponents'
import { CustomerInfo } from './CustomerInfo'
import PrimaryContact from '../../../../contact/views/PrimaryContact'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'
import { ContactDTO } from '../../../../../../api/api'

export const General: React.SFC<{
  actions: IGridItemActions
  subClient: ISubClient
}> = ({ subClient, actions }) => {
  if (!oc(subClient).businessPartner.contacts()) {
    return null
  }

  const contacts = subClient.businessPartner.contacts
  const primaryContact = contacts ? contacts.find(item => item.primary) : null

  return (
    <StyledGeneral minRows={70}>
      <Container minRows={38} margin={{ top: 25, left: 60, right: 60 }}>
        <CustomerInfo subClient={subClient} actions={actions} />
        <PrimaryContact
          columns={3}
          contacts={contacts}
          primaryContact={primaryContact}
          modifyContacts={(updatedContacts: ContactDTO[]) =>
            actions.modifyParentObjectField('businessPartner')({
              ...subClient.businessPartner,
              contacts: updatedContacts
            })
          }
        />
      </Container>
    </StyledGeneral>
  )
}
