import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { getBusinessPartnerSavePromise } from '../businessPartner/save'
import { getSteamShipLineStatus } from './status'
import { makeObjectSavePromise, tryToSave, makeSavePromiseWithCatch } from '../saveDTO'
import { ISteamShipLine } from '../../../components/common/steamShipLine/interfaces'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { steamShipLineAPI } from '../../../api/api'
import * as R from 'remeda'
import { parseDTO } from '../parseDTO'

export const getSteamShipLinePromise = (
  steamShipLine: ISteamShipLine,
  actions?: IModifyGridItemActions
): Promise<any> => {
  const { needToSave } = getSteamShipLineStatus(steamShipLine)

  return getBusinessPartnerSavePromise({ ...steamShipLine.businessPartner, legalAddress: null }).then(
    (businessPartner: BusinessPartner) => {
      const result = {
        ...steamShipLine,
        businessPartnerId: businessPartner.id,
        containerDetentionPerDiemFeeSchedule: steamShipLine.containerDetentionPerDiemFeeSchedule.map(item =>
          R.omit(item, ['id'])
        )
      }
      return makeObjectSavePromise(needToSave, result, actions, steamShipLineAPI, parseDTO.steamShipLine)
    }
  )
}

export const saveSteamShipLine = (steamShipLine: ISteamShipLine, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getSteamShipLinePromise(steamShipLine, actions))

  return tryToSave({
    condition: getSteamShipLineStatus(steamShipLine).noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    cancel: () => {},
    hideSpinner: () => actions.setFetching(false),
    discardChanges: () => actions.cancel()
  })
}
