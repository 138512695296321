import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import Input from '../../../../../UI/Input'

const extraStyles = {
  fontSize: 14,
  userSelect: 'none'
}

const General: React.SFC<{
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  actions: IGridItemActions
}> = ({ dispatchDeliveryOrder, actions }) => (
  <DispatchDeliveryOrderTabContent style={extraStyles as any}>
    <Container rows={38} margin={{ top: 25, left: 60, right: 60 }}>
      <Input
        isTextArea={true}
        value={dispatchDeliveryOrder.description}
        onChange={actions.modifyParentObjectField('description')}
      />
    </Container>
  </DispatchDeliveryOrderTabContent>
)

export default General
