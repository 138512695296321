import { BasicTableColumn } from '../../../../../../../BasicTable/interfaces'

export const applicableRatesTableHeaderProps: BasicTableColumn[] = [
  { key: 'ID', value: 'ID', visible: true, styles: { flex: '1 1 auto', width: 90, textAlign: 'center' } },
  { key: 'Activity', value: 'Activity', visible: true, styles: { flex: '1 1 auto', width: 135 } },
  { key: 'Surcharges', value: 'Surcharges', visible: true, styles: { flex: '1 1 auto', width: 105 } },
  { key: 'Type', value: 'Type', visible: true, styles: { flex: '1 1 auto', width: 45 } },
  {
    key: 'Calculation Type',
    value: 'Calculation Type',
    visible: true,
    styles: { flex: '1 1 auto', width: 275 }
  },
  { key: 'Rule', value: 'Rule', visible: true, styles: { flex: '1 1 auto', width: 100, height: '100%' } },
  { key: 'Effective Date', value: 'Effective Date', visible: true, styles: { flex: '1 1 auto', width: 90 } },
  { key: 'Expiration Date', value: 'Expiration Date', visible: true, styles: { flex: '1 1 auto', width: 90 } },
  {
    key: 'Amount',
    value: 'Amount',
    visible: true,
    styles: { flex: '1 1 auto', width: 100, textAlign: 'right', paddingRight: 10 }
  }
]

export const additionalSurchargesTableHeaderProps: BasicTableColumn[] = [
  { key: 'Activity', value: null, visible: true, styles: { flex: '1 1 auto', width: 180 } },
  { key: 'Calculation Type', value: null, visible: true, styles: { flex: '1 1 auto', width: 275, maxWidth: 320 } },
  { key: 'Description', value: null, visible: true, styles: { flex: '1 1 auto', width: 305 } },
  { key: 'Amount', value: null, visible: true, styles: { flex: '0 0 auto', width: 120 } },
  { key: 'Actions', value: null, visible: true, styles: { flex: '0 0 auto', width: 42, padding: '0 10px' } }
]
