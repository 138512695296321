import * as React from 'react'
import { ListRequestType, TabActionType, TabType, TTabData } from '../components/common/tabs/interfaces'

export type TGridContext = {
  tabId: string
  enableEditing: boolean
  tabData: TTabData
  listRequestProps: any
  tabType: TabType
  ignoreWS: boolean
  actionsType: TabActionType
  listRequestType: ListRequestType
  detailsRequestType: TabType | ListRequestType
  selectedIds: string[]
  pinnedIds: string[]
  disabledSelectIds: string[]
  fetchGridItems(withoutSpinner?: boolean): Promise<any>
  setGridFetching(state: boolean): void
}

export const GridContext: React.Context<TGridContext> = React.createContext({
  tabId: null,
  enableEditing: null,
  tabData: null,
  listRequestProps: null,
  detailsRequestType: null,
  tabType: null,
  ignoreWS: null,
  actionsType: null,
  selectedIds: null,
  pinnedIds: null,
  disabledSelectIds: null,
  listRequestType: null,
  fetchGridItems: null,
  setGridFetching: null
})
