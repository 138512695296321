import { IEquipment } from '../../../components/common/equipment/interfaces'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { tryToSave, makeObjectSavePromise, makeSavePromiseWithCatch } from '../saveDTO'
import { getEquipmentStatus } from './status'
import { equipmentAPI } from '../../../api/api'
import * as R from 'remeda'
import { isNewId } from '../index'
import { tryAddEquipmentToStore } from '../../../components/common/equipment/epics'

export const getEquipmentSavePromise = (
  equipment: IEquipment,
  actions?: IModifyGridItemActions
): Promise<IEquipment> => {
  const { needToSave } = getEquipmentStatus(equipment)

  const transformedObjectForSaving = {
    ...equipment,
    maintenanceInspections: equipment.maintenanceInspections
      ? equipment.maintenanceInspections.map(_ => (isNewId(_.id) ? R.omit(_, ['id']) : _))
      : [],
    repairs: equipment.repairs.map(_ => (isNewId(_.id) ? R.omit(_, ['id']) : _))
  }

  return makeObjectSavePromise(
    needToSave,
    transformedObjectForSaving,
    actions,
    equipmentAPI,
    tryAddEquipmentToStore
  ) as Promise<IEquipment>
}

export const saveEquipment = (equipment: IEquipment, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getEquipmentSavePromise(equipment, actions))

  return tryToSave({
    condition: getEquipmentStatus(equipment).noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
