import * as React from 'react'
import styled from 'styled-components'
import { StyledColumnHeader } from '../../../styles'
import cn from 'classnames'
import { transformMoney } from '../../../../../../../Input'

type Props = {
  quoteType: string
  profit?: number
  total: number
}
export const Header = ({ quoteType, profit, total }: Props) => (
  <StyledColumnHeader>
    <div>
      {profit !== null && profit !== undefined && (
        <>
          <Title>PROFIT</Title>
          {'  '}
          <ProfitAmount className={cn({ red: profit < 0, green: profit > 0 })}>{transformMoney(profit)}</ProfitAmount>
        </>
      )}
    </div>
    <div>
      <>
        <Title>{quoteType}</Title>
        {'  '}
        <span>Total</span>
        {'  '}
        {total === null || total === undefined ? (
          <Title>–</Title>
        ) : (
          <Title className={cn({ red: total < 0, green: total > 0 })}>{transformMoney(total)}</Title>
        )}
      </>
    </div>
  </StyledColumnHeader>
)

const Title = styled.span`
  font-weight: 500;
  letter-spacing: 0.75px;
  color: #445366;
`
const ProfitAmount = styled.span`
  font-weight: 500;
  color: #d8d8d8;

  &.red {
    color: #ff8181;
  }
  &.green {
    color: #27ae60;
  }
`
