import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { IContainer } from '../../../components/common/containers/interfaces'
import { makeObjectSavePromise, tryToSave, makeSavePromiseWithCatch } from '../saveDTO'
import { getContainerStatus } from './status'
import { containerAPI } from '../../../api/api'
import { parseDTO } from '../parseDTO'
import { oc } from 'ts-optchain'

export const getContainerSavePromise = (
  container: IContainer,
  actions?: IModifyGridItemActions
): Promise<IContainer> => {
  const { needToSave } = getContainerStatus(container)

  return makeObjectSavePromise(needToSave, container, actions, containerAPI, parseDTO.container) as Promise<IContainer>
}

export const saveContainer = (container: IContainer, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getContainerSavePromise(container, actions))

  const containerStateBeforeModifying = oc(actions).initialObjectState() as IContainer

  const noStoreDataForUpdate =
    !containerStateBeforeModifying ||
    !Object.keys(getContainerStatus(containerStateBeforeModifying).getDifference).length

  return tryToSave({
    condition: noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
