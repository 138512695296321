import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { UserAvatar, UserAvatarSize } from './UserAvatar'
import { UserDTO, UserType } from '../../../api/origin/user-service'
import theme from '../../../styles/theme'
import { preventEvent } from '../../../services/functions/basic'
import { DriverViewDTO } from '../../../api/origin/business-logic'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectContact } from '../../../store/select/contactSelect'
import { selectDriver } from '../../../store/select/driverSelect'

const EntityTypeMapping = {
  [UserType.OPERATOR]: 'Dispatch',
  [UserType.DRIVER]: 'Driver',
  [UserType.SERVICE]: 'Service'
}

type OwnProps = {
  userAuthOrVendorId: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const UserDetails = React.memo((props: Props) => {
  const { userAuthOrVendorId } = props
  const driver = useAppSelector(selectDriver(userAuthOrVendorId))
  const contact = useAppSelector(selectContact(userAuthOrVendorId))
  const contactByDriver = useAppSelector(selectContact(driver ? driver.authUserId : undefined))
  const user = contact || contactByDriver

  if (!user) {
    return null
  }

  return (
    <Container onClick={preventEvent}>
      <Popup>
        <Information>
          <UserAvatar user={user} size={UserAvatarSize.large} />
          <UserName>{oc(driver).name() || oc(user).name() || 'User Name'}</UserName>
          {user && user.type && <UserData>{EntityTypeMapping[user.type]}</UserData>}
          {user && user.phoneNumber && <UserData className={'mdi mdi-phone-message'}>+1{user.phoneNumber}</UserData>}
          {user && user.email && <UserData className={'mdi mdi-email'}>{user.email}</UserData>}
        </Information>
      </Popup>
    </Container>
  )
})

const Container = styled.div``
const Popup = styled.div`
  width: 268px;
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 15px;
`
const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 10px;
`
const UserName = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 18px;
`
const UserData = styled.div`
  min-height: 14px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 6px;

  &:before {
    font-size: 13px;
    margin-right: 5px;
  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 24px 0 10px;
  user-select: none;
`
const Action = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #323e4e;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
