import { ILocation } from '../../../components/common/location/interfaces'
import { LocationDTO } from '../../../api/origin/business-logic'
import { isLocationValid } from '../../functions/test/isLocationValid'

export const locationTypeIcons = {
  [LocationDTO.TypeEnum.TERMINAL]: 'mdi mdi-switch',
  [LocationDTO.TypeEnum.WAREHOUSEWITHDOCK]: 'mdi mdi-garage',
  [LocationDTO.TypeEnum.WAREHOUSEWITHOUTDOCK]: 'mdi mdi-city',
  [LocationDTO.TypeEnum.STOREFRONT]: 'mdi mdi-store',
  [LocationDTO.TypeEnum.RESIDENTIAL]: 'mdi mdi-account-box',
  [LocationDTO.TypeEnum.CONTAINERYARD]: 'mdi mdi-window-closed-variant',
  // [LocationDTO.TypeEnum.GENERIC]: 'mdi mdi-map-marker',
  [LocationDTO.TypeEnum.DEPOT]: 'mdi mdi-mailbox',
  [LocationDTO.TypeEnum.OTHER]: 'mdi mdi-map-marker-question'
}

export const checkLocationValidation = (location: ILocation): boolean => {
  return isLocationValid(location)
}
