import * as React from 'react'
import { IGridItemActions } from '../../../../contexts/GridItemContext'
import { TabType } from '../../../common/tabs/interfaces'

type TPreviewTab = {
  isModified: boolean
  tabs: Record<string, any>
  activeTab: string
  unit: any
  isUnitValid: boolean
  unitType: TabType
  actions: IGridItemActions
}

export const PreviewTab = ({ isModified, tabs, activeTab, unit, unitType, actions }: TPreviewTab) => {
  return tabs[activeTab](unit, actions, isModified)
}
