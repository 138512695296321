import * as React from 'react'
import styled from 'styled-components'
import { BasicTableBody } from './interfaces'
import { Row } from './Row'

export const Body = ({ rows, styles }: BasicTableBody) => <StyledBody style={styles}>{rows.map(Row)}</StyledBody>

const StyledBody = styled.div`
  flex-grow: 1;
  box-shadow: 0 0 0 1px #e5e5e5;
  border-radius: 2px;
`
