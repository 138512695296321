import * as React from 'react'
import { connect } from 'react-redux'
import {
  createNewTab,
  duplicateTab,
  getTabsFromSessionStorage,
  moveTab,
  removeAllTabsExcept,
  removeRightTabs,
  removeTab,
  setActiveTabId,
  updateTab,
  updateVisibleTabsNumber
} from './actions'
import { ITabState, TabType } from './interfaces'
import { getTabs } from './selectors'
import { StyledTabs, TabsList, WrapperSortTabs } from './styledComponents'
import Tab from './Tab'
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd } from 'react-sortable-hoc'
import { ApplicationContext, IStore } from '../../../store/store.interface'
import AddNewTab from './addNewTab'
import { createDefaultTab, tabTypes } from '../../../services/uiSettingsService/tabs'
import { showModal, TMsgType } from '../../UI/Modal/actions'
import { tabSessionStorage } from '../../../services/tabs/functions'
import { defaultTabData } from '../../../services/DTO/defaultTabData'
import { getStore } from '../../../store/configureStore'
import { getViewingObjectFromSessionStorage, deleteTabsViewingObject } from '../../../services/viewingObjects/actions'
import ContextMenu from '../../UI/ContextMenu'

const allTabTypes = tabTypes.allTypes()

type StateProps = {
  activeMainTabId: string
  applicationContext: ApplicationContext
  tabs: ITabState[]
}

type DispatchProps = {
  createNewTab: typeof createNewTab
  moveTab: typeof moveTab
  updateVisibleTabsNumber: typeof updateVisibleTabsNumber
  showModal: typeof showModal
  removeAllTabsExcept: typeof removeAllTabsExcept
  removeTab: typeof removeTab
  duplicateTab: typeof duplicateTab
  setActiveTabId: typeof setActiveTabId
  removeRightTabs: typeof removeRightTabs
  updateTab: typeof updateTab
  getTabsFromSessionStorage: typeof getTabsFromSessionStorage
  getViewingObjectFromSessionStorage: typeof getViewingObjectFromSessionStorage
  deleteTabsViewingObject: typeof deleteTabsViewingObject
}

type Props = StateProps & DispatchProps

export const Tabs = connect(
  (store: IStore): StateProps => ({
    applicationContext: store.applicationContext,
    tabs: getTabs(store),
    activeMainTabId: store.tabs.activeMainTabId
  }),
  {
    updateVisibleTabsNumber,
    moveTab,
    createNewTab,
    showModal,
    removeAllTabsExcept,
    removeTab,
    duplicateTab,
    setActiveTabId,
    removeRightTabs,
    updateTab,
    getTabsFromSessionStorage,
    getViewingObjectFromSessionStorage,
    deleteTabsViewingObject
  }
)((props: Props) => {
  const { applicationContext, tabs, activeMainTabId } = props
  const [contextMenu, setContaxtMenu] = React.useState<{
    tab: ITabState
    position: { top: number; left: number }
  }>(null)

  React.useEffect(() => {
    const { scrollPosition, viewingObjects } = tabSessionStorage
    const applicationTabsData = tabSessionStorage.tabs.get()
    const tabScrollPosition = scrollPosition.get()
    const applicationViewingObjects = viewingObjects.get()

    if (!applicationTabsData) {
      const mainTabs = [createDefaultTab(TabType.dispatchDeliveryOrder)]
      const adminTabs = [createDefaultTab(TabType.users)]
      tabSessionStorage.tabs.set({
        activeMainTabId: mainTabs[0].id,
        main: mainTabs,
        admin: adminTabs,
        version: Date.now()
      })
    } else if (!applicationTabsData.main.length) {
      const mainTabs = [createDefaultTab(TabType.dispatchDeliveryOrder)]
      tabSessionStorage.tabs.set({ ...applicationTabsData, activeMainTabId: mainTabs[0].id, main: mainTabs })
    } else if (!applicationTabsData.admin.length) {
      tabSessionStorage.tabs.set({ ...applicationTabsData, admin: [createDefaultTab(TabType.users)] })
    } else if (!applicationTabsData.main.some(tab => tab.id === applicationTabsData.activeMainTabId)) {
      tabSessionStorage.tabs.set({ ...applicationTabsData, activeMainTabId: applicationTabsData.main[0].id })
    }

    if (!applicationViewingObjects) {
      viewingObjects.set({})
    }

    if (!tabScrollPosition) {
      // Session Storage: create tab's scroll position
      scrollPosition.set({})
    }

    // put Settings from Session Storage to Redux Store
    props.getViewingObjectFromSessionStorage(viewingObjects.get())
    props.getTabsFromSessionStorage({
      settings: tabSessionStorage.tabs.get(),
      userId: null
    })
  }, [])

  const addNewTab = (tabType: TabType) => props.createNewTab({ tabType, data: defaultTabData()[tabType] })

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const currentTab = props.tabs[oldIndex]
    const insertBeforeTab = props.tabs[newIndex]
    props.moveTab({
      currentTab,
      insertBeforeTab,
      context: props.applicationContext
    })
  }

  const handleTabClick = ({ id }: ITabState) => () => {
    props.setActiveTabId({ tabId: id })
  }

  const tabActions = {
    remove: (tab: ITabState) => () => {
      if (tabs.length === 1) {
        props.showModal({
          msgType: TMsgType.info,
          message: "Let's not remove this one for now",
          onConfirm: () => {},
          onCancel: () => {}
        })
        return
      }

      if (tab.type === TabType.deliveryOrder && getStore().getState().viewingObjects[tab.id]) {
        props.showModal({
          msgType: TMsgType.delete,
          message: 'Are you sure you want to close the "New Delivery Order" tab? All changes will be lost',
          onCancel: () => {},
          onConfirm: () => {
            props.removeTab({
              context: applicationContext,
              id: tab.id
            })
            props.deleteTabsViewingObject({ tabIds: [tab.id] })
          }
        })
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message:
          'Do you really want to remove tab "' + tab.title.substr(0, 25) + (tab.title.length > 25 ? '...' : '') + '"?',
        onConfirm: () => {
          props.removeTab({
            context: applicationContext,
            id: tab.id
          })
          props.deleteTabsViewingObject({ tabIds: [tab.id] })
        },
        onCancel: () => {}
      })
    },
    removeOtherTabs: (tab: ITabState) => () => {
      if (tabs.length === 1) {
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message:
          'Do you really want to remove other tabs besides "' +
          tab.title.substr(0, 25) +
          (tab.title.length > 25 ? '...' : '') +
          '"?',
        onConfirm: () => {
          props.removeAllTabsExcept({
            tab: tab,
            context: applicationContext
          })
          props.deleteTabsViewingObject({ tabIds: tabs.filter(_ => _.id !== tab.id).map(_ => _.id) })
        },
        onCancel: () => {}
      })
    },
    removeRightTabs: (tab: ITabState) => () => {
      if (tabs.length === 1) {
        return
      }

      props.showModal({
        msgType: TMsgType.delete,
        message: 'Do you really want to close all tabs to the right?',
        onConfirm: () => {
          props.removeRightTabs({
            tab: tab,
            context: applicationContext
          })

          const store = getStore().getState()
          const viewingObjectTabIs = Object.keys(store.viewingObjects)
          const activeTabIds = store.tabs.main.map(({ id }) => id).concat(store.tabs.admin.map(({ id }) => id))
          const removedTabIds = viewingObjectTabIs.filter(id => !activeTabIds.includes(id))

          if (removedTabIds.length) {
            props.deleteTabsViewingObject({ tabIds: removedTabIds })
          }
        },
        onCancel: () => {}
      })
    },
    duplicate: (tab: ITabState) => () => props.duplicateTab({ originalTab: tab, context: props.applicationContext }),
    save: (updatedTab: ITabState) =>
      props.updateTab({
        tab: updatedTab,
        context: props.applicationContext
      })
  }

  const SortableItem = SortableElement(({ children }: any) => children)

  const SortableList: React.ComponentClass<{ items: ITabState[] } & SortableContainerProps> = SortableContainer(
    ({ items }: { items: ITabState[] } & SortableContainerProps) => (
      <WrapperSortTabs className={'tabs'}>
        {items.map((tab, index) => {
          const { icon } = allTabTypes.find(i => i.callback === tab.type)
          const isLast = index === items.length - 1
          const isSingle = items.length === 1

          return (
            <SortableItem key={index} index={index}>
              <div style={{ flexShrink: 1, width: 200 }}>
                <Tab
                  key={tab.id}
                  isActive={activeMainTabId === tab.id}
                  tab={tab}
                  icon={icon}
                  removeTab={tabActions.remove(tab)}
                  handleTabClick={handleTabClick(tab)}
                  saveTab={tabActions.save}
                  setContaxtMenu={setContaxtMenu}
                />
                {Boolean(contextMenu) && contextMenu.tab.id === tab.id && (
                  <ContextMenu
                    position={contextMenu && contextMenu.position}
                    closeContextMenu={() => setContaxtMenu(null)}
                    actions={(() => {
                      const actions = {
                        duplicate: { title: 'Duplicate', callToAction: tabActions.duplicate(tab), separator: true },
                        closeTab: isSingle
                          ? undefined
                          : { title: 'Close Tab', callToAction: tabActions.remove(tab), separator: false },
                        closeOtherTabs: isSingle
                          ? undefined
                          : {
                              title: 'Close Other Tabs',
                              callToAction: tabActions.removeOtherTabs(tab),
                              separator: false
                            },
                        closeTabsToTheRight:
                          isSingle || isLast
                            ? undefined
                            : {
                                title: 'Close Tabs to the Right',
                                callToAction: tabActions.removeRightTabs(tab),
                                separator: false
                              }
                      }
                      const tabContextActions = []

                      switch (tab.type) {
                        case TabType.deliveryOrder:
                          tabContextActions.push(actions.closeTab, actions.closeOtherTabs, actions.closeTabsToTheRight)
                          break
                        default:
                          tabContextActions.push(
                            actions.duplicate,
                            actions.closeTab,
                            actions.closeOtherTabs,
                            actions.closeTabsToTheRight
                          )
                      }

                      return tabContextActions.filter(Boolean)
                    })()}
                  />
                )}
              </div>
            </SortableItem>
          )
        })}
      </WrapperSortTabs>
    )
  )

  return (
    <StyledTabs>
      <TabsList>
        <AddNewTab tabTypes={tabTypes[applicationContext]} selectTabType={addNewTab} />

        <SortableList
          items={tabs}
          onSortEnd={onSortEnd}
          axis={'x'}
          lockAxis={'x'}
          lockToContainerEdges={true}
          distance={10}
          helperClass={'draggable-tab'}
        />
      </TabsList>
    </StyledTabs>
  )
})
