import { driverLists } from '../../select/driverLists'
import { DriverColumn } from '../../../components/common/drivers/views/configGrid'
import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'

export const driverFilterSettings = {
  [DriverColumn['Employee #']]: {
    name: 'number',
    type: GridHeaderColumnType.number,
    disabled: true
  },
  [DriverColumn['Active / Availability']]: {
    name: 'status',
    type: GridHeaderColumnType.multiSelectList
  },
  [DriverColumn.Name]: {
    name: 'name',
    type: GridHeaderColumnType.string
  },
  [DriverColumn.Vendor]: {
    name: 'parent.name',
    type: GridHeaderColumnType.string
  },
  [DriverColumn.MobileApp]: {
    name: 'mobileApp',
    type: GridHeaderColumnType.list,
    items: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]
  },
  [DriverColumn['Operational Preference']]: {
    name: 'operationalPreference',
    type: GridHeaderColumnType.list,
    items: driverLists.operationalPreference,
    disabled: true
  },
  [DriverColumn['Date of Birth']]: {
    name: 'birthday',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['Mailing Address']]: {
    name: 'mailingAddressFullName',
    type: GridHeaderColumnType.string,
    disabled: true
  },
  [DriverColumn['Phone Number']]: {
    name: 'phone',
    type: GridHeaderColumnType.number,
    disabled: true
  },
  [DriverColumn.Email]: {
    name: 'email',
    type: GridHeaderColumnType.string,
    disabled: true
  },
  [DriverColumn['Milage Restrictions']]: {
    name: 'millageRestriction',
    type: GridHeaderColumnType.list,
    items: driverLists.millageRestriction,
    disabled: true
  },
  [DriverColumn['Owned Truck']]: {
    name: 'powerUnit',
    type: GridHeaderColumnType.string,
    disabled: true
  },
  [DriverColumn['Driver Type']]: {
    name: 'driverType',
    type: GridHeaderColumnType.list,
    items: driverLists.type,
    disabled: true
  },
  [DriverColumn.ELD]: {
    name: 'eldNumber',
    type: GridHeaderColumnType.number,
    disabled: true
  },
  [DriverColumn['License Number']]: {
    name: 'licenseNumber',
    type: GridHeaderColumnType.string,
    disabled: true
  },
  [DriverColumn['License Exp. Date']]: {
    name: 'licenseExpDate',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['License Issue Date']]: {
    name: 'licenseIssueDate',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['License Issue State']]: {
    name: 'licenseIssueStateId',
    type: GridHeaderColumnType.state,
    disabled: true
  },
  [DriverColumn['License Address']]: {
    name: 'licenseAddressFullName',
    type: GridHeaderColumnType.string,
    disabled: true
  },
  [DriverColumn.Endorsements]: {
    name: 'endorsement',
    type: GridHeaderColumnType.list,
    items: driverLists.endorsement,
    disabled: true
  },
  [DriverColumn['Endorsements Exp. Date']]: {
    name: 'endorsementExpDate',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['Med. Exam. Exp. Date']]: {
    name: 'medicalExamCardExpDate',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['TWIC Exp. Date']]: {
    name: 'twicExpDate',
    type: GridHeaderColumnType.date,
    disabled: true
  },
  [DriverColumn['Port Credentials Exp. Date']]: {
    name: 'portCredentialsExpDate',
    type: GridHeaderColumnType.date,
    disabled: true
  }
}
