import {
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  TroubleTicketDTO,
  VendorDTO
} from '../../../../api/origin/business-logic'
import { DocumentDTO } from '../../../../api/origin/document-service'
import { ActivityGroup, TransportationActivityGroup } from '../../../../services/DTO/activity/interfaces'
import { DTOError } from '../../../../services/DTO/interfaces'
import { IAttachment } from '../../../../services/filesService/interfaces'
import { IMessage } from '../../../../store/reducers/communicationHub/interfaces'

export type IDispatchDeliveryOrder = DispatchDeliveryOrderViewDTO & {
  communicationHubAppChannelId?: string
  forceUpdate?: boolean
  assignedVendor?: VendorDTO
  sellSideQuotes?: any[]
  buySideQuotes?: any[]
  activityGroups?: ActivityGroup[]
  declinedVendorIds?: string[]
  currentActivityGroup?: TransportationActivityGroup
  temporaryData?: {
    streetTurnId?: string
    streetTurn?: DispatchDeliveryOrderStreetTurnDTO
    hasMovesActivities?: boolean
  }
  error?: DTOError
}

export type IDispatchDeliveryOrderActivityDocumentMessages = IMessage[]

export enum ActivityDocumentPopupView {
  INFO = 'INFO',
  CREATEDOCUMENT = 'CREATEDOCUMENT',
  CREATETROUBLETICKET = 'CREATETROUBLETICKET',
  RESOLVETROUBLETICKETUNSUCCESSFUL = 'RESOLVETROUBLETICKETUNSUCCESSFUL',
  RESOLVETROUBLETICKETVALIDATE = 'RESOLVETROUBLETICKETVALIDATE',
  RESOLVETROUBLETICKETPROCEED = 'RESOLVETROUBLETICKETPROCEED'
}
export interface IActivityDocumentPopup {
  view: ActivityDocumentPopupView
  activityId: string
  completionActualDate?: string
  uploadedDocumentData?: DocumentDTO
  uploadingFile?: IAttachment
  validatedManually?: boolean
  troubleTicketId?: string
  troubleTicket?: TroubleTicketDTO
  alertMessage?: IMessage
  troubleTicketDescription?: string
  troubleTicketNote?: string
}

export interface IDispatchDeliveryOrderState {
  [index: string]: IDispatchDeliveryOrder
}

export enum DDOErrorTypes {
  activity = 'activity'
}
