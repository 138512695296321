import { checkContactsValidation } from './contact'
import { checkLocationValidation } from './location'
import { checkAddressValidation, checkBusinessPartnerValidation } from './businessPartner'
import { checkDriverValidation } from './driver'
import { checkPowerUnitValidation } from './powerUnit'
import { checkEquipmentValidation } from './equipment'
import { checkSteamShipLineValidation } from './steamShipLine'
import { checkContainerValidation } from './container'
import { checkCustomerValidation } from './customer'
import { checkSubClientValidation } from './subClient'
import { checkDispatchDeliveryOrderValidation } from './dispatchDeliveryOrder'

export const checkValidation = {
  address: checkAddressValidation,
  businessPartner: checkBusinessPartnerValidation,
  contacts: checkContactsValidation,
  location: checkLocationValidation,
  driver: checkDriverValidation,
  powerUnit: checkPowerUnitValidation,
  equipment: checkEquipmentValidation,
  steamShipLine: checkSteamShipLineValidation,
  container: checkContainerValidation,
  customer: checkCustomerValidation,
  subClient: checkSubClientValidation,
  dispatchDeliveryOrder: checkDispatchDeliveryOrderValidation
}
