import * as React from 'react'
import { oc } from 'ts-optchain'
import { DefaultColumn } from '../../../../../UI/GridSystem'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { StyledRight, Top } from './styles'
import Cargo from '../../../../../UI/Widget/widgets/cargo/'
import Document from '../../../../../UI/Widget/widgets/document'
import SteamShipLine from '../../../../../UI/Widget/widgets/steamShipLine'
import {
  CargoDTO,
  DeliveryOrderViewDTO,
  HazmatDTO,
  SteamShipLineDTO
} from '../../../../../../api/origin/business-logic'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { DDOContext } from '../../../../../../services/DTO/dispatchDeliveryOrder/context'
import { TerminalIntegrationWidget } from '../../../../../UI/Widget/widgets/terminalIntegration'
import { isDDOActive, isDDOHasLocationsWithActiveTerminalIntegration } from '../../../../../../services/DTO/activity'
import { isDDOStatusNew } from '../../../../../../services/functions/test/isDDOStatusNew'

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateHazmat: (hazmat: HazmatDTO) => void
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  updateCargo: (fieldName: keyof CargoDTO) => (value: string) => void
  updateSteamShipLine: (steamShipLine: SteamShipLineDTO) => void
  checkTerminalIntegration: () => void
  actions: IGridItemActions
}

type Props = StateProps

const Right: React.SFC<Props> = ({
  actions,
  dispatchDeliveryOrder,
  updateDeliveryOrder,
  updateDispatchDeliveryOrderField,
  updateDispatchDeliveryOrderMultipleFields,
  updateHazmat,
  updateSteamShipLine,
  updateCargo,
  checkTerminalIntegration
}) => {
  const {
    state: {
      marks: { reefer }
    }
  } = React.useContext(DDOContext)
  return (
    <StyledRight>
      <Top>
        {oc(dispatchDeliveryOrder).deliveryOrder.type() !== DeliveryOrderViewDTO.TypeEnum.REPOSITION &&
          (!isDDOStatusNew(dispatchDeliveryOrder.status) ||
            Boolean(oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId())) &&
          isDDOActive(dispatchDeliveryOrder) &&
          isDDOHasLocationsWithActiveTerminalIntegration(dispatchDeliveryOrder) && (
            <TerminalIntegrationWidget
              actions={actions}
              dispatchDeliveryOrder={dispatchDeliveryOrder}
              checkTerminalIntegration={checkTerminalIntegration}
            />
          )}
      </Top>
      <DefaultColumn
        isGrid={true}
        flexClear={true}
        padding={{
          top: 15,
          right: 16,
          left: 16
        }}
      >
        <DefaultColumn margin={{ bottom: 15 }}>
          <Cargo
            disabled={actions.enableEditing === false}
            reefer={reefer}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            updateDeliveryOrder={updateDeliveryOrder}
            updateDispatchDeliveryOrder={updateDispatchDeliveryOrderField}
            updateDispatchDeliveryOrderMultipleFields={updateDispatchDeliveryOrderMultipleFields}
            updateHazmat={updateHazmat}
            updateCargo={updateCargo}
          />
        </DefaultColumn>
        <DefaultColumn margin={{ bottom: 15 }}>
          <Document
            disabled={actions.enableEditing === false}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            updateDeliveryOrder={updateDeliveryOrder}
          />
        </DefaultColumn>
        <DefaultColumn margin={{ bottom: 25 }}>
          <SteamShipLine
            disabled={actions.enableEditing === false}
            updateSteamShipLine={updateSteamShipLine}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            updateDeliveryOrder={updateDeliveryOrder}
          />
        </DefaultColumn>
      </DefaultColumn>
    </StyledRight>
  )
}

export default Right
