import { DriverDTO, DriverViewDTO } from '../../../api/api'
import { groupedDriverStatuses } from '../../constants/driverStatuses'

export const testDriverStatus = (status: DriverDTO.StatusEnum | DriverViewDTO.StatusEnum) => {
  return {
    isActive: groupedDriverStatuses.Active.includes(status),
    isInactive: groupedDriverStatuses.Inactive.includes(status),
    isCandidate: groupedDriverStatuses.Candidate.includes(status)
  }
}
