import * as React from 'react'
import { GridColumn, Column, ColumnRow } from '../../../../../UI/GridSystem'
import { Select } from '../../../../../UI/DataFields/Select'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { connect } from 'react-redux'
import { IEquipment } from '../../../interfaces'
import { Title } from '../Credentials/styledComponents'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { StyledGeneral } from './styledComponents'
import styled from '../../../../../../styles/styledComponents'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { equipmentLists } from '../../../../../../services/select/equipmentLists'
import { commonLists } from '../../../../../../services/select/commonLists'
import { EquipmentDTO } from '../../../../../../api/api'
import { checkEquipmentNumberValidation } from '../../../../../../services/DTO/equipment'
import theme from '../../../../../../styles/theme'
import { FileVersionDTO } from '../../../../../../api/origin/document-service'

const GeneralContainer = styled(GridColumn)`
  ${Column}:last-child {
    justify-content: flex-end;
  }
`

type OwnProps = { equipment: IEquipment; actions: IGridItemActions }

type DispatchProps = {}

type Props = DispatchProps & OwnProps

const isValidMake = createValidator(2, 64)
const isValidModel = createValidator(2, 64)

const General: React.SFC<Props> = ({ equipment, actions: { modify, modifyParentObjectField, setFetching } }) => {
  const isEquipmentNumberValid = checkEquipmentNumberValidation(equipment.chassisNumber || '')
  return (
    <StyledGeneral minRows={70}>
      <GeneralContainer rows={61} flexBasis={'auto'} padding={{ top: 25, left: 20, right: 20 }}>
        <Column columns={10} isGrid={true} margin={{ right: 40 }}>
          <ColumnRow>
            <InputField.TextMask
              title={'Equipment #'}
              highlighted={!isEquipmentNumberValid}
              required={true}
              value={equipment.chassisNumber}
              maxLength={11}
              transformOnInputChange={value => (value ? value.toUpperCase() : value)}
              textMask={[
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/
              ]}
              onChange={modifyParentObjectField('chassisNumber')}
            />
            {equipment.chassisNumber && !isEquipmentNumberValid && (
              <EquipmentNumberErrorMessage>
                Invalid number. 4 letters and 6 or 7 numbers allowed
              </EquipmentNumberErrorMessage>
            )}
          </ColumnRow>
          <ColumnRow margin={{ top: 20, bottom: 20 }}>
            <Select
              title={'Type'}
              required={true}
              selectedValue={equipment.equipmentType}
              list={equipmentLists.equipmentType}
              onSelect={modifyParentObjectField('equipmentType')}
            />
          </ColumnRow>
          <ColumnRow margin={{ bottom: 20 }}>
            <Input
              title={'SubType'}
              value={equipment.equipmentSubType}
              onChange={modifyParentObjectField('equipmentSubType')}
            />
          </ColumnRow>
          <ColumnRow>
            <Input title={'Specs'} value={equipment.specs} onChange={modifyParentObjectField('specs')} />
          </ColumnRow>
        </Column>

        <Column columns={10} isGrid={true} padding={{ top: 75 }} margin={{ right: 40 }}>
          <ColumnRow>
            <InputField.TextMask
              title={'Vehicle ID Number (VIN)'}
              valid={equipment.vin ? equipment.vin.length >= 11 : true}
              value={equipment.vin}
              transformOnInputChange={value => (value ? value.toUpperCase() : value)}
              textMask={[
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/,
                /(\d|[a-zA-Z])/
              ]}
              onChange={modifyParentObjectField('vin')}
            />
          </ColumnRow>
          <ColumnRow margin={{ top: 20, bottom: 20 }}>
            <InputField.TextMask
              title={'License Plate'}
              valid={equipment.licensePlate ? equipment.licensePlate.length >= 6 : true}
              value={equipment.licensePlate}
              transformOnInputChange={value => (value ? value.toUpperCase() : value)}
              textMask={[
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/,
                /(\d|[a-zA-Z]|-)/
              ]}
              onChange={modifyParentObjectField('licensePlate')}
            />
          </ColumnRow>
          <ColumnRow>
            <Select
              title={'Ownership'}
              required={true}
              selectedValue={equipment.ownership}
              list={
                equipment.ownership === EquipmentDTO.OwnershipEnum.OWNEROPERATED
                  ? equipmentLists.ownership
                  : equipmentLists.ownership.filter(item => item.value !== EquipmentDTO.OwnershipEnum.OWNEROPERATED)
              }
              onSelect={modifyParentObjectField('ownership')}
            />
          </ColumnRow>
        </Column>

        <Column columns={12} isGrid={true} padding={{ top: 75 }} margin={{ right: 40 }}>
          <ColumnRow>
            <Input
              title={'Make'}
              maxLength={64}
              valid={isValidMake(equipment.make)}
              value={equipment.make}
              onChange={modifyParentObjectField('make')}
            />
          </ColumnRow>
          <ColumnRow margin={{ top: 20, bottom: 20 }}>
            <Input
              title={'Model'}
              maxLength={64}
              valid={isValidModel(equipment.model)}
              value={equipment.model}
              onChange={modifyParentObjectField('model')}
            />
          </ColumnRow>
          <ColumnRow>
            <ColumnRow>
              <InputField.Numeric
                title={'Year'}
                maxLength={4}
                valid={equipment.year >= 1900 && equipment.year <= 2100}
                placeholder={'2001'}
                value={equipment.year}
                onChange={modifyParentObjectField('year')}
              />
            </ColumnRow>
            <ColumnRow margin={{ left: 20, right: 20 }}>
              <InputField.ThousandSeparator
                title={'MCW'}
                placeholder={'00000'}
                value={equipment.weight}
                onChange={modifyParentObjectField('weight')}
              />
            </ColumnRow>
            <ColumnRow>
              <Select
                title={'Unit'}
                selectedValue={equipment.weightUnit}
                list={commonLists.weightUnit}
                onSelect={modifyParentObjectField('weightUnit')}
              />
            </ColumnRow>
          </ColumnRow>
        </Column>

        <Column columns={12} isGrid={true} padding={{ top: 75 }} margin={{ right: 40 }}>
          <ColumnRow>
            <DateField
              title={'Pick Date'}
              date={equipment.pickupDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('pickupDate')}
            />
          </ColumnRow>
          <ColumnRow margin={{ top: 20, bottom: 20 }}>
            <DateField
              title={'Return Date'}
              date={equipment.returnDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('returnDate')}
            />
          </ColumnRow>
          {!!equipment.days && (
            <ColumnRow>
              <Input
                disabled={true}
                title={'Chassis Days in Operation'}
                placeholder={' '}
                value={equipment.days}
                onChange={() => {}}
              />
            </ColumnRow>
          )}
        </Column>

        <Column columns={9} isGrid={true} padding={{ top: 30 }} margin={{ right: 40 }}>
          <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 20 }}>
            Registration
          </Title>
          <ColumnRow>
            <FieldTemplate.StateSearch
              title={'State'}
              stateId={oc(equipment).registration.stateId(null)}
              onChange={stateId => modifyParentObjectField('registration')({ ...equipment.registration, stateId })}
            />
          </ColumnRow>
          <ColumnRow margin={{ top: 20, bottom: 20 }}>
            <DateField
              title={'Date'}
              date={equipment.registration.registeredDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={registeredDate =>
                modifyParentObjectField('registration')({ ...equipment.registration, registeredDate })
              }
            />
          </ColumnRow>
          <ColumnRow>
            <DateField
              title={'Exp. Date'}
              date={equipment.registration.expirationDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={expirationDate =>
                modifyParentObjectField('registration')({ ...equipment.registration, expirationDate })
              }
            />
          </ColumnRow>
        </Column>

        <Column columns={9} isGrid={true}>
          <ColumnRow>
            <ViewingObjectFileUploader
              uploadingFileId={equipment.id + ' equipment.registration.documentFile'}
              title={'Doc. Image'}
              file={equipment.registration.documentFile}
              historyProps={{ entityId: equipment.id, type: FileVersionDTO.TypeEnum.MACHINERYREGISTRATION }}
              onFileUploaded={(documentFile, modifiedObject) => ({
                ...modifiedObject,
                registration: { ...modifiedObject.registration, documentFile }
              })}
              onClearFile={() =>
                modifyParentObjectField('registration')({ ...equipment.registration, documentFile: undefined })
              }
            />
          </ColumnRow>
        </Column>
      </GeneralContainer>
    </StyledGeneral>
  )
}

export default connect(
  null,
  null
)(General)

const EquipmentNumberErrorMessage = styled.div`
  position: absolute;
  left: 100%;
  white-space: nowrap;
  color: ${theme.colors.defaultRed};
  padding: 30px 0 0 30px;
`
