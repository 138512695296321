import { TContact } from '../../../components/common/contact/interfaces'
import { schemaContact } from '../../yupScheme'

export const isContactValid = (contact: TContact): boolean => {
  // schemaContact.validate(contact).catch(e =>
  //   // getStore().dispatch(
  //   //   addServerMessage({
  //   //     type: 'error',
  //   //     message: e.message
  //   //   })
  //   // )
  //   console.log(e)
  // )
  return (
    schemaContact.isValidSync(contact) &&
    Boolean(contact.firstName || contact.lastName || contact.phone || contact.email || contact.notes)
  )
}
