import * as React from 'react'
import { ControllerStateAndHelpers } from 'downshift'
import { Input as StyledInput, ContainerInput } from '../../styledComponents'
import { Whitespace } from '../../../../../services/keyboardService/keys'
import theme, { IStyledComponentsProps } from '../../../../../styles/theme'
import styled, { withProps } from '../../../../../styles/styledComponents'

export const Input: React.FC<{
  downshift: ControllerStateAndHelpers<any>
  className: string
  placeholder: string
  isShowResult?: boolean
  isEdit?: boolean
  disabled?: boolean
  toggleIsEdit?: () => void
  fetchList: () => void
  isFetchingList?: boolean
  isShortName?: boolean
  transformForResult?: { head: string; tail: string }
}> = ({
  className,
  placeholder,
  fetchList,
  toggleIsEdit,
  disabled,
  isEdit,
  isShowResult,
  transformForResult,
  isFetchingList,
  isShortName,
  downshift: { getInputProps, openMenu, inputValue }
}) => {
  if (isShowResult) {
    if (isEdit) {
      return (
        <ContainerInput className={className} loading={isFetchingList} onBlur={toggleIsEdit}>
          <StyledInput
            {...getInputProps({
              disabled,
              placeholder,
              autoFocus: true,
              onKeyDown: ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                if (key === Whitespace.Enter) {
                  fetchList()
                  openMenu()
                }
              }
            } as any)}
          />
        </ContainerInput>
      )
    }
    if (isShortName) {
      return (
        <ContainerInput loading={isFetchingList} className={className}>
          <StyledInput
            {...getInputProps({
              disabled,
              placeholder,
              onKeyDown: ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
                if (key === Whitespace.Enter) {
                  fetchList()
                  openMenu()
                }
              }
            } as any)}
          />
        </ContainerInput>
      )
    }

    const noValue = !Boolean(inputValue)

    const { head, tail } = transformForResult

    return (
      <Div className={'mdi mdi-menu-down'} noValue={noValue} onClick={toggleIsEdit}>
        {!noValue ? (
          <Result head={head} tail={tail} />
        ) : (
          <span style={{ opacity: 0.3, lineHeight: '35px', paddingLeft: 1 }}>{placeholder}</span>
        )}
      </Div>
    )
  }

  return (
    <ContainerInput loading={isFetchingList} className={className}>
      <StyledInput
        {...getInputProps({
          disabled,
          placeholder,
          onKeyDown: ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
            if (key === Whitespace.Enter) {
              fetchList()
              openMenu()
            }
          }
        } as any)}
      />
    </ContainerInput>
  )
}

const Div = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  position: relative;
  padding: 0 10px;
  height: 35px;
  border: none;
  border-radius: 5px;
  width: 100%;
  line-height: 35px;
  overflow: hidden;
  background: ${theme.colors.basicBackgroundColor};
  white-space: nowrap;  

  &:before {
    content: '\f349' !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: ${theme.colors.basicBlueColor} !important;
    font-size: 18px !important;
    position: absolute;
    // top: 0;
    right: 0;
    // bottom: 0;
  }
`
const ResultValue = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 35px;
  left: 10px;
  right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 1px;

  > span {
    color: gray;
    margin-left: 5px;
  }
`

const Result: React.SFC<{ head: string; tail: string }> = ({ head, tail }) => {
  return (
    <ResultValue>
      {head}
      <span>{tail}</span>
    </ResultValue>
  )
}
