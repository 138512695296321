import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { CommunicationHubSuggestionsContext } from '../contexts/CommunicationHubSuggestionsContext'
import { IStore } from '../store/store.interface'
import { IDispatchDeliveryOrder } from '../components/common/dispatchDeliveryOrder/interfaces'
import { UserDTO, UserType } from '../api/origin/user-service'
import { getStore } from '../store/configureStore'
import { TransportationActivityViewDTO, DispatchDeliveryOrderViewDTO } from '../api/origin/business-logic'
import { callAPI, dispatchDeliveryOrderAPI } from '../api/api'
import { getDispatchDeliveryOrderById } from '../components/common/dispatchDeliveryOrder/epics'
import { useAppSelector } from '../hooks/useAppSelector'
import { selectContacts } from '../store/select/contactSelect'

type OwnProps = {
  dispatchDeliveryOrderId: string
  children: any
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

type Props = OwnProps & StateProps

const Component = (props: Props) => {
  const { children, dispatchDeliveryOrder, dispatchDeliveryOrderId } = props
  const contactMapping = useAppSelector(selectContacts)
  const [assignedVendorAuthIds, setAssignedVendorAuthIds] = React.useState<string[]>([])
  const [contacts, setContacts] = React.useState<UserDTO[]>([])

  React.useEffect(() => {
    if (!dispatchDeliveryOrder) {
      getDispatchDeliveryOrderById(dispatchDeliveryOrderId)
    }
  }, [dispatchDeliveryOrderId, dispatchDeliveryOrder])

  React.useEffect(() => {
    if (Array.isArray(oc(dispatchDeliveryOrder).activityIds())) {
      const store = getStore().getState()
      const activities = dispatchDeliveryOrder.activityIds
        .map(activityId => store.activity.transportationActivities[activityId])
        .filter(Boolean)
      const vendorAuthIds: string[] = R.uniq(
        activities.reduce((acc, activity) => {
          const vendorAuthUserId = oc(activity as TransportationActivityViewDTO).vendor.authUserId()

          if (vendorAuthUserId) {
            acc.push(vendorAuthUserId)
          }

          return acc
        }, [])
      )

      setAssignedVendorAuthIds(vendorAuthIds)
    } else {
      if (dispatchDeliveryOrderId) {
        callAPI(dispatchDeliveryOrderAPI.findVendors, dispatchDeliveryOrderId)
          .toPromise()
          .then(vendors => {
            setAssignedVendorAuthIds(vendors.map(vendor => vendor.authUserId).filter(Boolean))
          })
      }
    }
  }, [dispatchDeliveryOrder, dispatchDeliveryOrderId])

  React.useEffect(() => {
    const dispatchDeliveryOrderStatus = oc(dispatchDeliveryOrder).status()
    const operators = Object.values(contactMapping).filter(contact => contact.type === UserType.OPERATOR)
    const drivers = [
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED,
      DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED,
      DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED
    ].includes(dispatchDeliveryOrderStatus)
      ? []
      : assignedVendorAuthIds
          .map(authId => contactMapping[authId])
          .filter(Boolean)
          .filter(contact => contact.type === UserType.DRIVER)

    setContacts(drivers.concat(operators))
  }, [contactMapping, assignedVendorAuthIds, oc(dispatchDeliveryOrder).status()])

  return (
    <CommunicationHubSuggestionsContext.Provider
      value={{
        contacts
      }}
    >
      {children}
    </CommunicationHubSuggestionsContext.Provider>
  )
}

export const CommunicationHubSuggestionsProvider = connect(
  ({ dispatchDeliveryOrder }: IStore, { dispatchDeliveryOrderId }: OwnProps) => {
    return {
      dispatchDeliveryOrder: dispatchDeliveryOrderId ? dispatchDeliveryOrder[dispatchDeliveryOrderId] : undefined
    }
  }
)(React.memo(Component))
