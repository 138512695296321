import { AddressDTO, StateDTO } from '../../api/origin/business-logic'
import { isValidZip } from '../../components/UI/Input/Validation/Zip'
import { oc } from 'ts-optchain'
import { getStore } from '../../store/configureStore'

export const defaultState: StateDTO = {
  code: '',
  countryCode: '',
  fullObject: true,
  id: null,
  name: ''
}

export const checkAddressFields = (address: AddressDTO) => {
  return Boolean(
    address.street && address.city && address.stateId && address.postalCode && isValidZip(address.postalCode)
  )
}

export const createAddressLine = (address: AddressDTO): string => {
  if (!address) {
    return ''
  }

  const { stateId, postalCode, city, street } = address
  let stateCode = undefined
  if (stateId) {
    const state = getStore().getState().state[stateId]
    stateCode = oc(state).code()
  }

  return [street, city, stateCode, postalCode].filter(Boolean).join(', ')
}

export const createAddressLines = (address: AddressDTO): string[] => {
  if (!address) {
    return []
  }

  const { stateId, postalCode, city, street } = address
  let stateCode = undefined
  if (stateId) {
    const state = getStore().getState().state[stateId]
    stateCode = oc(state).code()
  }

  return [[street, city].filter(Boolean).join(', '), [stateCode, postalCode].filter(Boolean).join(', ')]
}

export const getStateInfo = (stateId?: string): StateDTO =>
  stateId ? getStore().getState().state[String(stateId)] || defaultState : defaultState

export const getStateByCodes = ({ stateCode, countryCode }: { stateCode: string; countryCode: string }): StateDTO => {
  const states = Object.values(getStore().getState().state)
  return states.find(state => state.code === stateCode && state.countryCode === countryCode) || defaultState
}
