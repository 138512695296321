import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'
import { ActionButton } from './ActionButton'
import { oc } from 'ts-optchain'
import { useUploadFilesStore } from '../../../hooks/useUploadFilesStore'
import { getStore } from '../../../store/configureStore'

export const StyledActions = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  user-select: none;
  position: absolute;
  bottom: 0;
  right: 30px;
`
const ActionCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 30px;
  color: ${theme.colors.darkGreyColor};
  font-size: 12px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #333;
  }
`
const ActionSave = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  color: white;
  border-radius: 15px;
  background: ${theme.colors.basicBlueColor};
  transition: background 0.5s ease;
  margin-left: 20px;

  &.disable {
    background: #e0e0e0;
    cursor: default;
  }
`

export const ActionsSaveCancel: React.SFC<{
  buttonSaveId?: string
  buttonCancelId?: string
  styles?: any
  disableSave?: boolean
  disableCancel?: boolean
  onSave: () => void
  onCancel?: () => void
  showSave?: boolean
  showCancel?: boolean
  labels?: {
    cancel?: string
    save?: string
  }
}> = ({
  styles,
  showSave = true,
  showCancel = true,
  disableSave,
  onCancel,
  onSave,
  labels,
  buttonCancelId,
  buttonSaveId
}) => (
  <StyledActions className="actions-save-cancel" style={styles}>
    {showCancel && (
      <ActionCancel id={buttonCancelId} onClick={() => onCancel()}>
        {(labels && labels.cancel) || 'Cancel'}
      </ActionCancel>
    )}
    {showSave && (
      <ActionSave
        id={buttonSaveId}
        className={disableSave ? 'disable' : ''}
        onClick={!disableSave ? () => onSave() : null}
      >
        {(labels && labels.save) || 'Save'}
      </ActionSave>
    )}
  </StyledActions>
)

export default ActionsSaveCancel

export const SaveCancelButtons = React.memo(
  (props: {
    buttonSaveId?: string
    buttonCancelId?: string
    disableSave: boolean
    styles?: any
    onSave: () => void
    onCancel: () => void
    labels?: {
      cancel?: string
      save?: string
    }
  }) => {
    const { buttonSaveId, buttonCancelId, onCancel, disableSave, labels, onSave, styles } = props
    const { uploadingFiles } = useUploadFilesStore()
    const activeTabId = React.useMemo(() => getStore().getState().tabs.activeMainTabId, [])
    const isUploading = React.useMemo((): boolean => Boolean(uploadingFiles[activeTabId]), [
      uploadingFiles[activeTabId]
    ])

    return (
      <ButtonsContainer style={styles}>
        <ActionButton
          id={buttonCancelId}
          disabled={isUploading}
          round={true}
          styles={{ border: 'none', background: 'none', color: 'rgb(108, 112, 125)' }}
          onClick={onCancel}
        >
          {oc(labels).cancel('Cancel')}
        </ActionButton>
        <ActionButton
          id={buttonSaveId}
          disabled={disableSave || isUploading}
          round={true}
          filled={true}
          onClick={onSave}
        >
          {oc(labels).save('Save')}
        </ActionButton>
      </ButtonsContainer>
    )
  }
)

const ButtonsContainer = styled.div`
  align-self: stretch;
  display: inline-flex;
  align-items: center;
`
