import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { createAddressLine, getStateByCodes } from '../../../../../services/addressService'
import { showErrorModalWindow, showModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { ILocation } from '../../../../common/location/interfaces'
import { locationControllerApi } from '../../../../../api/location'
import { getLocationStatus } from '../../../../../services/DTO/location/status'
import { SelectLocation } from '../../../../../services/DTO/location/SelectLocation'
import { getContactsStatusSavePromise } from '../../../../../services/DTO/contact/save'
import { TContact } from '../../../../common/contact/interfaces'
import { combineContactIds } from '../../../../../services/DTO/saveDTO'
import { getStore } from '../../../../../store/configureStore'
import { isNewObject } from '../../../../../services/DTO'
import { callAPIWithErrorMessage, locationAPI } from '../../../../../api/api'
import { parseDTO } from '../../../../../services/DTO/parseDTO'

type Props = {
  modifiedLocation: ILocation
  initialLocation: ILocation
  setFetching: (state: boolean) => void
  modifyLocation: (location: ILocation) => void
  onLocationSaveComplete?: (location: ILocation) => void
  placeIdPassed?: boolean
}

export const saveWidgetLocation = async (props: Props) => {
  const {
    modifiedLocation,
    initialLocation,
    onLocationSaveComplete,
    modifyLocation,
    setFetching,
    placeIdPassed
  } = props
  let location: ILocation = modifiedLocation
  const prevLocationVersion = initialLocation
  setFetching(true)

  if (!placeIdPassed) {
    let locationDataChanged = false
    const addressString = createAddressLine(location.address)
    const placeId = oc(location).placeId()
    const locationLatitude = oc(location).latitude()
    const locationLongitude = oc(location).longitude()

    if (prevLocationVersion) {
      const prevAddressString = createAddressLine(prevLocationVersion.address)
      locationDataChanged = prevAddressString !== addressString
    } else {
      locationDataChanged = true
    }

    if (locationDataChanged || !(placeId && locationLatitude && locationLongitude)) {
      const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

      if (searchResults.length) {
        const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId

        if (!(isPlaceIdCorrect && locationLatitude && locationLongitude)) {
          setFetching(false)

          return showModalWindow({
            width: 445,
            title: 'Select location',
            content: (
              <SelectLocation
                selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
                locations={searchResults}
                onSelect={selectedPlaceId => {
                  if (selectedPlaceId) {
                    setFetching(true)

                    locationControllerApi
                      .locationByPlaceId(selectedPlaceId)
                      .then(requestedLocation => {
                        const { latitude, longitude } = requestedLocation
                        const requestedAddress = oc(requestedLocation).address()

                        location = { ...location, placeId: selectedPlaceId, latitude, longitude }

                        if (requestedAddress) {
                          if (requestedAddress.city) {
                            location.address = {
                              ...location.address,
                              street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                              city: requestedAddress.city || location.address.city,
                              postalCode: requestedAddress.postalCode || location.address.postalCode,
                              stateId:
                                requestedAddress.stateCode && requestedAddress.countryCode
                                  ? getStateByCodes({
                                      stateCode: requestedAddress.stateCode,
                                      countryCode: requestedAddress.countryCode
                                    }).id
                                  : location.address.stateId
                            }
                          }
                        }

                        modifyLocation(location)
                        saveWidgetLocation({ ...props, modifiedLocation: location, placeIdPassed: true })
                      })
                      .catch(() => {
                        setFetching(false)

                        showErrorModalWindow({
                          title: 'Location search error'
                        })
                      })
                  }
                }}
              />
            ),
            buttons: [
              {
                label: 'Cancel'
              },
              {
                label: 'Apply',
                disabled: true
              }
            ]
          })
        }
      } else {
        setFetching(false)
        return showErrorModalWindow({
          content: 'Location with the entered address is not found in Google'
        })
      }
    }
  }

  const { dispatch, getState } = getStore()
  const storeLocation = getState().location[location.id]
  const { needToSave, noStoreDataForUpdate } = getLocationStatus(location)

  if (noStoreDataForUpdate) {
    getContactsStatusSavePromise(location.contacts).then(
      (contacts: TContact[]): any => {
        const result = {
          ...location,
          contactIds: combineContactIds(location.contactIds, contacts),
          primaryContactId: oc(contacts.find(_ => _.primary)).id(null)
        }

        if (needToSave || !R.equals(storeLocation.contactIds, result.contactIds)) {
          return (isNewObject(result)
            ? callAPIWithErrorMessage(locationAPI.create, R.omit(result, ['id', 'contacts', 'primaryContact']))
            : callAPIWithErrorMessage(locationAPI.update, R.omit(result, ['contacts', 'primaryContact']), result.id)
          )
            .then(savedLocation => {
              parseDTO.location(savedLocation)

              if (onLocationSaveComplete) {
                onLocationSaveComplete(savedLocation)
              }
            })
            .finally(() => setFetching(false))
        } else {
          if (onLocationSaveComplete) {
            onLocationSaveComplete(location)
          }
          setFetching(false)
        }
      }
    )
  }
}
