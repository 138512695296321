import { parseCustomers } from './customer/parse'
import { parseBusinessPartners } from './businessPartner/parse'
import { parseSubClients } from './subClient/parse'
import { parseDispatchDeliveryOrders } from './dispatchDeliveryOrder/parse'
import { parseSteamShipLines } from './steamShipLine/parse'
import { parseContainers } from './container/parse'
import { parseLocations } from './location/parse'
import { parseDeliveryOrders } from './deliveryOrder/parse'
import { parseActivities } from './activity/parse'
import { parseDriver } from './driver/parse'

const checkBeforeParsing = (parseAction: any) => (items: any | any[]) => {
  if (!items || (Array.isArray(items) && items.length === 0)) {
    return
  }

  const params = Array.isArray(items) ? items : [items] // always array
  return parseAction(params)
}

export const parseDTO = {
  customer: checkBeforeParsing(parseCustomers),
  driver: checkBeforeParsing(parseDriver),
  businessPartner: checkBeforeParsing(parseBusinessPartners),
  subClient: checkBeforeParsing(parseSubClients),
  steamShipLine: checkBeforeParsing(parseSteamShipLines),
  container: checkBeforeParsing(parseContainers),
  location: checkBeforeParsing(parseLocations),
  deliveryOrder: checkBeforeParsing(parseDeliveryOrders),
  dispatchDeliveryOrder: checkBeforeParsing(parseDispatchDeliveryOrders),
  activities: checkBeforeParsing(parseActivities)
}
