import * as React from 'react'
import styled from '../../../styles/styledComponents'
import { TabType } from '../../common/tabs/interfaces'

type OwnProps = {
  unitType: TabType
  itemsNumber?: number
}

type Props = OwnProps

export const FloatingGridItemsCounter = ({ unitType, itemsNumber }: Props) => {
  switch (unitType) {
    case TabType.dispatchDeliveryOrder:
    case TabType.scheduler:
      return (
        <Counter>
          <span>Number of filtered items:</span> {itemsNumber || 0}
        </Counter>
      )
    default:
      return null
  }
}

const Counter = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: #fff;
  border-radius: 4px;
  background: #475264;
  padding: 5px;

  span {
    font-weight: 300;
  }
`
