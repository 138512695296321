import * as R from 'remeda'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { addToStore, removeIds, addDocumentationActivitiesToStore, addTransportationActivitiesToStore } from '../action'
import { IActivityState } from '../interfaces'

const activityState: IActivityState = {
  documentationActivities: {},
  transportationActivities: {}
}

export default reducerWithInitialState<IActivityState>(activityState)
  .case(addToStore, (state, activities) => ({
    documentationActivities: { ...state.documentationActivities, ...activities.documentationActivities },
    transportationActivities: { ...state.transportationActivities, ...activities.transportationActivities }
  }))
  .case(addTransportationActivitiesToStore, (state, transportationActivities) => ({
    ...state,
    transportationActivities: { ...state.transportationActivities, ...transportationActivities }
  }))
  .case(addDocumentationActivitiesToStore, (state, documentationActivities) => ({
    ...state,
    documentationActivities: { ...state.documentationActivities, ...documentationActivities }
  }))
  .case(removeIds, (state, ids) => ({
    documentationActivities: R.omit(state.documentationActivities, ids),
    transportationActivities: R.omit(state.transportationActivities, ids)
  }))
  .build()
