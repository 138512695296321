import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { IDispatchDeliveryOrder } from '../../interfaces'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'

type Props = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

export const DispatchDeliveryOrderNumberColumn = (props: Props) => {
  const { dispatchDeliveryOrder } = props

  return (
    <>
      <Link
        style={dispatchDeliveryOrder.error ? { color: theme.colors.defaultRed } : undefined}
        onClick={event => {
          openDDOinNewTab({
            event,
            ddoId: dispatchDeliveryOrder.id,
            ddoNumber: dispatchDeliveryOrder.number
          })
        }}
      >
        {dispatchDeliveryOrder.number}
      </Link>
      {dispatchDeliveryOrder.error && <Alert className={'mdi mdi-alert'} />}
    </>
  )
}

const Alert = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: ${theme.colors.defaultRed};
`

const Link = styled.div`
  width: unset !important;
  color: ${theme.colors.basicBlueColor};
  padding: 10px 0;

  &:hover {
    text-decoration: underline;
  }
`
