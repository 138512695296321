import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { Group } from './Group'
import { SchedulerFilter, SchedulerSortBy } from '../../../interfaces'
import { Datepicker } from './Datepicker'
import { DateISOString, OngoingActivityGroupDTO } from '../../../../../../api/origin/business-logic'
import { updateTabFilters } from '../../../../tabs/actions'
import { dateService } from '../../../../../../services/timeService'
import { updateSchedulerFilterDate } from '../../../functions/updateSchedulerFilterDate'
import { ITabState } from '../../../../tabs/interfaces'
import { SelectDispatchDeliveryOrder } from './SelectDispatchDeliveryOrder'
import { SyncDriverActivitiesWithRouteBuilder } from './SyncDriverActivitiesWithRouteBuilder'
import { getCommunicationHubState, getDispatch } from '../../../../../../store'
import { communicationHubActions } from '../../../../../../store/reducers/communicationHub'
import { getStore } from '../../../../../../store/configureStore'

type OwnProps = {
  currentTab: ITabState
  sortBy: SchedulerSortBy
  dispatchDeliveryOrderNumber: number
  dispatchDeliveryOrderId: string
  syncWithRouteBuilder: boolean
  specificDate: DateISOString
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const FilteringAndSorting = React.memo((props: Props) => {
  const {
    currentTab,
    dispatchDeliveryOrderNumber,
    dispatchDeliveryOrderId,
    syncWithRouteBuilder,
    sortBy,
    specificDate
  } = props
  const isStageView = [
    SchedulerSortBy.date,
    SchedulerSortBy.deliveryLocationCity,
    SchedulerSortBy.deliveryStageDriver
  ].includes(sortBy)

  return (
    <Container>
      <FirstLine>
        <SelectDispatchDeliveryOrder
          dispatchDeliveryOrderNumber={dispatchDeliveryOrderNumber}
          dispatchDeliveryOrderId={dispatchDeliveryOrderId}
          currentTab={currentTab}
        />
        <Group currentTab={currentTab} sortBy={sortBy} />
      </FirstLine>
      {sortBy === SchedulerSortBy.driverActivities ? (
        <SyncDriverActivitiesWithRouteBuilder syncWithRouteBuilder={syncWithRouteBuilder} />
      ) : (
        <Datepicker
          disabled={Boolean(dispatchDeliveryOrderId && sortBy === SchedulerSortBy.driverAllStages)}
          date={specificDate}
          hideResetButton={isStageView}
          minDate={isStageView ? dateService.createStringDate.startDay : undefined}
          onChange={(date: DateISOString) => {
            setTimeout(() => {
              const dispatch = getDispatch()
              const { activeRouteBuilder } = getCommunicationHubState()
              const filters = oc(currentTab).uiSettings.filter([])
              const grouping = oc(filters.find(({ column }) => column === SchedulerFilter.grouping)).value()
              // @ts-ignore
              const updateFilters = updateSchedulerFilterDate({ date, grouping, filters })

              if (
                date &&
                (grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE || !grouping) &&
                activeRouteBuilder &&
                activeRouteBuilder.vendorId
              ) {
                const activeRouteBuilderTabIndex = activeRouteBuilder.tabs.findIndex(({ isActive }) => isActive)

                if (activeRouteBuilderTabIndex !== -1) {
                  const activeRouteBuilderTabDate = activeRouteBuilder.tabs[activeRouteBuilderTabIndex].date

                  if (!dateService.isTheSameDay(activeRouteBuilderTabDate, date)) {
                    dispatch(
                      communicationHubActions.setActiveRouteBuilderTabIndexDate({
                        tabIndex: activeRouteBuilderTabIndex,
                        date,
                        isActive: true
                      })
                    )
                  }
                }
              }

              if (updateFilters) {
                getStore().dispatch(updateTabFilters({ id: currentTab.id, filter: updateFilters }))
              }
            })
          }}
        />
      )}
    </Container>
  )
})

const Container = styled.div`
  flex-shrink: 0;
  width: 365px;
  background: white;
  border-radius: 4px;
  padding: 4px 16px;
  margin-right: 10px;
`
const FirstLine = styled.div`
  display: flex;
`
