import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { getContactStatus } from '../contact/status'
import { isEqualObjectsWithoutContacts } from '../saveDTO'
import { oc } from 'ts-optchain'

export const getBusinessPartnerStatus = (businessPartner: BusinessPartner): TObjectStatusForSave => {
  const store = getStore().getState()
  const storeBusinessPartner = store.businessPartner[businessPartner.id]
  const contactsStatus = (businessPartner.contacts || []).map(getContactStatus)
  const primaryContact = (businessPartner.contacts || []).find(_ => _.primary)
  const isPrimaryContactEqual = () => {
    if (!storeBusinessPartner.primaryContactId && !primaryContact) {
      return true
    }
    if (!storeBusinessPartner.primaryContactId && primaryContact) {
      return false
    }
    if (storeBusinessPartner.primaryContactId && primaryContact) {
      return storeBusinessPartner.primaryContactId === primaryContact.id
    }
  }

  return {
    noStoreDataForUpdate:
      compareUpdateDates(businessPartner, storeBusinessPartner) &&
      contactsStatus.map(_ => _.noStoreDataForUpdate).every(Boolean),
    needToSave:
      (oc(businessPartner).fullObject() &&
        !isEqualObjectsWithoutContacts(businessPartner, storeBusinessPartner, ['name'])) ||
      !isPrimaryContactEqual()
  }
}
