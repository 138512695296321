import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { Window } from './preview'
import { Popover } from './popover'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'

type OwnProps = {
  isDDOValid: boolean
  small?: boolean
  actions: IGridItemActions
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  quoteAmounts: {
    profit: number
    sellSideQuoteTotalAmount: number
    buySideQuoteTotalAmount: number
    buySideQuotesAmount: Record<string, number>
  }
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const QuotesWidget = ({
  isDDOValid,
  small,
  actions,
  dispatchDeliveryOrder,
  quoteAmounts: { profit, buySideQuotesAmount, buySideQuoteTotalAmount, sellSideQuoteTotalAmount }
}: Props) => {
  const highlighted = !oc(dispatchDeliveryOrder)
    .sellSideQuote.miscSurcharges([])
    .concat(
      ...oc(dispatchDeliveryOrder)
        .buySideQuotes([])
        .map(buySideQuote => oc(buySideQuote).miscSurcharges([]))
    )
    .every(miscSurcharge => miscSurcharge.description)

  return (
    <Widget
      fullScreen={true}
      highlighted={highlighted}
      extraPreviewStyle={small ? extraStylesForSmallWidget : undefined}
      isExternal={true}
      title={'SSQ/BSQ'}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        small,
        sellSideQuoteTotalAmount,
        buySideQuoteTotalAmount,
        profit
      }}
      detailsProps={{
        activities: dispatchDeliveryOrder.activities,
        isDDOValid,
        dispatchDeliveryOrder,
        profit,
        sellSideQuoteTotalAmount,
        buySideQuoteTotalAmount,
        buySideQuotesAmount,
        gridItemActions: actions
      }}
    />
  )
}

const extraStylesForSmallWidget = {
  height: 40,
  padding: '4px 8px 0'
}
