import * as React from 'react'
import { StyledWidget, Preview, Popover, PopoverContainer, ButtonClose, WidgetTitle, WidgetActions } from './styles'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import cn from 'classnames'
import { ActionButton } from '../Buttons/ActionButton'
import { Portal } from 'react-portal'
import { useApplicationSize } from '../../../hooks/useApplicationSize'

type BottomButton = {
  text: string
  onClick?: () => void
  isCloseButton?: boolean
  style?: any
}

type OwnProps = {
  fullScreen?: boolean
  title?: string
  extraPreviewStyle?: React.CSSProperties
  extraPopoverStyle?: React.CSSProperties
  RenderPreview: (props: any) => JSX.Element
  RenderDetails: (props: any) => JSX.Element
  previewProps: any
  detailsProps: any
  shadowColor?: string
  highlighted?: boolean
  fixedWidth?: boolean
  disable?: boolean
  required?: boolean
  red?: any
  value?: any
  isTableWidget?: boolean
  isExternal?: boolean
  bottomActions?: boolean | BottomButton[]
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const Widget = (props: Props) => {
  const {
    fullScreen,
    extraPreviewStyle,
    extraPopoverStyle,
    disable,
    required,
    value,
    shadowColor,
    highlighted,
    fixedWidth,
    red,
    isTableWidget,
    RenderDetails,
    RenderPreview,
    isExternal,
    detailsProps,
    previewProps,
    title,
    bottomActions
  } = props
  const { wrapperRef, togglePopover, statePopover, setPopoverState } = useOutsideClick({ withoutOutsideClick: true })
  const { communicationHubWidth } = useApplicationSize()
  const closePopover = () => setPopoverState(false)
  const toggle = () => (disable ? null : togglePopover())

  const classNames = cn({ open: statePopover, fixedWidth, disable, tableWidget: isTableWidget }, 'save')

  const previewClassNames = cn('widget-preview', {
    [shadowColor]: !disable && Boolean(shadowColor),
    errored: required && !value,
    highlighted: !statePopover && highlighted,
    red
  })
  const popoverClassNames = cn('save', { fullScreen })

  return (
    <StyledWidget ref={wrapperRef} className={classNames}>
      <Preview style={extraPreviewStyle} className={previewClassNames} isGrid={true} onClick={toggle}>
        <RenderPreview {...previewProps} />
      </Preview>
      {statePopover ? (
        <WidgetContext.Provider value={{ closePopover }}>
          <PopoverWrapper isExternal={isExternal}>
            <PopoverContainer
              className={cn({ isExternal })}
              style={fullScreen ? { right: communicationHubWidth } : undefined}
            >
              <Popover style={extraPopoverStyle} className={popoverClassNames}>
                <ButtonClose className={'mdi mdi-close'} onClick={closePopover} />
                {Boolean(title) && <WidgetTitle>{title}</WidgetTitle>}
                <RenderDetails {...detailsProps} />
                {Boolean(bottomActions) && (
                  <WidgetActions>
                    {Array.isArray(bottomActions) &&
                      bottomActions.map((button, index) => {
                        return (
                          <ActionButton
                            styles={button.style}
                            key={index}
                            filled={true}
                            round={true}
                            onClick={() => {
                              if (button.isCloseButton) {
                                closePopover()
                              }

                              if (button.onClick) {
                                button.onClick()
                              }
                            }}
                          >
                            {button.text}
                          </ActionButton>
                        )
                      })}
                    <ActionButton styles={{ marginLeft: 'auto' }} filled={true} round={true} onClick={closePopover}>
                      Ok
                    </ActionButton>
                  </WidgetActions>
                )}
              </Popover>
            </PopoverContainer>
          </PopoverWrapper>
        </WidgetContext.Provider>
      ) : null}
    </StyledWidget>
  )
}

type TWidgetPopoverContainer = {
  isExternal: boolean
  children: any
}
const PopoverWrapper = ({ children, isExternal }: TWidgetPopoverContainer) =>
  isExternal ? <Portal>{children}</Portal> : <>{children}</>

export const WidgetContext: React.Context<{ closePopover: () => void }> = React.createContext({
  closePopover: null
})
