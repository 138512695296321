import * as React from 'react'
import { connect } from 'react-redux'
import { StyledFieldContainer, StyledFilter, StyledToggle } from '../../Grid/styles'
import { LocationSearch } from '../../../../../UI/DataFields/SearchRequest/location'
// tslint:disable-next-line:max-line-length
import { LocationSearch as DeliveryLocationSearch } from '../../../../../UI/DataFields/SearchRequest/location/zip'
import { Select } from '../../../../../UI/DataFields/Select'
import { IRateFiltersState, RateField } from '../../../interfaces'
import { Toggle } from '../../../../../UI/Toggle'
import { findCustomersByTerm } from '../../../../customer/epics'
import { deliveryOrderLists } from '../../../../../../services/select/deliveryOrderLists'
import { quoteLists } from '../../../../../../services/select/quoteLists'
import { containerLists } from '../../../../../../services/select/containerLists'
import { qmpLists } from '../../../../../../services/select/qmpLists'
import { IStore } from '../../../../../../store/store.interface'
import { ContainerTypeDTO, DriverNameDTO } from '../../../../../../api/origin/business-logic'
import { ILocation } from '../../../../location/interfaces'
import { removeExtraZero } from '../../functions'
import { ResetButton, resetObjectValues } from '../../Grid/Filter/resetButton'
import { InputField } from '../../../../../UI/DataFields/Input'
import { SelecterWidget } from '../../../../../UI/DataFields/Select/SelecterWidget'
import { findDriversByTerm } from '../../../../drivers/epics'
import { SellSideQuoteRateDTO } from '../../../../../../api/origin/qmp-service'
import { oc } from 'ts-optchain'

type OwnProps = {
  filterState: IRateFiltersState
  setFiltersState: (filters: IRateFiltersState) => void
}

type StateProps = {
  containerType?: ContainerTypeDTO[]
  pickupLocation?: ILocation
  returnLocation?: ILocation
}

type DispatchProps = {}

type Props = StateProps & OwnProps & DispatchProps

const Component = (props: Props) => {
  const { filterState, setFiltersState, returnLocation, pickupLocation } = props

  const setFieldValue = React.useCallback(
    (field: RateField) => (value: any) =>
      setFiltersState({ ...filterState, [RateField.number]: null, [field]: (value && value.id) || value }),
    [filterState, setFiltersState]
  )

  const setNumberField = React.useCallback(
    (value: string) => {
      const val = removeExtraZero(value)

      if (val !== '') {
        setFiltersState({
          ...resetObjectValues(filterState),
          [RateField.number]: value,
          status: [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED]
        })
      }
    },
    [setFieldValue, filterState]
  )
  const customer = filterState[RateField.customerId]
  const vendor = filterState[RateField.vendorId]
  const ddoTypeIsRepo = oc(filterState)
    .deliveryOrderType([])
    .includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION)

  return (
    <StyledFilter className={'special-fields-container'}>
      <StyledFieldContainer style={{ width: 100 }} id={'number'}>
        <InputField.Numeric
          title={filterState.number ? 'Number' : null}
          value={filterState.number}
          onChange={setNumberField}
          placeholder={'Number'}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 120 }} id={'rate-type'}>
        <Select
          title={filterState.type ? 'Type of rate' : null}
          placeholder={'Type of rate'}
          selectedValue={filterState.type}
          multiselect={true}
          buttonClear={true}
          list={quoteLists.typeOfRate}
          dropdownStyle={{ minWidth: 150 }}
          onSelect={setFieldValue(RateField.type)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 220 }} id={'customer'}>
        <SelecterWidget
          title={'Customer'}
          placeholder={'Customer'}
          label={Array.isArray(customer) ? undefined : customer === '%%' ? 'TFF' : 'All Customers'}
          selectedItems={Array.isArray(customer) ? customer : null}
          staticList={[
            {
              label: 'All Customers',
              selecterValue: null,
              isSelected: customer === null
            },
            {
              label: 'TFF',
              selecterValue: '%%',
              isSelected: customer === '%%'
            }
          ]}
          getAllItems={() => findCustomersByTerm('')}
          onSelect={setFieldValue(RateField.customerId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 220 }} id={'driver'}>
        <SelecterWidget
          title={'Driver'}
          placeholder={'Driver'}
          label={Array.isArray(vendor) ? undefined : vendor === '%%' ? 'TFF' : 'All Drivers'}
          selectedItems={Array.isArray(vendor) ? vendor : null}
          staticList={[
            {
              label: 'All Drivers',
              selecterValue: null,
              isSelected: vendor === null
            },
            {
              label: 'TFF',
              selecterValue: '%%',
              isSelected: vendor === '%%'
            }
          ]}
          getAllItems={() => findDriversByTerm('', _ => _.driverType !== DriverNameDTO.DriverTypeEnum.VENDOR)}
          onSelect={setFieldValue(RateField.vendorId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'deliveryOrderType'} style={{ width: 120 }}>
        <Select
          title={filterState.deliveryOrderType ? 'IMP/EXP/REPO' : null}
          placeholder={'IMP/EXP/REPO'}
          multiselect={true}
          buttonClear={true}
          mutuallyExclusive={[
            [
              SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION.toString(),
              SellSideQuoteRateDTO.DeliveryOrderTypeEnum.IMPORT.toString()
            ],
            [
              SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION.toString(),
              SellSideQuoteRateDTO.DeliveryOrderTypeEnum.EXPORT.toString()
            ]
          ]}
          list={deliveryOrderLists.type}
          selectedValue={filterState.deliveryOrderType}
          onSelect={deliveryOrderType => {
            if (oc(deliveryOrderType)([]).includes(SellSideQuoteRateDTO.DeliveryOrderTypeEnum.REPOSITION)) {
              return setFiltersState({
                ...filterState,
                [RateField.number]: null,
                deliveryOrderType,
                deliveryLocation: null,
                loadType: null
              })
            }

            setFieldValue(RateField.deliveryOrderType)(deliveryOrderType)
          }}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'pickupLocationId'} style={{ width: 110 }}>
        <LocationSearch
          title={pickupLocation ? 'Pickup' : null}
          placeholder={'Pickup'}
          isShortName={true}
          value={pickupLocation}
          onChange={setFieldValue(RateField.pickupLocationId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'deliveryLocation'} style={{ width: 150 }}>
        <DeliveryLocationSearch
          disabled={ddoTypeIsRepo}
          title={filterState.deliveryLocation ? 'Delivery' : null}
          placeholder={'Delivery'}
          value={filterState.deliveryLocation}
          onChange={setFieldValue(RateField.deliveryLocation)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'returnLocationId'} style={{ width: 110 }}>
        <LocationSearch
          title={returnLocation ? 'Return' : null}
          placeholder={'Return'}
          isShortName={true}
          value={returnLocation}
          onChange={setFieldValue(RateField.returnLocationId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'containerTypeId'} style={{ width: 130 }}>
        <Select
          title={filterState.containerTypeId ? 'Container type' : null}
          placeholder={'Container type'}
          multiselect={true}
          buttonClear={true}
          dropdownStyle={{ minWidth: 260 }}
          selectedValue={filterState.containerTypeId}
          list={containerLists.containerTypeWithDescription}
          onSelect={setFieldValue(RateField.containerTypeId)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={'loadType'} style={{ width: 110 }}>
        <Select
          disabled={ddoTypeIsRepo}
          title={filterState.loadType ? 'Load Type' : null}
          placeholder={ddoTypeIsRepo ? '' : 'Load Type'}
          multiselect={true}
          buttonClear={true}
          selectedValue={filterState.loadType}
          list={quoteLists.loadTypeAllWithHTML}
          onSelect={setFieldValue(RateField.loadType)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 130 }} id={'rule'}>
        <Select
          title={filterState.ruleIds ? 'Rule' : null}
          placeholder={'Rule'}
          buttonClear={true}
          multiselect={true}
          selectedValue={filterState.ruleIds}
          list={qmpLists.rules}
          onSelect={setFieldValue(RateField.ruleIds)}
          dropdownStyle={{ minWidth: 270 }}
        />
      </StyledFieldContainer>
      <StyledToggle>
        <span>Show expired</span>
        <Toggle
          checked={filterState.status.includes(SellSideQuoteRateDTO.StatusEnum.EXPIRED)}
          onChange={(state: boolean) =>
            setFieldValue(RateField.status)(
              state
                ? [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED]
                : [SellSideQuoteRateDTO.StatusEnum.NEW]
            )
          }
        />
      </StyledToggle>

      <ResetButton filters={filterState} changeFilters={setFiltersState} />
    </StyledFilter>
  )
}

export const Filter = connect((store: IStore, state: OwnProps) => ({
  containerType: store.containerType,
  pickupLocation: store.location[state.filterState.pickupLocationId],
  returnLocation: store.location[state.filterState.returnLocationId]
}))(React.memo(Component) as any)
