import { SearchRequest } from '../searchRequest'
import { assembleDTO } from '../../../../../services/DTO/assemble'
import { callAPI, containerAPI } from '../../../../../api/api'
import * as React from 'react'
import { getValidationErrorMessage, TSearchField } from '../index'
import { newContainer } from '../../../../common/containers/reducers'
import { isNewObject } from '../../../../../services/DTO'
import { schemaContainerNumber } from '../../../../../services/yupScheme'
import { parseDTO } from '../../../../../services/DTO/parseDTO'
import { regExpOnlyNumbersAndLetters } from '../../../../../services/functions/regExp'

export const ContainerSearch = (props: TSearchField & { containerTypeId?: string; steamShipLineId?: string }) => {
  const { containerTypeId = null, steamShipLineId = null } = props
  const useFilters = true
  const getListRequest = (term: string) =>
    callAPI(
      containerAPI.search,
      useFilters ? containerTypeId : null,
      null,
      useFilters ? steamShipLineId : null,
      term
    ).toPromise()

  let getList = getListRequest

  if (props.allowCreate) {
    getList = (term: string) =>
      getListRequest(term).then(list => {
        if (term && !list.length) {
          return [
            {
              ...newContainer,
              number: term,
              containerTypeId,
              containerType: null,
              steamShipLineId
            }
          ]
        }

        return list
      })
  }

  return (
    <SearchRequest
      {...props}
      toUpperCase={true}
      label={props.value ? props.value.number : null}
      assembleObject={assembleDTO.container}
      termRegExp={regExpOnlyNumbersAndLetters}
      getList={getList}
      getDetails={value =>
        isNewObject(value) ? Promise.resolve(value) : callAPI(containerAPI.findById, value.id).toPromise()
      }
      addToStore={parseDTO.container}
      makeDropDownItem={response =>
        isNewObject(response)
          ? (() => {
              const errorMessage = getValidationErrorMessage(
                [
                  schemaContainerNumber.isValidSync(response.number),
                  Boolean(response.containerTypeId),
                  Boolean(response.steamShipLineId)
                ],
                ['• invalid number', '• Equipment must be set', '• SSL must be set']
              )

              return {
                disabled: Boolean(errorMessage),
                className: 'create validation' + (errorMessage ? ' invalid' : ''),
                before: 'Create',
                after: errorMessage,
                label: response.number,
                value: response
              }
            })()
          : {
              label: response.number,
              value: response
            }
      }
    />
  )
}
