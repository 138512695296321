import * as React from 'react'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TGridColumn } from '../../../grid/views/interfaces'
import { TableCell } from '../../../grid/views/styles'
import { transport } from '../../../../UI/Selecter/styledComponents'
import { powerUnitDirectory } from '../../../../../services/DTO/powerUnit/directory'

const Column = (props: TGridColumn) => {
  const { value, columnName, className, style } = props

  switch (columnName) {
    case 'Type':
      return (
        <TableCell className={className} style={style}>
          {value ? (
            <>
              <img style={{ marginRight: 10 }} src={transport[value]} width={20} height={30} alt={''} />
              {powerUnitDirectory.powerUnitType[value]}
            </>
          ) : (
            ''
          )}
        </TableCell>
      )
    case 'Active / Availability':
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className}>
          <AvailabilityColumn unit={value} />
        </TableCell>
      )
    default:
      return (
        <TableCell className={className} title={String(value)} style={style}>
          <div>{value || ''}</div>
        </TableCell>
      )
  }
}

export default Column
