import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

const Documents: React.SFC<{
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  actions: IGridItemActions
}> = ({ dispatchDeliveryOrder }) => (
  <DispatchDeliveryOrderTabContent>
    <Container rows={38} margin={{ top: 25, left: 60, right: 60 }} />
  </DispatchDeliveryOrderTabContent>
)

export default Documents
