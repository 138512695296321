import * as React from 'react'
import { connect } from 'react-redux'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { ApplicationContext, IStore } from '../../../store/store.interface'
import { TabActionType, TabType, TGridColumns } from '../../common/tabs/interfaces'
import { Preview } from './preview'
import Columns from './columns'
import { saveObject } from '../../../services/DTO/saveDTO'
import { isNewObject } from '../../../services/DTO'
import { IGridItemActions } from '../../../contexts/GridItemContext'
import { actionOnUnitExpand, getFullUnit } from './functions'
import { debuggingMode } from '../../../services/debug'
import {
  modifyTabViewingObject,
  deleteTabViewingObject,
  setTabViewingObjectData
} from '../../../services/viewingObjects/actions'
import { IViewingObject } from '../../../services/viewingObjects/interfaces'
import { localStorageService } from '../../../services/storageService/localStorage/LocalStorage'
import { ApplicationSizeProvider } from '../../../providers/ApplicationSizeProvider'
import { GridItemProvider } from '../../../providers/GridItemProvider'
import { useGrid } from '../../../hooks/useGrid'
import { gridPreviews } from './data'
import { whatIsIt } from '../../../services/DTO/functions'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectDriver } from '../../../store/select/driverSelect'

type OwnProps = {
  tabId: string
  tabType: TabType
  columnsSettings: TGridColumns
  enableEditing: boolean
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
}

type StateProps = {
  viewingObject?: IViewingObject
  isModified: boolean
}

type DispatchProps = {
  setTabViewingObjectData: typeof setTabViewingObjectData
  modifyTabViewingObject: typeof modifyTabViewingObject
  deleteTabViewingObject: typeof deleteTabViewingObject
}

export type TGridItemProps = StateProps & OwnProps & DispatchProps

const Component = (props: TGridItemProps) => {
  const {
    tabType,
    columnsSettings,
    viewingObject,
    enableEditing,
    isSelected,
    isDisabledSelect,
    isPinned,
    actionType,
    isModified
  } = props

  const mounted = React.useRef(true)
  const [fetching, setFetching] = React.useState<boolean | string>(false)
  const { tabId, detailsRequestType } = useGrid()
  const storeDriver = useAppSelector(selectDriver(viewingObject.id))
  const unit = viewingObject.modifiedObject || storeDriver
  const isNew = isNewObject(unit)
  const enableUnitEditing = unit && enableEditing
  const togglePreview = () => {
    if (!isModified) {
      props.deleteTabViewingObject({ tabId })
    }
  }

  const gridItemActiveTab = React.useMemo(() => {
    if (!unit) {
      return
    }

    if (viewingObject.data) {
      return viewingObject.data
    }

    // headisngs could depends on unit props so Fn is needed
    const arrayOfTabs = whatIsIt(gridPreviews[tabType].headings).isFunction
      ? (gridPreviews[tabType].headings as (unit: any) => string[])(unit)
      : (gridPreviews[tabType].headings as string[])

    return arrayOfTabs[0]
  }, [viewingObject.data, Boolean(unit)])

  React.useEffect(() => {
    if (!unit) {
      return
    }

    if (unit.id) {
      localStorageService.pushRecentForTabType(tabType, unit.id)
    }

    if (!fetching && !unit.fullObject) {
      setFetching(true)
      getFullUnit(detailsRequestType || tabType, unit.id).finally(() => {
        setFetching(false)
      })
    }
  }, [oc(unit).id()])

  React.useEffect(() => {
    if (!unit) {
      return
    }

    if (!isNew && unit.fullObject) {
      actionOnUnitExpand(detailsRequestType || tabType, unit)
    }
  }, [oc(unit).fullObject()])

  React.useEffect(() => {
    return () => {
      if (mounted && mounted.current) {
        mounted.current = false
      }
    }
  }, [])

  if (!unit) {
    return null
  }

  const actions: IGridItemActions = {
    enableEditing: enableUnitEditing && !oc(unit).error(),
    temporaryData: viewingObject.temporaryData,
    isModified,
    isFetching: Boolean(fetching),
    initialObjectState: oc(viewingObject).temporaryData.initialObjectState({}),
    setFetching: state => {
      if (mounted && mounted.current) {
        setFetching(state)
      }
    },
    togglePreviewTab: activeTab => {
      if (activeTab || !isModified) {
        props.setTabViewingObjectData({ tabId, data: activeTab })
      }
    },
    modify: (updatedUnit, updatedInitialState) => {
      if (enableUnitEditing === false) {
        return
      }

      let initialObjectState = isModified ? oc(viewingObject).temporaryData.initialObjectState({}) : R.clone(unit)

      if (updatedInitialState) {
        initialObjectState = R.clone(updatedInitialState)
      }

      props.modifyTabViewingObject({
        tabId,
        modifiedObject: isModified ? updatedUnit : R.clone(updatedUnit),
        initialObjectState
      })
    },
    modifyParentObjectField: field => (value, updatedInitialState) => {
      if (enableUnitEditing === false) {
        return
      }

      const clonedObject = isModified ? undefined : R.clone(unit)
      let initialObjectState = isModified ? oc(viewingObject).temporaryData.initialObjectState({}) : clonedObject

      if (updatedInitialState) {
        initialObjectState = R.clone(updatedInitialState)
      }

      props.modifyTabViewingObject({
        tabId,
        modifiedObject: {
          ...(isModified ? unit : clonedObject),
          [field]: value
        },
        initialObjectState
      })
    },
    cancel: () => {
      return isNew
        ? props.deleteTabViewingObject({ tabId })
        : props.modifyTabViewingObject({
            tabId,
            modifiedObject: undefined,
            initialObjectState: undefined
          })
    },
    save: () => {
      if (enableUnitEditing === false) {
        return
      }

      actions.setFetching(true)

      setTimeout(() =>
        saveObject[tabType](unit, {
          getUnitInfo: {
            applicationContext: ApplicationContext.main,
            tabId,
            activeTab: viewingObject.data,
            unitId: unit.id
          },
          setFetching: actions.setFetching,
          cancel: actions.cancel,
          reset: actions.cancel,
          modify: actions.modify,
          initialObjectState: oc(viewingObject).temporaryData.initialObjectState()
        })
      )
    },
    saveModifiedUnitAnyway: updatedUnit => {
      actions.setFetching(true)

      setTimeout(() => {
        saveObject[tabType](updatedUnit, {
          getUnitInfo: {
            applicationContext: ApplicationContext.main,
            tabId,
            activeTab: viewingObject.data,
            unitId: updatedUnit.id
          },
          setFetching: actions.setFetching,
          cancel: actions.cancel,
          reset: actions.cancel,
          modify: actions.modify,
          initialObjectState: oc(viewingObject).temporaryData.initialObjectState()
        })
      })
    }
  }

  if (debuggingMode.expandedGridItem) {
    // tslint:disable-next-line:no-console
    console.log('>>> Render Expanded Grid Item', unit)
    // tslint:disable-next-line:no-console
    console.log('>>> Render Expanded Grid Item Extra', {
      actions,
      unitType: tabType,
      isSelected,
      isPinned,
      actionType
    })
  }

  return (
    <GridItemProvider actions={actions}>
      <Columns
        columns={columnsSettings}
        isExpanded={true}
        unit={unit}
        unitType={tabType}
        togglePreview={togglePreview}
        isSelected={isSelected}
        isDisabledSelect={isDisabledSelect}
        isPinned={isPinned}
        actionType={actionType}
      />
      <ApplicationSizeProvider>
        <Preview
          fetching={fetching}
          gridItemActiveTab={gridItemActiveTab}
          unit={unit}
          unitType={tabType}
          isModified={isModified}
        />
      </ApplicationSizeProvider>
    </GridItemProvider>
  )
}

export const ViewingGridItemDriver = connect(
  (store: IStore, props: OwnProps) => {
    const viewingObject = store.viewingObjects[props.tabId]
    const modifiedUnit = viewingObject.modifiedObject

    return {
      viewingObject,
      isModified: Boolean(modifiedUnit)
    }
  },
  {
    setTabViewingObjectData,
    modifyTabViewingObject,
    deleteTabViewingObject
  }
)(Component)

// const showDataUpdatedMessage = (actionCancel: () => void) => {
//   const { dispatch, getState } = getStore()
//
//   if (!oc(getState()).modal.visible()) {
//     dispatch(
//       showModal({
//         msgType: TMsgType.info,
//         buttonSettings: {
//           button1: {
//             color: AlertButtonColor.yellow,
//             label: 'Ok',
//             action: () => {}
//           },
//           button2: {
//             color: AlertButtonColor.blue,
//             label: 'Discard my changes',
//             action: actionCancel
//           }
//         },
//         message:
//           'There is newer data for the object. Click “Ok” to continue or “Discard my changes” to apply newer data'
//       })
//     )
//   }
// }
