import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ILocation } from '../../../components/common/location/interfaces'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { getStore } from '../../../store/configureStore'
import { getContactsStatusSavePromise } from '../contact/save'
import { tryToSave, combineContactIds, createPromiseResolve, makeSavePromiseWithCatch } from '../saveDTO'
import { isNewObject, replaceOldGridItemWithNew } from '../index'
import { TContact } from '../../../components/common/contact/interfaces'
import { callAPI, locationAPI } from '../../../api/api'
import { getLocationStatus } from './status'
import { parseDTO } from '../parseDTO'
import { createAddressLine, getStateByCodes, getStateInfo } from '../../addressService'
import { locationControllerApi } from '../../../api/location'
import { showModalWindow, showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'
import { SelectLocation } from './SelectLocation'

export const getLocationSavePromise = (location: ILocation, actions?: IModifyGridItemActions): Promise<ILocation> => {
  if (!location || !location.fullObject) {
    return Promise.resolve(null)
  }

  const { dispatch, getState } = getStore()
  const storeLocation = getState().location[location.id]
  const { needToSave } = getLocationStatus(location)

  return getContactsStatusSavePromise(location.contacts).then(
    (contacts: TContact[]): any => {
      const result = {
        ...location,
        contactIds: combineContactIds(location.contactIds, contacts),
        primaryContactId: oc(contacts.find(_ => _.primary)).id(null)
      }

      return needToSave || !R.equals(storeLocation.contactIds, result.contactIds)
        ? (isNewObject(result)
            ? callAPI(locationAPI.create, R.omit(result, ['id', 'contacts', 'primaryContact']))
            : callAPI(locationAPI.update, R.omit(result, ['contacts', 'primaryContact']), result.id)
          )
            .toPromise()
            .then(data => {
              parseDTO.location(data)
              if (Boolean(actions)) {
                if (isNewObject(result)) {
                  replaceOldGridItemWithNew(data, actions.getUnitInfo)
                } else {
                  actions.reset()
                }
              }
              return data
            })
        : createPromiseResolve(result, actions)
    }
  )
}

export const saveLocation = async (_location: ILocation, actions: IModifyGridItemActions, placeIdPassed?: boolean) => {
  let location: ILocation = _location
  const prevLocationVersion = actions.initialObjectState as ILocation
  const save = () => makeSavePromiseWithCatch(actions, getLocationSavePromise(location, actions))

  if (!placeIdPassed) {
    let locationDataChanged = false
    const addressString = createAddressLine(location.address)
    const placeId = oc(location).placeId()
    const locationLatitude = oc(location).latitude()
    const locationLongitude = oc(location).longitude()

    if (prevLocationVersion) {
      const prevAddressString = createAddressLine(prevLocationVersion.address)
      locationDataChanged = prevAddressString !== addressString
    } else {
      locationDataChanged = true
    }

    if (locationDataChanged || !(placeId && locationLatitude && locationLongitude)) {
      const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

      if (searchResults.length) {
        const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId

        if (!isPlaceIdCorrect) {
          actions.setFetching(false)

          return showModalWindow({
            width: 445,
            title: 'Select location',
            content: (
              <SelectLocation
                selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
                locations={searchResults}
                onSelect={selectedPlaceId => {
                  if (selectedPlaceId) {
                    actions.setFetching(true)

                    locationControllerApi
                      .locationByPlaceId(selectedPlaceId)
                      .then(requestedLocation => {
                        const { latitude, longitude } = requestedLocation
                        const requestedAddress = oc(requestedLocation).address()

                        location = { ...location, placeId: selectedPlaceId, latitude, longitude }

                        if (requestedAddress) {
                          if (requestedAddress.city) {
                            location.address = {
                              ...location.address,
                              street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                              city: requestedAddress.city || location.address.city,
                              postalCode: requestedAddress.postalCode || location.address.postalCode,
                              stateId:
                                requestedAddress.stateCode && requestedAddress.countryCode
                                  ? getStateByCodes({
                                      stateCode: requestedAddress.stateCode,
                                      countryCode: requestedAddress.countryCode
                                    }).id
                                  : location.address.stateId
                            }
                          }
                        }

                        actions.modify(location)
                        saveLocation(location, actions, true)
                      })
                      .catch(() => {
                        actions.setFetching(false)

                        showErrorModalWindow({
                          title: 'Location search error'
                        })
                      })
                  }
                }}
              />
            ),
            buttons: [
              {
                label: 'Cancel'
              },
              {
                label: 'Apply',
                disabled: true
              }
            ]
          })
        }
      } else {
        actions.setFetching(false)
        return showErrorModalWindow({
          content: 'Location with the entered address is not found in Google'
        })
      }
    }
  }

  return tryToSave({
    condition: getLocationStatus(location).noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
