import * as React from 'react'
import { TGridColumn } from '../../../grid/views/interfaces'
import { NoWrap, TableCell } from '../../../grid/views/styles'

const Column = (props: TGridColumn) => {
  const { value, className, style } = props

  return (
    <TableCell className={className} title={String(value)} style={style}>
      <NoWrap>{value || ''}</NoWrap>
    </TableCell>
  )
}

export default Column
