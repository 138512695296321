import * as React from 'react'

export const useInputSearch = (initialValue: string) => {
  const [value, setValue] = React.useState(initialValue)
  const [isSearch, setIsSearch] = React.useState(true)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return { value, setValue, isSearch, setIsSearch }
}
