import * as React from 'react'
import { useAppSelector } from './useAppSelector'
import { selectDriverActivity } from '../store/select/driverActivitySelect'
import { selectDriver } from '../store/select/driverSelect'
import { ICalculatedDriverActivity } from '../services/DTO/driverActivity/interfaces'
import { getCalculatedDriverActivity } from '../services/functions/get/getCalculatedDriverActivity'

type Props = {
  driverIdOrAuthUserId: string
}

export const useDriverActivity = (props: Props): ICalculatedDriverActivity => {
  const { driverIdOrAuthUserId } = props
  const driver = useAppSelector(selectDriver(driverIdOrAuthUserId))
  const doCalculateRef = React.useRef(() => {})
  const driverActivity = useAppSelector(selectDriverActivity(driverIdOrAuthUserId))
  const [calculatedDriverActivity, setCalculatedDriverActivity] = React.useState(
    getCalculatedDriverActivity({ driver: undefined, driverActivity: undefined })
  )
  doCalculateRef.current = () => {
    setCalculatedDriverActivity(getCalculatedDriverActivity({ driver, driverActivity }))
  }

  // Last Active Date lable
  React.useEffect(() => {
    let interval: any = undefined

    doCalculateRef.current()

    if (driverActivity) {
      interval = setInterval(() => doCalculateRef.current(), 60 * 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [driver, driverActivity])

  return calculatedDriverActivity
}
