import * as React from 'react'
import { oc } from 'ts-optchain'
import { IDispatchDeliveryOrder } from '../../../../../../common/dispatchDeliveryOrder/interfaces'
import {
  BuySideQuoteRateDTO,
  CustomerQuoteDTO,
  SellSideQuoteRateDTO
} from '../../../../../../../api/origin/qmp-service'
import { getStore } from '../../../../../../../store/configureStore'
import { defaultTabData } from '../../../../../../../services/DTO/defaultTabData'
import { TabType } from '../../../../../../common/tabs/interfaces'
import { QMPTab } from '../../../../../../common/QMP/interfaces'
import { createNewTab } from '../../../../../../common/tabs/actions'
import { showModal, TMsgType } from '../../../../../Modal/actions'
import { AlertButtonColor } from '../../../../../Modal'
import { createCustomerQuote } from '../../../../../../../services/DTO/customerQuote/reducer'
import { resetObjectValues } from '../../../../../../common/QMP/views/Grid/Filter/resetButton'
import { quoteDirectory } from '../../../../../../../services/DTO/sellSideQuote/directory'
import { InputField } from '../../../../../DataFields/Input'
import { activityLists } from '../../../../../../../services/select/activityLists'
import { Select } from '../../../../../DataFields/Select'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../types'
import { createNewUnitId } from '../../../../../../../services/utils'
import { RateDTO } from '../../../../../../../services/DTO/rate/interfaces'
import {
  BuySideQuoteDTO,
  DeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../../../../../api/origin/business-logic'
import { HoursMinutes } from '../../../../../DataFields/Input/inputTypes/hoursMinutes'
import { getListState } from '../../../../../../../store'

export const fillQMPFiltersByDDOData = (dispatchDeliveryOrder: IDispatchDeliveryOrder) => {
  const deliveryLocationAddress = oc(dispatchDeliveryOrder as any).deliveryStage.location.address()
  return {
    deliveryOrderType: [dispatchDeliveryOrder.deliveryOrder.type],
    pickupLocationId: dispatchDeliveryOrder.pickupStage.locationId,
    deliveryLocation: deliveryLocationAddress
      ? {
          city: oc(deliveryLocationAddress).city(''),
          countryCode: '',
          postalCode: oc(deliveryLocationAddress).postalCode(''),
          stateId: oc(deliveryLocationAddress).stateId('')
        }
      : undefined,
    returnLocationId: dispatchDeliveryOrder.returnStage.locationId,
    customerId: dispatchDeliveryOrder.deliveryOrder.customerId,
    containerTypeId: dispatchDeliveryOrder.containerTypeId ? [dispatchDeliveryOrder.containerTypeId] : undefined,
    loadType: dispatchDeliveryOrder.loadType ? [dispatchDeliveryOrder.loadType] : undefined,
    status: [CustomerQuoteDTO.StatusEnum.NEW, CustomerQuoteDTO.StatusEnum.EXPIRED]
  }
}

export const makeCombinedSurchargeRate = (rate: RateDTO, surcharge: SurchargeDTO): CombinedSurchargeRateDTO => {
  // @ts-ignore
  return { ...rate, ...surcharge }
}

export const transformRateToSellSideQuoteSurcharge = (rate: RateDTO): SurchargeDTO => {
  const quote: SurchargeDTO = {
    id: createNewUnitId(),
    automatic: undefined,
    rateId: rate.id,
    amount: rate.amount,
    activityId: undefined,
    calculationType: rate.calculationType,
    quantity: 0,
    type: rate.type as any
  }

  if (rate.ruleIds && rate.ruleIds.length) {
    const storeRules = getListState().rule
    const rules = rate.ruleIds
      .map(ruleId => oc(storeRules[ruleId]).name())
      .filter(Boolean)
      .join(', ')

    if (rules.length) {
      quote.rules = rules
    }
  }

  return quote
}

export const toggleSurcharge = (
  surcharges: SurchargeDTO[],
  rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO
): SurchargeDTO[] => {
  const includesSelectedSurcharge = surcharges.find(surcharge => surcharge.id === rate.id)

  if (includesSelectedSurcharge) {
    return surcharges.filter(surcharge => surcharge.id !== rate.id)
  } else {
    return [...surcharges, transformRateToSellSideQuoteSurcharge(rate)]
  }
}

export const checkModifiedModeBeforeAction = (isModified: boolean, saveAction: () => void) => (action: () => void) => {
  const { dispatch } = getStore()
  if (isModified) {
    dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.yellow,
            label: 'Cancel',
            action: () => {}
          },
          button2: {
            color: AlertButtonColor.blue,
            label: 'Save',
            action: saveAction
          }
        },
        message:
          // tslint:disable:max-line-length
          'Before leaving, save your changes'
      })
    )
  } else {
    action()
  }
}

type OpenRatesTab = {
  filledFilters: any
  extraData?: any
}
export const openRatesTab = (props: OpenRatesTab) => () => {
  const { filledFilters, extraData } = props
  const { dispatch } = getStore()
  const defaultData = defaultTabData()[TabType.QMP]

  const tabData = {
    ...defaultData,
    extraData: {
      ...defaultData.extraData,
      [QMPTab.RATE]: extraData || {}
    },
    filters: {
      ...defaultData.filters,
      [QMPTab.RATE]: {
        ...defaultData.filters[QMPTab.RATE],
        ...filledFilters
      }
    }
  }

  dispatch(createNewTab({ tabType: TabType.QMP, data: tabData }))
}

export const openCustomerQuoteTab = (
  filledFilters: any,
  customerQuote: CustomerQuoteDTO,
  notCreateMode?: boolean,
  extraData?: any
) => () => {
  const { dispatch } = getStore()
  const defaultData = defaultTabData()[TabType.QMP]

  const newCustomerQuotes =
    notCreateMode || Boolean(customerQuote)
      ? undefined
      : {
          customerId: oc(filledFilters).customerId(),
          pickupLocationId: filledFilters.pickupLocationId,
          returnLocationId: filledFilters.returnLocationId,
          deliveryStateId: oc(filledFilters).deliveryLocation.stateId(),
          deliveryCity: oc(filledFilters).deliveryLocation.city(),
          deliveryPostalCode: oc(filledFilters).deliveryLocation.postalCode(),
          deliveryOrderType: filledFilters.deliveryOrderType as any,
          containerTypeId: filledFilters.containerTypeId as any,
          loadType: filledFilters.loadType as any,
          effectiveDate: oc(extraData).effectiveDate()
        }

  if (newCustomerQuotes) {
    const deliveryOrderTypes = oc(filledFilters).deliveryOrderType([])
    const isImport = deliveryOrderTypes.includes(DeliveryOrderViewDTO.TypeEnum.IMPORT)
    const isExport = deliveryOrderTypes.includes(DeliveryOrderViewDTO.TypeEnum.EXPORT)
    const isRepo = deliveryOrderTypes.includes(DeliveryOrderViewDTO.TypeEnum.REPOSITION)

    let requiredLocation: boolean = false

    if (isImport) {
      requiredLocation = Boolean(filledFilters.pickupLocationId)
    }
    if (isExport) {
      requiredLocation = Boolean(filledFilters.returnLocationId)
    }
    if (isRepo) {
      requiredLocation = Boolean(filledFilters.pickupLocationId && filledFilters.returnLocationId)
    }

    if (!requiredLocation || ((isImport || isExport) && !newCustomerQuotes.deliveryPostalCode)) {
      let errorText = ''

      const zipError =
        (newCustomerQuotes.deliveryStateId || newCustomerQuotes.deliveryCity) && !newCustomerQuotes.deliveryPostalCode

      if (isImport) {
        switch (true) {
          case !requiredLocation && zipError:
            errorText = `Pickup location and Delivery location ZIP code`
            break
          case !requiredLocation && !newCustomerQuotes.deliveryPostalCode:
            errorText = `Pickup and Delivery locations`
            break
          case !requiredLocation:
            errorText = `Pickup location`
            break
          case zipError:
            errorText = 'ZIP code for Delivery location'
            break
          case !newCustomerQuotes.deliveryPostalCode:
            errorText = 'Delivery location'
            break
          default:
        }
      }
      if (isExport) {
        switch (true) {
          case !requiredLocation && zipError:
            errorText = `Return location and Delivery location ZIP code`
            break
          case !requiredLocation && !newCustomerQuotes.deliveryPostalCode:
            errorText = `Return and Delivery locations`
            break
          case !requiredLocation:
            errorText = `Return location`
            break
          case zipError:
            errorText = 'ZIP code for Delivery location'
            break
          case !newCustomerQuotes.deliveryPostalCode:
            errorText = 'Delivery location'
            break
          default:
        }
      }
      if (isRepo) {
        switch (true) {
          case !requiredLocation && zipError:
            errorText = `Pickup location, Return location and Delivery location ZIP code`
            break
          case !requiredLocation:
            errorText = [
              !filledFilters.pickupLocationId ? 'Pickup location' : undefined,
              !filledFilters.returnLocationId ? 'Return location' : undefined
            ]
              .filter(Boolean)
              .join(', ')
            break
          case zipError:
            errorText = 'ZIP code for Delivery location'
            break
          default:
        }
      }

      return dispatch(
        showModal({
          msgType: TMsgType.info,
          buttonSettings: {
            button1: {
              color: AlertButtonColor.blue,
              label: 'Ok',
              action: () => {}
            }
          },
          message:
            // tslint:disable:max-line-length
            `You must set ${errorText} to create suitable Customer Quote`
        })
      )
    }
  }

  const tabData = {
    ...defaultData,
    activeTab: QMPTab.CUSTOMERQUOTE,
    [QMPTab.CUSTOMERQUOTE]: {
      ...defaultData[QMPTab.CUSTOMERQUOTE],
      newCustomerQuotes: newCustomerQuotes ? createCustomerQuote(newCustomerQuotes) : null,
      filters: {
        ...resetObjectValues(defaultData[QMPTab.CUSTOMERQUOTE].filters),
        ...filledFilters
      }
    }
  }
  dispatch(createNewTab({ tabType: TabType.QMP, data: tabData }))
}

export const makeActivityColumn = ({
  rate,
  activities,
  availableActivitiesToAttach,
  showIcon,
  updatedActivityId,
  disableActivityColumn
}: {
  rate: any
  activities: TransportationActivityViewDTO[]
  availableActivitiesToAttach: TransportationActivityViewDTO[]
  updatedActivityId: (rateId: string, value: any) => void
  showIcon: boolean
  disableActivityColumn?: boolean
}) => {
  const mapping = [
    { label: 'DDO', htmlLabel: <div style={{ fontWeight: 500 }}>DDO</div>, value: 'DDO' } as any,
    ...activityLists.makeTypeListWithIconAndActivityNumber(activities, showIcon)
  ]
  const actualList = [
    { label: 'DDO', htmlLabel: <div style={{ fontWeight: 500 }}>DDO</div>, value: 'DDO' } as any,
    ...activityLists.makeTypeListWithIconAndActivityNumber(availableActivitiesToAttach, showIcon)
  ]
  const selectedItem = mapping.find(({ value }: any) => value === rate.activityId)

  return (
    <Select
      mapping={mapping}
      disabled={disableActivityColumn}
      label={oc(selectedItem).htmlLabel('DDO')}
      selectedValue={rate.activityId}
      list={rate.activityId ? actualList : actualList.slice(1)}
      onSelect={value => updatedActivityId(rate.id, value === 'DDO' ? undefined : value)}
      dropdownStyle={{ minWidth: 220 }}
    />
  )
}

export const makeSurchargeCalculationTypeColumn = (
  allowChangeQuantity: boolean,
  rate: any,
  updateSurchargeQuantity: (rateId: string, value: number) => void
) => {
  const selectedCalcType = quoteDirectory.calculationType[rate.calculationType]

  if (allowChangeQuantity) {
    switch (rate.calculationType) {
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY:
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE:
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            {selectedCalcType}
            <div style={{ width: 67, paddingLeft: 5 }}>
              <InputField.Numeric
                value={rate.quantity}
                onChange={value => updateSurchargeQuantity(rate.id, value)}
                placeholder={'00'}
              />
            </div>
          </div>
        )
      case SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR:
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            {selectedCalcType}
            <div style={{ width: 135, paddingLeft: 5 }}>
              <HoursMinutes minutes={rate.quantity} onChange={value => updateSurchargeQuantity(rate.id, value)} />
            </div>
          </div>
        )
      default:
    }
  }

  return selectedCalcType
}

export const bsqTypeGroups = {
  delivery: [
    BuySideQuoteDTO.TypeEnum.LIVELOAD,
    BuySideQuoteDTO.TypeEnum.LIVEUNLOAD,
    BuySideQuoteDTO.TypeEnum.PICKFULL,
    BuySideQuoteDTO.TypeEnum.DROPFULL,
    BuySideQuoteDTO.TypeEnum.PICKEMPTY,
    BuySideQuoteDTO.TypeEnum.DROPEMPTY
  ],
  shuttle: [
    BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPEMPTY,
    BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPFULL,
    BuySideQuoteDTO.TypeEnum.SHUTTLERETURNEMPTY,
    BuySideQuoteDTO.TypeEnum.SHUTTLERETURNFULL
  ],
  live: [BuySideQuoteDTO.TypeEnum.LIVELOAD, BuySideQuoteDTO.TypeEnum.LIVEUNLOAD]
}
