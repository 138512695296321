import { IChannelMeta, IChannelsMeta } from '../interfaces'

export const sortChannelIds = (channelsMeta: IChannelsMeta, channelIds: string[]): string[] => {
  const channelsWithUpdatedAt: IChannelMeta[] = []
  const channelsWithoutUpdatedAt: string[] = []

  channelIds.forEach(channelId => {
    const channelMeta = channelsMeta[channelId]

    if (channelMeta && channelMeta.updatedAt) {
      channelsWithUpdatedAt.push(channelMeta)
    } else {
      channelsWithoutUpdatedAt.push(channelId)
    }
  })

  channelsWithUpdatedAt.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
  // const result = channelsWithUpdatedAt.map(({ id }) => id).concat(channelsWithoutUpdatedAt)

  // if (limit && result.length > limit) {
  //   result.length = limit
  // }

  return channelsWithUpdatedAt.map(({ id }) => id).concat(channelsWithoutUpdatedAt)
}
