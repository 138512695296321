import nanoid = require('nanoid')
import { omit } from 'remeda'

export const createId = (addToString: string = '') => addToString + 'fake_' + nanoid()

export const createNewUnitId = () => 'new_' + nanoid()

type NewItem = { id?: string }

export const isNewItem = (item: NewItem): boolean => item.id.includes('fake_')
export const isNotNewItem = (item: NewItem): boolean => !isNewItem(item)
export const deleteId = <T extends NewItem>(item: T) => omit(item, ['id'])
