import * as React from 'react'
import { connect } from 'react-redux'
import { TabActionType, TabType, TGridColumns } from '../../common/tabs/interfaces'
import Columns from './columns'
import { setTabViewingObject } from '../../../services/viewingObjects/actions'
import { IStore } from '../../../store/store.interface'
import { getGridItem } from '../../../services/DTO/assemble'
import { getStore } from '../../../store/configureStore'

type OwnProps = {
  unitId: string
  columnsSettings: TGridColumns
  disable: boolean
  tabId: string
  tabType: TabType
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
  handleGridItemVersion: (unit: any) => void
}

type StateProps = {
  dispatchDeliveryOrder?: any
  deliveryOrder?: any
  // activity?: any
  location?: any
  customer?: any
  businessPartner?: any
  driver?: any
  containerType?: any
  sellSideQuotes?: any
  buySideQuotes?: any
  powerUnit?: any
  equipment?: any
  container?: any
  contact?: any
  subClient?: any
  steamShipLine?: any
}

type DispatchProps = {
  setTabViewingObject: typeof setTabViewingObject
}

export type TGridItemProps = StateProps & OwnProps & DispatchProps

const Component = (props: TGridItemProps) => {
  const {
    unitId,
    columnsSettings,
    disable,
    tabId,
    tabType,
    isSelected,
    isDisabledSelect,
    isPinned,
    actionType,
    handleGridItemVersion
  } = props

  const unit = getGridItem({ store: getStore().getState(), tabType, id: unitId, simplify: true })

  if (!unit) {
    return null
  }

  React.useEffect(() => {
    handleGridItemVersion(unit)
  }, [unit.updatedAt, unit.version])

  const togglePreview = React.useCallback(() => {
    if (!disable) {
      props.setTabViewingObject({ tabId, viewingObject: { id: unitId } })
    }
  }, [disable, unitId])

  return (
    <Columns
      columns={columnsSettings}
      unit={unit}
      unitType={tabType}
      togglePreview={togglePreview}
      isSelected={isSelected}
      isDisabledSelect={isDisabledSelect}
      isPinned={isPinned}
      actionType={actionType}
    />
  )
}

export const OldGridItem = connect(
  (store: IStore) => ({
    dispatchDeliveryOrder: store.dispatchDeliveryOrder,
    deliveryOrder: store.deliveryOrder,
    // activity: store.activity,
    location: store.location,
    customer: store.customer,
    businessPartner: store.businessPartner,
    containerType: store.containerType,
    sellSideQuotes: store.sellSideQuotes,
    buySideQuotes: store.buySideQuotes,
    powerUnit: store.powerUnit,
    equipment: store.equipment,
    container: store.container,
    // contact: store.contact,
    subClient: store.subClient,
    steamShipLine: store.steamShipLine
  }),
  { setTabViewingObject }
)(React.memo(Component))
