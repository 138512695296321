import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { IStore } from '../../../../store/store.interface'
import { DispatchDeliveryOrderColorStatusIcon, Icon, IconMode, IconName } from '../Icon'
import { UserAvatar, UserAvatarSize } from '../UserAvatar'
import theme from '../../../../styles/theme'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { IDispatchDeliveryOrder } from '../../../common/dispatchDeliveryOrder/interfaces'
import { generateDispatchDeliveryOrderChatChannel } from '../../functions'
import { getStore } from '../../../../store/configureStore'
import { TabType } from '../../../common/tabs/interfaces'
import { SchedulerFilter, SchedulerSortBy } from '../../../common/scheduler/interfaces'
import {
  ChannelNamePlaceholder,
  ChannelType,
  CommunicationHubTab,
  ICHubChannel
} from '../../../../store/reducers/communicationHub/interfaces'
import { UserDTO } from '../../../../api/origin/user-service'
import { getNewStoreState } from '../../../../store'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { selectDriver } from '../../../../store/select/driverSelect'
import {
  selectCommunictaionHubChannel,
  selectCommunictaionHubChannelHasNewMasseges,
  selectCommunictaionHubChannelMentionsCount,
  selectCommunictaionHubIsActiveChannelId,
  selectCommunictaionHubIsChannelMuted,
  selectCommunictaionHubTab
} from '../../../../store/select/communicationHubSelect'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectContact } from '../../../../store/select/contactSelect'
import { selectRouteBuilderIsActiveSelectedVendor } from '../../../../store/select/routeBuilderSelect'
import { useDriverActivity } from '../../../../hooks/useDriverActivity'

type OwnProps = {
  channelId: string
  hasDraftMessage: boolean
  isRecentOpenedChannel: boolean
  search?: string
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder | undefined
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = React.memo((props: Props) => {
  const { isRecentOpenedChannel, channelId, hasDraftMessage, dispatchDeliveryOrder, search } = props
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectCommunictaionHubTab)
  const driver = useAppSelector(selectDriver(channelId))
  const user = useAppSelector(
    selectContact(activeTab === CommunicationHubTab.routeBuilder && driver ? driver.authUserId : channelId)
  )
  const containerRef = React.useRef(null)
  const communicationHubChannel = useAppSelector(selectCommunictaionHubChannel(channelId))
  const hasUnreadMessages = useAppSelector(selectCommunictaionHubChannelHasNewMasseges(channelId))
  const isMuted = useAppSelector(selectCommunictaionHubIsChannelMuted(channelId))
  const unreadMentionCount = useAppSelector(selectCommunictaionHubChannelMentionsCount(channelId))
  const active = useAppSelector(
    activeTab === CommunicationHubTab.routeBuilder
      ? selectRouteBuilderIsActiveSelectedVendor({ vendorId: channelId })
      : selectCommunictaionHubIsActiveChannelId(channelId, isRecentOpenedChannel)
  )
  const channel = React.useMemo(
    () =>
      dispatchDeliveryOrder ? generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder) : communicationHubChannel,
    [dispatchDeliveryOrder, communicationHubChannel]
  )
  const channelName = oc(driver).name('') || oc(user).name('') || oc(channel).name('')
  const channelSubName = user ? undefined : oc(channel).subName()
  const { showGeneratedLastActiveDate } = useDriverActivity({ driverIdOrAuthUserId: channelId })

  React.useEffect(() => {
    if (active && containerRef.current) {
      containerRef.current.scrollIntoView()
    }
  }, [])

  if (search && !channelName.toUpperCase().includes(search.toUpperCase())) {
    return null
  }

  // const toggleChannelMuteState = React.useCallback(
  //   (event: any) => {
  //     preventEvent(event)

  //     props.setChannelIdMuteState({ channelId, mute: !isMuted })

  //     const action = isMuted ? unmuteChannel : muteChannel

  //     action(channelId).catch(() => {
  //       props.setChannelIdMuteState({ channelId, mute: isMuted })
  //     })
  //   },
  //   [isMuted, props.setChannelIdMuteState]
  // )

  // <IconMute className={cn('mdi mdi-bell-off', { active: isMuted })} onClick={toggleChannelMuteState} />

  return (
    <NavigationChannelContainer
      ref={containerRef}
      className={cn({ active, 'has-unread-messages': hasUnreadMessages })}
      onClick={
        active
          ? undefined
          : () => {
              if (activeTab === CommunicationHubTab.messenger) {
                leaveChatChannelIfNoEditing(() =>
                  dispatch(
                    communicationHubActions.setActiveChannel({
                      activeChannel: {
                        id: channelId,
                        updateUnreadCount: false,
                        isRecentOpenedChannel
                      },
                      omitRecentChannelsUpdate: isRecentOpenedChannel
                    })
                  )
                )
              } else if (activeTab === CommunicationHubTab.routeBuilder && user) {
                const {
                  tabs: { activeMainTabId, main }
                } = getStore().getState()
                const {
                  communicationHub: { activeRouteBuilder }
                } = getNewStoreState()

                if (activeRouteBuilder && activeRouteBuilder.vendorId) {
                  dispatch(
                    communicationHubActions.setActiveRouteBuilderVendor({
                      vendorId: driver.id,
                      authUserId: driver.authUserId
                    })
                  )
                } else {
                  const activeSchedulerTabSettings = oc(
                    main.find(tab => tab.id === activeMainTabId && tab.type === TabType.scheduler)
                  ).uiSettings()
                  const schedulerTabDate =
                    activeSchedulerTabSettings &&
                    activeSchedulerTabSettings.filter &&
                    (oc(
                      activeSchedulerTabSettings.filter.find(({ column }) => column === SchedulerFilter.specificDate)
                    ).value() as string)
                  const schedulerTabSorting = activeSchedulerTabSettings && activeSchedulerTabSettings.sorting

                  dispatch(
                    communicationHubActions.setActiveRouteBuilderVendor({
                      vendorId: driver.id,
                      authUserId: driver.authUserId,
                      date: [
                        SchedulerSortBy.date,
                        SchedulerSortBy.deliveryLocationCity,
                        SchedulerSortBy.deliveryStageDriver
                      ].includes(schedulerTabSorting)
                        ? schedulerTabDate
                        : undefined
                    })
                  )
                }
              }
            }
      }
    >
      <Box style={{ marginRight: 8 }}>
        {user ? (
          <UserAvatar user={user} size={UserAvatarSize.small} />
        ) : oc(channel).type() === ChannelType.dispatchDeliveryOrder ? (
          <DispatchDeliveryOrderColorStatusIcon status={oc(channel).metadata.status()} />
        ) : null
        // (
        //   <Icon icon={getIconNameByChannelType(channelType)} mode={IconMode.LIGHT} />
        // )
        }
      </Box>
      <ChannelName>
        {channelSubName ? (
          <>
            <FirstLineChannelName>{channelName}</FirstLineChannelName>
            <SecondLineChannelName
              className={cn({ placeholderLongLine: channelSubName === ChannelNamePlaceholder.placeholderLongLine })}
            >
              {channelSubName}
            </SecondLineChannelName>
          </>
        ) : (
          channelName
        )}
      </ChannelName>
      <ChannelIndicators>
        {showGeneratedLastActiveDate && <LastActivityDate>{showGeneratedLastActiveDate}</LastActivityDate>}
        {isMuted && <Icon icon={IconName.BELL_OFF} fontSize={14} mode={active ? IconMode.DARK : IconMode.LIGHT} />}
        {Boolean(unreadMentionCount) && <UnreadMentionMessagesCount>{unreadMentionCount}</UnreadMentionMessagesCount>}
        {Boolean(hasDraftMessage && !active) && <Icon icon={IconName.PENCIL} mode={IconMode.LIGHT} />}
      </ChannelIndicators>
    </NavigationChannelContainer>
  )
})

export const NavigationChannel = connect((store: IStore, { channelId }: OwnProps) => {
  return {
    dispatchDeliveryOrder: store.dispatchDeliveryOrder[channelId]
  }
})(Component)

const Box = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`

const IconBox = styled(Box)`
  height: 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.active,
  &:hover {
    color: white;
  }

  &:hover {
    opacity: 0.7;
  }

  &.active {
    height: 24px;
    opacity: 1 !important;
  }
`
const IconMute = styled(IconBox)`
  font-size: 16px;
  opacity: 0.3;
  padding: 0 3px;
  cursor: pointer;
`

export const NavigationChannelContainer = styled.div`
  position: relative;
  min-height: 38px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #c9cddf;
  border-radius: 4px 0 0 4px;
  padding: 3px 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);

    ${IconMute} {
      height: 24px;
    }
  }

  &:not(.active).has-unread-messages {
    font-weight: 700;
    color: white;
  }

  &.active {
    background-color: #fcfdff;
    color: #06182f;
    cursor: default;
  }

  &:not(.active) .user-icon:before {
    border-color: #06182f;
  }
`
const ChannelNameTextLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ChannelName = styled(ChannelNameTextLine)`
  line-height: 1.2;
  padding-right: 4px;
`
const FirstLineChannelName = styled(ChannelNameTextLine)`
  font-size: 12px;
  line-height: 14px;
`
const SecondLineChannelName = styled(ChannelNameTextLine)`
  font-size: 11px;
  font-weight: 300;
  line-height: 1;

  &.placeholderLongLine {
    font-weight: 400;
    letter-spacing: -3px;
  }
`
const ChannelIndicators = styled(Box)`
  margin-left: auto;
`
export const UnreadMentionMessagesCount = styled.div`
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 500;
  background-color: ${theme.colors.defaultRed};
  border-radius: 8px;
  padding: 0 3px;
`
const LocationPlaceholderLine = styled.span`
  display: inline-flex;
  align-items: center;

  &::after {
    content: '――――';
  }
`
const LastActivityDate = styled.div`
  font-size: 11px;
  color: rgba(177, 181, 193, 0.9);
  user-select: none;

  ${NavigationChannelContainer}.active & {
    color: rgba(68, 83, 102, 0.8);
  }
`
