import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../../styles/theme'
import { Widget } from '../../../'
import { AddressDTO, ContactDTO, LocationViewDTO } from '../../../../../../api/origin/business-logic'
import { ILocation } from '../../../../../common/location/interfaces'
import { oc } from 'ts-optchain'
import { updateAddressObject } from '../functions'
import { createNewContact } from '../../../../../common/contact/reducers'
import { Window } from './preview'
import { Popover } from '../popover'
import { checkLocationValidation } from '../../../../../../services/DTO/location'
import { makeLocationTitle } from '../../../../../../services/DTO/location/functions'
import { isLocationGEOValid } from '../../../../../../services/functions/test/isLocationGEOValid'

export const StyledWindow = styled.span`
  padding-right: 20px;

  &:before {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: ${theme.colors.basicBlueColor} !important;
    font-size: 18px !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
`

type Props = {
  disableWidget?: boolean
  widgetTitle?: string
  isExternal?: boolean
  disabled?: boolean
  location: ILocation
  updateLocation: (value: ILocation) => void
  changeLocation: (value?: ILocation, updateInitialState?: boolean) => void
  required?: boolean
  isModifiedMode?: boolean
  isShortName?: boolean
}

export const LocationWidget: React.FC<Props> = ({
  disableWidget,
  widgetTitle,
  isExternal,
  disabled,
  updateLocation,
  location,
  changeLocation,
  required,
  isModifiedMode,
  isShortName
}) => {
  const isLocationGEOInvalid = isLocationGEOValid(location) === false
  const locationAddress = oc(location).address(null)
  const locationObject = {
    id: oc(location).id(''),
    status: oc(location).status(LocationViewDTO.StatusEnum.FULL),
    name: oc(location).name(''),
    code: oc(location).code(''),
    longName: oc(location).longName(''),
    shortName: oc(location).shortName(''),
    street: oc(location).address.street(''),
    type: oc(location).type(null),
    city: oc(location).address.city(''),
    stateId: oc(location).address.stateId(''),
    zip: oc(location).address.postalCode(''),
    notes: oc(location).notes(''),
    company: oc(location).careOf(''),
    mismatchIndicator: oc(location).mismatchIndicator(null),
    cityStateZip: oc(location).cityStateZip('')
  }

  let title = ''
  if (location) {
    if (isShortName) {
      title = location.fullObject
        ? locationObject.code || locationObject.name || makeLocationTitle(location)
        : locationObject.shortName || locationObject.longName || locationObject.name
    } else {
      title = location.fullObject
        ? makeLocationTitle(location)
        : [oc(location).shortName(''), oc(location).cityStateZip('')].filter(Boolean).join(', ')
    }
  }

  return (
    <Widget
      disable={disableWidget && !isLocationGEOInvalid}
      red={isLocationGEOInvalid}
      title={widgetTitle || 'Location'}
      isExternal={isExternal}
      highlighted={isLocationGEOInvalid || (location && location.fullObject && !checkLocationValidation(location))}
      required={required}
      value={location}
      isTableWidget={true}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        title,
        disabled: disableWidget
      }}
      detailsProps={{
        disableEditing: disableWidget,
        disabled,
        updateLocation: changeLocation,
        location,
        isModifiedMode
      }}
    />
  )
}
