import { equipmentAPI, callAPI, EquipmentDTO } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { addEquipmentsToStore } from '../action'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'

export const tryAddEquipmentToStore = (items: EquipmentDTO | EquipmentDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().equipment, items, addEquipmentsToStore)
}

export const getEquipment = async (id: string): Promise<EquipmentDTO> => {
  const { getState } = getStore()
  const store = getState()
  const equipment = store.equipment[id]
  const doRequest = id && !equipment

  if (doRequest) {
    return getEquipmentById(id)
  }

  return equipment
}

export const getEquipmentById = (id: string): Promise<EquipmentDTO> =>
  callAPI(equipmentAPI.findById, id)
    .toPromise()
    .then(equipment => {
      tryAddEquipmentToStore(equipment)
      return equipment
    })

export const findAllEquipments = async ({ sort, filter }: any): Promise<EquipmentDTO[]> => {
  return callAPI(equipmentAPI.findAll, filter, sort)
    .toPromise()
    .then(equipments => {
      tryAddEquipmentToStore(equipments)
      return equipments
    })
    .catch(catchListErrors)
}
