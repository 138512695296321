import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ContainerDTO } from '../../../../api/origin/business-logic'
import { IContainerState, IContainer } from '../interfaces'
import { addContainersToStore } from '../action'

export const newContainer: IContainer = {
  fullObject: true,
  id: 'newContainer',
  containerTypeId: null,
  containerType: { name: '', id: null, isoCode: '', code: null },
  number: '',
  active: true,
  unavailabilityReason: null,
  inactiveReason: null,
  availability: true,
  weightUnit: ContainerDTO.WeightUnitEnum.LBS,
  specs: '',
  ownership: ContainerDTO.OwnershipEnum.LINEOWNED
}

const containerState: IContainerState = {}

const containerReducer = reducerWithInitialState<IContainerState>(containerState)
  .case(addContainersToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default containerReducer
