import * as React from 'react'
import { getStore } from '../../../../store/configureStore'
import { SearchByList } from '../SearchByList'
import { StateDTO } from '../../../../api/origin/business-logic'

type Props = {
  title?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  stateId: string | null | undefined
  onChange: (stateId: string) => void
  showCode?: boolean
}

export const StateSearch = ({ title, required, stateId, onChange, disabled, showCode, placeholder }: Props) => {
  const states = getStore().getState().state

  const createStateLabel = ({ id, state, onlyCode }: { id?: string; state?: StateDTO; onlyCode?: boolean }): string => {
    const { code, name } = state || states[id] || { code: 'Code', name: 'Name' }
    return onlyCode ? code : `${code} – ${name}`
  }
  const stateList = Object.values(states).reduce(
    (acc, curr) => [...acc, { label: createStateLabel({ state: curr }), value: curr.id }],
    []
  )

  return (
    <SearchByList
      title={title}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      label={stateId ? createStateLabel({ id: stateId, onlyCode: showCode }) : null}
      dropDownList={stateList}
      onChange={onChange}
    />
  )
}
