import * as React from 'react'
import styled from 'styled-components'
import { Container } from '../../../../../UI/GridSystem'
import { Delete } from '../../../../../UI/styledComponents'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { RepairDTO } from '../../../../../../api/origin/business-logic'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateType, DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { Select } from '../../../../../UI/DataFields/Select'
import { Row } from '../../../../contact/views/styledComponents'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { equipmentLists } from '../../../../../../services/select/equipmentLists'
import { IEquipment } from '../../../interfaces'

const StyledRow = styled(Row)`
  > div > div {
    width: 100%;
  }
  .date-picker {
    transform: none;
  }
`

const isValidDescription = createValidator(2, 512)

const RepairRow: React.SFC<{
  repair: RepairDTO
  changeRepair: (fieldName: keyof RepairDTO) => (value: string | number | DateType | { id: string }) => void
  deleteRepair: (id: string) => void
  setFetching: (fetching: boolean | string) => void
}> = ({ repair, changeRepair, deleteRepair, setFetching }) => (
  <StyledRow rows={9} margin={{ bottom: 10 }}>
    <Container columns={8} margin={{ left: 20 }}>
      <DateField
        required={true}
        showTime={true}
        date={repair.startDate}
        dateType={DateTypes.DateISOString}
        placeholder={'MM/DD/YYYY'}
        onUpdate={changeRepair('startDate')}
      />
    </Container>
    <Container columns={8}>
      <DateField
        required={true}
        showTime={true}
        date={repair.finishDate}
        dateType={DateTypes.DateISOString}
        placeholder={'MM/DD/YYYY'}
        onUpdate={changeRepair('finishDate')}
      />
    </Container>
    <Container columns={6}>
      <Select
        required={true}
        list={equipmentLists.repairType}
        selectedValue={repair.repairType}
        onSelect={value => changeRepair('repairType')(value as any)}
      />
    </Container>
    <Container columns={6}>
      <Input
        required={true}
        value={repair.description}
        maxLength={512}
        valid={isValidDescription(repair.description)}
        onChange={changeRepair('description')}
      />
    </Container>
    <Container columns={6}>
      <Input value={repair.shopName} maxLength={12} onChange={changeRepair('shopName')} />
    </Container>
    <Container columns={5}>
      <InputField.Money value={repair.costEstimate} onChange={changeRepair('costEstimate')} />
    </Container>
    <Container columns={5}>
      <InputField.Money value={repair.billActual} onChange={changeRepair('billActual')} />
    </Container>
    <Container columns={5}>
      <InputField.Integer value={repair.equipmentHours} onChange={changeRepair('equipmentHours')} />
    </Container>

    <Container columns={6}>
      <ViewingObjectFileUploader
        uploadingFileId={repair.id + ' repair.receiptDocumentFile'}
        file={repair.receiptDocumentFile}
        onFileUploaded={(receiptDocumentFile, modifiedObject) => ({
          ...modifiedObject,
          repairs: (modifiedObject as IEquipment).repairs.map(item =>
            item.id === repair.id ? { ...item, receiptDocumentFile } : item
          )
        })}
        onClearFile={() => changeRepair('receiptDocumentFile')(undefined)}
      />
    </Container>

    <Delete className="mdi mdi-delete" columns={2} isFixed={true} onClick={() => deleteRepair(repair.id)} />
  </StyledRow>
)

export default RepairRow
