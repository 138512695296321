import * as React from 'react'
import { TitleWithLink, RateTable } from './'
import styled from 'styled-components'
import { TransportationActivityViewDTO } from '../../../../../../../../../api/origin/business-logic'
import { makeCombinedSurchargeRate, toggleSurcharge } from '../../functions'
import { SellSideQuoteRateDTO } from '../../../../../../../../../api/origin/qmp-service'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../types'
import { RateDTO } from '../../../../../../../../../services/DTO/rate/interfaces'
import { connect } from 'react-redux'
import { IStore } from '../../../../../../../../../store/store.interface'

type OwnProps = {
  multipleChoice: boolean
  disableActivityColumn?: boolean
  attachedSurcharges: SurchargeDTO[]
  applicableRates: RateDTO[]
  surcharges: SurchargeDTO[]
  updateSurcharges: (surcharges: SurchargeDTO[]) => void
  updateSurchargeQuantity: (surchargeId: string, quantity: number) => void
  openRateTab: () => void
  openRateNumber: (rateNumber: string) => void
  transportationActivities?: TransportationActivityViewDTO[]
}

type StateProps = {
  attachedSurchargesFullData?: CombinedSurchargeRateDTO[]
}

type Props = OwnProps & StateProps

export const ApplicableRatesBlock = connect((store: IStore, props: OwnProps) => {
  const attachedSurchargesFullData = props.attachedSurcharges.map(surcharge => {
    const rate = store.ssqRates[surcharge.rateId] || store.bsqRates[surcharge.rateId]

    return rate ? makeCombinedSurchargeRate(rate as RateDTO, surcharge) : (surcharge as CombinedSurchargeRateDTO)
  })
  return { attachedSurchargesFullData }
})((props: Props) => {
  const {
    multipleChoice,
    disableActivityColumn,
    applicableRates,
    updateSurcharges,
    updateSurchargeQuantity,
    surcharges,
    openRateTab,
    openRateNumber,
    transportationActivities,
    attachedSurchargesFullData
  } = props
  const toggleRate = (rate: SellSideQuoteRateDTO) => updateSurcharges(toggleSurcharge(surcharges, rate))
  const updateSurchargeActivityId = (surchargeId: string, activityId: string) =>
    updateSurcharges(
      surcharges.map(surcharge => (surcharge.id === surchargeId ? { ...surcharge, activityId } : surcharge))
    )

  return (
    <StyledCustomerQuoteBlock>
      <TitleWithLink
        title={'Applicable Rates'}
        link={{
          label: 'Go to Rates',
          onClick: openRateTab
        }}
      />
      <RateTable
        disableActivityColumn={disableActivityColumn}
        rates={[
          ...attachedSurchargesFullData,
          ...(multipleChoice
            ? applicableRates
            : applicableRates.filter(rate =>
                attachedSurchargesFullData.every(surcharge => surcharge.rateId !== rate.id)
              ))
        ]}
        transportationActivities={transportationActivities}
        toggleRate={toggleRate}
        openRateNumber={openRateNumber}
        updateSurchargeActivityId={updateSurchargeActivityId}
        updateSurchargeQuantity={updateSurchargeQuantity}
      />
    </StyledCustomerQuoteBlock>
  )
})

const StyledCustomerQuoteBlock = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`
