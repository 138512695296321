import { IPowerUnit } from '../../../components/common/powerUnit/interfaces'
import {
  schemaPowerUnit,
  schemaDotSafetyInspection,
  schemaInsurance,
  schemaPowerUnitRegistration
} from '../../yupScheme'
import * as yup from 'yup'
import { debuggingMode } from '../../debug'

const businessLogicValidationPowerUnit = schemaPowerUnit.shape({
  dotSafetyInspection: schemaDotSafetyInspection.shape({
    expirationDate: yup.string().required(),
    performedDate: yup.string().required(),
    documentFile: yup.object().required()
  }),
  insurance: schemaInsurance.shape({
    carrier: yup
      .string()
      .min(2)
      .max(64)
      .required(),
    policyNumber: yup
      .string()
      .min(8)
      .max(24)
      .required(),
    insuranceType: yup.mixed().oneOf(['LIABILITY', 'PROPERTY', 'BOBTAIL']),
    expirationDate: yup.string().required(),
    coverageCardFile: yup.object().required()
  }),
  licensePlate: yup
    .string()
    .min(6)
    .max(12)
    .required(),
  maintenanceInspectionFrequency: yup.mixed().oneOf(['MONTHLY', 'QUARTERLY', 'SEMI_ANNUALLY', 'ANNUALLY', 'OTHER']),
  make: yup
    .string()
    .min(2)
    .max(64)
    .required(),
  model: yup
    .string()
    .min(2)
    .max(64)
    .required(),
  powerUnitSubType: yup.string().required(),
  registration: schemaPowerUnitRegistration.shape({
    expirationDate: yup.string().required(),
    registeredDate: yup.string().required(),
    stateId: yup.string().required(),
    documentFile: yup.object().required()
  }),
  vin: yup
    .string()
    .min(11)
    .max(17)
    .required(),
  weight: yup.number().required(),
  year: yup
    .number()
    .min(1900)
    .max(2100)
    .required()
})

export const checkPowerUnitValidation = (powerUnit: IPowerUnit): boolean => {
  if (debuggingMode.common) {
    businessLogicValidationPowerUnit.validate(powerUnit).catch(e =>
      //   getStore().dispatch(
      //     addServerMessage({
      //       type: 'error',
      //       message: e.message
      //     })
      //   )
      // tslint:disable-next-line:no-console
      console.log('checkPowerUnitValidation', e)
    )
  }
  return businessLogicValidationPowerUnit.isValidSync(powerUnit)
}
