import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/origin/business-logic'

export type ActivityGroup = TransportationActivityGroup | DocumentationActivityGroup

export enum ActivityViewType {
  transportation = 'transportation',
  documentation = 'documentation'
}

export type TransportationActivityGroup = {
  gotoActivity: TransportationActivityViewDTO
  businessActivity: TransportationActivityViewDTO
}

export type DocumentationActivityGroup = {
  documentationActivity: DocumentationActivityDTO
}
