import * as React from 'react'
import General from '../Details/General'
import Activities from '../Details/Activities'
import Documents from '../Details/Documents'
import Description from '../Details/Description'
import { AuditTrail } from '../Details/AuditTrail'
import { TerminalNotes } from '../Details/TerminalNotes'
import { IDispatchDeliveryOrder } from '../../interfaces'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { DDOContext } from '../../../../../services/DTO/dispatchDeliveryOrder/context'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.DispatchDeliveryOrder

export const DispatchDeliveryOrderPreview = {
  headings: [T.General, T.Activities, T.Description, T.AuditTrail, T.TerminalNotes],
  tabs: {
    [T.General]: (dispatchDeliveryOrder: IDispatchDeliveryOrder, actions: IGridItemActions) => (
      <General dispatchDeliveryOrder={dispatchDeliveryOrder} actions={actions} />
    ),
    [T.Activities]: (dispatchDeliveryOrder: IDispatchDeliveryOrder, actions: IGridItemActions) => (
      <ActivityTab dispatchDeliveryOrder={dispatchDeliveryOrder} actions={actions} />
    ),
    [T.Documents]: (dispatchDeliveryOrder: IDispatchDeliveryOrder, actions: IGridItemActions) => (
      <Documents dispatchDeliveryOrder={dispatchDeliveryOrder} actions={actions} />
    ),
    [T.Description]: (dispatchDeliveryOrder: IDispatchDeliveryOrder, actions: IGridItemActions) => (
      <Description dispatchDeliveryOrder={dispatchDeliveryOrder} actions={actions} />
    ),
    [T.AuditTrail]: (dispatchDeliveryOrder: IDispatchDeliveryOrder, actions: IGridItemActions) => (
      <AuditTrail dispatchDeliveryOrder={dispatchDeliveryOrder} actions={actions} />
    ),
    [T.TerminalNotes]: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => (
      <TerminalNotes dispatchDeliveryOrder={dispatchDeliveryOrder} />
    )
  },
  isValid: checkValidation.dispatchDeliveryOrder
}

const ActivityTab = ({
  actions,
  dispatchDeliveryOrder
}: {
  actions: IGridItemActions
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}) => {
  const {
    saveButton,
    functions: { updateContainer, updateContainerType },
    state
  } = React.useContext(DDOContext)

  return (
    <Activities
      isDDOValid={state.isDDOValid}
      dispatchDeliveryOrder={dispatchDeliveryOrder}
      actions={actions}
      saveButton={saveButton}
      required={{ chassisNumber: state.required.chassisNumber }}
      functions={{
        updateContainer,
        updateContainerType
      }}
    />
  )
}
