import {
  ActivitiesDTO,
  ActivitiesViewDTO,
  ContainerTypeDTO,
  ContainerViewDTO,
  DateISOString,
  DateTimeRangeDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { oc } from 'ts-optchain'
import { isNewObject } from '../index'
import { IGridItemActions } from '../../../contexts/GridItemContext'
import { getStore } from '../../../store/configureStore'
import { showModal, TMsgType } from '../../../components/UI/Modal/actions'
import { AlertButtonColor } from '../../../components/UI/Modal'
import * as R from 'remeda'
import { omitDDOFields, omitFieldsOnStages } from './status'
import { ActivityGroup, DocumentationActivityGroup, TransportationActivityGroup } from '../activity/interfaces'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { IDeliveryOrder } from '../../../components/common/deliveryOrder/interfaces'
import { DDOWorkingStatus } from '../activity/controller'
import { isBusinessActivity } from '../../functions/test/isBusinessActivity'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isUnsuccessfulActivityGroup } from '../../functions/test/isUnsuccessfulActivity'

export const isChassisNumberRequired = (activities: ActivitiesDTO): boolean => {
  // required if completed activity is the last one of actyvity list
  // or
  // has particular type:
  // DROPCHASSIS | DROPEMPTYWITHOUTCHASSIS | DROPFULLWITHOUTCHASSIS

  if (!activities || !activities.transportationActivities) {
    return false
  }

  const searchActivityTypes = [
    TransportationActivityViewDTO.TypeEnum.DROPCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ]
  const lastCompletedBusinessActivity = activities.transportationActivities
    .slice()
    .reverse()
    .find(activity => {
      return isBusinessActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    })

  return Boolean(
    lastCompletedBusinessActivity &&
      (!searchActivityTypes.includes(lastCompletedBusinessActivity.type) ||
        lastCompletedBusinessActivity.number === activities.transportationActivities.length - 1)
  )
}

export const findActivityWithWrongOrderOfPlannedDate = (
  activities: ActivityViewDTO[]
): TransportationActivityViewDTO => {
  const sortedActivities = activities
    .filter(
      activity =>
        activity.type !== DocumentationActivityDTO.TypeEnum.STREETTURN &&
        activity.startPlannedDateTimeRange &&
        activity.startPlannedDateTimeRange.from
    )
    .sort((a, b) => a.number - b.number) as TransportationActivityViewDTO[]

  return sortedActivities.find((activity, index, array) => {
    if (index === 0) {
      return false
    }

    const prevPlannedDate = array[index - 1].startPlannedDateTimeRange.from
    const currPlannedDate = activity.startPlannedDateTimeRange.from

    return prevPlannedDate > currPlannedDate
  })
}

const alertOnChangingMainStreetTurnProps = () =>
  getStore().dispatch(
    showModal({
      msgType: TMsgType.info,
      buttonSettings: {
        button1: {
          color: AlertButtonColor.blue,
          label: 'Ok',
          action: () => {}
        }
      },
      message: 'Street Turn will be canceled with changes on Container Type/SSL'
    })
  )

// export const shouldUpdateDispatchDeliveryOrderTabWithTroubleTicketByPinnedIds = (
//   tab: ITabState,
//   activeMainTabId: string
// ): boolean => {
//   const { visited, type, ignoreWS, id } = tab
//   return type === TabType.dispatchDeliveryOrder && !ignoreWS && (visited || activeMainTabId === id)
// }

export const alertOnChangingMainStreetTurnPropsOfDDO = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  dispatchDeliveryOrderBeforeUpdate: IDispatchDeliveryOrder
) => {
  if (!dispatchDeliveryOrder || isNewObject(dispatchDeliveryOrder)) {
    return
  }

  if (
    oc(dispatchDeliveryOrder).streetTurn.status() === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED ||
    oc(dispatchDeliveryOrder).streetTurn.status() === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED
  ) {
    const storeDispatchDeliveryOrder = getStore().getState().dispatchDeliveryOrder[dispatchDeliveryOrder.id]

    if (
      storeDispatchDeliveryOrder &&
      dispatchDeliveryOrder.containerTypeId !== storeDispatchDeliveryOrder.containerTypeId &&
      dispatchDeliveryOrderBeforeUpdate.containerTypeId === storeDispatchDeliveryOrder.containerTypeId
    ) {
      return alertOnChangingMainStreetTurnProps()
    }
  }
}

export const alertOnChangingMainStreetTurnPropsOfDO = (
  deliveryOrder: IDeliveryOrder,
  deliveryOrderBeforeUpdate: IDeliveryOrder
) => {
  const store = getStore().getState()
  const storeDeliveryOrder = store.deliveryOrder[deliveryOrder.id]

  if (!storeDeliveryOrder || isNewObject(deliveryOrder)) {
    return
  }

  if (
    storeDeliveryOrder.steamShipLineId !== deliveryOrder.steamShipLineId &&
    deliveryOrderBeforeUpdate.steamShipLineId === storeDeliveryOrder.steamShipLineId &&
    oc(storeDeliveryOrder.dispatchDeliveryOrderIds)([]).some(ddoId => {
      const storeDDO = store.dispatchDeliveryOrder[ddoId]

      if (!storeDDO) {
        return false
      }

      const storeStreetTurnStatus =
        oc(store).streetTurn[storeDDO.streetTurnId].status() || oc(storeDDO).streetTurn.status()

      return (
        storeStreetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED ||
        storeStreetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED
      )
    })
  ) {
    return alertOnChangingMainStreetTurnProps()
  }
}

export const updateDDOContainer = (
  container: ContainerViewDTO,
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  deliveryOrder = dispatchDeliveryOrder.deliveryOrder
) => {
  const updatedDDO = {
    ...dispatchDeliveryOrder,
    containerId: oc(container).id(),
    container,
    ...(isNewObject(container)
      ? {}
      : {
          containerTypeId: oc(container).containerTypeId(dispatchDeliveryOrder.containerTypeId),
          containerType: oc(container).containerType(dispatchDeliveryOrder.containerType),
          ...(deliveryOrder
            ? {
                deliveryOrder: {
                  ...deliveryOrder,
                  steamShipLineId: deliveryOrder.steamShipLineId || oc(container).steamShipLineId(),
                  steamShipLine: deliveryOrder.steamShipLine || oc(container).steamShipLine()
                }
              }
            : {})
        })
  }

  alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
  return updatedDDO
}

export const containerFn = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IGridItemActions
): {
  updateContainerType: (containerType: ContainerTypeDTO) => void
  updateContainer: (container: ContainerViewDTO) => void
} => {
  if (!dispatchDeliveryOrder) {
    return {
      updateContainerType: null,
      updateContainer: null
    }
  }

  return {
    updateContainerType: containerType => {
      // >>> clear sync Container Type with Container
      let deleteContainerData = {}
      if (
        dispatchDeliveryOrder.container &&
        dispatchDeliveryOrder.container.containerTypeId !== oc(containerType).id()
      ) {
        deleteContainerData = { containerId: undefined, container: undefined }
      }
      // <<<

      const updatedDDO = {
        ...dispatchDeliveryOrder,
        ...deleteContainerData,
        containerType,
        containerTypeId: oc(containerType).id()
      }

      alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
      actions.modify(updatedDDO)
    },
    updateContainer: container => actions.modify(updateDDOContainer(container, dispatchDeliveryOrder))
  }
}

export const isEqualDates = (date1: string | null, date2: string | null): boolean => {
  if (!date1 && !date2) {
    return true
  }

  if (!date1 || !date2) {
    return false
  }

  const correctDate1 = new Date(Date.parse(date1)).setMilliseconds(0)
  const correctDate2 = new Date(Date.parse(date2)).setMilliseconds(0)

  return correctDate1 === correctDate2
}

export const checkDates = (
  ddo: IDispatchDeliveryOrder,
  updatedBefore?: string | null,
  updatedAfter?: string | null
): boolean => {
  let before
  let after

  if (ddo.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT) {
    before = updatedBefore === undefined ? oc(ddo).returnStage.plannedAppointmentDateTimeRange.from() : updatedBefore
    after = updatedAfter === undefined ? oc(ddo).deliveryOrder.generalCutoffDate() : updatedAfter
  } else {
    before = updatedBefore === undefined ? oc(ddo).pickupStage.plannedAppointmentDateTimeRange.from() : updatedBefore
    after = updatedAfter === undefined ? oc(ddo).deliveryOrder.lastFreeDateDemurrage() : updatedAfter
  }

  return Boolean(after && before && new Date(before).setHours(0, 0, 0, 0) > new Date(after).setHours(0, 0, 0, 0))
}

export const showModalOnDateConfuse = (condition: boolean, message: string, cancel: () => void, save: () => void) => {
  if (condition) {
    getStore().dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.blue,
            label: 'Cancel',
            action: cancel
          },
          button2: {
            color: AlertButtonColor.yellow,
            label: 'OK',
            action: save
          }
        },
        message
      })
    )
  }
}

export const relatedActivityTypes: Record<
  TransportationActivityViewDTO.StageEnum,
  TransportationActivityViewDTO.TypeEnum[]
> = {
  [TransportationActivityViewDTO.StageEnum.PICKUP]: [
    TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY,
    TransportationActivityViewDTO.TypeEnum.PICKUPFULL
  ],
  [TransportationActivityViewDTO.StageEnum.DELIVERY]: [
    TransportationActivityViewDTO.TypeEnum.GETLOADED,
    TransportationActivityViewDTO.TypeEnum.GETUNLOADED,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ],
  [TransportationActivityViewDTO.StageEnum.RETURN]: [
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
    TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
  ]
}

type TPhaseType = 'pickup' | 'delivery' | 'pick' | 'return'

export const getRelatedActivityType = (
  activities: TransportationActivityViewDTO[],
  phaseType: TPhaseType
): TransportationActivityViewDTO => {
  switch (phaseType) {
    case 'pickup':
      return activities.find(
        _ =>
          _.template &&
          _.stage === TransportationActivityViewDTO.StageEnum.PICKUP &&
          relatedActivityTypes[TransportationActivityViewDTO.StageEnum.PICKUP].includes(_.type)
      )
    case 'delivery':
      return activities.find(
        _ =>
          _.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
          _.template &&
          _.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
          relatedActivityTypes[TransportationActivityViewDTO.StageEnum.DELIVERY].includes(_.type)
      )
    case 'pick':
      return activities
        .slice()
        .reverse()
        .find(
          _ =>
            _.template &&
            _.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
            (_.type === TransportationActivityViewDTO.TypeEnum.PICKUPFULL ||
              _.type === TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY)
        )
    case 'return':
      return activities
        .slice()
        .reverse()
        .find(
          _ =>
            _.template &&
            _.stage === TransportationActivityViewDTO.StageEnum.RETURN &&
            relatedActivityTypes[TransportationActivityViewDTO.StageEnum.RETURN].includes(_.type)
        )
    default:
      return null
  }
}

export const linkDatesGeneralToActivities = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  phaseType: TPhaseType,
  date: DateISOString | DateTimeRangeDTO,
  checkForDateChange?: boolean,
  callBackIfDatesChanged: () => any = () => {}
) => {
  let stageField = 'pickupStage'
  let dateField = 'plannedAppointmentDateTimeRange'

  switch (phaseType) {
    case 'pickup':
      stageField = 'pickupStage'
      dateField = 'plannedAppointmentDateTimeRange'
      break
    case 'delivery':
      stageField = 'deliveryStage'
      dateField = 'plannedAppointmentDateTimeRange'
      break
    case 'return':
      stageField = 'returnStage'
      dateField = 'plannedAppointmentDateTimeRange'
      break
    case 'pick':
      stageField = 'deliveryStage'
      dateField = 'plannedPickDateTimeRange'
      break
    default:
  }

  const relatedActivityType = getRelatedActivityType(
    dispatchDeliveryOrder.activities.transportationActivities,
    phaseType
  )

  const modifiedDDO = {
    ...dispatchDeliveryOrder,
    [stageField]: { ...dispatchDeliveryOrder[stageField], [dateField]: date },
    ...(relatedActivityType
      ? {
          activities: {
            ...dispatchDeliveryOrder.activities,
            transportationActivities: dispatchDeliveryOrder.activities.transportationActivities.map(activity =>
              relatedActivityType.id === activity.id
                ? {
                    ...activity,
                    startPlannedDateTimeRange: date
                  }
                : activity
            )
          },
          activityGroups: dispatchDeliveryOrder.activityGroups.map(group => {
            if ('businessActivity' in group && group.businessActivity.id === relatedActivityType.id) {
              return {
                ...group,
                businessActivity: { ...group.businessActivity, startPlannedDateTimeRange: date }
              }
            }

            return group
          })
        }
      : {})
  }

  if (checkForDateChange) {
    if (!date || typeof date === 'string') {
      return modifiedDDO
    }

    if (
      !isEqualDates(oc(dispatchDeliveryOrder)[stageField][dateField].from(), date.from) ||
      !isEqualDates(oc(dispatchDeliveryOrder)[stageField][dateField].to(), date.to)
    ) {
      callBackIfDatesChanged()
      return modifiedDDO
    }

    return dispatchDeliveryOrder
  }

  return modifiedDDO
}

export const cleanDispatchDeliveryOrder = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder | DispatchDeliveryOrderViewDTO
): IDispatchDeliveryOrder => {
  return dispatchDeliveryOrder
    ? (R.omit(
        {
          ...dispatchDeliveryOrder,
          pickupStage: R.omit(dispatchDeliveryOrder.pickupStage || {}, omitFieldsOnStages),
          deliveryStage: R.omit(dispatchDeliveryOrder.deliveryStage || {}, omitFieldsOnStages),
          returnStage: R.omit(dispatchDeliveryOrder.returnStage || {}, omitFieldsOnStages)
        },
        omitDDOFields as any
      ) as IDispatchDeliveryOrder)
    : dispatchDeliveryOrder
}

export type InitialActivitiesCalculation = {
  transportationActivities: TransportationActivityViewDTO[]
  documentationActivities: DocumentationActivityDTO[]
  activityGroups?: ActivityGroup[]
  error: string
}

export const initialActivitiesCalculation = (initialActivities: ActivitiesViewDTO): InitialActivitiesCalculation => {
  let error: string

  const throwError = () => {
    return {
      transportationActivities: [] as TransportationActivityViewDTO[],
      documentationActivities: [] as DocumentationActivityDTO[],
      activityGroups: [] as ActivityGroup[],
      error
    }
  }

  // >>> check
  if (initialActivities.transportationActivities.length % 2 !== 0) {
    error = 'Incorrect Transportation Activity count'
    return throwError()
  }
  // <<<

  try {
    const activityByGroups: {
      [groupId: string]: TransportationActivityViewDTO[]
    } = oc(initialActivities)
      .transportationActivities([])
      .reduce((acc, activity) => {
        if (acc[activity.groupId]) {
          if (isGotoActivity(activity)) {
            acc[activity.groupId].unshift(activity)
          } else {
            acc[activity.groupId].push(activity)
          }
        } else {
          acc[activity.groupId] = [activity]
        }

        // >>> check groupId
        if (!activity.groupId) {
          error = `Activity must have a groupId: DDO ID: ${activity.dispatchDeliveryOrderId}`
        }
        // <<<
        return acc
      }, {})

    // >>> check
    if (error) {
      return throwError()
    }
    // <<<

    const activityGroupArray = Object.values(activityByGroups)

    // >>> check
    // activityGroupArray.forEach(activityGroup => {
    //   const gotoActivity = activityGroup[0]
    //   const businessActivity = activityGroup[1]
    //
    //   if (gotoActivity.activityNumber >= businessActivity.activityNumber) {
    //     error = `Incorrect Transportation Activity Numeration: gotoActivity# ${
    //       gotoActivity.activityNumber
    //     } <<<>>> businessActivity# ${businessActivity.activityNumber}`
    //   }
    // })
    //
    // if (error) {
    //   return throwError()
    // }
    // <<<

    const allActivityGroupArray: ActivityViewDTO[][] = activityGroupArray.concat(
      oc(initialActivities)
        .documentationActivities([])
        .map(documentationActivity => [documentationActivity as any])
    )
    const sortedAllActivityGroupArray: ActivityViewDTO[][] = allActivityGroupArray.sort(
      (a, b) => a[0].number - b[0].number
    )

    const { transportationActivities, activityGroups, documentationActivities } = updateActivityGroupsNumeration({
      activityGroupsArrays: sortedAllActivityGroupArray
    })

    return {
      transportationActivities,
      documentationActivities,
      activityGroups,
      error
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log(e)

    return throwError()
  }
}

export const calcDDOProps = (
  activityGroups: ActivityGroup[],
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
): {
  ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  currentActivityGroup: TransportationActivityGroup
  declinedVendorIds: string[]
} => {
  const isRepo = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const isDriverPlanning = getDDOWorkingStatus(dispatchDeliveryOrder) === DDOWorkingStatus.planning
  let ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum = DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH
  let declinedVendorIds: string[] = []
  let currentActivityGroup: ActivityGroup = undefined

  const usefulActivityGroups = filterUsefulActivityGroups(activityGroups, true) as TransportationActivityGroup[]

  const mappings = {
    activityStatusPriority: {
      [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: 0,
      [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]: 1,
      [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]: 2,
      [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]: 3,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: 1,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: 2,
      [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: 3
    },
    ddoStatusByActivityDriverStatus: {
      [TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
      [TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
      [TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED]:
        DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED
    }
  }

  // >>> Find current activity group
  currentActivityGroup = usefulActivityGroups
    .slice()
    .reverse()
    .find(
      activityGroup =>
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )
  // <<<

  // >>> Calc drivers
  usefulActivityGroups.forEach(({ gotoActivity }) => {
    if (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED) {
      declinedVendorIds.push(gotoActivity.vendorId)
    }

    const currentPriorityNumber = mappings.activityStatusPriority[ddoStatus]
    const activityPriorityNumber = mappings.activityStatusPriority[gotoActivity.status] || -1

    if (currentPriorityNumber < activityPriorityNumber) {
      ddoStatus = mappings.ddoStatusByActivityDriverStatus[gotoActivity.status]
    }
  })

  declinedVendorIds = R.uniq(declinedVendorIds)

  if (declinedVendorIds.length) {
    ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH
  }
  // <<<

  if (currentActivityGroup) {
    // >>> Set DDO status by current activity group
    switch (currentActivityGroup.gotoActivity.stage) {
      case TransportationActivityViewDTO.StageEnum.PICKUP:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP
        break
      case TransportationActivityViewDTO.StageEnum.DELIVERY:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY
        break
      case TransportationActivityViewDTO.StageEnum.RETURN:
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
        break
      default:
        break
    }
  }
  // <<<

  const usefulActivityGroupsByStage: Record<TransportationActivityViewDTO.StageEnum, ActivityGroup[]> = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: [],
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: [],
    [TransportationActivityViewDTO.StageEnum.RETURN]: []
  }

  filterUsefulActivityGroups(activityGroups).filter((group: any) => {
    if (
      group.documentationActivity &&
      group.documentationActivity.status !== DocumentationActivityDTO.StatusEnum.REJECTED
    ) {
      usefulActivityGroupsByStage[group.documentationActivity.stage].push(group)
    } else if (group.gotoActivity) {
      usefulActivityGroupsByStage[group.gotoActivity.stage].push(group)
    }
  })

  // >>> Mark stages as Complete/In-Process
  const isGroupCompleted = (group: any): boolean => {
    if (group.documentationActivity) {
      return group.documentationActivity.status === DocumentationActivityDTO.StatusEnum.APPROVED
    } else if (group.businessActivity) {
      return group.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    }
  }
  const isStageCompleted = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    return usefulActivityGroupsByStage[stage].every(isGroupCompleted)
  }

  const isPickupCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.PICKUP)
  const isDeliveryCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.DELIVERY)
  const isReturnCompleted = isStageCompleted(TransportationActivityViewDTO.StageEnum.RETURN)

  const stagesCompletedStatus = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: isPickupCompleted,
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: isDeliveryCompleted,
    [TransportationActivityViewDTO.StageEnum.RETURN]: isReturnCompleted
  }

  const isStageEmpty = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    return !activityGroups.some((group: any) => {
      const currentGroupStage = (group.documentationActivity || group.businessActivity).stage

      return stage === currentGroupStage
    })
  }

  const isStageInProcess = (stage: TransportationActivityViewDTO.StageEnum): boolean => {
    let handledNotStartedActivityRow = false
    return (
      !stagesCompletedStatus[stage] &&
      activityGroups.some((group: any) => {
        const currentGroupStage = (group.documentationActivity || group.businessActivity).stage

        if (currentGroupStage !== stage) {
          return
        }

        const inProcessStatuses = [
          DocumentationActivityDTO.StatusEnum.SUBMITTED,
          DocumentationActivityDTO.StatusEnum.APPROVED,
          TransportationActivityViewDTO.StatusEnum.INPROCESS,
          TransportationActivityViewDTO.StatusEnum.COMPLETED,
          TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
        ]

        if (group.documentationActivity) {
          return (
            (stage !== TransportationActivityViewDTO.StageEnum.RETURN || !handledNotStartedActivityRow) &&
            inProcessStatuses.includes(group.documentationActivity.status)
          )
        } else if (group.businessActivity) {
          const isInProcess =
            inProcessStatuses.includes(group.gotoActivity.status) ||
            inProcessStatuses.includes(group.businessActivity.status)

          if (!isInProcess) {
            handledNotStartedActivityRow = true
          }

          return isInProcess
        }
      })
    )
  }

  const isDeliveryInProcess = isStageInProcess(TransportationActivityViewDTO.StageEnum.DELIVERY)
  const isReturnInProcess = isStageInProcess(TransportationActivityViewDTO.StageEnum.RETURN)
  // <<<

  // >>> Set DDO status according to completed stages
  switch (true) {
    case isRepo: {
      if (isPickupCompleted) {
        ddoStatus = isReturnInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
          : DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP
      }
      if (isPickupCompleted && isReturnCompleted) {
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
      }

      break
    }
    default: {
      if (isPickupCompleted) {
        ddoStatus = isDeliveryInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY
          : DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP
      }
      if (isPickupCompleted && isDeliveryCompleted) {
        ddoStatus = isReturnInProcess
          ? DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN
          : !isStageEmpty(TransportationActivityViewDTO.StageEnum.DELIVERY)
          ? DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY
          : ddoStatus
      }
      if (isPickupCompleted && isDeliveryCompleted && isReturnCompleted) {
        ddoStatus = DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
      }
    }
  }
  // <<<

  return {
    ddoStatus: isDriverPlanning ? dispatchDeliveryOrder.status : ddoStatus,
    currentActivityGroup:
      ddoStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED ? currentActivityGroup : undefined,
    declinedVendorIds
  }
}

export type CorrectActivityData = {
  activityGroups: ActivityGroup[]
  transportationActivities: TransportationActivityViewDTO[]
  documentationActivities: DocumentationActivityDTO[]
}

export const updateActivityGroupsNumeration = ({
  activityGroups,
  activityGroupsArrays
}: {
  activityGroups?: ActivityGroup[]
  activityGroupsArrays?: ActivityViewDTO[][]
}): CorrectActivityData => {
  let activityNumber = 0
  const transportationActivities: TransportationActivityViewDTO[] = []
  const documentationActivities: DocumentationActivityDTO[] = []
  const updatedActivityGroups: ActivityGroup[] = []

  const activityGroupsArray: ActivityViewDTO[][] = activityGroupsArrays
    ? activityGroupsArrays
    : activityGroups.map(
        group =>
          [
            (group as DocumentationActivityGroup).documentationActivity,
            (group as TransportationActivityGroup).gotoActivity,
            (group as TransportationActivityGroup).businessActivity
          ].filter(Boolean) as ActivityViewDTO[]
      )

  const sortedActivityGroupsByStage: Record<TransportationActivityViewDTO.StageEnum, ActivityViewDTO[][]> = {
    [TransportationActivityViewDTO.StageEnum.PICKUP]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.PICKUP
    ),
    [TransportationActivityViewDTO.StageEnum.DELIVERY]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.DELIVERY
    ),
    [TransportationActivityViewDTO.StageEnum.RETURN]: activityGroupsArray.filter(
      group => group[0].stage === TransportationActivityViewDTO.StageEnum.RETURN
    )
  }

  sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.PICKUP]
    .concat(
      sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.DELIVERY],
      sortedActivityGroupsByStage[TransportationActivityViewDTO.StageEnum.RETURN]
    )
    .forEach(group => {
      switch (group.length) {
        case 1: {
          // Documentation Activity
          const documentationActivity = { ...group[0], number: activityNumber }
          activityNumber++

          documentationActivities.push(documentationActivity as DocumentationActivityDTO)
          updatedActivityGroups.push({ documentationActivity } as DocumentationActivityGroup)
          break
        }
        case 2: {
          // GOTO + Business Activity
          const gotoActivity = { ...group[0], number: activityNumber }
          activityNumber++
          const businessActivity = { ...group[1], number: activityNumber }
          activityNumber++

          transportationActivities.push(
            gotoActivity as TransportationActivityViewDTO,
            businessActivity as TransportationActivityViewDTO
          )
          updatedActivityGroups.push({ gotoActivity, businessActivity } as TransportationActivityGroup)
          break
        }
        default: {
          return
        }
      }
    })

  return {
    activityGroups: updatedActivityGroups,
    transportationActivities,
    documentationActivities
  }
}

export const filterDocumentationTypeGroups = (activityGroups: ActivityGroup[]): DocumentationActivityGroup[] => {
  return activityGroups.filter(group => 'documentationActivity' in group) as DocumentationActivityGroup[]
}

export const omitDocumentationTypeGroups = (activityGroups: ActivityGroup[]): TransportationActivityGroup[] => {
  return activityGroups.filter(activityGroup => 'gotoActivity' in activityGroup) as TransportationActivityGroup[]
}

export const filterUsefulActivityGroups = (
  activityGroups: ActivityGroup[],
  omitDocumentationType?: boolean
): ActivityGroup[] => {
  const activityGroupList = omitDocumentationType
    ? (omitDocumentationTypeGroups(activityGroups) as TransportationActivityGroup[])
    : (activityGroups as ActivityGroup[])

  return activityGroupList.filter(
    activityGroup =>
      oc(activityGroup as TransportationActivityGroup).gotoActivity.status() !==
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
      oc(activityGroup as TransportationActivityGroup).businessActivity.status() !==
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
  )
}

export const getCurrentActivityGroup = (
  activityGroups: ActivityGroup[]
): {
  currentActivityGroup: TransportationActivityGroup
  currentActivityGroupIndex: number
  lastUnsuccessfulActivityGroup: TransportationActivityGroup
  lastUnsuccessfulActivityGroupIndex: number
} => {
  const currentActivityGroup = (filterUsefulActivityGroups(activityGroups, true) as TransportationActivityGroup[])
    .slice()
    .reverse()
    .find(
      activityGroup =>
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
        activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )
  let lastUnsuccessfulActivityGroup: TransportationActivityGroup = activityGroups
    .slice()
    .reverse()
    .find(group => isUnsuccessfulActivityGroup(group as any)) as TransportationActivityGroup

  if (
    !oc(lastUnsuccessfulActivityGroup).gotoActivity.id() ||
    !oc(lastUnsuccessfulActivityGroup).businessActivity.id()
  ) {
    lastUnsuccessfulActivityGroup = undefined
  }

  return {
    currentActivityGroup,
    currentActivityGroupIndex: currentActivityGroup
      ? activityGroups.findIndex(
          group => oc(group as TransportationActivityGroup).gotoActivity.id() === currentActivityGroup.gotoActivity.id
        )
      : -1,
    lastUnsuccessfulActivityGroup,
    lastUnsuccessfulActivityGroupIndex: lastUnsuccessfulActivityGroup
      ? activityGroups.findIndex(
          group =>
            oc(group as TransportationActivityGroup).gotoActivity.id() === lastUnsuccessfulActivityGroup.gotoActivity.id
        )
      : -1
  }
}

export const getStreetTurnStatus = (props: {
  streetTurn: DispatchDeliveryOrderStreetTurnDTO
  streetTurnCount: number
}): DispatchDeliveryOrderStreetTurnDTO.StatusEnum | undefined => {
  const { streetTurn, streetTurnCount } = props
  let result = undefined

  if (streetTurnCount) {
    result = DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE
  }

  if (streetTurn && streetTurn.status) {
    result = streetTurn.status
  }

  return result
}

export const allowCalcDispatchDeliveryOrderStatus = (status: DispatchDeliveryOrderViewDTO.StatusEnum): boolean => {
  return ![
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].includes(status)
}

export const getDDOWorkingStatus = (dispatchDeliveryOrder: IDispatchDeliveryOrder): DDOWorkingStatus => {
  const status = dispatchDeliveryOrder.status

  switch (true) {
    case allowCalcDispatchDeliveryOrderStatus(status):
      return DDOWorkingStatus.working
    case [
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
      DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
      DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
      DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE
    ].includes(status):
      return DDOWorkingStatus.planning
    default:
      return DDOWorkingStatus.none
  }
}

export const isContainerRequiredForDDO = (
  doType: DeliveryOrderViewDTO.TypeEnum,
  ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
): boolean => {
  switch (doType) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return true
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
    case DeliveryOrderViewDTO.TypeEnum.REPOSITION:
      return (
        dispatchDeliveryOrderStatusIndex[ddoStatus] >=
          dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP] &&
        dispatchDeliveryOrderStatusIndex[ddoStatus] <
          dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]
      )
    default:
      return false
  }
}

type UserModelForDDO = {
  Status: any
  'Availability Date': any
  'Planned Appointment DateDateTimeRange': any
  'Actual Appointment Date': any
  'Per Diem Free By Date': any
  'Planned Pickup DateDateTimeRange': any
  'Actual Pickup Date': any
  'Load Type': any
  'Planned Return DateDateTimeRange': any
  'Actual Return Date': any
  'Cutoff Date': any
  'Planned Pick DateDateTimeRange': any
  'Actual Pick Date': any
  'First Receiving Date': any
  'Hazmat Cutoff Date': any
  'Last Free Date': any
  'Auto Cutoff Date': any
  'Reefer Cutoff Date': any
  'Spent (hours)': any
  Description: any
  'Pickup Location': any
  'Delivery Location': any
  'Return Location': any
  Customer: any
  Equipment: {
    'Container Type': any
    'Container #': any
    Seal: any
    'Chassis #': any
    'Chassis Pickup Date': any
    'Chassis Return Date': any
  }
  Cargo: {
    'Reference #': any
    Weight: any
    'Weight Unit': any
    Overweight: any
    Miles: any
    Description: any
    'Auto Indicator': any
    'Hazmat Indicator': any
    'Hazmat Description': any
  }
  Documents: {
    'Booking #': any
    'Bill of Landing #': any
  }
  SSL: {
    'SSL Name': any
    'Vessel Name': any
    'Voyage #': any
    'Vessel Departure (ETD)Date': any
  }
}

export const makeUserModelForDDO = (dispatchDeliveryOrder: IDispatchDeliveryOrder): UserModelForDDO => {
  const isExport = oc(dispatchDeliveryOrder).deliveryOrder.type() === DeliveryOrderViewDTO.TypeEnum.EXPORT

  const uppercaseBooleanValue = (value: true | false) => {
    if (value !== true && value !== false) {
      return
    }

    return String(value).toUpperCase()
  }

  return {
    Status: oc(dispatchDeliveryOrder).status(),
    'Availability Date': oc(dispatchDeliveryOrder).deliveryOrder.equipmentFirstPickupDate(),
    'Planned Appointment DateDateTimeRange': oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange(),
    'Actual Appointment Date': oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
    'Per Diem Free By Date': oc(dispatchDeliveryOrder).deliveryOrder.lastFreeDatePerDiem(),
    'Planned Pickup DateDateTimeRange': oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange(),
    'Actual Pickup Date': oc(dispatchDeliveryOrder).pickupStage.actualAppointmentDate(),
    'Load Type': oc(dispatchDeliveryOrder).loadType(),
    'Planned Return DateDateTimeRange': oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange(),
    'Actual Return Date': oc(dispatchDeliveryOrder).returnStage.actualAppointmentDate(),
    'Cutoff Date': oc(dispatchDeliveryOrder).deliveryOrder.generalCutoffDate(),
    'Planned Pick DateDateTimeRange': oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
    'Actual Pick Date': oc(dispatchDeliveryOrder).deliveryStage.actualPickDate(),
    'First Receiving Date': oc(dispatchDeliveryOrder).deliveryOrder.firstReceivingDate(),
    'Hazmat Cutoff Date': oc(dispatchDeliveryOrder).deliveryOrder.hazmatCutoffDate(),
    'Last Free Date': oc(dispatchDeliveryOrder).deliveryOrder.lastFreeDateDemurrage(),
    'Auto Cutoff Date': oc(dispatchDeliveryOrder).deliveryOrder.autoCutoffDate(),
    'Reefer Cutoff Date': oc(dispatchDeliveryOrder).deliveryOrder.reeferCutoffDate(),
    'Spent (hours)': oc(dispatchDeliveryOrder).deliveryStage.spentTimeSpan(),
    'Pickup Location': oc(dispatchDeliveryOrder).pickupStage.location.name(),
    'Delivery Location': oc(dispatchDeliveryOrder).deliveryStage.location.name(),
    'Return Location': oc(dispatchDeliveryOrder).returnStage.location.name(),
    Description: oc(dispatchDeliveryOrder).description(),
    Customer: oc(dispatchDeliveryOrder).deliveryOrder.customer.name(),
    Equipment: {
      'Container Type': oc(dispatchDeliveryOrder).containerType.name(),
      'Container #': oc(dispatchDeliveryOrder).container.number(),
      Seal: oc(dispatchDeliveryOrder).sealNumber(),
      'Chassis #': oc(dispatchDeliveryOrder).equipment.chassisNumber(),
      'Chassis Pickup Date': oc(dispatchDeliveryOrder).equipment.pickupDate(),
      'Chassis Return Date': oc(dispatchDeliveryOrder).equipment.returnDate()
    },
    Cargo: {
      'Reference #': oc(dispatchDeliveryOrder).deliveryOrder.cargo.referenceNumber(),
      Weight: oc(dispatchDeliveryOrder).weight(),
      'Weight Unit': oc(dispatchDeliveryOrder).weightUnit(),
      Overweight: oc(dispatchDeliveryOrder).overweightIndicator(),
      Miles: oc(dispatchDeliveryOrder).mileage(),
      Description: oc(dispatchDeliveryOrder).deliveryOrder.cargo.description(),
      'Auto Indicator': uppercaseBooleanValue(oc(dispatchDeliveryOrder).autoIndicator()),
      'Hazmat Indicator': uppercaseBooleanValue(oc(dispatchDeliveryOrder).hazmatIndicator()),
      'Hazmat Description': oc(dispatchDeliveryOrder).hazmat()
        ? [oc(dispatchDeliveryOrder).hazmat.code(), oc(dispatchDeliveryOrder).hazmat.description()]
            .filter(Boolean)
            .join(', ')
        : undefined
    },
    Documents: {
      'Booking #': oc(dispatchDeliveryOrder).deliveryOrder.bookingNumber(),
      'Bill of Landing #': oc(dispatchDeliveryOrder).deliveryOrder.billOfLadingNumber()
    },
    SSL: {
      'SSL Name': oc(dispatchDeliveryOrder).deliveryOrder.steamShipLine.name(),
      'Vessel Name': oc(dispatchDeliveryOrder).deliveryOrder.vesselName(),
      'Voyage #': oc(dispatchDeliveryOrder).deliveryOrder.voyageNumber(),
      'Vessel Departure (ETD)Date': oc(dispatchDeliveryOrder).deliveryOrder[
        isExport ? 'vesselDepartureDate' : 'vesselArrivalDate'
      ]()
    }
  }
}

export const dispatchDeliveryOrderStatusIndex: Record<DispatchDeliveryOrderViewDTO.StatusEnum, number> = (() =>
  [
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
    DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY,
    DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN,
    DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].reduce(
    (acc, currStatus, index) => {
      acc[currStatus] = index
      return acc
    },
    {
      '': -1,
      undefined: -1,
      null: -1
    }
  ))()
