import * as React from 'react'
import { oc } from 'ts-optchain'
import { Select } from '../../../../../UI/DataFields/Select'
import { SchedulerSortBy } from '../../../interfaces'
import { getStore } from '../../../../../../store/configureStore'
import { updateTabSettings } from '../../../../tabs/actions'
import { updateSchedulerFilterGroupBy } from '../../../functions/updateSchedulerFilterGroupBy'
import { ITabState } from '../../../../tabs/interfaces'

type StateProps = {
  currentTab: ITabState
  sortBy: SchedulerSortBy
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const Group = React.memo((props: Props) => {
  const { currentTab, sortBy } = props

  return (
    <Select
      title={'Group By'}
      selectedValue={sortBy}
      containerStyle={{ flexGrow: 1, width: 'unset' }}
      list={[
        SchedulerSortBy.date,
        SchedulerSortBy.deliveryLocationCity,
        SchedulerSortBy.deliveryStageDriver,
        SchedulerSortBy.driverActivities
      ].map(item => ({ value: item, label: item }))}
      onSelect={nextSortBy =>
        setTimeout(() => {
          const { dispatch } = getStore()

          dispatch(
            updateTabSettings({
              id: currentTab.id,
              uiSettings: {
                ...currentTab.uiSettings,
                sorting: nextSortBy,
                filter: updateSchedulerFilterGroupBy({
                  prevSortBy: sortBy,
                  nextSortBy,
                  filters: oc(currentTab).uiSettings.filter([])
                })
              }
            })
          )
        })
      }
    />
  )
})
