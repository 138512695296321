import { createSelector } from 'reselect'
import { ApplicationContext, IStore } from '../../../../store/store.interface'
import { TabType } from '../interfaces'
import applicationContextSelector from '../../../../services/applicationContextService/selectors'
import { ISchedulerTab } from '../../scheduler/interfaces'

export const tabsSelector = (state: IStore) => state.tabs.main

export const getApplicationTabs = (state: IStore) => state.tabs.main
export const getAdminTabs = (state: IStore) => state.tabs.admin

export const getTabs = createSelector(
  [applicationContextSelector, getApplicationTabs, getAdminTabs],
  (context, main, admin) => (context === ApplicationContext.main ? main : admin)
)

export const getTabsLength = (state: IStore) => state.tabs.main.length

export const getSchedulerTabs = createSelector(
  [getApplicationTabs],
  tabs => tabs.filter(tab => tab.type === TabType.scheduler) as ISchedulerTab[]
)
export const getSubClientTabs = createSelector(
  [getApplicationTabs],
  tabs => tabs.filter(tab => tab.type === TabType.subClient)
)

export const getUserTabs = createSelector(
  [getAdminTabs],
  tabs => tabs.filter(tab => tab.type === TabType.users)
)

export const getPowerUnitTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.powerUnit)
)

export const getEquipmentTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.equipment)
)
export const getLocationTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.location)
)

export const getDriverTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.driver)
)

export const getSurchargeTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.surcharge)
)

export const getContainerTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.container)
)

export const getCustomerTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.customer)
)

export const getQMPTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.QMP)
)

export const getDispatchDeliveryOrderTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.dispatchDeliveryOrder)
)

export const getSteamshipLineTabs = createSelector(
  [tabsSelector],
  tabs => tabs.filter(tab => tab.type === TabType.steamShipLine)
)
