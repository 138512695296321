import { TContact } from '../../../components/common/contact/interfaces'
import { oc } from 'ts-optchain'
import { ILocation } from '../../../components/common/location/interfaces'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { isContactValid } from '../../functions/test/isContactValid'

export const checkContactsValidation = (contacts: TContact[]): boolean => (contacts || []).every(isContactValid)

export const getPrimaryContact = (object: ILocation | BusinessPartner) =>
  object && object.contacts ? object.contacts.find(_ => _.primary) : null

export const getPrimaryContactNameFormContacts = (contacts: TContact[]) => {
  const primaryContact = contacts.find(_ => _.primary) || null
  return oc(primaryContact).firstName('') + ' ' + oc(primaryContact).lastName('')
}
