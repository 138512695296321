import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { IDispatchDeliveryOrder } from '../../../../../common/dispatchDeliveryOrder/interfaces'
import { StyledPopup } from './styles'
import { Columns } from './Columns'
import { ActionsSaveCancel } from '../../../../Buttons/ActionsSaveCancel'
import { ActivitiesViewDTO, BuySideQuoteDTO, SellSideQuoteDTO } from '../../../../../../api/origin/business-logic'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { GridSpinner } from '../../../../Spinner/Spinner'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectIsRulesLoaded } from '../../../../../../store/select/ruleSelect'
import { requestRules } from '../../../../../common/QMP/epics/rules'

type TPopover = {
  activities: ActivitiesViewDTO
  isDDOValid: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  gridItemActions: IGridItemActions
  profit: number
  sellSideQuoteTotalAmount: number
  buySideQuoteTotalAmount: number
  buySideQuotesAmount: Record<string, number>
}

export const Popover = (props: TPopover) => {
  const {
    activities,
    dispatchDeliveryOrder,
    gridItemActions,
    buySideQuotesAmount,
    buySideQuoteTotalAmount,
    profit,
    sellSideQuoteTotalAmount,
    isDDOValid
  } = props
  const isRulesLoaded = useAppSelector(selectIsRulesLoaded)
  const updateSellSideQuote = (sellSideQuote: SellSideQuoteDTO) =>
    gridItemActions.modify({ ...dispatchDeliveryOrder, sellSideQuote })
  const updateBuySideQuotes = (buySideQuotes: BuySideQuoteDTO[]) =>
    gridItemActions.modify({ ...dispatchDeliveryOrder, buySideQuotes })

  React.useEffect(() => {
    if (!isRulesLoaded) {
      requestRules()
    }
  }, [])

  return (
    <StyledPopup>
      {gridItemActions.isFetching && <GridSpinner />}
      <Columns
        activities={activities}
        profit={profit}
        sellSideQuoteTotalAmount={sellSideQuoteTotalAmount}
        buySideQuotesAmount={buySideQuotesAmount}
        buySideQuoteTotalAmount={buySideQuoteTotalAmount}
        dispatchDeliveryOrder={dispatchDeliveryOrder}
        updateSellSideQuote={updateSellSideQuote}
        updateBuySideQuotes={updateBuySideQuotes}
        isModifiedMode={gridItemActions.isModified}
        actionSave={gridItemActions.save}
      />
      {gridItemActions.isModified && (
        <ActionsSaveCancel
          disableSave={!isDDOValid}
          styles={extraSaveButtonContainerStyles}
          showCancel={true}
          onSave={gridItemActions.save}
          onCancel={gridItemActions.cancel}
        />
      )}
      <ReactTooltip className={'react-tooltip pre-line'} id={'rules'} />
      <ReactTooltip className={'react-tooltip'} id={'hint'} />
    </StyledPopup>
  )
}

const extraSaveButtonContainerStyles = { height: 55, right: 20 }
