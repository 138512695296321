import { ContactDTO, LocationGridItemDTO, LocationViewDTO } from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddContactToStore } from '../../../components/common/contact/epics'
import { tryAddLocationToStore } from '../../../components/common/location/epics'

export const parseLocations = (locations: (LocationViewDTO | LocationGridItemDTO)[]) => {
  const lists: { contacts: ContactDTO[] } = {
    contacts: []
  }

  const clearedLocations = locations.filter(Boolean).map(location => {
    if (location.primaryContact) {
      lists.contacts.push(location.primaryContact)
    }
    return R.omit(location, ['primaryContact'])
  })

  tryAddContactToStore(lists.contacts)
  tryAddLocationToStore(clearedLocations)
}
