import * as React from 'react'
import { connect } from 'react-redux'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import {
  DateISOString,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  EquipmentDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../api/api'
import {
  IActivityDocumentPopup,
  IDispatchDeliveryOrder,
  ActivityDocumentPopupView
} from '../components/common/dispatchDeliveryOrder/interfaces'
// tslint:disable:max-line-length
import { completionActualDayNeedStatuses } from '../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/CompletionActualDateActivityColumn'
import { startActualDayNeedStatuses } from '../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/StartActualDateActivityColumn'
import { ILocation } from '../components/common/location/interfaces'
import { TransportationActivityRowContext } from '../contexts/TransportationActivityRowContext'
import {
  ActivityGroupAction,
  ActivityRow,
  DDOWorkingStatus,
  TransportationActivityRow
} from '../services/DTO/activity/controller'
import { TransportationActivityGroup } from '../services/DTO/activity/interfaces'
import { relatedActivityTypes } from '../services/DTO/dispatchDeliveryOrder/functions'
import { FileCategory, IAttachment } from '../services/filesService/interfaces'
import { setTabViewingObjectTemporaryDataProps } from '../services/viewingObjects/actions'
import { IStore } from '../store/store.interface'
import { showModalWindow } from '../store/reducers/modalWindow/functions'
import { IMessage } from '../store/reducers/communicationHub/interfaces'
import { isDropBobtailGotoActivity, isPickBobtailGotoActivity } from '../services/functions/test/isBobtailGotoActivity'
import { getNextActivityRow } from '../services/functions/get/getNextActivityRow'
import { getPreviousActivityRow } from '../services/functions/get/getPreviousActivityRow'
import { isDropActivity } from '../services/functions/test/isDropActivity'
import { isPickActivity } from '../services/functions/test/isPickActivity'
import { isUnsuccessfulActivityGroup } from '../services/functions/test/isUnsuccessfulActivity'
import { getBobtailRefDispatchDeliveryOrderInfo } from '../services/functions/get/getBobtailRefDispatchDeliveryOrderInfo'
import { getStore } from '../store/configureStore'
import { getDispatchDeliveryOrderById } from '../components/common/dispatchDeliveryOrder/epics'
import { isGotoActivityType } from '../services/functions/test/isGotoActivity'
import { getActivityDropBobtailGroup } from '../services/functions/get/getActivityDropBobtailGroup'

type OwnProps = {
  rowNumber: number
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  transportationRow: TransportationActivityRow
  isModifiedMode: boolean
  allowModifying: boolean
  importDatesConfused: boolean
  exportDatesConfused: boolean
  activityWithWrongOrderOfPlannedDates: TransportationActivityViewDTO
  rows: ActivityRow[]
  usefulRows: TransportationActivityRow[]
  stageActivityRows: ActivityRow[]
  updateActivityRow: (
    updatedActivityGroup: TransportationActivityRow | TransportationActivityRow[],
    action: ActivityGroupAction,
    updateLocationForInitialObjectState?: ILocation
  ) => void
  modifyDispatchDeliveryOrder: (state: IDispatchDeliveryOrder) => void
  lastUnsuccessfulActivityGroup?: TransportationActivityGroup
  currentActivityGroup?: TransportationActivityGroup
  children?: any
}

type StateProps = {
  activityDocumentPopup: IActivityDocumentPopup | undefined
  activeMainTabId: string
  documentMessages: IMessage[] | undefined
}

type DispatchProps = {
  setTabViewingObjectTemporaryDataProps: typeof setTabViewingObjectTemporaryDataProps
}

type Props = OwnProps & StateProps & DispatchProps

export const TransportationActivityRowProvider = connect(
  ({ tabs, viewingObjects }: IStore) => {
    const activeMainTabId = tabs.activeMainTabId
    const temporaryData = oc(viewingObjects[activeMainTabId]).temporaryData({})
    return {
      activeMainTabId,
      activityDocumentPopup: temporaryData.activityDocumentPopup,
      documentMessages: temporaryData.activityDocumentMessages
    }
  },
  { setTabViewingObjectTemporaryDataProps }
)(
  React.memo((props: Props) => {
    const {
      activeMainTabId,
      activityDocumentPopup,
      rowNumber,
      dispatchDeliveryOrder,
      transportationRow,
      isModifiedMode,
      allowModifying,
      activityWithWrongOrderOfPlannedDates,
      importDatesConfused,
      exportDatesConfused,
      rows,
      usefulRows,
      stageActivityRows,
      modifyDispatchDeliveryOrder,
      lastUnsuccessfulActivityGroup,
      currentActivityGroup,
      documentMessages,
      children
    } = props
    const updateActivityRowRef = React.useRef(props.updateActivityRow)
    updateActivityRowRef.current = props.updateActivityRow
    const { gotoActivity, businessActivity } = transportationRow.activityGroup as TransportationActivityGroup
    const store = getStore().getState()
    const isDrop = isDropActivity(businessActivity)
    const isPick = isPickActivity(businessActivity)
    const isDropBobtail = isDropBobtailGotoActivity(gotoActivity)
    const isPickBobtail = isPickBobtailGotoActivity(gotoActivity)
    const isBobtail = isDropBobtail || isPickBobtail
    let bobtailGroup: TransportationActivityGroup[] = []
    const anotherDOOActivityLink = getBobtailRefDispatchDeliveryOrderInfo(gotoActivity)
    const isLinkedActivityToBobtail = Boolean(anotherDOOActivityLink && !isBobtail)
    const deliveryOrderType = oc(dispatchDeliveryOrder).deliveryOrder.type()
    const loadType = dispatchDeliveryOrder.loadType
    const isUnsuccessful = isUnsuccessfulActivityGroup(transportationRow.activityGroup)
    let appDateIsNotConfirmed: boolean
    let disableActualDates = false
    let tirDocument: IAttachment
    let podDocument: IAttachment
    let tirDocumentMessage: IMessage
    let podDocumentMessage: IMessage
    let enableTIRDocumnet = false
    let enablePODDocumnet = false
    let dropTypeActivityRowToCreateBobtail = undefined
    let pickTypeActivityRowToCreateBobtail = undefined
    let isDropBobtailValidationError = false
    const prevTransportationRowOfStage = getPreviousActivityRow(stageActivityRows, businessActivity.id, {
      returnOnlyTransportation: true
    }) as TransportationActivityRow
    const nextTransportationRowOfStage = getNextActivityRow(stageActivityRows, businessActivity.id, {
      returnOnlyTransportation: true
    }) as TransportationActivityRow
    const troubleTicketId = gotoActivity.troubleTicketId || businessActivity.troubleTicketId
    const permissions = transportationRow.permissions
    const gotoActivityStartDateRequired = startActualDayNeedStatuses.includes(gotoActivity.status)
    const businessActivityStartDateRequired = startActualDayNeedStatuses.includes(businessActivity.status)
    const gotoActivityCompleteDateRequired = completionActualDayNeedStatuses.includes(gotoActivity.status)
    const businessActivityCompleteDateRequired = completionActualDayNeedStatuses.includes(businessActivity.status)
    const linkedGotoActivity =
      anotherDOOActivityLink &&
      anotherDOOActivityLink.activityId &&
      store.activity.transportationActivities[anotherDOOActivityLink.activityId]
    let linkedBusinessActivity: TransportationActivityViewDTO = undefined

    if (linkedGotoActivity) {
      const linkedDispachDeliveryOrder = store.dispatchDeliveryOrder[linkedGotoActivity.dispatchDeliveryOrderId]

      if (linkedDispachDeliveryOrder && linkedDispachDeliveryOrder.fullObject) {
        linkedBusinessActivity = linkedDispachDeliveryOrder.activityIds
          .map(id => store.activity.transportationActivities[id])
          .filter(Boolean)
          .find(activity => {
            return activity.groupId === linkedGotoActivity.groupId && !isGotoActivityType(activity.type)
          })
      }
    }

    React.useEffect(() => {
      if (anotherDOOActivityLink && anotherDOOActivityLink.activityId && !linkedGotoActivity) {
        getDispatchDeliveryOrderById(anotherDOOActivityLink.dispatchDeliveryOrderId)
      }
    }, [anotherDOOActivityLink && anotherDOOActivityLink.activityId])

    const updateActivityRow = React.useCallback((...args) => {
      // @ts-ignore
      updateActivityRowRef.current(...args)
    }, [])

    if (
      (isDropBobtail &&
        transportationRow.activityGroup.gotoActivity.comboType === TransportationActivityViewDTO.ComboTypeEnum.EMPTY &&
        !transportationRow.activityGroup.gotoActivity.destinationId) ||
      transportationRow.permissions.ddoWorkingStatus !== DDOWorkingStatus.working ||
      !allowModifying ||
      (oc(currentActivityGroup).gotoActivity.id() !== transportationRow.id &&
        (!permissions.writable || !permissions.fullAccess)) ||
      (lastUnsuccessfulActivityGroup &&
        lastUnsuccessfulActivityGroup.gotoActivity.id !== gotoActivity.id &&
        lastUnsuccessfulActivityGroup.businessActivity.number > gotoActivity.number)
    ) {
      disableActualDates = true
    }

    const gotoActivityStartDateDisabled =
      isLinkedActivityToBobtail ||
      (disableActualDates && !(gotoActivityStartDateRequired && !gotoActivity.startActualDate))
    const gotoActivityCompleteDateDisabled =
      isLinkedActivityToBobtail ||
      (disableActualDates && !(gotoActivityCompleteDateRequired && !gotoActivity.completionActualDate))
    const businessActivityStartDateDisabled =
      Boolean(isLinkedActivityToBobtail && linkedGotoActivity && !linkedGotoActivity.completionActualDate) ||
      (disableActualDates && !(businessActivityStartDateRequired && !businessActivity.startActualDate))
    const businessActivityCompleteDateDisabled =
      Boolean(isLinkedActivityToBobtail && linkedGotoActivity && !linkedGotoActivity.completionActualDate) ||
      (disableActualDates && !(businessActivityCompleteDateRequired && !businessActivity.completionActualDate))

    if (
      // PICKUP
      (businessActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP &&
        [
          // @ts-ignore
          oc(dispatchDeliveryOrder).equipment.ownership() === EquipmentDTO.OwnershipEnum.RENTED
            ? TransportationActivityViewDTO.TypeEnum.PICKCHASSIS
            : undefined,
          TransportationActivityViewDTO.TypeEnum.PICKUPFULL,
          TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY
        ]
          .filter(Boolean)
          .includes(businessActivity.type)) ||
      // RETURN
      (businessActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN &&
        [
          // @ts-ignore
          oc(dispatchDeliveryOrder).equipment.ownership() === EquipmentDTO.OwnershipEnum.RENTED
            ? TransportationActivityViewDTO.TypeEnum.DROPCHASSIS
            : undefined,
          TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
          TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS,
          TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS,
          TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
        ]
          .filter(Boolean)
          .includes(businessActivity.type))
    ) {
      enableTIRDocumnet = true
    }

    if (
      // DELIVERY
      businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      // IMP
      ((deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT &&
        // D&P
        ((loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK &&
          [
            TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS,
            TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS
          ].includes(businessActivity.type) &&
          oc(businessActivity).destination.type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) ||
          // Live load
          (loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD &&
            [TransportationActivityViewDTO.TypeEnum.GETUNLOADED].includes(businessActivity.type)))) ||
        // EXP
        (deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT &&
          // D&P
          ((loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK &&
            [TransportationActivityViewDTO.TypeEnum.PICKUPFULL].includes(businessActivity.type) &&
            oc(businessActivity).destination.type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) ||
            // Live load
            (loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD &&
              [TransportationActivityViewDTO.TypeEnum.GETLOADED].includes(businessActivity.type)))))
    ) {
      enablePODDocumnet = true
    }

    const emptyRequiredFieldsForDocument: string = [
      enableTIRDocumnet &&
        !dispatchDeliveryOrder.containerId &&
        !(
          (businessActivity.type === TransportationActivityViewDTO.TypeEnum.PICKCHASSIS &&
            businessActivity.stage === TransportationActivityViewDTO.StageEnum.PICKUP) ||
          (businessActivity.type === TransportationActivityViewDTO.TypeEnum.DROPCHASSIS &&
            businessActivity.stage === TransportationActivityViewDTO.StageEnum.RETURN)
        ) &&
        'Container #',
      !businessActivity.vendorId && 'Driver',
      !businessActivity.destinationId && 'Location'
    ]
      .filter(Boolean)
      .join(', ')

    if ((enableTIRDocumnet || enablePODDocumnet) && documentMessages) {
      documentMessages
        .slice()
        .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
        .forEach(documentMessage => {
          if (
            documentMessage.metadata &&
            oc(documentMessage).metadata.activities[0].id() === businessActivity.id &&
            documentMessage.attachments
          ) {
            if (!tirDocument) {
              tirDocument = documentMessage.attachments.find(document => document.category === FileCategory.TIR)

              if (tirDocument) {
                tirDocumentMessage = documentMessage
              }
            }
            if (!podDocument) {
              podDocument = documentMessage.attachments.find(document => document.category === FileCategory.POD)

              if (podDocument) {
                podDocumentMessage = documentMessage
              }
            }
          }
        })
    }

    if (
      businessActivity.template &&
      businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      relatedActivityTypes[TransportationActivityViewDTO.StageEnum.DELIVERY].includes(businessActivity.type)
    ) {
      appDateIsNotConfirmed =
        oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed() === false
    }

    const openActivityDocumentPopup = (view: ActivityDocumentPopupView, ignoreEmptyRequiredFields: boolean) => {
      if (!emptyRequiredFieldsForDocument || ignoreEmptyRequiredFields) {
        props.setTabViewingObjectTemporaryDataProps({
          tabId: activeMainTabId,
          temporaryDataProps: {
            activityDocumentPopup: {
              view,
              activityId: businessActivity.id,
              completionActualDate: businessActivity.completionActualDate,
              troubleTicketId: gotoActivity.troubleTicketId || businessActivity.troubleTicketId,
              validatedManually:
                !gotoActivity.troubleTicketId && !businessActivity.troubleTicketId
                  ? enableTIRDocumnet
                    ? // @ts-ignore
                      oc(tirDocumentMessage).metadata.validation.validatedManually()
                    : enablePODDocumnet
                    ? // @ts-ignore
                      oc(podDocumentMessage).metadata.validation.validatedManually()
                    : undefined
                  : undefined
            }
          }
        })
      } else {
        showModalWindow({
          width: 480,
          title: 'Required fields are empty',
          content: `To Upload or Edit Documents you need to fill required fields (${emptyRequiredFieldsForDocument})`,
          buttons: [{ label: 'Ok' }]
        })
      }
    }

    const setActivityDocumentPopup = (state: IActivityDocumentPopup) => {
      props.setTabViewingObjectTemporaryDataProps({
        tabId: activeMainTabId,
        temporaryDataProps: {
          activityDocumentPopup: state
        }
      })
    }

    const updateCompletionActualDate = ({
      date,
      isGotoActivity
    }: {
      date?: DateISOString
      isGotoActivity: boolean
    }) => {
      // oc(currentActivityGroup).businessActivity.type() === TransportationActivityViewDTO.TypeEnum.EMPTY
      const modifiedActivityGroup: TransportationActivityGroup = R.clone(transportationRow.activityGroup)

      if (isUnsuccessful) {
        if (isGotoActivity) {
          modifiedActivityGroup.gotoActivity.completionActualDate = date
        } else {
          modifiedActivityGroup.businessActivity.completionActualDate = date
        }
      } else if (isGotoActivity) {
        // GOTO ACTIVITY
        if (date) {
          // Date has been changed
          modifiedActivityGroup.gotoActivity.status = TransportationActivityViewDTO.StatusEnum.COMPLETED

          if (!modifiedActivityGroup.gotoActivity.startActualDate) {
            modifiedActivityGroup.gotoActivity.startActualDate = date
          }

          if (isDropBobtail) {
            modifiedActivityGroup.businessActivity.status = modifiedActivityGroup.gotoActivity.status =
              TransportationActivityViewDTO.StatusEnum.COMPLETED

            if (!modifiedActivityGroup.businessActivity.startActualDate) {
              modifiedActivityGroup.businessActivity.startActualDate = date
            }

            if (!modifiedActivityGroup.businessActivity.completionActualDate) {
              modifiedActivityGroup.businessActivity.completionActualDate = date
            }
          }
        } else {
          // Date has been removed
          if (modifiedActivityGroup.gotoActivity.completionActualDate) {
            modifiedActivityGroup.gotoActivity.status = TransportationActivityViewDTO.StatusEnum.INPROCESS
            modifiedActivityGroup.businessActivity.startActualDate = modifiedActivityGroup.businessActivity.completionActualDate = null
            modifiedActivityGroup.businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
          }
        }

        modifiedActivityGroup.gotoActivity.completionActualDate = date
      } else {
        // BUSINESS ACTIVITY
        if (date) {
          // Date has been changed
          modifiedActivityGroup.businessActivity.status = modifiedActivityGroup.gotoActivity.status =
            TransportationActivityViewDTO.StatusEnum.COMPLETED

          if (!modifiedActivityGroup.gotoActivity.startActualDate) {
            modifiedActivityGroup.gotoActivity.startActualDate = date
          }

          if (!modifiedActivityGroup.gotoActivity.completionActualDate) {
            modifiedActivityGroup.gotoActivity.completionActualDate = date
          }

          if (!modifiedActivityGroup.businessActivity.startActualDate) {
            modifiedActivityGroup.businessActivity.startActualDate = date
          }
        } else {
          // Date has been removed
          if (modifiedActivityGroup.businessActivity.completionActualDate) {
            modifiedActivityGroup.businessActivity.status = TransportationActivityViewDTO.StatusEnum.INPROCESS
          }
        }

        modifiedActivityGroup.businessActivity.completionActualDate = date
      }

      updateActivityRow({ ...transportationRow, activityGroup: modifiedActivityGroup }, ActivityGroupAction.update)
    }

    if (transportationRow.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
      if (isDrop || isDropBobtail) {
        bobtailGroup = getActivityDropBobtailGroup({
          activity: gotoActivity,
          activities: oc(dispatchDeliveryOrder).activities.transportationActivities([]),
          includeParentActivityGroup: true
        })

        bobtailGroup = bobtailGroup.length > 1 ? bobtailGroup : undefined
      }

      if (oc(businessActivity).destination.type() !== LocationViewDTO.TypeEnum.CONTAINERYARD) {
        if (isDropActivity(businessActivity) && (!bobtailGroup || bobtailGroup.length <= 3)) {
          dropTypeActivityRowToCreateBobtail = transportationRow
        }

        if (isPickActivity(businessActivity)) {
          pickTypeActivityRowToCreateBobtail = transportationRow
        }

        isDropBobtailValidationError =
          isDropBobtail &&
          !isUnsuccessful &&
          isDropBobtailGotoActivity(
            prevTransportationRowOfStage && prevTransportationRowOfStage.activityGroup.gotoActivity
          ) &&
          !isUnsuccessfulActivityGroup(prevTransportationRowOfStage && prevTransportationRowOfStage.activityGroup)
      }
    }

    return (
      <TransportationActivityRowContext.Provider
        value={{
          isBobtail,
          isDropBobtail,
          isPickBobtail,
          activityDocumentPopup,
          rowNumber,
          gotoActivity,
          businessActivity,
          linkedGotoActivity,
          linkedBusinessActivity,
          stage: transportationRow.stage,
          disabled: !allowModifying || !permissions.writable,
          isUnsuccessful,
          isModifiedMode,
          disableActualDates,
          activityWithWrongOrderOfPlannedDates,
          importDatesConfused,
          exportDatesConfused,
          dispatchDeliveryOrder,
          anotherDOOActivityLink,
          dropTypeActivityRowToCreateBobtail,
          pickTypeActivityRowToCreateBobtail,
          isDropBobtailValidationError,
          isLinkedActivityToBobtail,
          isDropActivity: isDrop,
          isPickActivity: isPick,
          allowModifying,
          transportationRow,
          prevTransportationRowOfStage,
          nextTransportationRowOfStage,
          stageActivityRows,
          rows,
          bobtailGroup,
          usefulRows,
          currentActivityGroup,
          updateActivityRow,
          tirDocument,
          podDocument,
          enablePODDocumnet,
          enableTIRDocumnet,
          disableEditDocument: Boolean(emptyRequiredFieldsForDocument),
          tirDocumentMessage,
          podDocumentMessage,
          troubleTicketId,
          gotoActivityStartDateRequired,
          gotoActivityStartDateDisabled,
          businessActivityStartDateRequired,
          businessActivityStartDateDisabled,
          gotoActivityCompleteDateRequired,
          gotoActivityCompleteDateDisabled,
          businessActivityCompleteDateRequired,
          businessActivityCompleteDateDisabled,
          appDateIsNotConfirmed,
          updateCompletionActualDate,
          modifyDispatchDeliveryOrder,
          openActivityDocumentPopup,
          setActivityDocumentPopup
        }}
        children={children}
      />
    )
  })
)
