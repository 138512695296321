import * as React from 'react'
import { ITabState, TabActionType } from '../../common/tabs/interfaces'
import { StyledTabActions } from './styles'
import { StreetTurn } from './StreetTurn'

type OwnProps = {
  actionsType: TabActionType
  fetchGridItems: (withoutSpinner?: boolean) => Promise<any>
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const GridActions = (props: Props) => {
  const { actionsType, fetchGridItems } = props

  return <StyledTabActions>{Content[actionsType]({ fetchGridItems })}</StyledTabActions>
}

const Content = {
  [TabActionType.streetTurn]: StreetTurn
}
