import { callAPI } from '../../../api/api'
import moveServiceAPI, { handleMoveServiceError } from '../../../components/CommunicationHub/epics/moveServiceAxios'
import { getDispatch } from '../../../store'
import { listsActions } from '../../../store/reducers/lists'
import { ListEntityType } from '../../../store/reducers/lists/interfaces'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'
import driverActivityAxios from './driverActivityAxios'
import { IDriverActivityDTO } from './interfaces'

export const requestDriversActvity = (): Promise<IDriverActivityDTO[]> => {
  return callAPI(() => driverActivityAxios.get(`/driver-activeness/`))
    .toPromise()
    .then(data => {
      if (data && Array.isArray(data.data) && data.data.length) {
        getDispatch()(
          listsActions.update({
            update: { [ListEntityType.driverActivity]: data.data }
          })
        )

        return data.data
      }

      return []
    })
}
