import * as React from 'react'
import { connect } from 'react-redux'
import { ILocation } from '../../../interfaces'
import ContactsPreviewTab from '../../../../contact/views'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

type StateProps = {
  actions: IGridItemActions
  location: ILocation
  isModified: boolean
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

const Contacts = ({ isModified, location, actions: { modify, setFetching } }: Props) => (
  <ContactsPreviewTab
    isModified={isModified}
    primaryContactId={location.primaryContactId}
    contactIds={location.contactIds}
    contacts={location.contacts}
    modifyContacts={({ contacts, contactIds }) => modify({ ...location, contacts, contactIds })}
    togglePreviewSpinner={setFetching}
  />
)

export default connect(
  null,
  null
)(Contacts)
