import { TGridItemInfo } from '../uiSettingsService/tabs'
import { omit } from 'remeda'
import { getStore } from '../../store/configureStore'
import { AnyAction } from 'typescript-fsa'
import { setTabViewingObject } from '../viewingObjects/actions'
import { oc } from 'ts-optchain'
import { addCreatedGridItem } from '../../components/common/tabs/actions'

export const addNewObjectsOrReplaceWithFullObjectsToStoreList = <T extends {}>(
  addToEachObject: object = {},
  initialList: object = {},
  modifyObject: (object: object) => object = object => object
): any => (store: T, { result }: { result: any[] }) => {
  const updateItems = result.filter(item => {
    const storeObject = store[item.id]

    if (
      (!storeObject && item.id) ||
      item.forceUpdate ||
      (item.version !== undefined && storeObject.version < item.version) ||
      (item.updatedAt &&
        (!storeObject.updatedAt || Date.parse(item.updatedAt) - Date.parse(storeObject.updatedAt) >= 0)) ||
      (!storeObject.fullObject && item.fullObject)
    ) {
      return true
    }
  })

  if (!updateItems.length) {
    return store
  }

  return {
    ...store,
    ...updateItems.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: {
          ...addToEachObject,
          ...modifyObject(omit(curr, ['forceUpdate']))
        }
      }),
      initialList
    )
  }
}

export const correctInsertionObjectsIntoStorage = async (
  storeItems: Record<string, any>,
  newItems: any | any[],
  addToStoreAction: (newItems: Record<string, any>) => AnyAction,
  addToEachObject: any = {},
  modifyObject: (object: any) => object = item => item
): Promise<Record<string, any>> => {
  if (!newItems || (Array.isArray(newItems) && newItems.length === 0)) {
    return
  }
  const newItemsList = Array.isArray(newItems) ? newItems : [newItems]
  const updateItems = newItemsList.filter(newItem => {
    if (!(newItem && newItem.id)) {
      return false
    }

    const storeItem = storeItems[newItem.id]

    return (
      !storeItem ||
      newItem.forceUpdate || // TODO DDO Street Turn
      (newItem.version !== undefined && newItem.version > storeItem.version) ||
      (newItem.updatedAt && (!storeItem.updatedAt || newItem.updatedAt > storeItem.updatedAt)) ||
      (!storeItem.fullObject && newItem.fullObject)
    )
  })

  if (!updateItems.length) {
    return
  }

  const uniqList = addToStoreAction(
    updateItems.reduce((acc, curr) => {
      acc[curr.id] = {
        ...addToEachObject,
        ...modifyObject(omit(curr, ['forceUpdate']))
      }
      return acc
    }, {})
  )

  getStore().dispatch(uniqList)

  return uniqList
}

// COMPARE OBJECTS BEFORE SAVING
export type TObjectStatusForSave = {
  noStoreDataForUpdate: boolean
  needToSave: boolean
  getDifference?: { newObject: any; oldObject: any } | undefined
  needToUpdate?: boolean
  needAuthAction?: boolean
}

export const isNewObject = (object: any): boolean =>
  object && object.id ? object.id.includes('new') || object.id.includes('fake') : false
export const isNewId = (id: string): boolean => (id ? id.includes('new') || id.includes('fake') : false)

export const compareUpdateDates = (object1: any, object2: any): boolean =>
  object1 && object2 && object1.fullObject && object2.fullObject && object1.updatedAt && object2.updatedAt
    ? object1.updatedAt === object2.updatedAt
    : true

export const replaceOldGridItemWithNew = (newItem: any, unitInfo: TGridItemInfo) => {
  const { tabId, applicationContext } = unitInfo
  const { dispatch, getState } = getStore()

  const viewingObject = oc(getState()).viewingObjects[tabId]({} as any)
  dispatch(
    setTabViewingObject({
      tabId,
      viewingObject: { ...viewingObject, id: newItem.id, modifiedObject: undefined, temporaryData: {} }
    })
  )
  dispatch(addCreatedGridItem({ tabId, unitId: newItem.id, context: applicationContext }))
}
