import nanoid = require('nanoid')
import { ITabState, TabType, ITabSettings } from '../../components/common/tabs/interfaces'
import { TTabTypes } from './interfaces'
import { headers as powerUnitHeaders } from '../../components/common/powerUnit/views/configGrid'
import { headers as equipmentHeaders } from '../../components/common/equipment/views/configGrid'
import { headers as containerHeaders } from '../../components/common/containers/views/configGrid'
import { headers as driverHeaders } from '../../components/common/drivers/views/configGrid'
import { headers as customerHeaders } from '../../components/common/customer/views/configGrid'
import { headers as steamShipLineHeaders } from '../../components/common/steamShipLine/views/configGrid'
import { headers as subClientHeaders } from '../../components/common/subClient/views/configGrid'
import { headers as usersHeader } from '../../components/common/administration/users/views/configGrid'
import { dispatchDeliveryOrderFilterSettings } from '../DTO/dispatchDeliveryOrder/filterSettings'
import { driverFilterSettings } from '../DTO/driver/filterSettings'
import { containerFilterSettings } from '../DTO/container/filterSettings'
import { locationFilterSettings } from '../DTO/location/filterSettings'
import { ApplicationContext } from '../../store/store.interface'
import { filteringStateByTabType, sortingStateByTabType } from '../tabs/functions'
import { defaultDispatchDeliveryOrderTabSettings } from '../DTO/dispatchDeliveryOrder/defaultTabSettings'
import { defaultLocationTabSettings } from '../DTO/location/defaultTabSettings'
import { customerFilterSettings } from '../DTO/customer/filterSettings'
import { steamShipLineFilterSettings } from '../DTO/steamShipLine/filterSettings'
import { subClientFilterSettings } from '../DTO/subClient/filterSettings'
import { SchedulerFilter, SchedulerSortBy } from '../../components/common/scheduler/interfaces'
import { OngoingActivityGroupDTO } from '../../api/origin/business-logic'
import { dateService } from '../timeService'
import { groupedDriverStatuses } from '../constants/driverStatuses'

export const tabTypes: TTabTypes = {
  main: [
    { callback: TabType.dispatchDeliveryOrder, name: 'Dispatch DO', icon: 'mdi-file-document' },
    { callback: TabType.scheduler, name: 'Scheduler', icon: 'mdi-view-dashboard' },
    { callback: TabType.QMP, name: 'QMP', icon: 'mdi-cash-usd' },
    { callback: TabType.powerUnit, name: 'Power Units', icon: 'mdi-truck' },
    { callback: TabType.equipment, name: 'Equipments', icon: 'mdi-truck-trailer' },
    { callback: TabType.container, name: 'Containers', icon: 'mdi-barcode' },
    { callback: TabType.location, name: 'Locations', icon: 'mdi-map-marker' },
    { callback: TabType.driver, name: 'Drivers', icon: 'mdi-seat-recline-normal' },
    { callback: TabType.customer, name: 'Customers', icon: 'mdi-tie' },
    { callback: TabType.steamShipLine, name: 'SSL', icon: 'mdi-anchor' },
    { callback: TabType.subClient, name: 'SubClients', icon: 'mdi-tie' },
    { callback: TabType.deliveryOrder, name: 'Delivery Order', icon: 'mdi-file-document-box' }
  ],
  admin: [{ callback: TabType.users, name: 'Users', icon: 'mdi-account' }],
  allTypes: function() {
    return [...this.main, ...this.admin]
  },
  icons: function(type) {
    return this[type].map(tab => tab.icon)
  },
  names: function(type) {
    return this[type].map(tab => tab.name)
  },
  callbacks: function(type) {
    return this[type].map(tab => tab.callback)
  },
  namesMap: {
    [TabType.powerUnit]: 'Power Units',
    [TabType.equipment]: 'Equipments',
    [TabType.container]: 'Containers',
    [TabType.location]: 'Locations',
    [TabType.driver]: 'Drivers',
    [TabType.customer]: 'Customers',
    [TabType.steamShipLine]: 'SSL',
    [TabType.subClient]: 'SubClients',
    [TabType.users]: 'Users',
    [TabType.dispatchDeliveryOrder]: 'Dispatch DO',
    [TabType.QMP]: 'QMP',
    [TabType.scheduler]: 'Scheduler',
    [TabType.surcharge]: 'Surcharges',
    [TabType.deliveryOrder]: 'Delivery Orders'
  }
}

export const settingsPerTabType: Record<string, ITabSettings> = {
  [TabType.powerUnit]: {
    columns: powerUnitHeaders,
    sorting: '-updatedAt'
  },
  [TabType.equipment]: {
    columns: equipmentHeaders,
    sorting: '-updatedAt'
  },
  [TabType.container]: {
    columns: containerHeaders,
    sorting: '-updatedAt'
  },
  [TabType.location]: defaultLocationTabSettings,
  [TabType.driver]: {
    columns: driverHeaders,
    sorting: '-updatedAt',
    filter: [
      { column: 'status', value: groupedDriverStatuses.Active.concat(groupedDriverStatuses.Inactive).map(String) }
    ]
  },
  [TabType.customer]: {
    columns: customerHeaders,
    sorting: '-createdAt'
  },
  [TabType.steamShipLine]: {
    columns: steamShipLineHeaders,
    sorting: '-updatedAt'
  },
  [TabType.subClient]: {
    columns: subClientHeaders,
    sorting: '-updatedAt'
  },
  [TabType.users]: {
    columns: usersHeader,
    sorting: '-updatedAt'
  },
  [TabType.dispatchDeliveryOrder]: defaultDispatchDeliveryOrderTabSettings,
  [TabType.deliveryOrder]: {
    columns: {},
    sorting: ''
  },
  [TabType.scheduler]: {
    sorting: SchedulerSortBy.date,
    filter: [
      {
        column: SchedulerFilter.grouping,
        value: OngoingActivityGroupDTO.GroupingEnum.STAGE.toString()
      },
      {
        column: SchedulerFilter.specificDate,
        value: dateService.createStringDate.startDay
      },
      {
        column: SchedulerFilter.activityGroupDate,
        value: { from: dateService.createStringDate.startDay, to: dateService.createStringDate.endDay }
      }
    ],
    columns: {}
  }
}

export const pluralNamePerTabType = {
  [TabType.powerUnit]: 'Power Units',
  [TabType.equipment]: 'Equipment',
  [TabType.location]: 'Locations',
  [TabType.container]: 'Containers',
  [TabType.driver]: 'Drivers',
  [TabType.customer]: 'Customers',
  [TabType.steamShipLine]: 'SSL',
  [TabType.subClient]: 'SubClients',
  [TabType.surcharge]: 'Surcharges',
  [TabType.users]: 'Users',
  [TabType.dispatchDeliveryOrder]: 'Dispatch DO',
  [TabType.deliveryOrder]: 'New Delivery Order',
  [TabType.QMP]: 'QMP',
  [TabType.scheduler]: 'Scheduler'
}

export const createDefaultTab = (tabType: TabType = TabType.dispatchDeliveryOrder): ITabState => ({
  title: `${pluralNamePerTabType[tabType]} List`,
  storeTab: true,
  visited: false,
  id: nanoid(),
  type: tabType,
  gridItemIds: null,
  data: null,
  enableEditing: true,
  enableFiltering: filteringStateByTabType(tabType),
  enableSorting: sortingStateByTabType(tabType),
  uiSettings: settingsPerTabType[tabType]
})

export function createIdListFromObjectList(array: any[]) {
  return array.map(({ id }) => id)
}

export const combinedColumnFilterSettings = () => ({
  [TabType.dispatchDeliveryOrder]: dispatchDeliveryOrderFilterSettings,
  [TabType.driver]: driverFilterSettings,
  [TabType.container]: containerFilterSettings,
  [TabType.location]: locationFilterSettings,
  [TabType.customer]: customerFilterSettings,
  [TabType.steamShipLine]: steamShipLineFilterSettings,
  [TabType.subClient]: subClientFilterSettings
})

export type TGridItemInfo = {
  applicationContext: ApplicationContext
  tabId: string
  unitId: string
}

export interface IModifyGridItemActions {
  getUnitInfo: TGridItemInfo
  reset(): void
  cancel(): void
  setFetching(state: boolean): void
  modify?(modifiedGridItem: any): void
  initialObjectState?: object
}

export type TModifyGridItem = {
  applicationContext: ApplicationContext
  tabId: string
}
