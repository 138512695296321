import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IBuySideQuoteState } from '../interfaces'
import { addBSQsToStore, deleteBSQIds } from '../actions'
import * as R from 'remeda'

const buySideQuoteState: IBuySideQuoteState = {}

export const buySideQuoteReducer = reducerWithInitialState<IBuySideQuoteState>(buySideQuoteState)
  .case(addBSQsToStore, (store, newItems) => {
    return Object.values(newItems).reduce(
      (acc, currBSQ) => {
        if (currBSQ.activityId) {
          acc[currBSQ.activityId] = currBSQ
        }

        return acc
      },
      { ...store, ...newItems }
    )
  })
  .case(deleteBSQIds, (store, deleteIds) => {
    const allItemIdsToDelete = deleteIds.reduce((acc, currId) => {
      const storeItem = store[currId]

      if (storeItem) {
        acc.push(storeItem.activityId)
      }

      return acc
    }, deleteIds)
    return R.omit(store, allItemIdsToDelete)
  })
  .build()
