import { callAPI, steamShipLineAPI } from '../../../../api/api'
import { addSteamShipLineToStore } from '../action'
import { getStore } from '../../../../store/configureStore'
import { SteamShipLineViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'

export const tryAddSteamShipLineToStore = (items: SteamShipLineViewDTO | SteamShipLineViewDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().steamShipLine, items, addSteamShipLineToStore)
}

export const getSteamShipLineById = (id: string): Promise<SteamShipLineViewDTO> =>
  callAPI(steamShipLineAPI.findById, id)
    .toPromise()
    .then(steamShipLine => {
      parseDTO.steamShipLine(steamShipLine)
      return steamShipLine
    })

export const findAllSteamShipLines = async ({ sort, filter }: any): Promise<SteamShipLineViewDTO[]> => {
  return callAPI(steamShipLineAPI.findAll, filter, sort)
    .toPromise()
    .then(steamShipLines => {
      parseDTO.steamShipLine(steamShipLines)
      return steamShipLines
    })
    .catch(catchListErrors)
}
