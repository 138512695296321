import * as React from 'react'
import styled, { withProps, keyframes, css } from '../../../styles/styledComponents'
import theme, { IStyledComponentsProps } from '../../../styles/theme'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const animation = css`
  animation: ${spin} 1s linear infinite;
`

const content = css`
  content: '\f349' !important;
`

export const ContainerInput = withProps<IStyledComponentsProps, HTMLDivElement>(styled.div)`
  width: 100%;
  position: relative;
  padding-right: 30px;
  background: ${theme.colors.basicBackgroundColor};
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border 0.25s ease;

  &:before {
    ${p => (p.loading ? animation : content)}
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: ${theme.colors.basicBlueColor} !important;
    font-size: 18px !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.isTable {
    bottom: 0;
  }

  &.required,
  &.errored {
    box-shadow: 0 0 0 1px ${theme.colors.defaultRed};
  }
`

export const Input = styled.input`
  padding: 0 10px;
  height: 33px;
  line-height: 33px;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: none;

  &::placeholder {
    opacity: 0.3;
  }
`

export const Menu = styled.ul`
  z-index: 5;
  overflow-y: auto;
  max-height: 300px;
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  background-color: hsl(0, 0%, 100%);
  width: 100%;
  position: absolute;
  top: 100%;

  &.isTable {
    top: 100%;
  }

  &.open {
    border-top: 2px solid ${theme.colors.basicBlueColor};

    & ~ .mdi-menu-down {
      background: none;

      &:after {
        border: none;
      }

      ${Input} {
        background: #f5f6fa;
        border-radius: 5px 5px 0 0;
      }
    }
  }
`

export const Item = styled.li`
  cursor: pointer;
  background: none;
  color: inherit;
  display: block;
  user-select: none;
  font-size: inherit;
  margin: 0 10px;
  padding: 12px 0;
  border-bottom: 1px solid ${theme.colors.basicBorderColor};

  span {
    font-size: 12px;
    color: ${theme.colors.basicBlueColor};
    margin-right: 5px;
  }
`

export const NotFound = () => <Item>Not found</Item>

export const Fetching = () => <Item>Fetching</Item>
