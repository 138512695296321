import * as equal from 'fast-deep-equal'
import { getStore } from '../../store/configureStore'
import { showModal, TMsgType } from '../../components/UI/Modal/actions'
import { TabType } from '../../components/common/tabs/interfaces'
import * as R from 'remeda'
import { saveLocation } from './location/save'
import { savePowerUnit } from './powerUnit/save'
import { saveEquipment } from './equipment/save'
import { saveDriver } from './driver/save'
import { saveSteamShipLine } from './steamShipLine/save'
import { saveContainer } from './container/save'
import { saveSubClient } from './subClient/save'
import { saveCustomer } from './customer/save'
import { saveDispatchDeliveryOrder } from './dispatchDeliveryOrder/save'
import { isNewObject, isNewId, replaceOldGridItemWithNew } from './index'
import { TContact } from '../../components/common/contact/interfaces'
import { AlertButtonColor } from '../../components/UI/Modal'
import { IModifyGridItemActions } from '../uiSettingsService/tabs'
import { callAPI } from '../../api/api'
import { showErrorModalWindow } from '../../store/reducers/modalWindow/functions'

export const convertErrorToMessage = (error: any, promiseInfo?: string): string => {
  if (typeof error === 'string') {
    return error
  }
  if (error.message) {
    return error.message
  }
  return 'Unknown error'
}

export const omitUpdateInfo = (obj: any) => {
  return obj ? R.omit(obj, ['updatedAt', 'updatedBy', 'createdBy', 'createdAt']) : obj
}

export const isEqualObjects = (obj1: any = {}, obj2: any = {}, omit: string[] = []) => {
  const commonArray = ['updatedAt', 'updatedBy', 'createdBy', 'createdAt', ...omit]
  return equal(R.omit(obj1, commonArray), R.omit(obj2, commonArray))
}

export const OmitContacts = (obj: any) => {
  return R.omit(obj || {}, ['contacts', 'primaryContact', 'primaryContactId'])
}

export const isEqualObjectsWithoutContacts = (obj1: any = {}, obj2: any = {}, omit: string[] = []) =>
  isEqualObjects(obj1, obj2, [...omit, 'contacts', 'primaryContact', 'primaryContactId'])

export const isEqualObjectsWithoutBusinessPartner = (obj1: any = {}, obj2: any = {}, omit: string[] = []) =>
  isEqualObjects(obj1, obj2, [...omit, 'businessPartner', 'businessPartnerId'])

export const createPromiseResolve = (object: any, actions: IModifyGridItemActions): Promise<any> =>
  Promise.resolve(object).then(data => {
    if (Boolean(actions)) {
      actions.reset()
    }
    return data
  })

export const makeSavePromiseWithCatch = (actions: IModifyGridItemActions, savePromise: Promise<any>) =>
  savePromise
    .catch(errors => {
      // tslint:disable-next-line:no-console
      console.error(errors)
      const result = (message: any) => {
        showErrorModalWindow({
          content: convertErrorToMessage(message, JSON.stringify(savePromise))
        })
      }

      return errors && errors.json
        ? errors
            .json()
            .then((error: any) => result(error))
            .catch(() => {})
        : result(errors)
    })
    .finally(() => {
      actions.setFetching(false)
    })

export const makeObjectSavePromise = (
  needToSave: boolean,
  object: any,
  actions: IModifyGridItemActions,
  callAPIAction: any,
  tryAddToListFunction: (resolvedData: any) => void = () => {}
) => {
  return needToSave
    ? (isNewObject(object)
        ? callAPI(callAPIAction.create, R.omit(object, ['id']))
        : callAPI(callAPIAction.update, object, object.id)
      )
        .toPromise()
        .then(async data => {
          tryAddToListFunction(data)

          if (Boolean(actions)) {
            if (isNewObject(object)) {
              replaceOldGridItemWithNew(data, actions.getUnitInfo)
            } else {
              actions.reset()
            }
          }
          return data
        })
    : createPromiseResolve(object, actions)
}

type TSave = {
  condition: boolean
  discardChanges: () => void
  save: () => void
  cancel?: () => void
  hideSpinner?: () => void
}

export const combineContactIds = (objectContactIds: string[] = [], requestedContacts: TContact[] = []): string[] => {
  const contactIds = new Set(objectContactIds)
  requestedContacts.map(_ => _.id).forEach(id => contactIds.add(id))
  return Array.from(contactIds)
}

export const combineIds = (arr1: string[], arr2: string[]): string[] => {
  const ids = new Set(arr1.filter(item => !isNewId(item)))
  arr2.filter(item => !isNewId(item)).forEach(id => ids.add(id))
  return Array.from(ids)
}

export const tryToSave = ({
  condition,
  save,
  discardChanges = () => {},
  cancel = () => {},
  hideSpinner = () => {}
}: TSave) => {
  const { dispatch } = getStore()

  const blockSaving = () => {
    hideSpinner()

    dispatch(
      showModal({
        msgType: TMsgType.info,
        buttonSettings: {
          button1: {
            color: AlertButtonColor.red,
            label: 'Save anyway',
            action: save
          },
          button2: {
            color: AlertButtonColor.blue,
            label: 'Cancel',
            action: cancel
          },
          button3: {
            color: AlertButtonColor.yellow,
            label: 'Discard my changes',
            action: discardChanges
          }
        },
        message: 'There is newer data for the object'
      })
    )
  }

  return condition ? save() : blockSaving()
}

// COMBINED SAVE ACTIONS
export const saveObject = {
  [TabType.location]: saveLocation,
  [TabType.powerUnit]: savePowerUnit,
  [TabType.equipment]: saveEquipment,
  [TabType.driver]: saveDriver,
  [TabType.steamShipLine]: saveSteamShipLine,
  [TabType.container]: saveContainer,
  [TabType.subClient]: saveSubClient,
  [TabType.customer]: saveCustomer,
  [TabType.dispatchDeliveryOrder]: saveDispatchDeliveryOrder
}
