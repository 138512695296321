import {
  IconDDODropEmptyWithChassis,
  IconDDODropEmptyWithoutChassis,
  IconDDODropFullWithChassis,
  IconDDODropFullWithoutChassis,
  IconDDOGetLoaded,
  IconDDOGetUnloaded,
  IconDDOPickupEmpty,
  IconDDOPickupFull,
  IconDDOReposition
} from '../../components/UI/icons/DDOStatusIcon'
import { BuySideQuoteDTO } from '../../api/origin/business-logic'

export default {
  [BuySideQuoteDTO.TypeEnum.BOBTAIL]: {
    title: 'Bobtail En route To',
    icon: undefined
  },
  [BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPEMPTY]: {
    title: 'Shuttle',
    icon: IconDDOPickupEmpty
  },
  [BuySideQuoteDTO.TypeEnum.SHUTTLEPICKUPFULL]: {
    title: 'Shuttle',
    icon: IconDDOPickupFull
  },
  [BuySideQuoteDTO.TypeEnum.DROPEMPTY]: {
    title: 'Drop',
    icon: IconDDODropEmptyWithChassis
  },
  [BuySideQuoteDTO.TypeEnum.DROPFULL]: {
    title: 'Drop',
    icon: IconDDODropFullWithChassis
  },
  [BuySideQuoteDTO.TypeEnum.PICKEMPTY]: {
    title: 'Pick',
    icon: IconDDOPickupEmpty
  },
  [BuySideQuoteDTO.TypeEnum.PICKFULL]: {
    title: 'Pick',
    icon: IconDDOPickupFull
  },
  [BuySideQuoteDTO.TypeEnum.LIVELOAD]: {
    title: 'Live Load',
    icon: IconDDOGetLoaded
  },
  [BuySideQuoteDTO.TypeEnum.LIVEUNLOAD]: {
    title: 'Live Unload',
    icon: IconDDOGetUnloaded
  },
  [BuySideQuoteDTO.TypeEnum.SHUTTLERETURNEMPTY]: {
    title: 'Shuttle',
    icon: IconDDODropEmptyWithoutChassis
  },
  [BuySideQuoteDTO.TypeEnum.SHUTTLERETURNFULL]: {
    title: 'Shuttle',
    icon: IconDDODropFullWithoutChassis
  },
  [BuySideQuoteDTO.TypeEnum.REPOSITION]: {
    title: `Reposition`,
    icon: IconDDOReposition
  },
  [BuySideQuoteDTO.TypeEnum.ADDITIONALSURCHARGE]: {
    title: `Addn'l Surcharges`,
    icon: (): null => null
  },
  [BuySideQuoteDTO.TypeEnum.UNSUCCESSFUL]: {
    title: `Unsuccessful`,
    icon: (): null => null
  }
}
