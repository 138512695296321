import * as React from 'react'
import { DeliveryOrderViewDTO, SteamShipLineDTO } from '../../../../../api/origin/business-logic'
import { ColumnRow, Container } from '../../../GridSystem'
import { Input } from '../../../DataFields/Input'
import DateField from '../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../DatePicker/Interfaces'
import { WidgetActions } from '../../styles'
import { SteamShipLinePropsType } from './index'
import { oc } from 'ts-optchain'
import { SteamShipLineSearch } from '../../../DataFields/SearchRequest'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { DateTimePicker } from '../../../DataFields/DateTimePicker/views'

export const Popover: React.FC<{
  steamShipLineIsRequired: boolean
  disabled?: boolean
  updateSteamShipLine: (steamShipLine: SteamShipLineDTO) => void
  deliveryOrder: DeliveryOrderViewDTO
  steamShipLineProps: SteamShipLinePropsType
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  isExport: boolean
}> = ({
  steamShipLineIsRequired,
  updateSteamShipLine,
  deliveryOrder,
  steamShipLineProps,
  updateDeliveryOrder,
  isExport,
  disabled
}) => {
  const { closePopover } = React.useContext(WidgetContext)

  return (
    <>
      <Container isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <SteamShipLineSearch
            disabled={disabled}
            title={'SSL Name'}
            required={steamShipLineIsRequired}
            value={oc(deliveryOrder).steamShipLine(null)}
            onChange={updateSteamShipLine}
          />
        </ColumnRow>

        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            title={'Vessel Name'}
            value={steamShipLineProps.vesselName}
            onChange={updateDeliveryOrder('vesselName')}
          />
        </ColumnRow>

        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            title={'Voyage #'}
            value={steamShipLineProps.voyage}
            onChange={updateDeliveryOrder('voyageNumber')}
          />
        </ColumnRow>

        <ColumnRow>
          <DateTimePicker
            title={isExport ? 'Vessel Departure (ETD)' : 'Vessel Arrival (ETA)'}
            disabled={disabled}
            date={steamShipLineProps.date}
            onChange={updateDeliveryOrder(isExport ? 'vesselDepartureDate' : 'vesselArrivalDate')}
          />
        </ColumnRow>
      </Container>
      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
