import * as React from 'react'
import { ITabState } from '../common/tabs/interfaces'
import { QMP } from '../common/QMP/views'

type OwnProps = {
  currentTab: ITabState
}
type Props = OwnProps

export const QMPPage = React.memo((props: Props) => {
  return <QMP currentTab={props.currentTab} />
})
