import { DriverViewDTO } from '../../../api/origin/business-logic'
import { tryAddDriverToStore } from '../../../components/common/drivers/epics'

export const parseDriver = (drivers: DriverViewDTO[]) => {
  // const lists: { businessPartners: BusinessPartnerViewDTO[] } = {
  //   businessPartners: []
  // }
  // const clearedDrivers = drivers.filter(Boolean).map(driver => {
  //   if (driver.businessPartner) {
  //     lists.businessPartners.push(driver.businessPartner)
  //   }
  //   return R.omit(driver, ['businessPartner'])
  // })
  //
  // parseDTO.businessPartner(lists.businessPartners)
  // tryAddDriverToStore(clearedDrivers)
  tryAddDriverToStore(drivers)
}
