import * as React from 'react'
import { ISubClient } from '../../interfaces'
import { General } from '../Details/General'
import Contacts from '../Details/Contacts'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.SubClient

export const SubClientPreview = {
  headings: [T.General, T.Contacts],
  tabs: {
    [T.General]: (subClient: ISubClient, actions: IGridItemActions) => (
      <General subClient={subClient} actions={actions} />
    ),
    [T.Contacts]: (subClient: ISubClient, actions: IGridItemActions, isModified: boolean) => (
      <Contacts isModified={isModified} subClient={subClient} actions={actions} />
    )
  },
  isValid: checkValidation.subClient
}
