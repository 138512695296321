import { oc } from 'ts-optchain'
import * as R from 'remeda'
import nanoid = require('nanoid')
import { RawDraftContentState } from 'draft-js'
import { MessageType } from '../../../api/origin/communication-hub-service'
import { FileCategory } from '../../../services/filesService/interfaces'
import { TroubleTicketDTO } from '../../../api/api'
import { getListState } from '../../../store'

export const getTextWithMentionIds = (
  text: RawDraftContentState
): {
  text: any
  mentionUserIds: string[]
} => {
  const mentionUserIds = Object.values(oc(text).entityMap() || {})
    .filter(_ => oc(_ as any).type() === 'mention')
    .map(_ => oc(_ as any).data.mention.id())
    .filter(Boolean)

  return {
    text: { ...R.omit(text, ['entityMap']) },
    mentionUserIds
  }
}

export const convertMentionsToEntityMap = (
  text: RawDraftContentState,
  mentionUserIds: string[],
  type?: MessageType,
  alertDocumentType?: TroubleTicketDTO.TypeEnum
): RawDraftContentState => {
  if (!text) {
    return text
  }

  const result = { ...text, entityMap: {} }

  if (!mentionUserIds || !mentionUserIds.length) {
    return { ...text, entityMap: {} }
  }

  const contacts = getListState().contact

  result.entityMap = mentionUserIds.reduce((acc: any, currUserId: string, index: number) => {
    acc[index] = {
      mutability: 'IMMUTABLE',
      type: 'mention',
      data: {
        mention: contacts[currUserId] || {}
      }
    }

    return acc
  }, {})

  switch (type) {
    case MessageType.ALERT:
      let extraText = 'Trouble ticket'

      if (alertDocumentType === TroubleTicketDTO.TypeEnum.POD || alertDocumentType === TroubleTicketDTO.TypeEnum.TIR) {
        extraText = alertDocumentType + ' ' + extraText
      }

      result.blocks = [
        {
          data: {},
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: extraText.length, offset: 0 }],
          key: nanoid(5),
          text: extraText,
          type: 'unstyled'
        },
        ...(result.blocks || [])
      ]
      break
    default:
  }

  return result
}
