import * as React from 'react'
import { Column, DefaultColumn } from '../../../../../UI/GridSystem'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { StyledLeft, Top, DOIndicator } from './styles'
import { DeliveryOrderViewDTO, EquipmentDTO } from '../../../../../../api/origin/business-logic'
import Activity from '../../../../../UI/Widget/widgets/activity/'
import Customer from '../../../../../UI/Widget/widgets/customer/'
import Equipment from '../../../../../UI/Widget/widgets/equipment'
import { ICustomerView } from '../../../../customer/interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { DDOContext } from '../../../../../../services/DTO/dispatchDeliveryOrder/context'
import { QuotesWidget } from '../../../../../UI/Widget/widgets/quote'
import { calculateQuoteWidget } from '../../../../../UI/Widget/widgets/quote/functions'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import styled from 'styled-components'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'

type Props = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateCustomer: (customer: ICustomerView, updateInitialState?: boolean) => void
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  updateEquipment: (value: EquipmentDTO) => void
  actions: IGridItemActions
}

const Left: React.SFC<Props> = ({
  dispatchDeliveryOrder,
  updateDeliveryOrder,
  updateDispatchDeliveryOrderField,
  updateDispatchDeliveryOrder,
  updateCustomer,
  updateEquipment,
  actions
}) => {
  const {
    saveButton,
    state: {
      isDDOValid,
      marks: { reefer }
    },
    functions: { updateContainerType, updateContainer }
  } = React.useContext(DDOContext)

  const isModifiedMode = actions.isModified
  const quoteAmounts = calculateQuoteWidget(actions.isModified, dispatchDeliveryOrder)

  return (
    <StyledLeft>
      <Top>
        <DOIndicator style={{ marginLeft: -5 }}>{dispatchDeliveryOrder.deliveryOrder.type}</DOIndicator>
        <Column columns={7.5} isFixed={true} margin={{ left: 20 }}>
          <DateTimePicker
            title={'DO Date'}
            required={true}
            disabled={actions.enableEditing === false || dispatchDeliveryOrder.deliveryOrder.dateLocked}
            date={dispatchDeliveryOrder.deliveryOrder.date}
            onChange={updateDeliveryOrder('date')}
          />
        </Column>
        <Icons>
          {reefer && ddoDirectory.icons.refrigerator}
          {dispatchDeliveryOrder.hazmatIndicator && ddoDirectory.icons.hazmat}
          {dispatchDeliveryOrder.overweightIndicator && ddoDirectory.icons.overweight}
          {dispatchDeliveryOrder.autoIndicator && ddoDirectory.icons.auto}
        </Icons>
      </Top>

      <DefaultColumn isGrid={true} flexClear={true} padding={{ top: 15, right: 16, left: 16 }}>
        <DefaultColumn margin={{ bottom: 15 }}>
          <Activity
            disabled={actions.enableEditing === false}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            activities={dispatchDeliveryOrder.activities.transportationActivities}
            updateDispatchDeliveryOrder={updateDispatchDeliveryOrder}
            updateDispatchDeliveryOrderField={updateDispatchDeliveryOrderField}
            saveModifiedUnitAnyway={actions.saveModifiedUnitAnyway}
            saveButton={saveButton}
          />
        </DefaultColumn>

        <DefaultColumn margin={{ bottom: 15 }}>
          <Customer
            disabled={actions.enableEditing === false}
            customer={dispatchDeliveryOrder.deliveryOrder.customer as ICustomerView}
            updateCustomer={updateCustomer}
            isModifiedMode={isModifiedMode}
          />
        </DefaultColumn>

        <DefaultColumn>
          <Equipment
            disabled={actions.enableEditing === false}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            updateEquipment={updateEquipment}
            updateContainer={updateContainer}
            updateContainerType={updateContainerType}
            updateDispatchDeliveryOrder={updateDispatchDeliveryOrderField}
            isModifiedMode={isModifiedMode}
          />
        </DefaultColumn>

        <DefaultColumn margin={{ top: 25 }}>
          <QuotesWidget
            isDDOValid={isDDOValid}
            actions={actions}
            quoteAmounts={quoteAmounts}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
          />
        </DefaultColumn>
      </DefaultColumn>
    </StyledLeft>
  )
}

const Icons = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, auto);
  grid-gap: 5px;
  font-size: 18px;
  margin-left: auto;
`

export default Left
