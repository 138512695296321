import * as React from 'react'
import { DDOActivityTable } from './styles'
import { DDOActivityTableHeader } from './Header'
import { DDOActivityTableBody } from './Body'
import { IDispatchDeliveryOrder } from '../../../../interfaces'
import { CustomerQuoteDTO } from '../../../../../../../api/origin/qmp-service'
import { CorrectActivityData } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { ILocation } from '../../../../../location/interfaces'

type Props = {
  setFetching: (state: boolean) => void
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  modifyDispatchDeliveryOrder: (state: IDispatchDeliveryOrder) => void
  buySideQuotes: any[]
  sellSideQuote: any
  updateDDOActivitiesData: (
    correctActivityData: CorrectActivityData,
    updateLocationForInitialObjectState?: ILocation
  ) => void
  allowModifying: boolean
  quoteAmounts: {
    profit: number
    sellSideQuoteTotalAmount: number
    buySideQuoteTotalAmount: number
    buySideQuotesAmount: Record<string, number>
  }
  customerQuote?: CustomerQuoteDTO
  unlimitedHeight?: boolean
}

export const Table = (props: Props) => {
  return (
    <DDOActivityTable style={props.unlimitedHeight ? { height: 'auto' } : undefined}>
      <DDOActivityTableHeader />
      <DDOActivityTableBody {...props} />
    </DDOActivityTable>
  )
}
