import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'
import { customerLists } from '../../select/customerLists'

export const customerFilterSettings = {
  'Date Created': {
    name: 'createdAt',
    type: GridHeaderColumnType.date,
    newDateTimePicker: true
  },
  Name: {
    name: 'businessPartner.legalName',
    type: GridHeaderColumnType.string
  },
  Address: {
    name: 'businessPartner.legalAddress',
    type: GridHeaderColumnType.string
  },
  'Sales Rep': {
    name: 'salesRepUserId',
    type: GridHeaderColumnType.multiSelectList,
    disableSorting: true
  },
  'Net Terms Unit': {
    name: 'netTermsUnit',
    type: GridHeaderColumnType.list,
    items: customerLists.netTermsUnit
  },
  'Net Terms': {
    name: 'netTerms',
    type: GridHeaderColumnType.list,
    items: customerLists.netTerms
  },
  'Primary Contact': {
    name: 'businessPartner.primaryContact.firstName',
    type: GridHeaderColumnType.string
  }
}
