import { ISSQRatesState, IBSQRatesState } from '../interfaces'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { addSSQRatesToStore, addBSQRatesToStore } from '../actions'

const ssqRatesState: ISSQRatesState = {}
const bsqRatesState: IBSQRatesState = {}

export const SSQRatesReducer = reducerWithInitialState<ISSQRatesState>(ssqRatesState)
  .case(addSSQRatesToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()
export const BSQRatesReducer = reducerWithInitialState<IBSQRatesState>(bsqRatesState)
  .case(addBSQRatesToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()
