import { oc } from 'ts-optchain'
import { IStore } from '../../../store/store.interface'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from './'
import {
  DocumentationActivityDTO,
  TransportationActivityViewDTO,
  VendorViewDTO
} from '../../../api/origin/business-logic'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { ActivityViewType } from '../activity/interfaces'
import { getNewStoreState, RootState } from '../../../store'

type Props = {
  id: string
  store?: IStore
  newStore?: RootState
  type?: ActivityViewType
  simplify?: boolean
}

export const assembleActivity = (props: Props): ActivityViewDTO => {
  const store = props.store || getStore().getState()
  const newStore = props.newStore || getNewStoreState()

  switch (props.type) {
    case ActivityViewType.transportation: {
      const activity = store.activity.transportationActivities[props.id]
      return (activity
        ? getFullTransportationActivity(activity, store, newStore)
        : null) as TransportationActivityViewDTO
    }
    case ActivityViewType.documentation: {
      return store.activity.documentationActivities[props.id] as DocumentationActivityDTO
    }
    default: {
      if (store.activity.transportationActivities[props.id]) {
        // is Transportation Activity
        const activity = store.activity.transportationActivities[props.id]
        return getFullTransportationActivity(activity, store, newStore) as TransportationActivityViewDTO
      } else if (store.activity.documentationActivities[props.id]) {
        // is Documentation Activity
        return store.activity.documentationActivities[props.id] as DocumentationActivityDTO
      }

      return null
    }
  }
}

const getFullTransportationActivity = (
  transportationActivity: TransportationActivityViewDTO,
  store: IStore = getStore().getState(),
  newStore: RootState = getNewStoreState()
): TransportationActivityViewDTO => {
  if (!transportationActivity) {
    return null
  }

  return {
    ...transportationActivity,
    destination:
      assembleDTO.location({ store, id: transportationActivity.destinationId }) || transportationActivity.destination,
    vendor: (oc(newStore).lists.driver[transportationActivity.vendorId](
      transportationActivity.vendor as any
    ) as unknown) as VendorViewDTO
  }
}
