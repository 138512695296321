import * as React from 'react'
import styled from 'styled-components'
import { IDriver } from '../../../interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { DriverViewDTO } from '../../../../../../api/api'
import { FileVersionDTO } from '../../../../../../api/origin/document-service'
import { isDriverFieldRequired } from '../../../../../../services/functions/test/isDriverFieldRequired'

type Props = {
  driver: IDriver
  actions: IGridItemActions
}

export const DocumentsTab = (props: Props) => {
  const { driver, actions } = props
  const { modify, modifyParentObjectField } = actions
  const isCompany = driver.driverType === DriverViewDTO.DriverTypeEnum.COMPANY
  const isOwnerOperator = driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR

  const requiredField = isDriverFieldRequired(driver)

  return (
    <Container>
      {(isOwnerOperator || isCompany) && (
        <ViewingObjectFileUploader
          required={requiredField.applicationDocumentFile}
          uploadingFileId={driver.id + ' driver.applicationDocumentFile'}
          title={'Application Form'}
          file={driver.applicationDocumentFile}
          onFileUploaded={(applicationDocumentFile, modifiedObject) => ({
            ...modifiedObject,
            applicationDocumentFile
          })}
          onClearFile={() => modifyParentObjectField('applicationDocumentFile')(undefined)}
          historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORAPPLICATION }}
        />
      )}
      {isOwnerOperator && (
        <ViewingObjectFileUploader
          required={requiredField.leaseDocumentFile}
          uploadingFileId={driver.id + ' driver.leaseDocumentFile'}
          title={'Lease Agreement'}
          file={driver.leaseDocumentFile}
          onFileUploaded={(leaseDocumentFile, modifiedObject) => ({
            ...modifiedObject,
            leaseDocumentFile
          })}
          onClearFile={() => modifyParentObjectField('leaseDocumentFile')(undefined)}
          historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORLEASE }}
        />
      )}
      {requiredField.w9DocumentFile && (
        <ViewingObjectFileUploader
          required={requiredField.w9DocumentFile}
          uploadingFileId={driver.id + ' driver.w9DocumentFile'}
          title={'W9'}
          file={driver.w9DocumentFile}
          onFileUploaded={(w9DocumentFile, modifiedObject) => ({
            ...modifiedObject,
            w9DocumentFile
          })}
          onClearFile={() => modifyParentObjectField('w9DocumentFile')(undefined)}
          historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORW9 }}
        />
      )}
      {isOwnerOperator && (
        <ViewingObjectFileUploader
          required={requiredField.i9DocumentFile}
          uploadingFileId={driver.id + ' driver.i9DocumentFile'}
          title={'I9'}
          file={driver.i9DocumentFile}
          onFileUploaded={(i9DocumentFile, modifiedObject) => ({
            ...modifiedObject,
            i9DocumentFile
          })}
          onClearFile={() => modifyParentObjectField('i9DocumentFile')(undefined)}
          historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORI9 }}
        />
      )}
      {isCompany && (
        <ViewingObjectFileUploader
          uploadingFileId={driver.id + ' driver.w4DocumentFile'}
          title={'W4'}
          file={driver.w4DocumentFile}
          onFileUploaded={(w4DocumentFile, modifiedObject) => ({ ...modifiedObject, w4DocumentFile })}
          onClearFile={() => modifyParentObjectField('w4DocumentFile')(undefined)}
          historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORW4 }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 405px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 55px);
  grid-gap: 15px;
  padding: 40px;
`
