import { combineEpics } from 'redux-observable'
import { ContactDTO } from '../../../../api/origin/business-logic'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { getStore } from '../../../../store/configureStore'
import { addContactToStore } from '../action'

export const tryAddContactToStore = (items: ContactDTO | ContactDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().contact, items, addContactToStore)
}

export default combineEpics()
