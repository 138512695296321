import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { documentServiceControllerApi } from '../../../../../../api/document-service'
import { allowActivitiesModifying } from '../../../../../../services/DTO/activity'
import { connect } from 'react-redux'
import { showModal, TMsgType } from '../../../../../UI/Modal/actions'
import { Button, Wrapper } from './styles'

type OwnProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

type DispatchProps = {
  openModal: () => void
}

type Props = OwnProps & DispatchProps

const DownloadWorkOrder: React.SFC<Props> = ({ dispatchDeliveryOrder, openModal }) =>
  allowActivitiesModifying(dispatchDeliveryOrder) && (
    <Wrapper>
      <Button
        onClick={() =>
          documentServiceControllerApi
            .generateWorkOrderUrl(dispatchDeliveryOrder.id)
            .then(({ url, name }) => {
              // this allow to fast get file from same tab
              // window.location.assign(url)

              // Fetch from google cloud need CORS, we can setup it or use some hacks.
              // Hack on the top reassign download url to same browser tab.
              // You'll have warning at console but it didn't affect anything.
              // Below you'll find a call when CORS is properly setup.
              downloadWorkOrderFile({ name, url })
            })
            .catch(err => {
              // catch empty doc, we use 422 for that
              if (err.status === 422) {
                return openModal()
              }
              // tslint:disable-next-line:no-console
              console.error(err)
            })
        }
      >
        <i className={'mdi mdi-download'} />
        Download Work Order
      </Button>
    </Wrapper>
  )

export default connect(
  null,
  (dispatch): DispatchProps => ({
    openModal: () =>
      dispatch(
        showModal({
          msgType: TMsgType.info,
          message: `Please add driver to any Activity and save DDO to get Work Order`,
          onConfirm: () => {},
          onCancel: () => {}
        })
      )
  })
)(DownloadWorkOrder)

export const downloadWorkOrderFile = (props: { name?: string; url: string }) => {
  const { name = 'file', url } = props

  fetch(url).then(t => {
    return t.blob().then(b => {
      const link = document.createElement('a')
      link.target = '_blank'
      link.style.display = 'none'
      link.download = name
      link.href = URL.createObjectURL(b)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  })
}
