import * as React from 'react'
import { WidgetTable } from './Table'
import styled from 'styled-components'
import { createNewTab } from '../common/tabs/actions'
import { ListRequestType, TabActionType, TabType } from '../common/tabs/interfaces'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO } from '../../api/origin/business-logic'
import { findAllStreetTurnDispatchDeliveryOrders } from '../../services/DTO/streetTurn/epics'
import {
  convertISODateToDateWithHoursMins,
  convertRangeISODateToDateWithHoursMins
} from '../../services/timeService/dateUtils'
import { oc } from 'ts-optchain'
import { IDispatchDeliveryOrder } from '../common/dispatchDeliveryOrder/interfaces'
import { StreetTurnActions } from '../common/dispatchDeliveryOrder/views/Details/CustomHeader/StreetTurn'
import { dateService } from '../../services/timeService'
import { getStore } from '../../store/configureStore'

type OwnProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  setFetching: ((state: boolean) => void) | undefined
  hideStreetTurnActions?: boolean
  onLinkClick?: () => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const StreetTurnTable = (props: Props) => {
  const { dispatchDeliveryOrder, hideStreetTurnActions, onLinkClick } = props
  const [fetching, setFetching] = React.useState(true)
  const [ddoList, setDdoList] = React.useState([])
  const isExportDDO = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT

  React.useEffect(() => {
    findAllStreetTurnDispatchDeliveryOrders({ dispatchDeliveryOrderId: dispatchDeliveryOrder.id, saveToStore: false })
      .then(list => {
        if (list.length && ddoList) {
          setDdoList(list)
        }
      })
      .finally(() => {
        if (fetching) {
          setFetching(false)
        }
      })
  }, [])

  if (fetching) {
    return <Container className={'fetching'} />
  }

  if (ddoList.length === 0) {
    return null
  }

  const openLink = (selectedDDOId?: string) => {
    if (onLinkClick) {
      onLinkClick()
    }

    getStore().dispatch(
      createNewTab({
        tabType: TabType.dispatchDeliveryOrder,
        data: {
          streetTurnRequested: false,
          selectedIds: [dispatchDeliveryOrder.id, selectedDDOId].filter(Boolean),
          disabledSelectIds: [dispatchDeliveryOrder.id],
          pinnedIds: [dispatchDeliveryOrder.id, selectedDDOId].filter(Boolean),
          hideUnselectedGridItems: Boolean(selectedDDOId)
        },
        options: {
          title: 'Street Turn',
          storeTab: false,
          alwaysDoListRequestOnTabEnter: true,
          actionsType: TabActionType.streetTurn,
          detailsRequestType: ListRequestType.streetTurn,
          listRequestType: ListRequestType.streetTurn,
          listRequestProps: { dispatchDeliveryOrderId: dispatchDeliveryOrder.id },
          enableEditing: false,
          enableSorting: false,
          enableFiltering: false,
          ignoreWS: true
        }
      })
    )
  }

  return (
    <Container>
      <Header>
        <div>Street Turn</div>
        {!dispatchDeliveryOrder.streetTurnId ? (
          <Link onClick={() => openLink()}>Go to Matched List</Link>
        ) : (
          !hideStreetTurnActions && (
            <Actions>
              <StreetTurnActions streetTurn={dispatchDeliveryOrder.streetTurn} setFetching={props.setFetching} />
            </Actions>
          )
        )}
      </Header>
      <div style={tableStyles}>
        <WidgetTable
          tableStyles={{ width: '100%' }}
          header={
            isExportDDO
              ? ['DDO#', 'Container #', 'Delivery', 'Distance', 'LFD', 'Days Out']
              : ['DDO#', 'Delivery', 'Distance', 'Appointment', 'Cutoff Date']
          }
          body={ddoList.map((ddo: DispatchDeliveryOrderViewDTO) => {
            const DDOLink = () => <DDONumberLink onClick={() => openLink(ddo.id)}>{ddo.number}</DDONumberLink>

            return isExportDDO
              ? [
                  !dispatchDeliveryOrder.streetTurnId ? <DDOLink key={ddo.id} /> : ddo.number,
                  oc(ddo).container.number(''),
                  oc(ddo).deliveryStage.location.cityStateZip(''),
                  '',
                  dateService.makeLabel(oc(ddo).deliveryOrder.lastFreeDateDemurrage()),
                  ''
                ]
              : [
                  !dispatchDeliveryOrder.streetTurnId ? <DDOLink key={ddo.id} /> : ddo.number,
                  oc(ddo).deliveryStage.location.cityStateZip(''),
                  '',
                  convertISODateToDateWithHoursMins(oc(ddo).deliveryStage.actualAppointmentDate(), true, false) ||
                    convertRangeISODateToDateWithHoursMins(
                      oc(ddo).deliveryStage.plannedAppointmentDateTimeRange(),
                      true,
                      false
                    ),
                  dateService.makeLabel(oc(ddo).deliveryOrder.generalCutoffDate())
                ]
          })}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: 250px;
  transition: height 0.5s, opacity 0.5s 0.5s;

  &.fetching {
    height: 0;
    opacity: 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #4f4f4f;
  margin-bottom: 10px;
`
const Actions = styled.div`
  display: flex;
  margin-left: auto;
`
const Link = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #4555d0;
  cursor: pointer;
  margin-left: auto;
`

const DDONumberLink = styled.span`
  font-weight: 500;
  color: #4555d0;
  cursor: pointer;
`

// mdi mdi-map-marker

const tableStyles = { margin: '0 -16px', height: 215, overflow: 'auto' }
