import '../../services/rxjsService/operators/ofAction'
import '../../services/rxjsService/operators/ofActions'
import '../../services/rxjsService/operators/apiRequest'
import { combineEpics } from 'redux-observable'
import { tabsEpic } from '../../components/common/tabs/epics'
import viewingObjectsEpic from '../../services/viewingObjects/epics'
import authServiceEpic from '../../services/authService/epics'
import { businessPartnerEpic } from '../../components/common/businessPartner/epics'

export default combineEpics(authServiceEpic, tabsEpic, viewingObjectsEpic, businessPartnerEpic)
