import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { DetailsViewHeader } from '../DetailsView/DetailsViewHeader'
import { UserAvatar, UserAvatarSize } from '../UserAvatar'
import theme from '../../../../styles/theme'
import { IconName } from '../Icon'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import {
  selectRouteBuilderDriverAuthUserId,
  selectRouteBuilderDriverId
} from '../../../../store/select/routeBuilderSelect'
import { selectContact } from '../../../../store/select/contactSelect'
import { selectDriver } from '../../../../store/select/driverSelect'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useDriverActivity } from '../../../../hooks/useDriverActivity'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const RouteBuilderHeader = (props: Props) => {
  const dispatch = useAppDispatch()
  const authUserId = useAppSelector(selectRouteBuilderDriverAuthUserId)
  const vendorId = useAppSelector(selectRouteBuilderDriverId)
  const user = useAppSelector(selectContact(authUserId))
  const driver = useAppSelector(selectDriver(vendorId))
  const { showOnline, showOffline, showGeneratedLastActiveDate } = useDriverActivity({
    driverIdOrAuthUserId: authUserId
  })

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [user])

  const ChannelNameComponent = user ? (
    <Name data-for={'user-details-hint'} data-place={'bottom'} data-tip={user.id}>
      <UserAvatar user={user} size={UserAvatarSize.medium} />
      {(driver && driver.name) || user.name}
      {showOnline && <StatusIndicator style={{ backgroundColor: '#65D16C' }}>Online</StatusIndicator>}
      {showOffline && <StatusIndicator style={{ backgroundColor: theme.colors.offline }}>Offline</StatusIndicator>}
    </Name>
  ) : (
    'Choose Driver'
  )

  const RenderRightComponnet = () => {
    return <LastActivityDate>{showGeneratedLastActiveDate}</LastActivityDate>
  }

  return (
    <DetailsViewHeader
      title={ChannelNameComponent}
      extraRightBlock={showGeneratedLastActiveDate ? <RenderRightComponnet /> : undefined}
      closeButtonId={oc(user).id('ChooseDriver')}
      onCloseButtonClick={() => dispatch(communicationHubActions.closeDetailsView())}
      closeButtonIcon={IconName.CLOSE_DETAILS}
    />
  )
}

const Name = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }

  * {
    margin-right: 7px;
  }
`
const StatusIndicator = styled.div`
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 10px;
  padding: 3px 8px;
  margin-left: 24px;
`

const LastActivityDate = styled.div`
  font-size: 12px;
  color: rgba(68, 83, 102, 0.8);
  margin-right: 25px;
  user-select: none;
`
