import * as React from 'react'
import { IViewingObjectTemporaryData } from '../services/viewingObjects/interfaces'

export interface IGridItemActions {
  enableEditing?: boolean
  temporaryData: IViewingObjectTemporaryData | undefined
  isFetching: boolean | string
  isModified: boolean
  initialObjectState: any
  setFetching(fetchingState: boolean | string): void
  togglePreviewTab(tabName: string): void
  modify(modifiedUnit: any, updatedInitialObjectState?: any): void
  modifyParentObjectField(field: string): (value: any, updatedInitialObjectState?: any) => void
  cancel(): void
  save(): void
  saveModifiedUnitAnyway?(updatedUnit: any, initialObjectState?: any): void
}

export type TGridItemContext = {
  actions: IGridItemActions
}

export const GridItemContext: React.Context<TGridItemContext> = React.createContext(null)
