import * as React from 'react'
import cn from 'classnames'
import { IGridItemActions } from '../../contexts/GridItemContext'
import { AvailabilitySelecter, Button, DropDown, Selected } from './Availability/styledComponents'
import { WindowPopover } from './windowPopover'

type Props = {
  selectedItem: string
  actions: IGridItemActions
  stateActive: boolean
  activeList: string[]
  inactiveList: string[]
  handleSelect: (item: string) => void
  labelElement?: (props: { containerRef: any; togglePopover: () => void }) => JSX.Element
  attributes?: any
}

export const AvailabilityColumnSelecter = (props: Props) => {
  const [open, setOpen] = React.useState(false)
  const columnRef = React.useRef(null)
  const { selectedItem, actions, activeList, inactiveList, stateActive, attributes, handleSelect, labelElement } = props
  let hoverOffTimer: any

  const togglePopover = () => (actions ? setOpen(!open) : undefined)

  const handleHoverOn = () => {
    if (hoverOffTimer) {
      clearTimeout(hoverOffTimer)
      hoverOffTimer = null
    }
  }

  const handleHoverOff = () => {
    hoverOffTimer = setTimeout(() => {
      setOpen(false)
    }, 500)
  }

  return (
    <AvailabilitySelecter
      className={'availabilitySelecter'}
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
      onClick={event => {
        if (actions) {
          event.stopPropagation()
        }
      }}
    >
      {Boolean(labelElement) ? (
        labelElement({ containerRef: columnRef, togglePopover })
      ) : (
        <Selected
          ref={columnRef}
          className={cn({ isActive: stateActive, isInactive: !stateActive })}
          {...attributes || {}}
          onClick={togglePopover}
        >
          <Button
            className={`mdi ${open ? 'mdi-menu-up' : 'mdi-menu-down'}`}
            style={actions ? { opacity: 1 } : undefined}
          />
          {selectedItem}
        </Selected>
      )}

      {open && (
        <WindowPopover bindToRef={columnRef}>
          <DropDown>
            <span>Active</span>
            <ul>
              {activeList.map((item: string) => (
                <li
                  key={item}
                  className={'mdi mdi-checkbox-blank-circle' + (item === selectedItem ? ' active' : '')}
                  onClick={item !== selectedItem ? () => handleSelect(item) : undefined}
                >
                  {item}
                </li>
              ))}
            </ul>
            <span>Inactive</span>
            <ul>
              {inactiveList.map((item: string) => (
                <li
                  key={item}
                  className={'mdi mdi-checkbox-blank-circle' + (item === selectedItem ? ' active' : '')}
                  onClick={item !== selectedItem ? () => handleSelect(item) : undefined}
                >
                  {item}
                </li>
              ))}
            </ul>
          </DropDown>
        </WindowPopover>
      )}
    </AvailabilitySelecter>
  )
}
