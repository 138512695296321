import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { generateDispatchDeliveryOrderChatChannel } from '../../functions'
import { ddoDirectory } from '../../../../services/DTO/dispatchDeliveryOrder/directory'
import { connect } from 'react-redux'
import { IStore } from '../../../../store/store.interface'
import { IDispatchDeliveryOrder } from '../../../common/dispatchDeliveryOrder/interfaces'
import theme from '../../../../styles/theme'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannel } from '../../../../store/select/communicationHubSelect'
import { requestDispatchDeliveryOrdersByIds } from '../../../common/dispatchDeliveryOrder/epics'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'

type OwnProps = {
  channelId: string
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder | undefined
}

type Props = OwnProps & StateProps

const requestBatchDDODetails = (() => {
  let timer: NodeJS.Timeout & number = undefined
  let dispatchDeliveryOrderIds: string[] = []

  return (id: string) => {
    dispatchDeliveryOrderIds.push(id)

    if (timer) {
      clearTimeout(timer)
    }

    // @ts-ignore
    timer = setTimeout(() => {
      requestDispatchDeliveryOrdersByIds(dispatchDeliveryOrderIds, { notFullObject: true })
      dispatchDeliveryOrderIds = []
    }, 300)
  }
})()

const Component = React.memo((props: Props) => {
  const { channelId, dispatchDeliveryOrder } = props
  const dispatch = useAppDispatch()
  const storeChannel = useAppSelector(selectCommunictaionHubChannel(channelId))
  const channel =
    storeChannel ||
    (dispatchDeliveryOrder ? generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder) : undefined)

  React.useEffect(() => {
    if (!channel) {
      requestBatchDDODetails(channelId)
    }
  }, [])

  if (!channel) {
    return (
      <Container className={'fetching'}>
        <ColorIcon
          style={{
            backgroundColor: '#E3E8EA'
          }}
        />
        <TextPlaceholder />
      </Container>
    )
  }

  return (
    <Container onClick={() => dispatch(communicationHubActions.pushActiveChannel({ channel }))}>
      <ColorIcon
        style={{
          backgroundColor: ddoDirectory.statusColor[channel.metadata.status]
        }}
      />
      <Text>{channel.channelViewName}</Text>
    </Container>
  )
})

export const AllUnreadChannelHeaderDDO = connect(({ dispatchDeliveryOrder }: IStore, { channelId }: OwnProps) => {
  return {
    dispatchDeliveryOrder: dispatchDeliveryOrder[channelId]
  }
})(Component)

const Container = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  padding: 8px 0;
  user-select: none;

  &:not(.fetching):hover {
    color: ${theme.colors.basicBlueColor};
    cursor: pointer;
  }
`
const ColorIcon = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transform: translateY(-1px);
  margin-right: 11px;
`
const Text = styled.div`
  font-size: 14px;
`
const TextPlaceholder = styled.div`
  width: 210px;
  height: 12px;
  background: rgba(227, 232, 234, 0.5);
  border-radius: 4px;
`
