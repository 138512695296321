import * as React from 'react'
import { Widget } from '../../'
import { ILocation } from '../../../../common/location/interfaces'
import { oc } from 'ts-optchain'
import { checkLocationValidation } from '../../../../../services/DTO/location'
import { Window } from './preview'
import { Popover } from './popover'
import { createAddressLine } from '../../../../../services/addressService'
import { LocationViewDTO } from '../../../../../api/origin/business-logic'
import { makeLocationTitle } from '../../../../../services/DTO/location/functions'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'

type OwnProps = {
  widgetTitle: string
  shadowColor?: string
  location: ILocation
  saveButton: JSX.Element
  updateLocation: (updatedLocation: ILocation, updateInitialState?: boolean) => void
  isModifiedMode?: boolean
  disabled?: boolean
  disableEditing?: boolean
  isShortName?: boolean
  isCompanyName?: boolean
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const LocationWidget: React.SFC<Props> = ({
  widgetTitle,
  shadowColor,
  updateLocation,
  saveButton,
  location,
  isModifiedMode,
  disableEditing,
  disabled,
  isShortName,
  isCompanyName
}) => {
  const locationObject = {
    id: oc(location).id(''),
    status: oc(location).status(LocationViewDTO.StatusEnum.FULL),
    name: oc(location).name(''),
    code: oc(location).code(''),
    longName: oc(location).longName(''),
    shortName: oc(location).shortName(''),
    street: oc(location).address.street(''),
    type: oc(location).type(null),
    city: oc(location).address.city(''),
    stateId: oc(location).address.stateId(''),
    zip: oc(location).address.postalCode(''),
    notes: oc(location).notes(''),
    company: oc(location).careOf(''),
    terminalIntegration: oc(location).terminalIntegration(null),
    mismatchIndicator: oc(location).mismatchIndicator(null)
  }

  const previewProps = {
    isInvalid: isLocationGEOValid(location) === false,
    boldText: locationObject.name,
    locationCompany: '',
    locationAddress: '',
    locationType: locationObject.type,
    mismatchIndicator: locationObject.mismatchIndicator,
    terminalIntegration: locationObject.terminalIntegration,
    partialIndicator: locationObject.status === LocationViewDTO.StatusEnum.PARTIAL
  }

  if (isCompanyName && locationObject.company && locationObject.name !== locationObject.company) {
    previewProps.locationCompany = locationObject.company
  }

  if (location) {
    previewProps.locationAddress = location.fullObject
      ? createAddressLine(oc(location).address())
      : [oc(location).address.street(''), oc(location).cityStateZip('')].filter(Boolean).join(', ')

    if (isShortName) {
      previewProps.boldText = location.fullObject
        ? locationObject.code || locationObject.name || makeLocationTitle(location)
        : locationObject.shortName || locationObject.name
    }
  }

  return (
    <Widget
      disable={disabled && !previewProps.isInvalid}
      red={previewProps.isInvalid}
      title={widgetTitle}
      highlighted={location && location.fullObject && !checkLocationValidation(location)}
      shadowColor={shadowColor}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={previewProps}
      detailsProps={{
        disableEditing,
        location,
        updateLocation,
        isModifiedMode
      }}
    />
  )
}

export default LocationWidget
