import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'
import { locationLists } from '../../select/locationLists'

export const locationFilterSettings = {
  Name: {
    name: 'name',
    type: GridHeaderColumnType.string
  },
  Type: {
    name: 'type',
    type: GridHeaderColumnType.list,
    items: locationLists.type
  },
  Code: {
    name: 'code',
    type: GridHeaderColumnType.string
  },
  Company: {
    name: 'careOf',
    type: GridHeaderColumnType.string
  },
  Address: {
    name: 'street',
    path: 'address.street',
    type: GridHeaderColumnType.string
  },
  City: {
    name: 'city',
    path: 'address.city',
    type: GridHeaderColumnType.string
  },
  State: {
    name: 'state.id',
    path: 'address.stateId',
    type: GridHeaderColumnType.state
  },
  ZIP: {
    name: 'postalCode',
    path: 'address.postalCode',
    type: GridHeaderColumnType.string
  },
  'PC First Name': {
    name: 'primaryContact.firstName',
    type: GridHeaderColumnType.string
  },
  'PC Last Name': {
    name: 'primaryContact.lastName',
    type: GridHeaderColumnType.string
  },
  'PC phone': {
    name: 'primaryContact.phone',
    type: GridHeaderColumnType.string
  },
  'PC email': {
    name: 'primaryContact.email',
    type: GridHeaderColumnType.string
  }
}
