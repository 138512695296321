import { oc } from 'ts-optchain'
import { DeliveryOrderDTO, DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { getStore } from '../../../store/configureStore'
import { IMove } from '../../../store/reducers/communicationHub/interfaces'
import { IDispatchDeliveryOrder } from '../../common/dispatchDeliveryOrder/interfaces'

export const isStreetTurnButtonsAvailible = (props: {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  move: IMove
}): boolean => {
  const { dispatchDeliveryOrder, move } = props
  try {
    let targetStage: TransportationActivityViewDTO.StageEnum
    let adjustmentActivityIndex: -1 | 1

    if (!(dispatchDeliveryOrder && !dispatchDeliveryOrder.troubleTicketId && move.deliveryOrderType && move.items)) {
      return false
    }

    if (move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.IMPORT) {
      targetStage = TransportationActivityViewDTO.StageEnum.RETURN
      adjustmentActivityIndex = -1
    } else if (move.deliveryOrderType === DeliveryOrderDTO.TypeEnum.EXPORT) {
      targetStage = TransportationActivityViewDTO.StageEnum.PICKUP
      adjustmentActivityIndex = 1
    } else {
      return false
    }

    if (dispatchDeliveryOrder.streetTurnId) {
      let targetStreetTurnId: string
      const storeActivity = getStore().getState().activity
      const documentationActivityMapping = storeActivity.documentationActivities
      const transportationActivityMapping = storeActivity.transportationActivities
      const sortedTargetStageActivityIds = dispatchDeliveryOrder.activityIds
        .filter(id => {
          const { stage, status, type } = documentationActivityMapping[id] || transportationActivityMapping[id] || {}

          if (status !== DocumentationActivityDTO.StatusEnum.REJECTED) {
            if (stage === targetStage && type === DocumentationActivityDTO.TypeEnum.STREETTURN) {
              targetStreetTurnId = id
            }

            return true
          }
        })
        .sort((activity1Id, activity2Id) => {
          const activity1 = transportationActivityMapping[activity1Id] || documentationActivityMapping[activity1Id]
          const activity2 = transportationActivityMapping[activity2Id] || documentationActivityMapping[activity2Id]
          return oc(activity1).number(0) - oc(activity2).number(0)
        })

      if (!targetStreetTurnId) {
        return false
      } else {
        const targetStreetTurnIndex = sortedTargetStageActivityIds.findIndex(id => id === targetStreetTurnId)
        const nextToTransportationActivityId =
          sortedTargetStageActivityIds[targetStreetTurnIndex + adjustmentActivityIndex]

        return nextToTransportationActivityId
          ? move.items.some(
              ({ gotoActivityId, businessActivityId }) =>
                nextToTransportationActivityId === gotoActivityId ||
                nextToTransportationActivityId === businessActivityId
            )
          : false
      }
    } else if (dispatchDeliveryOrder.streetTurnCount) {
      return move.items.some(
        ({ businessActivity }) =>
          businessActivity && businessActivity.template && businessActivity.stage === targetStage
      )
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)
  }

  return false
}
