import * as React from 'react'

export const useDebounce = (fn: () => any, ms: number = 0, args: any[] = []) => {
  const [isNotFirstRender, setIsNotFirstRender] = React.useState(false)
  React.useEffect(() => {
    if (isNotFirstRender) {
      const handle = setTimeout(fn.bind(null, args), ms)

      return () => {
        clearTimeout(handle)
      }
    } else {
      setIsNotFirstRender(true)
    }
  }, args)
}
