import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { DeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { ColumnRow, Container } from '../../../GridSystem'
import { Input } from '../../../DataFields/Input'
import { WidgetActions } from '../../styles'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { DDOContext } from '../../../../../services/DTO/dispatchDeliveryOrder/context'
import { regExpOnlyNumbersAndLetters } from '../../../../../services/functions/regExp'

export const Popover: React.FC<{
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
}> = ({ dispatchDeliveryOrder, updateDeliveryOrder, disabled }) => {
  const {
    state: { isImport, isExport, isRepo }
  } = React.useContext(DDOContext)
  const { closePopover } = React.useContext(WidgetContext)

  return (
    <>
      <Container isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <Input
            disabled={disabled}
            title={'Booking #'}
            regExp={regExpOnlyNumbersAndLetters}
            required={isExport || isRepo}
            value={dispatchDeliveryOrder.deliveryOrder.bookingNumber}
            onChange={updateDeliveryOrder('bookingNumber')}
          />
        </ColumnRow>

        <ColumnRow>
          <Input
            disabled={disabled}
            title={'Bill of Lading #'}
            regExp={regExpOnlyNumbersAndLetters}
            required={isImport}
            value={dispatchDeliveryOrder.deliveryOrder.billOfLadingNumber}
            onChange={updateDeliveryOrder('billOfLadingNumber')}
          />
        </ColumnRow>
      </Container>
      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
