import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO,
  VendorDTO,
  VendorNameDTO
} from '../../../api/api'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { oc } from 'ts-optchain'
import { checkLocationValidation } from '../location'
import { ILocation } from '../../../components/common/location/interfaces'
// tslint:disable:max-line-length
import { completionActualDayNeedStatuses } from '../../../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/CompletionActualDateActivityColumn'
// tslint:disable:max-line-length
import { startActualDayNeedStatuses } from '../../../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/StartActualDateActivityColumn'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isDDOStatusCancelled } from '../../functions/test/isDDOStatusCancelled'

export const allowActivitiesModifying = (dispatchDeliveryOrder: IDispatchDeliveryOrder) => {
  return ![
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].includes(dispatchDeliveryOrder.status)
}

export const isDDOActive = (dispatchDeliveryOrder: IDispatchDeliveryOrder) =>
  Boolean(
    dispatchDeliveryOrder.status !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED &&
      !isDDOStatusCancelled(dispatchDeliveryOrder.status)
  )

export const isDDOHasLocationsWithActiveTerminalIntegration = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder
): boolean => {
  switch (oc(dispatchDeliveryOrder).deliveryOrder.type()) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return Boolean(oc(dispatchDeliveryOrder as any).pickupStage.location.terminalIntegration.active())
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
      return Boolean(oc(dispatchDeliveryOrder as any).returnStage.location.terminalIntegration.active())
    default:
      return false
  }
}

// export const checkActivity = (item: ActivityViewDTO) => Object.values(transformActivity(item)).some(Boolean)
export const checkActivity = (activity: TransportationActivityViewDTO) => {
  const vendorNeedStatuses = [
    TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
    TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED,
    TransportationActivityViewDTO.StatusEnum.INPROCESS,
    TransportationActivityViewDTO.StatusEnum.COMPLETED
  ]

  return Boolean(
    activity.dispatchDeliveryOrderId &&
      activity.number >= 0 &&
      activity.stage &&
      activity.status &&
      activity.type &&
      (vendorNeedStatuses.includes(activity.status)
        ? activity.vendorId && (activity.originId || activity.destinationId)
        : true) &&
      (activity.destination && activity.destination.fullObject
        ? checkLocationValidation(activity.destination as ILocation)
        : true) &&
      (startActualDayNeedStatuses.includes(activity.status) ? Boolean(activity.startActualDate) : true) &&
      (completionActualDayNeedStatuses.includes(activity.status) ? Boolean(activity.completionActualDate) : true) &&
      (activity.notes ? activity.notes.length <= 200 : true)
  )
}

export const filterUsefulTransportationActivities = (
  transportationActivities: TransportationActivityViewDTO[]
): TransportationActivityViewDTO[] =>
  transportationActivities
    .sort((a, b) => a.number - b.number)
    .filter(
      (activity, index) =>
        activity.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
        !(
          isGotoActivity(activity) &&
          transportationActivities[index + 1].status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
        )
    )
