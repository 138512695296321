import { TContact } from '../../../components/common/contact/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import { oc } from 'ts-optchain'

export const getContactStatus = (contact: TContact): TObjectStatusForSave => {
  const store = getStore().getState()
  const storeContact = store.contact[contact.id]

  return {
    noStoreDataForUpdate: compareUpdateDates(contact, storeContact),
    needToSave: oc(contact).fullObject() && !isEqualObjects(omitUpdateInfo(contact), omitUpdateInfo(storeContact))
  }
}
