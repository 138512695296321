import * as React from 'react'
import DeliveryOrder from '../common/deliveryOrder/views'
import { IStore } from '../../store/store.interface'
import { connect } from 'react-redux'
import { IDeliveryOrder } from '../common/deliveryOrder/interfaces'
import { ITabState } from '../common/tabs/interfaces'
import { getDeliveryOrderById } from '../common/deliveryOrder/epics'
import { IViewingObject } from '../../services/viewingObjects/interfaces'
import { oc } from 'ts-optchain'
import { assembleDTO } from '../../services/DTO/assemble'

type OwnProps = {
  currentTab: ITabState
}
type StateProps = {
  viewingObject?: IViewingObject
  deliveryOrder?: IDeliveryOrder
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DeliveryOrderPage = connect(
  (store: IStore, { currentTab }: OwnProps): StateProps => {
    const viewingObject = store.viewingObjects[currentTab.id]
    return {
      viewingObject,
      deliveryOrder: viewingObject
        ? viewingObject.modifiedObject || assembleDTO.deliveryOrder({ store, id: viewingObject.id })
        : undefined
    }
  },
  null
)((props: Props) => {
  const { currentTab, deliveryOrder, viewingObject } = props

  React.useEffect(() => {
    if (!deliveryOrder || !deliveryOrder.fullObject) {
      getDeliveryOrderById(oc(viewingObject).id())
    }
  }, [oc(deliveryOrder).id()])

  return <DeliveryOrder currentTab={currentTab} viewingObject={viewingObject} deliveryOrder={deliveryOrder} />
})
