import { DriverViewDTO } from '../../../../api/origin/business-logic'
import { transformPowerUnit } from '../../../../services/DTO/driver'
import { oc } from 'ts-optchain'
import { TColumns } from '../../grid/views/interfaces'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { getStateInfo, createAddressLine } from '../../../../services/addressService'
import { getDateFromISOString } from '../../../../services/timeService/dateUtils'
import { driverDirectory } from '../../../../services/DTO/driver/directory'

export enum DriverColumn {
  'Employee #' = 'Employee #',
  'Active / Availability' = 'Active / Availability',
  'Name' = 'Name',
  'Operational Preference' = 'Operational Preference',
  'Vendor' = 'Vendor',
  'Date of Birth' = 'Date of Birth',
  'Mailing Address' = 'Mailing Address',
  'Phone Number' = 'Phone Number',
  Email = 'Email',
  MobileApp = 'Mobile App',
  'Milage Restrictions' = 'Milage Restrictions',
  'Owned Truck' = 'Owned Truck',
  'Driver Type' = 'Driver Type',
  ELD = 'ELD',
  'License Number' = 'License Number',
  'License Exp. Date' = 'License Exp. Date',
  'License Issue Date' = 'License Issue Date',
  'License Issue State' = 'License Issue State',
  'License Address' = 'License Address',
  Endorsements = 'Endorsements',
  'Endorsements Exp. Date' = 'Endorsements Exp. Date',
  'Med. Exam. Exp. Date' = 'Med. Exam. Exp. Date',
  'TWIC Exp. Date' = 'TWIC Exp. Date',
  'Port Credentials Exp. Date' = 'Port Credentials Exp. Date'
}

export const headers = {
  [DriverColumn['Employee #']]: { width: 110, active: true },
  [DriverColumn['Active / Availability']]: { width: 260, active: true },
  [DriverColumn.Name]: { width: 160, active: true },
  [DriverColumn.Vendor]: { width: 200, minWidth: 200, active: true },
  [DriverColumn.MobileApp]: { width: 80, minWidth: 80, active: true },
  [DriverColumn['Operational Preference']]: { width: 160, active: true },
  [DriverColumn['Date of Birth']]: { width: 110, active: true },
  [DriverColumn['Mailing Address']]: { width: 260, active: true },
  [DriverColumn['Phone Number']]: { width: 120, active: true },
  [DriverColumn.Email]: { width: 200, active: true },
  [DriverColumn['Milage Restrictions']]: { width: 200, active: true },
  [DriverColumn['Owned Truck']]: { width: 160, active: true },
  [DriverColumn['Driver Type']]: { width: 160, active: true },
  [DriverColumn.ELD]: { width: 80, active: true },
  [DriverColumn['License Number']]: { width: 200, active: true },
  [DriverColumn['License Exp. Date']]: { width: 140, active: true },
  [DriverColumn['License Issue Date']]: { width: 140, active: true },
  [DriverColumn['License Issue State']]: { width: 160, active: true },
  [DriverColumn['License Address']]: { width: 260, active: true },
  [DriverColumn.Endorsements]: { width: 200, active: true },
  [DriverColumn['Endorsements Exp. Date']]: { width: 170, active: true },
  [DriverColumn['Med. Exam. Exp. Date']]: { width: 160, active: true },
  [DriverColumn['TWIC Exp. Date']]: { width: 120, active: true },
  [DriverColumn['Port Credentials Exp. Date']]: { width: 170, active: true }
}

export const adaptRowValues = (unit: DriverViewDTO): TColumns => {
  const columnValues = {
    [DriverColumn['Employee #']]: oc(unit).number(''),
    [DriverColumn['Active / Availability']]: unit,
    [DriverColumn.Name]: oc(unit).name(''),
    [DriverColumn['Operational Preference']]: String(unit.operationalPreference || '').toLowerCase(),
    [DriverColumn.Vendor]: oc(unit).parent.name(''),
    [DriverColumn.MobileApp]: oc(unit).mobileApp() === true ? 'Yes' : oc(unit).mobileApp() === false ? 'No' : '',
    [DriverColumn['Date of Birth']]: getDateFromISOString(unit.birthday),
    [DriverColumn['Mailing Address']]: createAddressLine(unit.mailingAddress),
    [DriverColumn['Phone Number']]: oc(unit).phone(''),
    [DriverColumn.Email]: oc(unit).email(''),
    [DriverColumn['Milage Restrictions']]: driverDirectory.millageRestriction[unit.millageRestriction],
    [DriverColumn['Owned Truck']]: transformPowerUnit(oc(unit).powerUnit(null)),
    [DriverColumn['Driver Type']]: driverDirectory.type[unit.driverType],
    [DriverColumn.ELD]: unit.eld ? unit.eldNumber || 'Yes' : 'No',
    [DriverColumn['License Number']]: oc(unit).licenseNumber(''),
    [DriverColumn['License Exp. Date']]: getDateFromISOString(unit.licenseExpDate),
    [DriverColumn['License Issue Date']]: getDateFromISOString(unit.licenseIssueDate),
    [DriverColumn['License Issue State']]: getStateInfo(unit.licenseIssueStateId).code,
    [DriverColumn['License Address']]: createAddressLine(unit.licenseAddress),
    [DriverColumn.Endorsements]: (unit.endorsement || []).map(_ => driverDirectory.endorsement[_]).join(', '),
    [DriverColumn['Endorsements Exp. Date']]: getDateFromISOString(unit.endorsementExpDate),
    [DriverColumn['Med. Exam. Exp. Date']]: getDateFromISOString(unit.medicalExamCardExpDate),
    [DriverColumn['TWIC Exp. Date']]: getDateFromISOString(unit.twicExpDate),
    [DriverColumn['Port Credentials Exp. Date']]: getDateFromISOString(unit.portCredentialsExpDate)
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
