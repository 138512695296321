import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { getBusinessPartnerSavePromise } from '../businessPartner/save'
import { makeObjectSavePromise, tryToSave, makeSavePromiseWithCatch } from '../saveDTO'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { ISubClient } from '../../../components/common/subClient/interfaces'
import { getSubClientStatus } from './status'
import { subClientAPI } from '../../../api/api'
import { parseDTO } from '../parseDTO'

export const getSubClientSavePromise = (subClient: ISubClient, actions?: IModifyGridItemActions): Promise<any> => {
  const { needToSave } = getSubClientStatus(subClient)

  return getBusinessPartnerSavePromise(subClient.businessPartner).then((businessPartner: BusinessPartner) => {
    const result: ISubClient = { ...subClient, businessPartnerId: businessPartner.id, businessPartner: undefined }
    return makeObjectSavePromise(needToSave, result, actions, subClientAPI, parseDTO.subClient)
  })
}

export const saveSubClient = (subClient: ISubClient, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getSubClientSavePromise(subClient, actions))

  return tryToSave({
    condition: getSubClientStatus(subClient).noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
