import { omit } from 'remeda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { filterNewListItems } from './functions'
import { IListsState, IListsStateUpdate, ListEntityType } from './interfaces'
import { DriverDTO } from '../../../api/api'

const initialState: IListsState = Object.keys(ListEntityType).reduce(
  (acc, key) => {
    acc[key] = {}

    return acc
  },
  {} as IListsState
)

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<IListsStateUpdate>) => {
      try {
        const updateLists = action.payload.update || {}
        const deleteListsIds = action.payload.delete || {}

        // >>> TODO push parsed list items here... newData[type].push(newItem)

        // <<<

        Object.keys(state).forEach(listType => {
          const updateListItems = updateLists[listType]
          const deleteListItemIds = deleteListsIds[listType]
          let updatedList = state[listType]

          // update
          if (updateListItems && updateListItems.length) {
            const filteredNewListItems = filterNewListItems({
              newListItems: updateListItems,
              prevListItems: state[listType]
            })

            if (filteredNewListItems) {
              updatedList = { ...state[listType], ...filteredNewListItems }

              if (listType === ListEntityType.driver) {
                state[ListEntityType.driverIdByAuthId] = {
                  ...state[ListEntityType.driverIdByAuthId],
                  ...Object.values(filteredNewListItems).reduce((acc, curr: DriverDTO) => {
                    if (curr.authUserId) {
                      acc[curr.authUserId] = curr.id
                    }

                    return acc
                  }, {})
                }
              }
            }
          }

          // delete ids
          if (deleteListItemIds && deleteListItemIds.length) {
            updatedList = omit(updatedList, deleteListItemIds)
          }

          state[listType] = updatedList
        })
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e)
      }
    }
  }
})

export const listsActions = listsSlice.actions
export default listsSlice.reducer
