import * as React from 'react'
import { ITabState, TabType } from './common/tabs/interfaces'
import { getTabs } from './common/tabs/selectors'
import { connect } from 'react-redux'
import { IStore } from '../store/store.interface'
import { Grid } from './pages/Grid'
import { SchedulerPage } from './pages/SchedulerPage'
import { DeliveryOrderPage } from './pages/DeliveryOrderPage'
import { QMPPage } from './pages/QMPPage'
import { ISchedulerTab } from './common/scheduler/interfaces'

type StateProps = {
  currentTab: ITabState
}

export const Router = connect(
  (store: IStore): StateProps => ({ currentTab: getTabs(store).find(_ => _.id === store.tabs.activeMainTabId) })
)((props: StateProps) => {
  const { currentTab } = props

  if (!currentTab) {
    return null
  }

  switch (currentTab.type) {
    case TabType.scheduler:
      return <SchedulerPage key={currentTab.id} currentTab={currentTab as ISchedulerTab} />
    case TabType.QMP:
      return <QMPPage key={currentTab.id} currentTab={currentTab} />
    case TabType.deliveryOrder:
      return <DeliveryOrderPage key={currentTab.id} currentTab={currentTab} />
    default:
      return <Grid key={currentTab.id} currentTab={currentTab} />
  }
})
