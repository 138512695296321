import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { IGridItemActions } from '../../../../../../../contexts/GridItemContext'
import { assembleDTO } from '../../../../../../../services/DTO/assemble'
import { checkValidation } from '../../../../../../../services/DTO/validation'
import { deleteTabViewingObject, modifyTabViewingObject } from '../../../../../../../services/viewingObjects/actions'
import { IViewingObject } from '../../../../../../../services/viewingObjects/interfaces'
import { ApplicationContext, IStore } from '../../../../../../../store/store.interface'
import { GridItemSpinner, InlineSpinner } from '../../../../../../UI/Spinner/Spinner'
import { IDispatchDeliveryOrder } from '../../../../../dispatchDeliveryOrder/interfaces'
import { saveDispatchDeliveryOrder } from '../../../../../../../services/DTO/dispatchDeliveryOrder/save'
import Activities from '../../../../../dispatchDeliveryOrder/views/Details/Activities'
import { containerFn, isChassisNumberRequired } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import ActionsSaveCancel from '../../../../../../UI/Buttons/ActionsSaveCancel'
import { Window } from '../../../../../../UI/Window'
import { MoveRowSpecs } from '../MoveRow/MoveRowSpecs'
import { getDispatchDeliveryOrderById } from '../../../../../dispatchDeliveryOrder/epics'
import { useApplicationSize } from '../../../../../../../hooks/useApplicationSize'

type OwnProps = {
  tabId: string
  viewingObject: IViewingObject
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

type DispatchProps = {
  modifyTabViewingObject: typeof modifyTabViewingObject
  deleteTabViewingObject: typeof deleteTabViewingObject
}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { viewingObject, dispatchDeliveryOrder, tabId } = props
  const [fetching, setFetching] = React.useState<boolean | string>(false)
  const { communicationHubWidth } = useApplicationSize()
  const modifiedUnit = viewingObject && viewingObject.modifiedObject
  const isModified = Boolean(modifiedUnit)

  React.useEffect(() => {
    let isExpiredData = false

    if (!dispatchDeliveryOrder) {
      setFetching(true)
      getDispatchDeliveryOrderById(viewingObject.id).then(() => {
        if (!isExpiredData) {
          setFetching(false)
        }
      })
    }

    return () => {
      isExpiredData = true
    }
  }, [])

  if (fetching && !dispatchDeliveryOrder) {
    return (
      <Window
        // startBelowTabs={true}
        isCommunicationHubVisible={true}
        onButtonCloseClick={isModified ? undefined : () => props.deleteTabViewingObject({ tabId })}
        windowStyles={{ width: '100%', height: '100%', paddingBottom: 65 }}
      >
        <ActivitiesContainer>
          <GridItemSpinner />
        </ActivitiesContainer>
      </Window>
    )
  }

  if (!dispatchDeliveryOrder) {
    return null
  }

  const unit = modifiedUnit || dispatchDeliveryOrder
  const isDDOValid = modifiedUnit && checkValidation.dispatchDeliveryOrder(modifiedUnit)

  const actions: IGridItemActions = {
    enableEditing: true,
    temporaryData: viewingObject.temporaryData,
    isFetching: fetching,
    setFetching,
    togglePreviewTab: () => {}, // don't need
    isModified,
    initialObjectState: oc(viewingObject).temporaryData.initialObjectState(),
    modify: (ddo, updatedInitialObjectState) => {
      const _unit = updatedInitialObjectState || (!isModified && unit)

      props.modifyTabViewingObject({
        tabId,
        modifiedObject: viewingObject && viewingObject.modifiedObject ? ddo : R.clone(ddo),
        initialObjectState: _unit ? R.clone(_unit) : oc(viewingObject).temporaryData.initialObjectState({})
      })
    },
    modifyParentObjectField: (ddo: any) => (field: string) => (value: any, updatedInitialObjectState?: any) => {
      actions.modify({ ...(modifiedUnit || R.clone(ddo)), [field]: value }, updatedInitialObjectState)
    },
    cancel() {
      props.modifyTabViewingObject({ tabId, modifiedObject: undefined, initialObjectState: undefined })
    },
    save() {
      actions.setFetching(true)
      setTimeout(() => {
        saveDispatchDeliveryOrder(modifiedUnit, {
          getUnitInfo: {
            applicationContext: ApplicationContext.main,
            tabId,
            unitId: modifiedUnit.id
          },
          modify: actions.modify,
          setFetching: actions.setFetching,
          cancel: actions.cancel,
          reset: actions.cancel
        })
      })
    }
  }

  return (
    <Window
      // startBelowTabs={true}
      isCommunicationHubVisible={true}
      onButtonCloseClick={isModified ? undefined : () => props.deleteTabViewingObject({ tabId })}
      windowStyles={{ width: '100%', height: '100%', paddingBottom: 65 }}
    >
      <ActivitiesContainer>
        <DDODetails>
          <MoveRowSpecs dispatchDeliveryOrder={dispatchDeliveryOrder} />
        </DDODetails>
        <Activities
          isDDOValid={isDDOValid}
          dispatchDeliveryOrder={unit}
          actions={actions}
          saveButton={null}
          functions={containerFn(unit, actions)}
          required={{ chassisNumber: isChassisNumberRequired(unit.activities) }}
          showUserInfo={true}
          unlimitedHeight={true}
        />
      </ActivitiesContainer>
      <DetailsButtonSaveContainer>
        {isModified && (
          <ActionsSaveCancel
            styles={{ height: 55, right: 20 }}
            disableSave={!isDDOValid}
            onSave={actions.save}
            onCancel={actions.cancel}
          />
        )}
      </DetailsButtonSaveContainer>
    </Window>
  )
}

export const DispatchDeliveryOrderPopupDetails = connect(
  (store: IStore, { viewingObject }: OwnProps) => {
    const dispatchDeliveryOrderId = viewingObject.id
    const storeDDO = store.dispatchDeliveryOrder[dispatchDeliveryOrderId]

    return {
      dispatchDeliveryOrder:
        storeDDO && storeDDO.fullObject
          ? assembleDTO.dispatchDeliveryOrder({ store, id: dispatchDeliveryOrderId })
          : undefined
    }
  },
  { modifyTabViewingObject, deleteTabViewingObject }
)(React.memo(Component))

const ActivitiesContainer = styled.div`
  background-color: #fff;
`
const DDODetails = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  padding-top: 1px;

  .MoveRowSpecs > div {
    border-left-width: 1px;
  }
`
const DetailsButtonSaveContainer = styled.div`
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
