import { IAttachment } from '../../../services/filesService/interfaces'
import {
  GeneralCounterDTO,
  MessageDTO,
  NotificationDTO,
  UserCounterDTO
} from '../../../api/origin/communication-hub-service'
import { EditorState, RawDraftContentState } from 'draft-js'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderSearchDTO,
  LocalDateTimeRangeDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { ApplicationFormDTO, OfficialFormDTO } from '../../../api/origin/document-service'

export interface ICommunicationHub {
  activeTab: CommunicationHubTab
  channels: IChannels
  messages: TMessages
  notifications: INotifications
  userMeta: TUserMeta
  channelsMeta: IChannelsMeta
  channelBrowser?: IChannelBrowser
  activeChannel?: IActiveChatChannel
  editMessageId?: string
  activeRouteBuilder?: IActiveRouteBuilder
  schedulerTabData?: IActiveRouteBuilderSchedulerTabData
}

export enum ChannelNamePlaceholder {
  placeholderLongLine = '―――'
}

export enum EntityCommentsTypes {
  dispatchDeliveryOrder = 'ddo'
}

export enum CommunicationHubTab {
  messenger = 'Messenger',
  routeBuilder = 'Route Builder'
}

export enum RouteBuilderGridView {
  grid = 'mdi-view-grid',
  list = 'mdi-view-list'
}

export interface IActiveRouteBuilder {
  authUserId: string | undefined
  vendorId: string | undefined
  view: RouteBuilderGridView
  tabs: IActiveRouteBuilderTab[]
  higlightActivityId?: string | undefined
}

export interface IActiveRouteBuilderSchedulerTabData {
  specificDate: string
  ongingDispatchDeliveryOrderIds: string[]
  activeDeliveryStageVendorIds: string[]
}

export interface IActiveRouteBuilderTab {
  isActive: boolean
  date: string
  moves: IMoveDTO[]
}

export type TOptionalChannelDocumentCollector = IMessage[]

export enum ActiveChatChannelIdSpecialTypes {
  NOTIFICATIONS = 'NOTIFICATIONS'
}

export interface IActiveChatChannel {
  id: string | undefined | null | ActiveChatChannelIdSpecialTypes.NOTIFICATIONS
  updateUnreadCount: boolean
  isRecentOpenedChannel?: boolean
  chatTab?: ChatTab
}

export interface IChannelBrowser {
  channelType: ChannelType
  filters?: any
}

export interface IDraftMessages {
  [channelId: string]: IDraftMessage
}

export interface IEditMessage extends IDraftMessage {
  messageId: string
}

export enum ChannelSorting {
  recentlyUpdatedFirst = 'Recently updated first',
  recentlyUpdatedLast = 'Recently updated last'
}

export enum ChatTab {
  Messages = 'Messages',
  Documents = 'Documents',
  Notes = 'Notes',
  Alerts = 'Alerts'
}

// CHANNEL
export enum ChannelType {
  recentlyOpened = 'Recently opened channels',
  dispatchDeliveryOrder = 'Dispatch Delivery Order channels',
  operator = 'Operator channels',
  driver = 'Driver channels'
}

export type IChannels = {
  idListByType: Record<ChannelType, string[]>
  mapping: {
    [channelId: string]: ICHubChannel
  }
}

interface ICHubChannelDDOMetadata {
  status?: any
  archived?: boolean
}
interface ICHubChannelVendorMetadata {}

export interface ICHubChannelMetadata extends ICHubChannelDDOMetadata, ICHubChannelVendorMetadata {}

export interface ICHubChannel {
  id: string
  name?: string
  subName?: string
  channelViewName?: string
  entityType?: EntityCommentsTypes
  entityDescription?: string
  type?: ChannelType
  metadata: ICHubChannelMetadata
}

// MESSAGE
export type TMessages = {
  ids: string[]
  mapping: {
    [messageId: string]: IMessage
  }
}

export type TMessageIdGroupsByDates = {
  [date: string]: string[]
}

export interface IMessageExternalData {
  localMessageId?: string
  incrementUnreadCount?: boolean
  addMentionUserIds?: string[]
  removeMentionUserIds?: string[]
  error?: TMessageError
}

export interface IDocumentMessage {
  channelId: string
  senderId: string
  attachments?: IAttachment[]
  metadata?: any
}

export interface IMessage extends MessageDTO {
  externalData?: IMessageExternalData
  attachments?: IAttachment[]
}

export enum MessageDeliveryStatus {
  'SENDING' = 'SENDING',
  'DELIVERED' = 'DELIVERED',
  'VIEWED' = 'VIEWED'
}

export type TReaction = {}

export type TMessageError = {
  type: string
  message: string
}

export interface IDraftMessage {
  editor: EditorState // RawDraftContentState
  attachments: IAttachment[]
}

// USER META
export type TUserMeta = {
  [channelId: string]: TUserMetaChannel
}

export type TUserMetaChannel = {
  unreadMessagesCount?: number
  muted?: boolean
}

// UNREAD CHANNELS
export type IChannelsMeta = {
  [channelId: string]: IChannelMeta
}

export interface IChannelMeta extends GeneralCounterDTO, UserCounterDTO {
  type: GeneralCounterDTO.TypeEnum | UserCounterDTO.TypeEnum
}

export interface ISearchChannelFilters {}

export interface IDispatchDeliveryOrderSearchResult extends DispatchDeliveryOrderSearchDTO {
  updatedAt?: string
}

export enum MoveStatus {
  PLANNED = 'PLANNED',
  ASSIGNED = 'ASSIGNED',
  CONFIRMED = 'CONFIRMED',
  REFUSED = 'REFUSED',
  INPROCESS = 'IN_PROCESS',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
  COMPLETED = 'COMPLETED'
}

export interface IMoveDTO {
  id: string
  fullObject: boolean
  vendorId: string
  sequenceNumber: number
  status: MoveStatus
  dispatchDeliveryOrderId: string
  items: IMoveItemDTO[]
  plannedDateFixed: boolean
  validForPrevDoubleMove: boolean
  validForNextDoubleMove: boolean
  prevConnectionType?: MoveConnectionType
  nextConnectionType?: MoveConnectionType
  firstMoveOfDay?: boolean
  lastMoveOfDay?: boolean
  startDate?: string
  completeDate?: string
  plannedDate?: string
  prevDoubleMoveId?: string
  nextDoubleMoveId?: string
  _isPrevDayMove?: boolean
  _isNextDayMove?: boolean
}

export interface IMove extends IMoveDTO {
  _status: MoveStatus
  items: IMoveItem[]
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
  dispatchDeliveryOrder?: {
    number: string
    status: DispatchDeliveryOrderGridItemDTO.StatusEnum
    autoIndicator: boolean
    hazmatIndicator: boolean
    overweightIndicator: boolean
    refrigeratorIndicator: boolean
    isConfirmedAppointmentDate: boolean
    deliveryOrderDocNumber?: string
    containerTypeCode?: string
    containerNumber?: string
    equipmentChassisNumber?: string
  }
}

export interface IMoveItemDTO {
  id: string
  moveId: string
  gotoActivityId: string
  businessActivityId: string
}

export interface IMoveItem extends IMoveItemDTO {
  gotoActivity?: TransportationActivityViewDTO
  businessActivity?: TransportationActivityViewDTO
}

export enum MoveConnectionType {
  DOUBLEMOVE = 'DOUBLE_MOVE',
  BOBTAIL = 'BOBTAIL'
}

export interface INotifications {
  filters: INotificationFilters
  sortedIds: string[]
  itemsMapping: INotificationItemsMapping
  documentForms: NotificationDocumentForm[]
}

export enum NotificationFilter {
  status = 'status',
  type = 'type',
  driverStatuses = 'driverStatuses',
  recipientUserIds = 'recipientUserIds',
  senderUserIds = 'senderUserIds',
  startDate = 'startDate',
  endDate = 'endDate',
  text = 'text'
}

export interface INotificationFilters {
  [NotificationFilter.status]?: NotificationDTO.StatusEnum
  [NotificationFilter.type]?: NotificationDTO.TypeEnum
  [NotificationFilter.driverStatuses]?: NotificationDTO.StatusEnum[]
  [NotificationFilter.recipientUserIds]?: string[]
  [NotificationFilter.senderUserIds]?: string[]
  [NotificationFilter.startDate]?: string
  [NotificationFilter.endDate]?: string
  [NotificationFilter.text]?: string
}

export interface INotificationItemsMapping {
  [id: string]: NotificationDTO
}

export type NotificationDocumentForm = (ApplicationFormDTO | OfficialFormDTO) & {
  isApplicationForm?: boolean
  isOfficialForm?: boolean
}
