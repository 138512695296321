import * as React from 'react'
import { oc } from 'ts-optchain'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../../../api/origin/business-logic'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import { WidgetContext } from '../../index'
import { getStringConvertISODateToDateWithHoursMins } from '../../../../../services/timeService/dateUtils'
import { Table } from './view/Table'
import { ManualStatuses } from './view/ManualStatuses'
import { cancelDispatchDeliveryOrder } from './index'
import { BoldLine } from '../../styles'
import { isDDOStatusNew } from '../../../../../services/functions/test/isDDOStatusNew'

export const Popover: React.FC<{
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  status: DispatchDeliveryOrderViewDTO.StatusEnum
  currentActivity: TransportationActivityViewDTO
  windowProps: {
    title: string
    activityTypeOrDDOStatus: string
    activityVendor: string
    titleAndIconColor: string
  }
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
}> = ({
  disabled,
  dispatchDeliveryOrder,
  status,
  currentActivity,
  windowProps,
  updateDispatchDeliveryOrderField,
  updateDispatchDeliveryOrder
}) => {
  const { closePopover } = React.useContext(WidgetContext)

  if (currentActivity) {
    return (
      <Table
        rows={{
          Type: windowProps.activityTypeOrDDOStatus,
          Status: activityDirectory.status[currentActivity.status],
          'Start Date': getStringConvertISODateToDateWithHoursMins(currentActivity.startActualDate, false, false),
          'Completed Date': getStringConvertISODateToDateWithHoursMins(
            currentActivity.completionActualDate,
            false,
            false
          ),
          Location: oc(currentActivity).destination.longName(''),
          Driver: windowProps.activityVendor
        }}
      />
    )
  }

  if (
    [
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY,
      DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN,
      DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED,
      DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED,
      DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED
    ].includes(status)
  ) {
    return (
      <BoldLine style={{ color: windowProps.titleAndIconColor, textTransform: 'uppercase' }}>
        {windowProps.activityTypeOrDDOStatus}
      </BoldLine>
    )
  }

  return (
    <ManualStatuses
      disabled={disabled}
      deliveryOrderType={dispatchDeliveryOrder.deliveryOrder.type}
      currentStatus={status}
      onChangeStatus={changedStatus => {
        if (changedStatus === DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED) {
          updateDispatchDeliveryOrder(cancelDispatchDeliveryOrder(dispatchDeliveryOrder))
          closePopover()
        } else {
          updateDispatchDeliveryOrderField('status')(changedStatus)
        }
      }}
    />
  )
}
