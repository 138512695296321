import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { ICustomerView } from '../../../components/common/customer/interfaces'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from './index'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
}

export const assembleCustomer = (props: Props): ICustomerView => {
  const store = props.store || getStore().getState()
  const customer = oc(store).customer[props.id]()

  if (!customer) {
    return null
  }

  const businessPartner = assembleDTO.businessPartner({ store, id: customer.businessPartnerId })

  return {
    ...customer,
    name: oc(businessPartner).legalName(customer.name),
    businessPartner,
    subClients: oc(customer)
      .subClientIds([])
      .map((subClientId: string) =>
        assembleDTO.subClient({ store, id: subClientId, customer: customer as ICustomerView })
      )
      .filter(Boolean)
  }
}
