import * as React from 'react'
import styled from '../../../styles/styledComponents'
import { TabType } from '../../common/tabs/interfaces'
import { addNewGridItem } from '../../common/tabs/epics'

type StateProps = {
  tabId: string
  unitType: TabType
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const FloatingAddButton = (props: Props) => {
  const { unitType, tabId } = props

  if (unitType === TabType.dispatchDeliveryOrder) {
    return null
  }

  return (
    <AddButton
      className={'add-object-button mdi mdi-plus-circle'}
      onClick={() => addNewGridItem({ tabId, unitType })}
    />
  )
}

const AddButton = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  color: #475264;
  font-size: 60px;
  transition: 0.25s;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #4555d0;
    transform: scale(1.1);
  }

  &:after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
  }
`
