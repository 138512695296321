import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { ISteamShipLine } from '../../../components/common/steamShipLine/interfaces'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from './index'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
}

export const assembleSteamShipLine = (props: Props): ISteamShipLine => {
  const store = props.store || getStore().getState()
  const steamShipLine = oc(store).steamShipLine[props.id]()

  if (!steamShipLine) {
    return null
  }

  return {
    ...steamShipLine,
    businessPartner: assembleDTO.businessPartner({ store, id: steamShipLine.businessPartnerId })
  }
}
