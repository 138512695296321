import { oc } from 'ts-optchain'
import { getStore } from '../../../store/configureStore'
import { IMove, IMoveDTO, MoveStatus } from '../../../store/reducers/communicationHub/interfaces'
import { checkWeatherContainerTypeIsReefer } from '../../DTO/containerType/functions'
import { getMoveStatus } from '../get/getMoveStatus'
import { isMoveStatusChangeable } from './isMoveStatusChangeable'
import { isBobtailGotoActivity } from './isBobtailGotoActivity'

type Props = {
  move: IMoveDTO
  status: MoveStatus
}

type Result = {
  changeable: boolean
  errorMessage?: string
}

export const isMoveAvailableToChangeStatus = (props: Props): Result => {
  const testResult: Result = { changeable: true, errorMessage: undefined }
  const { status } = props
  const store = getStore().getState()
  const transportationActivityMapping = store.activity.transportationActivities
  const dispatchDeliveryOrder = store.dispatchDeliveryOrder[props.move.dispatchDeliveryOrderId]
  let container
  let equipment
  let containerType
  let deliveryOrder

  if (dispatchDeliveryOrder) {
    container = dispatchDeliveryOrder.containerId && store.container[dispatchDeliveryOrder.containerId]
    equipment = dispatchDeliveryOrder.equipmentId && store.equipment[dispatchDeliveryOrder.equipmentId]
    containerType = dispatchDeliveryOrder.containerTypeId && store.containerType[dispatchDeliveryOrder.containerTypeId]
    deliveryOrder = dispatchDeliveryOrder.deliveryOrderId && store.deliveryOrder[dispatchDeliveryOrder.deliveryOrderId]
  }

  const move: IMove = {
    ...props.move,
    _status: undefined,
    deliveryOrderType: deliveryOrder && deliveryOrder.type,
    items: props.move.items
      .map(item => ({
        ...item,
        gotoActivity: transportationActivityMapping[item.gotoActivityId],
        businessActivity: transportationActivityMapping[item.businessActivityId]
      }))
      .sort((a, b) => oc(a).gotoActivity.number(0) - oc(b).gotoActivity.number(0)),
    dispatchDeliveryOrder: {
      number: oc(dispatchDeliveryOrder).number(),
      status: oc(dispatchDeliveryOrder).status(),
      autoIndicator: oc(dispatchDeliveryOrder).autoIndicator(),
      hazmatIndicator: oc(dispatchDeliveryOrder).hazmatIndicator(),
      overweightIndicator: oc(dispatchDeliveryOrder).overweightIndicator(),
      refrigeratorIndicator:
        containerType && checkWeatherContainerTypeIsReefer({ containerTypeName: containerType.name }),
      isConfirmedAppointmentDate: oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed(),
      deliveryOrderDocNumber: oc(deliveryOrder).bookingNumber(),
      containerTypeCode: oc(containerType).code(),
      containerNumber: oc(container).number(),
      equipmentChassisNumber: oc(equipment).number()
    }
  }

  move._status = getMoveStatus(move)

  if (status === MoveStatus.ASSIGNED) {
    testResult.changeable = isMoveStatusChangeable(move)[MoveStatus.ASSIGNED]
  }

  if (status === MoveStatus.PLANNED) {
    testResult.changeable = isMoveStatusChangeable(move)[MoveStatus.PLANNED]

    if (testResult.changeable) {
      if (
        move.items.some(
          ({ gotoActivity }) => gotoActivity && isBobtailGotoActivity(gotoActivity) && !gotoActivity.destinationId
        )
      ) {
        testResult.changeable = false
        testResult.errorMessage = "Move is not assigned. Some activities don't have location"
      }
    }
  }

  return testResult
}
