import { ILocation } from '../../../components/common/location/interfaces'
import { oc } from 'ts-optchain'
import { getPrimaryContact } from './index'

export const transformPhoneNumber = (tel: string | null): string =>
  tel
    ? tel.split('').reduce((acc, char, index) => {
        if (index === 0) {
          return `(${char}`
        }
        if (index === 2) {
          return `${acc}${char})`
        }
        if (index === 3) {
          return `${acc} ${char}`
        }
        if (index === 6) {
          return `${acc}-${char}`
        }
        return `${acc}${char}`
      }, '')
    : ''

export const getPrimaryContactInfoLines = (location: ILocation) => {
  const defaultResult = {
    firstName: '',
    lastName: '',
    fullName: '',
    phones: '',
    email: ''
  }
  if (!location) {
    return defaultResult
  }
  const primaryContact = getPrimaryContact(location)
  if (!primaryContact) {
    return defaultResult
  }
  const firstName = oc(primaryContact).firstName('')
  const lastName = oc(primaryContact).lastName('')
  const fullName = firstName + (firstName ? ' ' : '') + lastName
  const phone1 = primaryContact.phone ? transformPhoneNumber(primaryContact.phone) : ''
  const phone2 = primaryContact.phone2 ? transformPhoneNumber(primaryContact.phone2) : ''
  const email = oc(primaryContact).email('')

  return {
    firstName,
    lastName,
    fullName,
    email,
    phones: phone1 + (phone1 ? '\n' : '') + phone2
  }
}

export const getPrimaryContactInfo = (location: ILocation, separator: string = '\n') => {
  if (!location) {
    return null
  }

  const primaryContact = getPrimaryContact(location)
  if (!primaryContact) {
    return ''
  }
  const firstName = oc(primaryContact).firstName('')
  const lastName = oc(primaryContact).lastName('')
  const name = firstName + (firstName ? ' ' : '') + lastName
  const phone1 = primaryContact.phone ? transformPhoneNumber(primaryContact.phone) : ''
  const phone2 = primaryContact.phone2 ? transformPhoneNumber(primaryContact.phone2) : ''
  const email = oc(primaryContact).email('')

  return [name, phone1, phone2, email].reduce((acc, curr) => (curr ? acc + (acc ? separator : '') + curr : acc), '')
}
