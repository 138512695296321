import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { getBusinessPartnerStatus } from '../businessPartner/status'
import { isEqualObjectsWithoutBusinessPartner } from '../saveDTO'
import { ISteamShipLine } from '../../../components/common/steamShipLine/interfaces'
import { oc } from 'ts-optchain'

export const getSteamShipLineStatus = (steamShipLine: ISteamShipLine): TObjectStatusForSave => {
  const store = getStore().getState()
  const storeSteamShipLine = store.steamShipLine[steamShipLine.id]
  const businessPartnerInfo = getBusinessPartnerStatus(steamShipLine.businessPartner)

  return {
    noStoreDataForUpdate:
      compareUpdateDates(steamShipLine, storeSteamShipLine) && businessPartnerInfo.noStoreDataForUpdate,
    needToSave:
      oc(steamShipLine).fullObject() && !isEqualObjectsWithoutBusinessPartner(steamShipLine, storeSteamShipLine)
  }
}
