import * as React from 'react'
import { connect } from 'react-redux'
import { TabActionType, TabType, TGridColumns } from '../../common/tabs/interfaces'
import Columns from './columns'
import { setTabViewingObject } from '../../../services/viewingObjects/actions'
import { IStore } from '../../../store/store.interface'
import { getGridItem } from '../../../services/DTO/assemble'
import { getStore } from '../../../store/configureStore'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectDriver } from '../../../store/select/driverSelect'

type OwnProps = {
  unitId: string
  columnsSettings: TGridColumns
  disable: boolean
  tabId: string
  tabType: TabType
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
  handleGridItemVersion: (unit: any) => void
}

type StateProps = {}

type DispatchProps = {}

export type TGridItemProps = StateProps & OwnProps & DispatchProps

export const GridItemDriver = (props: TGridItemProps) => {
  const { unitId, columnsSettings, disable, tabId, tabType, isSelected, isDisabledSelect, isPinned, actionType } = props

  const unit = useAppSelector(selectDriver(unitId))

  if (!unit) {
    return null
  }

  const togglePreview = React.useCallback(() => {
    if (!disable) {
      getStore().dispatch(setTabViewingObject({ tabId, viewingObject: { id: unitId } }))
    }
  }, [disable, unitId])

  return (
    <Columns
      columns={columnsSettings}
      unit={unit}
      unitType={tabType}
      togglePreview={togglePreview}
      isSelected={isSelected}
      isDisabledSelect={isDisabledSelect}
      isPinned={isPinned}
      actionType={actionType}
    />
  )
}
