import * as React from 'react'
import { connect } from 'react-redux'
import { IViewingObject } from '../../../../../../../services/viewingObjects/interfaces'
import { IStore } from '../../../../../../../store/store.interface'
import { DispatchDeliveryOrderPopupDetails } from './DispatchDeliveryOrderPopupDetails'

type OwnProps = {
  tabId: string
}

type StateProps = {
  viewingObject: IViewingObject
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { tabId, viewingObject } = props

  if (!viewingObject) {
    return null
  }

  return <DispatchDeliveryOrderPopupDetails tabId={tabId} viewingObject={viewingObject} />
}

export const DispatchDeliveryOrderPopup = connect(({ viewingObjects }: IStore, { tabId }: OwnProps) => {
  return {
    viewingObject: viewingObjects[tabId]
  }
})(React.memo(Component))
