import * as React from 'react'
import styled from 'styled-components'
import { IDriver } from '../../interfaces'
import theme from '../../../../../styles/theme'
import { useGridItem } from '../../../../../hooks/useGridItem'
import { ArrowActionButton } from '../../../../UI/Buttons/ArrowActionButton'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'
import { DriverViewDTO } from '../../../../../api/api'
import { preventEvent } from '../../../../../services/functions/basic'

type Props = {
  driver: IDriver
  attributes?: any
}

export const CandidateStatusColumn = (props: Props) => {
  const {
    driver: { status },
    attributes
  } = props
  const { actions } = useGridItem() || {}
  const text = driverDirectory.status[status]
  const color = driverDirectory.statusColor[status]

  if (!actions) {
    return <ArrowActionButton text={text} color={color} attributes={attributes} />
  }

  const currStatusIndex = driverDirectory.sortedCandidateStatuses.findIndex(_status => _status === status)
  const prevStatus = driverDirectory.sortedCandidateStatuses[currStatusIndex - 1]
  const nextStatus = driverDirectory.sortedCandidateStatuses[currStatusIndex + 1]
  const changeStatus = (_status: DriverViewDTO.StatusEnum) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventEvent(event)
    return actions.modifyParentObjectField('status')(_status)
  }
  const onLeftArrowClick = prevStatus ? changeStatus(prevStatus) : undefined
  const onRightArrowClick = nextStatus ? changeStatus(nextStatus) : undefined

  return (
    <>
      {status !== DriverViewDTO.StatusEnum.CANDIDATEREJECTED && (
        <RejectButton
          children={'Reject'}
          style={{ marginRight: 8 }}
          onClick={changeStatus(DriverViewDTO.StatusEnum.CANDIDATEREJECTED)}
        />
      )}
      <ArrowActionButton
        text={text}
        color={color}
        attributes={attributes}
        onLeftArrowClick={onLeftArrowClick}
        onRightArrowClick={onRightArrowClick}
      />
    </>
  )
}

export const RejectButton = styled.div`
  width: auto !important;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  background: ${theme.colors.defaultRed};
  padding: 0px 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`
