import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { moveStatusIconMapping } from './statusIconMapping'
import {
  DateTimePickerButton,
  CalendarIconButton,
  TimesOfDay
} from '../../../UI/DataFields/DateTimePicker/views/DateTimePickerButton'
import { dateService } from '../../../../services/timeService'
import theme from '../../../../styles/theme'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { setMovesActivitiesStatus } from '../../epics/routeBuilder'
import { useRouteBuilder } from '../../../../hooks/useRouteBuilder'
import { TransportationActivityViewDTO } from '../../../../api/origin/business-logic'
import { IStore } from '../../../../store/store.interface'
import { IBuySideQuoteState } from '../../../../services/DTO/buySideQuote/interfaces'
import { IDispatchDeliveryOrderState } from '../../../common/dispatchDeliveryOrder/interfaces'
import {
  IActiveRouteBuilderTab,
  IMove,
  IMoveDTO,
  MoveStatus,
  RouteBuilderGridView
} from '../../../../store/reducers/communicationHub/interfaces'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { getMoveStatus } from '../../../../services/functions/get/getMoveStatus'
import { isMoveStatusChangeable } from '../../../../services/functions/test/isMoveStatusChangeable'
import { isMoveAvailableToChangeStatus } from '../../../../services/functions/test/isMoveAvailableToChangeStatus'
import { RouteBuilderToastMessageType } from './RouteBuilderToastMessage'
import { transformMoney } from '../../../UI/Input'

type OwnProps = {
  view: RouteBuilderGridView
  tabs: IActiveRouteBuilderTab[]
  currentDayMoves: IMoveDTO[]
}

type StateProps = {
  dispatchDeliveryOrderMapping: IDispatchDeliveryOrderState
  transportationActivityMapping: Record<string, TransportationActivityViewDTO>
  buySideQuoteMapping: IBuySideQuoteState
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const {
    tabs,
    view,
    buySideQuoteMapping,
    transportationActivityMapping,
    dispatchDeliveryOrderMapping,
    currentDayMoves
  } = props
  const dispatch = useAppDispatch()
  const {
    setFetch,
    getCurrentTabDayMoves,
    isDriverAssignable,
    vendorId,
    authUserId,
    setMoveErrorMappingRef,
    setRouteBuiderToastMessage
  } = useRouteBuilder()
  const assignAllMovesRef = React.useRef(async () => {})
  const fullActiveTabMoves = React.useMemo(
    (): IMove[] =>
      currentDayMoves.map(move => {
        const result = {
          ...move,
          dispatchDeliveryOrder: {
            status: oc(dispatchDeliveryOrderMapping[move.dispatchDeliveryOrderId]).status()
          },
          _status: undefined,
          deliveryOrderType: undefined,
          items: move.items.map(item => ({
            ...item,
            gotoActivity: transportationActivityMapping[item.gotoActivityId],
            businessActivity: transportationActivityMapping[item.businessActivityId]
          }))
        } as IMove
        result._status = getMoveStatus(result)
        return result
      }),
    [currentDayMoves, transportationActivityMapping, dispatchDeliveryOrderMapping]
  )

  assignAllMovesRef.current = async () => {
    try {
      const movesToUpdate: IMove[] = []
      let someMoveErrors = false

      fullActiveTabMoves.forEach(move => {
        const testResult = isMoveAvailableToChangeStatus({
          move,
          status: MoveStatus.PLANNED
        })

        if (testResult.changeable) {
          movesToUpdate.push(move)
        } else if (!testResult.changeable && testResult.errorMessage) {
          someMoveErrors = true

          if (setMoveErrorMappingRef.current[move.id]) {
            setMoveErrorMappingRef.current[move.id](testResult.errorMessage)
          }
        }
      })

      if (someMoveErrors) {
        setRouteBuiderToastMessage({
          type: RouteBuilderToastMessageType.error,
          message: "Some moves are not assigned. Some activities don't have location"
        })
      }

      if (!movesToUpdate.length) {
        return
      }

      setFetch(true)
      await setMovesActivitiesStatus({
        vendorId,
        authUserId,
        moves: movesToUpdate,
        searchStatus: TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
        newStatus: TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED
      })
    } catch (e) {}

    setFetch(false)
  }

  return (
    <Container>
      {tabs.map(({ isActive, moves, date }, tabIndex) => {
        const isToday = dateService.isCurrentDay(date)
        const label = isToday ? 'Today' : dateService.makeLabel(date, { long: true, fullYear: true })

        const moveToDay = (_date: string) =>
          dispatch(
            communicationHubActions.setActiveRouteBuilderTabIndexDate({
              tabIndex,
              date: _date,
              isActive: true
            })
          )

        const RenderDayInfo = (
          <TabDayInfo
            onClick={
              isActive ? undefined : () => dispatch(communicationHubActions.setActiveRouteBuilderTabIndex(tabIndex))
            }
          >
            <TabColumnDate>{label}</TabColumnDate>
            <RefreshButton
              className={'mdi mdi-refresh'}
              onClick={async (event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                setFetch(true)
                await getCurrentTabDayMoves()
                setFetch(false)
              }}
            />
            <TabColumnMoveStatusList>
              {fullActiveTabMoves.map(move => (
                <TabColumnMoveStatus key={move.id} children={moveStatusIconMapping[move._status]} />
              ))}
            </TabColumnMoveStatusList>
          </TabDayInfo>
        )

        return (
          <TabColumn key={tabIndex} className={cn({ active: isActive })}>
            <TabDayArrow
              className={'mdi mdi-chevron-left'}
              onClick={() => moveToDay(dateService.moveDate(date, { addDay: -1 }))}
            />
            <DateTimePickerButton
              renderButtonComponent={RenderDayInfo}
              date={date}
              timesOfDay={TimesOfDay.startWorkDay}
              calendarPosition={'center'}
              onChange={moveToDay}
            />
            <TabDayArrow
              className={'mdi mdi-chevron-right'}
              onClick={() => moveToDay(dateService.moveDate(date, { addDay: 1 }))}
            />
            <DatePickerColumn>
              <CalendarIconButton
                className={cn('mdi mdi-calendar', { disabled: isToday })}
                onClick={isToday ? undefined : () => moveToDay(dateService.createStringDate.startWorkDay)}
              />
            </DatePickerColumn>
          </TabColumn>
        )
      })}
      <Column style={{ flexGrow: 1, justifyContent: 'center' }}>
        <ActionButton
          round={true}
          filled={true}
          disabled={
            !isDriverAssignable || !fullActiveTabMoves.some(move => isMoveStatusChangeable(move)[MoveStatus.PLANNED])
          }
          onClick={() => {
            showModalWindow({
              width: 320,
              title: 'Are you sure you want to assign all moves?',
              buttons: [
                {
                  label: 'No'
                },
                {
                  label: 'Yes',
                  onClick: () => assignAllMovesRef.current()
                }
              ]
            })
          }}
        >
          Assign All
        </ActionButton>
      </Column>
      <AmountColumn>
        {transformMoney(
          currentDayMoves.reduce(
            (accMoves, { items }) =>
              items.reduce(
                (acc, { gotoActivityId, businessActivityId }) =>
                  acc +
                  oc(buySideQuoteMapping[gotoActivityId]).amount(0) +
                  oc(buySideQuoteMapping[businessActivityId]).amount(0),
                accMoves
              ),
            0
          )
        )}
      </AmountColumn>
      {/*<ViewListButton className={cn('mdi', view)} onClick={() => props.toggleActiveRouteBuilderView()} />*/}
    </Container>
  )
}

export const RouteBuilderDashboard = connect(
  ({ activity: { transportationActivities }, dispatchDeliveryOrder, buySideQuotes }: IStore) => {
    return {
      dispatchDeliveryOrderMapping: dispatchDeliveryOrder,
      transportationActivityMapping: transportationActivities,
      buySideQuoteMapping: buySideQuotes
    }
  }
)(React.memo(Component))

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: stretch;
  height: 60px;
  background-color: #f5f6fa;
  border-bottom: 1px solid #e3e8ea;
  user-select: none;
`
const Column = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-right: 1px solid #e3e8ea;
`
const TabColumn = styled(Column)`
  flex-shrink: 0;
  color: #9aa6b6;
  align-items: stretch;
  text-align: center;
  background-color: #f5f6fa;
  padding: 0 5px;
  cursor: pointer;

  &.active {
    color: ${theme.colors.basicBlueColor};
  }
`
const TabDayInfo = styled.div`
  flex-shrink: 0;
  width: 200px;
  height: 100%;
  cursor: pointer;

  &:hover {
    background-color: #fcfdff;
  }
`
const TabColumnDate = styled.div`
  flex-shrink: 0;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 10px 8px;
  user-select: none;
`
const TabColumnMoveStatusList = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const TabColumnMoveStatus = styled.div`
  display: inline-flex;
  margin: 0 1px;
`
const DatePickerColumn = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 13px 0 8px;
`
const TabDayArrow = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #9ba8b8;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
const RefreshButton = styled.div`
  flex-shrink: 0;
  position: absolute;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  color: #9ba8b8;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`
const AmountColumn = styled(Column)`
  min-width: 93px;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  color: #445366;
  padding: 0 10px;
`
const ViewListButton = styled(Column)`
  width: 60px;
  justify-content: center;
  font-size: 24px;
  color: #445366;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #fcfdff;
  }
`

/// id: "58e703bf-edb4-4e7d-ad37-067141df7997"
// updatedAt: "2022-08-03T17:13:17.337Z"
// updatedAt: "2022-08-03T17:13:17.337Z"
