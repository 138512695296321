import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ISellSideQuoteState } from '../interfaces'
import { addSSQsToStore, deleteSSQIds } from '../actions'
import * as R from 'remeda'

const sellSideQuoteState: ISellSideQuoteState = {}

export const sellSideQuoteReducer = reducerWithInitialState<ISellSideQuoteState>(sellSideQuoteState)
  .case(addSSQsToStore, (store, newItems) => ({ ...store, ...newItems }))
  .case(deleteSSQIds, (store, deleteIds) => {
    return R.omit(store, deleteIds)
  })
  .build()
