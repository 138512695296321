import { oc } from 'ts-optchain'

export const getStoreObjectsByIds = (ids: string[], storeList: any, transformItemFn?: any): any[] => {
  if (ids && ids.length && storeList) {
    return ids.reduce((acc, id) => {
      const item = storeList[id]
      if (item) {
        const resultedItem = transformItemFn ? transformItemFn(item) : item
        if (resultedItem) {
          acc.push(resultedItem)
        }
      }
      return acc
    }, [])
  }

  return []
}

export const whatIsIt = (item: any) => {
  return {
    get isString(): boolean {
      return typeof item === 'string'
    },
    get isFunction(): boolean {
      return Object.prototype.toString.call(item) === '[object Function]'
    },
    get isArray(): boolean {
      return Object.prototype.toString.call(item) === '[object Array]'
    },
    get isDate(): boolean {
      return Object.prototype.toString.call(item) === '[object Date]'
    },
    get isObject(): boolean {
      return Object.prototype.toString.call(item) === '[object Object]'
    },
    get isNewObject(): boolean {
      const id = oc(item).id('')
      return id.includes('new') || id.includes('fake')
    },
    get isNewId(): boolean {
      const id = item && typeof item === 'string' ? item : ''
      return id.includes('new') || id.includes('fake')
    }
  }
}

export const processObject = (object: any) => {
  if (!object) {
    return {
      clean: object,
      hardClean: object
    }
  }

  const omitEmptyValues = (obj: object, hard: boolean) => {
    return Object.keys(obj || {}).reduce((acc, key) => {
      let value = obj[key]

      if (value === null || value === undefined) {
        return acc
      }

      if (whatIsIt(value).isObject) {
        value = omitEmptyValues(value, hard)

        if (hard) {
          if (Object.keys(value).length) {
            acc[key] = value
          }
        } else {
          acc[key] = value
        }
      } else {
        acc[key] = value
      }

      return acc
    }, {})
  }

  return {
    get clean() {
      return omitEmptyValues(object, false)
    },
    get hardClean() {
      return omitEmptyValues(object, true)
    }
  }
}
