import styled from 'styled-components'

export const StyledPopup = styled.div`
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 -16px;
  height: calc(100% - 40px);
`

export const StyledColumns = styled.div`
  height: 100%;
  display: flex;
`

export const StyledColumn = styled.div`
  position: relative; // for inner spinner
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const StyledColumnHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #f2f2f2;
  padding: 0 16px;
`

export const VerticalSeparator = styled.div`
  z-index: 1;
  width: 1px;
  background-color: #e5e5e5;
`
