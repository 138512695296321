import { oc } from 'ts-optchain'
import { getCommunicationHubState, getDispatch } from '../../../../store'
import { getStore } from '../../../../store/configureStore'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { CommunicationHubTab } from '../../../../store/reducers/communicationHub/interfaces'
import { leaveChatChannelIfNoEditing } from '../../../CommunicationHub/functions/leaveChatChannelIfNoEditing'
import { updateTabFilters } from '../../tabs/actions'
import { SchedulerFilter } from '../interfaces'

export const toggleSyncDriverActivitiesWithRouteBuilder = (state: boolean) => {
  const { activeRouteBuilder, activeTab } = getCommunicationHubState()
  const {
    tabs: { activeMainTabId, main }
  } = getStore().getState()
  const currentTab = main.find(tab => tab.id === activeMainTabId)

  if (!currentTab) {
    return
  }

  const filter = oc(currentTab)
    .uiSettings.filter([])
    .filter(({ column }) => column !== SchedulerFilter.syncWithRouteBuilder)
    .concat({ column: SchedulerFilter.syncWithRouteBuilder, value: state })

  if (state === true && !(activeTab === CommunicationHubTab.routeBuilder && oc(activeRouteBuilder).vendorId())) {
    leaveChatChannelIfNoEditing(() =>
      getDispatch()(communicationHubActions.setActiveTab({ activeTab: CommunicationHubTab.routeBuilder, expand: true }))
    )
  }

  getStore().dispatch(updateTabFilters({ id: currentTab.id, filter }))
}
