import * as React from 'react'
import styled from 'styled-components'
import { Portal } from 'react-portal'
import { CreateDeductionTransactionDTO } from '../../../../../../api/origin/vendor-accounting-service'
import {
  BottomSection,
  MiddleSection,
  StyledModalWindow,
  TopSection,
  WindowOverlay
} from '../../../../../UI/ModalWindow/views/styles'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { Select } from '../../../../../UI/DataFields/Select'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { regExpOnlyNumbersAndLetters } from '../../../../../../services/functions/regExp'
import { deductionLists } from '../../../../../../services/select/deductionLists'
import { requestCreateDeductionTransaction } from '../../../../../../services/DTO/deduction/fetch'
import { GridSpinner } from '../../../../../UI/Spinner/Spinner'
import { showSuccessModalWindow } from '../../../../../../store/reducers/modalWindow/functions'
import { dateService } from '../../../../../../services/timeService'
import { deductionDirectory } from '../../../../../../services/DTO/deduction/directory'
import { CloseIcon } from '../../../../../UI/icons/CloseIcon'
import { transformMoney } from '../../../../../UI/Input'

type Props = {}

export const PayBackDeductionPopup = (props: Props) => {
  const { payBackDeduction, setPayBackDeduction, requestDriverDeductionsWithSpinner } = useDriverDeductions()
  const [fetching, setFetching] = React.useState(false)
  const [transaction, setTransaction] = React.useState<CreateDeductionTransactionDTO>({
    deductionId: payBackDeduction.id,
    date: undefined,
    checkType: undefined,
    checkNumber: undefined,
    amount: undefined,
    description: undefined
  })
  const popupTitle = 'Pay Back'
  const currentBalance = payBackDeduction.currentBalance || 0
  const isAmountValid = currentBalance !== 0 && (transaction.amount || 0) <= currentBalance
  const isRequestEnable =
    transaction.deductionId &&
    transaction.date &&
    Boolean(
      (!transaction.checkType && !transaction.checkNumber) || (transaction.checkType && transaction.checkNumber)
    ) &&
    (typeof transaction.amount === 'number' && isAmountValid)

  const changePropValue = (prop: keyof CreateDeductionTransactionDTO) => (value: any) => {
    setTransaction(item => ({ ...item, [prop]: value }))
  }

  const requestTransaction = () => {
    setFetching(true)
    requestCreateDeductionTransaction({ ...transaction, amount: -transaction.amount })
      .then(async requestedRefundTransaction => {
        showSuccessModalWindow({
          title: popupTitle,
          content: (
            <>
              <RefundRow>
                <RefundLabel>Date:</RefundLabel>
                {dateService.makeLabel(requestedRefundTransaction.date, { hideTime: true })}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Check Type:</RefundLabel>
                {deductionDirectory.checkType[requestedRefundTransaction.checkType]}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Check #:</RefundLabel>
                {requestedRefundTransaction.checkNumber}
              </RefundRow>
              <RefundRow>
                <RefundLabel>Sum:</RefundLabel>
                {transformMoney(Math.abs(requestedRefundTransaction.amount))}
              </RefundRow>
              {requestedRefundTransaction.description && (
                <RefundRow>
                  <RefundLabel style={{ alignSelf: 'flex-start' }}>Description:</RefundLabel>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{requestedRefundTransaction.description}</p>
                </RefundRow>
              )}
            </>
          )
        })
        setPayBackDeduction(null)
        requestDriverDeductionsWithSpinner()
      })
      .catch(() => setFetching(false))
  }

  return (
    <Portal>
      <WindowOverlay>
        <StyledModalWindow style={{ minWidth: 600, maxWidth: 600, position: 'relative' }}>
          {fetching && <GridSpinner />}
          <TopSection>
            {popupTitle} <CloseIcon style={{ marginLeft: 'auto' }} onClick={() => setPayBackDeduction(null)} />
          </TopSection>
          <MiddleSection>
            <InputsContainer>
              <DateTimePicker
                title={'Date'}
                required={true}
                hideTimeInput={true}
                date={transaction.date}
                onChange={changePropValue('date')}
              />
              <Select
                title={'Check Type'}
                selectedValue={transaction.checkType}
                list={[{ label: '–', value: undefined }].concat(deductionLists.checkType)}
                onSelect={changePropValue('checkType')}
              />
              <Input
                title={'Check #'}
                maxLength={8}
                regExp={regExpOnlyNumbersAndLetters}
                value={transaction.checkNumber}
                placeholder={'#'}
                onChange={changePropValue('checkNumber')}
              />
              <InputField.Money
                title={'Sum'}
                required={true}
                highlighted={!isAmountValid}
                isPositiveOrNegative={true}
                errorMessage={
                  typeof transaction.amount === 'number' &&
                  !isAmountValid && (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {transaction.amount === 0 ? "Sum can't be $0" : `Sum can't be bigger than $${currentBalance}`}
                    </span>
                  )
                }
                value={transaction.amount}
                onChange={changePropValue('amount')}
              />
            </InputsContainer>
            <TextareaContainer>
              <FieldContainer title={'Description (optional)'}>
                <Textarea
                  value={transaction.description}
                  maxLength={35}
                  placeholder={'Type your note here...'}
                  onChange={event => changePropValue('description')(event.target.value)}
                />
              </FieldContainer>
            </TextareaContainer>
          </MiddleSection>
          <BottomSection style={{ justifyContent: 'space-between' }}>
            <ActionButton round={true} onClick={() => setPayBackDeduction(null)}>
              Cancel
            </ActionButton>
            <ActionButton
              filled={true}
              round={true}
              disabled={!isRequestEnable}
              onClick={isRequestEnable ? requestTransaction : undefined}
            >
              Confirm
            </ActionButton>
          </BottomSection>
        </StyledModalWindow>
      </WindowOverlay>
    </Portal>
  )
}

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 12px));
  grid-gap: 16px;
  margin-bottom: 24px;
`

const TextareaContainer = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr;
  grid-gap: 50px;
`

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  padding: 10px 12px;

  &::placeholder {
    color: #d8d8d8;
  }
`

const Label = styled.div`
  font-size: 12px;
  color: black;
  user-select: none;
  margin-right: 4px;
`

const ShowClosedButton = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  cursor: pointer;

  &.disabled {
    cursor: default;

    * {
      color: #999999;
      cursor: default;
    }
  }
`

const RefundRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`
const RefundLabel = styled.div`
  width: 100px;
  font-weight: 500;
  margin-right: 20px;
`
