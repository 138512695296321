import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import theme from '../../../../../styles/theme'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { UserAvatar, UserAvatarSize } from '../../UserAvatar'
import { NotificationHeader } from './NotificationHeader'
import { NotificationText } from './NotificationText'
import { NotificationStatistic } from './NotificationStatistic'
import { NotificationDTO } from '../../../../../api/origin/communication-hub-service'
import { selectContact } from '../../../../../store/select/contactSelect'
import { notificationDirectory } from '../../../../../services/DTO/notification/directory'
import { selectNotification, selectNotificationDocumentForm } from '../../../../../store/select/notificationSelect'
import { NotificationActions } from './NotificationActions'
import { GridItemSpinner } from '../../../../UI/Spinner/Spinner'
import { driverNotificationStatuses } from '../../../../../services/constants/driverStatuses'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'

type OwnProps = {
  id: string
  styles?: React.CSSProperties
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const totalListItemsNumber = Object.values(driverNotificationStatuses).reduce((acc, curr) => acc + curr.length, 0)

export const Notification = React.memo((props: Props) => {
  const { id, styles } = props
  const containerRef = React.useRef(null)
  const notification = useAppSelector(selectNotification(id))
  const { subject, text, senderUserId, createdAt, driverStatuses, type, documentType } = notification || {}
  const sender = useAppSelector(selectContact(senderUserId))
  // @ts-ignore
  const documnetForm = useAppSelector(selectNotificationDocumentForm({ documentType }))
  const statusColor = notificationDirectory.statusColor[notification.status]
  const tags = React.useMemo(() => {
    const isNotifyAllDrivers = driverStatuses ? driverStatuses.length === totalListItemsNumber : false
    return [
      notificationDirectory.type[type]
        ? { text: notificationDirectory.type[type], style: { flexShrink: 0 } }
        : undefined,
      driverStatuses && driverStatuses.length
        ? {
            text: `Notify: ${
              isNotifyAllDrivers
                ? 'All Drivers'
                : driverStatuses
                    .map(item => driverDirectory.status[item])
                    .filter(Boolean)
                    .join(', ')
            }`
          }
        : undefined
    ].filter(Boolean)
  }, [type, driverStatuses])

  if (!notification) {
    return null
  }

  return (
    <Container
      ref={containerRef}
      style={{
        backgroundColor: statusColor,
        boxShadow:
          notification.status === NotificationDTO.StatusEnum.DELETED
            ? `0 0 0 1px ${theme.colors.defaultRed}`
            : undefined,
        ...(styles || {})
      }}
    >
      <UserAvatar showUserDetails={true} user={sender} size={UserAvatarSize.medium} />
      <Details>
        <NotificationHeader sender={sender} date={createdAt as string} tags={tags} />
        <NotificationText subject={subject || oc(documnetForm).name()} text={text as any} statusColor={statusColor} />
        <NotificationStatistic notification={notification} />
      </Details>
      <NotificationActions containerRef={containerRef} notification={notification} />
    </Container>
  )
})

const Container = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 18px;
  grid-column-gap: 8px;
  border-radius: 8px;
  background-color: rgba(239, 240, 242, 0.8);
  padding: 8px;
`
const Details = styled.div`
  position: relative;
  overflow: hidden;
`
