import * as React from 'react'
import { ContainerTypeDTO, ContainerViewDTO } from '../../../api/origin/business-logic'

type TActivityContext = {
  saveButton: any
  isModifiedMode: boolean
  required: {
    chassisNumber: boolean
  }
  functions: {
    updateContainerType: (containerType: ContainerTypeDTO) => void
    updateContainer: (container: ContainerViewDTO) => void
  }
}

export const ActivityContext: React.Context<TActivityContext> = React.createContext({
  saveButton: null,
  isModifiedMode: null,
  functions: null,
  required: null
})
