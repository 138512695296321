import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderDTO, DispatchDeliveryOrderViewDTO } from '../../../../api/api'
import { v4 } from 'uuid'
import { addDeliveryOrderToStore } from '../action'
import { IDeliveryOrderState, IDeliveryOrder } from '../interfaces'
import { dateService } from '../../../../services/timeService'

export const newDeliveryOrder = (): IDeliveryOrder => ({
  fullObject: true,
  id: 'newDeliveryOrder',
  cargoId: undefined,
  cargo: { referenceNumber: undefined, description: undefined },
  customerId: undefined,
  deliveryLocationId: undefined,
  pickupLocationId: undefined,
  firstReceivingDate: undefined,
  generalCutoffDate: undefined,
  type: undefined,
  loadType: DispatchDeliveryOrderDTO.LoadTypeEnum.LIVELOAD,
  dispatchDeliveryOrders: [],
  billOfLadingNumber: undefined,
  bookingNumber: undefined,
  steamShipLineId: undefined,
  dispatchDeliveryOrderIds: [],
  date: dateService.createStringDate.now
})

export const createNewDDO = ({
  type,
  pickupLocationId,
  deliveryLocationId,
  returnLocationId,
  pickupLocation,
  deliveryLocation,
  returnLocation,
  loadType,
  appointmentDateTimeRange,
  id
}: IDeliveryOrder): DispatchDeliveryOrderViewDTO =>
  ({
    id: 'fake_' + v4(),
    fullObject: true,
    activityIds: [],
    buySideQuoteIds: [],
    containerId: undefined,
    weight: undefined,
    weightUnit: DispatchDeliveryOrderDTO.WeightUnitEnum.LBS,
    status: DispatchDeliveryOrderDTO.StatusEnum.NEW,
    overweightIndicator: false,
    deliveryOrderId: id,
    containerTypeId: undefined,
    loadType: type === DeliveryOrderViewDTO.TypeEnum.REPOSITION ? undefined : loadType,
    pickupStage: {
      locationId: pickupLocationId,
      location: pickupLocation
    },
    deliveryStage:
      type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? {}
        : {
            locationId: deliveryLocationId,
            location: deliveryLocation,
            plannedAppointmentDateTimeRange:
              appointmentDateTimeRange && appointmentDateTimeRange.from
                ? { ...appointmentDateTimeRange, confirmed: true }
                : appointmentDateTimeRange
          },
    returnStage: {
      locationId: returnLocationId,
      location: returnLocation
    }
  } as any)

const deliveryOrderState: IDeliveryOrderState = {}

const deliveryOrderReducer = reducerWithInitialState<IDeliveryOrderState>(deliveryOrderState)
  .case(addDeliveryOrderToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default deliveryOrderReducer
