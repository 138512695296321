import * as React from 'react'
import { Button, Container, Info, Title } from './styledComponents'
import { authRedirect } from '../index'
import { AuthorizationState } from '../../../store/store.interface'

type Props = {
  authorizationState: AuthorizationState
}

export const AuthError: React.SFC<Props> = props => {
  let errorText: string
  switch (props.authorizationState) {
    case AuthorizationState.FailedWithAuth:
      errorText = 'Something went wrong with Auth'
      break
    case AuthorizationState.ServerError:
      errorText = 'Server error'
      break
    default:
      errorText = 'Something went wrong'
  }

  return (
    <Container>
      <Title>Error</Title>
      <Info>{errorText}</Info>
      <Button onClick={() => authRedirect()}>Try Again</Button>
    </Container>
  )
}
