import * as React from 'react'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderSearch } from '../../../../../UI/DataFields/SearchRequest'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { SchedulerFilter } from '../../../interfaces'
import { getStore } from '../../../../../../store/configureStore'
import { updateTab } from '../../../../tabs/actions'
import { ITabState } from '../../../../tabs/interfaces'

type OwnProps = {
  currentTab: ITabState
  dispatchDeliveryOrderNumber: number
  dispatchDeliveryOrderId: string
}
type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const SelectDispatchDeliveryOrder = React.memo((props: Props) => {
  const { currentTab, dispatchDeliveryOrderNumber, dispatchDeliveryOrderId } = props

  return (
    <FieldContainer style={{ width: 150, marginRight: 4 }} title={'Search'}>
      <DispatchDeliveryOrderSearch
        placeholder={'DDO#'}
        value={{ id: dispatchDeliveryOrderId, number: dispatchDeliveryOrderNumber }}
        onChange={(ddoParams: { id: string; number: string }) => {
          const changed = dispatchDeliveryOrderId !== oc(ddoParams).id()

          if (!changed) {
            return
          }

          const { dispatch } = getStore()
          let updatedFilter = oc(currentTab)
            .uiSettings.filter([])
            .filter(
              _ =>
                _.column !== SchedulerFilter.dispatchDeliveryOrderNumber &&
                _.column !== SchedulerFilter.dispatchDeliveryOrderId
            )

          if (ddoParams) {
            updatedFilter = updatedFilter.concat([
              {
                column: SchedulerFilter.dispatchDeliveryOrderNumber,
                value: Number(ddoParams.number)
              },
              {
                column: SchedulerFilter.dispatchDeliveryOrderId,
                value: ddoParams.id
              }
            ])
          }

          dispatch(
            updateTab({
              tab: {
                ...currentTab,
                data: {
                  ...oc(currentTab).data({}),
                  showMissed: Boolean(ddoParams),
                  ...(ddoParams ? { showNow: true } : {})
                },
                uiSettings: {
                  ...currentTab.uiSettings,
                  filter: updatedFilter
                }
              }
            })
          )
        }}
      />
    </FieldContainer>
  )
})
