import * as React from 'react'
import { Portal } from 'react-portal'
import {
  WindowOverlay,
  StyledModalWindow,
  TopSection,
  MiddleSection,
  BottomSection,
  CopyContentButton,
  CloseButton
} from './styles'
import { Button } from './Button'
import { ModalWindowIconMapping } from '../../icons/ModalWindowIconMapping'
import { ModalWindow as ModalWindowSpace } from '../../../../store/reducers/modalWindow/interfaces'
import { useModalWindow } from '../../../../hooks/useModalWindow'
import { CloseIcon } from '../../icons/CloseIcon'

const defaultCloseButtonData: ModalWindowSpace.Button = { label: 'Close', outline: true }

export const ModalWindow = () => {
  const { modalWindow, closeModalWindow } = useModalWindow()

  if (!modalWindow) {
    return null
  }

  const { closeButton, title, content, type, width, styleTopSetion, hasMaxHeight } = modalWindow
  const _buttons: ModalWindowSpace.Button[] =
    modalWindow.buttons && modalWindow.buttons.length ? modalWindow.buttons : [defaultCloseButtonData]
  const buttons = _buttons.filter(Boolean)

  return (
    <Portal>
      <WindowOverlay>
        <StyledModalWindow className={'ModalWindow'} style={width ? { minWidth: width, maxWidth: width } : undefined}>
          {Boolean(title || type) && (
            <TopSection style={styleTopSetion}>
              {type && <span style={{ marginRight: 12 }}>{ModalWindowIconMapping[type]}</span>}
              {title}
              {closeButton && <CloseButton children={<CloseIcon />} onClick={closeModalWindow} />}
              {type === ModalWindowSpace.Type.ALERT && typeof content === 'string' && (
                <CopyContentButton
                  className={'mdi mdi-content-copy'}
                  data-copy-button={true}
                  data-copy-value={content}
                />
              )}
            </TopSection>
          )}
          {Boolean(content) && (
            <MiddleSection
              style={
                hasMaxHeight || type === ModalWindowSpace.Type.ALERT
                  ? { maxHeight: '70vh', overflow: 'auto' }
                  : undefined
              }
              children={content}
            />
          )}
          {Boolean(buttons.length) && (
            <BottomSection style={{ justifyContent: buttons.length > 1 ? 'space-between' : 'flex-end' }}>
              {buttons.map((button, index) => (
                <Button key={index} {...button} />
              ))}
            </BottomSection>
          )}
        </StyledModalWindow>
      </WindowOverlay>
    </Portal>
  )
}
