import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import { BuySideQuoteDTO } from '../../../api/origin/business-logic'
import { getObjectsDifference } from '../ConflictsOnSaving'
import * as R from 'remeda'
import { oc } from 'ts-optchain'

const omitBSQFields = ['temporaryData']

export const getBuySideQuoteStatus = (buySideQuote: BuySideQuoteDTO): TObjectStatusForSave => {
  if (!buySideQuote) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false,
      getDifference: undefined
    }
  }

  const store = getStore().getState()
  const storeBuySideQuote = store.buySideQuotes[buySideQuote.id]

  if (!storeBuySideQuote) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false,
      getDifference: undefined
    }
  }

  const oldObject = omitUpdateInfo(R.omit(storeBuySideQuote, omitBSQFields as any[]))
  const newObject = omitUpdateInfo(R.omit(buySideQuote, omitBSQFields as any[]))

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(buySideQuote, storeBuySideQuote)
    },
    get needToSave() {
      return oc(newObject).fullObject() && !isEqualObjects(oldObject, newObject)
    },
    get getDifference() {
      return getObjectsDifference({ oldObject: newObject, newObject: oldObject })
    }
  }
}
