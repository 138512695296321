import * as React from 'react'
import styled from 'styled-components'
import { AccountingTabDeductionsWeekly } from './AccountingTabDeductionsWeekly'
import { AccountingTabOtherDeductions } from './AccountingTabOtherDeductions'
import { RefundEscrowDeductionPopup } from './RefundEscrowDeductionPopup'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { BankingDetailsPopup } from './BankingDetailsPopup'
import { PayBackDeductionPopup } from './PayBackDeductionPopup'

type Props = {}

export const AccountingTab = (props: Props) => {
  const {
    driver,
    refundEscrowDeduction,
    closeEscrowDeduction,
    payBackDeduction,
    currentDeductions,
    showAccountingPopup
  } = useDriverDeductions()

  return (
    <Container>
      <AccountingTabDeductionsWeekly />
      <AccountingTabOtherDeductions />
      {refundEscrowDeduction && currentDeductions.find(item => item.id === refundEscrowDeduction.id) && (
        <RefundEscrowDeductionPopup />
      )}
      {closeEscrowDeduction && currentDeductions.find(item => item.id === closeEscrowDeduction.id) && (
        <RefundEscrowDeductionPopup />
      )}
      {payBackDeduction && currentDeductions.find(item => item.id === payBackDeduction.id) && <PayBackDeductionPopup />}
      {showAccountingPopup && <BankingDetailsPopup />}
    </Container>
  )
}

const Container = styled.div`
  height: 405px;
  display: grid;
  grid-template-columns: 250px 1fr;
  overflow-x: auto;
`

export const Title = styled.div`
  color: #475364;
  font-size: 12px;
  font-weight: 500;
`
