import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ApplicationContext } from '../../store/store.interface'
import { ITabState } from '../../components/common/tabs/interfaces'
import { IFilterItem } from '../../components/common/grid/views/header/column'
import { updateTabGridItems } from '../../components/common/tabs/actions'
import { getStore } from '../../store/configureStore'

export const updateStoreObjectsWithTabsItemList = ({
  objectType,
  actionToUpdate,
  objectsToUpdate,
  objectsToAdd,
  listState
}: {
  objectType: string
  objectsToUpdate: any[]
  objectsToAdd: any[]
  actionToUpdate: any
  listState?: Record<string, any>
}) => {
  const { getState, dispatch } = getStore()
  const store = getState()
  const objects = objectsToAdd.concat(
    objectsToUpdate.filter(
      ({ id }) => (listState || store[objectType])[id] || objectsToAdd.find(objectToAdd => oc(objectToAdd).id() === id)
    )
  )

  if (!objects.length) {
    return
  }

  // update objects in store
  actionToUpdate(objects)

  // remove unsuitable Objects from grid
  const tabs: ITabState[] = store.tabs.main.filter(
    ({ visited, type, ignoreWS }) => !ignoreWS && visited && type === objectType
  )

  tabs.forEach(tab => {
    const filters = tab.uiSettings.filter
    const sorting = tab.uiSettings.sorting
    const { unsuitableIds, suitableIds } = filterObjectsByTabFilter(objects, filters)
    const gridItemIds: string[] = tab.gridItemIds || []
    let updatedGridItemIds: string[] = gridItemIds
    let hasUpdates = false
    let needSort = false
    const checkSortingColumns = ['daysOut']
    checkSortingColumns.push(...checkSortingColumns.map(column => '-' + column))

    if (suitableIds.length) {
      updatedGridItemIds = R.uniq(suitableIds.concat(updatedGridItemIds))
      hasUpdates = updatedGridItemIds.length !== gridItemIds.length

      if (objectType === 'dispatchDeliveryOrder' && checkSortingColumns.includes(sorting)) {
        needSort = true
      }
    }

    if (unsuitableIds.length) {
      updatedGridItemIds = updatedGridItemIds.filter(id => !unsuitableIds.includes(id))
      hasUpdates = updatedGridItemIds.length !== gridItemIds.length
    }

    if (needSort) {
      hasUpdates = true
      const updatedObjectsMapping: Record<string, any> = objects.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
      const sortItems: any[] = []
      const staticIds: string[] = []

      updatedGridItemIds.forEach(id => {
        const item = updatedObjectsMapping[id] || (listState || store[objectType])[id]

        if (item && typeof oc(item).daysOut() === 'number') {
          sortItems.push(item)
        } else {
          staticIds.push(id)
        }
      })

      updatedGridItemIds = sortItems
        .sort(
          sorting.includes('-')
            ? (a: any, b: any) => {
                return b.daysOut - a.daysOut
              }
            : (a: any, b: any) => {
                return a.daysOut - b.daysOut
              }
        )
        .map(item => item.id)
        .concat(staticIds)
    }

    if (hasUpdates) {
      dispatch(
        updateTabGridItems({
          context: ApplicationContext.main,
          tabId: tab.id,
          idList: updatedGridItemIds
        })
      )
    }
  })
}

export const filterObjectsByTabFilter = (
  items: any[],
  filters: IFilterItem[]
): { suitableIds: string[]; unsuitableIds: string[] } => {
  const suitableIds: string[] = []
  const unsuitableIds: string[] = []

  if (filters && filters.length) {
    items.forEach(item => {
      const isSuitable = filters.every(({ column, value, path, combinationOfValuePaths, isEqualToValue }) => {
        // use column value = NULL to omit the field
        if (value === null || value === undefined) {
          return true
        }

        if (combinationOfValuePaths && combinationOfValuePaths.length) {
          const objectFieldsValues = combinationOfValuePaths.map(_path => getObjectFieldValueByPath(item, _path))
          return objectFieldsValues.some(objectFieldValue => compareValues(value, objectFieldValue, isEqualToValue))
        } else {
          const objectFieldValue = getObjectFieldValueByPath(item, path || column)
          return compareValues(value, objectFieldValue, isEqualToValue)
        }
      })

      if (isSuitable) {
        suitableIds.push(item.id)
      } else {
        unsuitableIds.push(item.id)
      }
    })
  } else {
    suitableIds.push(...items.map(({ id }) => id))
  }

  return { suitableIds, unsuitableIds }
}

export const compareValues = (filterValue: any, entityValue: any, isEqualToValue: boolean): boolean => {
  const match = (() => {
    if (entityValue === undefined || entityValue === null) {
      return false
    }

    // multi select
    if (Array.isArray(filterValue)) {
      if (filterValue.length === 0) {
        return true
      }

      return filterValue.some(item => String(entityValue).toUpperCase() === String(item).toUpperCase())
    }

    // date
    if (typeof filterValue === 'object' && (filterValue.from && filterValue.to)) {
      const filterDate: { from: string; to: string } = filterValue
      const from = Date.parse(filterDate.from)
      const to = Date.parse(filterDate.to)
      const currentDate = Date.parse(entityValue)

      return currentDate >= from && currentDate <= to
    }

    // string
    return String(entityValue)
      .toUpperCase()
      .includes(String(filterValue).toUpperCase())
  })()

  return isEqualToValue === false ? !match : match
}

export const getObjectFieldValueByPath = (object: any, path: string): any => {
  return path.split('.').reduce((acc, curr) => (acc ? acc[curr] : undefined), object)
}
