import * as React from 'react'
import { Column } from './Column/'
import { StyledBody, FullHeightContainer } from '../../Grid/styles'
import { locationAPI, customerAPI, driverAPI } from '../../../../../../api/api'
import { getStore } from '../../../../../../store/configureStore'
import { GridItemSpinner } from '../../../../../UI/Spinner/Spinner'
import { ColumnSize, QMPTab, IRateFiltersState, RateType, NewRateDTO, IQMPFilters } from '../../../interfaces'
import { promiseFetchObjectsByIdAndPushToStore } from '../../functions'
import { getSellSideQuoteRates, getBuySideQuoteRates } from '../../../../../../services/DTO/rate/epics'
// @ts-ignore
import { debounce } from 'debounce'
import { tryAddDriverToStore } from '../../../../drivers/epics'
import { parseDTO } from '../../../../../../services/DTO/parseDTO'
import { useQMP } from '../../../../../../hooks/useQMP'
import { getListState } from '../../../../../../store'

type Props = {
  filters: IQMPFilters
  visited: boolean
  extraData: any
  ssqIds: string[]
  bsqIds: string[]
  newSSQRates: NewRateDTO[]
  newBSQRates: NewRateDTO[]
}

export const Rate = (props: Props) => {
  const { visited, extraData, filters, ssqIds, bsqIds, newSSQRates, newBSQRates } = props
  const mounted = React.useRef(false)
  const [columnSize, setColumnSize] = React.useState(ColumnSize.default)
  const { setRateIds } = useQMP()
  const [fetching, setFetching] = React.useState(false)

  const appliedFilters = React.useMemo(() => filters[QMPTab.RATE], [filters])

  const handleResizeClick = React.useCallback(
    (rateType: RateType) => {
      switch (columnSize) {
        case ColumnSize.default:
          return setColumnSize(rateType === RateType.ssq ? ColumnSize.wideSSQ : ColumnSize.wideBSQ)
        case ColumnSize.wideSSQ:
          return setColumnSize(rateType === RateType.ssq ? ColumnSize.default : ColumnSize.wideBSQ)
        case ColumnSize.wideBSQ:
          return setColumnSize(rateType === RateType.bsq ? ColumnSize.default : ColumnSize.wideSSQ)
        default:
      }
    },
    [columnSize]
  )

  React.useEffect(() => {
    const isFirstMountButNotFirstTabVisit = visited && !mounted.current
    mounted.current = true

    if (isFirstMountButNotFirstTabVisit) {
      return
    }

    setFetching(true)

    getRatesRequest(appliedFilters, (_props: { ssqIds: string[]; bsqIds: string[] }) => {
      setFetching(false)
      setRateIds(_props)
    })
  }, [appliedFilters])

  return (
    <StyledBody>
      <FullHeightContainer>
        {fetching && <GridItemSpinner />}
        <Column
          rateType={RateType.ssq}
          filters={filters}
          extraData={extraData}
          tabType={QMPTab.RATE}
          rateIds={ssqIds}
          newRates={newSSQRates}
          oppositeNewRates={newBSQRates}
          columnSize={columnSize}
          onResizeClick={handleResizeClick}
        />
        <Column
          rateType={RateType.bsq}
          filters={filters}
          extraData={extraData}
          tabType={QMPTab.RATE}
          rateIds={bsqIds}
          newRates={newBSQRates}
          oppositeNewRates={newSSQRates}
          columnSize={columnSize}
          onResizeClick={handleResizeClick}
        />
      </FullHeightContainer>
    </StyledBody>
  )
}

const getRatesRequest = debounce(
  (appliedFilters: IRateFiltersState, callback: (props: { ssqIds: string[]; bsqIds: string[] }) => void) => {
    const fetchLocationIds: string[] = [appliedFilters.pickupLocationId, appliedFilters.returnLocationId]
    const fetchCustomerIds: string[] = Array.isArray(appliedFilters.customerId)
      ? appliedFilters.customerId.map(item => item.value)
      : []
    const fetchVendorIds: string[] = Array.isArray(appliedFilters.vendorId)
      ? appliedFilters.vendorId.map(item => item.value)
      : []

    return Promise.all([getSellSideQuoteRates(appliedFilters), getBuySideQuoteRates(appliedFilters)])
      .then(async ([ssqList, bsqList]) => {
        const store = getStore().getState()

        ssqList.concat(bsqList as any).forEach((rate: any) => {
          fetchLocationIds.push(rate.pickupLocationId, rate.returnLocationId)
          fetchCustomerIds.push(rate.customerId)
          fetchVendorIds.push(rate.vendorId)
        })

        await Promise.all([
          promiseFetchObjectsByIdAndPushToStore(
            fetchLocationIds,
            locationAPI.findById,
            parseDTO.location,
            true,
            store.location
          ),
          promiseFetchObjectsByIdAndPushToStore(
            fetchCustomerIds,
            customerAPI.findById,
            parseDTO.customer,
            true,
            store.customer
          ),
          promiseFetchObjectsByIdAndPushToStore(
            fetchVendorIds,
            driverAPI.findById,
            tryAddDriverToStore,
            true,
            getListState().driver
          )
        ])

        callback({ ssqIds: ssqList.map(({ id }) => id), bsqIds: bsqList.map(({ id }) => id) })
      })
      .catch(() => {
        callback({ ssqIds: [], bsqIds: [] })
      })
  },
  1000
)
