import * as React from 'react'
import { StyledGeneral } from './styledComponents'
import { IDriver } from '../../../interfaces'
import PersonalInfo from './personalInfo/'
import Address from './address/'
import { TruckInfo } from './TruckInfo'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

type OwnProps = { driver: IDriver; actions: IGridItemActions }

export const General: React.SFC<OwnProps> = ({ driver, actions }) => {
  return (
    <StyledGeneral minRows={81} padding={{ top: 30, left: 20, right: 20 }}>
      <PersonalInfo driver={driver} actions={actions} />
      <Address driver={driver} actions={actions} />
      <TruckInfo driver={driver} actions={actions} />
    </StyledGeneral>
  )
}
