import { MaintenanceInspectionDTO } from '../../api/origin/business-logic'
import { v4 } from 'uuid'

export const newMaintenanceInspection = (): MaintenanceInspectionDTO => ({
  performedDate: null,
  expirationDate: null,
  document: null,
  id: 'fake_' + v4()
})

export const checkMaintenanceInspections = (maintenanceInspections: MaintenanceInspectionDTO[]): boolean =>
  maintenanceInspections ? maintenanceInspections.every(item => !!item.performedDate && !!item.expirationDate) : true
