import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { IDriver } from '../../../components/common/drivers/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { isEqualObjects } from '../saveDTO'
import { DriverViewDTO } from '../../../api/origin/business-logic'
import { getListState } from '../../../store'

const needAuthAction = (driver: IDriver, storeDriver: IDriver): boolean => {
  if (driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR) {
    return false
  }

  const propsToCheck: (keyof IDriver)[] = ['firstName', 'lastName', 'email', 'phone', 'mobileApp']

  return !driver || !storeDriver || propsToCheck.some(prop => storeDriver[prop] !== driver[prop])
}

export const omitDriverProps: (keyof IDriver)[] = ['powerUnit', 'parent', 'temporaryData']

export const getDriverStatus = (driver: IDriver): TObjectStatusForSave => {
  const storeDrivers = getListState().driver
  const storeDriver = storeDrivers[driver.id]
  const lightDriver = driver ? R.omit(driver, omitDriverProps) : driver
  // @ts-ignore
  const lightStoreDriver = storeDriver ? R.omit(storeDriver, omitDriverProps) : storeDriver

  return {
    noStoreDataForUpdate: compareUpdateDates(driver, storeDriver),
    needToSave: oc(driver).fullObject() && !isEqualObjects(lightDriver, lightStoreDriver),
    needAuthAction: needAuthAction(driver, storeDriver)
  }
}
