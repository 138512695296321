import * as React from 'react'
import General from '../Details/General'
import Contacts from '../Details/Contacts'
import { ILocation } from '../../interfaces'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Location

export const LocationPreview = {
  headings: [T.General, T.Contacts],
  tabs: {
    [T.General]: (location: ILocation, actions: IGridItemActions) => <General location={location} actions={actions} />,
    [T.Contacts]: (location: ILocation, actions: IGridItemActions, isModified: boolean) => (
      <Contacts location={location} actions={actions} isModified={isModified} />
    )
  },
  isValid: checkValidation.location
}
