import * as React from 'react'
import { toast } from 'react-toastify'
import * as activityActions from '../action'
import { ActivitiesViewDTO, TransportationActivityViewDTO } from '../../../../api/origin/business-logic'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { getStore } from '../../../../store/configureStore'
import { callAPI, troubleTicketDocumentAPI, transportationActivityAPI } from '../../../../api/api'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'

export enum TroubleTicketResolveAction {
  proceed = 'proceed',
  unsuccessful = 'unsuccessful'
}

export const tryAddActivitiesToStore = ({ documentationActivities, transportationActivities }: ActivitiesViewDTO) => {
  if (transportationActivities && transportationActivities.length) {
    correctInsertionObjectsIntoStorage(
      getStore().getState().activity.transportationActivities,
      transportationActivities,
      activityActions.addTransportationActivitiesToStore
    )
  }

  if (documentationActivities && documentationActivities.length) {
    correctInsertionObjectsIntoStorage(
      getStore().getState().activity.documentationActivities,
      documentationActivities,
      activityActions.addDocumentationActivitiesToStore
    )
  }
}

export const resolveTroubleTicketId = async (props: {
  action: TroubleTicketResolveAction
  troubleTicketId: string
  notes?: string
  handleError?: boolean
}) => {
  const { troubleTicketId, notes, handleError } = props
  let action = undefined

  switch (props.action) {
    case TroubleTicketResolveAction.proceed:
      action = troubleTicketDocumentAPI.proceed
      break
    case TroubleTicketResolveAction.unsuccessful:
      action = troubleTicketDocumentAPI.unsuccessful
      break
    default:
      return
  }

  return callAPI(action, troubleTicketId, { id: troubleTicketId, notes })
    .toPromise()
    .catch(async error => {
      let message = 'Resolve Trouble Ticket error: ' + troubleTicketId

      if (error && error.json) {
        const details = await error.json()
        message = details ? details.message || (typeof details === 'string' && details) || message : message
      }

      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>{message}</div>
          </div>
        </>
      )

      if (handleError) {
        throw message
      }
    })
}

export const requestTransporationActivitiesByIds = async (
  activityIds: string[]
): Promise<TransportationActivityViewDTO[]> => {
  if (!(activityIds && activityIds.length)) {
    return Promise.resolve([])
  }

  const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(activityIds)
  const requestedTransporationActivities: (TransportationActivityViewDTO)[] = []

  if (arrayOfRequestedIdsArrays.length) {
    await Promise.all(
      arrayOfRequestedIdsArrays.map(ids =>
        requestTransporationActivities({ filter: 'id%%' + ids.join(',') }, false).then(transporationActivities => {
          requestedTransporationActivities.push(...transporationActivities)
        })
      )
    )

    tryAddActivitiesToStore({ transportationActivities: requestedTransporationActivities })
  }

  return requestedTransporationActivities
}

export const requestTransporationActivities = (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<TransportationActivityViewDTO[]> => {
  return callAPI(transportationActivityAPI.findAll, filter, sort)
    .toPromise()
    .then(transportationActivities => {
      if (pushToStore) {
        tryAddActivitiesToStore({ transportationActivities })
      }

      return transportationActivities
    })
}
