import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { theme } from '../../styles/theme'
import { convertISODateToDateWithHoursMins, convertRangeISODateToDateWithHoursMins } from '../timeService/dateUtils'
import { getObjectFieldValueByPath } from '../websocket/actions'
import { allDirectoriesSearch } from './directory'
import { getStateInfo } from '../addressService'
import { processObject } from './functions'

type ConflictDetails = { fieldName: string; before: string; after: string }

type ConflictsOnObjectSaving = {
  title: string
  hideSaveAnywayButton: boolean
  details?: ConflictDetails[]
}

export const ConflictsOnSaving = (conflictsOnObjectsSaving: ConflictsOnObjectSaving[]) => (): JSX.Element => {
  if (!conflictsOnObjectsSaving || !conflictsOnObjectsSaving.length) {
    return null
  }

  const [expandedBlock, setExpandedBlock] = React.useState(
    conflictsOnObjectsSaving.length === 1 && oc(conflictsOnObjectsSaving[0]).details.length
      ? conflictsOnObjectsSaving[0].title
      : null
  )

  React.useEffect(() => {
    document.querySelectorAll('.conflict_block').forEach((_: any) => (_.style.height = ''))

    const block: any = document.querySelector('.conflict_block.active')

    if (block) {
      const container: any = document.querySelector('.conflict_block.active > div')

      if (container) {
        block.style.height = container.getBoundingClientRect().height + 'px'
      }
    }
  })

  return (
    <Container>
      {conflictsOnObjectsSaving.map((item, index) => {
        const isExpanded = item.title === expandedBlock
        const hasDetails = Boolean(item.details && item.details.length)
        const isCriticalChanges = item.hideSaveAnywayButton
        const extraClassName = isExpanded && hasDetails ? ' active' : ''

        return (
          <Block
            key={index}
            className={extraClassName}
            style={isCriticalChanges ? { background: 'rgba(255, 94, 94, 0.05)' } : undefined}
          >
            <TitleContainer
              className={(hasDetails ? '' : 'no-details') + extraClassName}
              onClick={hasDetails ? () => setExpandedBlock(isExpanded ? null : item.title) : undefined}
            >
              <Title style={isCriticalChanges ? { color: theme.colors.defaultRed } : undefined}>
                {item.title}
                {hasDetails && <Expand className={`mdi mdi-chevron-${isExpanded ? 'up' : 'down'}-circle`} />}
              </Title>
              {isCriticalChanges && (
                <div style={{ marginTop: 8 }}>You can not save changes. Critical changes have been made</div>
              )}
            </TitleContainer>
            {hasDetails && (
              <Details className={'conflict_block' + extraClassName}>
                <div>
                  {item.details.map((info, i) => (
                    <Detail key={i}>
                      <FieldName>{info.fieldName}</FieldName>
                      <CompareValues>
                        <Value className={cn('right', { empty: !info.before })}>
                          <div>{info.before || 'Empty'}</div>
                        </Value>
                        <Action className={'mdi mdi-arrow-right-thick'} />
                        <Value className={cn('left', { empty: !info.after })}>
                          <div>{info.after || 'Empty'}</div>
                        </Value>
                      </CompareValues>
                    </Detail>
                  ))}
                </div>
              </Details>
            )}
          </Block>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  width: 700px;
  margin: 0 -15px;
`
const Block = styled.div`
  &:nth-child(odd) {
    background-color: rgba(245, 246, 250, 0.5);
  }

  &.active {
    background-color: #f5f6fa;
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
`
const TitleContainer = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${theme.colors.header};
  padding: 15px;
  cursor: pointer;

  &:hover ${Title} {
    opacity: 0.8;
  }

  &.no-details {
    cursor: default !important;

    ${Title} {
      opacity: 1 !important;
    }
  }

  &.active ${Title} {
    color: ${theme.colors.basicBlueColor};
  }
`
const Expand = styled.div`
  margin-left: 8px;
`
const Details = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.15s;

  > div {
    padding: 0 0 10px 20px;
  }
`
const Detail = styled.div`
  width: 100%;
  display: flex;
`
const FieldName = styled.div`
  flex-shrink: 0;
  width: 200px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
`
const CompareValues = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`
const Value = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  white-space: pre-wrap;

  &.empty {
    color: ${theme.colors.defaultGray};
  }

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }
`
const Action = styled.div`
  font-size: 20px;
  padding: 0 15px;
`

const deepDiffMapper = (() => {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1: any, obj2: any) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw new Error('Invalid argument. Function given, object expected.')
      }

      const isDate = Boolean(oc(obj1).from() || oc(obj2).from())

      if (isDate) {
        if (
          oc(obj1).from() === oc(obj2).from() &&
          oc(obj1).to() === oc(obj2).to() &&
          oc(obj1).confirmed() === oc(obj2).confirmed()
        ) {
          return null
        }

        return {
          before: obj1,
          after: obj2
        }
      }

      if (this.isValue(obj1) || this.isValue(obj2)) {
        const type = this.compareValues(obj1, obj2)
        // const data = obj1 === undefined ? obj2 : obj1

        if (
          type === this.VALUE_UNCHANGED ||
          this.isObject(obj1) ||
          this.isObject(obj2) ||
          this.isArray(obj1) ||
          this.isArray(obj2)
        ) {
          return null
        }

        return {
          before: obj1 !== null && obj1 !== undefined ? String(obj1) : obj1,
          after: obj2 !== null && obj2 !== undefined ? String(obj2) : obj2
        }
      }

      const diff = {}

      Object.keys(obj1).forEach(key => {
        if (this.isFunction(obj1[key])) {
          return
        }

        let value2 = undefined
        if (obj2[key] !== undefined) {
          value2 = obj2[key]
        }

        diff[key] = this.map(obj1[key], value2)
      })

      Object.keys(obj2).forEach(key => {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          return
        }

        diff[key] = this.map(undefined, obj2[key])
      })

      return diff
    },
    compareValues(value1: any, value2: any) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED
      }
      return this.VALUE_UPDATED
    },
    isFunction(x: any) {
      return Object.prototype.toString.call(x) === '[object Function]'
    },
    isArray(x: any) {
      return Object.prototype.toString.call(x) === '[object Array]'
    },
    isDate(x: any) {
      return Object.prototype.toString.call(x) === '[object Date]'
    },
    isObject(x: any) {
      return Object.prototype.toString.call(x) === '[object Object]'
    },
    isValue(x: any) {
      return !this.isObject(x) && !this.isArray(x)
    }
  }
})()

export const getObjectsDifference = ({ oldObject, newObject }: { oldObject: object; newObject: object }) => {
  // console.log({
  //   deepDiffMapper: deepDiffMapper.map(oldObject, newObject),
  //   oldObject,
  //   newObject
  // })

  return processObject(deepDiffMapper.map(oldObject, newObject)).hardClean
}

export const prepareConflictList = (
  conflicts: object,
  storeObject: object,
  updatedObject: object,
  hideSaveAnywayButtonOnConflictedProps: string[] = []
): ConflictsOnObjectSaving[] => {
  if (!conflicts) {
    return []
  }

  const titles = Object.keys(conflicts)

  if (!titles.length) {
    return []
  }

  const getInnerProps = ({
    inheritFieldName,
    inheritPath,
    obj
  }: {
    inheritFieldName: string
    inheritPath: string
    obj: object
  }): ConflictDetails[] => {
    const keys = Object.keys(obj)

    return keys.reduce((acc, currKey) => {
      let value = obj[currKey]

      if (Object.prototype.toString.call(value) !== '[object Object]') {
        return acc
      }

      let fieldName = currKey + ''
      const path = (inheritPath ? inheritPath + '.' : '') + currKey

      switch (true) {
        case currKey.includes('DateTimeRange'):
          fieldName = fieldName.replace(/DateTimeRange/g, '')
          value = {
            before:
              convertRangeISODateToDateWithHoursMins(value.before, true) +
              (oc(value).before.confirmed() === true
                ? ' Confirmed'
                : oc(value).before.confirmed() === false
                ? ' Not Confirmed'
                : ''),
            after:
              convertRangeISODateToDateWithHoursMins(value.after, true) +
              (oc(value).after.confirmed() === true
                ? ' Confirmed'
                : oc(value).after.confirmed() === false
                ? ' Not Confirmed'
                : '')
          }
          break
        case currKey.includes('Date'):
          fieldName = fieldName.replace(/Date/g, '')
          value = {
            before: convertISODateToDateWithHoursMins(value.before),
            after: convertISODateToDateWithHoursMins(value.after)
          }
          break

        case currKey.includes('Id'):
          fieldName = fieldName.replace('Id', '')
          value = getRelatedInfo(path, storeObject, updatedObject, value) || value
          break
        default:
      }

      fieldName = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2')

      if (value.before || value.after) {
        acc.push({
          before: allDirectoriesSearch(value.before) || value.before,
          after: allDirectoriesSearch(value.after) || value.after,
          fieldName: inheritFieldName + fieldName
        })
      }

      acc.push(
        ...getInnerProps({
          inheritFieldName: `${fieldName}\n\n    `,
          inheritPath: path,
          obj: value
        })
      )

      return acc
    }, [])
  }

  return titles.map(title => {
    return {
      title,
      hideSaveAnywayButton: hideSaveAnywayButtonOnConflictedProps.includes(title),
      details:
        conflicts[title] === true
          ? undefined
          : getInnerProps({ inheritFieldName: '', inheritPath: '', obj: conflicts[title] })
    }
  })
}

const getRelatedInfo = (
  path: string,
  storeObject: object,
  updatedObject: object,
  value?: {
    before?: string
    after?: string
  }
): { before: string; after: string } | undefined => {
  const pathToObject = path.replace('Id', '')
  const storeValue: any = getObjectFieldValueByPath(storeObject, pathToObject)
  const updatedValue: any = getObjectFieldValueByPath(updatedObject, pathToObject)

  let before: string
  let after: string

  switch (true) {
    case path.includes('equipment'):
      before = oc(storeValue).chassisNumber('')
      after = oc(updatedValue).chassisNumber('')
      break
    case path.includes('hazmat'):
      before = storeValue ? oc(storeValue).code('') + ', ' + oc(storeValue).description('') : storeValue
      after = updatedValue ? oc(updatedValue).code('') + ', ' + oc(updatedValue).description('') : updatedValue
      break
    case path.includes('state'): {
      const stateBefore = value.before ? getStateInfo(value.before) : undefined
      const stateAfter = value.after ? getStateInfo(value.after) : undefined
      before = stateBefore ? `${stateBefore.code} – ${stateBefore.name}` : value.before
      after = stateAfter ? `${stateAfter.code} – ${stateAfter.name}` : value.after
      break
    }
    case path.includes('steamShipLine'):
      // @ts-ignore
      before = oc(storeObject).deliveryOrder.steamShipLine.name()
      // @ts-ignore
      after = oc(updatedObject).deliveryOrder.steamShipLine.name()
      break
    default:
      // @ts-ignore
      before = oc(storeValue).name('') || oc(storeValue).fullName('') || oc(storeValue).number('')
      // @ts-ignore
      after = oc(updatedValue).name('') || oc(updatedValue).fullName('') || oc(updatedValue).number('')
  }

  return before || after ? { before, after } : undefined
}
