import { IContainer } from '../../../components/common/containers/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import * as R from 'remeda'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { oc } from 'ts-optchain'

export const omitContainerFields: (keyof IContainer)[] = ['containerType', 'steamShipLine']

export const getContainerStatus = (container: IContainer): TObjectStatusForSave => {
  const store = getStore().getState()
  const storeContainer = store.container[container.id]

  const getLightObjects = () => {
    return {
      checkObject: omitUpdateInfo(R.omit(container, omitContainerFields)),
      storeObject: omitUpdateInfo(storeContainer ? R.omit(storeContainer, omitContainerFields) : {})
    }
  }

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(container, storeContainer)
    },
    get needToSave() {
      const { checkObject, storeObject } = getLightObjects()
      return oc(checkObject).fullObject() && !isEqualObjects(storeObject, checkObject)
    },
    get getDifference() {
      const { checkObject, storeObject } = getLightObjects()
      return getObjectsDifference({
        oldObject: checkObject,
        newObject: storeObject
      })
    }
  }
}
