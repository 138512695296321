import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import * as R from 'remeda'
import { getStore } from '../../../store/configureStore'
import {
  ActivitiesViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { isNewObject } from '../index'
import { getActivityStatus, omitActivityFields } from '../activity/status'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../api/api'
import { showModalWindow } from '../../../store/reducers/modalWindow/functions'
import { getLocationSavePromise } from './save'
import { makeSavePromiseWithCatch } from '../saveDTO'
import { getFullDispatchDeliveryOrderStatusSavePromise } from '../dispatchDeliveryOrder/save'
import { assembleDTO } from '../assemble'
import { ActivityViewType } from '../activity/interfaces'

const getObjectPromise = (object: any, promiseAction: any) =>
  object && object.fullObject ? promiseAction(object) : Promise.resolve(object)

export const getActivitiesPromises = async (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IModifyGridItemActions
) => {
  const store = getStore().getState()
  const updatedActivities = dispatchDeliveryOrder.activities.transportationActivities.concat(dispatchDeliveryOrder
    .activities.documentationActivities as any[]) as any[]

  const batchDocumentationActivities: DocumentationActivityDTO[] = []
  const batchTransportationActivities: TransportationActivityViewDTO[] = []

  updatedActivities.forEach(activity => {
    const activityArray =
      activity.type === DocumentationActivityDTO.TypeEnum.STREETTURN
        ? batchDocumentationActivities
        : batchTransportationActivities

    if (isNewObject(activity)) {
      activityArray.push(R.omit(activity, ['id']) as any)
      return
    }

    if (getActivityStatus(activity).needToSave) {
      activityArray.push(activity as any)
      return
    }
  })

  // >>> Remove activities Promise
  const removeDeletedActivitiesPromise = (): Promise<any> => {
    const activitiesIds = updatedActivities.map(_ => _.id)
    let removeActivities: ActivitiesViewDTO = {
      documentationActivities: [],
      transportationActivities: dispatchDeliveryOrder.activityIds
        .filter(id => !activitiesIds.includes(id))
        .map(
          id =>
            assembleDTO.activity({ store, id, type: ActivityViewType.transportation }) as TransportationActivityViewDTO
        )
        .filter(Boolean)
    }

    if (!removeActivities.documentationActivities.length) {
      removeActivities.documentationActivities = undefined
    }
    if (!removeActivities.transportationActivities.length) {
      removeActivities.transportationActivities = undefined
    }
    if (!removeActivities.documentationActivities && !removeActivities.transportationActivities) {
      removeActivities = undefined
    }

    return removeActivities
      ? callAPI(dispatchDeliveryOrderAPI.deleteActivities, removeActivities, dispatchDeliveryOrder.id).toPromise()
      : Promise.resolve()
  }
  // <<<

  const handleSimultaneousSavingOfActivities = async (error: any) => {
    if (error && error.status === 409) {
      showModalWindow({
        title: 'There is newer data for the object',
        buttons: [
          {
            label: 'Save anyway',
            onClick: () => {
              actions.setFetching(true)
              return makeSavePromiseWithCatch(
                actions,
                getFullDispatchDeliveryOrderStatusSavePromise(dispatchDeliveryOrder, actions)
              )
            }
          },
          {
            label: 'Discard my changes',
            onClick: actions.cancel
          },
          {
            label: 'Cancel'
          }
        ]
      })

      return Promise.reject({ message: '' })
    }

    return Promise.reject(error)
  }

  return (
    removeDeletedActivitiesPromise()
      .catch(handleSimultaneousSavingOfActivities)
      // Omit activity props here
      .then(async () => {
        let updateActivities: ActivitiesViewDTO = {
          documentationActivities: batchDocumentationActivities,
          transportationActivities: []
        }

        for (const transportationActivity of batchTransportationActivities) {
          if (transportationActivity.template || !transportationActivity.destination) {
            updateActivities.transportationActivities.push(R.omit(
              transportationActivity,
              omitActivityFields
            ) as TransportationActivityViewDTO)
            continue
          }

          updateActivities.transportationActivities.push(R.omit(
            transportationActivity,
            omitActivityFields
          ) as TransportationActivityViewDTO)
        }

        if (!updateActivities.documentationActivities.length) {
          updateActivities.documentationActivities = undefined
        }
        if (!updateActivities.transportationActivities.length) {
          updateActivities.transportationActivities = undefined
        }
        if (!updateActivities.documentationActivities && !updateActivities.transportationActivities) {
          updateActivities = undefined
        }

        return updateActivities
          ? callAPI(dispatchDeliveryOrderAPI.updateActivities, updateActivities, dispatchDeliveryOrder.id)
              .toPromise()
              .catch(handleSimultaneousSavingOfActivities)
          : Promise.resolve()
      })
  )
}
