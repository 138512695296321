import * as React from 'react'
import styled from 'styled-components'
import { BasicTableHeader } from './interfaces'

export const Header = ({ columns, styles }: BasicTableHeader) => (
  <StyledHeader style={styles}>
    {columns.map(columnProps => {
      if (!columnProps.visible) {
        return null
      }
      return (
        <StyledColumn key={columnProps.key} style={columnProps.styles}>
          {columnProps.value}
        </StyledColumn>
      )
    })}
  </StyledHeader>
)

const StyledHeader = styled.div`
  z-index: 1;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  display: flex;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #6c707d;
  background-color: #fff;
  font-size: 10px;
  padding: 4px;
`
const StyledColumn = styled.div`
  line-height: 20px;
  padding-left: 8px;

  &:not(:first-child) {
    border-left: 1px solid #e5e5e5;
  }
`
