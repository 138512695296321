import { combineLocations } from '../../../../../../services/DTO/location/functions'
import { newLocation } from '../../../../../common/location/reducers'
import { SearchRequest } from '../../searchRequest'
import * as React from 'react'
import { TSearchField } from '../../index'
import { locationControllerApi } from '../../../../../../api/location'
import { LocationViewDTO } from '../../../../../../api/origin/business-logic'

export const LocationSearch = (props: TSearchField & { combineWithLocation?: LocationViewDTO }) => {
  const getList = (term: string): Promise<any[]> => {
    if (term) {
      return locationControllerApi
        .searchLocation(term)
        .then((items: any) => (items ? items.map((_: any) => ({ ..._, googleResult: true })) : []))
        .catch(() => [{ create: true, description: 'Not found' }])
    } else {
      return Promise.resolve([])
    }
  }
  const getDetails = (location: any): Promise<any> => {
    return locationControllerApi
      .locationByPlaceId(location.placeId)
      .then(data => combineLocations(props.combineWithLocation || newLocation(), data))
  }

  return (
    <SearchRequest
      {...props}
      requestByEnterPress={true}
      filtering={false}
      label={props.value ? props.value.longName || props.value.name : null}
      getList={getList}
      getDetails={getDetails}
      makeDropDownItem={response => ({
        disabled: response.create,
        className: 'create ' + (response.googleResult ? 'google' : ''),
        before: '',
        label: response.description,
        value: response
      })}
    />
  )
}
