import { ICustomerView } from '../interfaces'
import { oc } from 'ts-optchain'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { createAddressLine } from '../../../../services/addressService'
import { getPrimaryContactNameFormContacts } from '../../../../services/DTO/contact'
import { customerDirectory } from '../../../../services/DTO/customer/directory'
import { dateService } from '../../../../services/timeService'

export const headers = {
  'Date Created': { width: 120, active: true },
  Name: { width: 160, active: true },
  Address: { width: 260, active: true },
  'Sales Rep': { width: 260, active: true },
  'Net Terms Unit': { width: 120, active: true },
  'Net Terms': { width: 100, active: true },
  'Primary Contact': { width: 160, active: true }
}

export const adaptRowValues = (unit: ICustomerView): TColumns => {
  const columnValues = {
    'Date Created': dateService.makeLabel(oc(unit).createdAt()),
    Name: oc(unit).businessPartner.legalName(''),
    Address: createAddressLine(oc(unit).businessPartner.legalAddress()),
    'Sales Rep': oc(unit).salesRepUserId(),
    'Net Terms Unit': unit.netTermsUnit ? customerDirectory.netTermsUnit[unit.netTermsUnit] : '',
    'Net Terms': unit.netTermsUnit ? customerDirectory.netTerms[unit.netTerms] : '',
    'Primary Contact': getPrimaryContactNameFormContacts(oc(unit).businessPartner.contacts([]))
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
