import * as React from 'react'
import { StyledGeneral } from './styledComponents'
import { IContainer } from '../../../interfaces'
import { Container, GridColumn } from '../../../../../UI/GridSystem'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { ContainerViewDTO } from '../../../../../../api/origin/business-logic'
import { connect } from 'react-redux'
import { Select } from '../../../../../UI/DataFields/Select'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { SteamShipLineSearch } from '../../../../../UI/DataFields/SearchRequest'
import { ISteamShipLine } from '../../../../steamShipLine/interfaces'
import { oc } from 'ts-optchain'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { schemaContainerNumber } from '../../../../../../services/yupScheme'
import { containerLists } from '../../../../../../services/select/containerLists'
import { commonLists } from '../../../../../../services/select/commonLists'

type OwnProps = { container: IContainer; actions: IGridItemActions }

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const General: React.SFC<Props> = ({ container, actions: { modify, modifyParentObjectField } }) => (
  <StyledGeneral minRows={23}>
    <GridColumn flexBasis={'auto'} padding={{ top: 25, left: 30, right: 30 }}>
      <Container columns={10} margin={{ right: 40 }}>
        <InputField.TextMask
          title={'Container #'}
          valid={schemaContainerNumber.isValidSync(container.number)}
          required={true}
          transformOnInputChange={value => (value ? value.toUpperCase() : value)}
          value={container.number}
          textMask={[
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/
          ]}
          onChange={modifyParentObjectField('number')}
        />
      </Container>
      <Container margin={{ right: 40 }}>
        <FieldTemplate.ContainerTypeSearch
          title={'Type'}
          required={true}
          containerType={oc(container).containerType(null)}
          onChange={data =>
            modify({ ...container, containerTypeId: data ? data.id : null, containerType: data || null })
          }
        />
      </Container>
      <Container columns={10} margin={{ right: 40 }}>
        <Input title={'Specs'} maxLength={1024} value={container.specs} onChange={modifyParentObjectField('specs')} />
      </Container>
      <Container columns={4} isFixed={true} margin={{ right: 20 }}>
        <InputField.ThousandSeparator
          title={'MCW'}
          placeholder={'00000'}
          value={container.weight}
          onChange={weight => modifyParentObjectField('weight')(weight ? Number(weight) : null)}
        />
      </Container>
      <Container columns={4} isFixed={true} margin={{ right: 40 }}>
        <Select
          title={'Unit'}
          selectedValue={container.weightUnit}
          list={commonLists.weightUnit}
          onSelect={modifyParentObjectField('weightUnit')}
        />
      </Container>
      <Container columns={10} margin={{ right: 40 }}>
        <Select
          title={'Ownership'}
          required={true}
          selectedValue={container.ownership}
          list={containerLists.ownership}
          onSelect={(ownership: ContainerViewDTO.OwnershipEnum) =>
            ownership === ContainerViewDTO.OwnershipEnum.SHIPPEROWNED
              ? modifyParentObjectField('ownership')(ownership)
              : modify({
                  ...container,
                  ownership,
                  steamShipLineId: null,
                  steamShipLine: null
                })
          }
        />
      </Container>
      <Container columns={10}>
        {container.ownership === ContainerViewDTO.OwnershipEnum.LINEOWNED && (
          <SteamShipLineSearch
            title={'SSL'}
            required={true}
            value={oc(container).steamShipLine(null)}
            onChange={(ssl: ISteamShipLine) =>
              modify({ ...container, steamShipLineId: ssl ? ssl.id : null, steamShipLine: ssl || null })
            }
          />
        )}
      </Container>
    </GridColumn>
  </StyledGeneral>
)

export default connect(
  null,
  null
)(General)
