import * as React from 'react'
import { RateTable } from '../common/body'
import styled from 'styled-components'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../types'
import { SellSideQuoteSurchargeDTO } from '../../../../../../../../api/origin/business-logic'
import { connect } from 'react-redux'
import { IStore } from '../../../../../../../../store/store.interface'
import { makeCombinedSurchargeRate } from '../functions'
import { RateDTO } from '../../../../../../../../services/DTO/rate/interfaces'

type OwnProps = {
  surcharges: SurchargeDTO[]
  updateSurchargeQuantity: (surchargeId: string, quantity: number) => void
  openRateNumber: (rateNumber: string) => void
}

type StateProps = {
  surchargesFullData?: CombinedSurchargeRateDTO[]
}

type Props = OwnProps & StateProps

export const MainRatesBlock = connect((store: IStore, props: OwnProps) => {
  const surchargesFullData = props.surcharges.map(surcharge => {
    const rate = store.ssqRates[surcharge.rateId] || store.bsqRates[surcharge.rateId]

    return rate ? makeCombinedSurchargeRate(rate as RateDTO, surcharge) : (surcharge as CombinedSurchargeRateDTO)
  })
  return { surchargesFullData }
})((props: Props) => {
  const { surchargesFullData, updateSurchargeQuantity, openRateNumber } = props

  if (!(surchargesFullData || []).length) {
    return null
  }

  const orderedSurcharges = (surchargesFullData || []).reduce((acc, surcharge) => {
    // sort and add
    switch (surcharge.type) {
      case SellSideQuoteSurchargeDTO.TypeEnum.ALLIN:
      case SellSideQuoteSurchargeDTO.TypeEnum.BASE: {
        acc.unshift(surcharge)
        break
      }
      case SellSideQuoteSurchargeDTO.TypeEnum.FUEL: {
        acc.splice(acc.length ? 1 : 0, 0, surcharge)
        break
      }
      default: {
        acc.push(surcharge)
        break
      }
    }
    return acc
  }, [])

  let calculateBasePlusFuel = false

  if (
    [SellSideQuoteSurchargeDTO.TypeEnum.BASE, SellSideQuoteSurchargeDTO.TypeEnum.FUEL].every(type =>
      orderedSurcharges.find(_ => _.type === type)
    )
  ) {
    calculateBasePlusFuel = true
  }

  return (
    <StyledCustomerQuoteBlock
      style={
        surchargesFullData.length
          ? { height: 30 + 16 + 50 * surchargesFullData.length + (calculateBasePlusFuel ? 46 : 0) }
          : undefined
      }
    >
      {Boolean(orderedSurcharges.length) && (
        <RateTable
          calculateBasePlusFuel={calculateBasePlusFuel}
          rates={orderedSurcharges}
          toggleRate={undefined}
          updateSurchargeActivityId={undefined}
          updateSurchargeQuantity={updateSurchargeQuantity}
          openRateNumber={openRateNumber}
        />
      )}
    </StyledCustomerQuoteBlock>
  )
})

const StyledCustomerQuoteBlock = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`
