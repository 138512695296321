import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { createWorkOrder } from '.'
import {
  ActivitiesDTO,
  callAPI,
  dispatchDeliveryOrderAPI,
  transportationActivityAPI,
  TransportationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { getStore } from '../../../store/configureStore'
import { tryAddActivitiesToStore } from '../../common/activity/epics'
import { requestDispatchDeliveryOrdersByIds } from '../../common/dispatchDeliveryOrder/epics'
import moveServiceAPI, { handleMoveServiceError } from './moveServiceAxios'
import { dateService } from '../../../services/timeService'
import { IMove, IMoveDTO, IMoveItem, MoveConnectionType } from '../../../store/reducers/communicationHub/interfaces'

// TODO catch errors
export const getVendorIdMoves = async ({ date, vendorId }: { date: string; vendorId: string }): Promise<IMove[]> => {
  return callAPI(() =>
    moveServiceAPI.get(`/route-builder-move/`, { params: { date: dateService.getStringDay(date), vendorId } })
  )
    .toPromise()
    .then(async data => {
      if (data && typeof data.data === 'object') {
        const moves = data.data as IMoveDTO[]
        const dispatchDeliveryOrderMapping = getStore().getState().dispatchDeliveryOrder
        const dispatchDeliveryOrderIds: string[] = R.uniq(
          moves.map(({ dispatchDeliveryOrderId }) => dispatchDeliveryOrderId)
        ).filter(dispatchDeliveryOrderId => {
          const dispatchDeliveryOrder = dispatchDeliveryOrderMapping[dispatchDeliveryOrderId]
          return !(dispatchDeliveryOrder && dispatchDeliveryOrder.fullObject)
        })

        if (dispatchDeliveryOrderIds.length) {
          await requestDispatchDeliveryOrdersByIds(dispatchDeliveryOrderIds)
        }

        return moves
      } else {
        return []
      }
    })
    .catch(handleMoveServiceError([]))
}
export const setMovePlannedDate = ({
  moveId,
  plannedDate
}: {
  plannedDate: string
  moveId: string
}): Promise<IMove> => {
  return callAPI(() =>
    moveServiceAPI.put(`/route-builder-move/${moveId}/planned-date?plannedDate=${plannedDate}`).then(data => {
      if (data && typeof data.data === 'object') {
        return data.data
      } else {
        return null
      }
    })
  ).toPromise()
}

export const setMoveItemStatus = async ({
  moveItem,
  newStatus,
  vendorId
}: {
  moveItem: IMoveItem
  newStatus: TransportationActivityViewDTO.StatusEnum
  vendorId: string
}): Promise<boolean> => {
  try {
    const { gotoActivity, businessActivity } = moveItem

    if (!gotoActivity || !businessActivity) {
      return false
    }

    const activityIds = [gotoActivity.id, businessActivity.id]
    const activitiesToUpdate: TransportationActivityViewDTO[] = await callAPI(
      transportationActivityAPI.findAll,
      `id%%${activityIds.join(',')}`
    )
      .toPromise()
      .catch(() => [])

    if (activitiesToUpdate.length) {
      const activities: ActivitiesDTO = {
        documentationActivities: [],
        transportationActivities: activitiesToUpdate.map(activity => ({ ...activity, status: newStatus, vendorId }))
      }

      return callAPI(
        dispatchDeliveryOrderAPI.updateActivities,
        activities,
        activitiesToUpdate[0].dispatchDeliveryOrderId
      )
        .toPromise()
        .then((_activities: ActivitiesDTO) => {
          tryAddActivitiesToStore(_activities)
          return true
        })
        .catch(() => false)
    }

    return false
  } catch (e) {
    return false
  }
}

export const setMovesActivitiesStatus = async ({
  vendorId,
  authUserId,
  moves,
  newStatus,
  searchStatus
}: {
  vendorId: string
  authUserId: string
  moves: IMove[]
  searchStatus: TransportationActivityViewDTO.StatusEnum
  newStatus: TransportationActivityViewDTO.StatusEnum
}): Promise<boolean> => {
  try {
    const activityIdsToUpdate: string[] = []
    const dispatchDeliveryOrderIds: string[] = R.uniq(
      moves.map(({ dispatchDeliveryOrderId }) => dispatchDeliveryOrderId)
    )

    moves.forEach(move => {
      move.items.forEach(({ gotoActivity, businessActivity }) => {
        if (oc(gotoActivity).status() === searchStatus) {
          activityIdsToUpdate.push(gotoActivity.id)
        }
        if (oc(businessActivity).status() === searchStatus) {
          activityIdsToUpdate.push(businessActivity.id)
        }
      })
    })

    if (activityIdsToUpdate.length) {
      const driverPlannedActivities: TransportationActivityViewDTO[] = await callAPI(
        transportationActivityAPI.findAll,
        `id%%${activityIdsToUpdate.join(',')};status%%${searchStatus}`
      )
        .toPromise()
        .catch(() => [])

      if (driverPlannedActivities.length) {
        const activitiesByDDOId: { [ddoId: string]: TransportationActivityViewDTO[] } = {}

        driverPlannedActivities.forEach(activity => {
          if (!activitiesByDDOId[activity.dispatchDeliveryOrderId]) {
            activitiesByDDOId[activity.dispatchDeliveryOrderId] = []
          }

          activitiesByDDOId[activity.dispatchDeliveryOrderId].push(activity)
        })

        await Promise.all(
          Object.keys(activitiesByDDOId).map(ddoId => {
            const activities: ActivitiesDTO = {
              documentationActivities: [],
              transportationActivities: activitiesByDDOId[ddoId].map(activity => ({ ...activity, status: newStatus }))
            }
            return callAPI(dispatchDeliveryOrderAPI.updateActivities, activities, ddoId)
              .toPromise()
              .then((_activities: ActivitiesDTO) => {
                tryAddActivitiesToStore(_activities)
              })
          })
        )

        if (newStatus === TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED) {
          return Promise.all(
            dispatchDeliveryOrderIds.map(ddoId =>
              createWorkOrder({
                ddoId,
                drivers: [{ vendorId, authUserId }]
              })
            )
          ).then(() => true)
        } else {
          return true
        }
      }
    }

    return false
  } catch (e) {
    return false
  }
}

// TODO catch errors
export const updateMoveSequence = async (moves: IMoveDTO[]): Promise<IMove[]> => {
  return callAPI(() =>
    moveServiceAPI.put(`/route-builder-move/sequence`, moves.map(({ id, sequenceNumber }) => ({ id, sequenceNumber })))
  )
    .toPromise()
    .then(data => {
      if (data && typeof data.data === 'object') {
        return data.data
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError([]))
}

export const deleteMove = async (move: IMove): Promise<boolean> => {
  const activities: ActivitiesDTO = {
    documentationActivities: [],
    transportationActivities: []
  }

  const activityIds: string[] = []

  move.items.forEach(({ gotoActivity, businessActivity }) => {
    if (gotoActivity) {
      activityIds.push(gotoActivity.id)
    }
    if (businessActivity) {
      activityIds.push(businessActivity.id)
    }
  })

  const transportationActivities = await callAPI(transportationActivityAPI.findAll, `id%%${activityIds.join(',')}`)
    .toPromise()
    .catch(() => [])

  if (!transportationActivities.length) {
    return false
  }

  activities.transportationActivities = transportationActivities.map(activity => ({
    ...activity,
    vendorId: undefined,
    status: TransportationActivityDTO.StatusEnum.NEW
  }))

  return callAPI(dispatchDeliveryOrderAPI.updateActivities, activities, move.dispatchDeliveryOrderId)
    .toPromise()
    .then(() => true)
    .catch(() => false)
}

// TODO catch errors
export const setDoubleMove = async (moveIds: string[]): Promise<IMove[]> => {
  return callAPI(() =>
    moveServiceAPI.put(`/double-move/set`, { moveIds, connectionType: MoveConnectionType.DOUBLEMOVE })
  )
    .toPromise()
    .then(data => {
      if (data && typeof data.data === 'object') {
        return data.data
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError())
}
// TODO catch errors
export const setBobtailDoubleMove = async (moveIds: string[]): Promise<IMove[]> => {
  return callAPI(() => moveServiceAPI.put(`/double-move/set`, { moveIds, connectionType: MoveConnectionType.BOBTAIL }))
    .toPromise()
    .then(data => {
      if (data && typeof data.data === 'object') {
        return data.data
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError())
}
// TODO catch errors
export const unsetDoubleMove = async (moveIds: string[]): Promise<IMove[]> => {
  return callAPI(() => moveServiceAPI.put(`/double-move/unset`, moveIds))
    .toPromise()
    .then(data => {
      if (data && typeof data.data === 'object') {
        return data.data
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError())
}

export const requestMoveDateByActivityId = (activityId: string): Promise<string> => {
  return callAPI(() => moveServiceAPI.get(`/route-builder-move/activity/${activityId}/move-date`))
    .toPromise()
    .then(data => {
      if (data && data.data && data.data.value) {
        return data.data.value
      } else {
        return null
      }
    })
    .catch(handleMoveServiceError())
}
