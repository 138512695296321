import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { ContainerTypeDTO, EquipmentDTO } from '../../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { Window } from './preview'
import { Popover } from './popover'
import { IEquipment } from '../../../../common/equipment/interfaces'
import { IContainer } from '../../../../common/containers/interfaces'
import { DDOContext } from '../../../../../services/DTO/dispatchDeliveryOrder/context'

type OwnProps = {
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDispatchDeliveryOrder: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateContainerType: (value?: ContainerTypeDTO) => void
  updateContainer: (container: IContainer) => void
  updateEquipment: (equipment: IEquipment) => void
  isModifiedMode?: boolean
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const EquipmentWidget: React.SFC<Props> = ({
  disabled,
  isModifiedMode,
  dispatchDeliveryOrder,
  updateContainerType,
  updateDispatchDeliveryOrder,
  updateContainer,
  updateEquipment
}) => {
  const {
    state: {
      isImport,
      required: { chassisNumber }
    }
  } = React.useContext(DDOContext)
  const container = dispatchDeliveryOrder.container
  const equipment = dispatchDeliveryOrder.equipment

  const equipmentProps = {
    container,
    equipment: equipment as EquipmentDTO,
    steamShipLineId: oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId(null),
    size: dispatchDeliveryOrder.containerType,
    containerNumber: oc(container).number(''),
    chassis: oc(equipment).chassisNumber(''),
    days: oc(equipment).days(null),
    pickupDate: oc(equipment).pickupDate(null),
    returnDate: oc(equipment).returnDate(null)
  }

  return (
    <Widget
      title={'Equipment'}
      highlighted={
        (isImport
          ? !oc(dispatchDeliveryOrder).containerTypeId() || !oc(dispatchDeliveryOrder).containerId()
          : !oc(dispatchDeliveryOrder).containerTypeId()) ||
        (chassisNumber && !equipment)
      }
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{ equipmentProps, dispatchDeliveryOrder }}
      detailsProps={{
        disabled,
        isModifiedMode,
        equipmentProps,
        updateContainerType,
        dispatchDeliveryOrder,
        updateDispatchDeliveryOrder,
        updateEquipment,
        updateContainer,
        isChassisNumberRequired: chassisNumber
      }}
    />
  )
}

export default connect(
  null,
  null
)(EquipmentWidget)
