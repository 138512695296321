import { ISteamShipLine } from '../../../components/common/steamShipLine/interfaces'
import { schemaSteamShipLineView } from '../../yupScheme'
import { checkContactsValidation } from '../contact'
import { debuggingMode } from '../../debug'

export const checkSteamShipLineValidation = (steamshipLine: ISteamShipLine): boolean => {
  if (debuggingMode.common) {
    schemaSteamShipLineView.validate(steamshipLine).catch(e =>
      //   getStore().dispatch(
      //     addServerMessage({
      //       type: 'error',
      //       message: e.message
      //     })
      //   )
      // tslint:disable-next-line:no-console
      console.log('checkSteamShipLineValidation', e)
    )
  }
  return (
    schemaSteamShipLineView.isValidSync(steamshipLine) &&
    checkContactsValidation(steamshipLine.businessPartner.contacts)
  )
}
