import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import styled from 'styled-components'
import { AdditionalRowActions, ColorRowLine, StyledCell, TableRowWithActions } from '../../../../Grid/styles'
import { columns, columnsSettings, isAllowCopyRate } from '../settings'
import { RowActions } from './RowActions'
import { quoteDirectory } from '../../../../../../../../services/DTO/sellSideQuote/directory'
import { deliveryOrderDirectory } from '../../../../../../../../services/DTO/deliveryOrder/directory'
import {
  BuySideQuoteRateDTO,
  RuleSearchDTO,
  SellSideQuoteRateDTO
} from '../../../../../../../../api/origin/qmp-service'
import { getStore } from '../../../../../../../../store/configureStore'
import { AnyRateDTO, ColumnSize, NewRateDTO, RateColumn, RateType, RateState } from '../../../../../interfaces'
import { RateWarning } from './RateWarning'
import { ColumnValueWithPopover } from '../../../../../../../UI/Column'
import { showWarningModalWindow } from '../../../../../../../../store/reducers/modalWindow/functions'
import { buySideQuoteRateAPI, callAPI, sellSideQuoteRateAPI } from '../../../../../../../../api/api'
import { tryAddBSQRatesToStore, tryAddSSQRatesToStore } from '../../../../../../../../services/DTO/rate/epics'
import { dateService } from '../../../../../../../../services/timeService'
import { RateDTO } from '../../../../../../../../services/DTO/rate/interfaces'
import { connect } from 'react-redux'
import { IStore } from '../../../../../../../../store/store.interface'
import { getListState } from '../../../../../../../../store'
import { transformMoney } from '../../../../../../../UI/Input'
import { generateExpireRateText } from '../../../../../../../../services/functions/generate/generateExpireRateText'
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector'
import { selectRules } from '../../../../../../../../store/select/ruleSelect'

type OwnProps = {
  columnSize: ColumnSize
  readOnly: boolean
  checkMark?: JSX.Element
  hideActions?: boolean
  rateType: RateType
  newRate?: AnyRateDTO
  rateId?: string
  onDelete?: () => void
  onEdit?: () => void
  onSaveAnyway?: () => void
  duplicateRate?: (rate: RateDTO) => void
  copyRate: (rate: RateDTO) => void
  className?: string
}

type StateProps = {
  rate: AnyRateDTO
}

type Props = OwnProps & StateProps

const Component = ({
  columnSize,
  readOnly,
  checkMark,
  hideActions,
  rateType,
  rate,
  rateId,
  onDelete,
  onEdit,
  onSaveAnyway,
  duplicateRate,
  copyRate,
  className
}: Props) => {
  const timer = React.useRef(null)
  const rowRef = React.useRef(null)
  const rules = useAppSelector(selectRules)
  const { state } = rate as NewRateDTO
  const [showWarning, setShowWarning] = React.useState(false)
  const hasWarning = state === RateState.warning

  const clearTimer = React.useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = undefined
    }
  }, [])

  const { onMouseLeave, onMouseEnter } = React.useMemo(
    () =>
      hasWarning
        ? {
            onMouseLeave() {
              clearTimer()
              timer.current = setTimeout(() => (rowRef && rowRef.current ? setShowWarning(false) : undefined), 500)
            },
            onMouseEnter() {
              clearTimer()
              setShowWarning(true)
            }
          }
        : {},
    [setShowWarning, clearTimer, hasWarning]
  )

  const canBeExpired = Boolean(oc(rate as any).status() === SellSideQuoteRateDTO.StatusEnum.NEW)

  const RenderActions = React.useCallback(() => {
    // @ts-ignore
    const onCopyToAnotherRateList = copyRate && isAllowCopyRate(rateType, rate.type) ? () => copyRate(rate) : undefined
    const onDuplicate = duplicateRate ? () => duplicateRate(rate as RateDTO) : undefined

    switch (state) {
      case RateState.new:
        return (
          <AdditionalRowActions>
            <span className={'mdi mdi-pencil'} onClick={onEdit} />
            <span className={'mdi mdi-minus-circle'} onClick={onDelete} />
          </AdditionalRowActions>
        )
      case RateState.edit:
        return null
      case RateState.warning:
        return null
      default:
        const enableActions = Boolean(
          (columnSize === ColumnSize.default ||
            (columnSize === ColumnSize.wideSSQ && rateType === RateType.ssq) ||
            (columnSize === ColumnSize.wideBSQ && rateType === RateType.bsq)) &&
            (onCopyToAnotherRateList || onDuplicate || canBeExpired)
        )

        return enableActions ? (
          <RowActions
            rateId={rate.id}
            rateNumber={rate.number}
            rateType={rateType}
            onCopyToAnotherRateList={onCopyToAnotherRateList}
            onDuplicate={onDuplicate}
            expire={
              canBeExpired
                ? () => {
                    showWarningModalWindow({
                      width: 360,
                      title: 'Expire Rate?',
                      content: generateExpireRateData(rate),
                      closeButton: true,
                      buttons: [
                        { label: 'No', outline: true },
                        {
                          label: 'Yes',
                          onClick: () => {
                            let action: any = undefined
                            let addToStoreAction: any = () => {}

                            if (rateType === RateType.ssq) {
                              action = sellSideQuoteRateAPI.expireSellSideQuote
                              addToStoreAction = tryAddSSQRatesToStore
                            }
                            if (rateType === RateType.bsq) {
                              action = buySideQuoteRateAPI.expireBuySideQuote
                              addToStoreAction = tryAddBSQRatesToStore
                            }

                            if (action) {
                              callAPI(action, rate.id)
                                .toPromise()
                                .then((_rate: any) => {
                                  addToStoreAction(_rate)
                                })
                            }
                          }
                        }
                      ]
                    })
                  }
                : undefined
            }
          />
        ) : null
    }
  }, [
    rateId,
    state,
    columnSize,
    copyRate,
    duplicateRate,
    canBeExpired,
    rate.id,
    rate.number,
    rate.type,
    rateType,
    onEdit,
    onDelete
  ])

  return (
    <>
      <TableRowWithActions
        id={[
          rate.number,
          rateType,
          ((): string => {
            switch (state) {
              case RateState.new:
                return 'row-new'
              case RateState.warning:
                return 'warning-row'
              default:
                return ''
            }
          })(),
          rate.id
        ]
          .filter(Boolean)
          .join('-')}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={rowRef}
        className={cn({ [className]: Boolean(className), [state]: Boolean(state) })}
      >
        {Boolean(state) && <ColorRowLine className={state} />}
        {Boolean(checkMark !== undefined) && (
          <div style={{ flex: '0 0 30px', paddingLeft: 10, alignSelf: 'center' }}>{checkMark}</div>
        )}
        {columns.map(
          column =>
            (!columnsSettings[column].visibility || columnsSettings[column].visibility.includes(columnSize)) && (
              <Cell key={column} rateType={rateType} column={column} rate={rate as any} rules={rules} />
            )
        )}
        {!readOnly && !hideActions ? <RenderActions /> : null}
      </TableRowWithActions>
      {hasWarning && showWarning && (
        <RateWarning
          id={`${rateType}-warning-box`}
          rowRef={rowRef}
          rate={rate as NewRateDTO}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onCancel={onDelete}
          onEdit={onEdit}
          onSaveAnyway={onSaveAnyway}
        />
      )}
    </>
  )
}

export const Row = connect((store: IStore, { newRate, rateId }: OwnProps) => {
  return { rate: newRate || store.ssqRates[rateId] || store.bsqRates[rateId] }
})(React.memo(Component))

export const Cell = React.memo(
  ({
    rateType,
    column,
    rate,
    rules
  }: {
    rateType: RateType
    column: string
    rate: NewRateDTO
    rules: Record<string, RuleSearchDTO>
  }) => {
    return (
      <StyledCell
        data-id={`${rate.id}-${rateType}RateColumn-${columnIdNameList[column]}-${rate.number}`}
        style={oc(columnsSettings)[column].styles()}
      >
        {getCellValue(column, rate, rules)}
      </StyledCell>
    )
  }
)

const getCellValue = (column: string, rate: NewRateDTO, rules: Record<string, RuleSearchDTO>) => {
  const store = getStore().getState()

  switch (column) {
    case RateColumn.id:
      return makeRowNumber(rate.number, 5)
    case RateColumn.typeOfRate:
      return <div style={{ letterSpacing: '0.75px', fontWeight: 500 }}>{quoteDirectory.typeOfRate[rate.type]}</div>
    case RateColumn.typeOfQuote:
      return (
        <div style={{ letterSpacing: '0.75px', fontWeight: 500 }}>{quoteDirectory.customerQuoteType[rate.type]}</div>
      )
    case RateColumn.type: {
      if (rate.vendorId) {
        return oc(getListState().driver[rate.vendorId]).name('–')
      } else if (rate.customerId) {
        return oc(store.customer[rate.customerId]).name('–')
      }

      return 'TFF'
    }
    case RateColumn.pickup: {
      const loc = store.location[rate.pickupLocationId]
      return loc ? loc.code || loc.name || '–' : '–'
    }
    case RateColumn.delivery:
      return getLocationCellValue(rate)
    case RateColumn.return: {
      const loc = store.location[rate.returnLocationId]
      return loc ? loc.code || loc.name || '–' : '–'
    }
    case RateColumn.doType: {
      if (Array.isArray(rate.deliveryOrderType)) {
        return rate.deliveryOrderType.map(item => deliveryOrderDirectory.columnType[item].toUpperCase()).join(', ')
      }
      return (deliveryOrderDirectory.columnType[rate.deliveryOrderType] || '–').toUpperCase()
    }
    case RateColumn.containerType: {
      if (Array.isArray(rate.containerTypeId)) {
        const values = rate.containerTypeId
          .map(id => store.containerType[id])
          .filter(Boolean)
          .map(({ code }) => code)

        if (rate.containerTypeId.length === 1) {
          return values
        }

        return ColumnValueWithPopover({ values, showNumberAsValue: true })
      }

      return rate.containerTypeId ? oc(store.containerType[rate.containerTypeId]).code('–') : '–'
    }
    case RateColumn.loadType: {
      if (Array.isArray(rate.loadType)) {
        return rate.loadType.map(item => quoteDirectory.loadTypeAll[item]).join(', ')
      }
      return quoteDirectory.loadTypeAll[rate.loadType] || '–'
    }
    case RateColumn.calculationType: {
      if (Array.isArray(rate.calculationType)) {
        return rate.calculationType.map(item => quoteDirectory.calculationType[item]).join(', ')
      }
      return quoteDirectory.calculationType[rate.calculationType] || '–'
    }
    case RateColumn.rule: {
      let _rules = null

      if (oc(rate).ruleIds([]).length) {
        const ruleList = rate.ruleIds.reduce((acc, currId) => {
          if (rules[currId] && rules[currId].name) {
            acc.push(rules[currId].name)
          }
          return acc
        }, [])

        _rules = (
          <ul data-for={'rules'} data-tip={ruleList.join('\n')}>
            {ruleList.map((rule: string, index: number) => {
              return <li key={index}>{rule}</li>
            })}
          </ul>
        )
      }

      return (
        <Rules>
          <div>{_rules || '–'}</div>
        </Rules>
      )
    }
    case RateColumn.effectiveDate: {
      const isExpired = rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED
      return (
        <span style={isExpired ? { color: 'red' } : undefined}>
          {dateService.makeLabel(rate.effectiveDate, { hideTime: true }) || (
            <span style={{ color: 'red' }}>--/--/--</span>
          )}
        </span>
      )
    }
    case RateColumn.expirationDate: {
      const isExpired = rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED
      return (
        <span style={isExpired ? { color: 'red' } : undefined}>
          {dateService.makeLabel(rate.expirationDate, { hideTime: true }) || '–'}
        </span>
      )
    }
    case RateColumn.amount:
      return (
        <div style={{ fontWeight: 500 }}>
          {rate.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE && rate.amount
            ? rate.amount + '%'
            : transformMoney(rate.amount) || ''}
        </div>
      )
    default:
      return ''
  }
}

export const makeRowNumber = (rateNumber: number, count?: number): string => {
  if (rateNumber === null || rateNumber === undefined) {
    return ''
  }

  const rateNum = String(rateNumber)
  if ((count || 5) > rateNum.length) {
    return Array((count || 5) - rateNum.length + 1).join('0') + rateNum
  } else {
    return rateNum
  }
}

const getLocationCellValue = (rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO) => {
  if (!rate) {
    return '–'
  }
  const store = getStore().getState()
  const city = rate.deliveryCity
  const state = rate.deliveryStateId && store.state ? oc(store.state[rate.deliveryStateId]).code(null) : null
  const zip = rate.deliveryPostalCode

  return city || state || zip ? (
    <div style={{ overflow: 'hidden', marginRight: -8 }}>
      {city && <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{city}</div>}
      {state && <div>{state}</div>}
      {zip && <div>{zip}</div>}
    </div>
  ) : (
    '–'
  )
}

const columnIdNameList = {
  [RateColumn.amount]: 'amount',
  [RateColumn.rule]: 'rule',
  [RateColumn.loadType]: 'loadType',
  [RateColumn.pickup]: 'pickup',
  [RateColumn.delivery]: 'delivery',
  [RateColumn.return]: 'return',
  [RateColumn.type]: 'type',
  [RateColumn.typeOfQuote]: 'typeOfQuote',
  [RateColumn.typeOfRate]: 'typeOfRate',
  [RateColumn.calculationType]: 'calculationType',
  [RateColumn.containerType]: 'containerType',
  [RateColumn.doType]: 'doType',
  [RateColumn.effectiveDate]: 'effectiveDate',
  [RateColumn.expirationDate]: 'expirationDate',
  [RateColumn.id]: 'id'
}

const Rules = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: auto;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      width: 100%;
      li {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`

const generateExpireRateData = (rate: AnyRateDTO): JSX.Element => {
  const text = generateExpireRateText(rate)
  const blocks = text.split('\n')

  return (
    <div style={{ lineHeight: 1.3, whiteSpace: 'pre-wrap' }}>
      {blocks.map((item, index) => {
        const lineData = item.split(':')
        const title = lineData[0]
        const value = lineData[1]

        return (
          <React.Fragment key={index}>
            {title && (
              <span style={{ display: 'inline-block', width: 90, fontWeight: 500 }}>
                {title}
                {value ? ':' : undefined}
              </span>
            )}
            {value}
            <br />
          </React.Fragment>
        )
      })}
      <br />
      Are you sure this rate is expired?
    </div>
  )
}
