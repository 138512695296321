import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import theme from '../../../../../../../../../../styles/theme'
import { FileCategory } from '../../../../../../../../../../services/filesService/interfaces'
import { dateService } from '../../../../../../../../../../services/timeService'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import { ActivityDocumentPopupView } from '../../../../../../../interfaces'
import { getListState } from '../../../../../../../../../../store'
import { useAppSelector } from '../../../../../../../../../../hooks/useAppSelector'
import { selectContacts } from '../../../../../../../../../../store/select/contactSelect'

type OwnProps = {}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const DocumentsActivityColumn = (props: Props) => {
  const {
    tirDocument,
    podDocument,
    tirDocumentMessage,
    podDocumentMessage,
    businessActivityCompleteDateDisabled,
    openActivityDocumentPopup
  } = useTransportationActivityRow()
  const users = useAppSelector(selectContacts)
  const isEditble = !businessActivityCompleteDateDisabled
  const documents = [
    {
      type: FileCategory.POD,
      document: podDocument,
      documentMessage: podDocumentMessage
    },
    {
      type: FileCategory.TIR,
      document: tirDocument,
      documentMessage: tirDocumentMessage
    }
  ].filter(({ document }) => document)

  return (
    <Container>
      {/* {tirDocument && (
        <DocumentIcon
          className={'mdi mdi-file-download'}
          title={FileCategory.TIR}
          onClick={() => tirDocument.url && openFileLink(tirDocument.url)}
        />
      )}
      {podDocument && (
        <DocumentIcon
          className={'mdi mdi-file-download'}
          title={FileCategory.POD}
          onClick={() => podDocument.url && openFileLink(podDocument.url)}
        />
      )} */}
      {documents.map(({ type, document, documentMessage }) => {
        // @ts-ignore
        const validatedManually = oc(documentMessage).metadata.validation.validatedManually()

        return (
          <Document
            key={documentMessage.id}
            className={cn({ isEditble })}
            onClick={() => openActivityDocumentPopup(ActivityDocumentPopupView.INFO, true)}
          >
            <DocumentIcon className={cn('mdi mdi-file-download', { validatedManually })} title={type} />
            <DocumentDetails>
              <DocumentCreator>{oc(users)[documentMessage.senderId].name('Sender Name')}</DocumentCreator>
              <DocumentDate>{dateService.makeLabel(documentMessage.createdAt)}</DocumentDate>
            </DocumentDetails>
            {isEditble && <DocumentEditButton className={'mdi mdi-square-edit-outline'} />}
          </Document>
        )
      })}
      {/* <Document className={cn({ isEditble })}>
        <DocumentIcon className={'red mdi mdi-alert'} title={'TT'} />
        <DocumentDetails>
          <DocumentCreator>Sender Name</DocumentCreator>
          <DocumentDate>11/18/22 09:49</DocumentDate>
        </DocumentDetails>
        {isEditble && <DocumentEditButton className={'mdi mdi-square-edit-outline'} />}
      </Document> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 0;
`
const Document = styled.div`
  height: 50%;
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 8px;
  cursor: pointer;

  &:hover {
    background-color: white;
  }

  &.isEditble {
    grid-template-columns: 40px 1fr 20px;
  }
`
const DocumentIcon = styled.div`
  text-align: center;
  font-size: 24px;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;

  &:after {
    content: attr(title);
    display: block;
    font-size: 14px;
    color: #475364;
    text-transform: uppercase;
  }

  &.red {
    color: ${theme.colors.defaultRed};
  }

  &.validatedManually {
    color: ${theme.colors.orange};
  }
`
const DocumentDetails = styled.div`
  overflow: hidden;
`
const DocumentCreator = styled.div`
  font-size: 14px;
  color: #475364;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
`
const DocumentDate = styled.div`
  font-size: 12px;
  color: #98a4b4;
`
const DocumentEditButton = styled.div`
  height: 30px;
  align-self: flex-start;
  color: #445366;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;

  &:hover {
    color: black;
  }
`
