import * as React from 'react'
import { TabActionType, TabType, TGridColumns } from '../../common/tabs/interfaces'
import { OldViewingGridItem } from './OldViewingGridItem'
import { ViewingGridItemDriver } from './ViewingGridItemDriver'

type Props = {
  tabId: string
  tabType: TabType
  columnsSettings: TGridColumns
  enableEditing: boolean
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
}

export const ViewingGridItem = (props: Props) => {
  const { tabType } = props

  switch (tabType) {
    case TabType.driver:
      return <ViewingGridItemDriver {...props} />
    default:
      return <OldViewingGridItem {...props} />
  }
}
