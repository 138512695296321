import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { MessageType } from '../../../../api/origin/communication-hub-service'
import { useUploadFiles } from '../../../../hooks/useUploadFiles'
import { FileCategory, IAttachment } from '../../../../services/filesService/interfaces'
import { getStore } from '../../../../store/configureStore'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { createChatDocumentMessage } from '../../epics'
import { generateChannelDocumentMessage } from '../../functions/generateChannelDocumentMessage'
import { File } from '../Attachments/File'

type Props = {
  channelId: string
}

export const AttachmentsUploader = React.memo((props: Props) => {
  const { channelId } = props
  const { onFilesSelect, uploadingFilesState } = useUploadFiles({
    streamId: channelId,
    onFileUploaded: uploadedFile => {
      const attachment: IAttachment = uploadedFile as IAttachment
      attachment.category = FileCategory.OTHER

      createChatDocumentMessage(
        generateChannelDocumentMessage({
          channelId,
          attachments: [attachment],
          metadata: {
            ddo: {
              id: channelId,
              number: oc(getStore().getState()).dispatchDeliveryOrder[channelId].number()
            }
          }
        })
      )
    }
  })

  return (
    <Conrainer>
      {uploadingFilesState ? (
        <>
          <UploadingFilesTitle>Uploading...</UploadingFilesTitle>
          <UploadingFilesContainer>
            {uploadingFilesState.map(file => (
              <File key={file.id} file={file} />
            ))}
          </UploadingFilesContainer>
        </>
      ) : (
        <label>
          <ActionButton
            filled={true}
            round={true}
            styles={{
              width: 120,
              height: 35,
              borderRadius: 20,
              boxShadow: '0 2px 12px rgba(68, 83, 102, 0.3)',
              position: 'fixed',
              right: 30,
              bottom: 30
            }}
            onClick={() => {}}
          >
            Upload File
          </ActionButton>
          <input type={'file'} multiple={true} onChange={e => onFilesSelect(e.target.files)} hidden={true} />
        </label>
      )}
    </Conrainer>
  )
})

const Conrainer = styled.div`
  z-index: 1;
`
const UploadingFilesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
`
const UploadingFilesTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 10px;
`
