import * as React from 'react'
import { connect } from 'react-redux'
import { IDeliveryOrder } from '../interfaces'
import styled from '../../../../styles/styledComponents'
import { GridColumn } from '../../../UI/GridSystem'
import { showModal, TMsgType } from '../../../UI/Modal/actions'
import * as R from 'remeda'
import { DeliveryOrderViewDTO } from '../../../../api/api'
import { StyledGeneral } from '../../dispatchDeliveryOrder/views/Details/Activities/styledComponents'
import ActionsSaveCancel from '../../../UI/Buttons/ActionsSaveCancel'
import DispatchDeliveryOrders from './DispatchDeliveryOrders'
import { isValidDispatchDeliveryOrder } from '../../../../services/DTO/deliveryOrder'
import UpdateInfo from '../../../UI/UpdateInfo'
import { LeftSide } from './LeftSide'
import { RightSide } from './RightSide'
import { isNewObject } from '../../../../services/DTO'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { ITabState } from '../../tabs/interfaces'
import { saveDeliveryOrder } from '../../../../services/DTO/deliveryOrder/save'
import { DeliveryOrderContext } from './context'
import { IViewingObject } from '../../../../services/viewingObjects/interfaces'
import { oc } from 'ts-optchain'
import { modifyTabViewingObject } from '../../../../services/viewingObjects/actions'

const StyledPreview = styled(GridColumn)`
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 85px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-top: none;

  .delivery-order_container {
    width: 100%;
    position: relative;
  }

  .updatedInfo,
  .actions-save-cancel {
    z-index: 1;
    height: 45px;
    top: 10px;
    bottom: unset;
  }

  ${StyledGeneral} {
    border-radius: 5px;
    padding-top: 75px;
  }
`

const UnitStatusCover = styled.div`
  position: absolute;
  right: 0;
  top: 6px;
  .updatedInfo {
    margin-top: 10px;
    .created-info,
    .updated-info {
      width: auto;
      margin-right: 30px;
    }
  }
`

type OwnProps = { deliveryOrder: IDeliveryOrder; currentTab: ITabState; viewingObject: IViewingObject }

type DispatchProps = {
  modifyDeliveryOrder: (modifiedGridItem: any) => void
  modifyDeliveryOrderField: (field: keyof IDeliveryOrder) => (value: any) => void
  cancel: () => void
  close: () => void
}

type Props = OwnProps & DispatchProps

const DeliveryOrder = ({
  modifyDeliveryOrder,
  modifyDeliveryOrderField,
  currentTab,
  deliveryOrder,
  cancel,
  viewingObject
}: Props) => {
  const readyToWork = Boolean(deliveryOrder && deliveryOrder.fullObject)
  if (!readyToWork) {
    return (
      <StyledPreview background={'#E5E7EF'} padding={{ top: 10, left: 10, right: 10 }}>
        <GridSpinner />
      </StyledPreview>
    )
  }

  const [isFetching, setFetching] = React.useState(false)
  const isNew = isNewObject(deliveryOrder)
  const isModifiedMode = Boolean(oc(viewingObject).modifiedObject())

  const SaveButton = (actionBeforeSaving: () => void = () => {}) =>
    isModifiedMode ? (
      <ActionsSaveCancel
        disableSave={!isValidDispatchDeliveryOrder(deliveryOrder)}
        showCancel={!isNew}
        onSave={() => {
          setFetching(true)
          setTimeout(() => {
            actionBeforeSaving()
            saveDeliveryOrder(deliveryOrder, {
              currentTab,
              hideSpinner: () => setFetching(false)
            })
          })
        }}
        onCancel={cancel}
      />
    ) : null

  const UnitStatus = () =>
    SaveButton() || (
      <UpdateInfo
        updatedAt={deliveryOrder.updatedAt}
        updatedBy={deliveryOrder.updatedBy}
        createdAt={deliveryOrder.createdAt}
        createdBy={deliveryOrder.createdBy}
      />
    )

  return (
    <DeliveryOrderContext.Provider
      value={{
        state: {
          deliveryOrder,
          isModifiedMode,
          isNew,
          isImport: deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT,
          isExport: deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT,
          isRepo: deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        },
        functions: {
          modifyDeliveryOrder,
          modifyDeliveryOrderField
        }
      }}
    >
      <StyledPreview background={'#E5E7EF'} padding={{ top: 10, left: 10, right: 10 }}>
        {isFetching && <GridSpinner />}
        <div className={'delivery-order_container'}>
          <StyledGeneral background={'white'} padding={{ top: 20, left: 25, right: 25, bottom: 40 }}>
            <UnitStatusCover>
              <UnitStatus />
            </UnitStatusCover>
            <LeftSide />
            <RightSide />
          </StyledGeneral>

          <DispatchDeliveryOrders />
        </div>
      </StyledPreview>
    </DeliveryOrderContext.Provider>
  )
}

export default connect(
  null,
  (dispatch, { deliveryOrder, currentTab, viewingObject }: OwnProps): DispatchProps => {
    const modified = oc(viewingObject).modifiedObject()
    const isModified = Boolean(modified)

    const modifyViewingObject = (modifiedObject: IDeliveryOrder) => {
      dispatch(modifyTabViewingObject({ tabId: currentTab.id, modifiedObject }))
    }

    return {
      modifyDeliveryOrder(modifiedItem: any) {
        modifyViewingObject(isModified ? modifiedItem : R.clone(modifiedItem))
      },
      modifyDeliveryOrderField(field: string) {
        return (value: any) => modifyViewingObject({ ...(modified || R.clone(deliveryOrder)), [field]: value })
      },
      cancel() {
        modifyViewingObject(undefined)
      },
      close() {
        dispatch(
          showModal({
            msgType: TMsgType.delete,
            message: 'Are you sure you want to close the "New Delivery Order" tab? All changes will be lost.',
            // onConfirm: () => dispatch(closeTabDeliveryOrder({ tabId: id })),
            onConfirm: () => {},
            onCancel: () => {}
          })
        )
      }
    }
  }
)(DeliveryOrder)
