import * as React from 'react'
import styled from 'styled-components'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import ReactTooltip from 'react-tooltip'
import { applicableRatesTableHeaderProps } from './config'
import { BasicTable } from '../../../../../../../BasicTable'
import { Checkbox } from '../../../../../../../Radio'
import { makeRowNumber } from '../../../../../../../../common/QMP/views/Tabs/Rate/Column/Row'
import { makeActivityColumn, makeSurchargeCalculationTypeColumn } from '../../functions'
import { quoteDirectory } from '../../../../../../../../../services/DTO/sellSideQuote/directory'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../types'
import {
  SellSideQuoteSurchargeDTO,
  TransportationActivityViewDTO
} from '../../../../../../../../../api/origin/business-logic'
import { SellSideQuoteRateDTO } from '../../../../../../../../../api/origin/qmp-service'
import { RateDTO } from '../../../../../../../../../services/DTO/rate/interfaces'
import { filterAvailableActivitiesToAttach } from '../../../../functions'
import { calcSurcharge } from '../../../../../../../../common/QMP/views/functions'
import { dateService } from '../../../../../../../../../services/timeService'
import { isBusinessActivity } from '../../../../../../../../../services/functions/test/isBusinessActivity'
import { isBobtailGotoActivity } from '../../../../../../../../../services/functions/test/isBobtailGotoActivity'
import { transformMoney } from '../../../../../../../Input'
import { useAppSelector } from '../../../../../../../../../hooks/useAppSelector'
import { selectRules } from '../../../../../../../../../store/select/ruleSelect'

type Props = {
  calculateBasePlusFuel?: boolean
  disableActivityColumn?: boolean
  rates: (CombinedSurchargeRateDTO | SurchargeDTO | RateDTO)[]
  toggleRate: (rate: SellSideQuoteRateDTO) => void
  updateSurchargeActivityId: (id: string, activityId: string) => void
  updateSurchargeQuantity: (rateId: string, quantity: number) => void
  openRateNumber: (rateNumber: string) => void
  transportationActivities?: TransportationActivityViewDTO[]
}

export const RateTable = (props: Props) => {
  const {
    calculateBasePlusFuel,
    disableActivityColumn,
    rates,
    transportationActivities,
    toggleRate,
    openRateNumber,
    updateSurchargeActivityId,
    updateSurchargeQuantity
  } = props
  const rules = useAppSelector(selectRules)

  React.useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!rates || !rates.length) {
    return null
  }

  const transportationBusinessActivities =
    transportationActivities && transportationActivities.filter(activity => isBusinessActivity(activity))
  const bobtailTransportationBusinessActivities =
    transportationActivities && transportationActivities.filter(activity => isBobtailGotoActivity(activity))
  const tableHeaderProps = transportationBusinessActivities
    ? applicableRatesTableHeaderProps
    : applicableRatesTableHeaderProps.filter(({ key }) => key !== 'Activity')

  let fuelHint: string = undefined
  let basePluFuelAmount: string = undefined

  if (calculateBasePlusFuel) {
    const basePluFuelRates = {
      base: rates.find(_ => _.type === SellSideQuoteSurchargeDTO.TypeEnum.BASE),
      fuel: rates.find(_ => _.type === SellSideQuoteSurchargeDTO.TypeEnum.FUEL)
    }

    if (basePluFuelRates.base && basePluFuelRates.fuel) {
      fuelHint = transformMoney((oc(basePluFuelRates).fuel.amount(0) / 100) * oc(basePluFuelRates).base.amount())
      basePluFuelAmount = transformMoney(
        oc(basePluFuelRates).base.amount(0) +
          oc(basePluFuelRates).base.amount(0) * (oc(basePluFuelRates).fuel.amount(0) / 100)
      )
    }
  }

  return (
    <>
      <BasicTable
        header={{
          columns: tableHeaderProps,
          styles: { padding: '4px 6px', background: transportationBusinessActivities ? '#fafafa' : undefined }
        }}
        body={{
          styles: { margin: '8px 0 6px auto', width: 'calc(100% - 6px)', overflowY: 'scroll' },
          rows: rates.map((rate: any) => ({
            key: rate.id,
            columns: tableHeaderProps.map(columnProps => {
              const column = R.clone(columnProps)
              const isSurcharge = 'rateId' in rate
              const isBobtail = rate.type === SellSideQuoteRateDTO.TypeEnum.BOBTAIL
              const allowChanges = Boolean(updateSurchargeActivityId)

              switch (columnProps.key) {
                case 'ID': {
                  column.styles.paddingLeft = 0
                  column.value = (
                    <IDColumn>
                      {allowChanges && (
                        <Checkbox style={{ margin: '0 10px' }} active={isSurcharge} onClick={() => toggleRate(rate)} />
                      )}
                      <IDLink onClick={() => openRateNumber(rate.number)}>{makeRowNumber(rate.number, 5)}</IDLink>
                    </IDColumn>
                  )
                  break
                }
                case 'Activity': {
                  const activities = isBobtail
                    ? bobtailTransportationBusinessActivities
                    : transportationBusinessActivities

                  column.value =
                    isSurcharge && allowChanges ? (
                      makeActivityColumn({
                        rate,
                        activities,
                        availableActivitiesToAttach: filterAvailableActivitiesToAttach(
                          activities,
                          rates.filter((r: any) => r.activityId && r.rateId === rate.rateId)
                        ),
                        updatedActivityId: updateSurchargeActivityId,
                        disableActivityColumn,
                        showIcon: false
                      })
                    ) : (
                      <div style={{ fontWeight: 500 }}>DDO</div>
                    )
                  break
                }
                case 'Surcharges': {
                  column.styles = { ...column.styles, fontWeight: 500 }
                  column.value = quoteDirectory.typeOfRate[rate.type]
                  break
                }
                case 'Type': {
                  column.value = rate.customerId || rate.vendorId ? 'PRT' : 'TFF'
                  break
                }
                case 'Calculation Type': {
                  column.value = (
                    <div style={{ display: 'flex' }}>
                      {makeSurchargeCalculationTypeColumn(isSurcharge, rate, updateSurchargeQuantity)}
                      {isSurcharge
                        ? (() => {
                            switch (rate.calculationType) {
                              case SellSideQuoteRateDTO.CalculationTypeEnum.PERHOUR:
                              case SellSideQuoteRateDTO.CalculationTypeEnum.PERDAY:
                              case SellSideQuoteRateDTO.CalculationTypeEnum.PERMILE:
                                return <RateAmount>{transformMoney(rate.amount)}</RateAmount>
                              default:
                                return null
                            }
                          })()
                        : null}
                    </div>
                  )

                  break
                }
                case 'Rule': {
                  let rulesHTML = null

                  if (Boolean(oc(rate).ruleIds([]).length)) {
                    const ruleList = rate.ruleIds.reduce((acc: string[], currId: string) => {
                      if (rules[currId] && rules[currId].name) {
                        acc.push(rules[currId].name)
                      }
                      return acc
                    }, [])

                    rulesHTML = (
                      <ul data-for={'rules'} data-tip={ruleList.join('\n')}>
                        {ruleList.map((rule: string, index: number) => {
                          return <li key={index}>{rule}</li>
                        })}
                      </ul>
                    )
                  }

                  column.value = (
                    <Rules>
                      <div>{rulesHTML || '–'}</div>
                    </Rules>
                  )
                  break
                }
                case 'Effective Date': {
                  column.value = dateService.makeLabel(rate.effectiveDate, { hideTime: true }) || '–'
                  break
                }
                case 'Expiration Date': {
                  const isExpired =
                    rate.status === SellSideQuoteRateDTO.StatusEnum.EXPIRED ||
                    (rate.expirationDate ? Date.parse(rate.expirationDate) < new Date().getTime() : false)
                  if (isExpired) {
                    column.styles = { ...column.styles, color: 'red' }
                  }
                  column.value = dateService.makeLabel(rate.expirationDate, { hideTime: true }) || '–'
                  break
                }
                case 'Amount': {
                  column.styles = { ...column.styles, fontWeight: 500 }

                  switch (rate.calculationType) {
                    case SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERDAY:
                    case SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERHOUR:
                    case SellSideQuoteSurchargeDTO.CalculationTypeEnum.PERMILE:
                      column.value = isSurcharge
                        ? transformMoney(calcSurcharge(0, rate, 0))
                        : transformMoney(rate.amount)
                      break
                    case SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE:
                      column.value = Boolean(fuelHint) ? (
                        <CellWithDetails data-for={'hint'} data-tip={fuelHint}>
                          {rate.amount + '%'}
                        </CellWithDetails>
                      ) : (
                        rate.amount + '%'
                      )
                      break
                    default:
                      column.value = transformMoney(rate.amount)
                  }
                  break
                }
                default:
              }

              return column
            })
          }))
        }}
      />
      {Boolean(basePluFuelAmount) && (
        <BasePlusFuelAmount>
          Base + Fuel:<span>{basePluFuelAmount}</span>
        </BasePlusFuelAmount>
      )}
    </>
  )
}

const IDColumn = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`
export const IDLink = styled.span`
  min-width: 45px;
  font-size: 14px;
  color: #4555d0;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`
const RateAmount = styled.div`
  flex-shrink: 0;
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  padding: 0 5px;
  margin-left: auto;
`
const CellWithDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .details {
    font-weight: 400;
  }
`
const Rules = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: auto;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      width: 100%;
      li {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`
const BasePlusFuelAmount = styled.div`
  text-align: right;
  padding: 10px 10px 20px;

  span {
    font-weight: 500;
    margin-left: 8px;
  }
`
