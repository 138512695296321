import * as React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Message } from '../Message/Message'
import { MessageType } from '../../../../api/origin/communication-hub-service'
import theme from '../../../../styles/theme'
import { checkIfNewMessageIncreasesChannelCounter } from '../../functions/checkIfNewMessageIncreasesChannelCounter'
import { ChatTab, IMessage, TMessageIdGroupsByDates } from '../../../../store/reducers/communicationHub/interfaces'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectUser } from '../../../../store/select/userSelect'
import {
  selectCommunictaionHubChannelMetaUnreadMessagesNumber,
  selectCommunictaionHubMessageIds,
  selectCommunictaionHubMessages
} from '../../../../store/select/communicationHubSelect'

type OwnProps = {
  chatTab: ChatTab
  onMessageListChange?: () => void
  showMessageIds?: string[]
  disableMessageActions?: boolean
  showOnlyUreadMessages?: boolean
  channelId?: string
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const MessagesTab = React.memo((props: Props) => {
  const { chatTab, onMessageListChange, disableMessageActions, showOnlyUreadMessages, channelId } = props
  const user = useAppSelector(selectUser)
  const messageIds = props.showMessageIds || useAppSelector(selectCommunictaionHubMessageIds)
  const messageMapping = useAppSelector(selectCommunictaionHubMessages)
  const newMessagesCount = useAppSelector(selectCommunictaionHubChannelMetaUnreadMessagesNumber(channelId))
  const firstNewMessageId = React.useRef(null)
  const showMessageIds = React.useMemo(() => {
    if (!showOnlyUreadMessages) {
      return messageIds
    }

    if (!newMessagesCount) {
      return []
    }

    const newMessages =
      messageIds.length > 0 && messageIds.length > newMessagesCount
        ? messageIds
            .filter(messageId => checkIfNewMessageIncreasesChannelCounter(messageMapping[messageId], user.id))
            .slice(-newMessagesCount)
        : messageIds

    return newMessages
  }, [messageIds, messageMapping, showOnlyUreadMessages, newMessagesCount, user.id])

  React.useLayoutEffect(() => {
    if (onMessageListChange) {
      onMessageListChange()
      setTimeout(onMessageListChange)
    }
  }, [chatTab, messageMapping])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [messageMapping, chatTab])

  const groupsOfMessages = React.useMemo(() => {
    return groupMessagesByDates(
      showMessageIds.reduce((acc, currId) => {
        const message = messageMapping[currId]

        if (message && message.type !== MessageType.DOCUMENT) {
          if (chatTab === ChatTab.Messages) {
            acc.push(message)
          } else if (chatTab === ChatTab.Notes && message.isPinned) {
            acc.push(message)
          } else if (chatTab === ChatTab.Alerts && message.type === MessageType.ALERT) {
            acc.push(message)
          }
        }

        return acc
      }, [])
    )
  }, [
    chatTab,
    newMessagesCount,
    [ChatTab.Messages, ChatTab.Alerts].includes(chatTab) ? showMessageIds : messageMapping
  ])

  const RenderNoMessagesText = React.useCallback(() => {
    let text = 'No messages yet'

    if (chatTab === ChatTab.Messages) {
      text = 'No messages yet'
    } else if (chatTab === ChatTab.Notes) {
      text = 'No notes yet'
    } else if (chatTab === ChatTab.Alerts) {
      text = 'No alerts yet'
    }

    return <NoMessagesText children={text} />
  }, [chatTab])

  firstNewMessageId.current = React.useMemo((): string => {
    if (showOnlyUreadMessages) {
      return undefined
    }

    if (chatTab === ChatTab.Messages && newMessagesCount) {
      const messages: IMessage[] = []

      Object.keys(groupsOfMessages).forEach(date => {
        groupsOfMessages[date].forEach(id => {
          const message = messageMapping[id]

          if (checkIfNewMessageIncreasesChannelCounter(message, user.id)) {
            messages.push(message)
          }
        })
      })

      if (messages.length >= newMessagesCount) {
        return messages[messages.length - newMessagesCount].id
      }
    }

    return firstNewMessageId.current
  }, [newMessagesCount, chatTab, groupsOfMessages, showOnlyUreadMessages])

  const groupsOfMessagesList = Object.keys(groupsOfMessages)

  return (
    <>
      {groupsOfMessagesList.length ? (
        groupsOfMessagesList.map(date => (
          <React.Fragment key={date}>
            <DateTitle key={date + 1}>{date}</DateTitle>
            {groupsOfMessages[date].map(messageId => (
              <React.Fragment key={messageId}>
                {chatTab === ChatTab.Messages && messageId === firstNewMessageId.current && (
                  <NewMessages>New Messages</NewMessages>
                )}
                <Message messageId={messageId} disableMessageActions={disableMessageActions} />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))
      ) : (
        <RenderNoMessagesText />
      )}
    </>
  )
})

export const NoMessagesText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c4c6cb;
  font-size: 14px;
  user-select: none;
`

export const DateTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  cursor: default;
  user-select: none;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    display: block;
    height: 1px;
    background-color: #333333;
    margin: 0 15px;
  }
`
const NewMessages = styled.div`
  color: ${theme.colors.defaultRed};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin: 15px;
  display: flex;
  align-items: center;
  user-select: none;

  &:after {
    content: '';
    flex-grow: 1;
    display: block;
    height: 1px;
    background-color: ${theme.colors.defaultRed}33;
    margin-left: 8px;
  }
`

const groupMessagesByDates = (messages: IMessage[]): TMessageIdGroupsByDates => {
  const result = {}
  const groupedMessages: Record<string, IMessage[]> = messages.reduce((acc, message) => {
    if (!message.createdAt) {
      return acc
    }

    const date = new Date(Date.parse(message.createdAt))
    const dateId = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

    if (!acc[dateId]) {
      acc[dateId] = []
    }

    acc[dateId].push(message)

    return acc
  }, {})

  Object.keys(groupedMessages)
    .sort((a, b) => Date.parse(a) - Date.parse(b))
    .forEach(day => {
      result[day] = groupedMessages[day]
        .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
        .map(_ => _.id)
    })

  return result
}
