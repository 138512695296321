import * as React from 'react'
import styled from '../../../../styles/styledComponents'
import theme from '../../../../styles/theme'
import { oc } from 'ts-optchain'
import { UserDTO } from '../../../../api/origin/user-service'

type Props = {
  mention: UserDTO
  className?: any
  fromMessage?: boolean
  children?: any
}

export const MentionLabel = (props: Props) => {
  const { mention, className, fromMessage, children } = props
  const nameToRender = children

  return (
    <MentionContainer className={`mention-container ${className || ''}`}>
      <span data-for={'user-details-hint'} data-place={'top'} data-tip={oc(mention).id()}>
        @{nameToRender}
      </span>
    </MentionContainer>
  )
}

const MentionContainer = styled.span`
  display: inline-block;
  position: relative;
  color: ${theme.colors.basicBlueColor};
  cursor: pointer;
  background: ${theme.colors.basicBlueColor}10;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
`
