import actionCreatorFactory, { ActionCreatorFactory } from 'typescript-fsa'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../../api/origin/business-logic'

const activity: ActionCreatorFactory = actionCreatorFactory('ACTIVITY')

export const removeIds = activity<string[]>('REMOVE_IDS')
export const addToStore = activity<{
  documentationActivities: Record<string, DocumentationActivityDTO>
  transportationActivities: Record<string, TransportationActivityViewDTO>
}>('ADD_TO_STORE')

export const addDocumentationActivitiesToStore = activity<Record<string, DocumentationActivityDTO>>(
  'DOCUMENTATION_ADD_TO_STORE'
)
export const addTransportationActivitiesToStore = activity<Record<string, TransportationActivityViewDTO>>(
  'TRANSPORTATION_ADD_TO_STORE'
)
