import { ISteamShipLine } from '../interfaces'
import { oc } from 'ts-optchain'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { getPrimaryContactNameFormContacts } from '../../../../services/DTO/contact'

export const headers = {
  Name: { width: 160, active: true },
  'Reference #': { width: 160, active: true },
  'Free Detention Allowance': { width: 160, active: true },
  'Primary Contact': { width: 160, active: true }
}

export const adaptRowValues = (steamShipLine: ISteamShipLine): TColumns => {
  const columnValues = {
    Name: oc(steamShipLine).businessPartner.legalName(''),
    'Reference #': oc(steamShipLine).referenceNumber(''),
    'Free Detention Allowance': oc(steamShipLine).freeDetentionAllowance()
      ? steamShipLine.freeDetentionAllowance + ' days'
      : '',
    'Primary Contact': getPrimaryContactNameFormContacts(oc(steamShipLine).businessPartner.contacts([]))
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
