import * as React from 'react'
import { connect } from 'react-redux'
import * as R from 'remeda'
import { IDispatchDeliveryOrder } from '../../../../../dispatchDeliveryOrder/interfaces'
import Activities from '../../../../../dispatchDeliveryOrder/views/Details/Activities'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../../../../../api/api'
import ActionsSaveCancel from '../../../../../../UI/Buttons/ActionsSaveCancel'
import { GridItemSpinner } from '../../../../../../UI/Spinner/Spinner'
import { ActivitiesContainer } from '../../../styledComponents'
import { containerFn, isChassisNumberRequired } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { IGridItemActions } from '../../../../../../../contexts/GridItemContext'
import { parseDTO } from '../../../../../../../services/DTO/parseDTO'
import { ApplicationContext, IStore } from '../../../../../../../store/store.interface'
import { saveDispatchDeliveryOrder } from '../../../../../../../services/DTO/dispatchDeliveryOrder/save'
import { checkValidation } from '../../../../../../../services/DTO/validation'
import { assembleDTO } from '../../../../../../../services/DTO/assemble'
import { DetailsButtonSaveContainer } from './styles'
import { oc } from 'ts-optchain'
import { localStorageService } from '../../../../../../../services/storageService/localStorage/LocalStorage'
import { TabType } from '../../../../../tabs/interfaces'
import { IViewingObject } from '../../../../../../../services/viewingObjects/interfaces'
import { modifyTabViewingObject } from '../../../../../../../services/viewingObjects/actions'

type OwnProps = {
  tabId: string
  dispatchDeliveryOrderId: string
  viewingObject: IViewingObject
}

type StateProps = {
  dispatchDeliveryOrder?: IDispatchDeliveryOrder
}

type DispatchProps = {
  modifyTabViewingObject: typeof modifyTabViewingObject
}

type Props = OwnProps & StateProps & DispatchProps

export const Details = connect(
  (store: IStore, { dispatchDeliveryOrderId }: OwnProps): StateProps => {
    const storeDDO = store.dispatchDeliveryOrder[dispatchDeliveryOrderId]

    return {
      dispatchDeliveryOrder: oc(storeDDO).fullObject(false)
        ? assembleDTO.dispatchDeliveryOrder({ store, id: dispatchDeliveryOrderId })
        : undefined
    }
  },
  { modifyTabViewingObject }
)((props: Props) => {
  const { viewingObject, dispatchDeliveryOrder, dispatchDeliveryOrderId, tabId } = props
  const [fetching, setFetching] = React.useState<boolean | string>(false)
  const modifiedUnit = viewingObject && viewingObject.modifiedObject
  const isModified = Boolean(modifiedUnit)

  React.useEffect(() => {
    if (dispatchDeliveryOrderId) {
      localStorageService.pushRecentForTabType(TabType.dispatchDeliveryOrder, dispatchDeliveryOrderId)
    }

    if (!oc(dispatchDeliveryOrder).fullObject()) {
      setFetching(true)
      callAPI(dispatchDeliveryOrderAPI.findById, dispatchDeliveryOrderId)
        .toPromise()
        .then(DDO => {
          parseDTO.dispatchDeliveryOrder(DDO)
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }, [])

  if (fetching && !dispatchDeliveryOrder) {
    return <GridItemSpinner />
  }

  if (!dispatchDeliveryOrder) {
    return null
  }

  const unit = modifiedUnit || dispatchDeliveryOrder
  const isDDOValid = modifiedUnit && checkValidation.dispatchDeliveryOrder(modifiedUnit)

  const actions: IGridItemActions = {
    enableEditing: true,
    temporaryData: viewingObject.temporaryData,
    isFetching: fetching,
    setFetching,
    togglePreviewTab: () => {}, // don't need
    isModified,
    initialObjectState: oc(viewingObject).temporaryData.initialObjectState(),
    modify: (ddo, updatedInitialObjectState) => {
      const _unit = updatedInitialObjectState || (!isModified && unit)

      props.modifyTabViewingObject({
        tabId,
        modifiedObject: viewingObject && viewingObject.modifiedObject ? ddo : R.clone(ddo),
        initialObjectState: _unit ? R.clone(_unit) : oc(viewingObject).temporaryData.initialObjectState({})
      })
    },
    modifyParentObjectField: (ddo: any) => (field: string) => (value: any, updatedInitialObjectState?: any) => {
      actions.modify({ ...(modifiedUnit || R.clone(ddo)), [field]: value }, updatedInitialObjectState)
    },
    cancel() {
      props.modifyTabViewingObject({ tabId, modifiedObject: undefined, initialObjectState: undefined })
    },
    save() {
      actions.setFetching(true)
      setTimeout(() => {
        saveDispatchDeliveryOrder(modifiedUnit, {
          getUnitInfo: {
            applicationContext: ApplicationContext.main,
            tabId,
            unitId: modifiedUnit.id
          },
          modify: actions.modify,
          setFetching: actions.setFetching,
          cancel: actions.cancel,
          reset: actions.cancel
        })
      })
    }
  }

  // const SaveButton = () =>
  //   isModified ? (
  //     <ActionsSaveCancel
  //       styles={extraActionsStyles}
  //       disableSave={!isDDOValid}
  //       onSave={actions.save}
  //       onCancel={actions.cancel}
  //     />
  //   ) : null

  return (
    <>
      <ActivitiesContainer>
        {fetching && <GridItemSpinner />}
        <Activities
          isDDOValid={isDDOValid}
          dispatchDeliveryOrder={unit}
          actions={actions}
          saveButton={null}
          functions={containerFn(unit, actions)}
          required={{ chassisNumber: isChassisNumberRequired(unit.activities) }}
          showUserInfo={true}
          unlimitedHeight={true}
        />
      </ActivitiesContainer>
      <DetailsButtonSaveContainer>
        {isModified && (
          <ActionsSaveCancel
            styles={{ height: 55, right: 20 }}
            disableSave={!isDDOValid}
            onSave={actions.save}
            onCancel={actions.cancel}
          />
        )}
      </DetailsButtonSaveContainer>
    </>
  )
})
