import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { makeObjectSavePromise } from '../saveDTO'
import { isNewObject } from '../index'
import { getSellSideQuoteStatus } from './status'
import { SellSideQuoteDTO, sellSideQuoteAPI } from '../../../api/api'
import { tryAddSSQsToStore } from './epics'

export const getSellSideQuoteSavePromise = (
  sellSideQuote: SellSideQuoteDTO,
  actions?: IModifyGridItemActions
): Promise<SellSideQuoteDTO> => {
  const { needToSave } = getSellSideQuoteStatus(sellSideQuote)
  if (!sellSideQuote || !sellSideQuote.fullObject) {
    return Promise.resolve(null)
  }

  const transformedObjectForSaving: SellSideQuoteDTO = {
    ...sellSideQuote,
    surcharges: sellSideQuote.surcharges.map(surcharge =>
      isNewObject(surcharge) ? { ...surcharge, id: undefined } : surcharge
    ),
    miscSurcharges: sellSideQuote.miscSurcharges.map(miscSurcharge =>
      isNewObject(miscSurcharge) ? { ...miscSurcharge, id: undefined } : miscSurcharge
    )
  }

  return makeObjectSavePromise(
    needToSave,
    transformedObjectForSaving,
    actions,
    sellSideQuoteAPI,
    tryAddSSQsToStore
  ) as Promise<SellSideQuoteDTO>
}
