import { oc } from 'ts-optchain'
import { transformMoney } from '../../../components/UI/Input'
import { getListState } from '../../../store'
import { getStore } from '../../../store/configureStore'
import { ListEntityType } from '../../../store/reducers/lists/interfaces'
import { quoteDirectory } from '../../DTO/sellSideQuote/directory'
import { AnyRateDTO } from '../../../components/common/QMP/interfaces'

export const generateExpireRateText = (rate: AnyRateDTO): string => {
  if (!rate) {
    return ''
  }

  try {
    const _rate = rate as any
    const vendorMapping = getListState()[ListEntityType.driver]
    const customerMappping = getStore().getState().customer
    const customer = oc(customerMappping[_rate.customerId]).name()
    const driver = oc(vendorMapping[_rate.vendorId]).name()
    const rateNumber = `Rate ID: ${rate.number}`
    const rateType = `Type: ${quoteDirectory.typeOfRate[rate.type]}`
    const rateTariff = `${customer ? 'Customer: ' : driver ? 'Driver: ' : ''}${customer || driver || 'TFF'}`
    const rateAmount = `Amount: ${transformMoney(rate.amount)}`

    return [rateNumber, rateType, rateTariff, rateAmount].join('\n')
  } catch (e) {
    return ''
  }
}
