import { ILocation } from '../../../components/common/location/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { getContactStatus } from '../contact/status'
import { isEqualObjectsWithoutContacts, OmitContacts, omitUpdateInfo } from '../saveDTO'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { oc } from 'ts-optchain'

export const getLocationStatus = (location: ILocation): TObjectStatusForSave => {
  if (!oc(location).fullObject()) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false
    }
  }

  const store = getStore().getState()
  const storeLocation = store.location[location.id]
  const locationContacts = location.contacts || []
  const contactsStatus = locationContacts.map(getContactStatus)
  const primaryContact = locationContacts.find(_ => _.primary)
  const isPrimaryContactEqual = () => {
    if (!storeLocation.primaryContactId && !primaryContact) {
      return true
    }
    if (!storeLocation.primaryContactId && primaryContact) {
      return false
    }
    if (storeLocation.primaryContactId && primaryContact) {
      return storeLocation.primaryContactId === primaryContact.id
    }
  }

  const noStoreDataForUpdate =
    compareUpdateDates(location, storeLocation) && contactsStatus.map(_ => _.noStoreDataForUpdate).every(Boolean)

  const storeObject = omitUpdateInfo(OmitContacts(storeLocation))
  const checkObject = omitUpdateInfo(OmitContacts(location))

  return {
    get noStoreDataForUpdate() {
      return noStoreDataForUpdate
    },
    get needToSave() {
      return (
        oc(location).fullObject() &&
        noStoreDataForUpdate &&
        (!isEqualObjectsWithoutContacts(checkObject, storeObject) || !isPrimaryContactEqual())
      )
    },
    get getDifference() {
      return noStoreDataForUpdate
        ? undefined
        : getObjectsDifference({
            oldObject: checkObject,
            newObject: storeObject
          })
    }
  }
}
