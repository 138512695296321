import * as React from 'react'
import General from '../Details/General'
import { IContainer } from '../../interfaces'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Container

export const ContainerPreview = {
  headings: [T.General],
  tabs: {
    [T.General]: (container: IContainer, actions: IGridItemActions) => (
      <General container={container} actions={actions} />
    )
  },
  isValid: checkValidation.container
}
