// import Container from './DynamicInput/Container'
// export { ContainerType as ContainerTypeSearch } from './ContainerType'
// import SteamShipLine from './DynamicInput/SteamShipLine'
// export { SubClient as SubClientSearch } from './DynamicInput/SubClient'
// import Customer from './DynamicInput/Customer'
// export { PowerUnit as PowerUnitSearch } from './DynamicInput/PowerUnit'
// export { Driver as DriverSearch } from './DynamicInput/Driver'
// import Location from './DynamicInput/Location'
// import Equipment from './DynamicInput/Equipment'
export { Hazmat as HazmatSearch } from './DynamicInput/Hazmat'

// export const SteamShipLineSearch = SteamShipLine
// export const CustomerSearch = Customer
// export const LocationSearch = Location
// export const ContainerSearch = Container
// export const EquipmentSearch = Equipment
