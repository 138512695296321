import { compareUpdateDates, isNewObject } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import * as R from 'remeda'
import { TransportationActivityViewDTO } from '../../../api/origin/business-logic'
import { ActivityViewDTO } from '../../../components/common/activity/interfaces'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { oc } from 'ts-optchain'

export const omitActivityFields: (keyof TransportationActivityViewDTO)[] = ['vendor', 'destination']

export const getActivityStatus = (activity: ActivityViewDTO) => {
  const store = getStore().getState()
  const storeActivity =
    store.activity.transportationActivities[activity.id] || store.activity.documentationActivities[activity.id]

  if (isNewObject(activity)) {
    return {
      noStoreDataForUpdate: true,
      needToSave: true,
      getDifference: undefined
    }
  }
  if (!storeActivity) {
    return {
      noStoreDataForUpdate: false,
      needToSave: false,
      getDifference: undefined
    }
  }

  const getLightObjects = () => {
    const lightActivity = R.omit(activity, omitActivityFields as any[])
    const lightStoreActivity = storeActivity ? R.omit(storeActivity, omitActivityFields as any[]) : {}

    return {
      newObject: omitUpdateInfo(lightActivity),
      oldObject: omitUpdateInfo(lightStoreActivity)
    }
  }

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(activity, storeActivity)
    },
    get needToSave() {
      const { newObject, oldObject } = getLightObjects()

      return oc(newObject).fullObject() && !isEqualObjects(oldObject, newObject)
    },
    get getDifference() {
      const { newObject, oldObject } = getLightObjects()
      return getObjectsDifference({ oldObject: newObject, newObject: oldObject })
    }
  }
}
