import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { getStore } from '../../../store/configureStore'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
}

export const assembleBusinessPartner = (props: Props): BusinessPartner => {
  const store = props.store || getStore().getState()
  const businessPartner = oc(store).businessPartner[props.id]()

  if (!businessPartner) {
    return null
  }

  const primaryContact = oc(store).contact[businessPartner.primaryContactId]()
  const contacts = oc(businessPartner)
    .contactIds([])
    .map(id => store.contact[id])
    .filter(Boolean)

  return {
    ...businessPartner,
    primaryContact,
    contacts
  }
}
