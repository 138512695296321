import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import styled from 'styled-components'
import { ActivityGroupAction } from '../../../../../../../../../../services/DTO/activity/controller'
import { activityStageColor } from '../../../styles'
import theme from '../../../../../../../../../../styles/theme'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
// tslint:disable:max-line-length
import { getActivityDropBobtailGroup } from '../../../../../../../../../../services/functions/get/getActivityDropBobtailGroup'
import { isEdgeActivityGroupIdOfStage } from '../../../../../../../../../../services/functions/test/isEdgeActivityGroupIdOfStage'
import { TransportationActivityViewDTO } from '../../../../../../../../../../api/api'

type Props = {}

export const ArrowsActivityColumn = (props: Props) => {
  const {
    transportationRow,
    updateActivityRow,
    troubleTicketId,
    dispatchDeliveryOrder,
    bobtailGroup,
    prevTransportationRowOfStage,
    nextTransportationRowOfStage,
    rows,
    stage
  } = useTransportationActivityRow()
  let permissionsMoveUp = transportationRow.permissions.moveUp
  let permissionsMoveDown = transportationRow.permissions.moveDown
  let hideMoveUp = false
  let hideMoveDown = false

  if (
    stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
    !transportationRow.activityGroup.gotoActivity.template
  ) {
    if (bobtailGroup && bobtailGroup.length > 1) {
      const bobtailRows = rows.filter(row => bobtailGroup.some(item => item.gotoActivity.id === row.id))

      if (bobtailGroup.some(group => group.gotoActivity.template)) {
        // has template in bobtail group
        permissionsMoveUp = false
        permissionsMoveDown = false
        hideMoveUp = true
        hideMoveDown = true
      } else if (oc(bobtailGroup)[0].gotoActivity.id() === transportationRow.activityGroup.gotoActivity.id) {
        // first in bobtail group
        permissionsMoveDown = false
        hideMoveDown = true
      } else if (
        oc(bobtailGroup)[bobtailGroup.length - 1].gotoActivity.id() === transportationRow.activityGroup.gotoActivity.id
      ) {
        // last in bobtail group
        permissionsMoveUp = false
        hideMoveUp = true
      } else {
        // middle in bobtail group
        permissionsMoveUp = false
        permissionsMoveDown = false
        hideMoveUp = true
        hideMoveDown = true
      }

      if (permissionsMoveUp) {
        permissionsMoveUp =
          !isEdgeActivityGroupIdOfStage({
            edge: 'first',
            groupId: transportationRow.activityGroup.businessActivity.groupId,
            stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
            activities: dispatchDeliveryOrder.activities.transportationActivities
          }) && bobtailRows.every(row => row.permissions.moveUp)
      }
      if (permissionsMoveDown) {
        permissionsMoveDown =
          !isEdgeActivityGroupIdOfStage({
            edge: 'last',
            groupId: oc(bobtailGroup)[bobtailGroup.length - 1].gotoActivity.groupId(),
            stage: TransportationActivityViewDTO.StageEnum.DELIVERY,
            activities: dispatchDeliveryOrder.activities.transportationActivities
          }) && bobtailRows.every(row => row.permissions.moveDown)
      }
    }

    if (permissionsMoveUp && prevTransportationRowOfStage) {
      const prevBobtailGroup = getActivityDropBobtailGroup({
        activity: prevTransportationRowOfStage.activityGroup.gotoActivity,
        activities: oc(dispatchDeliveryOrder).activities.transportationActivities()
      })

      if (prevBobtailGroup && prevBobtailGroup.length) {
        const prevBobtailRows = rows.filter(row => prevBobtailGroup.some(item => item.gotoActivity.id === row.id))
        permissionsMoveUp = prevBobtailRows.every(row => row.permissions.moveDown)
      }
    }

    if (permissionsMoveUp && nextTransportationRowOfStage) {
      const nextBobtailGroup = getActivityDropBobtailGroup({
        activity: nextTransportationRowOfStage.activityGroup.gotoActivity,
        activities: oc(dispatchDeliveryOrder).activities.transportationActivities()
      })

      if (nextBobtailGroup && nextBobtailGroup.length) {
        const nextBobtailRows = rows.filter(row => nextBobtailGroup.some(item => item.gotoActivity.id === row.id))
        permissionsMoveDown = nextBobtailRows.every(row => row.permissions.moveUp)
      }
    }
  }

  return (
    <Container>
      <ColorLine
        style={{
          backgroundColor: `${troubleTicketId ? theme.colors.defaultRed : activityStageColor[transportationRow.stage]}`
        }}
      />
      {transportationRow.activityGroup.gotoActivity.template || (hideMoveUp && hideMoveDown) ? null : (
        <ArrowsContainer>
          {hideMoveUp ? (
            <Arrow className={'disable'} />
          ) : (
            <Arrow
              data-id={'transportation-activity-group-button-arrow-up'}
              className={cn('mdi mdi-arrow-up-bold', { disable: !permissionsMoveUp })}
              onClick={
                permissionsMoveUp ? () => updateActivityRow(transportationRow, ActivityGroupAction.moveUp) : undefined
              }
            />
          )}
          {hideMoveDown ? (
            <Arrow className={'disable'} />
          ) : (
            <Arrow
              data-id={'transportation-activity-group-button-arrow-down'}
              className={cn('mdi mdi-arrow-down-bold', { disable: !permissionsMoveDown })}
              onClick={
                permissionsMoveDown
                  ? () => updateActivityRow(transportationRow, ActivityGroupAction.moveDown)
                  : undefined
              }
            />
          )}
        </ArrowsContainer>
      )}
    </Container>
  )
}

export const Container = styled.div`
  position: relative;
  flex: none;
  width: 16px;
  border: none !important;
`
const ColorLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px 0 0 5px;
`
const ArrowsContainer = styled.div`
  height: 100%;
  position: relative;
  font-size: 16px;
  color: white;
`
const Arrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  transition: background 0.25s;
  cursor: pointer;

  &:not(.disable):hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.disable {
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
  }

  &:first-child {
    border-radius: 5px 0 0 0;
  }
  &:last-child {
    border-radius: 0 0 0 5px;
  }
`
