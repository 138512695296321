import { DeliveryOrderViewDTO } from '../../../../api/origin/business-logic/api'
import { oc } from 'ts-optchain'
import { IDispatchDeliveryOrder } from '../interfaces'
import { TColumns } from '../../grid/views/interfaces'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { deliveryOrderDirectory } from '../../../../services/DTO/deliveryOrder/directory'
import { convertISODateToDateWithHoursMins } from '../../../../services/timeService/dateUtils'
import { ddoDirectory } from '../../../../services/DTO/dispatchDeliveryOrder/directory'
import { dateService } from '../../../../services/timeService'
import { activityDirectory } from '../../../../services/DTO/activity/directory'

export enum DispatchDeliveryOrderColumn {
  Notifications = 'Notifications',
  'DO created' = 'DO created',
  'DDO #' = 'DDO #',
  'DO #' = 'DO #',
  Status = 'Status',
  Type = 'Type',
  Equipment = 'Equipment',
  Container = 'Container',
  SSL = 'SSL',
  'Load Type' = 'Load Type',
  'Booking / BL' = 'Booking / BL',
  Customer = 'Customer',
  Chassis = 'Chassis',
  Pickup = 'Pickup',
  'Pickup: Driver' = 'Pickup: Driver',
  Delivery = 'Delivery',
  'Delivery: Driver' = 'Delivery: Driver',
  'Delivery: Activity' = 'Delivery: Activity',
  'Activity Planned' = 'Activity Planned',
  'Activity Completion' = 'Activity Completion',
  PickUpPlanned = 'Pick-up Planned',
  Appointment = 'Appointment',
  ReturnPlanned = 'Return Planned',
  'Appt Date Status' = 'Appt Date Status',
  Return = 'Return',
  'Return: Driver' = 'Return: Driver',
  'Days Out' = 'Days Out',
  'CutOff / LFD' = 'CutOff / LFD',
  LFD = 'LFD',
  'First Receiving' = 'First Receiving',
  'ETA' = 'ETA',
  Availability = 'Availability',
  'Per Diem Free by' = 'Per Diem Free by'
}

export const headers = {
  [DispatchDeliveryOrderColumn.Notifications]: { active: true, width: 100, minWidth: 100 },
  [DispatchDeliveryOrderColumn['DO created']]: { active: true, width: 120 },
  [DispatchDeliveryOrderColumn['DDO #']]: { active: true, width: 80 },
  [DispatchDeliveryOrderColumn['DO #']]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Status]: { active: true, width: 245, minWidth: 245 },
  [DispatchDeliveryOrderColumn.Type]: { active: true, width: 100 },
  [DispatchDeliveryOrderColumn.Equipment]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Container]: { active: true, width: 140, minWidth: 140 },
  [DispatchDeliveryOrderColumn.SSL]: { active: true, width: 140, minWidth: 140 },
  [DispatchDeliveryOrderColumn['Load Type']]: { active: true, width: 90, minWidth: 90 },
  [DispatchDeliveryOrderColumn['Booking / BL']]: { active: true, width: 110 },
  [DispatchDeliveryOrderColumn.Customer]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.Chassis]: { active: true, width: 150 },
  [DispatchDeliveryOrderColumn.Pickup]: { active: true, width: 130 },
  [DispatchDeliveryOrderColumn['Pickup: Driver']]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.PickUpPlanned]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.Delivery]: { active: true, width: 300 },
  [DispatchDeliveryOrderColumn['Delivery: Driver']]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn['Delivery: Activity']]: { active: true, width: 190 },
  [DispatchDeliveryOrderColumn['Activity Planned']]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn['Activity Completion']]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn.Appointment]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn['Appt Date Status']]: { active: true, width: 120, minWidth: 120 },
  [DispatchDeliveryOrderColumn.Return]: { active: true, width: 130 },
  [DispatchDeliveryOrderColumn['Return: Driver']]: { active: true, width: 140 },
  [DispatchDeliveryOrderColumn.ReturnPlanned]: { active: true, width: 160 },
  [DispatchDeliveryOrderColumn['Days Out']]: { active: true, width: 95, minWidth: 95 },
  [DispatchDeliveryOrderColumn['First Receiving']]: { active: true, width: 250 },
  [DispatchDeliveryOrderColumn['CutOff / LFD']]: { active: true, width: 160, headerWidth: 320 },
  [DispatchDeliveryOrderColumn.LFD]: { active: true, width: 160, headerWidth: 0 },
  [DispatchDeliveryOrderColumn.ETA]: { active: true, width: 250 },
  [DispatchDeliveryOrderColumn.Availability]: { active: false, width: 250 },
  [DispatchDeliveryOrderColumn['Per Diem Free by']]: { active: false, width: 250 }
}

export const adaptRowValues = (dispatchDeliveryOrder: IDispatchDeliveryOrder): TColumns => {
  const columnValues = {
    [DispatchDeliveryOrderColumn.Notifications]: dispatchDeliveryOrder,
    [DispatchDeliveryOrderColumn['DO created']]: dateService.makeLabel(oc(dispatchDeliveryOrder).deliveryOrder.date()),
    [DispatchDeliveryOrderColumn['DDO #']]: dispatchDeliveryOrder,
    [DispatchDeliveryOrderColumn['DO #']]: dispatchDeliveryOrder.deliveryOrder,
    [DispatchDeliveryOrderColumn.Status]: dispatchDeliveryOrder,
    [DispatchDeliveryOrderColumn.Type]: deliveryOrderDirectory.columnType[dispatchDeliveryOrder.deliveryOrder.type],
    [DispatchDeliveryOrderColumn.Equipment]: oc(dispatchDeliveryOrder).containerType.code(''),
    [DispatchDeliveryOrderColumn.Container]: dispatchDeliveryOrder,
    [DispatchDeliveryOrderColumn.SSL]: oc(dispatchDeliveryOrder).deliveryOrder.steamShipLine.name(''),
    [DispatchDeliveryOrderColumn['Load Type']]: ddoDirectory.loadType[dispatchDeliveryOrder.loadType],
    [DispatchDeliveryOrderColumn['Booking / BL']]:
      dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT ||
      dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? dispatchDeliveryOrder.deliveryOrder.bookingNumber
        : dispatchDeliveryOrder.deliveryOrder.billOfLadingNumber,
    [DispatchDeliveryOrderColumn.Customer]:
      oc(dispatchDeliveryOrder as any).deliveryOrder.customer.businessPartner.legalName('') ||
      oc(dispatchDeliveryOrder).deliveryOrder.customer.name(''),
    [DispatchDeliveryOrderColumn.Chassis]: oc(dispatchDeliveryOrder).equipment.chassisNumber(''),
    [DispatchDeliveryOrderColumn.Pickup]: {
      location: oc(dispatchDeliveryOrder).pickupStage.location(),
      isShuttle: oc(dispatchDeliveryOrder).pickupStage.shuttle(false)
    },
    [DispatchDeliveryOrderColumn['Pickup: Driver']]: oc(dispatchDeliveryOrder).pickupStage.vendor.id(),
    [DispatchDeliveryOrderColumn.Delivery]: oc(dispatchDeliveryOrder).deliveryStage.activity.destination(),
    [DispatchDeliveryOrderColumn['Delivery: Driver']]: oc(dispatchDeliveryOrder).deliveryStage.activity.vendor.id(),
    [DispatchDeliveryOrderColumn['Delivery: Activity']]:
      oc(dispatchDeliveryOrder).deliveryStage.activity.comboType() &&
      activityDirectory.type[dispatchDeliveryOrder.deliveryStage.activity.comboType],
    [DispatchDeliveryOrderColumn['Activity Planned']]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryStage.activity.startPlannedDateTimeRange()
    ),
    [DispatchDeliveryOrderColumn['Activity Completion']]: convertISODateToDateWithHoursMins(
      oc(dispatchDeliveryOrder).deliveryStage.activity.completionActualDate(),
      false,
      false
    ),
    [DispatchDeliveryOrderColumn.PickUpPlanned]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange()
    ),
    [DispatchDeliveryOrderColumn.Appointment]: {
      date:
        convertISODateToDateWithHoursMins(
          oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
          false,
          false
        ) || dateService.makeLabel(oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()),
      confirmed: oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed(true)
    },
    [DispatchDeliveryOrderColumn.ReturnPlanned]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange()
    ),
    [DispatchDeliveryOrderColumn['Appt Date Status']]: {
      confirmed: oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed(),
      disabled: Boolean(oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate())
    },
    [DispatchDeliveryOrderColumn.Return]: {
      location: oc(dispatchDeliveryOrder).returnStage.location(),
      isShuttle: oc(dispatchDeliveryOrder).returnStage.shuttle(false)
    },
    [DispatchDeliveryOrderColumn['Return: Driver']]: oc(dispatchDeliveryOrder).returnStage.vendor.id(),
    [DispatchDeliveryOrderColumn['Days Out']]: oc(dispatchDeliveryOrder).daysOut(),
    [DispatchDeliveryOrderColumn.Availability]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.equipmentFirstPickupDate()
    ),
    [DispatchDeliveryOrderColumn['CutOff / LFD']]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.generalCutoffDate()
    ),
    [DispatchDeliveryOrderColumn.LFD]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.lastFreeDateDemurrage()
    ),
    [DispatchDeliveryOrderColumn['Per Diem Free by']]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.lastFreeDatePerDiem()
    ),
    [DispatchDeliveryOrderColumn['First Receiving']]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.firstReceivingDate()
    ),
    [DispatchDeliveryOrderColumn.ETA]: dateService.makeLabel(
      oc(dispatchDeliveryOrder).deliveryOrder.vesselArrivalDate()
    )
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
