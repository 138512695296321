import { CustomerQuoteDTO } from '../../../../api/origin/qmp-service'
import { getStore } from '../../../../store/configureStore'
import { callAPI, customerAPI, customerQuoteAPI, locationAPI } from '../../../../api/api'
import { addCustomerQuotesToStore } from '../actions'
import { TNewCustomerQuotes } from '../interfaces'
import {
  createCustomerQuoteFilters,
  matchCustomerQuotesToSelectedRates
} from '../../../../components/common/QMP/views/Tabs/CustomerQuote/functions'
import {
  catchDuplicatesError,
  promiseFetchObjectsByIdAndPushToStore
} from '../../../../components/common/QMP/views/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../parseDTO'

export const tryAddCustomerQuotesToStore = (items: CustomerQuoteDTO | CustomerQuoteDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().customerQuotes, items, addCustomerQuotesToStore)
}

export const getCustomerQuotes = async (filters: any) => {
  return callAPI(customerQuoteAPI.getCustomerQuotes, createCustomerQuoteFilters(filters))
    .toPromise()
    .then(customerQuotes => {
      tryAddCustomerQuotesToStore(customerQuotes)
      return customerQuotes
    })
}

export const getCustomerQuoteById = (id: string): Promise<CustomerQuoteDTO> =>
  callAPI(customerQuoteAPI.getCustomerQuote, id)
    .toPromise()
    .then(resolvedCustomerQuote => {
      tryAddCustomerQuotesToStore(resolvedCustomerQuote)
      return resolvedCustomerQuote
    })

export const getCustomerQuote = async (customerQuoteId: string): Promise<CustomerQuoteDTO> => {
  const { getState } = getStore()
  const store = getState()
  const customerQuote = store.customerQuotes[customerQuoteId]
  const doRequest = customerQuoteId && !customerQuote

  if (doRequest) {
    return getCustomerQuoteById(customerQuoteId)
  }

  return customerQuote
}

export const createCustomerQuotes = async (
  newCustomerQuotes: TNewCustomerQuotes,
  forceSaving?: boolean
): Promise<{
  resolvedCustomerQuotes: CustomerQuoteDTO[]
  rejectedCustomerQuotes: CustomerQuoteDTO[]
  duplicateCustomerQuotes?: CustomerQuoteDTO[]
}> => {
  const allCustomerQuotesVariants = matchCustomerQuotesToSelectedRates(newCustomerQuotes)
  let resolvedCustomerQuotes: CustomerQuoteDTO[] = []
  let rejectedCustomerQuotes: CustomerQuoteDTO[] = []
  let duplicateCustomerQuotes: CustomerQuoteDTO[] = []

  await callAPI(
    customerQuoteAPI.batchCreateCustomerQuote,
    Boolean(forceSaving),
    allCustomerQuotesVariants.map(customerQuote => ({ ...customerQuote, id: undefined }))
  )
    .toPromise()
    .then(customerQuotes => {
      tryAddCustomerQuotesToStore(customerQuotes)
      const store = getStore().getState()
      const fetchLocationIds: string[] = []
      const fetchCustomerIds: string[] = []

      customerQuotes.forEach(customerQuote => {
        fetchLocationIds.push(customerQuote.pickupLocationId, customerQuote.returnLocationId)
        fetchCustomerIds.push(customerQuote.customerId)
      })

      promiseFetchObjectsByIdAndPushToStore(
        fetchLocationIds,
        locationAPI.findById,
        parseDTO.location,
        true,
        store.location
      )
      promiseFetchObjectsByIdAndPushToStore(
        fetchCustomerIds,
        customerAPI.findById,
        parseDTO.customer,
        true,
        store.customer
      )
      resolvedCustomerQuotes = customerQuotes
    })
    .catch(async errors => {
      await catchDuplicatesError(allCustomerQuotesVariants, (ids: string[]) =>
        callAPI(customerQuoteAPI.getCustomerQuotes, 'id%%' + ids.join(',')).toPromise()
      )(errors).then(({ rejectedItems, duplicateItems }) => {
        rejectedCustomerQuotes = rejectedItems
        duplicateCustomerQuotes = duplicateItems
      })
    })

  return { resolvedCustomerQuotes, rejectedCustomerQuotes, duplicateCustomerQuotes }
}
