import * as React from 'react'
import { connect } from 'react-redux'
import { MenuItem, StyledMainMenu, Title } from './styledComponents'
import { createNewTab } from '../tabs/actions'
import { ApplicationContext, IStore } from '../../../store/store.interface'
import { changeApplicationContext } from '../../../services/applicationContextService/actions'

type OwnProps = {
  closeMenu: () => void
}

type StateProps = {
  applicationContext: ApplicationContext
}

type DispatchProps = {
  changeApplicationContext: typeof changeApplicationContext
}

type Props = OwnProps & StateProps & DispatchProps

export const MainMenu = connect(
  (state: IStore) => ({ applicationContext: state.applicationContext }),
  { addNewTab: createNewTab, changeApplicationContext }
)((props: Props) => {
  const { applicationContext, closeMenu } = props

  const setApplicationContext = (context: ApplicationContext) => () => {
    if (applicationContext !== context) {
      props.changeApplicationContext(context)
      closeMenu()
    }
  }

  return (
    <StyledMainMenu columns={11} isGrid={true}>
      <Title rows={6} onClick={setApplicationContext(ApplicationContext.main)}>
        Application
      </Title>
      <Title rows={6} onClick={setApplicationContext(ApplicationContext.admin)}>
        Settings
      </Title>
      {/*{tabType.admin.map((collection, index) => (*/}
      {/*<MenuItem*/}
      {/*key={index}*/}
      {/*rows={6}*/}
      {/*onClick={() => {*/}
      {/*addNewTab({ tabType: collection.callback, tabId: nanoid() })*/}
      {/*props.changeApplicationContext(ApplicationContext.admin)*/}
      {/*closeMenu()*/}
      {/*}}*/}
      {/*>*/}
      {/*{collection.name}*/}
      {/*</MenuItem>*/}
      {/*))}*/}
    </StyledMainMenu>
  )
})
