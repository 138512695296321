import { correctInsertionObjectsIntoStorage } from '../../index'
import { getStore } from '../../../../store/configureStore'
import { addSSQsToStore, deleteSSQIds } from '../actions'
import { SellSideQuoteDTO } from '../../../../api/origin/business-logic'

export const tryAddSSQsToStore = (items: SellSideQuoteDTO | SellSideQuoteDTO[], deleteIds?: string[]) => {
  const { getState, dispatch } = getStore()
  const sellSideQuotes = getState().sellSideQuotes
  correctInsertionObjectsIntoStorage(sellSideQuotes, items, addSSQsToStore)

  if (deleteIds && deleteIds.some(id => sellSideQuotes[id])) {
    dispatch(deleteSSQIds(deleteIds))
  }
}
