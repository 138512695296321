import * as React from 'react'
import { oc } from 'ts-optchain'
import { GridColumn, Column, Container } from '../../../../../UI/GridSystem'
import { Select } from '../../../../../UI/DataFields/Select'
import { Input, InputField } from '../../../../../UI/DataFields/Input'
import { DateISOString } from '../../../../../../api/origin/business-logic'
import { IPowerUnit } from '../../../interfaces'
import { Date, Title } from '../Credentials/styledComponents'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { StyledGeneral } from './styledComponents'
import { createValidator } from '../../../../../../services/DTO/deliveryOrder'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { Checkbox } from '../../../../../UI/Checkbox'
import { powerUnitLists } from '../../../../../../services/select/powerUnitLists'
import { commonLists } from '../../../../../../services/select/commonLists'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { MultiSelectDriver } from './MultiSelectDriver'
import { FileVersionDTO } from '../../../../../../api/origin/document-service'

type OwnProps = {
  actions: IGridItemActions
  powerUnit: IPowerUnit
}

type DispatchProps = {}

type Props = DispatchProps & OwnProps

const isValidMake = createValidator(2, 64)
const isValidModel = createValidator(2, 64)

const General: React.SFC<Props> = ({ powerUnit, actions: { modify, modifyParentObjectField, setFetching } }) => (
  <StyledGeneral minRows={70}>
    <GridColumn rows={61} flexBasis={'auto'} padding={{ top: 25, left: 50, right: 50 }}>
      <Column columns={10} isGrid={true} margin={{ right: 80 }}>
        <Select
          title={'Type'}
          required={true}
          label={oc(powerUnitLists.powerUnitType.find(_ => _.value === (powerUnit.powerUnitType as any))).htmlLabel(
            null
          )}
          selectedValue={powerUnit.powerUnitType}
          list={powerUnitLists.powerUnitType}
          onSelect={modifyParentObjectField('powerUnitType')}
        />
        <Container margin={{ top: 20, bottom: 20 }}>
          <Input
            title={'SubType'}
            required={true}
            maxLength={250}
            value={powerUnit.powerUnitSubType}
            onChange={modifyParentObjectField('powerUnitSubType')}
          />
        </Container>
        <Container margin={{ bottom: 20 }}>
          <Input title={'Specs'} value={powerUnit.specs} maxLength={1024} onChange={modifyParentObjectField('specs')} />
        </Container>
        <FieldContainer title={'Driver'}>
          <MultiSelectDriver
            selectedDrivers={powerUnit.drivers}
            onDriverAdd={driver => {
              modify({
                ...powerUnit,
                driverIds: (powerUnit.drivers || []).map(({ id }) => id).concat(driver.id),
                drivers: (powerUnit.drivers || []).concat(driver)
              })
            }}
            onDriverIdRemove={driverId => {
              modify({
                ...powerUnit,
                driverIds: (powerUnit.drivers || []).map(({ id }) => id).filter(id => id !== driverId),
                drivers: (powerUnit.drivers || []).filter(({ id }) => id !== driverId)
              })
            }}
          />
        </FieldContainer>
      </Column>

      <Column columns={12} isGrid={true} margin={{ right: 80 }}>
        <InputField.TextMask
          title={'Vehicle ID Number (VIN)'}
          required={true}
          valid={powerUnit.vin && powerUnit.vin.length >= 11}
          value={powerUnit.vin}
          transformOnInputChange={value => (value ? value.toUpperCase() : value)}
          textMask={[
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/,
            /(\d|[a-zA-Z])/
          ]}
          onChange={modifyParentObjectField('vin')}
        />
        <Container margin={{ top: 20, bottom: 20 }}>
          <Input
            title={'Make'}
            required={true}
            value={powerUnit.make}
            maxLength={64}
            valid={isValidMake(powerUnit.make)}
            onChange={modifyParentObjectField('make')}
          />
        </Container>
        <Input
          title={'Model'}
          required={true}
          maxLength={64}
          valid={isValidModel(powerUnit.model)}
          value={powerUnit.model}
          onChange={modifyParentObjectField('model')}
        />
        <Container margin={{ top: 20 }}>
          <Container>
            <InputField.Numeric
              title={'Year'}
              required={true}
              maxLength={4}
              valid={powerUnit.year >= 1900 && powerUnit.year <= 2100}
              placeholder={'2001'}
              value={powerUnit.year}
              onChange={modifyParentObjectField('year')}
            />
          </Container>
          <Container margin={{ left: 20, right: 20 }}>
            <InputField.ThousandSeparator
              title={'GVW'}
              required={true}
              placeholder={'00000'}
              value={powerUnit.weight}
              onChange={modifyParentObjectField('weight')}
            />
          </Container>
          <Container>
            <Select
              title={'Unit'}
              required={true}
              selectedValue={powerUnit.weightUnit}
              list={commonLists.weightUnit}
              onSelect={modifyParentObjectField('weightUnit')}
            />
          </Container>
        </Container>
      </Column>

      <Column columns={10} isGrid={true} margin={{ right: 80 }}>
        <InputField.TextMask
          title={'License Plate'}
          required={true}
          valid={powerUnit.licensePlate && powerUnit.licensePlate.length >= 6}
          value={powerUnit.licensePlate}
          transformOnInputChange={value => (value ? value.toUpperCase() : value)}
          textMask={[
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/,
            /(\d|[a-zA-Z]|-)/
          ]}
          onChange={modifyParentObjectField('licensePlate')}
        />
        <Container margin={{ top: 20, bottom: 20 }}>
          <FieldContainer title={'Port Pass RFID'}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px' }}>
                <Checkbox
                  checked={Boolean(powerUnit.portPassRfid)}
                  onChange={state => modify({ ...powerUnit, portPassRfid: state, rfid: '' })}
                />
              </div>
              <Input
                disabled={!powerUnit.portPassRfid}
                value={powerUnit.rfid}
                onChange={modifyParentObjectField('rfid')}
              />
            </div>
          </FieldContainer>
        </Container>
        <Container>
          <Select
            title={'Ownership'}
            required={true}
            selectedValue={powerUnit.ownership}
            list={powerUnitLists.ownership}
            onSelect={modifyParentObjectField('ownership')}
          />
        </Container>
        <Container margin={{ top: 20 }}>
          <Select
            title={'Truck Type'}
            required={true}
            selectedValue={powerUnit.truckType}
            list={powerUnitLists.truckType}
            onSelect={modifyParentObjectField('truckType')}
          />
        </Container>
      </Column>

      <Column columns={9} isGrid={true} padding={{ top: 30 }} margin={{ right: 40 }}>
        <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 20 }}>
          Registration
        </Title>
        <FieldTemplate.StateSearch
          required={true}
          title={'State'}
          stateId={oc(powerUnit).registration.stateId(null)}
          onChange={stateId => modifyParentObjectField('registration')({ ...powerUnit.registration, stateId })}
        />
        <Date margin={{ top: 20, bottom: 20 }}>
          <DateField
            title={'Date'}
            required={true}
            date={powerUnit.registration.registeredDate}
            dateType={DateTypes.DateISOString}
            placeholder={'MM/DD/YYYY'}
            onUpdate={(registeredDate: DateISOString) =>
              modifyParentObjectField('registration')({ ...powerUnit.registration, registeredDate })
            }
          />
        </Date>
        <Container>
          <Container columns={4.5} isGrid={true} margin={{ right: 20 }}>
            <InputField.ThousandSeparator
              title={'GVW'}
              required={true}
              placeholder={'00000'}
              value={powerUnit.registration.weight}
              onChange={weight =>
                modifyParentObjectField('registration')({
                  ...powerUnit.registration,
                  weight: weight ? parseInt(weight, 10) : null
                })
              }
            />
          </Container>

          <Container columns={3.5} isGrid={true}>
            <Select
              title={'Unit'}
              required={true}
              selectedValue={powerUnit.registration.weightUnit}
              list={commonLists.weightUnit}
              onSelect={weightUnit =>
                modifyParentObjectField('registration')({ ...powerUnit.registration, weightUnit })
              }
            />
          </Container>
        </Container>
      </Column>

      <Column columns={9} isGrid={true} padding={{ top: 75 }}>
        <Container>
          <Select
            title={'Restrictions'}
            required={true}
            selectedValue={powerUnit.registration.restrictions}
            list={powerUnitLists.restrictions}
            onSelect={restrictions =>
              modifyParentObjectField('registration')({ ...powerUnit.registration, restrictions })
            }
          />
        </Container>
        <Date margin={{ top: 20, bottom: 20 }}>
          <DateField
            title={'Exp. Date'}
            required={true}
            date={powerUnit.registration.expirationDate}
            dateType={DateTypes.DateISOString}
            placeholder={'MM/DD/YYYY'}
            onUpdate={(expirationDate: DateISOString) =>
              modifyParentObjectField('registration')({ ...powerUnit.registration, expirationDate })
            }
          />
        </Date>
        <ViewingObjectFileUploader
          required={true}
          uploadingFileId={powerUnit.id + ' powerUnit.registration.documentFile'}
          title={'Doc. Image'}
          file={powerUnit.registration.documentFile}
          historyProps={{ entityId: powerUnit.id, type: FileVersionDTO.TypeEnum.MACHINERYREGISTRATION }}
          onFileUploaded={(documentFile, modifiedObject = powerUnit) => ({
            ...modifiedObject,
            registration: { ...modifiedObject.registration, documentFile }
          })}
          onClearFile={() =>
            modifyParentObjectField('registration')({ ...powerUnit.registration, documentFile: undefined })
          }
        />
      </Column>
    </GridColumn>
  </StyledGeneral>
)

export default General
