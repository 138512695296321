import * as dispatchDeliveryOrderGrid from '../../common/dispatchDeliveryOrder/views/configGrid'
import * as driverGrid from '../../common/drivers/views/configGrid'
import * as locationGrid from '../../common/location/views/configGrid'
import * as powerUnitGrid from '../../common/powerUnit/views/configGrid'
import * as equipmentGrid from '../../common/equipment/views/configGrid'
import * as containerGrid from '../../common/containers/views/configGrid'
import * as customerGrid from '../../common/customer/views/configGrid'
import * as steamShipLineGrid from '../../common/steamShipLine/views/configGrid'
import * as subClientGrid from '../../common/subClient/views/configGrid'
import * as usersGrid from '../../common/administration/users/views/configGrid'
import LocationColumn from '../../common/location/views/Column/'
import PowerUnitColumn from '../../common/powerUnit/views/Column/'
import EquipmentColumn from '../../common/equipment/views/Column/'
import ContainerColumn from '../../common/containers/views/Column/'
import DriverColumn from '../../common/drivers/views/Column/'
import CustomerColumn from '../../common/customer/views/Column/'
import SteamShipLineColumn from '../../common/steamShipLine/views/Column/'
import SubClientColumn from '../../common/subClient/views/Column/'
import dispatchDeliveryOrderColumn from '../../common/dispatchDeliveryOrder/views/Column/'
import UsersColumn from '../../common/administration/users/views/Column'
import { LocationPreview } from '../../common/location/views/Preview'
import { PowerUnitPreview } from '../../common/powerUnit/views/Preview'
import { EquipmentPreview } from '../../common/equipment/views/Preview'
import { ContainerPreview } from '../../common/containers/views/Preview'
import { DriverPreview } from '../../common/drivers/views/Preview'
import { CustomerPreview } from '../../common/customer/views/Preview'
import { SteamShipLinePreview } from '../../common/steamShipLine/views/Preview'
import { SubClientPreview } from '../../common/subClient/views/Preview'
import { DispatchDeliveryOrderPreview } from '../../common/dispatchDeliveryOrder/views/Preview'
import { UsersPreview } from '../../common/administration/users/views/Preview'
import { TabType } from '../../common/tabs/interfaces'

export const adaptRowValues = {
  [TabType.location]: locationGrid.adaptRowValues,
  [TabType.powerUnit]: powerUnitGrid.adaptRowValues,
  [TabType.equipment]: equipmentGrid.adaptRowValues,
  [TabType.container]: containerGrid.adaptRowValues,
  [TabType.driver]: driverGrid.adaptRowValues,
  [TabType.customer]: customerGrid.adaptRowValues,
  [TabType.steamShipLine]: steamShipLineGrid.adaptRowValues,
  [TabType.subClient]: subClientGrid.adaptRowValues,
  [TabType.dispatchDeliveryOrder]: dispatchDeliveryOrderGrid.adaptRowValues,
  [TabType.users]: usersGrid.adaptRowValues
}

export const columnSettings = {
  [TabType.dispatchDeliveryOrder]: dispatchDeliveryOrderGrid.headers,
  [TabType.driver]: driverGrid.headers,
  [TabType.container]: containerGrid.headers,
  [TabType.powerUnit]: powerUnitGrid.headers,
  [TabType.equipment]: equipmentGrid.headers,
  [TabType.location]: locationGrid.headers,
  [TabType.customer]: customerGrid.headers,
  [TabType.steamShipLine]: steamShipLineGrid.headers,
  [TabType.subClient]: subClientGrid.headers,
  [TabType.users]: usersGrid.headers
}

export const columnsValues = {
  [TabType.location]: LocationColumn,
  [TabType.powerUnit]: PowerUnitColumn,
  [TabType.equipment]: EquipmentColumn,
  [TabType.container]: ContainerColumn,
  [TabType.driver]: DriverColumn,
  [TabType.customer]: CustomerColumn,
  [TabType.steamShipLine]: SteamShipLineColumn,
  [TabType.subClient]: SubClientColumn,
  [TabType.dispatchDeliveryOrder]: dispatchDeliveryOrderColumn,
  [TabType.users]: UsersColumn
}

export const gridPreviews = {
  [TabType.location]: LocationPreview,
  [TabType.powerUnit]: PowerUnitPreview,
  [TabType.equipment]: EquipmentPreview,
  [TabType.container]: ContainerPreview,
  [TabType.driver]: DriverPreview,
  [TabType.customer]: CustomerPreview,
  [TabType.steamShipLine]: SteamShipLinePreview,
  [TabType.subClient]: SubClientPreview,
  [TabType.dispatchDeliveryOrder]: DispatchDeliveryOrderPreview,
  [TabType.users]: UsersPreview
}

export const gridItemHeaders = {
  [TabType.location]: LocationPreview.headings,
  [TabType.powerUnit]: PowerUnitPreview.headings,
  [TabType.equipment]: EquipmentPreview.headings,
  [TabType.container]: ContainerPreview.headings,
  [TabType.driver]: DriverPreview.headings,
  [TabType.customer]: CustomerPreview.headings,
  [TabType.steamShipLine]: SteamShipLinePreview.headings,
  [TabType.subClient]: SubClientPreview.headings,
  [TabType.dispatchDeliveryOrder]: DispatchDeliveryOrderPreview.headings,
  [TabType.users]: UsersPreview.headings
}
