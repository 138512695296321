import { oc } from 'ts-optchain'

export const filterNewListItems = (props: {
  newListItems: any[] | undefined
  prevListItems?: Record<string, any>
}): Record<string, any> | undefined => {
  const { newListItems, prevListItems } = props

  if (!(newListItems && newListItems.length)) {
    return
  }

  const getLatestOrForce = (items: any[], storeItem: any): object => {
    let latestItem: any = storeItem

    items
      .slice()
      .reverse()
      .forEach(newItem => {
        if (!latestItem) {
          latestItem = newItem
          return
        }

        const notVersionData = newItem.version === undefined && newItem.updatedAt === undefined

        // no version & no updatedAt – get latestItem
        if (notVersionData && newItem.forceUpdate && !latestItem.forceUpdate) {
          latestItem = newItem
          return
        }
        if (notVersionData) {
          return
        }

        // version
        if (latestItem.version !== undefined && latestItem.version < newItem.version) {
          latestItem = newItem
          return
        }

        // updatedAt
        if (newItem.updatedAt && (!latestItem.updatedAt || newItem.updatedAt > latestItem.updatedAt)) {
          latestItem = newItem
          return
        }

        // forceUpdate
        if (
          newItem.forceUpdate &&
          !latestItem.forceUpdate &&
          latestItem.version === newItem.version &&
          latestItem.updatedAt === newItem.updatedAt
        ) {
          latestItem = newItem
          return
        }

        if (!latestItem.fullObject && newItem.fullObject) {
          latestItem = newItem
          return
        }
      })

    if (latestItem.forceUpdate) {
      delete latestItem.forceUpdate
    }

    return latestItem
  }

  const newListItemsByID = newListItems.reduce((acc, curr) => {
    if (!(curr && curr.id)) {
      return acc
    }

    if (!acc[curr.id]) {
      acc[curr.id] = []
    }

    acc[curr.id].push(curr)
    return acc
  }, {})

  return Object.keys(newListItemsByID).reduce((acc, id) => {
    acc[id] = getLatestOrForce(newListItemsByID[id], oc(prevListItems)[id]())
    return acc
  }, {})
}
