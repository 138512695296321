import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { NEVER } from 'rxjs'
import { Action } from 'typescript-fsa'
import { IStore, IUiSettingsSettingsStoreState } from '../../../../store/store.interface'
import ofActions from '../../../../services/rxjsService/operators/ofActions'
import {
  setActiveTabId,
  createDOTab,
  createNewTab,
  duplicateTab,
  moveTab,
  openDOTab,
  removeAllTabsExcept,
  removeRightTabs,
  removeTab,
  updateTab,
  setTabData,
  updateTabData,
  updateTabSettings,
  updateTabFilters,
  updateTabColumns,
  updateTabSorting
} from '../actions'
import { ITabState, TabType } from '../interfaces'
import { tabSessionStorage } from '../../../../services/tabs/functions'
import * as uiSettingsServiceActions from '../../../../services/uiSettingsService/actions'
import { settingsPerTabType } from '../../../../services/uiSettingsService/tabs'
import { getStore } from '../../../../store/configureStore'
import { setTabViewingObject } from '../../../../services/viewingObjects/actions'
import { createEntity } from '../../../../services/DTO/createNewDTO'
import { getUserState } from '../../../../store'

export const addNewGridItem = ({
  tabId,
  newGridItem,
  unitType
}: {
  tabId: string
  newGridItem?: any
  unitType: TabType
}) => {
  const newObject = newGridItem || createEntity(unitType)

  if (!newObject) {
    return
  }

  getStore().dispatch(setTabViewingObject({ tabId, viewingObject: { id: newObject.id, modifiedObject: newObject } }))
}

const makeSettings = (store: IStore): IUiSettingsSettingsStoreState => {
  const correctTab = (tab: ITabState): ITabState => {
    switch (tab.type) {
      case TabType.QMP:
        return {
          ...tab,
          visited: false,
          spinner: false,
          data: {
            ...tab.data,
            openedCustomerQuoteId: null,
            ssqIds: [],
            bsqIds: [],
            customerQuoteIds: [],
            newSSQRates: [],
            newBSQRates: [],
            newCustomerQuotes: null,
            ratesTabVisited: false,
            customerQuotesTabVisited: false
          }
        }
      default:
        return {
          ...tab,
          gridItemIds: null,
          visited: false,
          spinner: false
        }
    }
  }

  let activeMainTabId = store.tabs.activeMainTabId
  const mainTabs = store.tabs.main.filter(({ storeTab }) => storeTab)

  // set active for last item
  if (!activeMainTabId) {
    activeMainTabId = mainTabs[mainTabs.length - 1].id
  }

  return {
    activeMainTabId,
    main: mainTabs.map(correctTab),
    admin: store.tabs.admin.map(correctTab),
    version: Date.now()
  }
}

const updateTabsEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<IStore>) =>
  action$.pipe(
    ofActions([
      createNewTab,
      setActiveTabId,
      openDOTab,
      removeAllTabsExcept,
      updateTab,
      setTabData,
      updateTabData,
      moveTab,
      duplicateTab,
      removeTab,
      removeRightTabs,
      createDOTab,
      updateTabSettings,
      updateTabFilters,
      updateTabColumns,
      updateTabSorting
    ]),
    mergeMap(_ => {
      const store = store$.value

      if (!getUserState()) {
        return NEVER
      }

      tabSessionStorage.tabs.set(makeSettings(store))

      return NEVER
    })
  )

const createTabSettingsEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<IStore>) =>
  action$.pipe(
    ofActions([createNewTab]),
    mergeMap(({ payload: { tabId, tabType, settings } }) => {
      if (!getUserState()) {
        return NEVER
      }
      return [
        uiSettingsServiceActions.addByTabId({
          tabId,
          settings: settings || settingsPerTabType[tabType]
        })
      ]
    })
  )

const removeTabSettingsEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<IStore>) =>
  action$.pipe(
    ofActions([removeTab]),
    mergeMap(({ payload: { tabId } }) => {
      if (!getUserState()) {
        return NEVER
      }

      return [
        uiSettingsServiceActions.removeByTabId({
          tabId
        })
      ]
    })
  )

export const tabsEpic = combineEpics(updateTabsEpic, createTabSettingsEpic, removeTabSettingsEpic)
