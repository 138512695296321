import { RateTable } from '../../common/body'
import * as React from 'react'
import { CombinedSurchargeRateDTO, SurchargeDTO } from '../../../types'
import { connect } from 'react-redux'
import { IStore } from '../../../../../../../../../store/store.interface'
import { makeCombinedSurchargeRate } from '../../functions'
import { RateDTO } from '../../../../../../../../../services/DTO/rate/interfaces'
import { SellSideQuoteSurchargeDTO } from '../../../../../../../../../api/origin/business-logic'

type OwnProps = {
  ownBuySideQuoteSurcharges: SurchargeDTO[]
  updateSurchargeQuantity: (rateId: string, quantity: number) => void
  openRateNumber: (rateNumber: string) => void
}

type StateProps = {
  surchargesFullData?: CombinedSurchargeRateDTO[]
}

type Props = OwnProps & StateProps

const Component = (props: Props) => {
  const { surchargesFullData, openRateNumber, updateSurchargeQuantity } = props
  if (surchargesFullData.length === 0) {
    return null
  }

  const calculateBasePlusFuel = [
    SellSideQuoteSurchargeDTO.TypeEnum.BASE,
    SellSideQuoteSurchargeDTO.TypeEnum.FUEL
  ].every(type => surchargesFullData.find(_ => _.type === type))

  return (
    <div
      style={{
        height: 30 + 16 + 50 * surchargesFullData.length + (calculateBasePlusFuel ? 46 : 0),
        display: 'flex',
        flexDirection: 'column',
        transform: 'translateY(-1px)'
      }}
    >
      <RateTable
        calculateBasePlusFuel={calculateBasePlusFuel}
        rates={surchargesFullData}
        toggleRate={undefined}
        updateSurchargeActivityId={undefined}
        updateSurchargeQuantity={updateSurchargeQuantity}
        openRateNumber={openRateNumber}
      />
    </div>
  )
}

export const OwnBuySideQuoteSurcharges = connect((store: IStore, props: OwnProps) => {
  const surchargesFullData = props.ownBuySideQuoteSurcharges
    .map(surcharge => {
      const rate = store.ssqRates[surcharge.rateId] || store.bsqRates[surcharge.rateId]

      return rate ? makeCombinedSurchargeRate(rate as RateDTO, surcharge) : (surcharge as CombinedSurchargeRateDTO)
    })
    .sort((a, b) => (a.type === SellSideQuoteSurchargeDTO.TypeEnum.BASE ? -1 : 1))

  return { surchargesFullData }
})(Component)
