import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { isNewObject, replaceOldGridItemWithNew } from '../index'
import { createPromiseResolve, makeSavePromiseWithCatch } from '../saveDTO'
import { getUpdatedDispatchDeliveryOrderStatus } from './status'
import { getCustomerStatus } from '../customer/status'
import { getLocationStatus } from '../location/status'
import { getUpdatedDeliveryOrderStatus } from '../deliveryOrder/status'
import { getEquipmentStatus } from '../equipment/status'
import { getContainerStatus } from '../container/status'
import { getLocationSavePromise } from '../location/save'
import { getContainerSavePromise } from '../container/save'
import { getDeliveryOrderSavePromise } from '../deliveryOrder/save'
import { getEquipmentSavePromise } from '../equipment/save'
import { getSellSideQuoteSavePromise } from '../sellSideQuote/save'
import { getSellSideQuoteStatus } from '../sellSideQuote/status'
import { getBuySideQuoteSavePromise } from '../buySideQuote/save'
import { getBuySideQuoteStatus } from '../buySideQuote/status'
import {
  BuySideQuoteDTO,
  callAPI,
  ContainerDTO,
  CustomerDTO,
  DeliveryOrderViewDTO,
  dispatchDeliveryOrderAPI,
  DispatchDeliveryOrderViewDTO,
  DriverViewDTO,
  EquipmentDTO,
  LocationViewDTO,
  SellSideQuoteDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { DDOErrorTypes, IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { getActivityStatus } from '../activity/status'
import {
  checkDates,
  cleanDispatchDeliveryOrder,
  getRelatedActivityType,
  initialActivitiesCalculation,
  makeUserModelForDDO,
  showModalOnDateConfuse
} from './functions'
import { getAssignedDrivers, getNeededActivityTypesByStages, TAssignedDrivers } from '../activity/functions'
import {
  getActivitiesByDispatchDeliveryOrderId,
  getDispatchDeliveryOrderById
} from '../../../components/common/dispatchDeliveryOrder/epics'
import { parseDTO } from '../parseDTO'
import { getCustomerSavePromise } from '../customer/save'
import { showErrorModalWindow, showModalWindow } from '../../../store/reducers/modalWindow/functions'
import { getActivitiesPromises } from '../location/saveInnerObjects'
import { processObject } from '../functions'
import { assembleDTO } from '../assemble'
import { isDeliveryOrderSteamShipLineFieldCorrect } from '../deliveryOrder'
import { ConflictsOnSaving, getObjectsDifference, prepareConflictList } from '../ConflictsOnSaving'
import { createWorkOrder } from '../../../components/CommunicationHub/epics'
import { debuggingMode } from '../../debug'
import { getStore } from '../../../store/configureStore'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isDropBobtailGotoActivity } from '../../functions/test/isBobtailGotoActivity'
import { isDropBobtailValid } from '../../functions/test/isDropBobtailValid'
import { isMoreThanOneNextToBobtails } from '../../functions/test/isMoreThanOneNextToBobtails'
import { getNewStoreState } from '../../../store'
import { isLoadTypeActitiesValid } from '../../functions/test/isLoadTypeActitiesValid'
import { activityDirectory } from '../activity/directory'
// tslint:disable:max-line-length
import { generateRequiredLoadTypeActivitiesErrorMessage } from '../../functions/generate/generateRequiredLoadTypeActivitiesErrorMessage'
import { testDriverStatus } from '../../functions/test/testDriverStatus'
import { isDDOStatusCancelled } from '../../functions/test/isDDOStatusCancelled'

const getObjectPromise = (object: any, promiseAction: any) => {
  return object && object.fullObject ? promiseAction(object) : Promise.resolve(object)
}

const getDDOSavePromise = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IModifyGridItemActions
): Promise<any> => {
  const { needToSave } = getUpdatedDispatchDeliveryOrderStatus(dispatchDeliveryOrder)

  if (needToSave) {
    const cleanDDO = cleanDispatchDeliveryOrder(dispatchDeliveryOrder)
    const isNewDDO = isNewObject(cleanDDO)

    return (isNewDDO
      ? callAPI(dispatchDeliveryOrderAPI.create, R.omit(cleanDDO, ['id']))
      : callAPI(dispatchDeliveryOrderAPI.update, cleanDDO, cleanDDO.id)
    )
      .toPromise()
      .then(data => {
        parseDTO.dispatchDeliveryOrder(data)
        if (Boolean(actions)) {
          if (isNewDDO) {
            replaceOldGridItemWithNew(data, actions.getUnitInfo)
          } else {
            actions.reset()
          }
        }
        return data
      })
  } else {
    return getDispatchDeliveryOrderById(dispatchDeliveryOrder.id, true).then(data =>
      createPromiseResolve(data, actions)
    )
  }
}

// ASSIGNED DRIVER
export const assignDriverPromise = (prevDDO: IDispatchDeliveryOrder) => async (updatedDDO: IDispatchDeliveryOrder) => {
  if (isDDOStatusCancelled(updatedDDO.status)) {
    return Promise.resolve()
  }

  const assignDrivers: TAssignedDrivers = getAssignedDrivers(
    oc(prevDDO).activities.transportationActivities([]),
    oc(updatedDDO).activities.transportationActivities([])
  )

  if (!assignDrivers.length) {
    return Promise.resolve()
  }

  if (
    oc(updatedDDO).deliveryStage.plannedAppointmentDateTimeRange.confirmed() === false &&
    getRelatedActivityType(oc(updatedDDO).activities.transportationActivities([]), 'delivery')
  ) {
    showModalWindow({
      width: 260,
      title: 'Appointment date is Not Confirmed',
      buttons: [{ label: 'Ok' }]
    })
  }

  return createWorkOrder({
    ddoId: updatedDDO.id,
    dispatchDeliveryOrder: updatedDDO,
    drivers: assignDrivers
  })
}

export const getFullDispatchDeliveryOrderStatusSavePromise = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IModifyGridItemActions
): Promise<any> => {
  const clonedDDOBeforeSaving = R.clone(
    assembleDTO.dispatchDeliveryOrder({ store: undefined, id: dispatchDeliveryOrder.id })
  )
  let modifiedDDO: IDispatchDeliveryOrder = R.clone(dispatchDeliveryOrder)
  // console.log('dispatchDeliveryOrder', dispatchDeliveryOrder)

  const modifyDDO = (ddo: IDispatchDeliveryOrder, description: string) => {
    modifiedDDO = ddo
    actions.modify(modifiedDDO)
    // console.log(description + ' => modifiedDDO', modifiedDDO)
  }

  return (
    getObjectPromise(modifiedDDO.container, getContainerSavePromise)
      // CONTAINER
      .then((container: ContainerDTO) => {
        modifyDDO({ ...modifiedDDO, containerId: oc(container).id(), container }, 'CONTAINER')
      })
      // EQUIPMENT
      .then(() =>
        getObjectPromise(modifiedDDO.equipment, getEquipmentSavePromise).then((equipment: EquipmentDTO) => {
          modifyDDO({ ...modifiedDDO, equipmentId: oc(equipment).id(), equipment }, 'EQUIPMENT')
        })
      )
      // CUSTOMER
      .then(() =>
        getObjectPromise(oc(modifiedDDO).deliveryOrder.customer(), getCustomerSavePromise).then(
          (customer: CustomerDTO) => {
            modifyDDO(
              {
                ...modifiedDDO,
                deliveryOrder: { ...modifiedDDO.deliveryOrder, customerId: oc(customer).id(), customer }
              },
              'CUSTOMER'
            )
          }
        )
      )
      // DELIVERY ORDER
      .then(() =>
        getObjectPromise(modifiedDDO.deliveryOrder, getDeliveryOrderSavePromise).then(
          (deliveryOrder: DeliveryOrderViewDTO) => {
            modifyDDO(
              {
                ...modifiedDDO,
                deliveryOrder
              },
              'DELIVERY ORDER'
            )
          }
        )
      )
      // SELL SIDE QUOTE
      .then(() =>
        getObjectPromise(modifiedDDO.sellSideQuote, getSellSideQuoteSavePromise).then(
          (sellSideQuote: SellSideQuoteDTO) => {
            modifyDDO(
              {
                ...modifiedDDO,
                sellSideQuote
              },
              'SELL SIDE QUOTE'
            )
          }
        )
      )
      // DELETE BUY SIDE QUOTES
      // .then(() => {
      //   const buySideQuotesBefore = oc(actions as any).initialObjectState.buySideQuotes([])
      //   const buySideQuotesToDelete: BuySideQuoteDTO[] = buySideQuotesBefore
      //     .filter((_: BuySideQuoteDTO) => _.type === BuySideQuoteDTO.TypeEnum.ADDITIONALSURCHARGE)
      //     .filter((__: BuySideQuoteDTO) => modifiedDDO.buySideQuotes.every((_: BuySideQuoteDTO) => __.id !== _.id))
      //
      //   return Promise.all(buySideQuotesToDelete.map(_ => callAPI(buySideQuoteAPI.delete, _.id).toPromise()))
      // })
      // UPDATE BUY SIDE QUOTES
      .then(() => {
        return Promise.all(
          modifiedDDO.buySideQuotes.map(buySideQuote => getObjectPromise(buySideQuote, getBuySideQuoteSavePromise))
        ).then((buySideQuotes: BuySideQuoteDTO[]) => {
          modifyDDO(
            {
              ...modifiedDDO,
              buySideQuotes
            },
            'BUY SIDE QUOTE UPDATE'
          )
        })
      })
      // CREATE/UPDATE ACTIVITIES
      .then(async () =>
        getActivitiesPromises(modifiedDDO, actions).then(savedActivities => {
          if (!savedActivities) {
            return Promise.resolve()
          }

          return getActivitiesByDispatchDeliveryOrderId(modifiedDDO.id).then(activities => {
            const sortedActivities = initialActivitiesCalculation(activities)

            modifyDDO(
              {
                ...modifiedDDO,
                activityIds: activities.transportationActivities
                  .map(({ id }) => id)
                  .concat(activities.documentationActivities.map(({ id }) => id)),
                activities: {
                  transportationActivities: sortedActivities.transportationActivities,
                  documentationActivities: sortedActivities.documentationActivities
                },
                activityGroups: sortedActivities.activityGroups,
                error: sortedActivities.error
                  ? {
                      type: DDOErrorTypes.activity,
                      message: sortedActivities.error
                    }
                  : undefined
              },
              'CREATE/UPDATE ACTIVITIES'
            )
          })
        })
      )
      // SMS DRIVER
      .then(() => assignDriverPromise(clonedDDOBeforeSaving)(modifiedDDO))
      // DISPATCH DELIVERY ORDER
      .then(() => getDDOSavePromise(processObject(modifiedDDO).clean, actions))
  )
}

export const saveDispatchDeliveryOrder = async (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IModifyGridItemActions,
  forceSave?: boolean
) => {
  const isExport = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT

  if (!forceSave && checkDates(dispatchDeliveryOrder)) {
    const exportDateModalErrorMessage =
      'You are scheduling return after Cutoff date to proceed click OK or CANCEL to return with out changes'
    const importDateModalErrorMessage =
      'You are scheduling pick up after LFD date to proceed click OK or CANCEL to return with out changes'

    actions.setFetching(false)

    showModalOnDateConfuse(
      true,
      isExport ? exportDateModalErrorMessage : importDateModalErrorMessage,
      () => {
        const relatedActivity = isExport
          ? getRelatedActivityType(dispatchDeliveryOrder.activities.transportationActivities, 'return')
          : getRelatedActivityType(dispatchDeliveryOrder.activities.transportationActivities, 'pickup')
        actions.modify({
          ...dispatchDeliveryOrder,
          ...(isExport
            ? {
                returnStage: {
                  ...dispatchDeliveryOrder.returnStage,
                  plannedAppointmentDateTimeRange: { from: null, to: null }
                }
              }
            : {
                pickupStage: {
                  ...dispatchDeliveryOrder.pickupStage,
                  plannedAppointmentDateTimeRange: { from: null, to: null }
                }
              }),
          activities: {
            ...dispatchDeliveryOrder.activities,
            transportationActivities: dispatchDeliveryOrder.activities.transportationActivities.map(
              (activity: TransportationActivityViewDTO) => ({
                ...activity,
                startPlannedDateTimeRange:
                  activity.id === relatedActivity.id ? { from: null, to: null } : activity.startPlannedDateTimeRange
              })
            )
          }
        })
      },
      () => {
        actions.setFetching(true)
        setTimeout(() => saveDispatchDeliveryOrder(dispatchDeliveryOrder, actions, true))
      }
    )
    return
  }

  const steamShipLineFieldIsValid = await isDeliveryOrderSteamShipLineFieldCorrect(dispatchDeliveryOrder.deliveryOrder)

  if (
    !isDDOStatusCancelled(dispatchDeliveryOrder.status) &&
    !isDDOStatusCancelled(oc(actions.initialObjectState as IDispatchDeliveryOrder).status())
  ) {
    const isLoadTypeActityListValid =
      dispatchDeliveryOrder.loadType !== oc(actions.initialObjectState as IDispatchDeliveryOrder).loadType() ||
      isLoadTypeActitiesValid(dispatchDeliveryOrder)
    const moreThanOneNextToDropBobtails = isMoreThanOneNextToBobtails(
      dispatchDeliveryOrder.activities.transportationActivities,
      'drop'
    )
    const allBobtailsHasDriver = dispatchDeliveryOrder.activities.transportationActivities.every(activity =>
      isDropBobtailGotoActivity(activity) ? isDropBobtailValid(activity) : true
    )
    const driverMapping = getNewStoreState().lists.driver
    const assignedOffDutyDrivers = getAssignedDrivers(
      oc(
        assembleDTO.dispatchDeliveryOrder({ store: undefined, id: dispatchDeliveryOrder.id })
      ).activities.transportationActivities([]),
      oc(dispatchDeliveryOrder).activities.transportationActivities([])
    )
      .map(item => driverMapping[item.vendorId])
      .filter(
        driver =>
          driver &&
          !testDriverStatus(driver.status).isActive &&
          driver.status !== DriverViewDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS
      )

    if (!isLoadTypeActityListValid) {
      actions.setFetching(false)

      return showErrorModalWindow({
        width: 445,
        title: 'Invalid set of activities',
        content: (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {generateRequiredLoadTypeActivitiesErrorMessage(dispatchDeliveryOrder)}
          </div>
        ),
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })
    }

    if (assignedOffDutyDrivers.length) {
      actions.setFetching(false)
      return showModalWindow({
        width: 445,
        title: "Activities can't be assigned",
        content: `Driver${assignedOffDutyDrivers.length > 1 ? 's' : ''} ${assignedOffDutyDrivers
          .map(driver => driver.name)
          .join(', ')} ${assignedOffDutyDrivers.length > 1 ? 'are' : 'is'} not available now`,
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })
    }

    if (!allBobtailsHasDriver) {
      actions.setFetching(false)
      return showModalWindow({
        width: 445,
        title: 'Invalid activity status',
        content: 'Please set a driver for the Drop activity or delete Not Assigned bobtail activity',
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })
    }

    if (moreThanOneNextToDropBobtails) {
      actions.setFetching(false)
      return showModalWindow({
        width: 445,
        title: 'Invalid activity status',
        content: 'You can not save with more than one unfinished bobtail after Drop',
        buttons: [
          {
            label: 'Ok'
          }
        ]
      })
    }
  }

  if (!steamShipLineFieldIsValid) {
    actions.setFetching(false)
    return showModalWindow({
      title: 'Steam Ship Line must be set',
      buttons: [
        {
          label: 'Close'
        }
      ]
    })
  }

  const save = () =>
    makeSavePromiseWithCatch(actions, getFullDispatchDeliveryOrderStatusSavePromise(dispatchDeliveryOrder, actions))

  await getDispatchDeliveryOrderById(dispatchDeliveryOrder.id)

  const checkObject = (object: any, getStatusAction: any) => {
    return object ? getStatusAction(object).noStoreDataForUpdate : true
  }

  const noStoreDataForUpdate = {
    dispatchDeliveryOrder: checkObject(dispatchDeliveryOrder, getUpdatedDispatchDeliveryOrderStatus),
    pickupLocation: checkObject(oc(dispatchDeliveryOrder).pickupStage.location(), getLocationStatus),
    deliveryLocation: checkObject(oc(dispatchDeliveryOrder).deliveryStage.location(), getLocationStatus),
    returnLocation: checkObject(oc(dispatchDeliveryOrder).returnStage.location(), getLocationStatus),
    deliveryOrder: checkObject(dispatchDeliveryOrder.deliveryOrder, getUpdatedDeliveryOrderStatus),
    equipment: checkObject(dispatchDeliveryOrder.equipment, getEquipmentStatus),
    container: checkObject(dispatchDeliveryOrder.container, getContainerStatus),
    customer: checkObject(oc(dispatchDeliveryOrder).deliveryOrder.customer(), getCustomerStatus),
    sellSideQuote: checkObject(oc(dispatchDeliveryOrder).sellSideQuote(), getSellSideQuoteStatus),
    buySideQuotes: oc(dispatchDeliveryOrder)
      .buySideQuotes([])
      .every(buySideQuote => checkObject(buySideQuote, getBuySideQuoteStatus)),
    activities: dispatchDeliveryOrder.activities.transportationActivities.every(
      activity => getActivityStatus(activity).noStoreDataForUpdate
    ),
    activityLocation: dispatchDeliveryOrder.activities.transportationActivities.every(activity =>
      isGotoActivity(activity)
        ? checkObject((activity as TransportationActivityViewDTO).destination, getLocationStatus)
        : true
    )
  }

  if (Object.values(noStoreDataForUpdate).every(Boolean)) {
    return save()
  }

  const initialObjectState = actions.initialObjectState as DispatchDeliveryOrderViewDTO
  let ConflictObjectsTable = undefined
  let hideSaveAnywayButton = false

  if (initialObjectState) {
    try {
      const conflicts = processObject({
        // 'Dispatch Delivery Order': noStoreDataForUpdate.dispatchDeliveryOrder
        //   ? undefined
        //   : getUpdatedDispatchDeliveryOrderStatus(initialObjectState).getDifference,
        'Dispatch Delivery Order has been updated': getObjectsDifference({
          oldObject: makeUserModelForDDO(initialObjectState),
          newObject: makeUserModelForDDO(
            assembleDTO.dispatchDeliveryOrder({ store: undefined, id: dispatchDeliveryOrder.id })
          )
        }),
        'Pickup Location has been updated': noStoreDataForUpdate.pickupLocation
          ? undefined
          : getLocationStatus(oc(initialObjectState).pickupStage.location() as any).getDifference,
        'Delivery Location has been updated': noStoreDataForUpdate.deliveryLocation
          ? undefined
          : getLocationStatus(oc(initialObjectState).deliveryStage.location() as any).getDifference,
        'Return Location has been updated': noStoreDataForUpdate.returnLocation
          ? undefined
          : getLocationStatus(oc(initialObjectState).returnStage.location() as any).getDifference,
        // 'Delivery Order': noStoreDataForUpdate.deliveryOrder
        //   ? undefined
        //   : getUpdatedDeliveryOrderStatus(initialObjectState.deliveryOrder as any).getDifference,
        Equipment: noStoreDataForUpdate.equipment
          ? undefined
          : getEquipmentStatus(initialObjectState.equipment as any).getDifference,
        Container: noStoreDataForUpdate.container
          ? undefined
          : getContainerStatus(initialObjectState.container).getDifference,
        Customer: noStoreDataForUpdate.customer
          ? undefined
          : getCustomerStatus(oc(initialObjectState).deliveryOrder.customer() as any).getDifference,
        'Sell Side Quote has been updated': noStoreDataForUpdate.sellSideQuote ? undefined : true,
        'Buy Side Quote list has been updated': noStoreDataForUpdate.buySideQuotes ? undefined : true,
        'Activity list has been updated': noStoreDataForUpdate.activities ? undefined : true,
        'Activity location has been updated': noStoreDataForUpdate.activityLocation ? undefined : true
      }).hardClean

      const list = prepareConflictList(
        conflicts,
        initialObjectState,
        assembleDTO.dispatchDeliveryOrder({ store: undefined, id: dispatchDeliveryOrder.id }),
        ['Activity list has been updated']
      )
      hideSaveAnywayButton = list.some(item => item.hideSaveAnywayButton)
      ConflictObjectsTable = ConflictsOnSaving(list)

      if (debuggingMode.common) {
        const storeDDO = getStore().getState().dispatchDeliveryOrder[dispatchDeliveryOrder.id]
        // tslint:disable-next-line:no-console
        console.log('Conflicts on DDO saving', {
          conflicts,
          noStoreDataForUpdate,
          states: {
            beforeUpdates: initialObjectState,
            afterUpdates: dispatchDeliveryOrder,
            store: storeDDO,
            cleanObjects: {
              beforeUpdates: cleanDispatchDeliveryOrder(initialObjectState),
              afterUpdates: cleanDispatchDeliveryOrder(initialObjectState),
              store: cleanDispatchDeliveryOrder(storeDDO)
            }
          }
        })
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
    }
  }

  actions.setFetching(false)

  return showModalWindow({
    title: 'There is newer data for the object',
    content: ConflictObjectsTable ? <ConflictObjectsTable /> : null,
    buttons: [
      hideSaveAnywayButton
        ? undefined
        : {
            label: 'Save anyway',
            onClick: () => {
              actions.setFetching(true)
              save()
            }
          },
      {
        label: 'Discard my changes',
        onClick: actions.cancel
      },
      {
        label: 'Close',
        isCloseButton: true
      }
    ].filter(Boolean)
  })
}
