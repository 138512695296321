import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { IContainer } from '../../../components/common/containers/interfaces'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from './index'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
}

export const assembleContainer = (props: Props): IContainer => {
  const store = props.store || getStore().getState()
  const container = oc(store).container[props.id](null)

  return container
    ? {
        ...container,
        containerType: container.containerTypeId && store.containerType[container.containerTypeId],
        steamShipLine: assembleDTO.steamShipLine({ store, id: container.steamShipLineId })
      }
    : null
}
