import * as React from 'react'
import { BoldLine, Text, Title } from '../../styles'
import { SteamShipLinePropsType } from './index'

export const Window: React.FC<{ steamShipLineProps: SteamShipLinePropsType }> = ({ steamShipLineProps }) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      SSL
      {/*<Additional className={'opacity'} fontSize={10} rows={2}>*/}
      {/*{convertISODateToDateWithHoursMins(sslProps.date)}*/}
      {/*</Additional>*/}
    </Title>
    <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
      {steamShipLineProps.name}
    </BoldLine>
    <Text className={'opacity uppercase'} rows={3} alignCenter={true}>
      {steamShipLineProps.vesselName && `${steamShipLineProps.vesselName}, ` + steamShipLineProps.voyage}
    </Text>
  </>
)
