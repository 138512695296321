import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { SAmount, SDriver, SIcon, SOpenMarker, SPreview, STitle, GrayText, ButtonDelete } from './styles'
import constants from '../../../../../../../../../services/constants'
import { BuySideQuote } from '../../../../../../../../../services/DTO/buySideQuote/interfaces'
import { BuySideQuoteDTO } from '../../../../../../../../../api/origin/business-logic'
import { activityDirectory } from '../../../../../../../../../services/DTO/activity/directory'
import { activityIcons } from '../../../../../../../../../services/constants/activityIcons'
import { quoteDirectory } from '../../../../../../../../../services/DTO/sellSideQuote/directory'
import { useAppSelector } from '../../../../../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../../../../../store/select/driverSelect'
import { transformMoney } from '../../../../../../../Input'

type OwnProps = {
  isOpen: boolean
  buySideQuote: BuySideQuote
  setOpenedBuySideQuoteId: (id: string) => void
  deleteBuySideQuote: () => void
  amount: number
}

type StateProps = {}

type Props = OwnProps & StateProps

export const Preview = (props: Props) => {
  const { isOpen, buySideQuote, setOpenedBuySideQuoteId, amount, deleteBuySideQuote } = props
  const { activityStage, activityComboType, activityVendorId } = oc(buySideQuote).temporaryData() || {}
  const activityVendorName = useAppSelector(selectDriverName(activityVendorId))
  const isUnsuccessful = buySideQuote.type === BuySideQuoteDTO.TypeEnum.UNSUCCESSFUL
  const directionInfo =
    buySideQuote.type === BuySideQuoteDTO.TypeEnum.ADDITIONALSURCHARGE ||
    buySideQuote.type === BuySideQuoteDTO.TypeEnum.UNSUCCESSFUL
      ? {
          title: activityDirectory.type[activityComboType] || '',
          icon: activityComboType ? activityIcons[activityComboType].icon : () => <div />
        }
      : buySideQuote.type === BuySideQuoteDTO.TypeEnum.BOBTAIL
      ? {
          title: quoteDirectory.typeOfRate[BuySideQuoteDTO.TypeEnum.BOBTAIL],
          icon: activityComboType ? activityIcons[activityComboType].icon : () => <div />
        }
      : constants.bsqTypes[buySideQuote.type] || {
          title: 'Empty Type',
          icon: () => <div />
        }

  const text = (t: string) => (isUnsuccessful ? <GrayText>{t}</GrayText> : t)

  // const disableDelete = buySideQuote.type !== BuySideQuoteDTO.TypeEnum.ADDITIONALSURCHARGE

  return (
    <SPreview onClick={() => setOpenedBuySideQuoteId(isOpen ? null : buySideQuote.id)}>
      <SIcon className={cn(String(activityStage), { UNSUCCESSFUL: isUnsuccessful })}>{directionInfo.icon()}</SIcon>
      <STitle>{text(directionInfo.title)}</STitle>
      <SDriver>
        {activityVendorName ? (
          isUnsuccessful ? (
            <GrayText>{activityVendorName}</GrayText>
          ) : (
            activityVendorName
          )
        ) : (
          <GrayText>No driver assigned</GrayText>
        )}
      </SDriver>
      <SAmount>{transformMoney(amount)}</SAmount>
      <SOpenMarker className={'mdi ' + (isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down')} />
      {/*<ButtonDelete*/}
      {/*className={cn('mdi mdi-delete', { disabled: disableDelete })}*/}
      {/*onClick={*/}
      {/*disableDelete*/}
      {/*? undefined*/}
      {/*: e => {*/}
      {/*e.preventDefault()*/}
      {/*e.stopPropagation()*/}
      {/*deleteBuySideQuote()*/}
      {/*}*/}
      {/*}*/}
      {/*/>*/}
    </SPreview>
  )
}
