import { callAPI, ruleAPI } from '../../../../api/api'
import { makeReadableDescription, qmpLists } from '../../../../services/select/qmpLists'
import { getDispatch } from '../../../../store'
import { listsActions } from '../../../../store/reducers/lists'
import { ListEntityType } from '../../../../store/reducers/lists/interfaces'

export const requestRules = () => {
  return callAPI(ruleAPI.getRules)
    .toPromise()
    .then(rules => {
      rules.forEach(rule => {
        qmpLists.rules.push({
          label: rule.name,
          value: rule.id
        })
        qmpLists.rulesWithDescription.push({
          label: rule.name,
          popover: makeReadableDescription(rule.description),
          value: rule.id
        })
      })

      getDispatch()(
        listsActions.update({
          update: {
            [ListEntityType.rule]: rules
          }
        })
      )
    })
}
