import * as React from 'react'
import { oc } from 'ts-optchain'
import ReactTooltip from 'react-tooltip'
import { IDriver } from '../../interfaces'
import { DriverDTO, DriverViewDTO } from '../../../../../api/origin/business-logic/'
import { AvailabilityColumnSelecter } from '../../../../UI/AvailabilitySelecter'
import { useGridItem } from '../../../../../hooks/useGridItem'
import { CandidateStatusColumn, RejectButton } from './CandidateStatusColumn'
import { ArrowActionButton } from '../../../../UI/Buttons/ArrowActionButton'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'
import { preventEvent } from '../../../../../services/functions/basic'
import { driverStatusColumnHintId } from '../../../../Hints/DriverStatusColumnHint'
import { groupedDriverStatuses } from '../../../../../services/constants/driverStatuses'
import { showWarningModalWindow } from '../../../../../store/reducers/modalWindow/functions'

type Props = {
  driver: IDriver
}

export const AvailabilityColumn = (props: Props) => {
  const { driver } = props
  const { actions } = useGridItem() || {}
  const isVendor = driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR
  const isOutsource = driver.driverType === DriverViewDTO.DriverTypeEnum.OUTSOURCE
  const attributes =
    !actions && driver && driver.notes ? { 'data-for': driverStatusColumnHintId, 'data-tip': driver.notes } : undefined

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [oc(driver).notes()])

  React.useEffect(() => {
    ReactTooltip.hide()
  }, [Boolean(actions)])

  const list = React.useMemo(() => {
    const makeItems = (_statuses: DriverDTO.StatusEnum[]) =>
      _statuses.map(item => ({ label: driverDirectory.status[item], value: item }))

    if (isVendor || isOutsource) {
      return [
        {
          title: 'Active',
          items: makeItems([DriverDTO.StatusEnum.AVAILABLE])
        },
        {
          title: 'Inactive',
          items: makeItems([DriverDTO.StatusEnum.TERMINATED])
        }
      ]
    } else if (driver.status === DriverDTO.StatusEnum.TERMINATED) {
      return [
        {
          title: 'Inactive',
          items: makeItems([DriverDTO.StatusEnum.TERMINATED])
        },
        {
          title: 'Candidate',
          items: makeItems(groupedDriverStatuses.Candidate)
        }
      ]
    }

    return [
      {
        title: 'Active',
        items: makeItems(groupedDriverStatuses.Active)
      },
      {
        title: 'Inactive',
        items: makeItems(groupedDriverStatuses.Inactive)
      }
    ]
  }, [driver.driverType, driver.status])

  if (
    driverDirectory.sortedCandidateStatuses
      .filter(_ => _ !== DriverViewDTO.StatusEnum.CANDIDATEAPPROVED)
      .includes(driver.status)
  ) {
    return <CandidateStatusColumn attributes={attributes} driver={driver} />
  }

  return (
    <AvailabilityColumnSelecter
      actions={actions}
      attributes={attributes}
      onSelect={status => {
        if (status === DriverViewDTO.StatusEnum.TERMINATED) {
          return showWarningModalWindow({
            width: 310,
            title: `Are you shure you want\n to terminate the driver?`,
            buttons: [
              { label: 'Cancel', outline: true },
              { label: 'Confirm', onClick: () => actions.modify({ ...driver, status }) }
            ]
          })
        }

        actions.modify({ ...driver, status })
      }}
      selected={driver.status}
      list={list}
      labelElement={
        driver.status === DriverViewDTO.StatusEnum.CANDIDATEAPPROVED
          ? ({ containerRef, togglePopover }) => {
              return (
                <div
                  ref={containerRef}
                  style={actions ? { display: 'grid', gridTemplateColumns: 'auto 1fr' } : undefined}
                >
                  {actions && (
                    <RejectButton
                      children={'Reject'}
                      style={{ marginRight: 8 }}
                      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        preventEvent(event)
                        return actions.modifyParentObjectField('status')(DriverViewDTO.StatusEnum.CANDIDATEREJECTED)
                      }}
                    />
                  )}
                  <ArrowActionButton
                    text={driverDirectory.status[driver.status]}
                    color={driverDirectory.statusColor[driver.status]}
                    attributes={attributes}
                    onLeftArrowClick={
                      actions
                        ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            preventEvent(event)
                            return actions.modifyParentObjectField('status')(DriverViewDTO.StatusEnum.CANDIDATEREVIEW)
                          }
                        : undefined
                    }
                    onRightArrowClick={actions ? togglePopover : undefined}
                  />
                </div>
              )
            }
          : undefined
      }
    />
  )
}
