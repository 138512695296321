import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { StatusBar } from './StatusBar'
import { ActualPlannedDates } from '../../../../../UI/DataFields/DateTimePicker/views/ActualPlannedDates'
import { Select } from '../../../../../UI/DataFields/Select'
import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityViewDTO
} from '../../../../../../api/origin/business-logic'
import { InputField } from '../../../../../UI/DataFields/Input'
import Location from '../../../../../UI/Widget/widgets/location'
import { oc } from 'ts-optchain'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { ILocation } from '../../../../location/interfaces'
import { DDOContext } from '../../../../../../services/DTO/dispatchDeliveryOrder/context'
import { FieldWarning } from '../../../../../UI/FieldWarning'
import {
  checkDates,
  findActivityWithWrongOrderOfPlannedDate,
  initialActivitiesCalculation,
  linkDatesGeneralToActivities
} from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import * as R from 'remeda'
import { MiddleColumn } from './styles'
import { dispatchDeliveryOrderLists } from '../../../../../../services/select/dispatchDeliveryOrderLists'
import { EffectiveFlexBox, FlexBox, PreviewRow } from '../../../../../pages/GridItem/styles'
import styled from 'styled-components'
import { DateOnFocus, DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
import { isBusinessActivity } from '../../../../../../services/functions/test/isBusinessActivity'
import { isGotoActivity } from '../../../../../../services/functions/test/isGotoActivity'

// TODO move to helper
const exportDateErrorMessage = 'You are scheduling return after Cutoff date'
const importDateErrorMessage = 'You are scheduling pick up after LFD date'

type Props = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  actions: IGridItemActions
}

const Center: React.SFC<Props> = ({
  dispatchDeliveryOrder,
  updateDeliveryOrder,
  updateDispatchDeliveryOrder,
  updateDispatchDeliveryOrderField,
  actions
}) => {
  const {
    saveButton,
    state: {
      isImport,
      isExport,
      isRepo,
      marks: { reefer }
    }
  } = React.useContext(DDOContext)

  let isLockedPickupLocation = false
  let isLockedDeliveryLocation = false
  let isLockedReturnLocation = false

  oc(dispatchDeliveryOrder)
    .activities.transportationActivities([])
    .filter(activity => activity.bobtailFrom && isGotoActivity(activity))
    .forEach(activity => {
      if (
        activity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
      ) {
        if (activity.stage === TransportationActivityViewDTO.StageEnum.PICKUP) {
          isLockedPickupLocation = true
        }
        if (activity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
          isLockedDeliveryLocation = true
        }
        if (activity.stage === TransportationActivityViewDTO.StageEnum.RETURN) {
          isLockedReturnLocation = true
        }
      }
    })

  const isModifiedMode = actions.isModified
  const activityWithWrongOrderOfPlannedDate = findActivityWithWrongOrderOfPlannedDate(
    dispatchDeliveryOrder.activities.transportationActivities
  )
  const pickupDates = {
    actual: oc(dispatchDeliveryOrder).pickupStage.actualAppointmentDate(),
    planned: oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange(),
    activityWithWrongOrderOfPlannedDate:
      activityWithWrongOrderOfPlannedDate &&
      activityWithWrongOrderOfPlannedDate.template &&
      activityWithWrongOrderOfPlannedDate.stage === TransportationActivityViewDTO.StageEnum.PICKUP &&
      isBusinessActivity(activityWithWrongOrderOfPlannedDate)
        ? activityWithWrongOrderOfPlannedDate
        : undefined
  }
  const appointmentDates = {
    actual: oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
    planned: oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange(),
    activityWithWrongOrderOfPlannedDate:
      activityWithWrongOrderOfPlannedDate &&
      activityWithWrongOrderOfPlannedDate.template &&
      activityWithWrongOrderOfPlannedDate.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      isBusinessActivity(activityWithWrongOrderOfPlannedDate)
        ? activityWithWrongOrderOfPlannedDate
        : undefined
  }
  const returnDates = {
    actual: oc(dispatchDeliveryOrder).returnStage.actualAppointmentDate(),
    planned: oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange(),
    activityWithWrongOrderOfPlannedDate:
      activityWithWrongOrderOfPlannedDate &&
      activityWithWrongOrderOfPlannedDate.template &&
      activityWithWrongOrderOfPlannedDate.stage === TransportationActivityViewDTO.StageEnum.RETURN &&
      isBusinessActivity(activityWithWrongOrderOfPlannedDate)
        ? activityWithWrongOrderOfPlannedDate
        : undefined
  }
  const pickDates = {
    actual: oc(dispatchDeliveryOrder).deliveryStage.actualPickDate(),
    planned: oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
    activityWithWrongOrderOfPlannedDate:
      activityWithWrongOrderOfPlannedDate &&
      activityWithWrongOrderOfPlannedDate.template &&
      activityWithWrongOrderOfPlannedDate.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      isBusinessActivity(activityWithWrongOrderOfPlannedDate)
        ? activityWithWrongOrderOfPlannedDate
        : undefined
  }

  const updateLocation = (field: 'pickupStage' | 'deliveryStage' | 'returnStage') => (
    location: ILocation,
    updateInitialState?: boolean
  ) => {
    let activityStage = TransportationActivityViewDTO.StageEnum.PICKUP
    if (field === 'deliveryStage') {
      activityStage = TransportationActivityViewDTO.StageEnum.DELIVERY
    } else if (field === 'returnStage') {
      activityStage = TransportationActivityViewDTO.StageEnum.RETURN
    }

    const updatedTransportationActivities = dispatchDeliveryOrder.activities.transportationActivities.map(activity =>
      activity.template && activity.stage === activityStage
        ? { ...activity, destination: location, destinationId: oc(location).id() }
        : activity
    )

    // updatedTransportationActivities.forEach((activity, index) => {
    //   if (activity.template && isBobtailableActivity(activity, false)) {
    //     let nextActivityIndex = index + 1
    //     let nextActivity = updatedTransportationActivities[nextActivityIndex]

    //     if (nextActivity) {
    //       if (isBobtailActivity(nextActivity)) {
    //         if (isActualActivity(nextActivity)) {
    //           updatedTransportationActivities[nextActivityIndex] = {
    //             ...nextActivity,
    //             destination: location,
    //             destinationId: oc(location).id()
    //           }
    //         } else {
    //           nextActivityIndex++
    //           let nextActivity = updatedTransportationActivities[nextActivityIndex]

    //           if (isBobtailActivity(nextActivity) && isActualActivity(nextActivity)) {
    //             updatedTransportationActivities[nextActivityIndex] = {
    //               ...nextActivity,
    //               destination: location,
    //               destinationId: oc(location).id()
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // })

    const { documentationActivities, activityGroups, transportationActivities } = initialActivitiesCalculation({
      documentationActivities: dispatchDeliveryOrder.activities.documentationActivities,
      transportationActivities: updatedTransportationActivities
    })

    return actions.modify(
      {
        ...dispatchDeliveryOrder,
        [field]: { ...dispatchDeliveryOrder[field], location, locationId: oc(location).id() },
        activities: {
          documentationActivities,
          transportationActivities
        },
        activityGroups
      },
      updateInitialState && location
        ? {
            ...actions.initialObjectState,
            [field]: { ...dispatchDeliveryOrder[field], location, locationId: location.id },
            activities: {
              ...oc(actions.initialObjectState).activities({}),
              transportationActivities: oc(actions.initialObjectState)
                .activities.transportationActivities([])
                .map((activity: any) =>
                  activity.destinationId === location.id ? { ...activity, destination: location } : activity
                )
            }
          }
        : undefined
    )
  }

  const disableLocationWidget = (stage: TransportationActivityViewDTO.StageEnum) =>
    dispatchDeliveryOrder.activities.transportationActivities.findIndex(
      activity =>
        activity.template &&
        activity.stage === stage &&
        (activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
          activity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL)
    ) !== -1

  const importDatesConfused = isImport ? checkDates(dispatchDeliveryOrder) : false
  const exportDatesConfused = isExport ? checkDates(dispatchDeliveryOrder) : false

  const RenderColumn = {
    get Empty() {
      return <Column key={'Empty'} />
    },
    get Availability() {
      return (
        <Column key={'Availability'} className={'ready-for-dispatch-required'}>
          <DateTimePicker
            title={'Availability'}
            disabled={actions.enableEditing === false}
            dateOnFocus={DateOnFocus.now}
            date={dispatchDeliveryOrder.deliveryOrder.equipmentFirstPickupDate}
            onChange={updateDeliveryOrder('equipmentFirstPickupDate')}
          />
        </Column>
      )
    },
    get 'Appointment Date'() {
      return (
        <Column key={'Appointment Date'} style={{ position: 'relative' }}>
          <AppDateConfirmedIndicator
            style={appExtraStyles}
            disabled={Boolean(oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate())}
            confirmed={oc(appointmentDates).planned.confirmed()}
            onClick={() =>
              updateDispatchDeliveryOrder({
                ...dispatchDeliveryOrder,
                deliveryStage: {
                  ...oc(dispatchDeliveryOrder).deliveryStage({}),
                  plannedAppointmentDateTimeRange: {
                    ...oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange({}),
                    confirmed: !Boolean(
                      oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed()
                    )
                  }
                }
              })
            }
          />
          <ActualPlannedDates
            activityWithWrongOrderOfPlannedDate={appointmentDates.activityWithWrongOrderOfPlannedDate}
            totalDisabled={isRepo}
            disabled={actions.enableEditing === false}
            title={'Appointment Date'}
            setTimeMorningOnEmpty={true}
            actual={{
              date: appointmentDates.actual
            }}
            planned={{
              date: appointmentDates.planned,
              onUpdate: _date => {
                const date: any = _date

                if (!appointmentDates.planned && _date) {
                  date.confirmed = true
                }

                return actions.modify({
                  ...linkDatesGeneralToActivities(dispatchDeliveryOrder, 'delivery', date, true)
                })
              }
            }}
          />
        </Column>
      )
    },
    get 'Per Diem Free By'() {
      return (
        <Column key={'Per Diem Free By'}>
          {(isImport || isExport) && (
            <DateTimePicker
              title={'Per Diem Free By'}
              disabled={actions.enableEditing === false}
              dateOnFocus={DateOnFocus.now}
              date={dispatchDeliveryOrder.deliveryOrder.lastFreeDatePerDiem}
              onChange={updateDeliveryOrder('lastFreeDatePerDiem')}
            />
          )}
        </Column>
      )
    },
    get Pickup() {
      return (
        <Column key={'Pickup'}>
          <FlexBox>
            {importDatesConfused && <FieldWarning style={{ left: -9, top: 15 }} message={importDateErrorMessage} />}
            <ActualPlannedDates
              activityWithWrongOrderOfPlannedDate={pickupDates.activityWithWrongOrderOfPlannedDate}
              disabled={actions.enableEditing === false}
              title={'Pickup'}
              setTimeNowOnEmpty={true}
              actual={{
                date: pickupDates.actual
              }}
              planned={{
                date: pickupDates.planned,
                onUpdate: date =>
                  actions.modify({
                    ...linkDatesGeneralToActivities(
                      dispatchDeliveryOrder,
                      'pickup',
                      date ? R.omit(date, ['confirmed'] as any) : date,
                      true
                    )
                  })
              }}
            />
          </FlexBox>
        </Column>
      )
    },
    get 'Load Type'() {
      return (
        <Column key={'Load Type'}>
          <EffectiveFlexBox>
            <div style={{ maxWidth: 120, marginRight: 10 }}>
              <Select
                disabled={isRepo || actions.enableEditing === false}
                required={isImport || isExport}
                title={'Load Type'}
                placeholder={''}
                selectedValue={dispatchDeliveryOrder.loadType}
                list={dispatchDeliveryOrderLists.loadType}
                onSelect={updateDispatchDeliveryOrderField('loadType')}
              />
            </div>
            <div>
              {(isImport || isExport) &&
                dispatchDeliveryOrder.loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.LIVELOAD &&
                dispatchDeliveryOrder.deliveryOrder && (
                  <InputField.Numeric
                    disabled={actions.enableEditing === false}
                    title={'Spent (hours)'}
                    placeholder={'0'}
                    value={oc(dispatchDeliveryOrder).deliveryStage.spentTimeSpan()}
                    onChange={spentTimeSpan =>
                      updateDispatchDeliveryOrder({
                        ...dispatchDeliveryOrder,
                        deliveryStage: { ...oc(dispatchDeliveryOrder).deliveryStage({}), spentTimeSpan }
                      })
                    }
                  />
                )}
            </div>
          </EffectiveFlexBox>
        </Column>
      )
    },
    get Return() {
      return (
        <Column key={'Return'}>
          <FlexBox>
            {exportDatesConfused && <FieldWarning style={{ left: -9, top: 15 }} message={exportDateErrorMessage} />}
            <ActualPlannedDates
              activityWithWrongOrderOfPlannedDate={returnDates.activityWithWrongOrderOfPlannedDate}
              disabled={actions.enableEditing === false}
              title={'Return'}
              setTimeMorningOnEmpty={true}
              actual={{
                date: returnDates.actual
              }}
              planned={{
                date: returnDates.planned,
                onUpdate: date =>
                  actions.modify({
                    ...linkDatesGeneralToActivities(dispatchDeliveryOrder, 'return', date)
                  })
              }}
            />
          </FlexBox>
        </Column>
      )
    },
    get 'Last Free'() {
      return (
        <Column key={'Last Free'}>
          <FlexBox className={'ready-for-dispatch-required'}>
            {importDatesConfused && <FieldWarning style={{ left: -9, top: 15 }} message={importDateErrorMessage} />}
            <DateTimePicker
              title={'Last Free'}
              disabled={actions.enableEditing === false}
              dateOnFocus={DateOnFocus.now}
              date={dispatchDeliveryOrder.deliveryOrder.lastFreeDateDemurrage}
              onChange={updateDeliveryOrder('lastFreeDateDemurrage')}
            />
          </FlexBox>
        </Column>
      )
    },
    get Cutoff() {
      return (
        <Column key={'Cutoff'}>
          <FlexBox className={'ready-for-dispatch-required'}>
            {exportDatesConfused && <FieldWarning style={{ left: -9, top: 15 }} message={exportDateErrorMessage} />}
            <DateTimePicker
              title={'Cutoff'}
              disabled={actions.enableEditing === false}
              dateOnFocus={DateOnFocus.now}
              date={dispatchDeliveryOrder.deliveryOrder.generalCutoffDate}
              onChange={updateDeliveryOrder('generalCutoffDate')}
            />
          </FlexBox>
        </Column>
      )
    },
    get Pick() {
      return (
        <Column key={'Pick'}>
          {dispatchDeliveryOrder.loadType === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK && (
            <ActualPlannedDates
              activityWithWrongOrderOfPlannedDate={pickDates.activityWithWrongOrderOfPlannedDate}
              disabled={actions.enableEditing === false}
              setTimeNowOnEmpty={true}
              title={'Pick'}
              actual={{
                date: pickDates.actual
              }}
              planned={{
                date: pickDates.planned,
                onUpdate: date =>
                  actions.modify({
                    ...linkDatesGeneralToActivities(dispatchDeliveryOrder, 'pick', date)
                  })
              }}
            />
          )}
        </Column>
      )
    },
    get 'First Receiving'() {
      return (
        <Column key={'First Receiving'} className={isExport ? 'ready-for-dispatch-required' : ''}>
          <DateTimePicker
            title={'First Receiving'}
            disabled={actions.enableEditing === false}
            date={dispatchDeliveryOrder.deliveryOrder.firstReceivingDate}
            onChange={updateDeliveryOrder('firstReceivingDate')}
          />
        </Column>
      )
    },
    get 'Hazmat Cutoff'() {
      return (
        dispatchDeliveryOrder.hazmatIndicator && (
          <Column key={'Hazmat Cutoff'}>
            <DateTimePicker
              title={'Hazmat Cutoff'}
              disabled={actions.enableEditing === false}
              date={dispatchDeliveryOrder.deliveryOrder.hazmatCutoffDate}
              onChange={updateDeliveryOrder('hazmatCutoffDate')}
            />
          </Column>
        )
      )
    },
    get 'Auto Cutoff'() {
      return (
        dispatchDeliveryOrder.autoIndicator && (
          <Column key={'Auto Cutoff'}>
            <DateTimePicker
              title={'Auto Cutoff'}
              disabled={actions.enableEditing === false}
              date={dispatchDeliveryOrder.deliveryOrder.autoCutoffDate}
              onChange={updateDeliveryOrder('autoCutoffDate')}
            />
          </Column>
        )
      )
    },
    get 'Reefer Cutoff'() {
      return (
        reefer && (
          <Column key={'Reefer Cutoff'}>
            <DateTimePicker
              title={'Reefer Cutoff'}
              disabled={actions.enableEditing === false}
              date={dispatchDeliveryOrder.deliveryOrder.reeferCutoffDate}
              onChange={updateDeliveryOrder('reeferCutoffDate')}
            />
          </Column>
        )
      )
    }
  }

  return (
    <MiddleColumn>
      <StatusBar progressBarState={dispatchDeliveryOrder.progressBarState} />

      <PreviewRow style={{ marginTop: 10 }}>
        <Column>
          <Location
            isShortName={true}
            widgetTitle={'Pickup Location'}
            disableEditing={
              actions.enableEditing === false ||
              disableLocationWidget(TransportationActivityViewDTO.StageEnum.PICKUP) ||
              isLockedPickupLocation
            }
            shadowColor={'blue'}
            location={dispatchDeliveryOrder.pickupStage.location as ILocation}
            updateLocation={updateLocation('pickupStage')}
            saveButton={saveButton}
            isModifiedMode={isModifiedMode}
          />
        </Column>
        <Column>
          <Location
            isCompanyName={true}
            disabled={isRepo}
            widgetTitle={'Delivery Location'}
            disableEditing={
              actions.enableEditing === false ||
              disableLocationWidget(TransportationActivityViewDTO.StageEnum.DELIVERY) ||
              isLockedDeliveryLocation
            }
            shadowColor={'pink'}
            location={oc(dispatchDeliveryOrder).deliveryStage.location() as ILocation}
            updateLocation={updateLocation('deliveryStage')}
            saveButton={saveButton}
            isModifiedMode={isModifiedMode}
          />
        </Column>
        <Column>
          <Location
            isShortName={true}
            widgetTitle={'Return Location'}
            disableEditing={
              actions.enableEditing === false ||
              disableLocationWidget(TransportationActivityViewDTO.StageEnum.RETURN) ||
              isLockedReturnLocation
            }
            shadowColor={'green'}
            location={dispatchDeliveryOrder.returnStage.location as ILocation}
            updateLocation={updateLocation('returnStage')}
            saveButton={saveButton}
            isModifiedMode={isModifiedMode}
          />
        </Column>
      </PreviewRow>

      <PreviewRow style={{ marginTop: 25 }}>
        {[RenderColumn.Availability, RenderColumn['Appointment Date'], RenderColumn['Per Diem Free By']]}
      </PreviewRow>

      <PreviewRow style={{ marginTop: 35 }}>
        {[RenderColumn.Pickup, RenderColumn['Load Type'], RenderColumn.Return]}
      </PreviewRow>

      <PreviewRow style={{ marginTop: 35 }}>
        {isImport && [RenderColumn['Last Free'], RenderColumn.Pick, RenderColumn.Empty]}
        {isExport && [RenderColumn.Cutoff, RenderColumn.Pick, RenderColumn['First Receiving']]}
        {isRepo && [RenderColumn.Empty, RenderColumn.Pick, RenderColumn['First Receiving']]}
      </PreviewRow>
      {isExport && (
        <PreviewRow style={{ marginTop: 35 }}>
          {[RenderColumn['Hazmat Cutoff'], RenderColumn['Auto Cutoff'], RenderColumn['Reefer Cutoff']]}
        </PreviewRow>
      )}
    </MiddleColumn>
  )
}

const Column = styled.div`
  width: calc(33.33% - 16px);
`
export const AppDateConfirmedIndicator = ({
  disabled,
  confirmed,
  onClick,
  style
}: {
  disabled?: boolean
  confirmed?: boolean
  style?: any
  onClick?: () => void
}) => {
  if (confirmed === true || confirmed === false) {
    return (
      <DateConfirmedIndicator
        style={disabled ? { ...(style || {}), backgroundColor: '#323e4e', cursor: 'default' } : style}
        className={String(confirmed)}
        onClick={
          !disabled && onClick
            ? (event: any) => {
                event.stopPropagation()
                onClick()
              }
            : undefined
        }
      >
        {confirmed ? 'Confirmed' : 'Not Confirmed'}
      </DateConfirmedIndicator>
    )
  }

  return null
}

const DateConfirmedIndicator = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 7px;
  width: 100px;
  background-color: red;
  color: white;
  text-align: center;
  padding: 5px 8px 4px;
  cursor: pointer;
  user-select: none;

  //&:after {
  //  z-index: 1;
  //  content: '';
  //  display: block;
  //  width: 22px;
  //  height: 22px;
  //  position: absolute;
  //  top: -2px;
  //  bottom: 0;
  //  border-radius: 50%;
  //  background-color: white;
  //  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  //}

  &.true {
    background-color: rgb(63, 182, 142);
    //padding-right: 28px;

    //&:after {
    //  right: -2px;
    //}
  }

  &.false {
    background-color: #ff5e5e;
    //padding-left: 28px;

    //&:after {
    //  left: -2px;
    //}
  }
`

const appExtraStyles = { zIndex: 1, position: 'absolute', top: -3, left: 120 }

export default Center
