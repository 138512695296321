import { GridColumn } from '../../../../../UI/GridSystem'
import theme from '../../../../../../styles/theme'
import styled from '../../../../../../styles/styledComponents'

export const ColumnTitles = styled(GridColumn)`
  border: 1px solid transparent;
  user-select: none;

  > div {
    align-items: center;
    border-left: 1px solid ${theme.colors.darkBorderColor};
    font-size: 10px;
    color: ${theme.colors.basicGreyColor};
    padding-left: 10px;

    &.required:after {
      content: '*';
      font-size: 12px;
      color: ${theme.colors.defaultRed};
      margin-left: 3px;
    }

    &:first-child {
      border: none;
    }
  }
`
export const Row = styled(GridColumn)`
  border: 1px solid ${theme.colors.darkBorderColor};
  border-radius: 5px;
  background: white;

  > div {
    align-items: center;
    padding-right: 20px;

    &:last-child {
      padding: 0 10px;
    }

    &:first-child {
      user-select: none;
    }
  }
`
export const AddRow = styled(GridColumn)`
  border: 1px solid ${theme.colors.darkBorderColor};
  border-radius: 5px;
  color: ${theme.colors.basicGreyColor};
  cursor: pointer;
  user-select: none;
`
