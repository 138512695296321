import * as R from 'remeda'
import { callAPI, DateTimeRangeDTO, OngoingActivityGroupDTO, schedulerAPI } from '../../../../api/api'
import { listsActions } from '../../../../store/reducers/lists'
import { getDispatch } from '../../../../store'
import { getStore } from '../../../../store/configureStore'
import { dateService } from '../../../../services/timeService'
import moveServiceAPI, { handleMoveServiceError } from '../../../CommunicationHub/epics/moveServiceAxios'
import { createFilterRequest } from '../../../../services/uiSettingsService/filter'
import { IFilterItem } from '../../grid/views/header/column'
import { requestDispatchDeliveryOrdersByIds } from '../../dispatchDeliveryOrder/epics'
import { isDispatchDeliveryOrderGoodForMoves } from '../functions/isDispatchDeliveryOrderGoodForMoves'
import { ListEntityType } from '../../../../store/reducers/lists/interfaces'
import { IMoveDTO } from '../../../../store/reducers/communicationHub/interfaces'

export const getSchedulerKeyPerformanceIndicator = () => {
  return callAPI(schedulerAPI.getKeyPerformanceIndicator, new Date().getTimezoneOffset()).toPromise()
}

export const getOngoingActivities = (props: { filter: string; sort: string }): Promise<OngoingActivityGroupDTO[]> => {
  return callAPI(schedulerAPI.findAll, props.filter, props.sort)
    .toPromise()
    .then(ongoingActivities => {
      if (props.filter.includes(OngoingActivityGroupDTO.GroupingEnum.STAGE.toString())) {
        const ongoingActivityDateStage: OngoingActivityGroupDTO[] = []
        const ongoingActivityDateStageNow: OngoingActivityGroupDTO[] = []

        ongoingActivities.forEach(ongoingActivity => {
          if (ongoingActivity.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGE) {
            ongoingActivityDateStage.push(ongoingActivity)
          } else if (ongoingActivity.grouping === OngoingActivityGroupDTO.GroupingEnum.STAGENOW) {
            ongoingActivityDateStageNow.push(ongoingActivity)
          }
        })

        getDispatch()(
          listsActions.update({
            update: {
              [ListEntityType.ongoingActivityDateStage]: ongoingActivityDateStage,
              [ListEntityType.ongoingActivityDateStageNow]: ongoingActivityDateStageNow
            }
          })
        )
      } else if (props.filter.includes(OngoingActivityGroupDTO.GroupingEnum.DRIVER.toString())) {
        getDispatch()(
          listsActions.update({
            update: {
              [ListEntityType.ongoingActivityDateDriver]: ongoingActivities
            }
          })
        )
      }

      return ongoingActivities
    })
}

// mm-dd-yyyy
export const getListOfUsedIdsOnSpecificDate = (date: string): Promise<string[]> => {
  // const store = getStore().getState()
  // const driverIds: string[] = R.uniq(Object.values(store.driver).map(driver => driver.id))
  // const randomCount = [0, 7, 2, 5, 10, 15]
  // const getRrandArrayItem = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)]
  // const count = getRrandArrayItem(randomCount)
  // const resultIds: string[] = []
  // let i = 0

  // while (i < count) {
  //   resultIds.push(getRrandArrayItem(driverIds))
  //   i++
  // }

  // return Promise.resolve(resultIds)

  return callAPI(schedulerAPI.findVendorsAssignedOnDeliveryStage, dateService.getStringDay(date)).toPromise()
}

export const getMoves = (props?: { vendorId?: string; plannedDate?: DateTimeRangeDTO }) => {
  const { vendorId, plannedDate } = props || {}
  const filterParams: IFilterItem[] = [
    { column: 'vendorId', value: vendorId },
    { column: 'plannedDate', value: plannedDate }
  ].filter(f => f.value)
  const filter = createFilterRequest(filterParams)

  return callAPI(() => moveServiceAPI.get(`/grid-move`, filter ? { params: { filter } } : undefined))
    .toPromise()
    .then(async data => {
      if (data && typeof data.data === 'object') {
        const moves = data.data as IMoveDTO[]

        if (moves.length) {
          getDispatch()(
            listsActions.update({
              update: {
                [ListEntityType.move]: moves
              }
            })
          )
        }

        // remove already existed Dispatch Delivery Orders from the request
        const dispatchDeliveryOrderMapping = getStore().getState().dispatchDeliveryOrder
        let dispatchDeliveryOrderIds: string[] = []

        moves.forEach(move => {
          const { dispatchDeliveryOrderId } = move
          const dispatchDeliveryOrder = dispatchDeliveryOrderMapping[dispatchDeliveryOrderId]

          if (!isDispatchDeliveryOrderGoodForMoves(dispatchDeliveryOrder)) {
            dispatchDeliveryOrderIds.push(dispatchDeliveryOrderId)
          }
        })

        dispatchDeliveryOrderIds = R.uniq(dispatchDeliveryOrderIds)

        // get needed Dispatch Delivery Orders & activities
        await requestDispatchDeliveryOrdersByIds(dispatchDeliveryOrderIds, { notFullObjectWithActivities: true })

        return moves
      } else {
        return []
      }
    })
    .catch(handleMoveServiceError([]))
}
