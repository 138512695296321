import { callAPI, customerAPI } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { addCustomersToStore } from '../action'
import { CustomerViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'

export const tryAddCustomerToStore = (items: CustomerViewDTO | CustomerViewDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().customer, items, addCustomersToStore)
}

export const getCustomerById = (id: string): Promise<CustomerViewDTO> =>
  callAPI(customerAPI.findById, id)
    .toPromise()
    .then(customer => {
      parseDTO.customer(customer)
      return customer
    })

export const findAllCustomers = async ({ sort, filter }: any): Promise<CustomerViewDTO[]> => {
  return callAPI(customerAPI.findAll, filter, sort)
    .toPromise()
    .then(customers => {
      parseDTO.customer(customers)
      return customers
    })
    .catch(catchListErrors)
}

export const findCustomersByTerm = async (term: string) => {
  return callAPI(customerAPI.search, null, term.trim()).toPromise()
}
