import * as React from 'react'
import { IContainer } from '../../interfaces'
import { ContainerDTO } from '../../../../../api/origin/business-logic/api'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { AvailabilityColumnSelecter } from '../../../../UI/oldAvailabilitySelecter'
import { useGridItem } from '../../../../../hooks/useGridItem'

const dropDownItems = {
  Active: ['Available', 'Not Available (Assigned)', 'Not Available (In-Use)'],
  Inactive: ['Repair']
}

type OwnProps = {
  unit: IContainer
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const AvailabilityColumn = (props: Props) => {
  let selectedItem: string
  const { unit } = props
  const { actions } = useGridItem() || {}

  const selectedValue = (container: IContainer) => {
    if (container.active) {
      container.availability
        ? (selectedItem = 'Available')
        : container.unavailabilityReason === ContainerDTO.UnavailabilityReasonEnum.ASSIGNED
        ? (selectedItem = 'Not Available (Assigned)')
        : (selectedItem = 'Not Available (In-Use)')
    } else {
      switch (container.inactiveReason) {
        case ContainerDTO.InactiveReasonEnum.REPAIR:
          selectedItem = 'Repair'
          break
        default:
          break
      }
    }

    return selectedItem
  }

  const currentSelectedItem: string = selectedValue(unit)

  return (
    <AvailabilityColumnSelecter
      actions={actions}
      handleSelect={handleSelect(unit, actions, currentSelectedItem)}
      activeList={dropDownItems.Active}
      inactiveList={dropDownItems.Inactive}
      selectedItem={currentSelectedItem}
      stateActive={unit.active}
    />
  )
}

const handleSelect = (unit: any, actions: IGridItemActions, selectedItem: any) => (item: string) => {
  if (item !== selectedItem) {
    let updatedUnit: IContainer

    switch (item) {
      case 'Available':
        updatedUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: true,
          unavailabilityReason: null
        }
        break
      case 'Not Available (Assigned)':
        updatedUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.ASSIGNED
        }
        break
      case 'Not Available (In-Use)':
        updatedUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.INUSE
        }
        break
      case 'Repair':
        updatedUnit = {
          ...unit,
          active: false,
          inactiveReason: ContainerDTO.InactiveReasonEnum.REPAIR,
          availability: false,
          unavailabilityReason: ContainerDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      default:
        updatedUnit = unit
        break
    }

    actions.modify(updatedUnit)
  }
}
