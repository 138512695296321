import * as React from 'react'
import { TabType } from '../../../common/tabs/interfaces'
import { CustomHeader as CustomDDOHeader } from '../../../common/dispatchDeliveryOrder/views/Details/CustomHeader'
import { IGridItemActions } from '../../../../contexts/GridItemContext'

type TUnitHeaderData = {
  unit: any
  unitType: TabType
  isModified: boolean
  isValid: boolean
  actions: IGridItemActions
}

export const CustomUnitHeader = ({ unit, actions, isModified, isValid, unitType }: TUnitHeaderData) => {
  switch (unitType) {
    case TabType.dispatchDeliveryOrder:
      return <CustomDDOHeader dispatchDeliveryOrder={unit} actions={actions} isModified={isModified} />
    default:
      return null
  }
}
