import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import theme from '../../../../../styles/theme'
import styled from '../../../../../styles/styledComponents'
import { Column, ColumnRow, Container, GridColumn } from '../../../GridSystem'
import { EditModeButton, WidgetActions } from '../../styles'
import { Select } from '../../../DataFields/Select'
import { AddressDTO, LocationViewDTO } from '../../../../../api/origin/business-logic'
import Textarea from '../../../Input'
import { Input, InputField } from '../../../DataFields/Input'
import { ILocation } from '../../../../common/location/interfaces'
import { LocationSearchWithGoogle } from '../../../DataFields/SearchRequest/location/withGoogle'
import { updateAddressObject } from './functions'
import { isNewObject } from '../../../../../services/DTO'
import { GridSpinner } from '../../../Spinner/Spinner'
import { checkLocationValidation } from '../../../../../services/DTO/location'
import { FieldTemplate } from '../../../DataFields/Templates'
import { locationLists } from '../../../../../services/select/locationLists'
import { requestLocationById } from '../../../../common/location/epics'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { showModal, TMsgType } from '../../../Modal/actions'
import { getStore } from '../../../../../store/configureStore'
import { assembleDTO } from '../../../../../services/DTO/assemble'
import { createAddressLine, getStateByCodes } from '../../../../../services/addressService'
import { locationControllerApi } from '../../../../../api/location'
import {
  showErrorModalWindow,
  showModalWindow,
  showSuccessModalWindow
} from '../../../../../store/reducers/modalWindow/functions'
import { SelectLocation } from '../../../../../services/DTO/location/SelectLocation'
import { callAPIWithErrorMessage, locationAPI } from '../../../../../api/api'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'
import { saveWidgetLocation } from './saveWidgetLocation'
import { isLocationValid } from '../../../../../services/functions/test/isLocationValid'

type Props = {
  disabled?: boolean
  disableEditing?: boolean
  location: ILocation
  updateLocation: (updatedLocation: ILocation, updateInitialState?: boolean) => void
  isModifiedMode?: boolean
  isShortName?: boolean
}

export const Popover = (props: Props) => {
  const { updateLocation, disabled, location, isModifiedMode, disableEditing } = props
  const { closePopover } = React.useContext(WidgetContext)
  const [modifiedLocation, setModifiedLocation] = React.useState<ILocation>(null)
  const isLocationEditable = Boolean(!disabled && !disableEditing && modifiedLocation)
  // oc(location).fullObject() ? checkLocationValidation(location) : false
  const [fetching, setFetching] = React.useState(location && !location.fullObject)
  const isInvalid = isLocationGEOValid(location) === false
  const isPartial = oc(location).status() === LocationViewDTO.StatusEnum.PARTIAL

  React.useEffect(() => {
    if (location && !location.fullObject) {
      requestLocationById(location.id)
        .then(resolvedLocation => {
          if (isModifiedMode) {
            updateLocation(assembleDTO.location({ store: undefined, id: resolvedLocation.id }), true)
          }
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }, [])

  const locationAddress = oc(modifiedLocation || location).address(null)
  const locationObject = {
    id: oc(modifiedLocation || location).id(''),
    status: oc(modifiedLocation || location).status(LocationViewDTO.StatusEnum.FULL),
    name: oc(modifiedLocation || location).name(''),
    code: oc(modifiedLocation || location).code(''),
    longName: oc(modifiedLocation || location).longName(''),
    street: oc(modifiedLocation || location).address.street(''),
    type: oc(modifiedLocation || location).type(null),
    city: oc(modifiedLocation || location).address.city(''),
    stateId: oc(modifiedLocation || location).address.stateId(''),
    zip: oc(modifiedLocation || location).address.postalCode(''),
    notes: oc(modifiedLocation || location).notes(''),
    company: oc(modifiedLocation || location).careOf('')
  }

  const contacts = oc(modifiedLocation || location).contacts(null)
  const primaryContact = contacts ? contacts.find(item => item.primary) : null

  const updateLocationField = (fieldName: keyof ILocation) => (value: any) => {
    setModifiedLocation(l => ({
      ...l,
      [fieldName]: value
    }))
  }

  const updateAddressField = (fieldName: keyof AddressDTO) => (value: any) => {
    const address = updateAddressObject(locationAddress)(fieldName)(value)
    return updateLocationField('address')(address)
  }

  const onCheckLocationClick = async () => {
    const addressString = createAddressLine(location.address)
    const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

    if (searchResults.length) {
      return showModalWindow({
        width: 445,
        title: 'Select location',
        content: (
          <SelectLocation
            selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
            locations={searchResults}
            onSelect={selectedPlaceId => {
              if (selectedPlaceId) {
                setFetching(true)

                locationControllerApi
                  .locationByPlaceId(selectedPlaceId)
                  .then(async requestedLocation => {
                    const { latitude, longitude } = requestedLocation
                    const requestedAddress = oc(requestedLocation).address()
                    const updatedLocation = { ...location, placeId: selectedPlaceId, latitude, longitude }

                    if (requestedAddress) {
                      if (requestedAddress.city) {
                        updatedLocation.address = {
                          ...updatedLocation.address,
                          street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                          city: requestedAddress.city || location.address.city,
                          postalCode: requestedAddress.postalCode || location.address.postalCode,
                          stateId:
                            requestedAddress.stateCode && requestedAddress.countryCode
                              ? getStateByCodes({
                                  stateCode: requestedAddress.stateCode,
                                  countryCode: requestedAddress.countryCode
                                }).id
                              : location.address.stateId
                        }
                      }
                    }

                    const resultedLocation = await callAPIWithErrorMessage(
                      locationAPI.update,
                      R.omit(updatedLocation, ['contacts', 'primaryContact']),
                      updatedLocation.id
                    )
                      .then(() => {
                        showSuccessModalWindow({ title: 'Validation Success' })
                      })
                      .catch(() => null)

                    if (resultedLocation) {
                      if (isModifiedMode) {
                        updateLocation(resultedLocation, true)
                      }
                    }

                    setFetching(false)
                  })
                  .catch(() => {
                    setFetching(false)
                    showErrorModalWindow({
                      title: 'Location search error'
                    })
                  })
              }
            }}
          />
        ),
        buttons: [
          {
            label: 'Cancel'
          },
          {
            label: 'Apply',
            disabled: true
          }
        ]
      })
    } else {
      setFetching(false)
      return showErrorModalWindow({
        content: 'Location with the entered address is not found in Google'
      })
    }
  }

  const onValidateLocationClick = async () => {
    if (!isInvalid) {
      return
    }

    const addressString = createAddressLine(location.address)
    const placeId = oc(location).placeId()
    const searchResults = await locationControllerApi.searchLocation(addressString).catch(() => [])

    if (searchResults.length) {
      const isPlaceIdCorrect = searchResults.length === 1 && oc(searchResults[0]).placeId() === placeId

      if (!(isPlaceIdCorrect && oc(location).longitude() && oc(location).latitude())) {
        return showModalWindow({
          width: 445,
          title: 'Select location',
          content: (
            <SelectLocation
              selectedPlaceId={searchResults.length === 1 ? oc(searchResults[0]).placeId() : undefined}
              locations={searchResults}
              onSelect={selectedPlaceId => {
                if (selectedPlaceId) {
                  setFetching(true)

                  locationControllerApi
                    .locationByPlaceId(selectedPlaceId)
                    .then(async requestedLocation => {
                      const { latitude, longitude } = requestedLocation
                      const requestedAddress = oc(requestedLocation).address()
                      const updatedLocation = { ...location, placeId: selectedPlaceId, latitude, longitude }

                      if (requestedAddress) {
                        if (requestedAddress.city) {
                          updatedLocation.address = {
                            ...updatedLocation.address,
                            street: requestedAddress.street || requestedAddress.street2 || location.address.street,
                            city: requestedAddress.city || location.address.city,
                            postalCode: requestedAddress.postalCode || location.address.postalCode,
                            stateId:
                              requestedAddress.stateCode && requestedAddress.countryCode
                                ? getStateByCodes({
                                    stateCode: requestedAddress.stateCode,
                                    countryCode: requestedAddress.countryCode
                                  }).id
                                : location.address.stateId
                          }
                        }
                      }

                      const resultedLocation = await callAPIWithErrorMessage(
                        locationAPI.update,
                        R.omit(updatedLocation, ['contacts', 'primaryContact']),
                        updatedLocation.id
                      )
                        .then(() => {
                          showSuccessModalWindow({ title: 'Validation Success' })
                        })
                        .catch(() => null)

                      if (resultedLocation) {
                        if (isModifiedMode) {
                          updateLocation(resultedLocation, true)
                        }
                      }

                      setFetching(false)
                    })
                    .catch(() => {
                      setFetching(false)
                      showErrorModalWindow({
                        title: 'Location search error'
                      })
                    })
                }
              }}
            />
          ),
          buttons: [
            {
              label: 'Cancel'
            },
            {
              label: 'Apply',
              disabled: true
            }
          ]
        })
      }
    } else {
      setFetching(false)
      return showErrorModalWindow({
        content: 'Location with the entered address is not found in Google'
      })
    }
  }

  return (
    <>
      {fetching && <GridSpinner />}
      {(isInvalid || isPartial) && (
        <LocationWidgetLabelsBlock>
          {isInvalid && <PopoverInvalidLabel />}
          {isPartial && <PopoverPartialLabel />}
        </LocationWidgetLabelsBlock>
      )}
      <Container columns={32} flexClear={true} isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <LocationSearchWithGoogle
            disabled={disableEditing}
            focus={!location}
            title={'Find Location'}
            value={location}
            onChange={(updatedLocation: ILocation) => {
              const doUpdate = () => {
                // updateLocation(updatedLocation)
                if (updatedLocation && isNewObject(updatedLocation)) {
                  setModifiedLocation(updatedLocation)
                } else {
                  updateLocation(updatedLocation)
                  setModifiedLocation(null)
                }
              }

              if (oc(location).status() === LocationViewDTO.StatusEnum.PARTIAL) {
                getStore().dispatch(
                  showModal({
                    msgType: TMsgType.delete,
                    message: 'Are you sure you want to remove partial location?',
                    onConfirm: doUpdate,
                    onCancel: () => {}
                  })
                )
              } else {
                doUpdate()
              }
            }}
          />
        </ColumnRow>

        {Boolean(modifiedLocation || oc(location).fullObject()) && (
          <>
            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!isLocationEditable}
                  required={true}
                  title={'Name'}
                  value={locationObject.name}
                  onChange={updateLocationField('name')}
                />
              </Column>
              <Column>
                <Input
                  disabled={!isLocationEditable}
                  required={true}
                  title={'Address'}
                  value={locationObject.street}
                  onChange={updateAddressField('street')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Column columns={4} margin={{ right: 10 }}>
                  <Select
                    disabled={!isLocationEditable}
                    required={true}
                    title={'Type'}
                    label={oc(locationLists.typeWithIcons.find(_ => _.value === locationObject.type)).htmlLabel(null)}
                    selectedValue={locationObject.type}
                    list={locationLists.typeWithIcons}
                    onSelect={updateLocationField('type')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <Input
                    title={'Code'}
                    disabled={!isLocationEditable}
                    value={locationObject.code}
                    onChange={updateLocationField('code')}
                  />
                </Column>
              </Column>
              <Column>
                <Column columns={5} margin={{ right: 10 }}>
                  <Input
                    disabled={!isLocationEditable}
                    required={true}
                    title={'City'}
                    value={locationObject.city}
                    onChange={updateAddressField('city')}
                  />
                </Column>
                <Column style={{ fontSize: 14 }} columns={4} isFixed={true} margin={{ right: 10 }}>
                  <FieldTemplate.StateSearch
                    disabled={!isLocationEditable}
                    required={true}
                    showCode={true}
                    title={'State'}
                    stateId={locationObject.stateId}
                    onChange={updateAddressField('stateId')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <InputField.Zip
                    disabled={!isLocationEditable}
                    required={true}
                    title={'ZIP'}
                    value={locationObject.zip}
                    onChange={updateAddressField('postalCode')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <Column rows={0.2} background={'#eff2f6'} margin={{ bottom: 15 }} />

            <GridColumn>
              <Column isGrid={true} margin={{ right: 16 }}>
                <ColumnRow margin={{ bottom: 20 }}>
                  <Input
                    disabled={!isLocationEditable}
                    title={'Company'}
                    value={locationObject.company}
                    onChange={updateLocationField('careOf')}
                  />
                </ColumnRow>
                <Column>
                  <Textarea
                    disabled={!isLocationEditable}
                    title={'Note'}
                    isTextArea={true}
                    value={locationObject.notes}
                    onChange={updateLocationField('notes')}
                  />
                </Column>
              </Column>

              <Column isGrid={true}>
                <ColumnRow margin={{ bottom: 20 }}>
                  <Column margin={{ right: 10 }}>
                    <Input
                      disabled={true}
                      title={'First Name'}
                      value={oc(primaryContact).firstName('')}
                      onChange={undefined}
                    />
                  </Column>
                  <Column>
                    <Input
                      disabled={true}
                      title={'Last Name'}
                      value={oc(primaryContact).lastName('')}
                      onChange={undefined}
                    />
                  </Column>
                </ColumnRow>

                <ColumnRow margin={{ bottom: 20 }}>
                  <InputField.Email
                    disabled={true}
                    title={'E-mail'}
                    value={oc(primaryContact).email('')}
                    onChange={undefined}
                  />
                </ColumnRow>

                <ColumnRow>
                  <Column margin={{ right: 10 }}>
                    <InputField.PhoneNumber
                      disabled={true}
                      title={'Phone'}
                      value={oc(primaryContact).phone('')}
                      onChange={undefined}
                    />
                  </Column>
                  <Column>
                    <InputField.PhoneNumber
                      disabled={true}
                      title={'Fax'}
                      value={oc(primaryContact).fax('')}
                      onChange={undefined}
                    />
                  </Column>
                </ColumnRow>
              </Column>
            </GridColumn>
            {oc(location).terminalIntegration() && (
              <>
                {location.terminalIntegration.active ? (
                  <TerminalIntegrationContainer>
                    <i className={'mdi mdi-checkbox-marked-circle'} />
                    Integrated with&nbsp;
                    <a href={`https://${location.terminalIntegration.webSite}`} target="_blank">
                      {location.terminalIntegration.webSite}
                    </a>
                    {location.terminalIntegration.fault && (
                      <TerminalAlert>
                        <i className={'mdi mdi-alert-circle'} /> Terminal Alert
                      </TerminalAlert>
                    )}
                  </TerminalIntegrationContainer>
                ) : (
                  <TerminalIntegrationContainer>Terminal Intergation turned off</TerminalIntegrationContainer>
                )}
              </>
            )}
          </>
        )}
      </Container>
      <WidgetActions>
        {modifiedLocation ? (
          <>
            <ActionButton onlyText={true} round={true} onClick={() => setModifiedLocation(null)} children={'Cancel'} />
            <ActionButton
              filled={true}
              round={true}
              disabled={!isLocationValid(modifiedLocation || location)}
              onClick={() => {
                saveWidgetLocation({
                  modifiedLocation,
                  modifyLocation: setModifiedLocation,
                  initialLocation: oc(location).id() === oc(modifiedLocation).id() ? location : undefined,
                  setFetching,
                  onLocationSaveComplete: (savedLocation: ILocation) => {
                    if (isModifiedMode && savedLocation) {
                      updateLocation(savedLocation, true)
                    }

                    setModifiedLocation(null)
                  }
                })
              }}
              children={'Save Location'}
            />
          </>
        ) : (
          <>
            {location && (
              <>
                <ActionButton
                  round={true}
                  filled={true}
                  red={Boolean(isInvalid)}
                  onClick={isInvalid ? onValidateLocationClick : onCheckLocationClick}
                  children={'Validate'}
                />
                {!disabled && !disableEditing && (
                  <EditModeButton onClick={() => setModifiedLocation(R.clone(location))} children={'Edit Location'} />
                )}
              </>
            )}
            <ActionButton filled={true} round={true} onClick={closePopover} children={'Ok'} />
          </>
        )}
      </WidgetActions>
    </>
  )
}

export const LocationWidgetLabelsBlock = styled.div`
  display: flex;
  position: absolute;
  left: 170px;
  top: 21px;

  > * {
    margin-right: 5px;
  }
`

export const LocationWidgetLabel = styled.div`
  background: ${theme.colors.basicBlueColor};
  color: white;
  font-weight: 500;
  border-radius: 3px;
  font-size: 10px;
  letter-spacing: 0.3px;
  padding: 2px 3px;
`

export const PopoverPartialLabel = styled(LocationWidgetLabel)`
  &:before {
    content: 'Partial';
  }
`

export const PopoverInvalidLabel = styled(PopoverPartialLabel)`
  background: ${theme.colors.defaultRed};

  &:before {
    content: 'Invalid';
  }
`

const TerminalIntegrationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  border-top: 1px solid rgba(214, 223, 232, 0.4);
  border-bottom: 1px solid rgba(214, 223, 232, 0.4);
  font-size: 14px;
  color: #445366;
  margin: 16px 0 0;

  i.mdi {
    font-size: 24px;
    margin-right: 7px;
  }

  .mdi-checkbox-marked-circle {
    color: #4555d0;
  }

  a {
    color: #4555d0;
    line-height: 56px;
    user-select: none;
    cursor: pointer;
  }
`

const TerminalAlert = styled.div`
  display: flex;
  align-items: center;
  color: #ff5e5e;
  margin-left: 20px;

  .mdi {
    color: inherit;
  }
`
