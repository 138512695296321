import { IEquipment } from '../../../components/common/equipment/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { oc } from 'ts-optchain'

export const getEquipmentStatus = (equipment: IEquipment): TObjectStatusForSave => {
  if (!equipment || !equipment.fullObject) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false,
      getDifference: undefined
    }
  }

  const store = getStore().getState()
  const storeEquipment = store.equipment[equipment.id]

  const storeObject = omitUpdateInfo(storeEquipment)
  const checkObject = omitUpdateInfo(equipment)

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(equipment, storeEquipment)
    },
    get needToSave() {
      return oc(checkObject).fullObject() && !isEqualObjects(checkObject, storeObject)
    },
    get getDifference() {
      return getObjectsDifference({ oldObject: checkObject, newObject: storeObject })
    }
  }
}
