import * as React from 'react'
import { TableBody } from '../../../../Grid/styles'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../../../../api/origin/qmp-service'
import { ColumnSize, NewRateDTO, RateType, RateState, SellSideQuoteRateGroupDTO } from '../../../../../interfaces'
import { TNewCustomerQuotes } from '../../../../../../../../services/DTO/customerQuote/interfaces'
import * as R from 'remeda'
import { generateOppositeColumnRateCopy, generateRateCopy } from '../functions'
import { RateDTO } from '../../../../../../../../services/DTO/rate/interfaces'
import { RateTabBlocks } from './RateTabBlocks'
import { ApplicationSizeProvider } from '../../../../../../../../providers/ApplicationSizeProvider'
import { useQMP } from '../../../../../../../../hooks/useQMP'
import { buySideQuoteRateAPI, callAPI, sellSideQuoteRateAPI } from '../../../../../../../../api/api'
import { tryAddBSQRatesToStore, tryAddSSQRatesToStore } from '../../../../../../../../services/DTO/rate/epics'
import { oc } from 'ts-optchain'

type OwnProps = {
  rateIds: string[]
  columnSize: ColumnSize
  readOnly: boolean
  extraBodyStyles?: any
  isNewCustomerQuotes?: boolean
  customerQuote?: TNewCustomerQuotes
  rateType: RateType
  rates: (RateDTO | SellSideQuoteRateGroupDTO)[]
  newRates: NewRateDTO[]
  oppositeNewRates: NewRateDTO[]
  editRate: (quote: NewRateDTO, type?: RateType) => void
  adaptRateTabFilters: (resolvedRates: any) => void
  bodyRef: any
}

type StateProps = {}

type Props = OwnProps & StateProps

export const Body = React.memo((props: Props) => {
  const {
    readOnly,
    columnSize,
    extraBodyStyles,
    rateType,
    newRates,
    oppositeNewRates,
    editRate,
    bodyRef,
    adaptRateTabFilters,
    rateIds
  } = props
  const { setNewBSQRates, setNewSSQRates, setNewRates, setSSQIds, setBSQIds } = useQMP()

  // const hideNewRatesWhichDuplicateSelected = Boolean(customerQuote)

  // const getCheckMark = React.useCallback(
  //   (rate: AnyRateDTO) =>
  //     renderRowCheckMark({
  //       rate,
  //       selectedRates,
  //       rateType,
  //       customerQuote,
  //       newSSQRates: newRates,
  //       selectRate: newCustomerQuotes.selectRate
  //     }),
  //   [selectedRates, rateType, customerQuote, newRates.state.ssq, newCustomerQuotes.selectRate]
  // )

  const copyRate = React.useCallback(
    (rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO) => {
      const updatedOppositeNewRates = [
        ...(oppositeNewRates || []),
        ...generateOppositeColumnRateCopy({
          rate,
          rateType
        })
      ]

      if (rateType === RateType.ssq) {
        setNewBSQRates(updatedOppositeNewRates)
      } else {
        setNewSSQRates(updatedOppositeNewRates)
      }
    },
    [rateType, oppositeNewRates]
  )

  const duplicateRate = React.useCallback(
    (rate: SellSideQuoteRateDTO | BuySideQuoteRateDTO) => {
      setNewRates(rateType, [...newRates, generateRateCopy(rate)])
    },
    [rateType, newRates]
  )

  const onSaveAnyway = React.useCallback(
    (rate: NewRateDTO) => {
      const callAPIRequest =
        rateType === RateType.ssq
          ? sellSideQuoteRateAPI.createSellSideQuoteRate
          : buySideQuoteRateAPI.createBuySideQuoteRate

      callAPI(
        callAPIRequest as any,
        true,
        R.omit(rate, ['id', 'state', 'duplicateIds', 'warningMessage', 'warningMessageDescription'])
      )
        .toPromise()
        .then((data: any) => {
          const updatedRateIds = [data.id, ...rateIds]
          const addToList = rateType === RateType.ssq ? tryAddSSQRatesToStore : tryAddBSQRatesToStore

          addToList(data)

          if (rateType === RateType.ssq) {
            setSSQIds(updatedRateIds)
          } else {
            setBSQIds(updatedRateIds)
          }

          setNewRates(rateType, newRates.filter(({ id }) => id !== rate.id))

          if (adaptRateTabFilters) {
            adaptRateTabFilters([data])
          }
        })
        .catch(errors => {
          if (errors && errors.json) {
            errors.json().then((error: any) => {
              setNewRates(
                rateType,
                newRates.map(r =>
                  r.id === rate.id
                    ? {
                        ...rate,
                        state: RateState.warning,
                        duplicateIds: oc(error).duplicateIds(),
                        warningMessage: oc(error).message(),
                        warningMessageDescription: oc(error).description()
                      }
                    : r
                )
              )
            })
          } else {
            setNewRates(rateType, newRates.map(r => (r.id === rate.id ? { ...rate, state: RateState.warning } : r)))
          }
        })
    },
    [rateType, adaptRateTabFilters, newRates, rateIds]
  )

  const onDelete = React.useCallback(
    (rate: NewRateDTO) => {
      setNewRates(rateType, newRates.filter(r => r.id !== rate.id))
      // if (rate.state && selectedRates.find(({ id }) => id === rate.id)) {
      //   newCustomerQuotes.removeSelectedRate(rate.id, rateType)
      // }
    },
    [rateType, newRates]
  )

  return (
    <TableBody className={columnSize} style={extraBodyStyles} ref={bodyRef}>
      <ApplicationSizeProvider rerenderOnResize={true}>
        <RateTabBlocks
          readOnly={readOnly}
          columnSize={columnSize}
          bodyRef={bodyRef}
          rateType={rateType}
          newRates={newRates}
          rateIds={rateIds}
          onSaveAnyway={onSaveAnyway}
          onDelete={onDelete}
          editRate={editRate}
          copyRate={copyRate}
          duplicateRate={duplicateRate}
          disallowCopyRateToAnotherList={(oppositeNewRates || []).some(_ => _.id.includes('copied'))}
        />
        {/*{customerQuote ? (*/}
        {/*<CustomerQuoteTabBlocks*/}
        {/*readOnly={readOnly}*/}
        {/*customerQuote={customerQuote}*/}
        {/*columnSize={columnSize}*/}
        {/*bodyRef={bodyRef}*/}
        {/*rateType={rateType}*/}
        {/*isNewCustomerQuotes={isNewCustomerQuotes}*/}
        {/*getCheckMark={getCheckMark}*/}
        {/*selectedRates={blocksOfRates.selected}*/}
        {/*newRates={blocksOfRates.new}*/}
        {/*existedRates={blocksOfRates.existing}*/}
        {/*omitRateIds={omitRateIds}*/}
        {/*onDelete={onDelete}*/}
        {/*editRate={editRate}*/}
        {/*/>*/}
        {/*) : (*/}
        {/*<RateTabBlocks*/}
        {/*readOnly={readOnly}*/}
        {/*columnSize={columnSize}*/}
        {/*bodyRef={bodyRef}*/}
        {/*rateType={rateType}*/}
        {/*newRates={blocksOfRates.new}*/}
        {/*existedRates={blocksOfRates.existing}*/}
        {/*conflictOnSaveRates={blocksOfRates.conflictOnSave}*/}
        {/*omitRateIds={omitRateIds}*/}
        {/*onSaveAnyway={onSaveAnyway}*/}
        {/*onDelete={onDelete}*/}
        {/*editRate={editRate}*/}
        {/*copyRate={copyRate}*/}
        {/*duplicateRate={duplicateRate}*/}
        {/*disallowCopyRateToAnotherList={disallowCopyRateToAnotherList}*/}
        {/*/>*/}
        {/*)}*/}
      </ApplicationSizeProvider>
    </TableBody>
  )
})
