import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { ActivityGroupAction } from '../../../../../../../../../services/DTO/activity/controller'
import { activityStageColor, DeleteRow, QuoteColumn, StyledActivityRow } from '../../styles'
import { CustomerQuoteDTO } from '../../../../../../../../../api/origin/qmp-service'
import { VendorLocationActivityColumn } from './Column/VendorLocationActivityColumn'
import { ArrowsActivityColumn } from './Column/ArrowsActivityColumn'
import { IconsActivityColumn } from './Column/IconsActivityColumn'
import { TypeActivityColumn } from './Column/TypeActivityColumn'
import { StatusActivityColumn } from './Column/StatusActivityColumn'
import { PlannedDateActivityColumn } from './Column/PlannedDateActivityColumn'
import { StartActualDateActivityColumn } from './Column/StartActualDateActivityColumn'
import { CompletionActualDateActivityColumn } from './Column/CompletionActualDateActivityColumn'
import { DocumentsActivityColumn } from './Column/DocumentsActivityColumn'
import { calcSurcharge } from '../../../../../../../QMP/views/functions'
import theme from '../../../../../../../../../styles/theme'
import { useTransportationActivityRow } from '../../../../../../../../../hooks/useTransportationActivityRow'
import { FileCategory, IAttachment } from '../../../../../../../../../services/filesService/interfaces'
import { ActivityDocument } from './Popups/ActivityDocument/ActivityDocument'
import { IMessage } from '../../../../../../../../../store/reducers/communicationHub/interfaces'
import { transformMoney } from '../../../../../../../../UI/Input'

type Props = {
  handleRowClick: (event: React.MouseEvent) => void
  setFetching: (state: boolean) => void
  buySideQuotesAmount: Record<string, number>
  customerQuote?: CustomerQuoteDTO
}

export const TransportationRow = React.memo((props: Props) => {
  const { customerQuote, buySideQuotesAmount, setFetching, handleRowClick } = props
  const {
    stage,
    stageActivityRows,
    isLinkedActivityToBobtail,
    dropTypeActivityRowToCreateBobtail,
    pickTypeActivityRowToCreateBobtail,
    activityDocumentPopup,
    disableEditDocument,
    enableTIRDocumnet,
    enablePODDocumnet,
    podDocument,
    podDocumentMessage,
    tirDocument,
    tirDocumentMessage,
    transportationRow,
    bobtailGroup,
    gotoActivity,
    businessActivity,
    dispatchDeliveryOrder,
    businessActivityCompleteDateDisabled,
    troubleTicketId,
    currentActivityGroup,
    isDropBobtailValidationError,
    updateActivityRow,
    setActivityDocumentPopup,
    updateCompletionActualDate
  } = useTransportationActivityRow()

  let documentType: FileCategory.POD | FileCategory.TIR = undefined
  let document: IAttachment = undefined
  let documentMessage: IMessage = undefined
  const buySideQuotes = oc(dispatchDeliveryOrder).buySideQuotes([])
  const surcharges = oc(dispatchDeliveryOrder).sellSideQuote.surcharges([])
  const gotoActivityBSQ = buySideQuotes.find(bsq => bsq.activityId === gotoActivity.id)
  const businessActivityBSQ = buySideQuotes.find(bsq => bsq.activityId === businessActivity.id)
  const gotoActivitySSQSurcharge = surcharges.find(surcharge => surcharge.activityId === gotoActivity.id)
  const businessActivitySSQSurcharge = surcharges.find(surcharge => surcharge.activityId === businessActivity.id)
  const BSQAmount =
    gotoActivityBSQ || businessActivityBSQ
      ? (gotoActivityBSQ ? oc(buySideQuotesAmount)[gotoActivityBSQ.id](0) : 0) +
        (businessActivityBSQ ? oc(buySideQuotesAmount)[businessActivityBSQ.id](0) : 0)
      : undefined
  const SSQAmount =
    gotoActivitySSQSurcharge || businessActivitySSQSurcharge
      ? calcSurcharge(oc(customerQuote).amount(0), gotoActivitySSQSurcharge) +
        calcSurcharge(oc(customerQuote).amount(0), businessActivitySSQSurcharge)
      : undefined
  const deleteActivityGroupAction =
    transportationRow.permissions.deletable && !dispatchDeliveryOrder.troubleTicketId && !isLinkedActivityToBobtail
      ? () => updateActivityRow(transportationRow, ActivityGroupAction.remove)
      : undefined

  if (enablePODDocumnet) {
    documentType = FileCategory.POD
    document = podDocument
    documentMessage = podDocumentMessage
  } else if (enableTIRDocumnet) {
    documentType = FileCategory.TIR
    document = tirDocument
    documentMessage = tirDocumentMessage
  }

  return (
    <StyledActivityRow
      data-id={'transportation-activity-group'}
      className={cn('activity-group-row', {
        'trouble-ticket': troubleTicketId,
        'bobtail-button-top': pickTypeActivityRowToCreateBobtail,
        'bobtail-button-bottom': dropTypeActivityRowToCreateBobtail
      })}
      style={{
        backgroundColor: `${activityStageColor.rgba(gotoActivity.stage, 0.05)}`,
        boxShadow:
          troubleTicketId || isDropBobtailValidationError
            ? `inset 0 0 0 2px ${theme.colors.defaultRed}`
            : gotoActivity.id === oc(currentActivityGroup).gotoActivity.id()
            ? `inset 0 0 0 2px ${activityStageColor.rgba(gotoActivity.stage, 0.5)}`
            : undefined,
        marginTop:
          bobtailGroup &&
          bobtailGroup.length > 1 &&
          bobtailGroup[0].gotoActivity &&
          bobtailGroup[0].gotoActivity.id !== gotoActivity.id
            ? -1
            : undefined
      }}
      // @ts-ignore
      onClick={handleRowClick}
    >
      {oc(activityDocumentPopup).activityId() === transportationRow.activityGroup.businessActivity.id && (
        <ActivityDocument
          isActivityEditable={!businessActivityCompleteDateDisabled && !disableEditDocument}
          businessActivity={transportationRow.activityGroup.businessActivity}
          documentType={documentType}
          document={document}
          documentMessage={documentMessage}
          dispatchDeliveryOrder={dispatchDeliveryOrder}
          activityDocumentPopupState={activityDocumentPopup}
          // @ts-ignore
          onCompletionDateUpdate={date => updateCompletionActualDate({ date, isGotoActivity: false })}
          updateActivityDocumentPopupState={setActivityDocumentPopup}
        />
      )}
      <ArrowsActivityColumn />
      <VendorLocationActivityColumn />
      <IconsActivityColumn />
      <TypeActivityColumn />
      <StatusActivityColumn />
      <PlannedDateActivityColumn />
      <StartActualDateActivityColumn />
      <CompletionActualDateActivityColumn />
      <QuoteColumn data-id={'transportation-activity-group-ssq'}>{transformMoney(SSQAmount)}</QuoteColumn>
      <QuoteColumn data-id={'transportation-activity-group-bsq'}>{transformMoney(BSQAmount)}</QuoteColumn>
      <DocumentsActivityColumn />
      <DeleteRow
        data-id={'transportation-activity-group-button-delete'}
        className={cn({ disabled: !Boolean(deleteActivityGroupAction), 'mdi mdi-delete': true })}
        onClick={deleteActivityGroupAction}
      />
    </StyledActivityRow>
  )
})
