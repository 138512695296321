import { ILocation } from '../../../components/common/location/interfaces'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { oc } from 'ts-optchain'

export const correctLocation = (initialLocation: ILocation, updatedLocation: ILocation) => {
  return oc(initialLocation).id() === oc(updatedLocation).id() ? updatedLocation : initialLocation
}

export const correctDDOLocations = (location: ILocation) => (ddo: IDispatchDeliveryOrder) => {
  const updateDDOStageLocation = (stage: any) => ({
    ...stage,
    location: correctLocation(stage.location as any, location)
  })

  return {
    ...ddo,
    ...(ddo.pickupStage ? { pickupStage: updateDDOStageLocation(ddo.pickupStage) } : {}),
    ...(ddo.pickupStage ? { pickupStage: updateDDOStageLocation(ddo.pickupStage) } : {}),
    ...(ddo.pickupStage ? { pickupStage: updateDDOStageLocation(ddo.pickupStage) } : {})
  }
}
