import { reducerWithInitialState } from 'typescript-fsa-reducers'
// import { IUserState } from '../interfaces'
// import { UserWithPasswordDto } from '../../../../../api/origin/auth'
import { addNewObjectsOrReplaceWithFullObjectsToStoreList } from '../../../../../services/DTO'
import { UserDTO } from '../../../../../api/origin/user-service'
// import * as usersActions from '../action'

export const newUser: UserDTO = {
  id: 'newUser',
  email: null,
  name: null,
  username: null,
  type: null,
  firstName: null,
  lastName: null,
  mobileApp: null,
  phoneNumber: null
}

const usersState: UserDTO[] = []

const usersReducer = reducerWithInitialState<UserDTO[]>(usersState).build()

export default usersReducer
