import * as React from 'react'
import { StyledColumn } from '../../styles'
import { Header } from '../common/header'
import { BuySideQuoteDTO, ActivitiesViewDTO, DateISOString } from '../../../../../../../../api/origin/business-logic'
import { Row } from './Row'
import * as R from 'remeda'
import { getRatesByFilters } from '../../../../../../../../services/DTO/rate/epics'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../../../../api/origin/qmp-service'
import { sortBSQListByType } from '../../../functions'
import { GridItemSpinner } from '../../../../../../Spinner/Spinner'
import { suitableLoadTypes } from '../../../../../../../common/QMP/views/Tabs/Rate/functions'
import { bsqTypeGroups } from '../functions'
import { BuySideQuote } from '../../../../../../../../services/DTO/buySideQuote/interfaces'
import { IDispatchDeliveryOrder } from '../../../../../../../common/dispatchDeliveryOrder/interfaces'
import { RateType } from '../../../../../../../common/QMP/interfaces'

type OwnProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  activities: ActivitiesViewDTO
  filledFiltersByDDO: any
  buySideQuoteList: BuySideQuoteDTO[]
  totalAmount: number
  buySideQuotesAmount: Record<string, number>
  openRatesLink: (date: DateISOString) => (vendor?: { label: string; value: string }) => void
  openRateLink: (rateNumber: string) => void
  updateBuySideQuotes: (buySideQuotes: BuySideQuoteDTO[]) => void
  omitUnsuitableSurchargeTypes: SellSideQuoteRateDTO.TypeEnum[]
}

type Props = OwnProps

export const BSQColumn = (props: Props) => {
  const {
    activities,
    totalAmount,
    buySideQuotesAmount,
    buySideQuoteList,
    filledFiltersByDDO,
    openRatesLink,
    openRateLink,
    updateBuySideQuotes,
    omitUnsuitableSurchargeTypes
  } = props
  const [fetching, setFetching] = React.useState(false)
  const [applicableRates, setApplicableRates] = React.useState([])
  const [openedBuySideQuoteId, setOpenedBuySideQuoteId] = React.useState(null)
  const sortedBSQListByType: BuySideQuote[] = sortBSQListByType(buySideQuoteList, activities)

  // >>> get applicable rates
  React.useEffect(() => {
    setApplicableRates([])

    if (openedBuySideQuoteId && buySideQuoteList && buySideQuoteList.length) {
      setFetching(true)
      const rateType = RateType.bsq
      const openedBuySideQuote = buySideQuoteList.find(({ id }) => id === openedBuySideQuoteId)
      const allSurchargeIds: string[] = R.uniq(
        buySideQuoteList.reduce((acc, curr) => acc.concat(curr.surcharges.map(({ rateId }) => rateId)), [])
      )
      const calcLoadType = loadTypeFromBSQType(openedBuySideQuote.type)
      const loadType = calcLoadType ? [calcLoadType] : suitableLoadTypes(RateType.bsq, filledFiltersByDDO.loadType)
      const vendorId = openedBuySideQuote.vendorId || '%%'
      const omitRateTypes = [
        ...omitUnsuitableSurchargeTypes,
        ...omitExtraSurchargeTypes(openedBuySideQuote.type, sortedBSQListByType)
      ]

      Promise.all([
        getRatesByFilters({
          rateType,
          filledFilters: {
            ...filledFiltersByDDO,
            loadType,
            vendorId,
            quoteDate: openedBuySideQuote.date
          },
          requestParticularRateTypes:
            openedBuySideQuote.type === BuySideQuoteDTO.TypeEnum.BOBTAIL
              ? [BuySideQuoteRateDTO.TypeEnum.BOBTAIL]
              : undefined,
          omitRateTypes
        }),
        allSurchargeIds.length ? getRatesByFilters({ rateType, filledFilters: { id: allSurchargeIds } }) : null
      ]).then(([buySideQuoteRatesRequest]) => {
        setFetching(false)
        if (buySideQuoteRatesRequest.length) {
          setApplicableRates(buySideQuoteRatesRequest)
        }
      })
    }
  }, [openedBuySideQuoteId])
  // <<<

  return (
    <StyledColumn>
      {fetching && <GridItemSpinner />}
      <Header quoteType={'BSQ'} total={totalAmount} />
      {sortedBSQListByType.map(buySideQuote => (
        <Row
          key={buySideQuote.id}
          amount={buySideQuotesAmount[buySideQuote.id] || buySideQuote.amount}
          isOpen={openedBuySideQuoteId === buySideQuote.id}
          setOpenedBuySideQuoteId={setOpenedBuySideQuoteId}
          applicableRates={applicableRates}
          deleteBuySideQuote={() => updateBuySideQuotes(buySideQuoteList.filter(_ => _.id !== buySideQuote.id))}
          buySideQuote={buySideQuote}
          openRatesLink={openRatesLink(buySideQuote.date)}
          openRateLink={openRateLink}
          updateBuySideQuote={(updatedBuySideQuote: BuySideQuoteDTO) =>
            updateBuySideQuotes(
              buySideQuoteList.map(bsq => (bsq.id === updatedBuySideQuote.id ? updatedBuySideQuote : bsq))
            )
          }
        />
      ))}
      {/*<CreateBuySideQuote*/}
      {/*dispatchDeliveryOrderId={dispatchDeliveryOrder.id}*/}
      {/*transportationActivities={oc(activities).transportationActivities([])}*/}
      {/*buySideQuoteList={buySideQuoteList}*/}
      {/*updateBuySideQuotes={updateBuySideQuotes}*/}
      {/*/>*/}
    </StyledColumn>
  )
}

const loadTypeFromBSQType = (bsqType: BuySideQuoteDTO.TypeEnum): BuySideQuoteRateDTO.LoadTypeEnum => {
  if (bsqType === BuySideQuoteDTO.TypeEnum.DROPFULL || bsqType === BuySideQuoteDTO.TypeEnum.DROPEMPTY) {
    return BuySideQuoteRateDTO.LoadTypeEnum.DROP
  } else if (bsqType === BuySideQuoteDTO.TypeEnum.PICKFULL || bsqType === BuySideQuoteDTO.TypeEnum.PICKEMPTY) {
    return BuySideQuoteRateDTO.LoadTypeEnum.PICK
  } else if (bsqType === BuySideQuoteDTO.TypeEnum.LIVELOAD || bsqType === BuySideQuoteDTO.TypeEnum.LIVEUNLOAD) {
    return BuySideQuoteRateDTO.LoadTypeEnum.LIVELOAD
  }

  return null
}

const omitExtraSurchargeTypes = (
  bsqType: BuySideQuoteDTO.TypeEnum,
  bsqListByType: BuySideQuoteDTO[]
): BuySideQuoteRateDTO.TypeEnum[] => {
  const omitTypes = []
  const isShuttleInList = bsqListByType.some(bsq => bsqTypeGroups.shuttle.includes(bsq.type))

  if (!(bsqTypeGroups.delivery.includes(bsqType) && isShuttleInList)) {
    omitTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER, BuySideQuoteRateDTO.TypeEnum.DEDUCTION)
  }

  if (!bsqTypeGroups.shuttle.includes(bsqType)) {
    omitTypes.push(
      BuySideQuoteRateDTO.TypeEnum.SHUTTLE,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW,
      BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ
    )
  }

  if (bsqTypeGroups.live.includes(bsqType)) {
    omitTypes.push(BuySideQuoteRateDTO.TypeEnum.DROPANDPICKUP)
  }

  return omitTypes.filter(Boolean)
}
