import * as React from 'react'
import { oc } from 'ts-optchain'
import { ICustomerView } from '../../../interfaces'
import { CustomerInfo } from './CustomerInfo'
import PrimaryContact from '../../../../contact/views/PrimaryContact'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { ContactDTO } from '../../../../../../api/api'
import styled from 'styled-components'

export const General: React.FC<{ customer: ICustomerView; actions: IGridItemActions }> = ({ customer, actions }) => {
  if (!oc(customer).businessPartner.contacts()) {
    return null
  }

  const contacts = customer.businessPartner.contacts
  const primaryContact = contacts ? contacts.find(item => item.primary) : null

  return (
    <Container>
      <CustomerInfo customer={customer} actions={actions} />
      <PrimaryContact
        titleStyles={{ height: 25, minHeight: 25, maxHeight: 25 }}
        primaryContact={primaryContact}
        contacts={contacts}
        modifyContacts={(updatedContacts: ContactDTO[]) =>
          actions.modifyParentObjectField('businessPartner')({
            ...customer.businessPartner,
            contacts: updatedContacts
          })
        }
      />
    </Container>
  )
}

const Container = styled.div`
  min-height: 350px;
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  grid-column-gap: 40px;
  padding: 25px 60px 0 60px;
`
