import * as React from 'react'
import { connect } from 'react-redux'
import { ButtonWithIcon } from './styledComponents'
import styled from '../../../styles/styledComponents'
import { Popover } from '../../UI/Dropdowns/Popover'
import { IStore, ApplicationContext } from '../../../store/store.interface'
import { createDeliveryOrderAndTab } from '../deliveryOrder/epics'
import { addNewGridItem } from '../tabs/epics'
import { createNewTab, setActiveTabId } from '../tabs/actions'
import { ITabsStoreState, TabType } from '../tabs/interfaces'
import { ViewingObjectState } from '../../../services/viewingObjects/interfaces'
import { getStore } from '../../../store/configureStore'

type OwnProps = {}

type StateProps = {
  context: ApplicationContext
  tabs: ITabsStoreState
  viewingObjects: ViewingObjectState
}

type DispatchProps = {
  setActiveTabId: typeof setActiveTabId
  createNewTab: typeof createNewTab
}

type Props = OwnProps & StateProps & DispatchProps

export const CreateUnit = connect(
  (store: IStore) => ({
    viewingObjects: store.viewingObjects,
    context: store.applicationContext,
    tabs: store.tabs
  }),
  {
    setActiveTabId,
    createNewTab
  }
)((props: Props) => {
  const { context, tabs, viewingObjects } = props
  const [dropdownState, setDropdownState] = React.useState(false)

  const onClick = (unitType: TabType) => {
    switch (unitType) {
      case TabType.deliveryOrder:
        return createDeliveryOrderAndTab()
      case TabType.powerUnit:
      case TabType.equipment:
      case TabType.container:
      case TabType.location:
      case TabType.driver:
      case TabType.customer:
      case TabType.subClient:
      case TabType.steamShipLine:
        const suitableTab = tabs[context].find(tab => tab.type === unitType && !viewingObjects[tab.id])
        let tabId: string = undefined

        if (suitableTab) {
          tabId = suitableTab.id
          props.setActiveTabId({ tabId })
        } else {
          props.createNewTab({ tabType: unitType })
          tabId = getStore().getState().tabs.activeMainTabId
        }

        addNewGridItem({ tabId, unitType })
        return setDropdownState(false)
      default:
    }
  }

  return (
    <StyledAddButton>
      <Popover
        side={'right'}
        isOpen={dropdownState}
        byHover={true}
        lifetime={1}
        changeState={setDropdownState}
        button={<ButtonWithIcon className={'mdi mdi-plus-circle-outline'} />}
      >
        {units[context].map((unit: { value: TabType; label: string }) => (
          <div key={unit.value} onClick={() => onClick(unit.value)}>
            {unit.label}
          </div>
        ))}
      </Popover>
    </StyledAddButton>
  )
})

const StyledAddButton = styled.div`
  margin: 0 1px;
`

const units: Record<string, { value: TabType; label: string }[]> = {
  main: [
    { label: 'Power Unit', value: TabType.powerUnit },
    { label: 'Equipment', value: TabType.equipment },
    { label: 'Container', value: TabType.container },
    { label: 'Location', value: TabType.location },
    { label: 'Driver', value: TabType.driver },
    { label: 'Customer', value: TabType.customer },
    { label: 'SteamShipLine', value: TabType.steamShipLine },
    { label: 'SubClient', value: TabType.subClient },
    { label: 'Delivery Order', value: TabType.deliveryOrder }
  ],
  admin: [{ label: 'User', value: TabType.users }],
  ownObjectsTabs: [{ label: 'Delivery Order', value: TabType.deliveryOrder }]
}
