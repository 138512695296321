import * as React from 'react'
import { connect } from 'react-redux'
import { oc } from 'ts-optchain'
import DefaultGrid from '../common/grid/views'
import { ITabState, ListRequestType, TabType } from '../common/tabs/interfaces'
import { createFilterRequest } from '../../services/uiSettingsService/filter'
import * as tabActions from '../common/tabs/actions'
import { getStore } from '../../store/configureStore'
import { findAllSteamShipLines, getSteamShipLineById } from '../common/steamShipLine/epics'
import { findAllCustomers, getCustomerById } from '../common/customer/epics'
import { findAllEquipments, getEquipmentById } from '../common/equipment/epics'
import { findAllPowerUnits, getPowerUnitById } from '../common/powerUnit/epics'
import { findAllContainers, getContainerById } from '../common/containers/epics'
import { requestAllGridLocations, requestLocationById } from '../common/location/epics'
import { requestDriverById, requestDrivers } from '../common/drivers/epics'
import { findAllSubClients, getSubClientById } from '../common/subClient/epics'
import {
  findAllDroppedAtDeliveryLocation,
  findAllGridDispatchDeliveryOrders,
  getDispatchDeliveryOrderById,
  findAllDeliveryStageCompletedNoReturned,
  findAllDroppedAtYard,
  findAllPickedUpNoDelivery,
  findRecentDispatchDeliveryOrders
} from '../common/dispatchDeliveryOrder/epics'
import { ApplicationContext, IStore } from '../../store/store.interface'
import { findAllStreetTurnDispatchDeliveryOrdersForGrid } from '../../services/DTO/streetTurn/epics'
import { GridProvider } from '../../providers/GridProvider'
import { isNewId } from '../../services/DTO'

type OwnProps = {
  currentTab: ITabState
}

type StateProps = {
  viewingObjectId?: string
  isModifiedMode?: boolean
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { currentTab, viewingObjectId, isModifiedMode } = props
  const mountedRef = React.useRef(false)
  const [isFetching, setFetching] = React.useState(!currentTab.visited)

  React.useEffect(() => {
    if (!currentTab.visited) {
      if (viewingObjectId && !isNewId(viewingObjectId)) {
        findGridItem(currentTab.type)(viewingObjectId)
      }
      getList(currentTab, setFetching)
    } else if (mountedRef.current || currentTab.alwaysDoListRequestOnTabEnter) {
      // catch uiSettings changes
      getList(currentTab, setFetching)
    }

    mountedRef.current = true
  }, [currentTab.uiSettings.filter, currentTab.uiSettings.sorting])

  const fetchGridItems = React.useCallback(async (withoutSpinner?: boolean) => {
    return getList(currentTab, withoutSpinner ? () => {} : setFetching)
  }, [])

  return (
    <GridProvider fetchGridItems={fetchGridItems} setGridFetching={setFetching}>
      <DefaultGrid
        gridItemIds={currentTab.gridItemIds}
        viewingObjectId={viewingObjectId}
        isModifiedMode={isModifiedMode}
        isFetching={isFetching || currentTab.spinner}
        columnsSettings={currentTab.uiSettings.columns}
        fetchGridItems={fetchGridItems}
      />
    </GridProvider>
  )
}

export const Grid = connect((store: IStore, props: OwnProps) => {
  const viewingObject = store.viewingObjects[props.currentTab.id]

  return {
    viewingObjectId: oc(viewingObject).id(),
    isModifiedMode: Boolean(oc(viewingObject).modifiedObject())
  }
})(React.memo(Component))

const findGridItem = (type: TabType) => {
  switch (type) {
    case TabType.powerUnit:
      return getPowerUnitById
    case TabType.equipment:
      return getEquipmentById
    case TabType.container:
      return getContainerById
    case TabType.location:
      return requestLocationById
    case TabType.driver:
      return requestDriverById
    case TabType.customer:
      return getCustomerById
    case TabType.steamShipLine:
      return getSteamShipLineById
    case TabType.subClient:
      return getSubClientById
    case TabType.dispatchDeliveryOrder:
      return getDispatchDeliveryOrderById
    default:
      return () => Promise.resolve(null)
  }
}

const listRequest = (currentTab: ITabState) => {
  const { type, listRequestType, uiSettings } = currentTab

  if (listRequestType) {
    switch (listRequestType) {
      case ListRequestType.streetTurn:
        return findAllStreetTurnDispatchDeliveryOrdersForGrid
      case ListRequestType.droppedAtDeliveryLocation:
        return findAllDroppedAtDeliveryLocation
      case ListRequestType.deliveryStageCompletedNoReturned:
        return findAllDeliveryStageCompletedNoReturned
      case ListRequestType.droppedAtYard:
        return findAllDroppedAtYard
      case ListRequestType.pickedUpNoDelivery:
        return findAllPickedUpNoDelivery
      case ListRequestType.recentDispatchDeliverOrders:
        return findRecentDispatchDeliveryOrders
      default:
        return () => Promise.resolve([])
    }
  }

  const returnEmptyList = () => Promise.resolve([])

  switch (type) {
    case TabType.powerUnit:
      return findAllPowerUnits
    case TabType.equipment:
      return findAllEquipments
    case TabType.container:
      return oc(uiSettings).filter([]).length ? findAllContainers : returnEmptyList
    case TabType.location:
      return requestAllGridLocations
    case TabType.driver:
      return requestDrivers
    case TabType.customer:
      return findAllCustomers
    case TabType.steamShipLine:
      return findAllSteamShipLines
    case TabType.subClient:
      return findAllSubClients
    case TabType.dispatchDeliveryOrder:
      return findAllGridDispatchDeliveryOrders
    default:
      return returnEmptyList
  }
}

const getList = (currentTab: ITabState, setFetching: (state: boolean) => void) => {
  const { sorting, filter } = currentTab.uiSettings
  let localFetchingState = true
  setFetching(true)

  const fetch = listRequest(currentTab)
  const fetchProps = currentTab.listRequestProps || { filter: createFilterRequest(filter), sort: sorting }

  return fetch(fetchProps)
    .then(list => {
      localFetchingState = false
      setFetching(false)
      getStore().dispatch(
        tabActions.updateTabGridItems({
          context: ApplicationContext.main,
          tabId: currentTab.id,
          idList: list.map(({ id }) => id)
        })
      )
    })
    .finally(() => {
      if (localFetchingState) {
        setFetching(false)
      }
    })
}
