import * as React from 'react'
import { configureStore } from '@reduxjs/toolkit'
import userSliceReducer from './reducers/user'
import listsSliceReducer from './reducers/lists'
import communicationHubSliceReducer from './reducers/communicationHub'
import { IListsState } from './reducers/lists/interfaces'
import { UserDTO } from '../api/origin/user-service'
import { ICommunicationHub, INotifications } from './reducers/communicationHub/interfaces'

export const store = configureStore({
  reducer: {
    user: userSliceReducer,
    lists: listsSliceReducer,
    communicationHub: communicationHubSliceReducer
  }
})

export const getDispatch = (): AppDispatch => store.dispatch

export const newStoreContext: any = React.createContext({})
export const useStore = () => store
export const getNewStore = () => store
export const getNewStoreState = (): RootState => store.getState()

export const getUserState = (): UserDTO => store.getState().user
export const getListState = (): IListsState => store.getState().lists
export const getCommunicationHubState = (): ICommunicationHub => store.getState().communicationHub
export const getNotificationsState = (): INotifications => store.getState().communicationHub.notifications

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
