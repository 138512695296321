import * as React from 'react'
import { SearchByList, TDropDownItem } from '../SearchByList'
import { IStore } from '../../../../store/store.interface'
// @ts-ignore
import { debounce } from 'debounce'
import { isNewObject } from '../../../../services/DTO'

type OwnProps = {
  disabled?: boolean
  toUpperCase?: boolean
  title?: string
  required?: boolean
  label: any
  onChange: (value: any) => void
  placeholder?: string
  symbolsNumberToFetch?: number
  getList: (inputValue: string) => Promise<any>
  getDetails: (value: any) => Promise<any>
  addToStore?: any
  assembleObject?: (props: { store: IStore; id: string }) => any
  makeDropDownItem: (searchItem: any) => TDropDownItem
  requestByEnterPress?: boolean
  filtering?: boolean
  highlighted?: boolean
  focus?: boolean
  maxLength?: number
  minWidth?: number
  termRegExp?: RegExp
  filterListResultFunction?: (object: any) => boolean
}

type DispatchProps = {
  addToStoreList?: (object: any) => void
}

type Props = OwnProps & DispatchProps

export const SearchRequest = ({
  disabled,
  toUpperCase,
  title,
  required,
  label,
  onChange,
  placeholder,
  symbolsNumberToFetch = 3,
  assembleObject,
  makeDropDownItem,
  addToStore,
  getList,
  getDetails,
  requestByEnterPress,
  filtering,
  highlighted,
  focus,
  maxLength,
  minWidth,
  termRegExp,
  filterListResultFunction
}: Props) => {
  const [fetching, setFetching] = React.useState(false)
  const commonProps = {
    title,
    toUpperCase,
    required,
    label,
    placeholder,
    symbolsNumberToFetch,
    filtering,
    highlighted,
    disabled,
    maxLength,
    minWidth,
    focus,
    termRegExp
  }
  const [dropDownList, setDropDownList] = React.useState([])

  // FIND ALL ITEMS
  const getListRequest = (searchValue: string) => {
    setFetching(true)

    const term: string = searchValue.trim()

    getList(term)
      .then(list => {
        let items = list

        if (filterListResultFunction) {
          items = items.filter(filterListResultFunction)
        }

        setDropDownList(items.map(makeDropDownItem))
      })
      .finally(() => {
        setFetching(false)
      })
    // .catch(() => {
    //   setDropDownList([])
    //   setFetching(false)
    // })
  }

  // GET ALL BUTTON
  const findAll = () => getListRequest('')

  // HANDLE INPUT CHANGE
  const handleInputChange = (inputValue: string) => {
    if (inputValue.length >= symbolsNumberToFetch && !requestByEnterPress) {
      getListRequest(inputValue)
    } else if (inputValue.length !== 0) {
      setDropDownList([])
    }
  }

  // ON ENTER PRESS
  const onEnterPress = (inputValue: string) => {
    if (inputValue.length >= symbolsNumberToFetch) {
      getListRequest(inputValue)
    }
  }

  // SELECT ITEM FROM THE LIST
  const handleChange = async (value: any) => {
    if (value) {
      setFetching(true)
      await getDetails(value)
        .then(data => {
          if (addToStore && !isNewObject(data)) {
            addToStore(data)
          }
          onChange(assembleObject && !isNewObject(data) ? assembleObject({ store: undefined, id: data.id }) : data)
        })
        .finally(() => {
          setFetching(false)
        })
    } else {
      onChange(null)
    }
  }

  return (
    <SearchByList
      {...commonProps}
      onChange={handleChange}
      isFetching={fetching}
      onEnterPress={requestByEnterPress ? debounce(onEnterPress, 350) : null}
      handleInputChange={debounce(handleInputChange, 350, requestByEnterPress)}
      dropDownList={dropDownList}
      findAll={findAll}
    />
  )
}
