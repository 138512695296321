import { oc } from 'ts-optchain'
import { DDOErrorTypes, IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { IStore } from '../../../store/store.interface'
import { getStore } from '../../../store/configureStore'
import { initialActivitiesCalculation } from '../dispatchDeliveryOrder/functions'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/origin/business-logic'
import { assembleDTO } from './'
import { ActivityViewType } from '../activity/interfaces'
import { getNewStoreState, RootState } from '../../../store'

type Props = {
  id: string
  store?: IStore
  newStore?: RootState
  simplify?: boolean
  clearDeliveryOrder?: boolean
}

export const assembleDispatchDeliveryOrder = (props: Props): IDispatchDeliveryOrder => {
  const store = props.store || getStore().getState()
  const newStore = props.newStore || getNewStoreState()
  const dispatchDeliveryOrder = oc(store).dispatchDeliveryOrder[props.id]()

  if (!dispatchDeliveryOrder) {
    return null
  }

  // >>> assemble and sort activities IF dispatchDeliveryOrder.fullObject is true
  const condition = !props.simplify && dispatchDeliveryOrder.fullObject
  const sortedActivities = condition
    ? initialActivitiesCalculation({
        documentationActivities: oc(dispatchDeliveryOrder)
          .activityIds([])
          .map(id => assembleDTO.activity({ store, newStore, id, type: ActivityViewType.documentation }))
          .filter(Boolean) as DocumentationActivityDTO[],
        transportationActivities: oc(dispatchDeliveryOrder)
          .activityIds([])
          .map(id => assembleDTO.activity({ store, newStore, id, type: ActivityViewType.transportation }))
          .filter(Boolean) as TransportationActivityViewDTO[]
      })
    : {
        transportationActivities: [],
        documentationActivities: [],
        activityGroups: undefined,
        error: undefined
      }
  // <<<

  return {
    ...dispatchDeliveryOrder,
    buySideQuotes: (dispatchDeliveryOrder.buySideQuoteIds || []).map(id => store.buySideQuotes[id]).filter(Boolean),
    sellSideQuote: dispatchDeliveryOrder.sellSideQuoteId
      ? store.sellSideQuotes[dispatchDeliveryOrder.sellSideQuoteId]
      : undefined,
    equipment: store.equipment[dispatchDeliveryOrder.equipmentId] || dispatchDeliveryOrder.equipment,
    container:
      assembleDTO.container({ store, id: dispatchDeliveryOrder.containerId }) || dispatchDeliveryOrder.container,
    pickupStage: {
      ...oc(dispatchDeliveryOrder).pickupStage({}),
      location:
        assembleDTO.location({ store, id: oc(dispatchDeliveryOrder).pickupStage.locationId() }) ||
        oc(dispatchDeliveryOrder).pickupStage.location()
    },
    deliveryStage: {
      ...oc(dispatchDeliveryOrder).deliveryStage({}),
      location:
        assembleDTO.location({ store, id: oc(dispatchDeliveryOrder).deliveryStage.locationId() }) ||
        oc(dispatchDeliveryOrder).deliveryStage.location()
    },
    returnStage: {
      ...oc(dispatchDeliveryOrder).returnStage({}),
      location:
        assembleDTO.location({ store, id: oc(dispatchDeliveryOrder).returnStage.locationId() }) ||
        oc(dispatchDeliveryOrder).returnStage.location()
    },
    deliveryOrder: props.clearDeliveryOrder
      ? null
      : assembleDTO.deliveryOrder({ store, newStore, id: dispatchDeliveryOrder.deliveryOrderId, simplify: true }) ||
        dispatchDeliveryOrder.deliveryOrder,
    activities: {
      transportationActivities: sortedActivities.transportationActivities,
      documentationActivities: sortedActivities.documentationActivities
    },
    activityGroups: sortedActivities.activityGroups,
    streetTurn: store.streetTurn[dispatchDeliveryOrder.streetTurnId] || dispatchDeliveryOrder.streetTurn,
    error: sortedActivities.error
      ? {
          type: DDOErrorTypes.activity,
          message: sortedActivities.error
        }
      : undefined
  }
}
