import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'

export const subClientFilterSettings = {
  Name: {
    name: 'businessPartner.legalName',
    type: GridHeaderColumnType.string
  },
  Address: {
    name: 'businessPartner.legalAddress',
    type: GridHeaderColumnType.string
  },
  Customer: {
    name: 'customer.businessPartner.legalName',
    type: GridHeaderColumnType.string
  },
  'Primary Contact': {
    name: 'businessPartner.primaryContact.firstName',
    type: GridHeaderColumnType.string
  }
}
