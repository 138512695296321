import { ContainerViewDTO, SteamShipLineViewDTO } from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddContainerToStore } from '../../../components/common/containers/epics'
import { parseDTO } from '../parseDTO'

export const parseContainers = (containers: ContainerViewDTO[]) => {
  const lists: { steamShipLines: SteamShipLineViewDTO[] } = {
    steamShipLines: []
  }

  const clearedContainers = containers.filter(Boolean).map(container => {
    const { steamShipLine } = container
    if (steamShipLine) {
      lists.steamShipLines.push(steamShipLine as SteamShipLineViewDTO)
    }
    return R.omit(container, ['steamShipLine', 'containerType'])
  })

  parseDTO.steamShipLine(lists.steamShipLines)
  tryAddContainerToStore(clearedContainers)
}
