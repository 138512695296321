import * as React from 'react'
import * as ReactDom from 'react-dom'
import { oc } from 'ts-optchain'
import { ZipValidationInput } from './Input/Validation'
import { Container, ColumnRow, Column } from './GridSystem'
import { Input } from './DataFields/Input'
import { AddressDTO } from '../../api/api'
import { StyledAddressField } from '../common/drivers/views/Details/General/styledComponents'
import { LocationSearch } from './DataFields/SearchRequest/location/fromGoogle'
import { defaultAddress, newLocation } from '../common/location/reducers'
import { createAddressLine, getStateInfo } from '../../services/addressService'
import { FieldTemplate } from './DataFields/Templates'
import { FieldContainer } from './DataFields/FieldContainer'

type Props = {
  address: AddressDTO
  onChange: (address: AddressDTO) => void
  title?: string
  required?: boolean
  highlighted?: boolean
}

type State = { open: boolean }

class AddressField extends React.PureComponent<Props, State> {
  address: AddressDTO

  state = { open: false }

  render() {
    const { address, title, highlighted, required } = this.props
    const { open } = this.state
    this.address = defaultAddress()

    if (address) {
      Object.keys(address).map(key => {
        this.address[key] = address[key]
      })
    }

    const fakeLocation = { ...newLocation(), address: this.address }
    const addressLabel = createAddressLine(this.address)
    fakeLocation.name = addressLabel

    return (
      <StyledAddressField
        className={open ? 'open' : ''}
        rows={title ? 17 : 0}
        onClick={() => this.togglePopover()}
        margin={title ? { top: -15, bottom: 10, left: -20, right: -20 } : { right: open ? -20 : 0 }}
        isColumn={!!title}
      >
        <Container
          padding={title ? { top: 15, bottom: 15, left: 20, right: 20 } : { top: 15, bottom: 15, left: 0, right: 0 }}
        >
          {open ? (
            <LocationSearch
              title={title}
              focus={!addressLabel}
              value={addressLabel ? fakeLocation : null}
              onChange={changedLocation => this.changeAddress('address', changedLocation)}
            />
          ) : (
            <Input
              title={title}
              highlighted={highlighted}
              required={required}
              value={addressLabel}
              onChange={value => (value === null ? this.changeAddress('address', null) : null)}
            />
          )}
        </Container>
        {open && (
          <Container
            rows={54}
            isGrid={true}
            background={'white'}
            padding={{ top: 20, bottom: 20, left: 50, right: 50 }}
          >
            <ColumnRow margin={{ bottom: 30 }}>
              <Input
                title={'Address'}
                required={true}
                maxLength={250}
                value={this.address.street}
                onChange={(value: string) => this.changeAddress('street', value)}
              />
            </ColumnRow>

            <Column>
              <Column columns={8} isGrid={true} margin={{ right: 20 }}>
                <ColumnRow margin={{ bottom: 30 }}>
                  <Input
                    title={'City'}
                    required={true}
                    maxLength={200}
                    value={this.address.city}
                    onChange={(value: string) => this.changeAddress('city', value)}
                  />
                </ColumnRow>
                <ColumnRow>
                  <FieldContainer title={'Country'} wrapperHeight={35}>
                    {getStateInfo(oc(address).stateId(null)).countryCode}
                  </FieldContainer>
                </ColumnRow>
              </Column>

              <Column columns={8} isGrid={true}>
                <ColumnRow margin={{ bottom: 30 }}>
                  <FieldTemplate.StateSearch
                    required={true}
                    title={'State'}
                    stateId={oc(address).stateId(null)}
                    onChange={(stateId: string) => this.changeAddress('stateId', stateId)}
                  />
                </ColumnRow>
                <ColumnRow>
                  <ZipValidationInput
                    title={'ZIP'}
                    required={true}
                    value={this.address.postalCode}
                    onChange={value => this.changeAddress('postalCode', value)}
                  />
                </ColumnRow>
              </Column>
            </Column>
          </Container>
        )}
      </StyledAddressField>
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.outsideClickListener)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outsideClickListener)
  }

  private togglePopover = (state: boolean = true) => {
    this.setState(this.state.open !== state ? { open: state } : null)
  }

  private outsideClickListener = (e: any) => {
    const box = ReactDom.findDOMNode(this) as HTMLDivElement
    if (!box.contains(e.target)) {
      setTimeout(() => this.togglePopover(false), 100)
    }
  }

  changeAddress = (fieldName: string, value: any) => {
    let resultAddress: AddressDTO

    switch (fieldName) {
      case 'street':
      case 'street2':
      case 'city':
      case 'stateId':
      case 'postalCode': {
        resultAddress = { ...this.props.address, [fieldName]: value }
        break
      }
      case 'address': {
        resultAddress = {
          ...this.address,
          city: oc(value).address.city(null),
          postalCode: oc(value).address.postalCode(null),
          stateId: oc(value).address.stateId(null),
          street: oc(value).address.street(null),
          street2: oc(value).address.street2(null)
        }
        break
      }
      default:
        break
    }

    if (Object.values(resultAddress).every(field => !field)) {
      if (!this.props.address) {
        return
      } else {
        return this.props.onChange(undefined)
      }
    }

    return this.props.onChange(resultAddress)
  }
}

export default AddressField
