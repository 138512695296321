import { EquipmentDTO } from '../../../../api/origin/business-logic/api'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { equipmentDirectory } from '../../../../services/DTO/equipment/directory'
import { getStateInfo } from '../../../../services/addressService'
import { oc } from 'ts-optchain'
import { getDateFromISOString } from '../../../../services/timeService/dateUtils'

export const headers = {
  'Equipment #': { width: 160, active: true },
  'Active / Availability': { width: 260, active: true },
  Type: { width: 180, active: true },
  Year: { width: 160, active: true },
  Make: { width: 160, active: true },
  Model: { width: 160, active: true },
  'Max Cargo Weight': { width: 160, active: true },
  Ownership: { width: 160, active: true },
  'License Plate #': { width: 160, active: true },
  'Registration State': { width: 160, active: true },
  'Registration Date': { width: 160, active: true },
  'Registration Exp. Date': { width: 160, active: true },
  'DOT Safety Inspection Date': { width: 180, active: true },
  'DOT Safety Inspection Annual Exp. Date': {
    width: 190,
    active: true
  },
  'Maintenance Inspection Frequency': {
    width: 170,
    active: true
  },
  'Insurance Type': {
    width: 160,
    active: true
  },
  'Insurance Carrier': { width: 160, active: true },
  'Insurance Exp. Date': { width: 160, active: true },
  'Insurance Policy #': { width: 160, active: true }
}

export const adaptRowValues = (unit: EquipmentDTO): TColumns => {
  const columnValues = {
    'Equipment #': unit.chassisNumber,
    'Active / Availability': unit,
    Type: unit.equipmentType ? equipmentDirectory.equipmentType[unit.equipmentType] : '',
    Year: unit.year || '',
    Make: unit.make || '',
    Model: unit.model || '',
    'Max Cargo Weight': unit.weight && unit.weightUnit ? unit.weight + ' ' + unit.weightUnit : '',
    Ownership: unit.ownership ? equipmentDirectory.ownership[unit.ownership] : '',
    'License Plate #': oc(unit).licensePlate(''),
    'Registration State': getStateInfo(unit.registration.stateId).name,
    'Registration Date': getDateFromISOString(unit.registration.registeredDate),
    'Registration Exp. Date': getDateFromISOString(unit.registration.expirationDate),
    'DOT Safety Inspection Date': getDateFromISOString(unit.dotSafetyInspection.performedDate),
    'DOT Safety Inspection Annual Exp. Date': getDateFromISOString(unit.dotSafetyInspection.expirationDate),
    'Maintenance Inspection Frequency': unit.maintenanceInspectionFrequency
      ? equipmentDirectory.maintenanceInspectionFrequency[unit.maintenanceInspectionFrequency]
      : '',
    'Insurance Type': unit.insurance.insuranceType
      ? equipmentDirectory.insuranceType[unit.insurance.insuranceType]
      : '',
    'Insurance Carrier': oc(unit).insurance.carrier(''),
    'Insurance Exp. Date': getDateFromISOString(unit.insurance.expirationDate),
    'Insurance Policy #': oc(unit).insurance.policyNumber('')
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
