import { oc } from 'ts-optchain'
import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../../api/api'
import { isDocumentationActivity } from '../test/isDocumentationActivity'
import { isGotoActivity } from '../test/isGotoActivity'
import { ActivityGroup, DocumentationActivityGroup, TransportationActivityGroup } from '../../DTO/activity/interfaces'

export const getGroupedActivitiesByGroupId = (
  activities: (TransportationActivityViewDTO | DocumentationActivityDTO)[]
): ActivityGroup[] => {
  if (!Array.isArray(activities)) {
    return []
  }

  const groupByGroupId: Record<string, ActivityGroup> = {}

  activities.forEach(activity => {
    if (!groupByGroupId[activity.groupId]) {
      groupByGroupId[activity.groupId] = {} as ActivityGroup
    }

    const group = groupByGroupId[activity.groupId]

    if (isGotoActivity(activity)) {
      // @ts-ignore
      group.gotoActivity = activity
    } else if (isDocumentationActivity(activity)) {
      // @ts-ignore
      group.documentationActivity = activity as DocumentationActivityDTO
    } else {
      // @ts-ignore
      group.businessActivity = activity
    }
  })

  return Object.values(groupByGroupId).sort((group1, group2) => {
    const number1 =
      oc(group1 as TransportationActivityGroup).gotoActivity.number(0) ||
      oc(group1 as TransportationActivityGroup).businessActivity.number(0) ||
      oc(group1 as DocumentationActivityGroup).documentationActivity.number(0)
    const number2 =
      oc(group2 as TransportationActivityGroup).gotoActivity.number(0) ||
      oc(group2 as TransportationActivityGroup).businessActivity.number(0) ||
      oc(group2 as DocumentationActivityGroup).documentationActivity.number(0)

    return number1 - number2
  })
}
