import * as React from 'react'
import { TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../grid/views/interfaces'

const Column = (props: TGridColumn) => {
  const { value, className, style } = props

  return (
    <TableCell className={className} title={String(value)} style={style}>
      <div>{value || ''}</div>
    </TableCell>
  )
}

export default Column
