import * as React from 'react'
import { useDebounce } from '../hooks'

const fetchEnd = <T>(list: T[]) => ({ type: 'FETCH_END', payload: list } as const)
const fetchStart = () => ({ type: 'FETCH_START' } as const)
const fetchError = () => ({ type: 'FETCH_ERROR' } as const)

type Action = ReturnType<typeof fetchEnd | typeof fetchStart | typeof fetchError>

type State<T extends {}> = {
  list: T[]
  isFetchingList: boolean
  fetchError: boolean
  isNotFound: boolean
}

const initialState: State<any> = { list: [], isFetchingList: false, fetchError: false, isNotFound: false }

const reducer = (state: State<any>, action: Action) => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, list: [], fetchError: false, isNotFound: false, isFetchingList: true }
    case 'FETCH_END':
      return {
        ...state,
        isFetchingList: false,
        fetchError: false,
        isNotFound: !Boolean(action.payload.length),
        list: action.payload
      }
    case 'FETCH_ERROR':
      return { ...state, isFetchingList: false, list: [], isNotFound: false, fetchError: true }

    default:
      return state
  }
}

export const useFetchList = <T>({
  term,
  func,
  isSearch = true
}: {
  term?: string
  func: (term: string) => Promise<T[]>
  isSearch?: boolean
}) => {
  const [{ list, isFetchingList, isNotFound }, dispatch] = React.useReducer(reducer, initialState)

  const fetchList = () => {
    if (term.length && !isFetchingList) {
      dispatch(fetchStart())
      func(term)
        .then(result => dispatch(fetchEnd(result)))
        .catch(() => dispatch(fetchError()))
    }
  }

  useDebounce(
    () => {
      if (term.length >= 3 && isSearch) {
        fetchList()
      }
    },
    500,
    [term]
  )

  return { list, isFetchingList, fetchList, isNotFound }
}
