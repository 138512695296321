import * as yup from 'yup'
import { ContainerValidator } from './DTO/container/ContainerValidation'
import { isValidEmail } from '../components/UI/Input/Validation/Email'
import { schemaDeliveryOrderGridItem } from './DTO/deliveryOrder'
import { CustomerDTO, DriverViewDTO, EquipmentDTO, LocationDTO, PowerUnitDTO } from '../api/origin/business-logic'
import { equipmentNumberRegex } from './DTO/equipment'
import { isDriverFieldRequired } from './functions/test/isDriverFieldRequired'

export const emptyStringToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const isValidContainerNumber = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  if (value.length === 11) {
    const validator = new ContainerValidator()
    return validator.isValid(value)
  }
  return false
}

export const schemaContainerNumber = yup
  .string()
  .test('is-container-number-valid', 'You must enter a valid number', value => isValidContainerNumber(value))
  .required()

class Email extends yup.string {
  _validFormats: any = []
  constructor() {
    super()

    this.withMutation(() => {
      this.transform(function(value, originalvalue) {
        if (this.isType(value)) {
          return value
        }
        return originalvalue
      })
    })
  }

  _typeCheck(value: string) {
    return isValidEmail(value)
  }

  format(formats: string) {
    if (!formats) {
      throw new Error('must enter a valid format')
    }
    const next = this.clone()
    next._validFormats = [].concat(formats)
  }
}

export const schemaMaintenanceInspection = yup.object().shape({
  documentFile: yup.object().required(),
  expirationDate: yup.string().required(),
  performedDate: yup.string().required()
})

export const schemaPowerUnitRegistration = yup.object().shape({
  document: yup.string().nullable(true),
  expirationDate: yup.string().nullable(true),
  registeredDate: yup.string().nullable(true),
  restrictions: yup.mixed().oneOf(['NONE', 'HIGHWAY', 'BOBTAIL']),
  stateId: yup.string().nullable(true),
  weight: yup.number().required(),
  weightUnit: yup.mixed().oneOf(['KGS', 'LBS'])
})

export const schemaDotSafetyInspection = yup.object().shape({
  document: yup.string().nullable(true),
  expirationDate: yup.string().nullable(true),
  performedDate: yup.string().nullable(true)
})

export const schemaInsurance = yup.object().shape({
  carrier: yup
    .string()
    .min(2)
    .max(64)
    .nullable(true),
  coverageCard: yup.string().nullable(true),
  expirationDate: yup.string().nullable(true),
  insuranceType: yup
    .string()
    .matches(/LIABILITY|PROPERTY|BOBTAIL/)
    .nullable(true),
  policyNumber: yup
    .string()
    .min(8)
    .max(24)
    .nullable(true)
})

export const schemaRepair = yup.object().shape({
  billActual: yup.number().nullable(true),
  costEstimate: yup.number().nullable(true),
  description: yup
    .string()
    .min(2)
    .max(512)
    .nullable(true)
    .required(),
  equipmentHours: yup.number().nullable(true),
  finishDate: yup.string().required(),
  powerUnitMileage: yup.number().nullable(true),
  receiptDocument: yup
    .string()
    .min(0)
    .max(255)
    .nullable(true),
  repairType: yup
    .string()
    .matches(
      /MAINTENANCE_OIL_FILTERS|MAINTENANCE_TIRES|MAINTENANCE_GREASE|MAINTENANCE_OTHER|BREAKDOWN|PREVENTIVE_MAINTENANCE/
    )
    .required(),
  shopName: yup
    .string()
    .min(0)
    .max(100)
    .nullable(true),
  startDate: yup.string().required()
})

export const schemaDriverSearch = yup.object().shape({
  firstName: yup.string().nullable(true),
  lastName: yup.string().nullable(true)
})

export const schemaPowerUnit = yup.object().shape({
  active: yup.boolean(),
  availability: yup.boolean(),
  dotSafetyInspection: schemaDotSafetyInspection,
  driverIds: yup.array().of(yup.string()),
  drivers: yup.array().of(schemaDriverSearch),
  inactiveReason: yup
    .string()
    .oneOf([...Object.keys(PowerUnitDTO.InactiveReasonEnum), null])
    .nullable(true),
  insurance: schemaInsurance,
  licensePlate: yup
    .string()
    .min(6)
    .max(12)
    .nullable(true),
  maintenanceInspectionFrequency: yup
    .string()
    .matches(/MONTHLY|QUARTERLY|SEMI_ANNUALLY|ANNUALLY|OTHER/)
    .nullable(true),
  maintenanceInspections: yup
    .array()
    .of(schemaMaintenanceInspection)
    .min(1, 'at least one maintenanceInspection required'),
  make: yup
    .string()
    .min(2)
    .max(64)
    .nullable(true),
  model: yup
    .string()
    .min(2)
    .max(64)
    .nullable(true),
  number: yup.number().nullable(true),
  ownership: yup
    .string()
    .matches(/OWNED|LEASED|RENTED|OWNER_OPERATED/)
    .nullable(true),
  portPassRfid: yup.boolean(),
  powerUnitSubType: yup
    .string()
    .max(250)
    .nullable(true),
  powerUnitType: yup
    .mixed()
    .oneOf(['TRACTOR', 'BOX_TRUCK', 'BOBTAIL'])
    .required(),
  registration: schemaPowerUnitRegistration,
  repairs: yup.array().of(schemaRepair),
  rfid: yup.string().nullable(true),
  specs: yup
    .string()
    .min(0)
    .max(1024)
    .nullable(true),
  truckType: yup.mixed().oneOf(['SLEEPER', 'DAY_CAP', 'BOBTAIL']),
  unavailabilityReason: yup
    .string()
    .matches(/ASSIGNED|IN_USE|INACTIVE/)
    .nullable(true),
  vin: yup
    .string()
    .min(11)
    .max(17)
    .nullable(true),
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS']),
  year: yup
    .number()
    .min(1900)
    .max(2100)
    .nullable(true)
})

export const schemaAddress = yup.object().shape({
  city: yup
    .string()
    .max(200)
    .required(),
  postalCode: yup
    .string()
    .min(5)
    .required()
    .transform(emptyStringToNull),
  stateId: yup.string().required(),
  street: yup
    .string()
    .max(250)
    .required(),
  street2: yup
    .string()
    .max(250)
    .nullable(true)
    .notRequired()
})

export const schemaContact = yup.object().shape(
  {
    email: new Email().nullable(true).notRequired(),
    fax: yup
      .string()
      .min(10)
      .nullable(true),
    firstName: yup
      .string()
      .min(0)
      .max(100)
      .nullable(true),
    lastName: yup
      .string()
      .min(0)
      .max(100)
      .nullable(true),
    mobile: yup
      .string()
      .min(0)
      .max(50)
      .nullable(true),
    phone: yup
      .string()
      .min(10)
      .nullable(true)
      .notRequired(),
    phone2: yup
      .string()
      .min(10)
      .nullable(true),
    primary: yup
      .boolean()
      .nullable(true)
      .notRequired(),
    notes: yup
      .string()
      // .max(1000)
      .nullable(true)
      .notRequired()
  },
  [['phone', 'email'], ['email', 'phone']]
)

export const schemaBusinessPartner = yup.object().shape({
  contactIds: yup.array().of(yup.string()),
  contacts: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .min(0)
        .max(100)
        .nullable(true),
      lastName: yup
        .string()
        .min(0)
        .max(100)
        .nullable(true),
      phone: yup
        .string()
        .min(10)
        .notRequired()
        .nullable(true),
      email: new Email().nullable(true).notRequired()
    })
  ),
  dbaName: yup.string().nullable(true),
  legalAddress: schemaAddress,
  legalName: yup.string().required(),
  locationIds: yup.array().of(yup.string()),
  notes: yup.string().nullable(true),
  parentId: yup.string().nullable(true),
  primaryContact: schemaContact
    .nullable(true)
    .default(undefined)
    .notRequired(),
  type: yup
    .mixed()
    .oneOf(['DIVISION', 'ORGANIZATION', 'INDIVIDUAL'])
    .nullable(true),
  webSite: yup.string().nullable(true),
  workingHours: yup.string().nullable(true)
})

export const schemaCustomer = yup.object().shape({
  businessPartner: schemaBusinessPartner,
  name: yup.string().nullable(true),
  netTerms: yup.mixed().oneOf(Object.values(CustomerDTO.NetTermsEnum)),
  netTermsUnit: yup.mixed().oneOf(Object.values(CustomerDTO.NetTermsUnitEnum)),
  subClientIds: yup.array().of(yup.string())
})

export const schemaSubClient = yup.object().shape({
  businessPartner: schemaBusinessPartner,
  // customer: schemaCustomer,
  customerId: yup.string().required(),
  name: yup.string().nullable(true)
})

export const schemaCustomerView = schemaCustomer.shape({ subClients: yup.array().of(schemaSubClient) })

export const schemaFeeSchedule = yup.object().shape({
  cost: yup
    .number()
    .min(0)
    .nullable(true),
  fromDay: yup.string().nullable(true), // todo
  freeDetentionAllowance: yup
    .number()
    .min(0)
    .nullable(true),
  toDay: yup
    .number()
    .min(0)
    .nullable(true)
})

export const schemaScac = yup
  .string()
  .matches(/[[\d|A-Z]{4}/, 'numbers and all uppercase chars')
  .nullable(false)
  .required()

export const schemaSteamShipLineView = yup.object().shape({
  businessPartner: schemaBusinessPartner.shape({ legalAddress: yup.object().nullable(true) }),
  containerDetentionPerDiemFeeSchedule: yup.array().of(schemaFeeSchedule),
  freeDetentionAllowance: yup
    .number()
    .min(0)
    .nullable(true),
  scac: schemaScac,
  name: yup.string().nullable(true),
  referenceNumber: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true)
})

export const schemaLocationView = yup.object().shape({
  address: schemaAddress,
  altitude: yup.number().nullable(true),
  careOf: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  code: yup
    .string()
    .min(0)
    .max(6)
    .nullable(true),
  contactIds: yup.array().of(yup.string()),
  description: yup
    .string()
    .min(0)
    .max(512)
    .nullable(true),
  latitude: yup.number().nullable(true),
  longName: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  longitude: yup.number().nullable(true),
  name: yup
    .string()
    .min(1)
    .max(225)
    .required(),
  notes: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  placeId: yup.string().nullable(true),
  primary: yup.boolean(),
  primaryContactId: yup.string().nullable(true),
  rating: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  shortName: yup.string().nullable(true),
  timezone: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  type: yup.mixed().oneOf(Object.keys(LocationDTO.TypeEnum)),
  unlocode: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  webSite: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true),
  workingHours: yup
    .string()
    .min(0)
    .max(225)
    .nullable(true)
})

export const schemaLocation = yup.lazy((value: any) =>
  (value && value.fullObject === true ? schemaLocationView : yup.mixed())
    .nullable(true)
    .notRequired()
    .default(undefined)
)

export const schemaCustomerLazy = yup.lazy((value: any) =>
  (value && value.fullObject === true ? schemaCustomer : yup.mixed())
    .nullable(true)
    .notRequired()
    .default(undefined)
)

export const schemaContainerType = yup.object().shape({
  isoCode: yup
    .string()
    .min(0)
    .max(4)
    .nullable(true),
  name: yup
    .string()
    .min(0)
    .max(250)
    .required()
})

export const schemaContainerView = yup.object().shape({
  active: yup.boolean(),
  availability: yup.boolean(),
  containerType: schemaContainerType,
  containerTypeId: yup.string().required(),
  inactiveReason: yup.mixed().oneOf(['REPAIR', null]),
  make: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true),
  model: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true),
  number: schemaContainerNumber,
  ownership: yup.mixed().oneOf(['SHIPPER_OWNED', 'LINE_OWNED']),
  serialNumber: yup
    .string()
    .min(0)
    .max(17)
    .nullable(true),
  specs: yup
    .string()
    .min(0)
    .max(1024)
    .nullable(true),
  steamShipLine: yup
    .object()
    .nullable(true)
    .notRequired(),
  steamShipLineId: yup
    .string()
    .when('ownership', (ownership: string, schema: yup.StringSchema) =>
      ownership === 'LINE_OWNED' ? schema.required() : schema.nullable(true).notRequired()
    ),
  unavailabilityReason: yup.mixed().oneOf(['ASSIGNED', 'IN_USE', 'INACTIVE', null]),
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS']),
  year: yup
    .number()
    .min(1900)
    .max(2100)
    .nullable(true)
})

export const schemaContainer = yup.object().shape({
  active: yup.boolean(),
  availability: yup.boolean(),
  containerTypeId: yup.string().required(),
  inactiveReason: yup.mixed().oneOf(['REPAIR', null]),
  make: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true),
  model: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true),
  number: schemaContainerNumber,
  ownership: yup
    .mixed()
    .oneOf(['SHIPPER_OWNED', 'LINE_OWNED'])
    .required(),

  serialNumber: yup
    .string()
    .min(0)
    .max(17)
    .nullable(true),
  specs: yup
    .string()
    .min(0)
    .max(1024)
    .nullable(true),
  steamShipLineId: yup.string().nullable(true),
  unavailabilityReason: yup.mixed().oneOf(['ASSIGNED', 'IN_USE', 'INACTIVE', null]),
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS', null]),
  year: yup
    .number()
    .min(1900)
    .max(2100)
    .nullable(true)
})

export const schemaPowerUnitSearch = yup.object().shape({
  licensePlate: yup.string().nullable(true),
  make: yup.string().nullable(true),
  model: yup.string().nullable(true),
  year: yup.number().nullable(true)
})

export const schemaDriverView = yup.object().shape({
  mobileApp: yup
    .boolean()
    .notRequired()
    .nullable(true),
  active: yup.boolean(),
  availability: yup.boolean(),
  birthday: yup.string().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ status, driverType } as any).birthday,
    then: yup.string().required()
  }),
  driverType: yup.mixed().oneOf(Object.values(DriverViewDTO.DriverTypeEnum)),
  ezPassTagNumber: yup
    .string()
    .length(11)
    .notRequired()
    .nullable(true),
  fuelCardNumber: yup
    .string()
    .length(19)
    .notRequired()
    .nullable(true),
  eld: yup.boolean().nullable(true),
  eldNumber: yup
    .string()
    .min(0)
    .max(255)
    .nullable(true),
  email: new Email()
    .max(40)
    .when(
      ['status', 'driverType'],
      (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum, schema: yup.StringSchema) => {
        return isDriverFieldRequired({ status, driverType } as any).email
          ? schema.required()
          : schema.notRequired().nullable(true)
      }
    ),
  endorsement: yup
    .array()
    .of(yup.string())
    .notRequired()
    .nullable(true),
  firstName: yup
    .string()
    .min(1)
    .max(32)
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  ssn: yup
    .string()
    .min(9)
    .max(9)
    .when(
      ['status', 'driverType', 'hasSSN'],
      (
        status: DriverViewDTO.StatusEnum,
        driverType: DriverViewDTO.DriverTypeEnum,
        hasSSN: boolean,
        schema: yup.StringSchema
      ) => {
        return isDriverFieldRequired({ status, driverType } as any).ssn && !hasSSN
          ? schema.required()
          : schema.notRequired().nullable(true)
      }
    ),
  // ssn: yup.lazy(value =>
  //   value === ''
  //     ? yup.string()
  //     : yup
  //         .string()
  // .min(9)
  // .max(9)
  //         .nullable(true)
  // ),
  taxId: yup
    .string()
    .min(9)
    .max(9)
    .when('$driverType', (driverType: DriverViewDTO.DriverTypeEnum, schema: yup.StringSchema) =>
      driverType === DriverViewDTO.DriverTypeEnum.VENDOR ? schema.required() : schema.notRequired().nullable(true)
    ),
  inactiveReason: yup
    .mixed()
    .oneOf([...Object.values(DriverViewDTO.InactiveReasonEnum), null, undefined])
    .notRequired()
    .nullable(true),
  lastName: yup
    .string()
    .min(1)
    .max(64)
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  proofEinTaxIdDocumentFile: yup
    .object()
    .when('$driverType', (driverType: DriverViewDTO.DriverTypeEnum, schema: yup.StringSchema) => {
      return driverType === DriverViewDTO.DriverTypeEnum.VENDOR
        ? schema.required()
        : schema.notRequired().nullable(true)
    }),
  // licenseAddress: schemaAddress
  //   .notRequired()
  //   .default(undefined)
  //   .nullable(true),
  licenseDocument: yup.string().nullable(true),
  licenseExpDate: yup
    .string()
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  licenseIssueDate: yup
    .string()
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  licenseIssueStateId: yup
    .string()
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  licenseNumber: yup
    .string()
    .min(0)
    .max(100)
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  licenseDocumentFile: yup.object().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ driverType, status } as any).licenseDocumentFile,
    then: yup.object().required()
  }),
  medicalExamCardDocument: yup.string().nullable(true),
  medicalExamCardExpDate: yup.string().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ status, driverType } as any).medicalExamCardExpDate,
    then: yup.string().required()
  }),
  middleName: yup
    .string()
    .min(0)
    .max(64)
    .nullable(true),
  millageRestriction: yup
    .mixed()
    .oneOf(['LESS_THEN_100_MILES', 'BETWEEN_100_AND_150_MILES', 'BETWEEN_150_AND_300_MILES', 'MORE_THEN_300_MILES'])
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.required() : schema.notRequired().nullable(true)
    ),
  name: yup
    .string()
    .max(200)
    .when('$isDriver', (isDriver: boolean, schema: yup.StringSchema) =>
      isDriver ? schema.notRequired().nullable(true) : schema.required()
    ),
  notes: yup
    .string()
    .min(0)
    .max(1024)
    .nullable(true),
  number: yup.string().nullable(true),
  operationalPreference: yup.mixed().oneOf(['SINGLE', 'TEAM']),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .when('$driverType', (driverType: DriverViewDTO.DriverTypeEnum, schema: yup.StringSchema) =>
      driverType === DriverViewDTO.DriverTypeEnum.VENDOR ? schema.notRequired().nullable(true) : schema.required()
    ),
  portCredentialsDocument: yup.string().nullable(true),
  portCredentialsExpDate: yup.string().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ status, driverType } as any).portCredentialsExpDate,
    then: yup.string().required()
  }),
  powerUnit: schemaPowerUnitSearch.nullable(true),
  powerUnitId: yup.string().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ status, driverType } as any).powerUnitId,
    then: yup.string().required()
  }),
  twicDocument: yup.string().nullable(true),
  twicExpDate: yup.string().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ status, driverType } as any).twicExpDate,
    then: yup.string().required()
  }),
  unavailabilityReason: yup
    .mixed()
    .oneOf(['ASSIGNED', 'IN_USE', 'INACTIVE', null, undefined])
    .nullable(true),
  w4Document: yup.string().nullable(true),
  w9Document: yup.string().nullable(true),
  w9DocumentFile: yup.object().when(['driverType', 'parentId'], {
    is: (driverType: DriverViewDTO.DriverTypeEnum, parentId: string) =>
      isDriverFieldRequired({ driverType, parentId } as any).w9DocumentFile,
    then: yup.object().required()
  }),
  i9DocumentFile: yup.object().when(['driverType', 'parentId'], {
    is: (driverType: DriverViewDTO.DriverTypeEnum, parentId: string) =>
      isDriverFieldRequired({ driverType, parentId } as any).i9DocumentFile,
    then: yup.object().required()
  }),
  applicationDocumentFile: yup.object().when(['driverType', 'parentId'], {
    is: (driverType: DriverViewDTO.DriverTypeEnum, parentId: string) =>
      isDriverFieldRequired({ driverType, parentId } as any).applicationDocumentFile,
    then: yup.object().required()
  }),
  leaseDocumentFile: yup.object().when(['driverType', 'parentId'], {
    is: (driverType: DriverViewDTO.DriverTypeEnum, parentId: string) =>
      isDriverFieldRequired({ driverType, parentId } as any).leaseDocumentFile,
    then: yup.object().required()
  }),
  medicalExamCardDocumentFile: yup.object().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ driverType, status } as any).medicalExamCardDocumentFile,
    then: yup.object().required()
  }),
  twicDocumentFile: yup.object().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ driverType, status } as any).twicDocumentFile,
    then: yup.object().required()
  }),
  portCredentialsDocumentFile: yup.object().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ driverType, status } as any).portCredentialsDocumentFile,
    then: yup.object().required()
  }),
  licenseBackDocumentFile: yup.object().when(['status', 'driverType'], {
    is: (status: DriverViewDTO.StatusEnum, driverType: DriverViewDTO.DriverTypeEnum) =>
      isDriverFieldRequired({ driverType, status } as any).licenseBackDocumentFile,
    then: yup.object().required()
  })
})

export const schemaRegistration = yup.object().shape({
  document: yup.string().nullable(true),
  expirationDate: yup.string().nullable(true),
  registeredDate: yup.string().nullable(true),
  stateId: yup.string().nullable(true)
})

export const schemaEquipment = yup.object().shape({
  active: yup.boolean(),
  availability: yup.boolean(),
  chassisNumber: yup
    .string()
    .matches(equipmentNumberRegex, 'Invalid number. 4 letters and 6 or 7 numbers allowed')
    .required(),
  company: yup.string().nullable(true),
  days: yup.number().nullable(true),
  dotSafetyInspection: schemaDotSafetyInspection,
  equipmentType: yup
    .mixed()
    .oneOf([
      'FLATBED_TRAILER',
      'DRY_VANS',
      'REFRIGERATED_TRAILER',
      'LOWBOY_TRAILER',
      'STEP_DECK_TRAILER',
      'EXTENDABLE_FLATBED_TRAILER',
      'STRETCH_SINGLE_DROP_DECK_TRAILER',
      'REMOVABLE_GOOSENECK_TRAILER',
      'SPECIALTY_TRAILER',
      'SIDEKIT_TRAILER',
      'EXTENDABLE_DOUBLE_DROP_TRAILER',
      'STRETCH_RNG_TRAILER',
      'CONESTOGA_TRAILER',
      'POWER_ONLY_TRAILER',
      'MULTI_CAR_TRAILER',
      'SPECIALIZED_TRAILER',
      'OCEAN_CONTAINER_CHASSIS'
    ])
    .required(),
  inactiveReason: yup
    .mixed()
    .oneOf([...Object.keys(EquipmentDTO.InactiveReasonEnum), null])
    .nullable(true),
  insurance: schemaInsurance,
  licensePlate: yup
    .string()
    .min(6)
    .max(12)
    .nullable(true),
  maintenanceInspectionFrequency: yup
    .mixed()
    .oneOf(['MONTHLY', 'QUARTERLY', 'SEMI_ANNUALLY', 'ANNUALLY', 'OTHER', null]),
  maintenanceInspections: yup.array().of(schemaMaintenanceInspection),
  make: yup
    .string()
    .min(2)
    .max(64)
    .nullable(true),
  model: yup
    .string()
    .min(2)
    .max(64)
    .nullable(true),
  number: yup.string().nullable(true),
  ownership: yup
    .mixed()
    .oneOf(['OWNED', 'LEASED', 'RENTED', 'OWNER_OPERATED'])
    .required(),
  pickupDate: yup.string().nullable(true),
  registration: schemaRegistration,
  repairs: yup.array().of(schemaRepair),
  returnDate: yup.string().nullable(true),
  unavailabilityReason: yup.mixed().oneOf(['ASSIGNED', 'IN_USE', 'INACTIVE', null]),
  vin: yup
    .string()
    .min(11)
    .max(17)
    .nullable(true),
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS']),
  year: yup
    .number()
    .min(1900)
    .max(2100)
    .nullable(true)
})

export const schemaEquipmentLazy = yup.lazy((value: any) =>
  (value && value.fullObject === true ? schemaEquipment : yup.mixed())
    .nullable(true)
    .notRequired()
    .default(undefined)
)

export const schemaHazmat = yup.object().shape({
  code: yup
    .string()
    .min(0)
    .max(6)
    .nullable(true),
  description: yup
    .string()
    .min(0)
    .max(300)
    .nullable(true),
  hazardClass: yup
    .string()
    .min(0)
    .max(6)
    .nullable(true)
})

export const schemaOrderNote = yup.object().shape({
  dispatchDeliveryOrderId: yup.string().required(),
  note: yup
    .string()
    .min(0)
    .max(1000)
    .required()
})

export const schemaDateTimeRange = yup
  .object()
  .shape({ from: yup.string().nullable(true), to: yup.string().nullable(true) })

export const schemaPickupStage = yup.object().shape({
  actualAppointmentDate: yup.string().nullable(true),
  appointmentDate: yup.string().nullable(true),
  location: schemaLocation,
  locationId: yup
    .string()
    .nullable(true)
    .notRequired()
    .default(undefined),
  plannedAppointmentDateTimeRange: schemaDateTimeRange
})
export const schemaDeliveryStage = yup.object().shape({
  actualAppointmentDate: yup.string().nullable(true),
  actualPickDate: yup.string().nullable(true),
  appointmentDate: yup.string().nullable(true),
  location: schemaLocation,
  locationId: yup
    .string()
    .nullable(true)
    .notRequired()
    .default(undefined),
  pickDate: yup.string().nullable(true),
  plannedAppointmentDateTimeRange: schemaDateTimeRange,
  plannedPickDateTimeRange: schemaDateTimeRange,
  spentTimeSpan: yup.number().nullable(true)
})
export const schemaReturnStage = yup.object().shape({
  actualAppointmentDate: yup.string().nullable(true),
  appointmentDate: yup.string().nullable(true),
  location: schemaLocation,
  locationId: yup
    .string()
    .nullable(true)
    .notRequired()
    .default(undefined),
  plannedAppointmentDateTimeRange: schemaDateTimeRange
})

export const schemaDispatchDeliveryOrderGridItem = yup.object().shape({
  pickupStage: schemaPickupStage,
  deliveryStage: schemaDeliveryStage,
  returnStage: schemaReturnStage,
  activityIds: yup
    .array()
    .of(yup.string())
    .nullable(true),
  buySideQuoteId: yup.string().nullable(true),
  cargoCutoffDate: yup.string().nullable(true),
  container: yup
    .string()
    .notRequired()
    .default(undefined)
    .nullable(true),
  containerId: yup.string().nullable(true),
  containerNumberPlaceholder: yup.string().nullable(true),
  containerType: schemaContainerType.nullable(true),
  containerTypeId: yup.string().nullable(true),
  deliveryOrder: schemaDeliveryOrderGridItem,
  deliveryOrderId: yup.string().required(),
  description: yup.string().nullable(true),
  enclosedDocuments: yup
    .array()
    .of(yup.string())
    .nullable(true),
  enclosedPictures: yup
    .array()
    .of(yup.string())
    .nullable(true),
  equipment: schemaEquipmentLazy,
  equipmentId: yup.string().nullable(true),
  hazmat: schemaHazmat.nullable(true),
  hazmatId: yup.string().nullable(true),
  loadType: yup.mixed().oneOf(['LIVE_LOAD', 'DROP_AND_PICK']),
  number: yup
    .string()
    .min(0)
    .max(50)
    .nullable(true),
  orderNoteIds: yup
    .array()
    .of(yup.string())
    .nullable(true),
  orderNotes: yup
    .array()
    .of(schemaOrderNote)
    .nullable(true),
  overweightIndicator: yup.boolean().required(),
  sellSideQuoteId: yup.string().nullable(true),
  steamShipLineId: yup.string().nullable(true),
  subClient: schemaSubClient
    .nullable(true)
    .default(undefined)
    .notRequired(),
  subClientId: yup
    .string()
    .nullable(true)
    .notRequired(),
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS'])
})
