import * as React from 'react'
import styled from 'styled-components'
import { StyledAddNew, StyledFieldContainer } from '../../../Grid/styles'
import { Select } from '../../../../../../UI/DataFields/Select'
import { individualRateField } from '../../../functions'
import { SellSideQuoteRateDTO } from '../../../../../../../api/origin/qmp-service'
import { InputField } from '../../../../../../UI/DataFields/Input'
import { RateType, IRateFiltersState, ICustomerQuoterFilterState, RateState, NewRateDTO } from '../../../../interfaces'
import { quoteLists } from '../../../../../../../services/select/quoteLists'
import { makeRateTypesList, makeRuleList } from '../functions'
import { oc } from 'ts-optchain'
import { DateTimePicker, DateOnFocus } from '../../../../../../UI/DataFields/DateTimePicker/views'
import { defaultNewRate } from './settings'

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #445366;
  margin-right: 15px;
`

const ActionButton = styled.div`
  font-size: 20px;
  color: #bdbdbd;
  cursor: default;

  &.active {
    cursor: pointer;
    color: #4555d0;
  }
`

type Props = {
  newRates: NewRateDTO[]
  isCustomerQuoteParent: boolean
  rateType: RateType
  appliedFilters: IRateFiltersState | ICustomerQuoterFilterState
  validate: (newRate: NewRateDTO) => boolean
  onCreate?: (newRate: NewRateDTO) => void
  onCancel?: (newRate: NewRateDTO) => void
  onApply?: (newRate: NewRateDTO) => void
  extraData: any
}

export const CreateRate = React.memo((props: Props) => {
  const {
    rateType,
    appliedFilters,
    validate,
    onCreate,
    onCancel,
    onApply,
    isCustomerQuoteParent,
    extraData,
    newRates
  } = props
  const rateTypeField = individualRateField[rateType]
  const extraRateData = React.useMemo(
    () =>
      isCustomerQuoteParent
        ? { ...(extraData || {}), [rateTypeField]: oc(appliedFilters)[rateTypeField][0].value() }
        : extraData,
    [isCustomerQuoteParent, extraData, oc(appliedFilters)[rateTypeField][0].value()]
  )
  const [newRate, setNewRate] = React.useState(defaultNewRate(extraRateData))
  const isEditMode = oc(newRate).state() === RateState.edit

  React.useEffect(() => {
    let rate = null
    if (newRates) {
      rate = newRates.find(_ => _.state === RateState.edit)
    }
    if (rate) {
      setNewRate({ ...rate, [rateTypeField]: rate[rateTypeField] || undefined })
    }
  }, [newRates])

  React.useEffect(() => {
    const newRateTypeFieldValue = newRate[rateTypeField]
    const currentAppliedFilters = Array.isArray(appliedFilters[rateTypeField]) ? appliedFilters[rateTypeField] : null
    if (
      !currentAppliedFilters ||
      (newRateTypeFieldValue &&
        currentAppliedFilters &&
        !currentAppliedFilters.find(({ value }: any) => value === newRateTypeFieldValue))
    ) {
      setNewRate({ ...newRate, [rateTypeField]: undefined })
    }
  }, [appliedFilters.customerId, appliedFilters.vendorId])

  const updateRateField = React.useCallback(
    (field: string) => (value: any) => {
      switch (field) {
        case 'type':
          const list = quoteLists.calculationTypeOfRateType[rateType][value]
          return setNewRate(_newRate => ({
            ..._newRate,
            [field]: value,
            calculationType: oc(list)[0].value(),
            ruleIds: undefined
          }))
        default:
          return setNewRate(_newRate => ({ ..._newRate, [field]: value }))
      }
    },
    []
  )

  const newDefaultRate = React.useCallback(() => {
    return defaultNewRate(isCustomerQuoteParent ? extraRateData : undefined)
  }, [isCustomerQuoteParent, extraRateData])

  const cancelEditing = React.useCallback(() => {
    const rate = newDefaultRate()
    setNewRate(rate)

    if (onCancel) {
      onCancel(rate)
    }
  }, [rateType, isCustomerQuoteParent, onCancel, newDefaultRate])

  const applyNewRate = React.useCallback(() => {
    if (onApply) {
      onApply(newRate)
    }

    setNewRate(newDefaultRate())
  }, [newRate, onApply, newDefaultRate])

  const createNewRate = React.useCallback(() => {
    if (onCreate) {
      onCreate(newRate)
    }

    setNewRate(newDefaultRate())
  }, [newRate, onCreate, newDefaultRate])

  const currentCalcTypeList = newRate.type
    ? quoteLists.calculationTypeOfRateType[rateType][newRate.type]
    : quoteLists.calculationType
  const typeOfRateSelectList = React.useMemo(() => makeRateTypesList(appliedFilters, rateType, isCustomerQuoteParent), [
    appliedFilters,
    rateType,
    isCustomerQuoteParent
  ])
  const ruleList = React.useMemo(() => makeRuleList(oc(newRate).type()), [oc(newRate).type()])

  let typeValue = appliedFilters[rateTypeField]

  if (typeValue === '%%') {
    typeValue = null
  }

  const typeList = React.useMemo(() => [{ label: 'TFF', value: 'TFF' }, ...(typeValue || [])], [typeValue])

  React.useEffect(() => {
    // >>> validate new rate
    if (newRate.type && !typeOfRateSelectList.some(item => item.value === newRate.type)) {
      updateRateField('type')(undefined)
    }
    // <<<
  }, [oc(appliedFilters).deliveryOrderType(), oc(newRate).type()])

  const amountProps = React.useMemo(
    () => ({
      id: `${rateType}-amount`,
      onChange: updateRateField('amount'),
      value: newRate.amount
    }),
    [newRate.amount, rateType, updateRateField]
  )

  const valid = React.useMemo(() => validate(newRate), [validate, newRate])

  return (
    <StyledAddNew className={'special-fields-container'}>
      <Label>{isEditMode ? 'Edit' : 'Add New'}</Label>
      <StyledFieldContainer style={{ width: 125 }} id={`${rateType}-rate-type`}>
        <Select
          disabled={isEditMode}
          title={newRate.type ? 'Type of Rate' : null}
          placeholder={'Type of Rate'}
          selectedValue={newRate.type}
          list={typeOfRateSelectList}
          onSelect={updateRateField('type')}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 120 }} id={`${rateType}-type`}>
        <Select
          title={'Type'}
          placeholder={'Type'}
          selectedValue={newRate[rateTypeField]}
          label={newRate[rateTypeField] ? undefined : 'TFF'}
          disabled={!typeValue}
          list={typeList}
          onSelect={value => updateRateField(rateTypeField)(value === 'TFF' ? undefined : value)}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 140 }} id={`${rateType}-calc-type`}>
        <Select
          disabled={currentCalcTypeList.length === 1}
          title={newRate.calculationType ? 'Calculation Type' : null}
          placeholder={'Calculation Type'}
          selectedValue={newRate.calculationType}
          list={currentCalcTypeList}
          onSelect={updateRateField('calculationType')}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 85 }} id={`${rateType}-rule`}>
        <Select
          disabled={ruleList.length === 0}
          title={newRate.ruleIds ? 'Rule' : null}
          placeholder={'Rule'}
          mutuallyExclusive={[
            [
              'd141eaab-aed4-44bb-977d-fbd375e58a40',
              '9e8a631c-cc96-4cf2-af81-8444e2429bd7',
              'dd30744c-376d-4c84-9170-bb71f7bad771'
            ],
            [
              'dcdb3da2-149f-4042-85c3-9e57b8462642',
              '8f4cdca7-a67a-4254-842c-0cded2fd1b9d',
              'c0013165-81e4-4344-bb5b-36307fbe0802'
            ]
          ]} // rule ids: pickup, delivery, return
          multiselect={true}
          selectedValue={newRate.ruleIds}
          list={ruleList}
          onSelect={updateRateField('ruleIds')}
          dropdownStyle={{ minWidth: 270 }}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={`${rateType}-effective-date`} style={{ width: 105 }}>
        <DateTimePicker
          title={newRate.effectiveDate ? 'Effective Date' : undefined}
          date={newRate.effectiveDate}
          maxDate={newRate.expirationDate}
          dateOnFocus={DateOnFocus.startDay}
          placeholder={'Effective Date'}
          hideTimeInput={true}
          onChange={updateRateField('effectiveDate')}
        />
      </StyledFieldContainer>
      <StyledFieldContainer id={`${rateType}-expiration-date`} style={{ width: 105 }}>
        <DateTimePicker
          title={newRate.expirationDate ? 'Expiration Date' : undefined}
          date={newRate.expirationDate}
          minDate={newRate.effectiveDate}
          dateOnFocus={DateOnFocus.endDay}
          placeholder={'Expiration Date'}
          hideTimeInput={true}
          onChange={updateRateField('expirationDate')}
        />
      </StyledFieldContainer>
      <StyledFieldContainer style={{ width: 90 }}>
        {newRate.calculationType === SellSideQuoteRateDTO.CalculationTypeEnum.PERCENTAGE ? (
          <InputField.Percentage {...amountProps} title={newRate.amount ? 'Amount (%)' : null} placeholder={'%'} />
        ) : (
          <InputField.Money
            {...amountProps}
            title={newRate.amount ? 'Amount' : null}
            placeholder={'Amount'}
            isNegative={
              (newRate.type === SellSideQuoteRateDTO.TypeEnum.DEDUCTION ||
                newRate.type === SellSideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER) &&
              newRate.amount !== 0
            }
          />
        )}
      </StyledFieldContainer>

      {isEditMode ? (
        <>
          <ActionButton
            style={{ marginRight: 5, cursor: 'pointer' }}
            className={'mdi mdi-close-circle'}
            onClick={cancelEditing}
          />
          <ActionButton
            className={'mdi mdi-checkbox-marked-circle' + (valid ? ' active' : '')}
            onClick={valid ? applyNewRate : undefined}
          />
        </>
      ) : (
        <ActionButton
          id={`${rateType}-plus`}
          className={'mdi mdi-plus-circle-outline' + (valid ? ' active' : '')}
          onClick={valid ? createNewRate : undefined}
        />
      )}
    </StyledAddNew>
  )
})
