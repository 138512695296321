import { DriverDTO } from '../../api/api'
import { IDriver } from '../../components/common/drivers/interfaces'
import { createNewUnitId } from '../utils'

export const createDriver = (): IDriver => {
  return {
    id: createNewUnitId(),
    fullObject: true,
    status: DriverDTO.StatusEnum.AVAILABLE,
    birthday: null,
    driverType: DriverDTO.DriverTypeEnum.OWNEROPERATOR,
    eld: false,
    eldNumber: null,
    email: null,
    endorsement: null,
    endorsementExpDate: null,
    firstName: null,
    lastName: null,
    licenseDocument: null,
    licenseExpDate: null,
    licenseIssueStateId: null,
    licenseIssueDate: null,
    licenseNumber: null,
    medicalExamCardDocument: null,
    medicalExamCardExpDate: null,
    middleName: null,
    millageRestriction: null,
    notes: null,
    number: null,
    operationalPreference: DriverDTO.OperationalPreferenceEnum.SINGLE,
    phone: null,
    powerUnitId: null,
    portCredentialsDocument: null,
    portCredentialsExpDate: null,
    twicDocument: null,
    twicExpDate: null,
    w4Document: null,
    w9Document: null,
    mobileApp: false
  }
}
