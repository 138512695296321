import { IPowerUnit } from '../../../components/common/powerUnit/interfaces'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { makeObjectSavePromise, tryToSave, makeSavePromiseWithCatch } from '../saveDTO'
import { getPowerUnitStatus } from './status'
import { powerUnitAPI } from '../../../api/api'
import * as R from 'remeda'
import { isNewId } from '../index'
import { tryAddPowerUnitToStore } from '../../../components/common/powerUnit/epics'

export const getPowerUnitSavePromise = (
  powerUnit: IPowerUnit,
  actions?: IModifyGridItemActions
): Promise<IPowerUnit> => {
  const { needToSave } = getPowerUnitStatus(powerUnit)

  const transformedObjectForSaving = {
    ...powerUnit,
    maintenanceInspections: powerUnit.maintenanceInspections.map(_ => (isNewId(_.id) ? R.omit(_, ['id']) : _)),
    repairs: powerUnit.repairs.map(_ => (isNewId(_.id) ? R.omit(_, ['id']) : _))
  }

  return makeObjectSavePromise(
    needToSave,
    transformedObjectForSaving,
    actions,
    powerUnitAPI,
    tryAddPowerUnitToStore
  ) as Promise<IPowerUnit>
}

export const savePowerUnit = (powerUnit: IPowerUnit, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getPowerUnitSavePromise(powerUnit, actions))

  return tryToSave({
    condition: getPowerUnitStatus(powerUnit).noStoreDataForUpdate,
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
