import { OngoingActivityGroupDTO } from '../../../../api/api'
import { dateService } from '../../../../services/timeService'
import { IFilterItem } from '../../grid/views/header/column'
import { SchedulerFilter } from '../interfaces'

type Props = {
  date: string
  grouping: OngoingActivityGroupDTO.GroupingEnum
  filters: IFilterItem[]
}

export const updateSchedulerFilterDate = (props: Props): IFilterItem[] | undefined => {
  const { date, grouping } = props
  let filters = props.filters.filter(
    ({ column }) => column !== SchedulerFilter.specificDate && column !== SchedulerFilter.activityGroupDate
  )

  if (!grouping) {
    if (date) {
      filters = filters.concat(
        { column: SchedulerFilter.specificDate, value: date },
        {
          column: SchedulerFilter.activityGroupDate,
          value: { from: dateService.convertStringDate(date).startDay, to: dateService.convertStringDate(date).endDay }
        }
      )
    }
  } else if (grouping === OngoingActivityGroupDTO.GroupingEnum.DRIVER) {
    filters = filters.concat(
      date
        ? [
            { column: SchedulerFilter.specificDate, value: date },
            {
              column: SchedulerFilter.activityGroupDate,
              value: { from: date, to: dateService.createStringDate.endDay }
            }
          ]
        : {
            column: SchedulerFilter.activityGroupDate,
            value: { from: dateService.createStringDate.startDay }
          }
    )
  } else {
    if (!date) {
      return
    }

    filters = filters.concat(
      { column: SchedulerFilter.specificDate, value: date },
      {
        column: SchedulerFilter.activityGroupDate,
        value: { from: dateService.convertStringDate(date).startDay, to: dateService.convertStringDate(date).endDay }
      }
    )
  }

  return filters
}
