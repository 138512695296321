import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { GridColumn, Column, Container } from '../../../../../../UI/GridSystem'
import { InputField, Input } from '../../../../../../UI/DataFields/Input'
import { AddressDTO, LocationViewDTO } from '../../../../../../../api/origin/business-logic'
import { LocationSearch } from '../../../../../../UI/DataFields/SearchRequest/location/fromGoogle'
import { LocationDetailsDto } from '../../../../../../../api/origin/location'
import { FieldTemplate } from '../../../../../../UI/DataFields/Templates'
import { getStateInfo } from '../../../../../../../services/addressService'
import { FieldContainer } from '../../../../../../UI/DataFields/FieldContainer'
import { ActionButton } from '../../../../../../UI/Buttons/ActionButton'
import { validateLocationGEO } from '../../../../../../../services/functions/validateLocationGEO'

type Props = {
  location?: LocationViewDTO
  address: AddressDTO
  changeAddress: (name: keyof AddressDTO, value: any) => void
  selectLocationBySearch: (locationDetails: LocationDetailsDto) => void
  setFetching?: (state: boolean) => void
  isModified?: boolean
}

export const Address = (props: Props) => {
  const { isModified, location, address, changeAddress, selectLocationBySearch, setFetching } = props

  return (
    <Column columns={17} isGrid={true} padding={{ top: 25, right: 20, bottom: 35, left: 2 }}>
      <LocationSearchContainer>
        <LocationSearch title={'Address Search'} combineWithLocation={location} onChange={selectLocationBySearch} />
        <ActionButton
          disabled={isModified}
          round={true}
          filled={true}
          onClick={() => validateLocationGEO(location, { setFetching })}
          children={'Validate'}
        />
      </LocationSearchContainer>

      <Container rows={11} margin={{ bottom: 25 }}>
        <Input
          required={true}
          title={'Address'}
          value={oc(address).street('')}
          onChange={value => changeAddress('street', value)}
        />
      </Container>
      <Container>
        <GridColumn columns={8} isGrid={true} padding={{ right: 10 }}>
          <Container rows={11} margin={{ bottom: 25 }}>
            <Input
              title={'City'}
              required={true}
              value={oc(address).city('')}
              onChange={value => changeAddress('city', value)}
            />
          </Container>
          <Container>
            <FieldContainer title={'Country'} wrapperHeight={35}>
              {getStateInfo(oc(address).stateId(null)).countryCode}
            </FieldContainer>
          </Container>
        </GridColumn>

        <GridColumn columns={8} isGrid={true} padding={{ left: 10 }}>
          <Container rows={11} margin={{ bottom: 25 }}>
            <FieldTemplate.StateSearch
              required={true}
              title={'State'}
              stateId={oc(address).stateId(null)}
              onChange={(stateId: string) => changeAddress('stateId', stateId)}
            />
          </Container>
          <Container rows={11} margin={{ bottom: 25 }}>
            <InputField.Zip
              title={'ZIP'}
              required={true}
              value={oc(address).postalCode('')}
              onChange={value => changeAddress('postalCode', value)}
            />
          </Container>
        </GridColumn>
      </Container>
    </Column>
  )
}

const LocationSearchContainer = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 120px;
  grid-gap: 20px;
  margin-bottom: 25px;
`
