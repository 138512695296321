import * as React from 'react'
import { Column, DDOLink, Text, TextLine, TextLines } from '../PreviewPhase'
import { locationTypeIcons } from '../../../../../../../../services/DTO/location'
import { openDDOinNewTab } from '../../../../../../../../services/DTO/dispatchDeliveryOrder'
import { TCalcPhase } from '../../../../../functions'
import { LocationDTO, OngoingActivityDTO, TransportationActivityViewDTO } from '../../../../../../../../api/api'
import { DestinationTypeWithName } from './DestinationTypeWithName'
import { isLocationGEOValid } from '../../../../../../../../services/functions/test/isLocationGEOValid'
import theme from '../../../../../../../../styles/theme'

type Props = {
  phase: TCalcPhase
  isNowSection?: boolean
  isExtraActivity?: boolean
  isTwoActivitiesView?: boolean
}

export const PreviewPhaseDestinationColumn = React.memo((props: Props) => {
  const { phase, isNowSection, isTwoActivitiesView, isExtraActivity } = props
  const { stage, isActive } = phase
  const activity = isExtraActivity ? phase.extraActivity : phase.activity
  const isStreetTurn = (activity.comboType || activity.type) === OngoingActivityDTO.TypeEnum.STREETTURN
  const isDeliveryPhase = stage === TransportationActivityViewDTO.StageEnum.DELIVERY
  const destination = activity.destination || {}

  const getDestinationCityStatePostalCode = () => {
    return [destination.city, [destination.state, destination.postalCode].filter(Boolean).join(' ')]
      .filter(Boolean)
      .join(', ')
  }

  const locationPrefix = isTwoActivitiesView ? (isExtraActivity ? 'topL' : 'bottomL') : 'l'

  return (
    <Column
      style={isLocationGEOValid(destination) === false ? { color: theme.colors.defaultRed } : undefined}
      data-copy-value-on-mousedown={true}
      data-id={[
        `phase-location`,
        activity.destination && `${locationPrefix}ocationName='${destination.shortName || destination.name}'`,
        activity.destination && `${locationPrefix}ocationType='${destination.type}'`,
        `isStreetTurn=${isStreetTurn}`
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {isStreetTurn ? (
        activity.matchedDDOId && activity.matchedDDONumber ? (
          <DDOLink
            onClick={event =>
              openDDOinNewTab({
                // @ts-ignore
                event,
                ddoNumber: activity.matchedDDONumber,
                ddoId: activity.matchedDDOId
              })
            }
          >
            DDO# {activity.matchedDDONumber}
          </DDOLink>
        ) : null
      ) : isTwoActivitiesView ? (
        <TextLine style={{ paddingBottom: 1 }}>
          <DestinationTypeWithName
            destinationType={destination.type}
            destinationName={destination ? destination.shortName || destination.name : ''}
          />
          {(destination.city || destination.state || destination.postalCode) && (
            <span style={{ marginLeft: 8, fontWeight: isExtraActivity ? 500 : 300 }}>
              {getDestinationCityStatePostalCode()}
            </span>
          )}
        </TextLine>
      ) : (
        <TextLines className={isActive ? 'space-between' : undefined}>
          <TextLine
            style={
              isActive
                ? { fontSize: 12, fontWeight: 400 }
                : {
                    fontSize: isDeliveryPhase ? 13 : 14,
                    fontWeight: isDeliveryPhase ? 300 : 400
                  }
            }
          >
            <DestinationTypeWithName
              destinationType={destination.type}
              destinationName={destination ? destination.shortName || destination.name : ''}
            />
          </TextLine>
          <TextLine style={{ fontSize: 12, fontWeight: 500 }}>
            {destination.city || destination.state || destination.postalCode ? (
              getDestinationCityStatePostalCode()
            ) : (
              <Text>Location</Text>
            )}
          </TextLine>
        </TextLines>
      )}
    </Column>
  )
})
