import {
  ActivitiesViewDTO,
  DocumentationActivityDTO,
  TransportationActivityViewDTO
} from '../../../api/origin/business-logic'
import { promiseFetchObjectsByIdAndPushToStore } from '../../../components/common/QMP/views/functions'
import { driverAPI } from '../../../api/api'
import { getStore } from '../../../store/configureStore'
import { tryAddActivitiesToStore } from '../../../components/common/activity/epics'
import { tryAddDriverToStore } from '../../../components/common/drivers/epics'

export const parseActivities = (activities: ActivitiesViewDTO[]) => {
  const transportationActivities: TransportationActivityViewDTO[] = []
  const documentationActivities: DocumentationActivityDTO[] = []

  activities.forEach(_activities => {
    if (_activities && _activities.transportationActivities) {
      transportationActivities.push(..._activities.transportationActivities)
    }
    if (_activities && _activities.documentationActivities) {
      documentationActivities.push(..._activities.documentationActivities)
    }
  })

  if (!transportationActivities.length && !documentationActivities.length) {
    return
  }

  // if (transportationActivities.length) {
  //   promiseFetchObjectsByIdAndPushToStore(
  //     transportationActivities.map(activity => (activity as TransportationActivityViewDTO).vendorId).filter(Boolean),
  //     driverAPI.findById,
  //     tryAddDriverToStore,
  //     true,
  //     getStore().getState().driver
  //   )
  // }

  tryAddActivitiesToStore({
    transportationActivities: transportationActivities,
    documentationActivities: documentationActivities
  })
}
