import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { DeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { Window } from './preview'
import { Popover } from './popover'
import { DDOContext } from '../../../../../services/DTO/dispatchDeliveryOrder/context'

type Props = {
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
}

const Document: React.SFC<Props> = ({ dispatchDeliveryOrder, updateDeliveryOrder, disabled }) => {
  const {
    state: { isImport, isExport, isRepo }
  } = React.useContext(DDOContext)

  let isHighlighted = false
  let boldText = ''
  let text = ''

  if (isImport) {
    isHighlighted = !dispatchDeliveryOrder.deliveryOrder.billOfLadingNumber
    boldText = dispatchDeliveryOrder.deliveryOrder.billOfLadingNumber
    text = dispatchDeliveryOrder.deliveryOrder.bookingNumber
  }

  if (isExport || isRepo) {
    isHighlighted = !dispatchDeliveryOrder.deliveryOrder.bookingNumber
    boldText = dispatchDeliveryOrder.deliveryOrder.bookingNumber
    text = dispatchDeliveryOrder.deliveryOrder.billOfLadingNumber
  }

  return (
    <Widget
      title={'Documents'}
      highlighted={isHighlighted}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        bold: boldText,
        text
      }}
      detailsProps={{
        disabled,
        dispatchDeliveryOrder,
        updateDeliveryOrder
      }}
    />
  )
}

export default Document
