import * as React from 'react'
import { connect } from 'react-redux'
import { ICustomerView } from '../../../interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import ContactsPreviewTab from '../../../../contact/views'
import { oc } from 'ts-optchain'

type OwnProps = { customer: ICustomerView; actions: IGridItemActions; isModified: boolean }

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Contacts = ({
  customer: { businessPartner },
  isModified,
  actions: { modify, modifyParentObjectField, setFetching }
}: Props) => {
  if (!oc(businessPartner).contacts()) {
    return null
  }

  return (
    <ContactsPreviewTab
      isModified={isModified}
      primaryContactId={businessPartner.primaryContactId}
      contactIds={businessPartner.contactIds}
      contacts={businessPartner.contacts}
      modifyContacts={({ contacts, contactIds }) =>
        modifyParentObjectField('businessPartner')({ ...businessPartner, contacts, contactIds })
      }
      togglePreviewSpinner={setFetching}
    />
  )
}

export default connect(
  null,
  null
)(Contacts)
