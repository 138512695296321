import * as React from 'react'
import styled from 'styled-components'
import * as R from 'remeda'
import { Column, GridColumn, ColumnRow, Container } from '../../../UI/GridSystem'
import { LocationDTO } from '../../../../api/api'
import DateField from '../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../UI/DatePicker/Interfaces'
import { Select } from '../../../UI/DataFields/Select'
import { LocationWidget } from '../../../UI/Widget/widgets/location/simple'
import { FieldContainer } from '../../../UI/DataFields/FieldContainer'
import { dispatchDeliveryOrderLists } from '../../../../services/select/dispatchDeliveryOrderLists'
import { ILocation } from '../../location/interfaces'
import { correctDDOLocations, correctLocation } from '../../../../services/DTO/deliveryOrder/functions'
import { oc } from 'ts-optchain'
import { DeliveryOrderContext } from './context'
import { DateOnFocus, DateTimePicker } from '../../../UI/DataFields/DateTimePicker/views'

type OwnProps = {}

const DORightSide = styled(GridColumn)`
  .search-location {
    input,
    .mdi > div,
    .mdi > span {
      font-size: 18px;
    }
  }
`

type Props = OwnProps

export const RightSide: React.FC<Props> = () => {
  const {
    state: { deliveryOrder, isImport, isExport, isRepo, isModifiedMode },
    functions: { modifyDeliveryOrder, modifyDeliveryOrderField }
  } = React.useContext(DeliveryOrderContext)

  const updateLocation = (fieldName: 'pickupLocation' | 'deliveryLocation' | 'returnLocation') => (
    location: LocationDTO
  ) => {
    return modifyDeliveryOrder({
      ...deliveryOrder,
      pickupLocation: correctLocation(deliveryOrder.pickupLocation as ILocation, location),
      deliveryLocation: correctLocation(deliveryOrder.deliveryLocation as ILocation, location),
      returnLocation: correctLocation(deliveryOrder.returnLocation as ILocation, location),
      [fieldName]: location,
      [fieldName + 'Id']: oc(location).id(),
      dispatchDeliveryOrders: location
        ? deliveryOrder.dispatchDeliveryOrders.map(correctDDOLocations(location))
        : deliveryOrder.dispatchDeliveryOrders
    })
  }

  return (
    <DORightSide style={{ width: '100%' }} isGrid={true} flexClear={true} margin={{ left: 70 }}>
      <ColumnRow margin={{ bottom: 15 }}>
        <Column id={'do-pickupLocation'}>
          <FieldContainer title={'Pickup'}>
            <LocationWidget
              isExternal={true}
              isShortName={false}
              location={deliveryOrder.pickupLocation as ILocation}
              changeLocation={updateLocation('pickupLocation')}
              updateLocation={updateLocation('pickupLocation')}
              isModifiedMode={isModifiedMode}
            />
          </FieldContainer>
        </Column>
      </ColumnRow>
      <ColumnRow margin={{ bottom: 35 }}>
        <Column margin={{ right: 15 }} id={'do-equipmentFirstPickupDate'}>
          <DateTimePicker
            title={'Availability'}
            date={deliveryOrder.equipmentFirstPickupDate}
            onChange={modifyDeliveryOrderField('equipmentFirstPickupDate')}
          />
        </Column>

        <Column margin={{ right: 15 }} id={'do-vesselArrivalDate'}>
          {isImport && (
            <DateTimePicker
              title={'Vessel Arrival'}
              date={deliveryOrder.vesselArrivalDate}
              onChange={modifyDeliveryOrderField('vesselArrivalDate')}
            />
          )}
        </Column>

        <Column id={'do-lastFreeDateDemurrage'}>
          {isImport && (
            <DateTimePicker
              title={'Last Free'}
              date={deliveryOrder.lastFreeDateDemurrage}
              onChange={modifyDeliveryOrderField('lastFreeDateDemurrage')}
            />
          )}
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 25 }}>
        <Column id={'do-deliveryLocation'}>
          <FieldContainer title={'Delivery'} disabled={isRepo}>
            <LocationWidget
              disableWidget={isRepo}
              isExternal={true}
              isShortName={false}
              location={deliveryOrder.deliveryLocation as ILocation}
              changeLocation={updateLocation('deliveryLocation')}
              updateLocation={updateLocation('deliveryLocation')}
              isModifiedMode={isModifiedMode}
            />
          </FieldContainer>
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 45 }}>
        <Column margin={{ right: 15 }} id={'do-appointmentDateTimeRange'}>
          <DateTimePicker
            disabled={isRepo}
            title={'Appointment Date'}
            dateOnFocus={DateOnFocus.startWorkDay}
            date={deliveryOrder.appointmentDateTimeRange}
            isRange={true}
            onChange={value => {
              const isHaveDate = (date: any) => Boolean(date) || !R.equals(date, { to: null, from: null })

              return modifyDeliveryOrder({
                ...deliveryOrder,
                appointmentDateTimeRange: value,
                dispatchDeliveryOrders: deliveryOrder.dispatchDeliveryOrders.map(ddo => {
                  if (!ddo.deliveryStage || isHaveDate(oc(ddo).deliveryStage.plannedAppointmentDateTimeRange())) {
                    return ddo
                  }

                  return {
                    ...ddo,
                    deliveryStage: {
                      ...oc(ddo).deliveryStage({}),
                      plannedAppointmentDateTimeRange: value
                    }
                  }
                })
              })
            }}
          />
        </Column>
        <Column margin={{ right: 15 }} id={'do-loadType'}>
          <Container columns={4} isFixed={true}>
            <Select
              disabled={isRepo}
              title={'Load Type'}
              required={!isRepo}
              placeholder={''}
              selectedValue={deliveryOrder.loadType}
              list={dispatchDeliveryOrderLists.loadType}
              onSelect={loadType =>
                modifyDeliveryOrder({
                  ...deliveryOrder,
                  loadType
                })
              }
            />
          </Container>
        </Column>
        <Column />
      </ColumnRow>

      <ColumnRow margin={{ bottom: 15 }} id={'do-returnLocation'}>
        <FieldContainer title={'Return'}>
          <LocationWidget
            isExternal={true}
            isShortName={false}
            location={deliveryOrder.returnLocation as ILocation}
            changeLocation={updateLocation('returnLocation')}
            updateLocation={updateLocation('returnLocation')}
            isModifiedMode={isModifiedMode}
          />
        </FieldContainer>
      </ColumnRow>

      <ColumnRow>
        {!isRepo && (
          <Column margin={{ right: 15 }} id={'do-lastFreeDatePerDiem'}>
            <DateTimePicker
              title={'Per Diem Free by'}
              date={deliveryOrder.lastFreeDatePerDiem}
              onChange={modifyDeliveryOrderField('lastFreeDatePerDiem')}
            />
          </Column>
        )}
        <Column margin={{ right: 15 }} id={'do-firstReceivingDate'}>
          {(isExport || isRepo) && (
            <DateTimePicker
              title={'First Receiving'}
              date={deliveryOrder.firstReceivingDate}
              onChange={modifyDeliveryOrderField('firstReceivingDate')}
            />
          )}
        </Column>
        <Column margin={isRepo ? { right: 15 } : undefined} id={'do-generalCutoffDate'}>
          {isExport && (
            <DateTimePicker
              title={'Cutoff'}
              date={deliveryOrder.generalCutoffDate}
              onChange={modifyDeliveryOrderField('generalCutoffDate')}
            />
          )}
        </Column>
        {isRepo && <Column />}
      </ColumnRow>
    </DORightSide>
  )
}
