export const regExpOnlyNumbers: RegExp = /[^0-9]+/g
export const regExpOnlyLetters: RegExp = /[^a-zA-Z]+/g
export const regExpOnlyNumbersAndLetters: RegExp = /[^a-zA-Z0-9]+/g

export const useRegExp = (regExp: RegExp, str: string, replaceTo?: any): string => {
  if (!regExp) {
    return str
  }

  try {
    return (str || '').replace(regExp, replaceTo || '')
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(`useRegExp function error (regExp: ${regExp}, str: ${str})`, e)
    return ''
  }
}

export const leaveOnlyNumbersAndLetters = (str: string): string => {
  return useRegExp(regExpOnlyNumbersAndLetters, str)
}
