import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import { connect } from 'react-redux'
import { ChannelType, ChatTab, ICHubChannel } from '../../../../store/reducers/communicationHub/interfaces'
import { openDDOinNewTab } from '../../../../services/DTO/dispatchDeliveryOrder'
import { UserAvatar, UserAvatarSize } from '../UserAvatar'
import { DetailsViewHeader } from '../DetailsView/DetailsViewHeader'
import { leaveChatChannelIfNoEditing } from '../../functions/leaveChatChannelIfNoEditing'
import { DispatchDeliveryOrderColorStatusIcon, IconName } from '../Icon'
import { IDispatchDeliveryOrder } from '../../../common/dispatchDeliveryOrder/interfaces'
import { IStore } from '../../../../store/store.interface'
import { generateDispatchDeliveryOrderChatChannel } from '../../functions'
import { UserDTO } from '../../../../api/origin/user-service'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectCommunictaionHubChannelMeta } from '../../../../store/select/communicationHubSelect'
import { muteChannel, unmuteChannel } from '../../epics'

type TCalculatedChannelNameData = {
  name: string
  icon: JSX.Element
  extraProps: object
  clickable?: boolean
  onClick?: () => void
}

type OwnProps = {
  channel: ICHubChannel | UserDTO
  chatTab?: ChatTab
  dispatchDeliveryOrder?: IDispatchDeliveryOrder
  extraRightBlock?: JSX.Element
  hideCloseButton?: boolean
  onTitleClick?: () => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { chatTab, dispatchDeliveryOrder, extraRightBlock, hideCloseButton, onTitleClick } = props
  const dispatch = useAppDispatch()
  const filterList = React.useMemo(() => [ChatTab.Messages, ChatTab.Documents, ChatTab.Notes, ChatTab.Alerts], [])
  const channel = React.useMemo(
    () => (dispatchDeliveryOrder ? generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder) : props.channel),
    [props.channel, dispatchDeliveryOrder]
  )
  const channelMeta = useAppSelector(selectCommunictaionHubChannelMeta(props.channel.id))

  const channelNameData: TCalculatedChannelNameData = React.useMemo(() => {
    const result: TCalculatedChannelNameData = {
      name: channel.name,
      icon: undefined,
      extraProps: {}
    }

    if (channel.type) {
      const { id, metadata, channelViewName, name } = channel as ICHubChannel
      if (channel.type === ChannelType.dispatchDeliveryOrder) {
        result.icon = <DispatchDeliveryOrderColorStatusIcon status={metadata.status} />
        result.onClick = () => openDDOinNewTab({ ddoId: id, tabName: channelViewName || name })
        result.name = channelViewName || name
      }
    } else {
      result.icon = <UserAvatar user={channel as UserDTO} size={UserAvatarSize.medium} />
      result.clickable = true
      result.extraProps = {
        'data-for': 'user-details-hint',
        'data-place': 'bottom',
        'data-tip': channel.id
      }
    }

    return result
  }, [channel])

  const ChannelNameComponent = React.useMemo(() => {
    return (
      <Name
        {...channelNameData.extraProps}
        className={cn({ clickable: Boolean(onTitleClick || channelNameData.onClick || channelNameData.clickable) })}
        onClick={onTitleClick || channelNameData.onClick}
      >
        {channelNameData.icon}
        {channelNameData.name}
      </Name>
    )
  }, [channelNameData])

  const ChannelFilterComponent = React.useMemo(() => {
    return (
      <Filter>
        {filterList.map(filter => {
          const active = filter === chatTab

          return (
            <FilterItem
              key={filter}
              className={cn({ active })}
              onClick={
                active
                  ? undefined
                  : () =>
                      leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.setActiveChannelTab(filter)))
              }
            >
              {filter}
            </FilterItem>
          )
        })}
      </Filter>
    )
  }, [chatTab])

  return (
    <DetailsViewHeader
      title={ChannelNameComponent}
      onCloseButtonClick={() => leaveChatChannelIfNoEditing(() => dispatch(communicationHubActions.closeDetailsView()))}
      actions={
        channelMeta
          ? [
              channelMeta.mute
                ? {
                    label: 'Unmute',
                    iconClassName: 'mdi mdi-bell-off-outline',
                    onClick: () => unmuteChannel(channel.id)
                  }
                : { label: 'Mute', iconClassName: 'mdi mdi-bell-outline', onClick: () => muteChannel(channel.id) }
            ]
          : undefined
      }
      extraRightBlock={extraRightBlock || ChannelFilterComponent}
      closeButtonId={channel.id}
      closeButtonIcon={IconName.CLOSE_DETAILS}
      hideCloseButton={hideCloseButton}
    />
  )
}

export const ChannelViewHeader = connect(({ dispatchDeliveryOrder }: IStore, { channel }: OwnProps) => ({
  dispatchDeliveryOrder: channel && dispatchDeliveryOrder[channel.id]
}))(React.memo(Component))

const Name = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-right: 10px;

  * {
    margin-right: 7px;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      color: ${theme.colors.basicBlueColor};
      text-decoration: underline;
    }
  }
`
const Filter = styled.div`
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  align-items: stretch;
  font-size: 14px;
`
const FilterItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #333333;
  margin-right: 16px;
  user-select: none;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }

  &.active {
    color: ${theme.colors.basicBlueColor};
    cursor: default;

    &:after {
      background-color: ${theme.colors.basicBlueColor};
    }
  }
`
const Actions = styled.div`
  flex-shrink: 0;
`
