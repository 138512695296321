import * as React from 'react'
import { TablePreview } from '../../../common/grid/views/styles'
import { PreviewContent, PreviewHeader } from '../../../common/grid/views/styledComponents'
import { UnitStatus } from './UnitStatus'
import { CustomUnitHeader } from './CustomUnitHeader'
import { GridItemSpinner } from '../../../UI/Spinner/Spinner'
import { TabType } from '../../../common/tabs/interfaces'
import { Navigation } from './Navigation'
import { PreviewTab } from './PreviewTab'
import { useApplicationSize } from '../../../../hooks/useApplicationSize'
import { useGridItem } from '../../../../hooks/useGridItem'
import { commonDDOFunctions, commonDDOState, DDOContext } from '../../../../services/DTO/dispatchDeliveryOrder/context'
import ActionsSaveCancel from '../../../UI/Buttons/ActionsSaveCancel'
import { gridPreviews } from '../data'

export type TUnitPreview = {
  headings: string[] | ((unit: any) => string[])
  tabs: Record<string, any>
  isValid: (unit: any) => boolean
}

type OwnProps = {
  gridItemActiveTab: string
  fetching: boolean | string
  unit: any
  unitType: TabType
  isModified: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = DispatchProps & OwnProps & StateProps

export const Preview = React.memo((props: Props) => {
  const { gridItemActiveTab, fetching, unit, unitType, isModified } = props
  const { communicationHubWidth } = useApplicationSize()
  const { actions } = useGridItem()
  const unitPreview = gridPreviews[unitType]
  const isUnitValid = React.useMemo(() => (isModified ? unitPreview.isValid(unit) : false), [
    isModified,
    unit,
    unitPreview.isValid
  ])

  const RenderContent = (
    <TablePreview className={'table__row-details'}>
      <PreviewContent className={'grid-preview'} style={{ width: `calc(100vw - ${communicationHubWidth}px)` }}>
        {Boolean(fetching) && <GridItemSpinner text={typeof fetching === 'string' ? fetching : undefined} />}
        <PreviewHeader>
          <Navigation
            unit={unit}
            headings={unitPreview.headings}
            activeTab={gridItemActiveTab}
            handleClick={actions.togglePreviewTab}
          />
          <CustomUnitHeader
            unitType={unitType}
            unit={unit}
            isModified={isModified}
            isValid={isUnitValid}
            actions={actions}
          />
          <UnitStatus unitType={unitType} unit={unit} isModified={isModified} isValid={isUnitValid} actions={actions} />
        </PreviewHeader>
        <PreviewTab
          isModified={isModified}
          tabs={unitPreview.tabs}
          activeTab={gridItemActiveTab}
          unit={unit}
          isUnitValid={isUnitValid}
          unitType={unitType}
          actions={actions}
        />
      </PreviewContent>
    </TablePreview>
  )

  switch (unitType) {
    case TabType.dispatchDeliveryOrder:
      return (
        <DDOContext.Provider
          value={{
            state: commonDDOState({ dispatchDeliveryOrder: unit, isModifiedMode: isModified, isUnitValid }),
            functions: commonDDOFunctions(unit, actions),
            saveButton: (action: () => void = () => {}): JSX.Element =>
              isModified ? (
                <ActionsSaveCancel
                  disableSave={!isUnitValid}
                  showCancel={true}
                  onSave={() => {
                    action()
                    actions.save()
                  }}
                  onCancel={actions.cancel}
                />
              ) : null
          }}
          children={RenderContent}
        />
      )
    default:
      return RenderContent
  }
})
