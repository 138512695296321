import { callAPI, vendorAccountingAPI } from '../../../api/api'
import { VendorDTO } from '../../../api/origin/vendor-accounting-service'

export const requestVendorAccounting = (vendorId: string): Promise<VendorDTO> => {
  return callAPI(vendorAccountingAPI.vendorControllerGet, vendorId).toPromise()
}

export const requestVendorAccountingUpdate = (vendor: VendorDTO): Promise<VendorDTO> => {
  // @ts-ignore
  return callAPI(vendorAccountingAPI.vendorControllerUpdate, vendor.id, vendor).toPromise()
}
