import * as React from 'react'
import { TableCell } from '../../../grid/views/styles'
import styled from 'styled-components'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { IDispatchDeliveryOrder } from '../../interfaces'
import { oc } from 'ts-optchain'
import { getStreetTurnStatus } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'

type Props = {
  className: string
  style: any
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

export const ContainerColumn = (props: Props) => {
  const { className, dispatchDeliveryOrder, style } = props
  const containerNumber = oc(dispatchDeliveryOrder).container.number('')
  const streetTurn = dispatchDeliveryOrder.streetTurn || oc(dispatchDeliveryOrder).temporaryData.streetTurn()
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn,
    streetTurnCount: dispatchDeliveryOrder.streetTurnCount
  })
  const isExportDDO = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isStreetTurn =
    streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED ||
    streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED

  return (
    <TableCell
      className={className}
      data-copy-value-on-mousedown={true}
      data-copy-value={containerNumber}
      style={style}
    >
      <div>
        <ValueContainer>
          {isStreetTurn ? (
            <>
              {isExportDDO ? (
                // Export Street Turn
                <div>
                  <StreetTurnDDOLink
                    onClick={event =>
                      openDDOinNewTab({
                        // @ts-ignore
                        event,
                        ddoId: isExportDDO ? streetTurn.ddoImportId : streetTurn.ddoExportId,
                        ddoNumber: isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber
                      })
                    }
                  >
                    DDO# {isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
                  </StreetTurnDDOLink>
                  <div style={{ marginTop: 3 }}>{containerNumber}</div>
                </div>
              ) : (
                // Import Street Turn
                <div>
                  <div style={{ marginBottom: 2 }}>{containerNumber}</div>
                  <StreetTurnDDOLink
                    onClick={event =>
                      openDDOinNewTab({
                        // @ts-ignore
                        event,
                        ddoId: isExportDDO ? streetTurn.ddoImportId : streetTurn.ddoExportId,
                        ddoNumber: isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber
                      })
                    }
                  >
                    DDO# {isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
                  </StreetTurnDDOLink>
                </div>
              )}
              <STLabel style={{ backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus] }}>ST</STLabel>
            </>
          ) : (
            // Default
            <>
              {containerNumber}
              {streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE && (
                <STLabel style={{ backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus] }}>ST</STLabel>
              )}
            </>
          )}
        </ValueContainer>
      </div>
    </TableCell>
  )
}

const STLabel = styled.div`
  display: inline-flex;
  font-size: 12px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 auto;
`
const StreetTurnDDOLink = styled.div`
  color: #4555d0;
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    color: #5599ff;
  }
`
const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`
