import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { Row } from './styledComponents'
import { Delete } from '../../../../../UI/styledComponents'
import { InputField, Input } from '../../../../../UI/DataFields/Input'
import { AddressDTO } from '../../../../../../api/origin/business-logic/api'
import { oc } from 'ts-optchain'
import { createNewContact } from '../../../../contact/reducers'
import { isNewObject } from '../../../../../../services/DTO'
import AddressField from '../../../../../UI/AddressField'
import { ISubClient } from '../../../../subClient/interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { ICustomerView } from '../../../interfaces'

type OwnProps = {
  customer: ICustomerView
  subClient: ISubClient
  actions: IGridItemActions
  deleteSubClient: (id: string) => void
}

const SubClient: React.FC<OwnProps> = ({
  customer,
  deleteSubClient,
  subClient,
  actions: { modifyParentObjectField }
}) => {
  const primaryContact = subClient.businessPartner.contacts
    ? subClient.businessPartner.contacts.find(item => item.primary)
    : null

  const modifyPrimaryContact = (field: string) => (value: any) =>
    modifyParentObjectField('subClients')(
      customer.subClients.map(item =>
        item.id === subClient.id
          ? {
              ...subClient,
              businessPartner: {
                ...subClient.businessPartner,
                contacts: primaryContact
                  ? subClient.businessPartner.contacts.map(_ =>
                      _.id === primaryContact.id ? { ..._, [field]: value } : _
                    )
                  : [...subClient.businessPartner.contacts, { ...createNewContact(true), [field]: value }]
              }
            }
          : item
      )
    )

  return (
    <Row rows={9} margin={{ bottom: 10 }}>
      <Container columns={8} margin={{ left: 20 }}>
        <Input
          required={true}
          placeholder={'Name'}
          value={oc(subClient).businessPartner.legalName('')}
          onChange={(legalName: string) =>
            modifyParentObjectField('subClients')(
              customer.subClients.map(item =>
                item.id === subClient.id
                  ? {
                      ...subClient,
                      name: legalName,
                      businessPartner: {
                        ...subClient.businessPartner,
                        legalName
                      }
                    }
                  : item
              )
            )
          }
        />
      </Container>
      <Container columns={22}>
        <AddressField
          required={true}
          address={oc(subClient).businessPartner.legalAddress(null)}
          onChange={(legalAddress: AddressDTO) =>
            modifyParentObjectField('subClients')(
              customer.subClients.map(item =>
                item.id === subClient.id
                  ? {
                      ...subClient,
                      businessPartner: {
                        ...subClient.businessPartner,
                        legalAddress
                      }
                    }
                  : item
              )
            )
          }
        />
      </Container>
      <Container columns={9}>
        <Input
          placeholder={'First Name'}
          value={oc(primaryContact).firstName('')}
          onChange={modifyPrimaryContact('firstName')}
        />
      </Container>
      <Container columns={9}>
        <Input
          placeholder={'Last Name'}
          value={oc(primaryContact).lastName('')}
          onChange={modifyPrimaryContact('lastName')}
        />
      </Container>
      <Container columns={9}>
        <InputField.PhoneNumber
          placeholder={'Phone'}
          value={oc(primaryContact).phone('')}
          onChange={modifyPrimaryContact('phone')}
        />
      </Container>
      <Container columns={9}>
        <InputField.Email
          placeholder={'Email'}
          value={oc(primaryContact).email('')}
          onChange={modifyPrimaryContact('email')}
        />
      </Container>
      <Delete
        className={`mdi mdi-delete ${isNewObject(subClient) ? '' : 'disabled'} `}
        columns={2}
        isFixed={true}
        onClick={() => (isNewObject(subClient) ? deleteSubClient(subClient.id) : null)}
      />
    </Row>
  )
}

export default SubClient
