import * as React from 'react'
import { ColumnRow } from '../../../../../UI/GridSystem'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { oc } from 'ts-optchain'
import { EquipmentDTO } from '../../../../../../api/api'
import { ContainerSearch, EquipmentSearch } from '../../../../../UI/DataFields/SearchRequest'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { compareDates, convertISODateToDateWithHoursMins } from '../../../../../../services/timeService/dateUtils'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { isNewObject } from '../../../../../../services/DTO'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { ActivityContext } from '../../../../../../services/DTO/activity/context'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { StyledTopBlock } from './styledComponents'
import { QuotesWidget } from '../../../../../UI/Widget/widgets/quote'
import styled from 'styled-components'
import { isContainerRequiredForDDO } from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'

type OwnProps = {
  actions: IGridItemActions
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  isDDOValid: boolean
  allowModifying: boolean
  enableEditing: boolean
  showDates?: boolean
  quoteAmounts: {
    profit: number
    sellSideQuoteTotalAmount: number
    buySideQuoteTotalAmount: number
    buySideQuotesAmount: Record<string, number>
  }
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const TopBlock = ({
  isDDOValid,
  quoteAmounts,
  actions,
  dispatchDeliveryOrder,
  allowModifying,
  enableEditing,
  showDates
}: Props) => {
  const {
    saveButton,
    functions: { updateContainerType, updateContainer },
    required
  } = React.useContext(ActivityContext)
  const { modify } = actions
  const equipmentProps = {
    size: dispatchDeliveryOrder.containerType,
    containerNumber: oc(dispatchDeliveryOrder).container.number(''),
    container: oc(dispatchDeliveryOrder).container(null),
    equipment: oc(dispatchDeliveryOrder).equipment(null),
    chassis: oc(dispatchDeliveryOrder).equipment.chassisNumber(''),
    days: oc(dispatchDeliveryOrder).equipment.days(null),
    pickupDate: oc(dispatchDeliveryOrder).equipment.pickupDate(null),
    returnDate: oc(dispatchDeliveryOrder).equipment.returnDate(null)
  }

  const updateEquipment = (equipment: EquipmentDTO) =>
    modify({
      ...dispatchDeliveryOrder,
      equipment,
      equipmentId: equipment && !isNewObject(equipment) ? equipment.id : null
    })

  const updateEquipmentDate = (field: string) => (value: any) =>
    updateEquipment({
      ...dispatchDeliveryOrder.equipment,
      [field]: value
    } as EquipmentDTO)

  const isNewChassis = oc(equipmentProps)
    .equipment.id('')
    .includes('newEquipment')

  const estimate = {
    checkDates: (returnDate: any) => compareDates(equipmentProps.pickupDate, returnDate),
    calcDates:
      equipmentProps.pickupDate && equipmentProps.returnDate
        ? Math.ceil(Number(compareDates(equipmentProps.pickupDate, equipmentProps.returnDate, 'days')))
        : '',
    placeholder: isNewChassis ? 'MM/DD/YY' : ' '
  }

  return (
    <StyledTopBlock className={'activities__top_block'}>
      <ColumnRow data-id={'size'} columns={15} isFixed={true}>
        <FieldTemplate.ContainerTypeSearch
          disabled={enableEditing === false}
          title={'Size'}
          required={true}
          containerType={equipmentProps.size}
          onChange={updateContainerType}
        />
      </ColumnRow>
      <ColumnRow data-id={'container'} columns={9} isFixed={true} margin={{ left: 10 }}>
        <ContainerSearch
          disabled={enableEditing === false}
          required={isContainerRequiredForDDO(dispatchDeliveryOrder.deliveryOrder.type, dispatchDeliveryOrder.status)}
          title={'Container #'}
          value={equipmentProps.container}
          steamShipLineId={oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId(null)}
          containerTypeId={dispatchDeliveryOrder.containerTypeId}
          onChange={updateContainer}
        />
      </ColumnRow>
      <ColumnRow data-id={'chassisNumber'} columns={9} isFixed={true} margin={{ left: 10 }}>
        <EquipmentSearch
          disabled={enableEditing === false}
          allowCreate={true}
          required={required.chassisNumber}
          title={'Chassis #'}
          value={oc(equipmentProps).equipment(null)}
          onChange={updateEquipment}
        />
      </ColumnRow>
      <ColumnRow data-id={'pickupDate'} columns={7} isFixed={true} margin={{ left: 10 }}>
        <DateField
          showTime={true}
          disabled={enableEditing === false || !isNewChassis}
          title={'Chassis Pickup'}
          date={equipmentProps.pickupDate}
          dateType={DateTypes.DateISOString}
          placeholder={estimate.placeholder}
          onUpdate={updateEquipmentDate('pickupDate')}
        />
      </ColumnRow>
      <ColumnRow data-id={'returnDate'} columns={7} isFixed={true} margin={{ left: 10 }}>
        <DateField
          showTime={true}
          disabled={enableEditing === false || !isNewChassis}
          title={'Chassis Return'}
          date={equipmentProps.returnDate}
          dateType={DateTypes.DateISOString}
          placeholder={estimate.placeholder}
          onUpdate={value => {
            const date = estimate.checkDates(value) ? value : null
            return updateEquipmentDate('returnDate')(date)
          }}
        />
      </ColumnRow>
      <ColumnRow data-id={'days'} columns={4} isFixed={true} margin={{ left: 10 }}>
        <FieldContainer title={'Chassis Days'} wrapChild={true} childrenStyle={{ height: 35, fontSize: 13 }}>
          {equipmentProps.days || estimate.calcDates}
        </FieldContainer>
      </ColumnRow>

      <div style={extraWidgetContainerStyles}>
        <QuotesWidget
          isDDOValid={isDDOValid}
          actions={actions}
          quoteAmounts={quoteAmounts}
          dispatchDeliveryOrder={dispatchDeliveryOrder}
        />
      </div>

      {showDates && (
        <SUpdateInfo>
          <FieldContainer title={'Created'}>
            <Info style={{ marginBottom: 10 }}>
              {convertISODateToDateWithHoursMins(dispatchDeliveryOrder.createdAt)}
              <br />
              {dispatchDeliveryOrder.createdBy}
            </Info>
          </FieldContainer>

          <FieldContainer title={'Updated'}>
            <Info>
              {convertISODateToDateWithHoursMins(dispatchDeliveryOrder.updatedAt)}
              <br />
              {dispatchDeliveryOrder.updatedBy}
            </Info>
          </FieldContainer>
        </SUpdateInfo>
      )}
    </StyledTopBlock>
  )
}

const Info = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgb(108, 112, 125);
  margin-left: 10px;
`

const SUpdateInfo = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;

    label {
      font-size: 10px;
      color: rgb(69, 85, 208);
      margin: 0;
    }
  }
`

const extraWidgetContainerStyles = {
  minWidth: 280,
  marginLeft: 'auto',
  marginRight: 15
}
