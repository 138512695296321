import * as React from 'react'
import { getStore } from '../../../../../store/configureStore'
import { updateTabData } from '../../../../common/tabs/actions'
import { Radio } from '../../../../UI/Radio'
import { useGrid } from '../../../../../hooks/useGrid'

type OwnProps = {
  unitId: string
  isSelected?: boolean
  isDisabledSelect?: boolean
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const StreetTurnSelect = (props: Props) => {
  const { unitId, isSelected, isDisabledSelect } = props
  const { tabId, tabData, listRequestProps } = useGrid()

  const streetTurnRequested = tabData.streetTurnRequested
  const disabled = isDisabledSelect || streetTurnRequested

  return (
    <Radio
      active={isSelected}
      onClick={(event: any) => {
        event.stopPropagation()

        if (disabled) {
          return
        }

        const { dispatch } = getStore()
        const requesterId = listRequestProps.dispatchDeliveryOrderId

        dispatch(
          updateTabData({
            tabId: tabId,
            options: {
              selectedIds: isSelected ? [requesterId] : [requesterId, unitId],
              pinnedIds: isSelected ? [requesterId] : [requesterId, unitId]
            }
          })
        )
      }}
      disabled={disabled}
    />
  )
}
