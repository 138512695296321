import * as React from 'react'
import { Preview } from './Preview'
import { Details } from './Details'
import { Window } from '../../../../../../UI/Window'
import styled from 'styled-components'
import { OngoingActivityGroupDTO } from '../../../../../../../api/origin/business-logic'
import { IStore } from '../../../../../../../store/store.interface'
import { connect } from 'react-redux'
import { IViewingObject } from '../../../../../../../services/viewingObjects/interfaces'
import { deleteTabViewingObject } from '../../../../../../../services/viewingObjects/actions'

type OwnProps = {
  isFavorite: boolean
  isMissed: boolean
  isNowSection: boolean
  activityGroup: OngoingActivityGroupDTO
  tabId: string
}

type StateProps = {
  viewingObject: IViewingObject
}

type DispatchProps = {
  deleteTabViewingObject: typeof deleteTabViewingObject
}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { activityGroup, tabId, isFavorite, isMissed, isNowSection, viewingObject } = props
  const isModifiedMode = Boolean(viewingObject.modifiedObject)

  return (
    <Window
      // startBelowTabs={true}
      isCommunicationHubVisible={true}
      onButtonCloseClick={isModifiedMode ? undefined : () => props.deleteTabViewingObject({ tabId })}
      windowStyles={{ width: '100%', height: '100%', paddingBottom: 65, paddingTop: 52 }}
    >
      <>
        <WindowPreviewContainer className={'details'}>
          <Preview
            isFavorite={isFavorite}
            isMissed={isMissed}
            isNowSection={isNowSection}
            activityGroup={activityGroup}
          />
        </WindowPreviewContainer>
        <Details
          tabId={tabId}
          viewingObject={viewingObject}
          dispatchDeliveryOrderId={activityGroup.dispatchDeliveryOrderId}
        />
      </>
    </Window>
  )
}

export const ViewingRowMode = connect(
  (store: IStore, { tabId }: OwnProps) => {
    return {
      viewingObject: store.viewingObjects[tabId]
    }
  },
  { deleteTabViewingObject }
)(React.memo(Component))

const WindowPreviewContainer = styled.div`
  z-index: 1111;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 8px 1px 1px;
`
