import { OngoingActivityGroupDTO } from '../../../../api/api'
import { dateService } from '../../../../services/timeService'
import { IFilterItem, IFilterItemDate } from '../../grid/views/header/column'
import { SchedulerFilter, SchedulerSortBy } from '../interfaces'

type Props = {
  prevSortBy: SchedulerSortBy
  nextSortBy: SchedulerSortBy
  filters: IFilterItem[]
}
export const updateSchedulerFilterGroupBy = (props: Props): IFilterItem[] => {
  const { prevSortBy, nextSortBy } = props
  let filters = props.filters.filter(_ => _.column !== SchedulerFilter.grouping)
  let grouping: string

  if (nextSortBy === SchedulerSortBy.driverActivities) {
    grouping = undefined
  } else if (nextSortBy === SchedulerSortBy.driverAllStages) {
    grouping = OngoingActivityGroupDTO.GroupingEnum.DRIVER.toString()

    if (filters.some(({ column }) => column === SchedulerFilter.dispatchDeliveryOrderNumber)) {
      filters = filters.filter(({ column }) => column !== SchedulerFilter.activityGroupDate)
    } else {
      const hasActivityGroupDateFilter = filters.some(({ column }) => column === SchedulerFilter.activityGroupDate)
      filters = hasActivityGroupDateFilter
        ? filters.map(filter =>
            filter.column === SchedulerFilter.activityGroupDate
              ? {
                  ...filter,
                  value:
                    filter.value && (filter.value as IFilterItemDate).from
                      ? filter.value
                      : { from: dateService.createStringDate.startDay }
                }
              : filter
          )
        : filters.concat({
            column: SchedulerFilter.activityGroupDate,
            value: { from: dateService.createStringDate.startDay }
          })
    }
  } else {
    grouping = OngoingActivityGroupDTO.GroupingEnum.STAGE.toString()

    if (prevSortBy === SchedulerSortBy.driverAllStages || prevSortBy === SchedulerSortBy.driverActivities) {
      filters = filters
        .filter(({ column }) => column !== SchedulerFilter.activityGroupDate && column !== SchedulerFilter.specificDate)
        .concat(
          {
            column: SchedulerFilter.specificDate,
            value: dateService.createStringDate.startDay
          },
          {
            column: SchedulerFilter.activityGroupDate,
            value: {
              from: dateService.createStringDate.startDay,
              to: dateService.createStringDate.endDay
            }
          }
        )
    }
  }

  return filters.concat({
    column: SchedulerFilter.grouping,
    value: grouping
  })
}
