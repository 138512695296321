import { combineReducers } from 'redux'
import { IStore } from '../store.interface'
import tabs from '../../components/common/tabs/reducers'
import modal from '../../components/UI/Modal/reducers'
import { applicationContextReducer } from '../../services/applicationContextService/reducers'
import {
  authorizationReducer,
  actionsToDispatchAfterSuccessfulAuthorizationReducer
} from '../../services/authService/reducers/authServiceReducers'
import users from '../../components/common/administration/users/reducers'
import powerUnit from '../../components/common/powerUnit/reducers'
import equipment from '../../components/common/equipment/reducers'
import location from '../../components/common/location/reducers'
import container from '../../components/common/containers/reducers'
import { contactReducer } from '../../components/common/contact/reducers'
import customer from '../../components/common/customer/reducers'
import steamShipLine from '../../components/common/steamShipLine/reducers'
import subClient from '../../components/common/subClient/reducers'
import dispatchDeliveryOrder from '../../components/common/dispatchDeliveryOrder/reducers'
import deliveryOrder from '../../components/common/deliveryOrder/reducers'
import { containerTypeReducer } from '../../services/DTO/containerType/reducer'
import { stateReducer } from '../../services/stateService/reducer'
import { customerQuotesReducer } from '../../services/DTO/customerQuote/reducer'
import { businessPartnerStateReducer } from '../../components/common/businessPartner/reducers'
import activity from '../../components/common/activity/reducers'
import { SSQRatesReducer, BSQRatesReducer } from '../../services/DTO/rate/rudecers'
import { buySideQuoteReducer } from '../../services/DTO/buySideQuote/reducer'
import { sellSideQuoteReducer } from '../../services/DTO/sellSideQuote/reducer'
import { streetTurnReducer } from '../../services/DTO/streetTurn/reducers'
import { viewingObjectsReducer } from '../../services/viewingObjects/reducers'
import { notificationsReducer } from '../../components/UI/Notification/reducer'

export const rootReducer = () =>
  combineReducers<IStore>({
    notifications: notificationsReducer,
    applicationContext: applicationContextReducer,
    authorization: authorizationReducer,
    actionsToDispatchAfterSuccessfulAuthorization: actionsToDispatchAfterSuccessfulAuthorizationReducer,
    viewingObjects: viewingObjectsReducer,
    users,
    state: stateReducer,
    powerUnit,
    equipment,
    location,
    container,
    ssqRates: SSQRatesReducer,
    bsqRates: BSQRatesReducer,
    sellSideQuotes: sellSideQuoteReducer,
    buySideQuotes: buySideQuoteReducer,
    customer,
    steamShipLine,
    subClient,
    dispatchDeliveryOrder,
    deliveryOrder,
    activity,
    streetTurn: streetTurnReducer,
    containerType: containerTypeReducer,
    customerQuotes: customerQuotesReducer,
    businessPartner: businessPartnerStateReducer,
    contact: contactReducer,
    tabs,
    modal
  })
