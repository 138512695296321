import * as React from 'react'
import { OngoingActivityDestinationDTO } from '../../../../../../../../api/api'
import { locationTypeIcons } from '../../../../../../../../services/DTO/location'

type Props = {
  destinationType: OngoingActivityDestinationDTO.TypeEnum
  destinationName: string
}

export const DestinationTypeWithName = React.memo((props: Props) => {
  const { destinationType, destinationName } = props

  return (
    <>
      {destinationType ? (
        <span className={locationTypeIcons[destinationType]} style={{ fontSize: 16, marginRight: 3 }} />
      ) : (
        <span style={{ fontSize: 20 }}>&mdash;</span>
      )}
      {destinationName}
    </>
  )
})
