import * as React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../../../../store/store.interface'
import { NotificationsState, NotificationType } from '../interfaces'
import { NotificationsContainer, NotificationList, NotificationsButton } from './styles'
import { Notification } from './Notification'
import { setNotificationsState, removeNotification } from '../actions'
import cn from 'classnames'
import { useApplicationSize } from '../../../../hooks/useApplicationSize'
import { getAlerts } from '../../../CommunicationHub/epics'
import { TroubleTicketDTO } from '../../../../api/api'

type StateProps = {
  notifications: NotificationsState
}

type DispatchProps = {
  setNotificationsState: typeof setNotificationsState
  removeNotification: typeof removeNotification
}

type Props = StateProps & DispatchProps

const Component = React.memo((props: Props) => {
  const { notifications } = props
  const { communicationHubWidth } = useApplicationSize()
  const [opened, setOpened] = React.useState(true)

  React.useEffect(() => {
    setOpened(true)
  }, [notifications])

  React.useEffect(() => {
    getAlerts({ status: TroubleTicketDTO.StatusEnum.NEW })
      .then(alertMessages => {
        if (alertMessages && alertMessages.length) {
          props.setNotificationsState(
            alertMessages.map(alertMessage => ({
              id: alertMessage.id,
              type: NotificationType.alert,
              data: alertMessage
            }))
          )
        }
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.error(error)
      })
  }, [])

  const toggle = React.useCallback(() => setOpened(_ => !_), [])

  const RenderContent = React.useMemo(
    () => (
      <>
        <NotificationsButton
          data-id={'alert-notifications-open-button'}
          className={cn('mdi', 'mdi-alert-decagram', { opened })}
          onClick={toggle}
        />
        {opened && (
          <NotificationList data-id={'alert-notifications-list'}>
            {notifications.map(notification => (
              <Notification
                key={notification.id}
                notification={notification}
                removeNotification={() => props.removeNotification({ id: notification.id })}
              />
            ))}
          </NotificationList>
        )}
      </>
    ),
    [opened, notifications]
  )

  if (!notifications || notifications.length === 0) {
    return null
  }

  return (
    <NotificationsContainer data-id={'alert-notifications'} style={{ right: communicationHubWidth }}>
      {RenderContent}
    </NotificationsContainer>
  )
})

export const Notifications = connect(
  ({ notifications }: IStore) => ({
    notifications
  }),
  { setNotificationsState, removeNotification }
)(React.memo(Component))
