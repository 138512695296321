import * as React from 'react'
import { IGridItemActions, GridItemContext } from '../contexts/GridItemContext'

type Props = {
  actions: IGridItemActions
  children?: any
}

export const GridItemProvider = React.memo((props: Props) => {
  const { actions, children } = props

  return <GridItemContext.Provider value={{ actions }} children={children} />
})
