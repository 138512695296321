import { DocumentationActivityDTO, TransportationActivityViewDTO } from '../../api/api'
import * as icons from '../../components/UI/icons/DDOStatusIcon'

export const activityIcons = {
  DDO: {
    title: 'DDO',
    icon: (): any => {
      return null
    }
  },
  [TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY]: {
    title: 'Pick-up Empty',
    icon: icons.IconDDOPickupEmpty
  },
  [TransportationActivityViewDTO.TypeEnum.PICKUPEMPTY + '_EN_ROUTE']: {
    title: 'Pick-up Empty',
    icon: icons.IconDDOPickupEmptyEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.PICKUPFULL]: {
    title: 'Pick-up Full',
    icon: icons.IconDDOPickupFull
  },
  [TransportationActivityViewDTO.TypeEnum.PICKUPFULL + '_EN_ROUTE']: {
    title: 'Pick-up Full',
    icon: icons.IconDDOPickupFullEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.GETUNLOADED]: {
    title: 'Get Unloaded',
    icon: icons.IconDDOGetUnloaded
  },
  [TransportationActivityViewDTO.TypeEnum.GETUNLOADED + '_EN_ROUTE']: {
    title: 'Get Unloaded',
    icon: icons.IconDDOGetUnloadedEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.GETLOADED]: {
    title: 'Get Loaded',
    icon: icons.IconDDOGetLoaded
  },
  [TransportationActivityViewDTO.TypeEnum.GETLOADED + '_EN_ROUTE']: {
    title: 'Get Loaded',
    icon: icons.IconDDOGetLoadedEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS]: {
    title: 'Drop Empty w/ Chassis',
    icon: icons.IconDDODropEmptyWithChassis
  },
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHCHASSIS + '_EN_ROUTE']: {
    title: 'Drop Empty w/ Chassis',
    icon: icons.IconDDODropEmptyWithChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS]: {
    title: 'Drop Full w/ Chassis',
    icon: icons.IconDDODropFullWithChassis
  },
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHCHASSIS + '_EN_ROUTE']: {
    title: 'Drop Full w/ Chassis',
    icon: icons.IconDDODropFullWithChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.PICKCHASSIS]: {
    title: 'Pick Chassis',
    icon: icons.IconDDOPickChassis
  },
  [TransportationActivityViewDTO.TypeEnum.PICKCHASSIS + '_EN_ROUTE']: {
    title: 'Pick Chassis',
    icon: icons.IconDDOPickChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.DROPCHASSIS]: {
    title: 'Drop Chassis',
    icon: icons.IconDDODropChassis
  },
  [TransportationActivityViewDTO.TypeEnum.DROPCHASSIS + '_EN_ROUTE']: {
    title: 'Drop Chassis',
    icon: icons.IconDDODropChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS]: {
    title: 'Drop Empty w/o Chassis',
    icon: icons.IconDDODropEmptyWithoutChassis
  },
  [TransportationActivityViewDTO.TypeEnum.DROPEMPTYWITHOUTCHASSIS + '_EN_ROUTE']: {
    title: 'Drop Empty w/o Chassis',
    icon: icons.IconDDODropEmptyWithoutChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS]: {
    title: 'Drop Full w/o Chassis',
    icon: icons.IconDDODropFullWithoutChassis
  },
  [TransportationActivityViewDTO.TypeEnum.DROPFULLWITHOUTCHASSIS + '_EN_ROUTE']: {
    title: 'Drop Full w/o Chassis',
    icon: icons.IconDDODropFullWithoutChassisEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.SCALETARE]: {
    title: 'Scale - Tare',
    icon: icons.IconDDOScaleTare
  },
  [TransportationActivityViewDTO.TypeEnum.SCALETARE + '_EN_ROUTE']: {
    title: 'Scale - Tare',
    icon: icons.IconDDOScaleTareEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.SCALEFULL]: {
    title: 'Scale - Full',
    icon: icons.IconDDOScaleFull
  },
  [TransportationActivityViewDTO.TypeEnum.SCALEFULL + '_EN_ROUTE']: {
    title: 'Scale - Full',
    icon: icons.IconDDOScaleFullEnRoute
  },
  [TransportationActivityViewDTO.TypeEnum.EMPTY]: {
    title: 'Bobtail En-Route To',
    icon: icons.IconEmptyBobtail
  },
  [TransportationActivityViewDTO.TypeEnum.EMPTY + '_EN_ROUTE']: {
    title: 'Bobtail En-Route To',
    icon: icons.IconEmptyBobtail
  },
  [TransportationActivityViewDTO.TypeEnum.OFFDUTY]: {
    title: 'Off Duty',
    icon: icons.IconOffDutyBobtail
  },
  [TransportationActivityViewDTO.TypeEnum.OFFDUTY + '_EN_ROUTE']: {
    title: 'Off Duty',
    icon: icons.IconOffDutyBobtail
  },
  [DocumentationActivityDTO.TypeEnum.STREETTURN]: {
    title: 'Street Turn',
    icon: icons.IconStreetTurn
  }
}

export default activityIcons
