import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { IStore } from '../../../store/store.interface'
import ofActions from '../../rxjsService/operators/ofActions'
import {
  setTabViewingObject,
  deleteTabViewingObject,
  deleteTabsViewingObject,
  modifyTabViewingObject,
  setTabViewingObjectData
} from '../actions'
import { mergeMap } from 'rxjs/operators'
import { NEVER } from 'rxjs'
import * as R from 'remeda'
import { tabSessionStorage } from '../../tabs/functions'
import { ViewingObjectState } from '../interfaces'
import { isNewId } from '../../DTO'
import { getUserState } from '../../../store'

const correctViewingObjects = (viewingObjects: ViewingObjectState): ViewingObjectState => {
  const value = viewingObjects || {}
  return Object.keys(value).reduce((acc, key) => {
    const viewingObject = value[key]

    if (viewingObject && !isNewId(viewingObject.id)) {
      acc[key] = R.omit(viewingObject, ['modifiedObject', 'temporaryData'])
    }

    return acc
  }, {})
}

const updateViewingObjectsEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<IStore>) =>
  action$.pipe(
    ofActions([
      setTabViewingObject,
      modifyTabViewingObject,
      setTabViewingObjectData,
      deleteTabViewingObject,
      deleteTabsViewingObject
    ]),
    mergeMap(_ => {
      const state = store$.value

      if (!getUserState()) {
        return NEVER
      }

      tabSessionStorage.viewingObjects.set(correctViewingObjects(state.viewingObjects))

      return NEVER
    })
  )

export default combineEpics(updateViewingObjectsEpic)
