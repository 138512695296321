import * as React from 'react'
import { GridColumn } from '../../../../../UI/GridSystem'
import { ISteamShipLine } from '../../../interfaces'
import { StyledGeneral } from './styledComponents'
import SteamshipLineInfo from './SteamshipLineInfo'
import PrimaryContact from '../../../../contact/views/PrimaryContact'
import ContainerDetentionPerDiemFeeSchedule from './ContainerDetentionPerDiemFeeSchedule'
import { v4 } from 'uuid'
import { newContainerDetentionPerDiemFeeSchedule } from '../../../reducers'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'
import { ContactDTO } from '../../../../../../api/api'

type OwnProps = {
  actions: IGridItemActions
  ssl: ISteamShipLine
}

const General: React.SFC<OwnProps> = ({ ssl, actions }) => {
  if (!oc(ssl).businessPartner.contacts()) {
    return null
  }

  const contacts = ssl.businessPartner.contacts
  const primaryContact = contacts ? contacts.find(item => item.primary) : null

  return (
    <StyledGeneral minRows={70}>
      <GridColumn flexBasis={'auto'} padding={{ top: 25, left: 50, right: 50 }}>
        <SteamshipLineInfo ssl={ssl} actions={actions} />
        <PrimaryContact
          contacts={contacts}
          primaryContact={primaryContact}
          modifyContacts={(updatedContacts: ContactDTO[]) =>
            actions.modifyParentObjectField('businessPartner')({
              ...ssl.businessPartner,
              contacts: updatedContacts
            })
          }
        />
      </GridColumn>
      <ContainerDetentionPerDiemFeeSchedule
        ssl={ssl}
        createContainerDetentionPerDiemFeeSchedule={() =>
          actions.modifyParentObjectField('containerDetentionPerDiemFeeSchedule')([
            ...ssl.containerDetentionPerDiemFeeSchedule,
            { ...newContainerDetentionPerDiemFeeSchedule, id: 'fake_' + v4() }
          ])
        }
        changeContainerDetentionPerDiemFeeSchedule={(id, containerDetentionPerDiemFeeSchedule) =>
          actions.modifyParentObjectField('containerDetentionPerDiemFeeSchedule')(
            ssl.containerDetentionPerDiemFeeSchedule.map(item =>
              item.id === id ? containerDetentionPerDiemFeeSchedule : item
            )
          )
        }
        deleteContainerDetentionPerDiemFeeSchedule={id =>
          actions.modifyParentObjectField('containerDetentionPerDiemFeeSchedule')(
            ssl.containerDetentionPerDiemFeeSchedule.filter(item => item.id !== id)
          )
        }
      />
    </StyledGeneral>
  )
}

export default General
