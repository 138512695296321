import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ContainerTypeState } from './type'
import { businessLogicApiActions } from '../../../api/businessLogicApi'
import { containerLists } from '../../select/containerLists'

export const containerTypeReducer = reducerWithInitialState<ContainerTypeState>({})
  .case(businessLogicApiActions.containerTypeFindAllUsingGetAction.done, (state, { result }) => {
    const containerTypeCodesList: any[] = []
    const containerTypeNamesList: any[] = []
    const containerTypeListWithDescription: any[] = []
    result.forEach(item => {
      containerTypeCodesList.push({ label: item.code, value: item.id })
      containerTypeNamesList.push({ label: item.name, value: item.id })
      containerTypeListWithDescription.push({
        label: item.code,
        htmlLabel: item.code + ' – ' + item.name,
        value: item.id
      })
    })

    containerLists.containerTypes = result
    containerLists.containerTypeCodesList = containerTypeCodesList
    containerLists.containerTypeNamesList = containerTypeNamesList
    containerLists.containerTypeWithDescription = containerTypeListWithDescription

    return result.reduce(
      (acc, curr) => {
        acc[curr.id] = curr
        return acc
      },
      { ...state }
    )
  })
  .build()
