import * as React from 'react'
import { showModal, TMsgType } from '../../../UI/Modal/actions'
import Close from '../../../UI/Buttons/Close'
import { connect } from 'react-redux'
import { ApplicationContext, IStore } from '../../../../store/store.interface'
import { createNewTab, removeAllTabsExcept, setActiveTabId, removeTab } from '../../tabs/actions'
import { ITabState, ListRequestType, TabType } from '../../tabs/interfaces'
import {
  getSchedulerTabs,
  getContainerTabs,
  getCustomerTabs,
  getDispatchDeliveryOrderTabs,
  getDriverTabs,
  getEquipmentTabs,
  getLocationTabs,
  getPowerUnitTabs,
  getQMPTabs,
  getSteamshipLineTabs,
  getSubClientTabs,
  getUserTabs
} from '../../tabs/selectors'
import { Container } from '../../../UI/GridSystem'
import { defaultTabData } from '../../../../services/DTO/defaultTabData'
import cn from 'classnames'
import { localStorageService } from '../../../../services/storageService/localStorage/LocalStorage'
import { oc } from 'ts-optchain'
import { settingsPerTabType } from '../../../../services/uiSettingsService/tabs'
import { StyledDropdownContainer } from '../../../UI/Dropdowns/Popover'

type OwnProps = {
  tabType: TabType
  tabsLength: number
  applicationContext: ApplicationContext
  hideDropdownAction: () => void
}

type StateProps = {
  activeMainTabId: string
  tabs: ITabState[]
}

type DispatchProps = {
  createNewTab?: typeof createNewTab
  setActiveTabId?: typeof setActiveTabId
  removeAllTabsExcept?: typeof removeAllTabsExcept
  removeTab?: typeof removeTab
  showModal?: typeof showModal
}

type Props = OwnProps & StateProps & DispatchProps

export const CollectionDropdown = connect(
  (store: IStore, props: OwnProps) => ({
    activeMainTabId: store.tabs.activeMainTabId,
    tabs: getTabsByType(props.tabType, store)
  }),
  {
    removeAllTabsExcept,
    removeTab,
    createNewTab,
    setActiveTabId,
    showModal
  }
)((props: Props) => {
  const { tabs, applicationContext, tabType, activeMainTabId, hideDropdownAction, tabsLength } = props

  const recentItemsAction = () => {
    hideDropdownAction()

    const uiSettings = { ...settingsPerTabType[tabType] }
    uiSettings.filter = null
    const recentTabs = tabs.filter(_tab => _tab.listRequestType === ListRequestType.recentDispatchDeliverOrders)

    props.createNewTab({
      tabType: tabType,
      uiSettings: uiSettings,
      options: {
        title: 'Recent DDOs ' + (recentTabs.length + 1),
        alwaysDoListRequestOnTabEnter: true,
        listRequestType: ListRequestType.recentDispatchDeliverOrders,
        enableSorting: false
      }
    })
  }

  const addNewTabAction = () => {
    hideDropdownAction()
    props.createNewTab({ tabType: tabType, data: defaultTabData()[tabType] })
  }

  const selectTabAction = (tab: ITabState) => () => {
    hideDropdownAction()
    props.setActiveTabId({ tabId: tab.id })
  }

  const closeAllTabsBesidesTheFirstTabAction = () => {
    hideDropdownAction()

    props.showModal({
      msgType: TMsgType.delete,
      message: 'Do you really want to remove Tab?',
      onConfirm: () => {
        props.removeAllTabsExcept({
          tab: tabs[0],
          context: applicationContext
        })
      },
      onCancel: () => {}
    })
  }

  const removeTabAction = (tab: ITabState) => () => {
    hideDropdownAction()

    if (tabsLength === 1) {
      props.showModal({
        msgType: TMsgType.info,
        message: "Let's not remove this one for now",
        onConfirm: () => {},
        onCancel: () => {}
      })
      return
    }

    props.showModal({
      msgType: TMsgType.delete,
      message:
        'Do you really want to remove tab "' + tab.title.substr(0, 25) + (tab.title.length > 25 ? '...' : '') + '"?',
      onConfirm: () => {
        props.removeTab({ id: tab.id, context: applicationContext })
      },
      onCancel: () => {}
    })
  }
  let isCreateAvailable = true
  let hasRecentItems = false

  switch (tabType) {
    case TabType.dispatchDeliveryOrder:
      hasRecentItems = Boolean(oc(localStorageService.getRecentForTabType(tabType))([]).length)
      break
    case TabType.scheduler:
      isCreateAvailable = !tabs.length
      break
    default:
  }

  return (
    <StyledDropdownContainer>
      {hasRecentItems && <div onClick={recentItemsAction}>Recent</div>}
      {isCreateAvailable && <div onClick={addNewTabAction}>New Tab</div>}
      <div className="title">Displayed Tabs</div>
      <ul>
        {tabs.map(tab => {
          return (
            <li key={tab.id}>
              <span
                className={cn('check', { 'mdi mdi-check': tab.id === activeMainTabId })}
                onClick={selectTabAction(tab)}
              />
              <Container
                className={'textLine'}
                rows={7}
                alignCenter={true}
                flexBasis={'auto'}
                padding={{ right: 15 }}
                onClick={selectTabAction(tab)}
              >
                <span>{tab.title}</span>
              </Container>
              <Close key={tab.id} onClick={removeTabAction(tab)} />
            </li>
          )
        })}
      </ul>
      <div onClick={closeAllTabsBesidesTheFirstTabAction}>Close All</div>
    </StyledDropdownContainer>
  )
})

const getTabsByType = (type: TabType, store: IStore) => {
  switch (type) {
    case TabType.powerUnit:
      return getPowerUnitTabs(store)
    case TabType.equipment:
      return getEquipmentTabs(store)
    case TabType.container:
      return getContainerTabs(store)
    case TabType.location:
      return getLocationTabs(store)
    case TabType.driver:
      return getDriverTabs(store)
    case TabType.customer:
      return getCustomerTabs(store)
    case TabType.steamShipLine:
      return getSteamshipLineTabs(store)
    case TabType.subClient:
      return getSubClientTabs(store)
    case TabType.users:
      return getUserTabs(store)
    case TabType.dispatchDeliveryOrder:
      return getDispatchDeliveryOrderTabs(store)
    case TabType.QMP:
      return getQMPTabs(store)
    case TabType.scheduler:
      return getSchedulerTabs(store)
    default:
      return []
  }
}
