import * as React from 'react'
import { Scheduler } from '../common/scheduler/views'
import { ISchedulerTab } from '../common/scheduler/interfaces'
import { DateTimeRangeDTO } from '../../api/origin/business-logic'

type StateProps = {
  currentTab: ISchedulerTab
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export type GetSchedulerTabData = {
  noSpinner?: boolean
  loadMoreDates?: {
    requestDates: DateTimeRangeDTO
    resultFilterDates: DateTimeRangeDTO
  }
}

export const SchedulerPage = React.memo((props: Props) => {
  const { currentTab } = props

  return <Scheduler currentTab={currentTab} />
})
