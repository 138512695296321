import * as React from 'react'
import { Container, GridColumn, Column, ColumnRow } from '../../../UI/GridSystem'
import { InputField, Input } from '../../../UI/DataFields/Input'
import { TContact } from '../interfaces'
import { connect } from 'react-redux'
import styled from '../../../../styles/styledComponents'
import { oc } from 'ts-optchain'
import { createNewContact } from '../reducers'

const Title = styled(Container)``

type OwnProps = {
  columns?: 2 | 3
  padding?: {
    top?: number
    left?: number
    right?: number
    bottom?: number
  }
  titleStyles?: any | null
  primaryContact: TContact
  contacts: TContact[]
  modifyContacts: (contacts: TContact[]) => void
}

type State = {}

type DispatchProps = {}

type Props = OwnProps & State & DispatchProps

const PrimaryContact = ({
  columns,
  primaryContact,
  modifyContacts,
  contacts,
  padding = null,
  titleStyles = null
}: Props) => {
  const modify = (field: keyof TContact) => (value: any) =>
    modifyContacts(
      primaryContact
        ? contacts.map(item => (item.id === primaryContact.id ? { ...item, [field]: value } : item))
        : [...(contacts || []), { ...createNewContact(true), [field]: value }]
    )

  const fields = {
    firstName: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <Input title={'First Name'} value={oc(primaryContact).firstName('')} onChange={modify('firstName')} />
      </ColumnRow>
    ),
    phone: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber title={'Phone'} value={oc(primaryContact).phone('')} onChange={modify('phone')} />
      </ColumnRow>
    ),
    email: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.Email title={'Email'} value={oc(primaryContact).email('')} onChange={modify('email')} />
      </ColumnRow>
    ),
    lastName: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <Input title={'Last Name'} value={oc(primaryContact).lastName('')} onChange={modify('lastName')} />
      </ColumnRow>
    ),
    phone2: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber title={'Phone 2'} value={oc(primaryContact).phone2('')} onChange={modify('phone2')} />
      </ColumnRow>
    ),
    fax: () => (
      <ColumnRow margin={{ bottom: 25 }}>
        <InputField.PhoneNumber title={'Fax'} value={oc(primaryContact).fax('')} onChange={modify('fax')} />
      </ColumnRow>
    )
  }

  return (
    <Column columns={17} isGrid={true} padding={padding}>
      <Title style={titleStyles} rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
        Primary Contact
      </Title>

      {columns === 3 ? (
        <Container>
          <GridColumn isGrid={true} padding={{ right: 10 }}>
            {fields.firstName()}
            {fields.phone2()}
          </GridColumn>
          <GridColumn isGrid={true} padding={{ left: 10, right: 10 }}>
            {fields.lastName()}
            {fields.email()}
          </GridColumn>
          <GridColumn isGrid={true} padding={{ left: 10 }}>
            {fields.phone()}
            {fields.fax()}
          </GridColumn>
        </Container>
      ) : (
        <Container>
          <GridColumn columns={8} isGrid={true} padding={{ right: 10 }}>
            {fields.firstName()}
            {fields.phone()}
            {fields.email()}
          </GridColumn>
          <GridColumn columns={8} isGrid={true} padding={{ left: 10 }}>
            {fields.lastName()}
            {fields.phone2()}
            {fields.fax()}
          </GridColumn>
        </Container>
      )}
    </Column>
  )
}

export default connect(
  null,
  null
)(PrimaryContact)
