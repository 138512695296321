import { ISubClient } from '../../../components/common/subClient/interfaces'
import { schemaSubClient } from '../../yupScheme'
import { checkContactsValidation } from '../contact'
import { debuggingMode } from '../../debug'

export const checkSubClientValidation = (subClient: ISubClient): boolean => {
  if (debuggingMode.common) {
    schemaSubClient.validate(subClient).catch(e =>
      //   getStore().dispatch(
      //     addServerMessage({
      //       type: 'error',
      //       message: e.message
      //     })
      //   )
      // tslint:disable-next-line:no-console
      console.log('checkSubClientValidation', e)
    )
  }
  return schemaSubClient.isValidSync(subClient) && checkContactsValidation(subClient.businessPartner.contacts)
}
