import { BusinessPartnerViewDTO, ContactDTO } from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddContactToStore } from '../../../components/common/contact/epics'
import { tryAddBusinessPartnerToStore } from '../../../components/common/businessPartner/epics'

export const parseBusinessPartners = (businessPartners: BusinessPartnerViewDTO[]) => {
  const lists: { contacts: ContactDTO[] } = {
    contacts: []
  }
  const clearedBusinessPartners = businessPartners.filter(Boolean).map(businessPartner => {
    if (businessPartner.primaryContact) {
      lists.contacts.push(businessPartner.primaryContact)
    }
    return R.omit(businessPartner, ['primaryContact'])
  })

  tryAddContactToStore(lists.contacts)
  tryAddBusinessPartnerToStore(clearedBusinessPartners)
}
