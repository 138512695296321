// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url'

import { Configuration } from './configuration'

const BASE_PATH = 'https://intermodal-gateway.dev.port24.com/document-service'.replace(/\/+$/, '')

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration

  constructor(configuration?: Configuration, protected basePath: string = configuration.basePath) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

/**
 *
 * @export
 * @interface AccountingReportFilter
 */
export interface AccountingReportFilter {
  /**
   *
   * @type {string}
   * @memberof AccountingReportFilter
   */
  accountingReportType?: AccountingReportFilter.AccountingReportTypeEnum
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingReportFilter
   */
  activityCompletionDateTimeFrom?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingReportFilter
   */
  activityCompletionDateTimeTill?: DateISOString
  /**
   *
   * @type {Array<string>}
   * @memberof AccountingReportFilter
   */
  customerIds?: Array<string>
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingReportFilter
   */
  ddoCompletionDateTimeFrom?: DateISOString
  /**
   *
   * @type {DateISOString}
   * @memberof AccountingReportFilter
   */
  ddoCompletionDateTimeTill?: DateISOString
  /**
   *
   * @type {Array<string>}
   * @memberof AccountingReportFilter
   */
  vendorIds?: Array<string>
}

/**
 * @export
 * @namespace AccountingReportFilter
 */
export namespace AccountingReportFilter {
  /**
   * @export
   * @enum {string}
   */
  export enum AccountingReportTypeEnum {
    RECEIVABLE = <any>'ACCOUNTS_RECEIVABLE',
    PAYABLE = <any>'ACCOUNTS_PAYABLE'
  }
}

/**
 *
 * @export
 * @interface ApiError
 */
export interface ApiError {
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  field?: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  value?: string
}

/**
 *
 * @export
 * @interface ApplicationFormDTO
 */
export interface ApplicationFormDTO {
  /**
   *
   * @type {string}
   * @memberof ApplicationFormDTO
   */
  documentType?: ApplicationFormDTO.DocumentTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationFormDTO
   */
  driverTypes?: Array<ApplicationFormDTO.DriverTypesEnum>
  /**
   *
   * @type {string}
   * @memberof ApplicationFormDTO
   */
  formId?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormDTO
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormDTO
   */
  lang?: ApplicationFormDTO.LangEnum
  /**
   *
   * @type {string}
   * @memberof ApplicationFormDTO
   */
  name?: string
}

/**
 * @export
 * @namespace ApplicationFormDTO
 */
export namespace ApplicationFormDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DocumentTypeEnum {
    APPFORM = <any>'APP_FORM',
    LEASEAGREEMENT = <any>'LEASE_AGREEMENT'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypesEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
}

/**
 *
 * @export
 * @interface ApplicationFormRequestDTO
 */
export interface ApplicationFormRequestDTO {
  /**
   *
   * @type {FormAddressDTO}
   * @memberof ApplicationFormRequestDTO
   */
  address?: FormAddressDTO
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  documentType?: ApplicationFormRequestDTO.DocumentTypeEnum
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  driverName?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  driverType?: ApplicationFormRequestDTO.DriverTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof ApplicationFormRequestDTO
   */
  endorsements?: Array<ApplicationFormRequestDTO.EndorsementsEnum>
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  formId?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  lang?: ApplicationFormRequestDTO.LangEnum
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  licenseExpirationDate?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  licenseNumber?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  licenseState?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  medicalCardExpirationDate?: string
  /**
   *
   * @type {string}
   * @memberof ApplicationFormRequestDTO
   */
  phone?: string
  /**
   *
   * @type {TruckDTO}
   * @memberof ApplicationFormRequestDTO
   */
  truck?: TruckDTO
}

/**
 * @export
 * @namespace ApplicationFormRequestDTO
 */
export namespace ApplicationFormRequestDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DocumentTypeEnum {
    APPFORM = <any>'APP_FORM',
    LEASEAGREEMENT = <any>'LEASE_AGREEMENT'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum EndorsementsEnum {
    HAZARDOUSMATERIALS = <any>'HAZARDOUS_MATERIALS',
    TANKERWITHHAZARDOUSMATERIALS = <any>'TANKER_WITH_HAZARDOUS_MATERIALS',
    TANKER = <any>'TANKER',
    DOUBLESTRIPLES = <any>'DOUBLES_TRIPLES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
}

/**
 *
 * @export
 * @interface BalanceDTO
 */
export interface BalanceDTO {
  /**
   *
   * @type {number}
   * @memberof BalanceDTO
   */
  currentEscrow?: number
  /**
   *
   * @type {number}
   * @memberof BalanceDTO
   */
  currentLoan?: number
  /**
   *
   * @type {number}
   * @memberof BalanceDTO
   */
  loanTotal?: number
  /**
   *
   * @type {number}
   * @memberof BalanceDTO
   */
  maxEscrow?: number
}

/**
 *
 * @export
 * @interface Deduction
 */
export interface Deduction {
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  eld?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  escrow?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  fuel?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  loan?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  parking?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  pdInsurance?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  toll?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  total?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  truckRegistration?: number
  /**
   *
   * @type {number}
   * @memberof Deduction
   */
  wcInsurance?: number
}

/**
 *
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
  /**
   *
   * @type {FileDTO}
   * @memberof DocumentDTO
   */
  file?: FileDTO
  /**
   *
   * @type {DocumentMetadataDTO}
   * @memberof DocumentDTO
   */
  metadata?: DocumentMetadataDTO
  /**
   *
   * @type {DocumentValidationDTO}
   * @memberof DocumentDTO
   */
  validation?: DocumentValidationDTO
}

/**
 *
 * @export
 * @interface DocumentMetadataDTO
 */
export interface DocumentMetadataDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityId?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  activityType?: DocumentMetadataDTO.ActivityTypeEnum
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderId?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  dispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  qrCode?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMetadataDTO
   */
  type?: DocumentMetadataDTO.TypeEnum
}

/**
 * @export
 * @namespace DocumentMetadataDTO
 */
export namespace DocumentMetadataDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum ActivityTypeEnum {
    GOTO = <any>'GOTO',
    BOBTAILGOTO = <any>'BOBTAIL_GOTO',
    EMPTY = <any>'EMPTY',
    OFFDUTY = <any>'OFF_DUTY',
    PICKUPEMPTY = <any>'PICK_UP_EMPTY',
    PICKUPFULL = <any>'PICK_UP_FULL',
    GETUNLOADED = <any>'GET_UNLOADED',
    GETLOADED = <any>'GET_LOADED',
    DROPEMPTYWITHCHASSIS = <any>'DROP_EMPTY_WITH_CHASSIS',
    DROPFULLWITHCHASSIS = <any>'DROP_FULL_WITH_CHASSIS',
    PICKCHASSIS = <any>'PICK_CHASSIS',
    DROPCHASSIS = <any>'DROP_CHASSIS',
    DROPEMPTYWITHOUTCHASSIS = <any>'DROP_EMPTY_WITH_OUT_CHASSIS',
    DROPFULLWITHOUTCHASSIS = <any>'DROP_FULL_WITH_OUT_CHASSIS',
    SCALETARE = <any>'SCALE_TARE',
    SCALEFULL = <any>'SCALE_FULL'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    TIR = <any>'TIR',
    POD = <any>'POD',
    OTHER = <any>'OTHER'
  }
}

/**
 *
 * @export
 * @interface DocumentValidationDTO
 */
export interface DocumentValidationDTO {
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  billOfLadingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  bookingNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  bookingNumberRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  chassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  containerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  dispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  message?: string
  /**
   *
   * @type {Array<string>}
   * @memberof DocumentValidationDTO
   */
  messages?: Array<string>
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedChassisNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedContainerNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  recognizedDispatchDeliveryOrderNumber?: string
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  referenceNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  referenceNumberRecognized?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentValidationDTO
   */
  signatureRecognized?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentValidationDTO
   */
  status?: DocumentValidationDTO.StatusEnum
}

/**
 * @export
 * @namespace DocumentValidationDTO
 */
export namespace DocumentValidationDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    ACCEPTED = <any>'ACCEPTED',
    FAILED = <any>'FAILED'
  }
}

/**
 *
 * @export
 * @interface DriverAccountingReportDTO
 */
export interface DriverAccountingReportDTO {
  /**
   *
   * @type {BalanceDTO}
   * @memberof DriverAccountingReportDTO
   */
  balance?: BalanceDTO
  /**
   *
   * @type {PaymentCheckDTO}
   * @memberof DriverAccountingReportDTO
   */
  check?: PaymentCheckDTO
  /**
   *
   * @type {Array<ReportDetailsDTO>}
   * @memberof DriverAccountingReportDTO
   */
  details?: Array<ReportDetailsDTO>
  /**
   *
   * @type {FuelTransactionDetails}
   * @memberof DriverAccountingReportDTO
   */
  fuelTransactionDetails?: FuelTransactionDetails
  /**
   *
   * @type {ReportHeader}
   * @memberof DriverAccountingReportDTO
   */
  header?: ReportHeader
  /**
   *
   * @type {string}
   * @memberof DriverAccountingReportDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverAccountingReportDTO
   */
  number?: string
  /**
   *
   * @type {TollTransactionDetails}
   * @memberof DriverAccountingReportDTO
   */
  tollTransactionDetails?: TollTransactionDetails
  /**
   *
   * @type {string}
   * @memberof DriverAccountingReportDTO
   */
  type?: DriverAccountingReportDTO.TypeEnum
}

/**
 * @export
 * @namespace DriverAccountingReportDTO
 */
export namespace DriverAccountingReportDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    DAILY = <any>'DAILY',
    WEEKLY = <any>'WEEKLY'
  }
}

/**
 *
 * @export
 * @interface DriverReportDTO
 */
export interface DriverReportDTO {
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  comment?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  contentType?: string
  /**
   *
   * @type {DateISOString}
   * @memberof DriverReportDTO
   */
  date?: DateISOString
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  status?: DriverReportDTO.StatusEnum
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  type?: DriverReportDTO.TypeEnum
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportDTO
   */
  userId?: string
}

/**
 * @export
 * @namespace DriverReportDTO
 */
export namespace DriverReportDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PENDING = <any>'PENDING',
    READY = <any>'READY',
    FAILED = <any>'FAILED'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    PSP = <any>'PSP',
    MVR = <any>'MVR'
  }
}

/**
 *
 * @export
 * @interface DriverReportGenerationRequestDTO
 */
export interface DriverReportGenerationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  licenseIssueStateId?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  licenseNumber?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof DriverReportGenerationRequestDTO
   */
  type?: DriverReportGenerationRequestDTO.TypeEnum
}

/**
 * @export
 * @namespace DriverReportGenerationRequestDTO
 */
export namespace DriverReportGenerationRequestDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    PSP = <any>'PSP',
    MVR = <any>'MVR'
  }
}

/**
 *
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  contentType?: string
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof FileDTO
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  thumb?: string
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  url?: string
}

/**
 *
 * @export
 * @interface FileVersionDTO
 */
export interface FileVersionDTO {
  /**
   *
   * @type {DateISOString}
   * @memberof FileVersionDTO
   */
  createdAt?: DateISOString
  /**
   *
   * @type {string}
   * @memberof FileVersionDTO
   */
  createdBy?: string
  /**
   *
   * @type {FileDTO}
   * @memberof FileVersionDTO
   */
  data?: FileDTO
  /**
   *
   * @type {string}
   * @memberof FileVersionDTO
   */
  entityId?: string
  /**
   *
   * @type {string}
   * @memberof FileVersionDTO
   */
  type?: FileVersionDTO.TypeEnum
}

/**
 * @export
 * @namespace FileVersionDTO
 */
export namespace FileVersionDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    VENDORLICENSE = <any>'VENDOR_LICENSE',
    VENDORLICENSEBACK = <any>'VENDOR_LICENSE_BACK',
    VENDORMEDICALEXAM = <any>'VENDOR_MEDICAL_EXAM',
    VENDORTWIC = <any>'VENDOR_TWIC',
    VENDORPORT = <any>'VENDOR_PORT',
    VENDORW4 = <any>'VENDOR_W4',
    VENDORW9 = <any>'VENDOR_W9',
    VENDORI9 = <any>'VENDOR_I9',
    VENDORAPPLICATION = <any>'VENDOR_APPLICATION',
    VENDORLEASE = <any>'VENDOR_LEASE',
    VENDORPROOFEINTAXID = <any>'VENDOR_PROOF_EIN_TAX_ID',
    MACHINERYREGISTRATION = <any>'MACHINERY_REGISTRATION',
    MACHINERYDOTSAFETYINSPECTION = <any>'MACHINERY_DOT_SAFETY_INSPECTION',
    MACHINERYINSURANCECOVERAGE = <any>'MACHINERY_INSURANCE_COVERAGE',
    MACHINERYMAINTENANCEINSPECTION = <any>'MACHINERY_MAINTENANCE_INSPECTION',
    MACHINERYREPAIR = <any>'MACHINERY_REPAIR'
  }
}

/**
 *
 * @export
 * @interface FormAddressDTO
 */
export interface FormAddressDTO {
  /**
   *
   * @type {string}
   * @memberof FormAddressDTO
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof FormAddressDTO
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof FormAddressDTO
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof FormAddressDTO
   */
  street?: string
}

/**
 *
 * @export
 * @interface FuelTransaction
 */
export interface FuelTransaction {
  /**
   *
   * @type {number}
   * @memberof FuelTransaction
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof FuelTransaction
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof FuelTransaction
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof FuelTransaction
   */
  locationName?: string
  /**
   *
   * @type {number}
   * @memberof FuelTransaction
   */
  quantity?: number
  /**
   *
   * @type {string}
   * @memberof FuelTransaction
   */
  time?: string
}

/**
 *
 * @export
 * @interface FuelTransactionDetails
 */
export interface FuelTransactionDetails {
  /**
   *
   * @type {string}
   * @memberof FuelTransactionDetails
   */
  fuelCardNumber?: string
  /**
   *
   * @type {Array<FuelTransactionGroup>}
   * @memberof FuelTransactionDetails
   */
  fuelTransactionGroups?: Array<FuelTransactionGroup>
  /**
   *
   * @type {number}
   * @memberof FuelTransactionDetails
   */
  total?: number
  /**
   *
   * @type {string}
   * @memberof FuelTransactionDetails
   */
  totalPrefix?: string
}

/**
 *
 * @export
 * @interface FuelTransactionGroup
 */
export interface FuelTransactionGroup {
  /**
   *
   * @type {string}
   * @memberof FuelTransactionGroup
   */
  date?: string
  /**
   *
   * @type {Array<FuelTransaction>}
   * @memberof FuelTransactionGroup
   */
  fuelTransactions?: Array<FuelTransaction>
  /**
   *
   * @type {number}
   * @memberof FuelTransactionGroup
   */
  total?: number
}

/**
 *
 * @export
 * @interface Income
 */
export interface Income {
  /**
   *
   * @type {string}
   * @memberof Income
   */
  activityDescription?: string
  /**
   *
   * @type {number}
   * @memberof Income
   */
  baseRate?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  baseTotal?: number
  /**
   *
   * @type {string}
   * @memberof Income
   */
  containerNumber?: string
  /**
   *
   * @type {number}
   * @memberof Income
   */
  deduction?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  detention?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  fuel?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  hazmat?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  layover?: number
  /**
   *
   * @type {string}
   * @memberof Income
   */
  locationName?: string
  /**
   *
   * @type {number}
   * @memberof Income
   */
  misc?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  overweight?: number
  /**
   *
   * @type {number}
   * @memberof Income
   */
  reefer?: number
  /**
   *
   * @type {string}
   * @memberof Income
   */
  time?: string
  /**
   *
   * @type {number}
   * @memberof Income
   */
  total?: number
}

/**
 *
 * @export
 * @interface InputStream
 */
export interface InputStream {}

/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  absolute?: boolean
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  absoluteFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  absolutePath?: string
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  canonicalFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  canonicalPath?: string
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  directory?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  executable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  file?: boolean
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  freeSpace?: number
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  hidden?: boolean
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  lastModified?: number
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  parent?: string
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  parentFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  path?: string
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  readable?: boolean
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  totalSpace?: number
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  usableSpace?: number
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  writable?: boolean
}

/**
 *
 * @export
 * @interface MvrOrderCompletedEventDTO
 */
export interface MvrOrderCompletedEventDTO {
  /**
   *
   * @type {string}
   * @memberof MvrOrderCompletedEventDTO
   */
  resourceGuid?: string
}

/**
 *
 * @export
 * @interface OfficialFormDTO
 */
export interface OfficialFormDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof OfficialFormDTO
   */
  driverTypes?: Array<OfficialFormDTO.DriverTypesEnum>
  /**
   *
   * @type {string}
   * @memberof OfficialFormDTO
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormDTO
   */
  lang?: OfficialFormDTO.LangEnum
  /**
   *
   * @type {string}
   * @memberof OfficialFormDTO
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormDTO
   */
  type?: OfficialFormDTO.TypeEnum
}

/**
 * @export
 * @namespace OfficialFormDTO
 */
export namespace OfficialFormDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypesEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    W9 = <any>'W9',
    I9 = <any>'I9'
  }
}

/**
 *
 * @export
 * @interface OfficialFormRequestDTO
 */
export interface OfficialFormRequestDTO {
  /**
   *
   * @type {FormAddressDTO}
   * @memberof OfficialFormRequestDTO
   */
  address?: FormAddressDTO
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  companyName?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  driverFirstName?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  driverLastName?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  driverName?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  lang?: OfficialFormRequestDTO.LangEnum
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  taxId?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormRequestDTO
   */
  type?: OfficialFormRequestDTO.TypeEnum
}

/**
 * @export
 * @namespace OfficialFormRequestDTO
 */
export namespace OfficialFormRequestDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    W9 = <any>'W9',
    I9 = <any>'I9'
  }
}

/**
 *
 * @export
 * @interface OfficialFormResponseDTO
 */
export interface OfficialFormResponseDTO {
  /**
   *
   * @type {FileDTO}
   * @memberof OfficialFormResponseDTO
   */
  document?: FileDTO
  /**
   *
   * @type {string}
   * @memberof OfficialFormResponseDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormResponseDTO
   */
  esignUrl?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormResponseDTO
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OfficialFormResponseDTO
   */
  status?: OfficialFormResponseDTO.StatusEnum
}

/**
 * @export
 * @namespace OfficialFormResponseDTO
 */
export namespace OfficialFormResponseDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    PENDING = <any>'PENDING',
    READY = <any>'READY',
    FAILED = <any>'FAILED'
  }
}

/**
 *
 * @export
 * @interface PaymentCheckDTO
 */
export interface PaymentCheckDTO {
  /**
   *
   * @type {number}
   * @memberof PaymentCheckDTO
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof PaymentCheckDTO
   */
  type?: PaymentCheckDTO.TypeEnum
}

/**
 * @export
 * @namespace PaymentCheckDTO
 */
export namespace PaymentCheckDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    ACH = <any>'ACH',
    CHECK = <any>'CHECK',
    AMEX = <any>'AMEX',
    MASTER = <any>'MASTER',
    VISA = <any>'VISA',
    CASH = <any>'CASH',
    ECHECK = <any>'E_CHECK',
    WIRE = <any>'WIRE'
  }
}

/**
 *
 * @export
 * @interface PreviewApplicationFormRequestDTO
 */
export interface PreviewApplicationFormRequestDTO {
  /**
   *
   * @type {string}
   * @memberof PreviewApplicationFormRequestDTO
   */
  documentType?: PreviewApplicationFormRequestDTO.DocumentTypeEnum
  /**
   *
   * @type {string}
   * @memberof PreviewApplicationFormRequestDTO
   */
  driverType?: PreviewApplicationFormRequestDTO.DriverTypeEnum
  /**
   *
   * @type {string}
   * @memberof PreviewApplicationFormRequestDTO
   */
  lang?: PreviewApplicationFormRequestDTO.LangEnum
}

/**
 * @export
 * @namespace PreviewApplicationFormRequestDTO
 */
export namespace PreviewApplicationFormRequestDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum DocumentTypeEnum {
    APPFORM = <any>'APP_FORM',
    LEASEAGREEMENT = <any>'LEASE_AGREEMENT'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum DriverTypeEnum {
    COMPANY = <any>'COMPANY',
    OWNEROPERATOR = <any>'OWNER_OPERATOR'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
}

/**
 *
 * @export
 * @interface PreviewOfficialFormRequestDTO
 */
export interface PreviewOfficialFormRequestDTO {
  /**
   *
   * @type {string}
   * @memberof PreviewOfficialFormRequestDTO
   */
  lang?: PreviewOfficialFormRequestDTO.LangEnum
  /**
   *
   * @type {string}
   * @memberof PreviewOfficialFormRequestDTO
   */
  type?: PreviewOfficialFormRequestDTO.TypeEnum
}

/**
 * @export
 * @namespace PreviewOfficialFormRequestDTO
 */
export namespace PreviewOfficialFormRequestDTO {
  /**
   * @export
   * @enum {string}
   */
  export enum LangEnum {
    EN = <any>'EN',
    ES = <any>'ES'
  }
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    W9 = <any>'W9',
    I9 = <any>'I9'
  }
}

/**
 *
 * @export
 * @interface PreviewOfficialFormResponseDTO
 */
export interface PreviewOfficialFormResponseDTO {
  /**
   *
   * @type {string}
   * @memberof PreviewOfficialFormResponseDTO
   */
  signingUrl?: string
}

/**
 *
 * @export
 * @interface ReportDetailsDTO
 */
export interface ReportDetailsDTO {
  /**
   *
   * @type {number}
   * @memberof ReportDetailsDTO
   */
  beginningBalance?: number
  /**
   *
   * @type {string}
   * @memberof ReportDetailsDTO
   */
  beginningBalanceFrom?: string
  /**
   *
   * @type {string}
   * @memberof ReportDetailsDTO
   */
  date?: string
  /**
   *
   * @type {Deduction}
   * @memberof ReportDetailsDTO
   */
  deduction?: Deduction
  /**
   *
   * @type {string}
   * @memberof ReportDetailsDTO
   */
  fuelPercent?: string
  /**
   *
   * @type {string}
   * @memberof ReportDetailsDTO
   */
  incomeDate?: string
  /**
   *
   * @type {number}
   * @memberof ReportDetailsDTO
   */
  incomeTotal?: number
  /**
   *
   * @type {Array<Income>}
   * @memberof ReportDetailsDTO
   */
  incomes?: Array<Income>
  /**
   *
   * @type {number}
   * @memberof ReportDetailsDTO
   */
  total?: number
}

/**
 *
 * @export
 * @interface ReportHeader
 */
export interface ReportHeader {
  /**
   *
   * @type {string}
   * @memberof ReportHeader
   */
  driverName?: string
  /**
   *
   * @type {string}
   * @memberof ReportHeader
   */
  periodFrom?: string
  /**
   *
   * @type {string}
   * @memberof ReportHeader
   */
  periodTill?: string
  /**
   *
   * @type {number}
   * @memberof ReportHeader
   */
  totalEarned?: number
  /**
   *
   * @type {string}
   * @memberof ReportHeader
   */
  vendorName?: string
  /**
   *
   * @type {number}
   * @memberof ReportHeader
   */
  ydtTotal?: number
}

/**
 *
 * @export
 * @interface Resource
 */
export interface Resource {
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  description?: string
  /**
   *
   * @type {any}
   * @memberof Resource
   */
  file?: any
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  filename?: string
  /**
   *
   * @type {InputStream}
   * @memberof Resource
   */
  inputStream?: InputStream
  /**
   *
   * @type {boolean}
   * @memberof Resource
   */
  open?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Resource
   */
  readable?: boolean
  /**
   *
   * @type {URI}
   * @memberof Resource
   */
  uri?: URI
  /**
   *
   * @type {URL}
   * @memberof Resource
   */
  url?: URL
}

/**
 *
 * @export
 * @interface Serializable
 */
export interface Serializable {}

/**
 *
 * @export
 * @interface TollTransaction
 */
export interface TollTransaction {
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  agency?: string
  /**
   *
   * @type {number}
   * @memberof TollTransaction
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  entry?: string
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  entryAt?: string
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  exit?: string
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  exitAt?: string
  /**
   *
   * @type {string}
   * @memberof TollTransaction
   */
  time?: string
}

/**
 *
 * @export
 * @interface TollTransactionDetails
 */
export interface TollTransactionDetails {
  /**
   *
   * @type {string}
   * @memberof TollTransactionDetails
   */
  tollTagNumber?: string
  /**
   *
   * @type {Array<TollTransactionGroup>}
   * @memberof TollTransactionDetails
   */
  tollTransactionGroups?: Array<TollTransactionGroup>
  /**
   *
   * @type {number}
   * @memberof TollTransactionDetails
   */
  total?: number
  /**
   *
   * @type {string}
   * @memberof TollTransactionDetails
   */
  totalPrefix?: string
}

/**
 *
 * @export
 * @interface TollTransactionGroup
 */
export interface TollTransactionGroup {
  /**
   *
   * @type {string}
   * @memberof TollTransactionGroup
   */
  date?: string
  /**
   *
   * @type {Array<TollTransaction>}
   * @memberof TollTransactionGroup
   */
  tollTransactions?: Array<TollTransaction>
  /**
   *
   * @type {number}
   * @memberof TollTransactionGroup
   */
  total?: number
}

/**
 *
 * @export
 * @interface TruckDTO
 */
export interface TruckDTO {
  /**
   *
   * @type {string}
   * @memberof TruckDTO
   */
  make?: string
  /**
   *
   * @type {string}
   * @memberof TruckDTO
   */
  vin?: string
  /**
   *
   * @type {string}
   * @memberof TruckDTO
   */
  year?: string
}

/**
 *
 * @export
 * @interface URI
 */
export interface URI {
  /**
   *
   * @type {boolean}
   * @memberof URI
   */
  absolute?: boolean
  /**
   *
   * @type {string}
   * @memberof URI
   */
  authority?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  fragment?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  host?: string
  /**
   *
   * @type {boolean}
   * @memberof URI
   */
  opaque?: boolean
  /**
   *
   * @type {string}
   * @memberof URI
   */
  path?: string
  /**
   *
   * @type {number}
   * @memberof URI
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof URI
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawAuthority?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawFragment?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawPath?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawQuery?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawSchemeSpecificPart?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawUserInfo?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  scheme?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  schemeSpecificPart?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  userInfo?: string
}

/**
 *
 * @export
 * @interface URL
 */
export interface URL {
  /**
   *
   * @type {string}
   * @memberof URL
   */
  authority?: string
  /**
   *
   * @type {any}
   * @memberof URL
   */
  content?: any
  /**
   *
   * @type {number}
   * @memberof URL
   */
  defaultPort?: number
  /**
   *
   * @type {string}
   * @memberof URL
   */
  file?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  host?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  path?: string
  /**
   *
   * @type {number}
   * @memberof URL
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof URL
   */
  protocol?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  ref?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  userInfo?: string
}

/**
 * AccountingReportControllerApi - fetch parameter creator
 * @export
 */
export const AccountingReportControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateDriverReportUrl
     * @param {DriverAccountingReportDTO} reportDTO reportDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDriverReportUrl(reportDTO: DriverAccountingReportDTO, options: any = {}): FetchArgs {
      // verify required parameter 'reportDTO' is not null or undefined
      if (reportDTO === null || reportDTO === undefined) {
        throw new RequiredError(
          'reportDTO',
          'Required parameter reportDTO was null or undefined when calling generateDriverReportUrl.'
        )
      }
      const localVarPath = `/accounting-report/driver/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverAccountingReportDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(reportDTO || {}) : reportDTO || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary generateReportUrl
     * @param {AccountingReportFilter} filter filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReportUrl(filter: AccountingReportFilter, options: any = {}): FetchArgs {
      // verify required parameter 'filter' is not null or undefined
      if (filter === null || filter === undefined) {
        throw new RequiredError(
          'filter',
          'Required parameter filter was null or undefined when calling generateReportUrl.'
        )
      }
      const localVarPath = `/accounting-report/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'AccountingReportFilter' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(filter || {}) : filter || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AccountingReportControllerApi - functional programming interface
 * @export
 */
export const AccountingReportControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateDriverReportUrl
     * @param {DriverAccountingReportDTO} reportDTO reportDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDriverReportUrl(
      reportDTO: DriverAccountingReportDTO,
      options?: any
    ): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = AccountingReportControllerApiFetchParamCreator(configuration).generateDriverReportUrl(
        reportDTO,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary generateReportUrl
     * @param {AccountingReportFilter} filter filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReportUrl(filter: AccountingReportFilter, options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = AccountingReportControllerApiFetchParamCreator(configuration).generateReportUrl(
        filter,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * AccountingReportControllerApi - factory interface
 * @export
 */
export const AccountingReportControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary generateDriverReportUrl
     * @param {DriverAccountingReportDTO} reportDTO reportDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateDriverReportUrl(reportDTO: DriverAccountingReportDTO, options?: any) {
      return AccountingReportControllerApiFp(configuration).generateDriverReportUrl(reportDTO, options)(basePath)
    },
    /**
     *
     * @summary generateReportUrl
     * @param {AccountingReportFilter} filter filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReportUrl(filter: AccountingReportFilter, options?: any) {
      return AccountingReportControllerApiFp(configuration).generateReportUrl(filter, options)(basePath)
    }
  }
}

/**
 * AccountingReportControllerApi - object-oriented interface
 * @export
 * @class AccountingReportControllerApi
 * @extends {BaseAPI}
 */
export class AccountingReportControllerApi extends BaseAPI {
  /**
   *
   * @summary generateDriverReportUrl
   * @param {DriverAccountingReportDTO} reportDTO reportDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingReportControllerApi
   */
  public generateDriverReportUrl(reportDTO: DriverAccountingReportDTO, options?: any) {
    return AccountingReportControllerApiFp(this.configuration).generateDriverReportUrl(reportDTO, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary generateReportUrl
   * @param {AccountingReportFilter} filter filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountingReportControllerApi
   */
  public generateReportUrl(filter: AccountingReportFilter, options?: any) {
    return AccountingReportControllerApiFp(this.configuration).generateReportUrl(filter, options)(this.basePath)
  }
}

/**
 * AppSettingsControllerApi - fetch parameter creator
 * @export
 */
export const AppSettingsControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary getPspApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspApiKey(options: any = {}): FetchArgs {
      const localVarPath = `/settings/psp-api-key`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary updatePspApiKey
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePspApiKey(key: string, options: any = {}): FetchArgs {
      // verify required parameter 'key' is not null or undefined
      if (key === null || key === undefined) {
        throw new RequiredError('key', 'Required parameter key was null or undefined when calling updatePspApiKey.')
      }
      const localVarPath = `/settings/psp-api-key`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (key !== undefined) {
        localVarQueryParameter['key'] = key
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AppSettingsControllerApi - functional programming interface
 * @export
 */
export const AppSettingsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary getPspApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspApiKey(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = AppSettingsControllerApiFetchParamCreator(configuration).getPspApiKey(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary updatePspApiKey
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePspApiKey(key: string, options?: any): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = AppSettingsControllerApiFetchParamCreator(configuration).updatePspApiKey(key, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * AppSettingsControllerApi - factory interface
 * @export
 */
export const AppSettingsControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary getPspApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPspApiKey(options?: any) {
      return AppSettingsControllerApiFp(configuration).getPspApiKey(options)(basePath)
    },
    /**
     *
     * @summary updatePspApiKey
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePspApiKey(key: string, options?: any) {
      return AppSettingsControllerApiFp(configuration).updatePspApiKey(key, options)(basePath)
    }
  }
}

/**
 * AppSettingsControllerApi - object-oriented interface
 * @export
 * @class AppSettingsControllerApi
 * @extends {BaseAPI}
 */
export class AppSettingsControllerApi extends BaseAPI {
  /**
   *
   * @summary getPspApiKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingsControllerApi
   */
  public getPspApiKey(options?: any) {
    return AppSettingsControllerApiFp(this.configuration).getPspApiKey(options)(this.basePath)
  }

  /**
   *
   * @summary updatePspApiKey
   * @param {string} key key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppSettingsControllerApi
   */
  public updatePspApiKey(key: string, options?: any) {
    return AppSettingsControllerApiFp(this.configuration).updatePspApiKey(key, options)(this.basePath)
  }
}

/**
 * ApplicationFormControllerApi - fetch parameter creator
 * @export
 */
export const ApplicationFormControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createAndRenderApplicationFormDocumentPreview
     * @param {PreviewApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndRenderApplicationFormDocumentPreview(
      request: PreviewApplicationFormRequestDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createAndRenderApplicationFormDocumentPreview.'
        )
      }
      const localVarPath = `/application-form/preview`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'PreviewApplicationFormRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary createApplicationFormDocument
     * @param {ApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationFormDocument(request: ApplicationFormRequestDTO, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createApplicationFormDocument.'
        )
      }
      const localVarPath = `/application-form/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'ApplicationFormRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getAllApplicationForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllApplicationForms(lang?: 'EN' | 'ES', options: any = {}): FetchArgs {
      const localVarPath = `/application-form/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary renderApplicationFormDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renderApplicationFormDocument(options: any = {}): FetchArgs {
      const localVarPath = `/application-form/render`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ApplicationFormControllerApi - functional programming interface
 * @export
 */
export const ApplicationFormControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createAndRenderApplicationFormDocumentPreview
     * @param {PreviewApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndRenderApplicationFormDocumentPreview(
      request: PreviewApplicationFormRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = ApplicationFormControllerApiFetchParamCreator(
        configuration
      ).createAndRenderApplicationFormDocumentPreview(request, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary createApplicationFormDocument
     * @param {ApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationFormDocument(
      request: ApplicationFormRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = ApplicationFormControllerApiFetchParamCreator(
        configuration
      ).createApplicationFormDocument(request, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getAllApplicationForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllApplicationForms(
      lang?: 'EN' | 'ES',
      options?: any
    ): (basePath?: string) => Promise<Array<ApplicationFormDTO>> {
      const localVarFetchArgs = ApplicationFormControllerApiFetchParamCreator(configuration).getAllApplicationForms(
        lang,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary renderApplicationFormDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renderApplicationFormDocument(options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = ApplicationFormControllerApiFetchParamCreator(
        configuration
      ).renderApplicationFormDocument(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * ApplicationFormControllerApi - factory interface
 * @export
 */
export const ApplicationFormControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary createAndRenderApplicationFormDocumentPreview
     * @param {PreviewApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndRenderApplicationFormDocumentPreview(request: PreviewApplicationFormRequestDTO, options?: any) {
      return ApplicationFormControllerApiFp(configuration).createAndRenderApplicationFormDocumentPreview(
        request,
        options
      )(basePath)
    },
    /**
     *
     * @summary createApplicationFormDocument
     * @param {ApplicationFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createApplicationFormDocument(request: ApplicationFormRequestDTO, options?: any) {
      return ApplicationFormControllerApiFp(configuration).createApplicationFormDocument(request, options)(basePath)
    },
    /**
     *
     * @summary getAllApplicationForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllApplicationForms(lang?: 'EN' | 'ES', options?: any) {
      return ApplicationFormControllerApiFp(configuration).getAllApplicationForms(lang, options)(basePath)
    },
    /**
     *
     * @summary renderApplicationFormDocument
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renderApplicationFormDocument(options?: any) {
      return ApplicationFormControllerApiFp(configuration).renderApplicationFormDocument(options)(basePath)
    }
  }
}

/**
 * ApplicationFormControllerApi - object-oriented interface
 * @export
 * @class ApplicationFormControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationFormControllerApi extends BaseAPI {
  /**
   *
   * @summary createAndRenderApplicationFormDocumentPreview
   * @param {PreviewApplicationFormRequestDTO} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormControllerApi
   */
  public createAndRenderApplicationFormDocumentPreview(request: PreviewApplicationFormRequestDTO, options?: any) {
    return ApplicationFormControllerApiFp(this.configuration).createAndRenderApplicationFormDocumentPreview(
      request,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary createApplicationFormDocument
   * @param {ApplicationFormRequestDTO} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormControllerApi
   */
  public createApplicationFormDocument(request: ApplicationFormRequestDTO, options?: any) {
    return ApplicationFormControllerApiFp(this.configuration).createApplicationFormDocument(request, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary getAllApplicationForms
   * @param {'EN' | 'ES'} [lang] lang
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormControllerApi
   */
  public getAllApplicationForms(lang?: 'EN' | 'ES', options?: any) {
    return ApplicationFormControllerApiFp(this.configuration).getAllApplicationForms(lang, options)(this.basePath)
  }

  /**
   *
   * @summary renderApplicationFormDocument
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationFormControllerApi
   */
  public renderApplicationFormDocument(options?: any) {
    return ApplicationFormControllerApiFp(this.configuration).renderApplicationFormDocument(options)(this.basePath)
  }
}

/**
 * DocumentControllerApi - fetch parameter creator
 * @export
 */
export const DocumentControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate POD
     * @param {any} metadata metadata
     * @param {any} signature Select signature to upload
     * @param {any} [businessCard] Select business card to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePod(metadata: any, signature: any, businessCard?: any, options: any = {}): FetchArgs {
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new RequiredError(
          'metadata',
          'Required parameter metadata was null or undefined when calling generatePod.'
        )
      }
      // verify required parameter 'signature' is not null or undefined
      if (signature === null || signature === undefined) {
        throw new RequiredError(
          'signature',
          'Required parameter signature was null or undefined when calling generatePod.'
        )
      }
      const localVarPath = `/document/signed-pod`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (businessCard !== undefined) {
        localVarFormParams.set('businessCard', businessCard as any)
      }

      if (metadata !== undefined) {
        localVarFormParams.set('metadata', metadata as any)
      }

      if (signature !== undefined) {
        localVarFormParams.set('signature', signature as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary generateWorkOrderImage
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {string} driverId driverId
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderImage(
      dispatchDeliveryOrderId: string,
      driverId: string,
      hash: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling generateWorkOrderImage.'
        )
      }
      // verify required parameter 'driverId' is not null or undefined
      if (driverId === null || driverId === undefined) {
        throw new RequiredError(
          'driverId',
          'Required parameter driverId was null or undefined when calling generateWorkOrderImage.'
        )
      }
      // verify required parameter 'hash' is not null or undefined
      if (hash === null || hash === undefined) {
        throw new RequiredError(
          'hash',
          'Required parameter hash was null or undefined when calling generateWorkOrderImage.'
        )
      }
      const localVarPath = `/document/work-order-image`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (dispatchDeliveryOrderId !== undefined) {
        localVarQueryParameter['dispatchDeliveryOrderId'] = dispatchDeliveryOrderId
      }

      if (driverId !== undefined) {
        localVarQueryParameter['driverId'] = driverId
      }

      if (hash !== undefined) {
        localVarQueryParameter['hash'] = hash
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary generateWorkOrderUrl
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderUrl(dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling generateWorkOrderUrl.'
        )
      }
      const localVarPath = `/document/work-order-url/{dispatchDeliveryOrderId}`.replace(
        `{${'dispatchDeliveryOrderId'}}`,
        encodeURIComponent(String(dispatchDeliveryOrderId))
      )
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary generateWorkOrder
     * @param {string} activityId activityId
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrder(activityId: string, dispatchDeliveryOrderId: string, options: any = {}): FetchArgs {
      // verify required parameter 'activityId' is not null or undefined
      if (activityId === null || activityId === undefined) {
        throw new RequiredError(
          'activityId',
          'Required parameter activityId was null or undefined when calling generateWorkOrder.'
        )
      }
      // verify required parameter 'dispatchDeliveryOrderId' is not null or undefined
      if (dispatchDeliveryOrderId === null || dispatchDeliveryOrderId === undefined) {
        throw new RequiredError(
          'dispatchDeliveryOrderId',
          'Required parameter dispatchDeliveryOrderId was null or undefined when calling generateWorkOrder.'
        )
      }
      const localVarPath = `/document/work-order/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (activityId !== undefined) {
        localVarQueryParameter['activityId'] = activityId
      }

      if (dispatchDeliveryOrderId !== undefined) {
        localVarQueryParameter['dispatchDeliveryOrderId'] = dispatchDeliveryOrderId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Upload chassis photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadChassisPhoto(metadata: any, photo: any, options: any = {}): FetchArgs {
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new RequiredError(
          'metadata',
          'Required parameter metadata was null or undefined when calling uploadChassisPhoto.'
        )
      }
      // verify required parameter 'photo' is not null or undefined
      if (photo === null || photo === undefined) {
        throw new RequiredError(
          'photo',
          'Required parameter photo was null or undefined when calling uploadChassisPhoto.'
        )
      }
      const localVarPath = `/document/chassis`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (metadata !== undefined) {
        localVarFormParams.set('metadata', metadata as any)
      }

      if (photo !== undefined) {
        localVarFormParams.set('photo', photo as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Upload container photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadContainerPhoto(metadata: any, photo: any, options: any = {}): FetchArgs {
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new RequiredError(
          'metadata',
          'Required parameter metadata was null or undefined when calling uploadContainerPhoto.'
        )
      }
      // verify required parameter 'photo' is not null or undefined
      if (photo === null || photo === undefined) {
        throw new RequiredError(
          'photo',
          'Required parameter photo was null or undefined when calling uploadContainerPhoto.'
        )
      }
      const localVarPath = `/document/container`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (metadata !== undefined) {
        localVarFormParams.set('metadata', metadata as any)
      }

      if (photo !== undefined) {
        localVarFormParams.set('photo', photo as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Upload POD
     * @param {any} document Select POD to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPod(document: any, metadata: any, options: any = {}): FetchArgs {
      // verify required parameter 'document' is not null or undefined
      if (document === null || document === undefined) {
        throw new RequiredError('document', 'Required parameter document was null or undefined when calling uploadPod.')
      }
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new RequiredError('metadata', 'Required parameter metadata was null or undefined when calling uploadPod.')
      }
      const localVarPath = `/document/pod`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (document !== undefined) {
        localVarFormParams.set('document', document as any)
      }

      if (metadata !== undefined) {
        localVarFormParams.set('metadata', metadata as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Upload TIR
     * @param {any} document Select TIR to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTir(document: any, metadata: any, options: any = {}): FetchArgs {
      // verify required parameter 'document' is not null or undefined
      if (document === null || document === undefined) {
        throw new RequiredError('document', 'Required parameter document was null or undefined when calling uploadTir.')
      }
      // verify required parameter 'metadata' is not null or undefined
      if (metadata === null || metadata === undefined) {
        throw new RequiredError('metadata', 'Required parameter metadata was null or undefined when calling uploadTir.')
      }
      const localVarPath = `/document/tir`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (document !== undefined) {
        localVarFormParams.set('document', document as any)
      }

      if (metadata !== undefined) {
        localVarFormParams.set('metadata', metadata as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate POD
     * @param {any} metadata metadata
     * @param {any} signature Select signature to upload
     * @param {any} [businessCard] Select business card to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePod(
      metadata: any,
      signature: any,
      businessCard?: any,
      options?: any
    ): (basePath?: string) => Promise<DocumentDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).generatePod(
        metadata,
        signature,
        businessCard,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary generateWorkOrderImage
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {string} driverId driverId
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderImage(
      dispatchDeliveryOrderId: string,
      driverId: string,
      hash: string,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).generateWorkOrderImage(
        dispatchDeliveryOrderId,
        driverId,
        hash,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary generateWorkOrderUrl
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderUrl(dispatchDeliveryOrderId: string, options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).generateWorkOrderUrl(
        dispatchDeliveryOrderId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary generateWorkOrder
     * @param {string} activityId activityId
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrder(
      activityId: string,
      dispatchDeliveryOrderId: string,
      options?: any
    ): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).generateWorkOrder(
        activityId,
        dispatchDeliveryOrderId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Upload chassis photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadChassisPhoto(metadata: any, photo: any, options?: any): (basePath?: string) => Promise<DocumentDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).uploadChassisPhoto(
        metadata,
        photo,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Upload container photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadContainerPhoto(metadata: any, photo: any, options?: any): (basePath?: string) => Promise<DocumentDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).uploadContainerPhoto(
        metadata,
        photo,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Upload POD
     * @param {any} document Select POD to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPod(document: any, metadata: any, options?: any): (basePath?: string) => Promise<DocumentDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).uploadPod(
        document,
        metadata,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Upload TIR
     * @param {any} document Select TIR to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTir(document: any, metadata: any, options?: any): (basePath?: string) => Promise<DocumentDTO> {
      const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).uploadTir(
        document,
        metadata,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary Generate POD
     * @param {any} metadata metadata
     * @param {any} signature Select signature to upload
     * @param {any} [businessCard] Select business card to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePod(metadata: any, signature: any, businessCard?: any, options?: any) {
      return DocumentControllerApiFp(configuration).generatePod(metadata, signature, businessCard, options)(basePath)
    },
    /**
     *
     * @summary generateWorkOrderImage
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {string} driverId driverId
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderImage(dispatchDeliveryOrderId: string, driverId: string, hash: string, options?: any) {
      return DocumentControllerApiFp(configuration).generateWorkOrderImage(
        dispatchDeliveryOrderId,
        driverId,
        hash,
        options
      )(basePath)
    },
    /**
     *
     * @summary generateWorkOrderUrl
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrderUrl(dispatchDeliveryOrderId: string, options?: any) {
      return DocumentControllerApiFp(configuration).generateWorkOrderUrl(dispatchDeliveryOrderId, options)(basePath)
    },
    /**
     *
     * @summary generateWorkOrder
     * @param {string} activityId activityId
     * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateWorkOrder(activityId: string, dispatchDeliveryOrderId: string, options?: any) {
      return DocumentControllerApiFp(configuration).generateWorkOrder(activityId, dispatchDeliveryOrderId, options)(
        basePath
      )
    },
    /**
     *
     * @summary Upload chassis photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadChassisPhoto(metadata: any, photo: any, options?: any) {
      return DocumentControllerApiFp(configuration).uploadChassisPhoto(metadata, photo, options)(basePath)
    },
    /**
     *
     * @summary Upload container photo
     * @param {any} metadata metadata
     * @param {any} photo Select photo to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadContainerPhoto(metadata: any, photo: any, options?: any) {
      return DocumentControllerApiFp(configuration).uploadContainerPhoto(metadata, photo, options)(basePath)
    },
    /**
     *
     * @summary Upload POD
     * @param {any} document Select POD to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPod(document: any, metadata: any, options?: any) {
      return DocumentControllerApiFp(configuration).uploadPod(document, metadata, options)(basePath)
    },
    /**
     *
     * @summary Upload TIR
     * @param {any} document Select TIR to upload
     * @param {any} metadata metadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadTir(document: any, metadata: any, options?: any) {
      return DocumentControllerApiFp(configuration).uploadTir(document, metadata, options)(basePath)
    }
  }
}

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
  /**
   *
   * @summary Generate POD
   * @param {any} metadata metadata
   * @param {any} signature Select signature to upload
   * @param {any} [businessCard] Select business card to upload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public generatePod(metadata: any, signature: any, businessCard?: any, options?: any) {
    return DocumentControllerApiFp(this.configuration).generatePod(metadata, signature, businessCard, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary generateWorkOrderImage
   * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
   * @param {string} driverId driverId
   * @param {string} hash hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public generateWorkOrderImage(dispatchDeliveryOrderId: string, driverId: string, hash: string, options?: any) {
    return DocumentControllerApiFp(this.configuration).generateWorkOrderImage(
      dispatchDeliveryOrderId,
      driverId,
      hash,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary generateWorkOrderUrl
   * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public generateWorkOrderUrl(dispatchDeliveryOrderId: string, options?: any) {
    return DocumentControllerApiFp(this.configuration).generateWorkOrderUrl(dispatchDeliveryOrderId, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary generateWorkOrder
   * @param {string} activityId activityId
   * @param {string} dispatchDeliveryOrderId dispatchDeliveryOrderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public generateWorkOrder(activityId: string, dispatchDeliveryOrderId: string, options?: any) {
    return DocumentControllerApiFp(this.configuration).generateWorkOrder(activityId, dispatchDeliveryOrderId, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary Upload chassis photo
   * @param {any} metadata metadata
   * @param {any} photo Select photo to upload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public uploadChassisPhoto(metadata: any, photo: any, options?: any) {
    return DocumentControllerApiFp(this.configuration).uploadChassisPhoto(metadata, photo, options)(this.basePath)
  }

  /**
   *
   * @summary Upload container photo
   * @param {any} metadata metadata
   * @param {any} photo Select photo to upload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public uploadContainerPhoto(metadata: any, photo: any, options?: any) {
    return DocumentControllerApiFp(this.configuration).uploadContainerPhoto(metadata, photo, options)(this.basePath)
  }

  /**
   *
   * @summary Upload POD
   * @param {any} document Select POD to upload
   * @param {any} metadata metadata
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public uploadPod(document: any, metadata: any, options?: any) {
    return DocumentControllerApiFp(this.configuration).uploadPod(document, metadata, options)(this.basePath)
  }

  /**
   *
   * @summary Upload TIR
   * @param {any} document Select TIR to upload
   * @param {any} metadata metadata
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentControllerApi
   */
  public uploadTir(document: any, metadata: any, options?: any) {
    return DocumentControllerApiFp(this.configuration).uploadTir(document, metadata, options)(this.basePath)
  }
}

/**
 * DriverReportControllerApi - fetch parameter creator
 * @export
 */
export const DriverReportControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReport(
      driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO,
      userId: string,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'driverReportGenerationRequestDTO' is not null or undefined
      if (driverReportGenerationRequestDTO === null || driverReportGenerationRequestDTO === undefined) {
        throw new RequiredError(
          'driverReportGenerationRequestDTO',
          'Required parameter driverReportGenerationRequestDTO was null or undefined when calling generateReport.'
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling generateReport.'
        )
      }
      const localVarPath = `/driver-report`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverReportGenerationRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(driverReportGenerationRequestDTO || {})
        : driverReportGenerationRequestDTO || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getReportHistory
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportHistory(type: 'PSP' | 'MVR', userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling getReportHistory.')
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling getReportHistory.'
        )
      }
      const localVarPath = `/driver-report/history`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getReport
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(type: 'PSP' | 'MVR', userId: string, options: any = {}): FetchArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling getReport.')
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getReport.')
      }
      const localVarPath = `/driver-report`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary onMvrOrderCompleted
     * @param {string} authorization Authorization Header
     * @param {MvrOrderCompletedEventDTO} mvrOrderCompletedEventDTO mvrOrderCompletedEventDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onMvrOrderCompleted(
      authorization: string,
      mvrOrderCompletedEventDTO: MvrOrderCompletedEventDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'authorization' is not null or undefined
      if (authorization === null || authorization === undefined) {
        throw new RequiredError(
          'authorization',
          'Required parameter authorization was null or undefined when calling onMvrOrderCompleted.'
        )
      }
      // verify required parameter 'mvrOrderCompletedEventDTO' is not null or undefined
      if (mvrOrderCompletedEventDTO === null || mvrOrderCompletedEventDTO === undefined) {
        throw new RequiredError(
          'mvrOrderCompletedEventDTO',
          'Required parameter mvrOrderCompletedEventDTO was null or undefined when calling onMvrOrderCompleted.'
        )
      }
      const localVarPath = `/driver-report/on-mvr-order-completed`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'MvrOrderCompletedEventDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(mvrOrderCompletedEventDTO || {})
        : mvrOrderCompletedEventDTO || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary testIp
     * @param {string} testUrl test-url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testIp(testUrl: string, options: any = {}): FetchArgs {
      // verify required parameter 'testUrl' is not null or undefined
      if (testUrl === null || testUrl === undefined) {
        throw new RequiredError('testUrl', 'Required parameter testUrl was null or undefined when calling testIp.')
      }
      const localVarPath = `/driver-report/test-ip`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (testUrl !== undefined) {
        localVarQueryParameter['test-url'] = testUrl
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * DriverReportControllerApi - functional programming interface
 * @export
 */
export const DriverReportControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReport(
      driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO,
      userId: string,
      options?: any
    ): (basePath?: string) => Promise<DriverReportDTO> {
      const localVarFetchArgs = DriverReportControllerApiFetchParamCreator(configuration).generateReport(
        driverReportGenerationRequestDTO,
        userId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getReportHistory
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportHistory(
      type: 'PSP' | 'MVR',
      userId: string,
      options?: any
    ): (basePath?: string) => Promise<Array<DriverReportDTO>> {
      const localVarFetchArgs = DriverReportControllerApiFetchParamCreator(configuration).getReportHistory(
        type,
        userId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getReport
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(type: 'PSP' | 'MVR', userId: string, options?: any): (basePath?: string) => Promise<DriverReportDTO> {
      const localVarFetchArgs = DriverReportControllerApiFetchParamCreator(configuration).getReport(
        type,
        userId,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary onMvrOrderCompleted
     * @param {string} authorization Authorization Header
     * @param {MvrOrderCompletedEventDTO} mvrOrderCompletedEventDTO mvrOrderCompletedEventDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onMvrOrderCompleted(
      authorization: string,
      mvrOrderCompletedEventDTO: MvrOrderCompletedEventDTO,
      options?: any
    ): (basePath?: string) => Promise<Response> {
      const localVarFetchArgs = DriverReportControllerApiFetchParamCreator(configuration).onMvrOrderCompleted(
        authorization,
        mvrOrderCompletedEventDTO,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary testIp
     * @param {string} testUrl test-url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testIp(testUrl: string, options?: any): (basePath?: string) => Promise<DriverReportDTO> {
      const localVarFetchArgs = DriverReportControllerApiFetchParamCreator(configuration).testIp(testUrl, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * DriverReportControllerApi - factory interface
 * @export
 */
export const DriverReportControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary generateReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateReport(driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO, userId: string, options?: any) {
      return DriverReportControllerApiFp(configuration).generateReport(
        driverReportGenerationRequestDTO,
        userId,
        options
      )(basePath)
    },
    /**
     *
     * @summary getReportHistory
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReportHistory(type: 'PSP' | 'MVR', userId: string, options?: any) {
      return DriverReportControllerApiFp(configuration).getReportHistory(type, userId, options)(basePath)
    },
    /**
     *
     * @summary getReport
     * @param {'PSP' | 'MVR'} type type
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReport(type: 'PSP' | 'MVR', userId: string, options?: any) {
      return DriverReportControllerApiFp(configuration).getReport(type, userId, options)(basePath)
    },
    /**
     *
     * @summary onMvrOrderCompleted
     * @param {string} authorization Authorization Header
     * @param {MvrOrderCompletedEventDTO} mvrOrderCompletedEventDTO mvrOrderCompletedEventDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onMvrOrderCompleted(authorization: string, mvrOrderCompletedEventDTO: MvrOrderCompletedEventDTO, options?: any) {
      return DriverReportControllerApiFp(configuration).onMvrOrderCompleted(
        authorization,
        mvrOrderCompletedEventDTO,
        options
      )(basePath)
    },
    /**
     *
     * @summary testIp
     * @param {string} testUrl test-url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testIp(testUrl: string, options?: any) {
      return DriverReportControllerApiFp(configuration).testIp(testUrl, options)(basePath)
    }
  }
}

/**
 * DriverReportControllerApi - object-oriented interface
 * @export
 * @class DriverReportControllerApi
 * @extends {BaseAPI}
 */
export class DriverReportControllerApi extends BaseAPI {
  /**
   *
   * @summary generateReport
   * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
   * @param {string} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverReportControllerApi
   */
  public generateReport(
    driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO,
    userId: string,
    options?: any
  ) {
    return DriverReportControllerApiFp(this.configuration).generateReport(
      driverReportGenerationRequestDTO,
      userId,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary getReportHistory
   * @param {'PSP' | 'MVR'} type type
   * @param {string} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverReportControllerApi
   */
  public getReportHistory(type: 'PSP' | 'MVR', userId: string, options?: any) {
    return DriverReportControllerApiFp(this.configuration).getReportHistory(type, userId, options)(this.basePath)
  }

  /**
   *
   * @summary getReport
   * @param {'PSP' | 'MVR'} type type
   * @param {string} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverReportControllerApi
   */
  public getReport(type: 'PSP' | 'MVR', userId: string, options?: any) {
    return DriverReportControllerApiFp(this.configuration).getReport(type, userId, options)(this.basePath)
  }

  /**
   *
   * @summary onMvrOrderCompleted
   * @param {string} authorization Authorization Header
   * @param {MvrOrderCompletedEventDTO} mvrOrderCompletedEventDTO mvrOrderCompletedEventDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverReportControllerApi
   */
  public onMvrOrderCompleted(
    authorization: string,
    mvrOrderCompletedEventDTO: MvrOrderCompletedEventDTO,
    options?: any
  ) {
    return DriverReportControllerApiFp(this.configuration).onMvrOrderCompleted(
      authorization,
      mvrOrderCompletedEventDTO,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary testIp
   * @param {string} testUrl test-url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DriverReportControllerApi
   */
  public testIp(testUrl: string, options?: any) {
    return DriverReportControllerApiFp(this.configuration).testIp(testUrl, options)(this.basePath)
  }
}

/**
 * FileControllerApi - fetch parameter creator
 * @export
 */
export const FileControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary downloadFile
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFile(hash: string, options: any = {}): FetchArgs {
      // verify required parameter 'hash' is not null or undefined
      if (hash === null || hash === undefined) {
        throw new RequiredError('hash', 'Required parameter hash was null or undefined when calling downloadFile.')
      }
      const localVarPath = `/file/**`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (hash !== undefined) {
        localVarQueryParameter['hash'] = hash
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getFileHistory
     * @param {string} entityId entityId
     * @param {'VENDOR_LICENSE' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE' | 'MACHINERY_MAINTENANCE_INSPECTION' | 'MACHINERY_REPAIR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileHistory(
      entityId: string,
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE'
        | 'MACHINERY_MAINTENANCE_INSPECTION'
        | 'MACHINERY_REPAIR',
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'entityId' is not null or undefined
      if (entityId === null || entityId === undefined) {
        throw new RequiredError(
          'entityId',
          'Required parameter entityId was null or undefined when calling getFileHistory.'
        )
      }
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling getFileHistory.')
      }
      const localVarPath = `/file/history`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (entityId !== undefined) {
        localVarQueryParameter['entityId'] = entityId
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Merge files
     * @param {Array<string>} fileUrls fileUrls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeFiles(fileUrls: Array<string>, options: any = {}): FetchArgs {
      // verify required parameter 'fileUrls' is not null or undefined
      if (fileUrls === null || fileUrls === undefined) {
        throw new RequiredError(
          'fileUrls',
          'Required parameter fileUrls was null or undefined when calling mergeFiles.'
        )
      }
      const localVarPath = `/file/merge`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'string[]' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(fileUrls || {}) : fileUrls || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Upload a file
     * @param {any} file Select a file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options: any = {}): FetchArgs {
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError('file', 'Required parameter file was null or undefined when calling uploadFile.')
      }
      const localVarPath = `/file/upload`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new url.URLSearchParams()

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (file !== undefined) {
        localVarFormParams.set('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      localVarRequestOptions.body = localVarFormParams.toString()

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary downloadFile
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFile(hash: string, options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).downloadFile(hash, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getFileHistory
     * @param {string} entityId entityId
     * @param {'VENDOR_LICENSE' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE' | 'MACHINERY_MAINTENANCE_INSPECTION' | 'MACHINERY_REPAIR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileHistory(
      entityId: string,
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE'
        | 'MACHINERY_MAINTENANCE_INSPECTION'
        | 'MACHINERY_REPAIR',
      options?: any
    ): (basePath?: string) => Promise<Array<FileVersionDTO>> {
      const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).getFileHistory(
        entityId,
        type,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Merge files
     * @param {Array<string>} fileUrls fileUrls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeFiles(fileUrls: Array<string>, options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).mergeFiles(fileUrls, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary Upload a file
     * @param {any} file Select a file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any): (basePath?: string) => Promise<FileDTO> {
      const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).uploadFile(file, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary downloadFile
     * @param {string} hash hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFile(hash: string, options?: any) {
      return FileControllerApiFp(configuration).downloadFile(hash, options)(basePath)
    },
    /**
     *
     * @summary getFileHistory
     * @param {string} entityId entityId
     * @param {'VENDOR_LICENSE' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE' | 'MACHINERY_MAINTENANCE_INSPECTION' | 'MACHINERY_REPAIR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileHistory(
      entityId: string,
      type:
        | 'VENDOR_LICENSE'
        | 'VENDOR_MEDICAL_EXAM'
        | 'VENDOR_TWIC'
        | 'VENDOR_PORT'
        | 'VENDOR_W4'
        | 'VENDOR_W9'
        | 'VENDOR_I9'
        | 'VENDOR_APPLICATION'
        | 'VENDOR_LEASE'
        | 'VENDOR_PROOF_EIN_TAX_ID'
        | 'MACHINERY_REGISTRATION'
        | 'MACHINERY_DOT_SAFETY_INSPECTION'
        | 'MACHINERY_INSURANCE_COVERAGE'
        | 'MACHINERY_MAINTENANCE_INSPECTION'
        | 'MACHINERY_REPAIR',
      options?: any
    ) {
      return FileControllerApiFp(configuration).getFileHistory(entityId, type, options)(basePath)
    },
    /**
     *
     * @summary Merge files
     * @param {Array<string>} fileUrls fileUrls
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeFiles(fileUrls: Array<string>, options?: any) {
      return FileControllerApiFp(configuration).mergeFiles(fileUrls, options)(basePath)
    },
    /**
     *
     * @summary Upload a file
     * @param {any} file Select a file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(file: any, options?: any) {
      return FileControllerApiFp(configuration).uploadFile(file, options)(basePath)
    }
  }
}

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
  /**
   *
   * @summary downloadFile
   * @param {string} hash hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public downloadFile(hash: string, options?: any) {
    return FileControllerApiFp(this.configuration).downloadFile(hash, options)(this.basePath)
  }

  /**
   *
   * @summary getFileHistory
   * @param {string} entityId entityId
   * @param {'VENDOR_LICENSE' | 'VENDOR_MEDICAL_EXAM' | 'VENDOR_TWIC' | 'VENDOR_PORT' | 'VENDOR_W4' | 'VENDOR_W9' | 'VENDOR_I9' | 'VENDOR_APPLICATION' | 'VENDOR_LEASE' | 'VENDOR_PROOF_EIN_TAX_ID' | 'MACHINERY_REGISTRATION' | 'MACHINERY_DOT_SAFETY_INSPECTION' | 'MACHINERY_INSURANCE_COVERAGE' | 'MACHINERY_MAINTENANCE_INSPECTION' | 'MACHINERY_REPAIR'} type type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public getFileHistory(
    entityId: string,
    type:
      | 'VENDOR_LICENSE'
      | 'VENDOR_MEDICAL_EXAM'
      | 'VENDOR_TWIC'
      | 'VENDOR_PORT'
      | 'VENDOR_W4'
      | 'VENDOR_W9'
      | 'VENDOR_I9'
      | 'VENDOR_APPLICATION'
      | 'VENDOR_LEASE'
      | 'VENDOR_PROOF_EIN_TAX_ID'
      | 'MACHINERY_REGISTRATION'
      | 'MACHINERY_DOT_SAFETY_INSPECTION'
      | 'MACHINERY_INSURANCE_COVERAGE'
      | 'MACHINERY_MAINTENANCE_INSPECTION'
      | 'MACHINERY_REPAIR',
    options?: any
  ) {
    return FileControllerApiFp(this.configuration).getFileHistory(entityId, type, options)(this.basePath)
  }

  /**
   *
   * @summary Merge files
   * @param {Array<string>} fileUrls fileUrls
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public mergeFiles(fileUrls: Array<string>, options?: any) {
    return FileControllerApiFp(this.configuration).mergeFiles(fileUrls, options)(this.basePath)
  }

  /**
   *
   * @summary Upload a file
   * @param {any} file Select a file to upload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public uploadFile(file: any, options?: any) {
    return FileControllerApiFp(this.configuration).uploadFile(file, options)(this.basePath)
  }
}

/**
 * IndexControllerApi - fetch parameter creator
 * @export
 */
export const IndexControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options: any = {}): FetchArgs {
      const localVarPath = `/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * IndexControllerApi - functional programming interface
 * @export
 */
export const IndexControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): (basePath?: string) => Promise<string> {
      const localVarFetchArgs = IndexControllerApiFetchParamCreator(configuration).get(options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * IndexControllerApi - factory interface
 * @export
 */
export const IndexControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any) {
      return IndexControllerApiFp(configuration).get(options)(basePath)
    }
  }
}

/**
 * IndexControllerApi - object-oriented interface
 * @export
 * @class IndexControllerApi
 * @extends {BaseAPI}
 */
export class IndexControllerApi extends BaseAPI {
  /**
   *
   * @summary get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IndexControllerApi
   */
  public get(options?: any) {
    return IndexControllerApiFp(this.configuration).get(options)(this.basePath)
  }
}

/**
 * OfficialFormControllerApi - fetch parameter creator
 * @export
 */
export const OfficialFormControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createAndGetOfficialFormDocumentPreview
     * @param {PreviewOfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndGetOfficialFormDocumentPreview(request: PreviewOfficialFormRequestDTO, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createAndGetOfficialFormDocumentPreview.'
        )
      }
      const localVarPath = `/official-form/preview`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'PreviewOfficialFormRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary createOfficialFormDocument
     * @param {OfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOfficialFormDocument(request: OfficialFormRequestDTO, options: any = {}): FetchArgs {
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createOfficialFormDocument.'
        )
      }
      const localVarPath = `/official-form/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'OfficialFormRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(request || {}) : request || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getAllOfficialForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOfficialForms(lang?: 'EN' | 'ES', options: any = {}): FetchArgs {
      const localVarPath = `/official-form/`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getOfficialFormDocument
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOfficialFormDocument(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getOfficialFormDocument.'
        )
      }
      const localVarPath = `/official-form/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * OfficialFormControllerApi - functional programming interface
 * @export
 */
export const OfficialFormControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createAndGetOfficialFormDocumentPreview
     * @param {PreviewOfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndGetOfficialFormDocumentPreview(
      request: PreviewOfficialFormRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<OfficialFormResponseDTO> {
      const localVarFetchArgs = OfficialFormControllerApiFetchParamCreator(
        configuration
      ).createAndGetOfficialFormDocumentPreview(request, options)
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary createOfficialFormDocument
     * @param {OfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOfficialFormDocument(
      request: OfficialFormRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<OfficialFormResponseDTO> {
      const localVarFetchArgs = OfficialFormControllerApiFetchParamCreator(configuration).createOfficialFormDocument(
        request,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getAllOfficialForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOfficialForms(lang?: 'EN' | 'ES', options?: any): (basePath?: string) => Promise<Array<OfficialFormDTO>> {
      const localVarFetchArgs = OfficialFormControllerApiFetchParamCreator(configuration).getAllOfficialForms(
        lang,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getOfficialFormDocument
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOfficialFormDocument(id: string, options?: any): (basePath?: string) => Promise<OfficialFormResponseDTO> {
      const localVarFetchArgs = OfficialFormControllerApiFetchParamCreator(configuration).getOfficialFormDocument(
        id,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * OfficialFormControllerApi - factory interface
 * @export
 */
export const OfficialFormControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary createAndGetOfficialFormDocumentPreview
     * @param {PreviewOfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndGetOfficialFormDocumentPreview(request: PreviewOfficialFormRequestDTO, options?: any) {
      return OfficialFormControllerApiFp(configuration).createAndGetOfficialFormDocumentPreview(request, options)(
        basePath
      )
    },
    /**
     *
     * @summary createOfficialFormDocument
     * @param {OfficialFormRequestDTO} request request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOfficialFormDocument(request: OfficialFormRequestDTO, options?: any) {
      return OfficialFormControllerApiFp(configuration).createOfficialFormDocument(request, options)(basePath)
    },
    /**
     *
     * @summary getAllOfficialForms
     * @param {'EN' | 'ES'} [lang] lang
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOfficialForms(lang?: 'EN' | 'ES', options?: any) {
      return OfficialFormControllerApiFp(configuration).getAllOfficialForms(lang, options)(basePath)
    },
    /**
     *
     * @summary getOfficialFormDocument
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOfficialFormDocument(id: string, options?: any) {
      return OfficialFormControllerApiFp(configuration).getOfficialFormDocument(id, options)(basePath)
    }
  }
}

/**
 * OfficialFormControllerApi - object-oriented interface
 * @export
 * @class OfficialFormControllerApi
 * @extends {BaseAPI}
 */
export class OfficialFormControllerApi extends BaseAPI {
  /**
   *
   * @summary createAndGetOfficialFormDocumentPreview
   * @param {PreviewOfficialFormRequestDTO} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficialFormControllerApi
   */
  public createAndGetOfficialFormDocumentPreview(request: PreviewOfficialFormRequestDTO, options?: any) {
    return OfficialFormControllerApiFp(this.configuration).createAndGetOfficialFormDocumentPreview(request, options)(
      this.basePath
    )
  }

  /**
   *
   * @summary createOfficialFormDocument
   * @param {OfficialFormRequestDTO} request request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficialFormControllerApi
   */
  public createOfficialFormDocument(request: OfficialFormRequestDTO, options?: any) {
    return OfficialFormControllerApiFp(this.configuration).createOfficialFormDocument(request, options)(this.basePath)
  }

  /**
   *
   * @summary getAllOfficialForms
   * @param {'EN' | 'ES'} [lang] lang
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficialFormControllerApi
   */
  public getAllOfficialForms(lang?: 'EN' | 'ES', options?: any) {
    return OfficialFormControllerApiFp(this.configuration).getAllOfficialForms(lang, options)(this.basePath)
  }

  /**
   *
   * @summary getOfficialFormDocument
   * @param {string} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OfficialFormControllerApi
   */
  public getOfficialFormDocument(id: string, options?: any) {
    return OfficialFormControllerApiFp(this.configuration).getOfficialFormDocument(id, options)(this.basePath)
  }
}

/**
 * UserDriverReportControllerApi - fetch parameter creator
 * @export
 */
export const UserDriverReportControllerApiFetchParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateUserReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateUserReport(
      driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO,
      options: any = {}
    ): FetchArgs {
      // verify required parameter 'driverReportGenerationRequestDTO' is not null or undefined
      if (driverReportGenerationRequestDTO === null || driverReportGenerationRequestDTO === undefined) {
        throw new RequiredError(
          'driverReportGenerationRequestDTO',
          'Required parameter driverReportGenerationRequestDTO was null or undefined when calling generateUserReport.'
        )
      }
      const localVarPath = `/user/driver-report`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )
      const needsSerialization =
        <any>'DriverReportGenerationRequestDTO' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(driverReportGenerationRequestDTO || {})
        : driverReportGenerationRequestDTO || ''

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary getUserReport
     * @param {'PSP' | 'MVR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserReport(type: 'PSP' | 'MVR', options: any = {}): FetchArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling getUserReport.')
      }
      const localVarPath = `/user/driver-report`
      const localVarUrlObj = url.parse(localVarPath, true)
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options)
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function' ? configuration.apiKey('Authorization') : configuration.apiKey
        localVarHeaderParameter['Authorization'] = localVarApiKeyValue
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query)
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
        configuration.getDefaultHeaders()
      )

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * UserDriverReportControllerApi - functional programming interface
 * @export
 */
export const UserDriverReportControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary generateUserReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateUserReport(
      driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO,
      options?: any
    ): (basePath?: string) => Promise<DriverReportDTO> {
      const localVarFetchArgs = UserDriverReportControllerApiFetchParamCreator(configuration).generateUserReport(
        driverReportGenerationRequestDTO,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    },
    /**
     *
     * @summary getUserReport
     * @param {'PSP' | 'MVR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserReport(type: 'PSP' | 'MVR', options?: any): (basePath?: string) => Promise<DriverReportDTO> {
      const localVarFetchArgs = UserDriverReportControllerApiFetchParamCreator(configuration).getUserReport(
        type,
        options
      )
      return (basePath: string = configuration.basePath) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json()
          } else {
            throw response
          }
        })
      }
    }
  }
}

/**
 * UserDriverReportControllerApi - factory interface
 * @export
 */
export const UserDriverReportControllerApiFactory = function(configuration?: Configuration, basePath?: string) {
  return {
    /**
     *
     * @summary generateUserReport
     * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateUserReport(driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO, options?: any) {
      return UserDriverReportControllerApiFp(configuration).generateUserReport(
        driverReportGenerationRequestDTO,
        options
      )(basePath)
    },
    /**
     *
     * @summary getUserReport
     * @param {'PSP' | 'MVR'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserReport(type: 'PSP' | 'MVR', options?: any) {
      return UserDriverReportControllerApiFp(configuration).getUserReport(type, options)(basePath)
    }
  }
}

/**
 * UserDriverReportControllerApi - object-oriented interface
 * @export
 * @class UserDriverReportControllerApi
 * @extends {BaseAPI}
 */
export class UserDriverReportControllerApi extends BaseAPI {
  /**
   *
   * @summary generateUserReport
   * @param {DriverReportGenerationRequestDTO} driverReportGenerationRequestDTO driverReportGenerationRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserDriverReportControllerApi
   */
  public generateUserReport(driverReportGenerationRequestDTO: DriverReportGenerationRequestDTO, options?: any) {
    return UserDriverReportControllerApiFp(this.configuration).generateUserReport(
      driverReportGenerationRequestDTO,
      options
    )(this.basePath)
  }

  /**
   *
   * @summary getUserReport
   * @param {'PSP' | 'MVR'} type type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserDriverReportControllerApi
   */
  public getUserReport(type: 'PSP' | 'MVR', options?: any) {
    return UserDriverReportControllerApiFp(this.configuration).getUserReport(type, options)(this.basePath)
  }
}

export type DateISOString = string // 2018-04-15T08:36:01.711Z
