import * as React from 'react'
import { oc } from 'ts-optchain'
import { DriverDeductionsContext } from '../contexts/DriverDeductionsContext'
import { IGridItemActions } from '../contexts/GridItemContext'
import { IDriver } from '../components/common/drivers/interfaces'
import {
  DeductionDTO,
  DeductionStatus,
  DeductionType,
  PaymentMethod,
  VendorDTO
} from '../api/origin/vendor-accounting-service'
import { isNewId, isNewObject } from '../services/DTO'
import { getStore } from '../store/configureStore'
import { setTabViewingObjectTemporaryDataProps } from '../services/viewingObjects/actions'
import { IDriverAccountingTabData } from '../services/viewingObjects/interfaces'
import { requestDriverDeductions } from '../services/DTO/deduction/fetch'
import { requestVendorAccounting } from '../services/DTO/vendorAccounting/fetch'
import { generateDeduction } from '../services/functions/generate/generateDeduction'
import { isWeeklyDeduction } from '../services/functions/test/isWeeklyDeduction'
import { sortDeductions } from '../services/functions/sort/sortDeductions'

type Props = {
  driver: IDriver
  actions: IGridItemActions
  children?: any
}

export const DriverDeductionsProvider = React.memo((props: Props) => {
  const { driver, actions, children } = props
  const toggleSpinnerRef = React.useRef(actions.setFetching)
  const driverAccountingTabDataRef = React.useRef(oc(actions).temporaryData.driverAccountingTabData({}))
  toggleSpinnerRef.current = actions.setFetching
  driverAccountingTabDataRef.current = oc(actions).temporaryData.driverAccountingTabData({})
  const [refundEscrowDeduction, setRefundEscrowDeduction] = React.useState<DeductionDTO>(null)
  const [closeEscrowDeduction, setCloseEscrowDeduction] = React.useState<DeductionDTO>(null)
  const [payBackDeduction, setPayBackDeduction] = React.useState<DeductionDTO>(null)
  const [showAccountingPopup, setShowAccountingPopup] = React.useState<boolean>(false)
  const isModifiedMode = actions.isModified
  const vendorAccounting = driverAccountingTabDataRef.current.vendor
  const showClosedDeductions = oc(driverAccountingTabDataRef.current).showClosedDeductions(true)
  const deductions = driverAccountingTabDataRef.current.deductions || []
  const updatedDeductions = oc(driver).temporaryData.updatedDeductions([])
  const removedDeductionIds = oc(driver).temporaryData.removeDeductionIds([])
  const updatedVendorAccounting = oc(driver).temporaryData.updatedVendorAccounting()
  const currentDeductions = sortDeductions(
    deductions
      .map(item => {
        if (removedDeductionIds.includes(item.id)) {
          return undefined
        }

        const updatedItem = updatedDeductions.find(_item => _item.id === item.id)

        if (updatedItem) {
          return updatedItem
        }

        return item
      })
      .filter(Boolean)
      .concat(updatedDeductions.filter(item => isNewObject(item)))
      .filter(item => showClosedDeductions || item.status !== DeductionStatus.CLOSED)
  )

  const setSpinnerState = (state: boolean) => {
    toggleSpinnerRef.current(state)
  }

  const updateDriverAccountingTabData = (data: IDriverAccountingTabData) => {
    const { dispatch, getState } = getStore()
    const tabId = getState().tabs.activeMainTabId

    dispatch(
      setTabViewingObjectTemporaryDataProps({
        tabId,
        temporaryDataProps: {
          driverAccountingTabData: { ...driverAccountingTabDataRef.current, ...data }
        }
      })
    )
  }

  const requestDriverDeductionsWithSpinner = () => {
    setSpinnerState(true)

    requestDriverDeductions(driver.id)
      .then(requestedDeductions => updateDriverAccountingTabData({ deductions: requestedDeductions }))
      .catch(() => {})
      .finally(() => setSpinnerState(false))
  }

  const requestVendorAccountingData = async () => {
    return requestVendorAccounting(driver.id)
      .then(requestedVendor => {
        updateDriverAccountingTabData({ vendor: requestedVendor })

        return requestedVendor
      })
      .catch(() => undefined)
  }

  React.useEffect(() => {
    if (isNewObject(driver)) {
      return
    }

    let tries = 2
    let isExpiredData = false

    const requestAccountingTabData = () =>
      Promise.all([requestVendorAccounting(driver.id), requestDriverDeductions(driver.id)])
        .then(([requestedVendor, requestedDeductions]) => {
          if (!isExpiredData) {
            updateDriverAccountingTabData({ vendor: requestedVendor, deductions: requestedDeductions })

            if (!requestedDeductions.length && tries > 0) {
              tries--

              setTimeout(requestAccountingTabData, 1000)
            }
          }
        })
        .catch(() => {})

    requestAccountingTabData()

    return () => {
      isExpiredData = true
    }
  }, [])

  const toggleShowClosedDeductions = () => {
    updateDriverAccountingTabData({ showClosedDeductions: !showClosedDeductions })
  }

  const createDeduction = (options?: Partial<DeductionDTO>) => {
    actions.modify({
      ...driver,
      temporaryData: {
        ...(driver.temporaryData || {}),
        updatedDeductions: updatedDeductions.concat(generateDeduction({ ...(options || {}), vendorId: driver.id }))
      }
    })
  }

  const updateDeductionField = (_deduction: DeductionDTO) => (prop: keyof DeductionDTO) => (value: any) => {
    let updated = false
    const extraOptions = prop === 'type' && value === DeductionType.ESCROW ? { checkType: null, checkNumber: null } : {}

    const _updatedDeductions = updatedDeductions.map(item => {
      if (item.id === _deduction.id) {
        updated = true
        return {
          ..._deduction,
          [prop]: value,
          ...extraOptions
        }
      }

      return item
    })

    if (!updated) {
      _updatedDeductions.push({
        ..._deduction,
        [prop]: value,
        ...extraOptions
      })
    }

    actions.modify({
      ...driver,
      temporaryData: {
        ...(driver.temporaryData || {}),
        updatedDeductions: _updatedDeductions
      }
    })
  }

  const removeDeductionById = (deductionId: string) => {
    actions.modify({
      ...driver,
      temporaryData: {
        ...(driver.temporaryData || {}),
        updatedDeductions: updatedDeductions ? updatedDeductions.filter(item => item.id !== deductionId) : undefined,
        removeDeductionIds: isNewId(deductionId) ? removedDeductionIds : [...removedDeductionIds, deductionId]
      }
    })
  }

  const updateVendorAccountingField = (prop: keyof VendorDTO) => (value: any) => {
    if (vendorAccounting) {
      const _updatedVendorAccounting = { ...(updatedVendorAccounting || vendorAccounting), [prop]: value }

      if (prop === 'paymentMethod' && value === PaymentMethod.CHECK) {
        _updatedVendorAccounting.routingCode = undefined
        _updatedVendorAccounting.accountNumber = undefined
        _updatedVendorAccounting.voidedCheck = undefined
      }

      actions.modify({
        ...driver,
        temporaryData: {
          ...(driver.temporaryData || {}),
          updatedVendorAccounting: _updatedVendorAccounting
        }
      })
    }
  }

  return (
    <DriverDeductionsContext.Provider
      value={{
        driver,
        isModifiedMode,
        vendorAccounting: updatedVendorAccounting || vendorAccounting,
        deductions,
        currentDeductions,
        updatedDeductions,
        removedDeductionIds,
        weeklyDeductions: currentDeductions.filter(isWeeklyDeduction),
        notWeeklyDeductions: currentDeductions.filter(deduction => !isWeeklyDeduction(deduction)),
        showClosedDeductions,
        toggleShowClosedDeductions,
        setSpinnerState,
        requestDriverDeductionsWithSpinner,
        requestVendorAccounting: requestVendorAccountingData,
        createDeduction,
        updateDeductionField,
        removeDeductionById,
        refundEscrowDeduction,
        setRefundEscrowDeduction,
        closeEscrowDeduction,
        setCloseEscrowDeduction,
        payBackDeduction,
        setPayBackDeduction,
        updateVendorAccountingField,
        showAccountingPopup,
        setShowAccountingPopup
      }}
      children={children}
    />
  )
})
