import * as React from 'react'
import { DeliveryOrderDTO } from '../../../../../api/origin/business-logic'
import { TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../grid/views/interfaces'
import { createNewTab } from '../../../tabs/actions'
import { getStore } from '../../../../../store/configureStore'
import { TabType } from '../../../tabs/interfaces'
import styled from '../../../../../styles/styledComponents'
import { setTabViewingObject } from '../../../../../services/viewingObjects/actions'

export const DeliveryOrderNumberColumn = (props: TGridColumn) => {
  const { value, className, style } = props
  const { completionMonitor, id } = value as DeliveryOrderDTO
  const process = completionMonitor ? completionMonitor.replace('/', ' of ') + ' processed' : ''

  return (
    <TableCell className={className} data-copy-value-on-mousedown={true} data-copy-value={value.number} style={style}>
      <div>
        <DONumber
          onClick={event => {
            event.stopPropagation()
            getStore().dispatch(
              createNewTab({
                tabType: TabType.deliveryOrder,
                options: { title: 'DO #' + value.number }
              })
            )
            getStore().dispatch(
              setTabViewingObject({ tabId: getStore().getState().tabs.activeMainTabId, viewingObject: { id } })
            )
          }}
        >
          {value.number}
        </DONumber>
        <CompletionMonitor>{process}</CompletionMonitor>
      </div>
    </TableCell>
  )
}

const DONumber = styled.span`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #4555d0;
  cursor: pointer;
`
const CompletionMonitor = styled.p`
  display: block;
  font-size: 10px;
  line-height: 11px;
  color: #445366;
  font-weight: 300;
  margin-top: 2px;
`
