import * as React from 'react'
import { TableRowColumns } from '../../../common/grid/views/styles'
import { columnSettings, adaptRowValues, columnsValues } from '../data'
import { TabActionType, TabType, TGridColumns } from '../../../common/tabs/interfaces'
import { SelectColumn } from './SelectColumn'
import cn from 'classnames'
import { IDispatchDeliveryOrder } from '../../../common/dispatchDeliveryOrder/interfaces'

export type TGridItemColumns = {
  columns: TGridColumns
  isExpanded?: boolean
  unit: any
  unitType: TabType
  togglePreview: (event: React.MouseEvent) => void
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
}

const Columns = (props: TGridItemColumns) => {
  const { unit, togglePreview, unitType, isExpanded, actionType, isDisabledSelect, isSelected } = props
  const Column = columnsValues[unitType]
  const headings = Object.keys(columnSettings[unitType])
  const columns = adaptRowValues[unitType](unit)

  const highlightRed =
    unitType === TabType.dispatchDeliveryOrder && unit && (unit as IDispatchDeliveryOrder).troubleTicketId

  return (
    <>
      <TableRowColumns
        className={cn('table__row-columns', {
          'table__row-columns_expanded': isExpanded,
          'highlight-red': highlightRed
        })}
        // @ts-ignore
        onClick={togglePreview}
      >
        <SelectColumn
          unitId={unit.id}
          isSelected={isSelected}
          isDisabledSelect={isDisabledSelect}
          actionType={actionType}
        />
        {headings.map((title: string) => {
          const columnProps = props.columns[title]

          if (!columnProps.active) {
            return null
          }

          const columnData = { ...columns[title], ...columnProps }

          return (
            <Column
              {...columnData}
              key={title}
              className={'table__row-column'}
              columnName={title}
              style={{
                width: columnProps.width,
                minWidth: columnProps.minWidth,
                maxWidth: columnProps.maxWidth
              }}
            />
          )
        })}
      </TableRowColumns>
    </>
  )
}

export default Columns
