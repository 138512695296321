import { ITabState, TabType } from '../../components/common/tabs/interfaces'
import { compareValues, getObjectFieldValueByPath } from './actions'
import { updateTabData } from '../../components/common/tabs/actions'
import { oc } from 'ts-optchain'
import { QMPTab, IQMPTabData } from '../../components/common/QMP/interfaces'
import { getStore } from '../../store/configureStore'
import * as R from 'remeda'
import { IFilterItem } from '../../components/common/grid/views/header/column'

export enum QMPObjectType {
  ssqRates = 'ssqRates',
  bsqRates = 'bsqRates',
  customerQuotes = 'customerQuotes'
}

const mapIdKey = {
  ssqRates: 'ssqIds',
  bsqRates: 'bsqIds',
  customerQuotes: 'customerQuoteIds'
}

const mapSettings = {
  ssqRates: QMPTab.RATE,
  bsqRates: QMPTab.RATE,
  customerQuotes: QMPTab.CUSTOMERQUOTE
}

const getIds = (objectType: QMPObjectType, qmpTabData: IQMPTabData): string[] => {
  switch (objectType) {
    case QMPObjectType.ssqRates:
      return qmpTabData.ssqIds
    case QMPObjectType.bsqRates:
      return qmpTabData.bsqIds
    case QMPObjectType.customerQuotes:
      return qmpTabData.customerQuoteIds
    default:
      return []
  }
}

export const QMPUpdateStoreObject = (objectType: QMPObjectType, objects: any[], actionToUpdate: any) => {
  const { getState, dispatch } = getStore()
  const store = getState()
  const storeList = store[objectType]
  const existedObjects = objects.filter(({ id }) => storeList[id])
  if (existedObjects.length) {
    // remove unsuitable Objects from grid
    const tabs: ITabState[] = store.tabs.main.filter(({ visited, type }) => visited && type === TabType.QMP)

    if (tabs.length) {
      tabs.forEach(tab => {
        const dataType = mapSettings[objectType]
        const tabData = tab.data as IQMPTabData
        let filters: any = tabData.filters[dataType]

        filters = Object.keys(filters)
          .filter(key => filters[key] !== null && filters[key] !== undefined)
          .map(key => createFilerTypeObject(key, filters[key]))
        const { unsuitableIds } = filterItemsByQMPTabFilter(objectType, objects, filters)

        if (unsuitableIds.length) {
          const idList = getIds(objectType, tabData) || []
          const resultIds = idList.filter((id: string) => !unsuitableIds.includes(id))

          if (resultIds.length !== idList.length) {
            dispatch(
              updateTabData({
                tabId: tab.id,
                options: {
                  [mapIdKey[objectType]]: resultIds
                }
              })
            )
          }
        }
      })
    }

    // update objects in store
    actionToUpdate(existedObjects)
  }
}

export const QMPAddObjectsToStoreAndTabs = (objectType: QMPObjectType, objects: any[], actionToAdd: any) => {
  const { getState, dispatch } = getStore()
  const store = getState()
  actionToAdd(objects)
  const tabs: ITabState[] = store.tabs.main.filter(({ visited, type }) => visited && type === TabType.QMP)

  if (tabs.length) {
    tabs.forEach(tab => {
      const dataType = mapSettings[objectType]
      const tabData = tab.data as IQMPTabData
      let filters: any = tabData.filters[dataType]

      filters = Object.keys(filters)
        .filter(key => filters[key] !== null && filters[key] !== undefined)
        .map(key => createFilerTypeObject(key, filters[key]))
      const { suitableIds } = filterItemsByQMPTabFilter(objectType, objects, filters)

      if (suitableIds.length) {
        const idList = getIds(objectType, tabData) || []
        const resultIds = R.uniq(suitableIds.concat(idList))

        if (resultIds.length !== idList.length) {
          dispatch(
            updateTabData({
              tabId: tab.id,
              options: {
                [mapIdKey[objectType]]: resultIds
              }
            })
          )
        }
      }
    })
  }
}

const createFilerTypeObject = (column: string, value: any) => {
  switch (column) {
    case 'deliveryLocation':
      return { column, path: 'deliveryStateId', value: oc(value).stateId() }
    case 'customerId':
    case 'vendorId':
      return {
        column,
        path: column,
        value: value ? (Array.isArray(value) ? value.map(item => item.value) : value) : undefined
      }
    case 'containerTypeId':
      return {
        column,
        path: column,
        value: value && Array.isArray(value) ? value : undefined
      }
    default:
      return { column, path: column, value }
  }
}

const filterItemsByQMPTabFilter = (
  type: QMPObjectType,
  objects: any[],
  filters: IFilterItem[]
): { suitableIds: string[]; unsuitableIds: string[] } => {
  const suitableIds: string[] = []
  const unsuitableIds: string[] = []

  if (filters && filters.length) {
    objects.forEach(object => {
      const isSuitable = leaveOnlyUsefulRateTypeFilters({
        object,
        objectType: type,
        filters
      }).every(({ column, value, path, combinationOfValuePaths, isEqualToValue }) => {
        if ((column === 'vendorId' || column === 'customerId') && value === '%%') {
          return object[path] === null || object[path] === undefined
        }

        // use column value = NULL to omit the field
        if (value === null || value === undefined) {
          return true
        }

        if (combinationOfValuePaths && combinationOfValuePaths.length) {
          const objectFieldsValues = combinationOfValuePaths.map(_path => getObjectFieldValueByPath(object, _path))
          return objectFieldsValues.some(objectFieldValue => compareValues(value, objectFieldValue, isEqualToValue))
        } else {
          const objectFieldValue = getObjectFieldValueByPath(object, path || column)
          // console.log({
          //   objectValue,
          //   value
          // })
          return compareValues(value, objectFieldValue, isEqualToValue)
        }
      })

      if (isSuitable) {
        suitableIds.push(object.id)
      } else {
        unsuitableIds.push(object.id)
      }
    })

    // console.log({
    //   objects,
    //   filters,
    //   suitableIds
    // })
  } else {
    suitableIds.push(...objects.map(({ id }) => id))
  }

  return { suitableIds, unsuitableIds }
}

const leaveOnlyUsefulRateTypeFilters = (props: {
  object: any // SellSideQuoteRateDTO | BuySideQuoteRateDTO | CustomerQuoteDTO,
  objectType: QMPObjectType
  filters: IFilterItem[]
}): IFilterItem[] => {
  const { object, objectType, filters } = props
  const appliedFilters = oc(filters)([])

  const usefulFilterProps: string[] = []

  const addFilterProp = (properties: string[]) => {
    properties.forEach(prop => {
      const value = object[prop]

      if (value !== undefined && value !== null) {
        usefulFilterProps.push(prop)
      }
    })
  }

  // >>> SSQ/BSQ
  if (objectType === QMPObjectType.ssqRates || objectType === QMPObjectType.bsqRates) {
    addFilterProp([
      'number',
      'type',
      'status',
      'customerId',
      'vendorId',
      'pickupLocationId',
      'returnLocationId',
      'containerTypeId',
      'deliveryCity',
      'deliveryPostalCode',
      'deliveryStateId',
      'loadType',
      'deliveryOrderType'
    ])

    if (oc(object).ruleIds([]).length) {
      usefulFilterProps.push('ruleIds')
    }
  }
  // <<<

  // >>> CUSTOMER QUOTE
  if (objectType === QMPObjectType.customerQuotes) {
    addFilterProp([
      'number',
      'deliveryOrderType',
      'status',
      'customerId',
      'pickupLocationId',
      'deliveryCity',
      'deliveryPostalCode',
      'deliveryStateId',
      'returnLocationId',
      'containerTypeId',
      'loadType'
    ])
  }
  // <<<

  return appliedFilters.filter(({ column }) => usefulFilterProps.includes(column))
}
