import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ILocationState, ILocation } from '../interfaces'
import { AddressDTO, LocationViewDTO } from '../../../../api/origin/business-logic'
import { createNewUnitId } from '../../../../services/utils'
import { addLocationsToStore } from '../action'

export const defaultAddress = (): AddressDTO => ({
  city: null,
  postalCode: null,
  stateId: null,
  street: null,
  street2: null
})

export const newLocation = (): ILocation => ({
  fullObject: true,
  address: defaultAddress(),
  altitude: null,
  businessPartnerId: null,
  careOf: null,
  contactIds: [],
  contacts: [],
  description: null,
  id: createNewUnitId(),
  status: LocationViewDTO.StatusEnum.FULL,
  latitude: null,
  longitude: null,
  name: null,
  notes: null,
  placeId: null,
  primary: false,
  primaryContactId: null,
  rating: null,
  timezone: null,
  type: LocationViewDTO.TypeEnum.WAREHOUSEWITHDOCK,
  unlocode: null,
  webSite: null,
  workingHours: null,
  active: true
})

const locationState: ILocationState = {}

const locationReducer = reducerWithInitialState<ILocationState>(locationState)
  .case(addLocationsToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default locationReducer
