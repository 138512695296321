import * as React from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { activityDirectory } from '../../../../services/DTO/activity/directory'
import { TransportationActivityViewDTO } from '../../../../api/origin/business-logic'
import { activityStatusIconMapping } from './statusIconMapping'
import { IStore } from '../../../../store/store.interface'
import theme from '../../../../styles/theme'
import { Time, TimeLabel } from './RouteBuilderMove'
import { dateService } from '../../../../services/timeService'
import { setMoveItemStatus } from '../../epics/routeBuilder'
import { useRouteBuilder } from '../../../../hooks/useRouteBuilder'
import { ILocation } from '../../../common/location/interfaces'
import {
  IMoveItem,
  MoveConnectionType,
  RouteBuilderGridView
} from '../../../../store/reducers/communicationHub/interfaces'
import {
  isBobtailGotoActivity,
  isDropBobtailGotoActivity
} from '../../../../services/functions/test/isBobtailGotoActivity'
import { openDDOinNewTab } from '../../../../services/DTO/dispatchDeliveryOrder'
// tslint:disable:max-line-length
import { getBobtailRefDispatchDeliveryOrderInfo } from '../../../../services/functions/get/getBobtailRefDispatchDeliveryOrderInfo'
import { transformMoney } from '../../../UI/Input'
import { isLocationGEOValid } from '../../../../services/functions/test/isLocationGEOValid'
import { showWarningModalWindow } from '../../../../store/reducers/modalWindow/functions'

type OwnProps = {
  disable: boolean
  isFirstActualMoveItem: boolean
  dateIsNotConfirmed: boolean
  view: RouteBuilderGridView
  moveItem: IMoveItem
  activeTabDate: string
  prevConnectionType: MoveConnectionType
  nextConnectionType: MoveConnectionType
}

type StateProps = {
  gotoActivityBSQAmount?: number
  businessActivityBSQAmount?: number
  destination?: ILocation
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const {
    view,
    disable,
    isFirstActualMoveItem,
    prevConnectionType,
    moveItem,
    dateIsNotConfirmed,
    gotoActivityBSQAmount,
    businessActivityBSQAmount,
    activeTabDate
  } = props
  const { gotoActivity, businessActivity } = moveItem
  const { setFetch, vendorId } = useRouteBuilder()
  const isBobtail = isBobtailGotoActivity(gotoActivity)
  const isDropBobtail = isDropBobtailGotoActivity(gotoActivity)
  const troubleTicketId = oc(gotoActivity).troubleTicketId() || oc(businessActivity).troubleTicketId()
  const isOffDuty = businessActivity.type === TransportationActivityViewDTO.TypeEnum.OFFDUTY
  const isActive =
    (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS && !gotoActivity.bobtailFrom) ||
    businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
    (gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED &&
      [
        TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
        TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
      ].includes(businessActivity.status))
  const activityStageColor = activityDirectory.stageColor[businessActivity.stage]
  const activityPlannedTime = React.useMemo(() => {
    if (
      [
        TransportationActivityViewDTO.StatusEnum.COMPLETED,
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
      ].includes(businessActivity.status)
    ) {
      return
    }

    const date = oc(businessActivity).startPlannedDateTimeRange.from()
      ? businessActivity.startPlannedDateTimeRange
      : oc(gotoActivity).startPlannedDateTimeRange.from()
      ? gotoActivity.startPlannedDateTimeRange
      : undefined

    if (date) {
      const showDay = !dateService.isTheSameDay(date.from, activeTabDate)
      const showYear = showDay && !dateService.isTheSameYear(date.from, activeTabDate)

      return {
        day: showDay && dateService.makeLabel(date.from, { hideTime: true, hideYear: !showYear }),
        from: dateService.makeLabel(date.from, { onlyTime: true }),
        to: dateService.makeLabel(date.to, { onlyTime: true })
      }
    }
  }, [gotoActivity, businessActivity])
  let bsqAmount: number = undefined
  if (typeof gotoActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + gotoActivityBSQAmount
  }
  if (typeof businessActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + businessActivityBSQAmount
  }
  const {
    isNew,
    isPlanned,
    isCompleted,
    isUnsuccessful,
    specificBackgroundColor,
    specificBorderColor,
    specificTextColor,
    specificActivityTypeTextColor,
    specificTimeColor
  } = React.useMemo(() => {
    const _isNew = businessActivity.status === TransportationActivityViewDTO.StatusEnum.NEW
    const _isPlanned = businessActivity.status === TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
    const _isCompleted = businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    const _isUnsuccessful = businessActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL

    return {
      isNew: _isNew,
      isPlanned: _isPlanned,
      isCompleted: _isCompleted,
      isUnsuccessful: _isUnsuccessful,
      specificTextColor: _isNew || _isCompleted || _isUnsuccessful ? theme.colors.gray : undefined,
      specificActivityTypeTextColor: _isCompleted || _isUnsuccessful ? theme.colors.gray : undefined,
      specificBorderColor: _isNew || _isPlanned ? '#EBCD33' : _isCompleted ? theme.colors.gray : undefined,
      specificBackgroundColor: _isCompleted || _isUnsuccessful || _isNew || isBobtail ? '#f5f7f7' : undefined,
      specificTimeColor: _isCompleted || _isUnsuccessful ? theme.colors.gray : undefined
    }
  }, [businessActivity.status])

  const { address, code, isInvalidLocation } = React.useMemo(() => {
    const location = props.destination || businessActivity.destination

    if (!location) {
      return {
        address: undefined,
        code: undefined
      }
    }

    const city_StateZip = (location.cityStateZip && location.cityStateZip.split(',')) || []
    const stateZip = city_StateZip[1]
    const city = city_StateZip[0]
    const shortName = location.shortName

    return {
      isInvalidLocation: isLocationGEOValid(location) === false,
      address: [shortName, city].filter(Boolean).join(', '),
      code: stateZip
    }
  }, [businessActivity.destination, props.destination])

  const onIconButtonClick =
    !disable && !isDropBobtail && (isPlanned || isNew)
      ? async () => {
          setFetch(true)
          if (isNew) {
            if (isInvalidLocation) {
              showWarningModalWindow({ title: 'Location is Invalid' })
            } else {
              await setMoveItemStatus({
                moveItem,
                vendorId,
                newStatus: TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
              })
            }
          } else if (isPlanned) {
            await setMoveItemStatus({
              moveItem,
              vendorId: undefined,
              newStatus: TransportationActivityViewDTO.StatusEnum.NEW
            })
          }
          setFetch(false)
        }
      : undefined

  const RenderIcon = () => {
    return (
      <ActivityIcon
        className={cn({
          button: Boolean(onIconButtonClick),
          hide:
            isPlanned &&
            (isDropBobtail || (isFirstActualMoveItem && prevConnectionType === MoveConnectionType.BOBTAIL)),
          'troubleTicket mdi mdi-alert-decagram': troubleTicketId
        })}
        onClick={onIconButtonClick}
      >
        {troubleTicketId || (isDropBobtail && isPlanned)
          ? undefined
          : activityStatusIconMapping[businessActivity.status]}
      </ActivityIcon>
    )
  }

  const RenderAddress = () => {
    const content =
      address || code ? (
        <>
          {address && (
            <ActivityAddress style={isInvalidLocation ? { color: theme.colors.defaultRed } : undefined}>
              {address}
            </ActivityAddress>
          )}
          {code && (
            <ActivityPostalCode style={isInvalidLocation ? { color: theme.colors.defaultRed } : undefined}>
              {code}
            </ActivityPostalCode>
          )}
        </>
      ) : (
        <ActivityAddressPlaceholder />
      )

    return isNew || isBobtail ? (
      <div style={{ overflow: 'hidden' }}>
        <ActivityAddress
          style={isBobtail ? { color: theme.colors.defaultRed } : undefined}
          children={isBobtail ? 'Bobtail' : activityDirectory.status[TransportationActivityViewDTO.StatusEnum.NEW]}
        />
        <ActivityGreyColorAddress>{content}</ActivityGreyColorAddress>
      </div>
    ) : (
      content
    )
  }

  const RenderActivityType = () => {
    if (isDropBobtail && !isOffDuty) {
      const ddoLinkData = getBobtailRefDispatchDeliveryOrderInfo(gotoActivity)

      if (ddoLinkData) {
        return (
          <BobtailDDOLink
            onClick={() =>
              openDDOinNewTab({
                ddoId: ddoLinkData.dispatchDeliveryOrderId,
                ddoNumber: ddoLinkData.dispatchDeliveryOrderNumber
              })
            }
          >
            DDO# {ddoLinkData.dispatchDeliveryOrderNumber}
          </BobtailDDOLink>
        )
      }

      return null
    }

    return (
      <ActivityType style={{ color: specificActivityTypeTextColor || activityStageColor }}>
        {activityDirectory.type[businessActivity.type]}
      </ActivityType>
    )
  }

  if (view === RouteBuilderGridView.grid) {
    return (
      <Activity
        style={{ borderColor: specificBorderColor || activityStageColor, backgroundColor: specificBackgroundColor }}
      >
        {activityPlannedTime && (
          <Time
            className={'fixed'}
            style={{ color: dateIsNotConfirmed ? theme.colors.defaultRed : specificTimeColor || activityStageColor }}
          >
            {activityPlannedTime.day && (
              <div style={{ fontWeight: 500, marginBottom: -3 }}>{activityPlannedTime.day}</div>
            )}
            {activityPlannedTime.from}
            {activityPlannedTime.to && (
              <>
                -<div style={{ marginTop: -3 }}>{activityPlannedTime.to}</div>
              </>
            )}
            <TimeLabel style={activityPlannedTime.to ? { position: 'absolute' } : undefined}>Planned time</TimeLabel>
          </Time>
        )}
        {isActive && (
          <EnRouteArrow>
            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="31" />
              <path
                d="M6 15.6471H24M24 15.6471L17.7 8M24 15.6471L16.8 23"
                stroke={specificTimeColor || activityStageColor}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </EnRouteArrow>
        )}
        <ActivityLabel style={{ color: specificTimeColor || activityStageColor }}>
          {gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS && !gotoActivity.bobtailFrom && (
            <>
              En Route
              <br />
              &#8195;
            </>
          )}
          {gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED &&
            [
              TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
              TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
            ].includes(businessActivity.status) && (
              <>
                Arrived
                <br />
                &#8195;
              </>
            )}
          {businessActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS && (
            <>
              In-
              <br />
              Process
            </>
          )}
        </ActivityLabel>
        <RenderIcon />
        <RenderAddress />
        <RenderActivityType />
        {typeof bsqAmount === 'number' && <QuoteAmount>{transformMoney(bsqAmount)}</QuoteAmount>}
      </Activity>
    )
  }
  // else if (view === RouteBuilderGridView.list) {
  //   return (
  //     <ListViewActivityColumn>
  //       <ListViewAddress>
  //         {isNew ? (
  //           <ActivityAddress style={{ color: theme.colors.gray, whiteSpace: 'unset' }}>Not Planned</ActivityAddress>
  //         ) : (
  //           <>
  //             {address && <ActivityAddress>{address}</ActivityAddress>}
  //             {code && <ActivityPostalCode style={{ fontSize: 12, padding: 0 }}>{code}</ActivityPostalCode>}
  //           </>
  //         )}
  //       </ListViewAddress>
  //       <ActivityType
  //         style={{ color: specificTextColor || activityStageColor, margin: 0, padding: 0, textAlign: 'unset' }}
  //       >
  //         {activityDirectory.type[activity.type]}
  //       </ActivityType>
  //     </ListViewActivityColumn>
  //   )
  // }

  return null
}

export const RouteBuilderMoveActivity = connect(
  (
    { buySideQuotes, location }: IStore,
    { moveItem: { businessActivity, businessActivityId, gotoActivityId } }: OwnProps
  ) => {
    return {
      destination: businessActivity && businessActivity.destinationId && location[businessActivity.destinationId],
      gotoActivityBSQAmount: buySideQuotes[gotoActivityId] && buySideQuotes[gotoActivityId].amount,
      businessActivityBSQAmount: buySideQuotes[businessActivityId] && buySideQuotes[businessActivityId].amount
    }
  }
)(React.memo(Component))

// GRID VIEW
const Activity = styled.div`
  width: 355px;
  height: 42px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  color: #333333;
  padding-right: 12px;
`
const ActivityIcon = styled.div`
  flex-shrink: 0;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  background-color: #f5f7f7;
  margin-right: 8px;

  &.button {
    border-right: 1px solid #d9d9d9;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: unset;
    }
  }

  &.troubleTicket {
    background-color: #ffeaea;
    color: ${theme.colors.defaultRed};
    font-size: 18px;
  }

  &.hide {
    width: 8px;
    visibility: hidden;
  }
`
const ActivityAddressPlaceholder = styled.div`
  color: #06182f;

  &:before {
    content: '―――';
    letter-spacing: -4px;
  }
`
const ActivityAddress = styled.div`
  color: #333333;
  font-size: 14px;
  line-height: 1.2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ActivityPostalCode = styled.div`
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 300;
  padding-left: 5px;
`
const ActivityGreyColorAddress = styled.div`
  display: flex;

  ${ActivityAddress}, ${ActivityPostalCode}, ${ActivityAddressPlaceholder} {
    font-size: 12px;
    color: #9ba8b8;
  }
`
const ActivityType = styled.div`
  min-width: 95px;
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
  margin-left: auto;
  user-select: none;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    font-weight: 500;
  }
`
const BobtailDDOLink = styled(ActivityType)`
  color: ${theme.colors.basicBlueColor};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const QuoteAmount = styled.div`
  position: absolute;
  right: 40px;
  color: #697585;
  font-weight: 500;
  font-size: 12px;
`
const EnRouteArrow = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
`
const ActivityLabel = styled.div`
  width: 50px;
  position: absolute;
  left: 113px;
  font-weight: 700;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  user-select: none;

  &:before {
    font-size: 14px;
    font-weight: 700;
    transform: translate(-5px, 2px);
  }
`

// LIST VIEW
const ListViewActivityColumn = styled.div`
  flex-shrink: 1;
  width: 33.3%;
  border-right: 1px solid #e3e8ea;
  padding: 0 10px 0 14px;
  overflow: hidden;
`
const ListViewAddress = styled.div`
  height: 28px;
  margin-bottom: 5px;
`
