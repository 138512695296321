import * as React from 'react'
import { IEquipment } from '../../interfaces'
import { EquipmentDTO } from '../../../../../api/origin/business-logic'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { AvailabilityColumnSelecter } from '../../../../UI/oldAvailabilitySelecter'
import { useGridItem } from '../../../../../hooks/useGridItem'

const dropDownItems = {
  Active: ['Available', 'Not Available (Assigned)', 'Not Available (In-Use)'],
  Inactive: [
    'Expired Registration',
    'Expired Safety Inspection',
    'Expired Maintenance Inspection',
    'Maintenance',
    'Terminated',
    'Other Inactive Reason'
  ]
}

type OwnProps = {
  unit: IEquipment
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const AvailabilityColumn = (props: Props) => {
  const { actions } = useGridItem() || {}
  const { unit } = props
  let selectedItem: string

  const selectedValue = (powerUnit: any) => {
    if (powerUnit.active) {
      powerUnit.availability
        ? (selectedItem = 'Available')
        : powerUnit.unavailabilityReason === EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
        ? (selectedItem = 'Not Available (Assigned)')
        : (selectedItem = 'Not Available (In-Use)')
    } else {
      switch (powerUnit.inactiveReason) {
        case EquipmentDTO.InactiveReasonEnum.EXPIREDREGISTRATION:
          selectedItem = 'Expired Registration'
          break
        case EquipmentDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION:
          selectedItem = 'Expired Safety Inspection'
          break
        case EquipmentDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION:
          selectedItem = 'Expired Maintenance Inspection'
          break
        case EquipmentDTO.InactiveReasonEnum.MAINTENANCE:
          selectedItem = 'Maintenance'
          break
        case EquipmentDTO.InactiveReasonEnum.TERMINATED:
          selectedItem = 'Terminated'
          break
        case EquipmentDTO.InactiveReasonEnum.OTHER:
          selectedItem = 'Other Inactive Reason'
          break
        default:
          break
      }
    }

    return selectedItem
  }
  const currentSelectedItem: string = selectedValue(unit)

  return (
    <AvailabilityColumnSelecter
      actions={actions}
      handleSelect={handleSelect(unit, actions, currentSelectedItem)}
      activeList={dropDownItems.Active}
      inactiveList={dropDownItems.Inactive}
      selectedItem={currentSelectedItem}
      stateActive={unit.active}
    />
  )
}

const handleSelect = (unit: any, actions: IGridItemActions, selectedItem: any) => (item: string) => {
  if (item !== selectedItem) {
    let updateUnit: IEquipment

    switch (item) {
      case 'Available':
        updateUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: true,
          unavailabilityReason: null
        }
        break
      case 'Not Available (Assigned)':
        updateUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
        }
        break
      case 'Not Available (In-Use)':
        updateUnit = {
          ...unit,
          active: true,
          inactiveReason: null,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INUSE
        }
        break
      case 'Expired Registration':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDREGISTRATION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Safety Inspection':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDSAFETYINSPECTION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Expired Maintenance Inspection':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.EXPIREDMAINTENANCEINSPECTION,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Maintenance':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.MAINTENANCE,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Terminated':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.TERMINATED,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      case 'Other Inactive Reason':
        updateUnit = {
          ...unit,
          active: false,
          inactiveReason: EquipmentDTO.InactiveReasonEnum.OTHER,
          availability: false,
          unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.INACTIVE
        }
        break
      default:
        updateUnit = unit
        break
    }

    actions.modify(updateUnit)
  }
}
