import * as React from 'react'
import styled from 'styled-components'
import { BasicTableRow } from './interfaces'

export const Row = ({ key, columns, styles }: BasicTableRow) => (
  <StyledRow key={key} style={styles}>
    {columns.map(columnProps => {
      if (columnProps.visible !== undefined && !columnProps.visible) {
        return null
      }
      return (
        <StyledColumn key={columnProps.key} style={columnProps.styles}>
          {columnProps.value}
        </StyledColumn>
      )
    })}
  </StyledRow>
)

const StyledRow = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  background-color: #fff;

  &:not(:first-child) {
    border-top: 1px solid #e5e5e5;
  }
`
const StyledColumn = styled.div`
  font-size: 14px;
  padding-left: 8px;

  &:not(:first-child) {
    border-left: 1px solid transparent;
  }
`
