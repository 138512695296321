import * as React from 'react'
import { MainMenu } from '../mainMenu'
import { ButtonWithIcon } from './styledComponents'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

export const Menu = () => {
  const { statePopover, togglePopover, wrapperRef } = useOutsideClick()

  return (
    <div ref={wrapperRef}>
      <ButtonWithIcon onClick={togglePopover} className={'mdi mdi-plus-circle-outline'} />
      {statePopover && <MainMenu closeMenu={togglePopover} />}
    </div>
  )
}
