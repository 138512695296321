import { compareUpdateDates } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import * as R from 'remeda'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { cleanDispatchDeliveryOrder } from './functions'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { processObject } from '../functions'
import { oc } from 'ts-optchain'

export const omitFieldsOnStages: any[] = ['location', 'vendor']

export const omitDDOFields: (keyof IDispatchDeliveryOrder)[] = [
  'declinedVendorIds',
  'currentActivityGroup',
  'activityIds',
  'activities',
  'activityGroups',
  'deliveryOrder',
  'container',
  'equipment',
  'buySideQuotes',
  'sellSideQuotes',
  'containerType',
  'progressBarState',
  'profitAmount',
  'bsqAmount',
  'ssqAmount',
  'sellSideQuote',
  'buySideQuotes',
  'streetTurn',
  'streetTurnCount',
  'temporaryData',
  'error',
  'communicationHubAppChannelId'
]

const extraOmitKeys = ['containerId', 'equipmentId']

export const getUpdatedDispatchDeliveryOrderStatus = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  allowNotFullObject?: boolean
) => {
  const store = getStore().getState()
  const storeDispatchDeliveryOrder = store.dispatchDeliveryOrder[dispatchDeliveryOrder.id]

  const getLightObjects = () => {
    const omitExtraFields = extraOmitKeys.filter(field => !dispatchDeliveryOrder[field])
    let lightDDO = cleanDispatchDeliveryOrder(processObject(dispatchDeliveryOrder).hardClean as IDispatchDeliveryOrder)
    if (omitExtraFields.length) {
      // @ts-ignore
      lightDDO = R.omit(lightDDO, omitExtraFields)
    }

    const lightStoreDDO =
      cleanDispatchDeliveryOrder(processObject(storeDispatchDeliveryOrder).hardClean as IDispatchDeliveryOrder) || {}

    return {
      checkObject: omitUpdateInfo(lightDDO),
      storeObject: omitUpdateInfo(lightStoreDDO)
    }
  }

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(dispatchDeliveryOrder, storeDispatchDeliveryOrder)
    },
    get needToSave() {
      const { checkObject, storeObject } = getLightObjects()
      return (oc(checkObject).fullObject() || allowNotFullObject) && !isEqualObjects(checkObject, storeObject)
    },
    get getDifference() {
      const { checkObject, storeObject } = getLightObjects()
      const omitProps = ['buySideQuoteIds']

      return getObjectsDifference({
        oldObject: omitUpdateInfo(R.omit(checkObject, omitProps)),
        newObject: omitUpdateInfo(R.omit(storeObject, omitProps))
      })
    }
  }
}
