import {
  ActivitiesViewDTO,
  BuySideQuoteDTO,
  DispatchDeliveryOrderViewDTO,
  SellSideQuoteDTO,
  TransportationActivityViewDTO
} from '../../../../../api/origin/business-logic'
import { calcWidgetQuoteAmount } from '../../../../common/QMP/views/functions'
import { oc } from 'ts-optchain'
import { SellSideQuoteRateDTO } from '../../../../../api/origin/qmp-service'
import { ActivityViewDTO } from '../../../../common/activity/interfaces'
import { BuySideQuote } from '../../../../../services/DTO/buySideQuote/interfaces'

type TCalculateQuotes = {
  profit: number
  sellSideQuoteTotalAmount: number
  buySideQuoteTotalAmount: number
  buySideQuotesAmount: Record<string, number>
}

export const calculateQuoteWidget = (
  isModifiedMode: boolean,
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
): TCalculateQuotes => {
  const { sellSideQuote, buySideQuotes } = dispatchDeliveryOrder

  return calculateQuotes({ sellSideQuote, buySideQuotes, isModifiedMode })
}

export const calculateQuotes = (props: {
  sellSideQuote: SellSideQuoteDTO
  buySideQuotes: BuySideQuoteDTO[]
  isModifiedMode?: boolean
}): TCalculateQuotes => {
  const { sellSideQuote, buySideQuotes, isModifiedMode } = props
  const allSSQSurcharges = [...oc(sellSideQuote).surcharges([]), ...oc(sellSideQuote).miscSurcharges([])]
  let sellSideQuoteTotalAmount = allSSQSurcharges.length ? oc(sellSideQuote).amount() : undefined
  let buySideQuoteTotalAmount = buySideQuotes && buySideQuotes.length ? 0 : undefined
  const buySideQuotesAmount: Record<string, number> = {}
  let profit = sellSideQuoteTotalAmount !== undefined ? 0 : undefined

  if (buySideQuotes && buySideQuotes.length) {
    buySideQuotes.filter(Boolean).forEach(buySideQuote => {
      const amount = calculateBuySideQuote(buySideQuote, isModifiedMode)

      buySideQuotesAmount[buySideQuote.id] = amount
      buySideQuoteTotalAmount += amount
    })
  }

  if (allSSQSurcharges.length) {
    sellSideQuoteTotalAmount = isModifiedMode ? calcWidgetQuoteAmount(allSSQSurcharges) : sellSideQuote.amount
    profit = (sellSideQuoteTotalAmount || 0) - (buySideQuoteTotalAmount || 0)
  }

  return {
    sellSideQuoteTotalAmount,
    buySideQuoteTotalAmount,
    buySideQuotesAmount,
    profit
  }
}

export const calculateBuySideQuote = (buySideQuote: BuySideQuoteDTO, isModifiedMode: boolean = false): number => {
  return isModifiedMode
    ? calcWidgetQuoteAmount([...buySideQuote.miscSurcharges, ...buySideQuote.surcharges])
    : buySideQuote.amount
}

export const mainRateTypes = [
  SellSideQuoteRateDTO.TypeEnum.BASE,
  SellSideQuoteRateDTO.TypeEnum.FUEL,
  SellSideQuoteRateDTO.TypeEnum.ALLIN
]

export const sortBSQListByType = (buySideQuoteList: BuySideQuote[], activities: ActivitiesViewDTO): BuySideQuote[] => {
  if (!buySideQuoteList || !buySideQuoteList.length) {
    return buySideQuoteList
  }

  const activitiesMapping: Record<string, ActivityViewDTO> = oc(activities)
    .transportationActivities([])
    .reduce((acc: Record<string, ActivityViewDTO>, curr: ActivityViewDTO) => {
      acc[curr.id] = curr
      return acc
    }, {})

  return buySideQuoteList
    .filter(bsq => activitiesMapping[bsq.activityId])
    .map(bsq => {
      const currentActivity: any = activitiesMapping[bsq.activityId]

      return {
        ...bsq,
        temporaryData: {
          activityStage: oc(currentActivity).stage(),
          activityOrder: oc(currentActivity).number(999),
          activityType: oc(currentActivity).type(),
          activityComboType: oc(currentActivity).comboType(),
          activityVendorId: oc(currentActivity).vendor.id()
        }
      }
    })
    .sort((a, b) => a.temporaryData.activityOrder - b.temporaryData.activityOrder)
}

export const sortSurchargesByActivityNumber = (
  surcharges: any[],
  transportationActivities: TransportationActivityViewDTO[]
): any[] => {
  if (!surcharges || !surcharges.length) {
    return surcharges
  }

  const activitiesMapping: Record<string, ActivityViewDTO> = (transportationActivities || []).reduce(
    (acc: Record<string, ActivityViewDTO>, curr: ActivityViewDTO) => {
      acc[curr.id] = curr
      return acc
    },
    {}
  )

  return surcharges
    .map(surcharge => {
      const currentActivity: any = activitiesMapping[surcharge.activityId]

      return {
        ...surcharge,
        temporaryData: {
          activityStage: oc(currentActivity).stage(),
          activityOrder: oc(currentActivity).number(999),
          activityType: oc(currentActivity).type(),
          activityComboType: oc(currentActivity).comboType(),
          activityGroupId: oc(currentActivity).groupId()
        }
      }
    })
    .sort((a, b) => a.temporaryData.activityOrder - b.temporaryData.activityOrder)
}

export const filterAvailableActivitiesToAttach = (
  activities: TransportationActivityViewDTO[],
  surcharges: any[]
): TransportationActivityViewDTO[] => {
  return (activities || []).filter(activity =>
    (surcharges || []).every(surcharge => surcharge.activityId !== activity.id)
  )
}
