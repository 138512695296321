import { ISubClient } from '../../../components/common/subClient/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { getBusinessPartnerStatus } from '../businessPartner/status'
import { isEqualObjectsWithoutBusinessPartner } from '../saveDTO'
import { oc } from 'ts-optchain'

export const getSubClientStatus = (subClient: ISubClient): TObjectStatusForSave => {
  const store = getStore().getState()
  const storeSubClient = store.subClient[subClient.id]
  const businessPartnerInfo = getBusinessPartnerStatus(subClient.businessPartner)

  return {
    noStoreDataForUpdate: compareUpdateDates(subClient, storeSubClient) && businessPartnerInfo.noStoreDataForUpdate,
    needToSave:
      oc(subClient).fullObject() && !isEqualObjectsWithoutBusinessPartner(subClient, storeSubClient, ['customer'])
  }
}
