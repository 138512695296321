import { IDeliveryOrder } from '../../../components/common/deliveryOrder/interfaces'
import * as yup from 'yup'
import { oc } from 'ts-optchain'
import { callAPI, DeliveryOrderViewDTO, dispatchDeliveryOrderAPI, DispatchDeliveryOrderViewDTO } from '../../../api/api'
import {
  schemaContainer,
  schemaCustomerLazy,
  schemaDateTimeRange,
  schemaDeliveryStage,
  schemaLocation,
  schemaPickupStage,
  schemaReturnStage,
  schemaSteamShipLineView
} from '../../yupScheme'
import { checkContactsValidation } from '../contact'
import { debuggingMode } from '../../debug'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from '../assemble'
import { parseDTO } from '../parseDTO'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { isDDOStatusNew } from '../../functions/test/isDDOStatusNew'
import { isDDOStatusCancelled } from '../../functions/test/isDDOStatusCancelled'

export const createValidator = (min: number, max: number) => (value: string): boolean => {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  if (value.length >= min && value.length <= max) {
    return true
  }
  return false
}

export const deliveryOrderType = {
  Export: DeliveryOrderViewDTO.TypeEnum.EXPORT,
  Import: DeliveryOrderViewDTO.TypeEnum.IMPORT
}

export const origin = {
  deliveryOrderType
}

export const isValidDescription = createValidator(2, 1000)
export const isValidJofNumber = createValidator(2, 15)
export const isValidProductIdentifier = createValidator(4, 64)
export const isValidPurchaseOrderNumber = createValidator(4, 64)
export const isValidReferenceNumber = createValidator(4, 64)
export const isValidBillOfLadingNumber = createValidator(4, 64)
export const isValidBookingNumber = createValidator(4, 64)
export const isValidNumber = createValidator(0, 50)
export const isValidVesselName = createValidator(0, 120)
export const isValidVoyageNumber = createValidator(0, 120)

export const schemaCargo = yup.object().shape({
  description: yup
    .string()
    .min(2)
    .max(1000)
    .nullable(true)
    .required(),
  purchaseOrderNumber: yup
    .string()
    .min(4)
    .max(64)
    .nullable(true),
  referenceNumber: yup
    .string()
    .min(4)
    .max(64)
    .required()
})

const schemaDispatchDelivery = yup.object().shape({
  weight: yup.number().nullable(true),
  weightUnit: yup.mixed().oneOf(['LBS', 'KGS']),
  loadType: yup.mixed().oneOf(['LIVE_LOAD', 'DROP_AND_PICK']),
  containerTypeId: yup.string().required(),
  pickupStage: schemaPickupStage,
  returnStage: schemaReturnStage
})

const schemaDispatchDeliveryFor = (type: DeliveryOrderViewDTO.TypeEnum) =>
  schemaDispatchDelivery.shape({
    containerId: yup
      .string()
      .when('type', (_type: string, schema: yup.StringSchema) =>
        type === DeliveryOrderViewDTO.TypeEnum.IMPORT ? schema.required() : schema.nullable(true)
      ),
    hazmatId: yup
      .string()
      .when('hazmatIndicator', (_hazmatIndicator: boolean, schema: yup.StringSchema) =>
        _hazmatIndicator ? schema.required() : schema.nullable(true)
      ),
    deliveryStage: yup
      .object()
      .when('type', (_type: string, schema: yup.StringSchema) =>
        type === DeliveryOrderViewDTO.TypeEnum.REPOSITION ? schema.nullable(true) : schemaDeliveryStage
      )
  })

export const schemaDeliveryOrder = (deliveryOrder: IDeliveryOrder) =>
  yup.object().shape({
    type: yup.string().required(),
    customerId: yup.string().required(),
    firstReceivingDate: yup.string().nullable(true),
    generalCutoffDate: yup.string().nullable(true),
    pickupLocationId: yup.string().nullable(true),
    deliveryLocationId: yup.string().nullable(true),
    steamShipLineId: yup.string().nullable(true),
    vesselName: yup
      .string()
      .max(120)
      .nullable(true),
    voyageNumber: yup
      .string()
      .max(120)
      .nullable(true),
    bookingNumber: yup.string().when('type', (type: string, schema: yup.StringSchema) =>
      type === 'EXPORT' || type === 'REPOSITION'
        ? schema
            .min(4)
            .max(64)
            .required()
        : schema.nullable(true)
    ),
    billOfLadingNumber: yup.string().when('type', (type: string, schema: yup.StringSchema) =>
      type === 'IMPORT'
        ? schema
            .min(4)
            .max(64)
            .required()
        : schema.nullable(true)
    ),
    cargo: schemaCargo,
    pickupLocation: schemaLocation,
    deliveryLocation: schemaLocation,
    returnLocation: schemaLocation,
    dispatchDeliveryOrders: yup
      .array()
      .of(schemaDispatchDeliveryFor(deliveryOrder.type))
      .required()
  })

// export const checkDeliveryOrder = schemaDeliveryOrder

export const isValidDispatchDeliveryOrder = (deliveryOrder: IDeliveryOrder): boolean => {
  const schema = schemaDeliveryOrder(deliveryOrder)

  const allContacts = [
    // @ts-ignore
    ...oc(deliveryOrder).pickupLocation.contacts([]),
    // @ts-ignore
    ...oc(deliveryOrder).deliveryLocation.contacts([]),
    // @ts-ignore
    ...oc(deliveryOrder).returnLocation.contacts([]),
    // @ts-ignore
    ...oc(deliveryOrder)
      .dispatchDeliveryOrders([])
      .reduce(
        (acc, cur) => [
          ...acc,
          // @ts-ignore
          ...oc(cur).pickupLocation.contacts([]),
          // @ts-ignore
          ...oc(cur).deliveryLocation.contacts([]),
          // @ts-ignore
          ...oc(cur).returnLocation.contacts([])
        ],
        []
      )
  ]

  if (debuggingMode.common) {
    let validateDO: any = true

    schema
      .validate(deliveryOrder)
      .catch(e => {
        // getStore().dispatch(
        //   addServerMessage({
        //     type: 'error',
        //     message: e.message
        //   })
        // )

        validateDO = e
      })
      .finally(() => {
        let validationResult: any = {
          validateDO,
          validateContacts: checkContactsValidation(allContacts),
          deliveryOrderSteamShipLineValueIsValid: isDeliveryOrderSteamShipLineFieldValid(deliveryOrder)
        }

        validationResult = Object.keys(validationResult).reduce((acc, currKey) => {
          if (validationResult[currKey] !== true) {
            acc[currKey] = validationResult[currKey]
          }
          return acc
        }, {})

        if (Object.keys(validationResult).length) {
          // tslint:disable-next-line:no-console
          console.error(`DO#${deliveryOrder.number || 'NEW'} validation error`, validationResult)
        }
      })
  }

  return (
    schema.isValidSync(deliveryOrder) &&
    checkContactsValidation(allContacts) &&
    isDeliveryOrderSteamShipLineFieldValid(deliveryOrder)
  )
}

export const isDeliveryOrderIdSteamShipLineRequiredByDDOs = (deliveryOrderId: string) => (
  modifiedDispatchDeliveryOrder?: IDispatchDeliveryOrder
) => {
  const assembledDO = assembleDTO.deliveryOrder({ store: undefined, id: deliveryOrderId })
  return isDeliveryOrderSteamShipLineRequiredByDDOs(
    modifiedDispatchDeliveryOrder
      ? {
          ...assembledDO,
          dispatchDeliveryOrders: assembledDO.dispatchDeliveryOrders.map(ddo =>
            ddo.id === modifiedDispatchDeliveryOrder.id ? modifiedDispatchDeliveryOrder : ddo
          )
        }
      : assembledDO
  )
}

export const isDeliveryOrderSteamShipLineRequiredByDDOs = (deliveryOrder: IDeliveryOrder): boolean => {
  const sslIsNotRequiredForDDO = (ddo: DispatchDeliveryOrderViewDTO): boolean => {
    if (deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION) {
      const ddoIsNew =
        isDDOStatusNew(ddo.status) &&
        !(
          oc(deliveryOrder).equipmentFirstPickupDate() &&
          oc(ddo).pickupStage.locationId() &&
          oc(ddo).returnStage.locationId()
        )
      return ddoIsNew || (isDDOStatusCancelled(ddo.status) && !oc(deliveryOrder).steamShipLineId())
    }

    return isDDOStatusNew(ddo.status) || (isDDOStatusCancelled(ddo.status) && !oc(deliveryOrder).steamShipLineId())
  }

  return oc(deliveryOrder)
    .dispatchDeliveryOrders([])
    .some(ddo => !sslIsNotRequiredForDDO(ddo))
}

export const isDeliveryOrderSteamShipLineFieldCorrect = async (
  deliveryOrder: IDeliveryOrder | DeliveryOrderViewDTO
): Promise<boolean> => {
  if (deliveryOrder.steamShipLineId) {
    return true
  }

  const store = getStore().getState()

  if (
    oc(store)
      .deliveryOrder[deliveryOrder.id].dispatchDeliveryOrderIds([])
      .some(id => !store.dispatchDeliveryOrder[id])
  ) {
    await callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, 'deliveryOrder.id%%' + deliveryOrder.id)
      .toPromise()
      .then(receivedDDOs => {
        parseDTO.dispatchDeliveryOrder(receivedDDOs)
      })
  }

  return isDeliveryOrderSteamShipLineFieldValid(assembleDTO.deliveryOrder({ store: undefined, id: deliveryOrder.id }))
}

export const isDeliveryOrderSteamShipLineFieldValid = (deliveryOrder: IDeliveryOrder): boolean => {
  if (!deliveryOrder) {
    return false
  }

  return Boolean(deliveryOrder.steamShipLineId || !isDeliveryOrderSteamShipLineRequiredByDDOs(deliveryOrder))
}

export const schemaDeliveryOrderGridItem = yup.object().shape({
  appointmentDateTimeRange: schemaDateTimeRange,
  billOfLadingNumber: yup
    .string()
    .min(4)
    .max(64)
    .nullable(true),
  bookingNumber: yup
    .string()
    .min(4)
    .max(64)
    .nullable(true),
  cargo: schemaCargo,
  date: yup.string().required(),
  cargoId: yup.string().required(),
  container: schemaContainer,
  containerId: yup.string().nullable(true),
  completionMonitor: yup.string().nullable(true),
  customer: schemaCustomerLazy,
  customerId: yup.string().required(),
  dispatchDeliveryOrderIds: yup.array().of(yup.string()),
  docNumber: yup.string().nullable(true),
  equipmentFirstPickupDate: yup.string().nullable(true),
  firstReceivingDate: yup.string().nullable(true),
  generalCutoffDate: yup.string().nullable(true),
  type: yup.mixed().oneOf(['IMPORT', 'EXPORT', 'REPOSITION']),
  lastFreeDateDemurrage: yup.string().nullable(true),
  lastFreeDatePerDiem: yup.string().nullable(true),
  steamShipLine: schemaSteamShipLineView,
  number: yup
    .string()
    .min(0)
    .max(50)
    .nullable(true),
  steamShipLineId: yup.string().nullable(true),
  vesselArrivalDate: yup.string().nullable(true),
  vesselDepartureDate: yup.string().nullable(true),
  vesselName: yup
    .string()
    .min(0)
    .max(120)
    .nullable(true),
  vesselSchedule: yup.string().nullable(true),
  voyageNumber: yup
    .string()
    .min(0)
    .max(120)
    .nullable(true)
})
