import * as React from 'react'
import { TProps } from './simple'
import { TextMask } from './textMask'
import { transformMoney } from '../../../Input'

const transformMoneyAmount = (_value: string | number): string => {
  let result = ''
  const value = typeof _value === 'number' ? String(_value) : _value

  value.split('.').forEach((subString: string, index: number) => {
    if (index === 0) {
      result = subString
    }

    if (index === 1 && subString.length !== 0) {
      let doit = subString.slice(0, 2)
      if (doit.length === 2 && doit.slice(-1) === '0') {
        doit = doit.slice(0, -1)
      }

      result += '.' + doit
    }
  })

  return result
}

export const Money = (
  props: TProps & { allowZero?: boolean; isNegative?: boolean; isPositiveOrNegative?: boolean }
) => {
  const { onChange, value, allowZero, isNegative, isPositiveOrNegative } = props

  const handleChange = (input: string | null) => {
    if (input === null) {
      return onChange(null)
    }

    const cleanValue = input.replace('$ ', '')

    if (cleanValue === '-') {
      return onChange(null)
    }

    const result = transformMoneyAmount(cleanValue)

    // allow negative only for negative
    const regex = isNegative || isPositiveOrNegative ? /^-?\d+([.]\d{1,2})?$/ : /^\d+([.]\d{1,2})?$/

    if (allowZero && (cleanValue === '0' || cleanValue === '-0')) {
      return onChange(Number(result))
    }

    if (!input) {
      return onChange(null)
    }

    if (regex.test(result) && Number(result) !== value) {
      return onChange(Number(result))
    }
  }

  return (
    <TextMask
      {...props}
      value={transformMoney(props.value, true)}
      placeholder={props.placeholder || '$'}
      dynamicTextMask={
        isPositiveOrNegative
          ? (inputValue: string, editState: boolean) => {
              const clearedValue = String(inputValue || '').replace('$ ', '')
              const hasMinus = clearedValue.includes('-')

              return [
                hasMinus && !editState ? '-' : '',
                '$ ',
                editState ? /[0-9\-]/ : /[0-9]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9\.]/,
                /[0-9]/
              ]
            }
          : undefined
      }
      textMask={[
        isNegative ? '-' : '',
        '$ ',
        /[0-9]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9\.]/,
        /[0-9]/
      ]}
      onChange={handleChange}
    />
  )
}
