import { oc } from 'ts-optchain'
import { IconName } from '../views/Icon'
import { deliveryOrderDirectory } from '../../../services/DTO/deliveryOrder/directory'
import { IDispatchDeliveryOrder } from '../../common/dispatchDeliveryOrder/interfaces'
import {
  DeliveryOrderGridItemDTO,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderSearchDTO
} from '../../../api/origin/business-logic'
import { getStore } from '../../../store/configureStore'
import { ChannelNamePlaceholder, ChannelType, ICHubChannel } from '../../../store/reducers/communicationHub/interfaces'

export const getIconNameByChannelType = (channelType: ChannelType): IconName => {
  switch (channelType) {
    case ChannelType.operator:
      return IconName.LATTICE
    case ChannelType.dispatchDeliveryOrder:
      return IconName.DOCUMENT
    default:
      return IconName.LATTICE
  }
}

export const generateChannelDataByEntity = (
  channelType: ChannelType,
  data: any
): {
  id: string
  name: string
  subName: string
  channelViewName: string
} => {
  const result = {
    id: '',
    name: '',
    subName: '',
    channelViewName: ''
  }

  switch (channelType) {
    case ChannelType.dispatchDeliveryOrder: {
      result.id = oc(data).id()
      const deliveryOrderNumber = oc(data).number('DDO#')
      let deliveryOrderType = oc(data).deliveryOrder.type()

      if (!deliveryOrderType && oc(data).deliveryOrderId()) {
        deliveryOrderType = oc(getStore().getState()).deliveryOrder[data.deliveryOrderId].type()
      }

      const deliveryOrderLocation =
        deliveryOrderType === DeliveryOrderGridItemDTO.TypeEnum.REPOSITION
          ? oc(data).returnStage.location.shortName()
          : oc(data).deliveryStage.location.shortName()

      result.name = deliveryOrderNumber

      if (deliveryOrderType) {
        deliveryOrderType = deliveryOrderDirectory.columnType[deliveryOrderType].toUpperCase()
        result.name += ' ' + deliveryOrderType
      }

      result.subName = deliveryOrderLocation || ChannelNamePlaceholder.placeholderLongLine
      result.channelViewName = [deliveryOrderNumber, deliveryOrderType, deliveryOrderLocation]
        .filter(Boolean)
        .join(' • ')

      return result
    }
    default:
      return result
  }
}

export const generateDispatchDeliveryOrderChatChannel = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder | DispatchDeliveryOrderGridItemDTO | DispatchDeliveryOrderSearchDTO
): ICHubChannel => {
  const { id, name, subName, channelViewName } = generateChannelDataByEntity(
    ChannelType.dispatchDeliveryOrder,
    dispatchDeliveryOrder
  )

  return {
    id,
    type: ChannelType.dispatchDeliveryOrder,
    name,
    subName,
    channelViewName,
    metadata: {
      status: oc(dispatchDeliveryOrder).status(),
      archived: oc(dispatchDeliveryOrder).archived()
    }
  }
}
