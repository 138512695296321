import { getStore } from '../../../store/configureStore'
import { isNewId, isNewObject } from '../index'
import { convertErrorToMessage, tryToSave } from '../saveDTO'
import * as R from 'remeda'
import { IDeliveryOrder } from '../../../components/common/deliveryOrder/interfaces'
import {
  getUpdatedDeliveryOrderStatus,
  getDispatchDeliveryOrdersToSave,
  omitDOFields,
  saveDispatchDeliveryOrders
} from './status'
import { callAPI, cargoAPI, ContainerDTO, deliveryOrderAPI } from '../../../api/api'
import { updateTab } from '../../../components/common/tabs/actions'
import { oc } from 'ts-optchain'
import { getLocationStatus } from '../location/status'
import { getContainerSavePromise } from '../container/save'
import { ILocation } from '../../../components/common/location/interfaces'
import { IStore } from '../../../store/store.interface'
import { ITabState } from '../../../components/common/tabs/interfaces'
import { parseDTO } from '../parseDTO'
import { setTabViewingObject } from '../../viewingObjects/actions'
import { assembleDTO } from '../assemble'
import { showErrorModalWindow } from '../../../store/reducers/modalWindow/functions'

export const getDeliveryOrderSavePromise = (deliveryOrder: IDeliveryOrder, actions?: TActions): Promise<any> => {
  const { dispatch } = getStore()
  const { needToSave } = getUpdatedDeliveryOrderStatus(deliveryOrder)
  const getObjectPromise = (object: any, promiseAction: any) => (object ? promiseAction(object) : object)

  let updatedDO = { ...deliveryOrder }

  const updateViewingObject = () => {
    if (actions) {
      let tab = {
        ...actions.currentTab,
        storeTab: !isNewObject(updatedDO)
      }
      if (!isNewObject(updatedDO)) {
        tab = {
          ...tab,
          title: 'DO #' + updatedDO.number,
          storeTab: true
        }
      }

      dispatch(
        updateTab({
          tab
        })
      )

      dispatch(setTabViewingObject({ tabId: tab.id, viewingObject: { id: updatedDO.id, modifiedObject: updatedDO } }))
    }
  }

  const replaceOldObjectsWithNew = {
    listOfObjects: {},
    id: (id: string) => replaceOldObjectsWithNew.listOfObjects[id] || id || undefined,
    object: (object: any) =>
      object && replaceOldObjectsWithNew.listOfObjects[object.id] ? undefined : object || undefined
  }

  const saveDOLocationsAndDDOContainers = () => {
    const containersToUpdate = oc(updatedDO)
      .dispatchDeliveryOrders([])
      .filter(ddo => isNewObject(ddo.container))
      .map(ddo => ddo.container)
      .reduce((acc, curr) => {
        if (!acc.some(container => container.number === curr.number)) {
          acc.push(curr)
        }

        return acc
      }, [])

    return (containersToUpdate.length
      ? Promise.all(containersToUpdate.map(container => getObjectPromise(container, getContainerSavePromise))).then(
          (containers: ContainerDTO[]) => {
            updatedDO = {
              ...updatedDO,
              dispatchDeliveryOrders: updatedDO.dispatchDeliveryOrders.map(ddo => {
                const newContainer = containers.find(container => container.number === oc(ddo).container.number())
                if (newContainer) {
                  return R.omit({ ...ddo, containerId: newContainer.id }, ['container'])
                }

                return ddo
              })
            }

            updateViewingObject()
          }
        )
      : Promise.resolve()
    ).then(() => updatedDO)
  }

  return (
    saveDOLocationsAndDDOContainers()
      // >>> create/update cargo
      .then(
        () =>
          needToSave
            ? isNewObject(updatedDO)
              ? callAPI(cargoAPI.create, updatedDO.cargo)
                  .toPromise()
                  .then(cargo =>
                    callAPI(deliveryOrderAPI.create, R.omit({ ...updatedDO, cargoId: cargo.id }, [
                      ...omitDOFields,
                      'cargo',
                      'id'
                    ]) as IDeliveryOrder).toPromise()
                  )
              : callAPI(cargoAPI.update, updatedDO.cargo, updatedDO.cargo.id)
                  .toPromise()
                  .then(() =>
                    callAPI(
                      deliveryOrderAPI.update,
                      R.omit(updatedDO, [...omitDOFields, 'cargo']) as IDeliveryOrder,
                      updatedDO.id
                    ).toPromise()
                  )
            : updatedDO
        // <<<
      )
      .then(resolvedDeliveryOrder => {
        const canInheritUpdatedLocations = Boolean(Object.keys(replaceOldObjectsWithNew.listOfObjects).length)

        if (needToSave || canInheritUpdatedLocations) {
          updatedDO = {
            ...(resolvedDeliveryOrder as IDeliveryOrder),
            dispatchDeliveryOrders: updatedDO.dispatchDeliveryOrders.map(ddo => ({
              ...ddo,
              deliveryOrderId: resolvedDeliveryOrder.id,
              pickupStage: { ...oc(ddo).pickupStage({}) },
              deliveryStage: { ...oc(ddo).deliveryStage({}) },
              returnStage: { ...oc(ddo).returnStage({}) }
            }))
          }

          updateViewingObject()
        }

        const dispatchDeliveryOrdersToSave = getDispatchDeliveryOrdersToSave(updatedDO.dispatchDeliveryOrders || [])

        return saveDispatchDeliveryOrders(dispatchDeliveryOrdersToSave)
          .then(result => {
            return result
              ? callAPI(deliveryOrderAPI.findById, updatedDO.id)
                  .toPromise()
                  .then(resultedDO => {
                    parseDTO.deliveryOrder({ ...resultedDO, forceUpdate: true })
                    return resultedDO
                  })
              : resolvedDeliveryOrder
          })
          .then(DOData => {
            if (actions) {
              dispatch(
                updateTab({
                  tab: {
                    ...actions.currentTab,
                    title: 'DO #' + DOData.number,
                    storeTab: true
                  }
                })
              )
              dispatch(setTabViewingObject({ tabId: actions.currentTab.id, viewingObject: { id: DOData.id } }))
              actions.hideSpinner()
            }
            return DOData
          })
      })
  )
}

type TActions = {
  hideSpinner: () => void
  currentTab: ITabState
}

export const saveDeliveryOrder = (deliveryOrder: IDeliveryOrder, actions?: TActions) => {
  const { dispatch, getState } = getStore()
  const checkObject = (object: any, getStatusAction: any) =>
    object ? getStatusAction(object).noStoreDataForUpdate : true

  const save = () =>
    getDeliveryOrderSavePromise(deliveryOrder, actions)
      .catch(errors => {
        const result = (message: any) => {
          showErrorModalWindow({ content: convertErrorToMessage(message) })
          actions.hideSpinner()
        }

        return errors && errors.json
          ? errors
              .json()
              .then((error: any) => result(error))
              .catch(() => {})
          : result(errors)
      })
      .finally(() => {
        actions.hideSpinner()
      })

  const condition = checkObject(deliveryOrder, getUpdatedDeliveryOrderStatus)

  return tryToSave({
    condition,
    save: save,
    cancel: () => {},
    hideSpinner: actions.hideSpinner,
    discardChanges: () => {
      if (isNewObject(deliveryOrder)) {
        const modifiedObject = discardNewDOChanges(deliveryOrder, getState())
        dispatch(
          setTabViewingObject({
            tabId: actions.currentTab.id,
            viewingObject: { id: modifiedObject.id, modifiedObject }
          })
        )
      } else {
        actions.hideSpinner()
      }
    }
  })
}

const discardNewDOChanges = (deliveryOrder: IDeliveryOrder, store: IStore): IDeliveryOrder => {
  const clearLocations = (object: any) => {
    const getCorrectLocation = (locationId: string, prevLocationState: ILocation) =>
      assembleDTO.location({ store, id: locationId }) || prevLocationState || null

    return {
      ...object,
      pickupLocation: getCorrectLocation(object.pickupLocationId, object.pickupLocation),
      deliveryLocation: getCorrectLocation(object.deliveryLocationId, object.deliveryLocation),
      returnLocation: getCorrectLocation(object.returnLocationId, object.returnLocation)
    }
  }

  return {
    ...clearLocations(deliveryOrder),
    dispatchDeliveryOrders: oc(deliveryOrder)
      .dispatchDeliveryOrders([])
      .map(clearLocations)
  }
}
