import * as React from 'react'
import { Additional, BoldLine, Text, Title } from '../../styles'
import { TransportationActivityViewDTO } from '../../../../../api/origin/business-logic'

export const Preview: React.FC<{
  windowProps: {
    title: string
    activityTypeOrDDOStatus: string
    activityVendor: string
    titleAndIconColor: string
  }
  currentActivityStatus: TransportationActivityViewDTO.StatusEnum
}> = ({
  windowProps: { activityTypeOrDDOStatus, activityVendor, titleAndIconColor, title },
  currentActivityStatus
}) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      {title}
    </Title>
    <BoldLine
      className={'uppercase'}
      style={{ color: titleAndIconColor }}
      rows={3}
      alignCenter={true}
      margin={{ bottom: 5 }}
    >
      {activityTypeOrDDOStatus}
      {(currentActivityStatus === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        currentActivityStatus === TransportationActivityViewDTO.StatusEnum.COMPLETED) && (
        <Additional
          className={
            currentActivityStatus === TransportationActivityViewDTO.StatusEnum.COMPLETED
              ? 'mdi mdi-checkbox-marked-circle'
              : 'mdi mdi-clock-outline'
          }
          style={{
            color: titleAndIconColor,
            fontSize: 24,
            transform: 'translateY(-20px)'
          }}
          rows={4}
        />
      )}
    </BoldLine>
    <Text className={'opacity'} rows={3} alignCenter={true}>
      {activityVendor}
    </Text>
  </>
)
