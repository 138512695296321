import {
  BusinessPartnerViewDTO,
  CustomerBusinessPartnerViewDTO,
  SubClientViewDTO
} from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddSubClientToStore } from '../../../components/common/subClient/epics'
import { parseDTO } from '../parseDTO'

export const parseSubClients = (subClients: SubClientViewDTO[]) => {
  const lists: { businessPartners: BusinessPartnerViewDTO[]; customer: CustomerBusinessPartnerViewDTO[] } = {
    businessPartners: [],
    customer: []
  }
  const clearedSubClients = subClients.filter(Boolean).map(subClient => {
    if (subClient.businessPartner) {
      lists.businessPartners.push(subClient.businessPartner)
    }
    if (subClient.customer) {
      lists.customer.push(subClient.customer)
    }
    return R.omit(subClient, ['businessPartner', 'customer'])
  })

  parseDTO.businessPartner(lists.businessPartners)
  parseDTO.customer(lists.customer)
  tryAddSubClientToStore(clearedSubClients)
}
