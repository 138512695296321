import * as React from 'react'
import { connect } from 'react-redux'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { DDOErrorTypes, IDispatchDeliveryOrder } from '../../../interfaces'
import { TopBlock } from './topBlock'
import { Table } from './Table'
import {
  ConfirmedLocalDateTimeRangeDTO,
  ContainerTypeDTO,
  ContainerViewDTO,
  DateISOString,
  DateTimeRangeDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  EquipmentDTO,
  TransportationActivityViewDTO
} from '../../../../../../api/origin/business-logic'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'
import { ActivityContext } from '../../../../../../services/DTO/activity/context'
import {
  calcDDOProps,
  CorrectActivityData,
  getDDOWorkingStatus,
  getRelatedActivityType,
  isChassisNumberRequired
} from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { IStore } from '../../../../../../store/store.interface'
import { getEquipment } from '../../../../equipment/epics'
import { calculateQuoteWidget } from '../../../../../UI/Widget/widgets/quote/functions'
import { checkStagesForShuttle, DDOWorkingStatus } from '../../../../../../services/DTO/activity/controller'
import { isDiffRanges } from '../../../../../UI/DatePicker/Functions'
import { isBusinessActivity } from '../../../../../../services/functions/test/isBusinessActivity'
import { isDDOStatusCancelled } from '../../../../../../services/functions/test/isDDOStatusCancelled'

type OwnProps = {
  isDDOValid: boolean
  actions: IGridItemActions
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  saveButton: any
  required: {
    chassisNumber: boolean
  }
  functions: {
    updateContainerType: (containerType: ContainerTypeDTO) => void
    updateContainer: (container: ContainerViewDTO) => void
  }
  showUserInfo?: boolean
  unlimitedHeight?: boolean
}

export const updateActivities = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  updatedDDOActivitiesData: CorrectActivityData,
  modifyDDOAction: (dispatchDeliveryOrder: IDispatchDeliveryOrder, modifiedInitial?: IDispatchDeliveryOrder) => void,
  modifiedInitialObjectState?: IDispatchDeliveryOrder
) => {
  const { activityGroups, documentationActivities, transportationActivities } = updatedDDOActivitiesData
  const { ddoStatus, currentActivityGroup, declinedVendorIds } = calcDDOProps(activityGroups, dispatchDeliveryOrder)
  const stagesShuttle = checkStagesForShuttle(dispatchDeliveryOrder, activityGroups)
  const needClearChassis =
    ddoStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED &&
    dispatchDeliveryOrder.activities.transportationActivities.filter(
      activity => isBusinessActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    ).length <
      transportationActivities.filter(
        activity =>
          isBusinessActivity(activity) && activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
      ).length &&
    isChassisNumberRequired(dispatchDeliveryOrder.activities) &&
    !isChassisNumberRequired({ transportationActivities })

  const pickupRelatedActivity = getRelatedActivityType(transportationActivities, 'pickup')
  const appointmentRelatedActivity = getRelatedActivityType(transportationActivities, 'delivery')
  const pickRelatedActivity = getRelatedActivityType(transportationActivities, 'pick')
  const returnRelatedActivity = getRelatedActivityType(transportationActivities, 'return')

  let pickupLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
  let pickupLocation = oc(dispatchDeliveryOrder).pickupStage.location()
  let deliveryLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
  let deliveryLocation = oc(dispatchDeliveryOrder).deliveryStage.location()
  let returnLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()
  let returnLocation = oc(dispatchDeliveryOrder).returnStage.location()

  const isRepo = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION

  transportationActivities.forEach(activity => {
    if (activity.template) {
      switch (activity.stage) {
        case TransportationActivityViewDTO.StageEnum.PICKUP:
          pickupLocationId = activity.destinationId
          pickupLocation = activity.destination
          break
        case TransportationActivityViewDTO.StageEnum.DELIVERY:
          deliveryLocationId = activity.destinationId
          deliveryLocation = activity.destination
          break
        case TransportationActivityViewDTO.StageEnum.RETURN:
          returnLocationId = activity.destinationId
          returnLocation = activity.destination
          break
        default:
          break
      }
    }
  })

  const updatedDDO = {
    ...dispatchDeliveryOrder,
    status: isDDOStatusCancelled(dispatchDeliveryOrder.status) ? dispatchDeliveryOrder.status : ddoStatus,
    currentActivityGroup,
    declinedVendorIds,
    pickupStage: {
      ...oc(dispatchDeliveryOrder).pickupStage({}),
      shuttle: stagesShuttle[TransportationActivityViewDTO.StageEnum.PICKUP],
      locationId: pickupLocationId,
      location: pickupLocation,
      plannedAppointmentDateTimeRange: pickupRelatedActivity
        ? pickupRelatedActivity.startPlannedDateTimeRange
        : dispatchDeliveryOrder.pickupStage.plannedAppointmentDateTimeRange,
      actualAppointmentDate: pickupRelatedActivity
        ? pickupRelatedActivity.completionActualDate
        : dispatchDeliveryOrder.pickupStage.actualAppointmentDate
    },
    deliveryStage: isRepo
      ? {}
      : {
          ...oc(dispatchDeliveryOrder).deliveryStage({}),
          locationId: deliveryLocationId,
          location: deliveryLocation,
          plannedAppointmentDateTimeRange: appointmentRelatedActivity
            ? getCorrectPlannedAppointmentDate({
                activityCompletionActualDate: oc(appointmentRelatedActivity).completionActualDate(),
                ddoPlannedAppointmentDateTimeRange: oc(
                  dispatchDeliveryOrder
                ).deliveryStage.plannedAppointmentDateTimeRange(),
                activityStartPlannedDateTimeRange: oc(appointmentRelatedActivity).startPlannedDateTimeRange()
              })
            : oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange(),
          actualAppointmentDate: appointmentRelatedActivity
            ? appointmentRelatedActivity.completionActualDate
            : oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
          plannedPickDateTimeRange: pickRelatedActivity
            ? pickRelatedActivity.startPlannedDateTimeRange
            : oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
          actualPickDate: pickRelatedActivity
            ? pickRelatedActivity.completionActualDate
            : oc(dispatchDeliveryOrder).deliveryStage.actualPickDate()
        },
    returnStage: {
      ...oc(dispatchDeliveryOrder).returnStage({}),
      shuttle: stagesShuttle[TransportationActivityViewDTO.StageEnum.RETURN],
      locationId: returnLocationId,
      location: returnLocation,
      plannedAppointmentDateTimeRange: returnRelatedActivity
        ? returnRelatedActivity.startPlannedDateTimeRange
        : dispatchDeliveryOrder.returnStage.plannedAppointmentDateTimeRange,
      actualAppointmentDate: returnRelatedActivity
        ? returnRelatedActivity.completionActualDate
        : dispatchDeliveryOrder.returnStage.actualAppointmentDate
    },
    activityGroups,
    activities: {
      documentationActivities,
      transportationActivities
    }
  }

  if (needClearChassis) {
    delete updatedDDO.equipment
    delete updatedDDO.equipmentId
  }

  return modifyDDOAction(updatedDDO, modifiedInitialObjectState)
}

type StateProps = {}

type DispatchProps = {
  equipment?: EquipmentDTO
}

type Props = OwnProps & StateProps & DispatchProps

const Activities = (props: Props) => {
  const {
    isDDOValid,
    actions,
    dispatchDeliveryOrder,
    equipment,
    saveButton,
    functions,
    showUserInfo,
    unlimitedHeight,
    required
  } = props
  const sellSideQuote = oc(dispatchDeliveryOrder).sellSideQuotes[0](null)
  const allowModifying = actions.enableEditing && getDDOWorkingStatus(dispatchDeliveryOrder) !== DDOWorkingStatus.none
  const isModifiedMode = actions.isModified
  const quoteAmounts = calculateQuoteWidget(isModifiedMode, dispatchDeliveryOrder)

  React.useEffect(() => {
    if (!equipment) {
      if (dispatchDeliveryOrder.equipmentId) {
        getEquipment(dispatchDeliveryOrder.equipmentId)
      }
    }
  }, [])

  return (
    <ActivityContext.Provider
      value={{ isModifiedMode, required, saveButton: isModifiedMode ? saveButton : null, functions }}
    >
      <DispatchDeliveryOrderTabContent style={unlimitedHeight ? { height: 'auto', paddingBottom: 10 } : undefined}>
        {oc(dispatchDeliveryOrder).error.type() === DDOErrorTypes.activity &&
          oc(dispatchDeliveryOrder).error.message('')}
        {Boolean(
          dispatchDeliveryOrder.activities.documentationActivities.length ||
            dispatchDeliveryOrder.activities.transportationActivities.length
        ) && (
          <>
            <TopBlock
              actions={actions}
              isDDOValid={isDDOValid}
              quoteAmounts={quoteAmounts}
              dispatchDeliveryOrder={dispatchDeliveryOrder}
              enableEditing={actions.enableEditing}
              allowModifying={allowModifying}
              showDates={showUserInfo}
            />
            <Table
              setFetching={actions.setFetching}
              unlimitedHeight={unlimitedHeight}
              quoteAmounts={quoteAmounts}
              dispatchDeliveryOrder={dispatchDeliveryOrder}
              allowModifying={allowModifying && !dispatchDeliveryOrder.troubleTicketId}
              sellSideQuote={sellSideQuote}
              buySideQuotes={dispatchDeliveryOrder.buySideQuotes}
              modifyDispatchDeliveryOrder={actions.modify}
              updateDDOActivitiesData={(updatedDDOActivitiesData, updateLocationForInitialObjectState) => {
                const initialObjectState = actions.initialObjectState

                updateActivities(
                  dispatchDeliveryOrder,
                  updatedDDOActivitiesData,
                  actions.modify,
                  updateLocationForInitialObjectState
                    ? {
                        ...initialObjectState,
                        ...(oc(initialObjectState).pickupStage.location()
                          ? {
                              pickupStage: {
                                ...initialObjectState.pickupStage,
                                location:
                                  initialObjectState.pickupStage.locationId === updateLocationForInitialObjectState.id
                                    ? updateLocationForInitialObjectState
                                    : initialObjectState.pickupStage.location
                              }
                            }
                          : {}),
                        ...(oc(initialObjectState).deliveryStage.location()
                          ? {
                              deliveryStage: {
                                ...initialObjectState.deliveryStage,
                                location:
                                  initialObjectState.deliveryStage.locationId === updateLocationForInitialObjectState.id
                                    ? updateLocationForInitialObjectState
                                    : initialObjectState.deliveryStage.location
                              }
                            }
                          : {}),
                        ...(oc(initialObjectState).returnStage.location()
                          ? {
                              returnStage: {
                                ...initialObjectState.returnStage,
                                location:
                                  initialObjectState.returnStage.locationId === updateLocationForInitialObjectState.id
                                    ? updateLocationForInitialObjectState
                                    : initialObjectState.returnStage.location
                              }
                            }
                          : {}),
                        activities: {
                          ...oc(initialObjectState).activities({}),
                          transportationActivities: oc(initialObjectState)
                            .activities.transportationActivities([])
                            .map((activity: any) =>
                              activity.destinationId === updateLocationForInitialObjectState.id
                                ? { ...activity, destination: updateLocationForInitialObjectState }
                                : activity
                            )
                        }
                      }
                    : undefined
                )
              }}
            />
          </>
        )}
      </DispatchDeliveryOrderTabContent>
    </ActivityContext.Provider>
  )
}

type CorrectPlannedAppointmentDate = {
  activityCompletionActualDate: DateISOString
  ddoPlannedAppointmentDateTimeRange: ConfirmedLocalDateTimeRangeDTO
  activityStartPlannedDateTimeRange: DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO
}
const getCorrectPlannedAppointmentDate = (props: CorrectPlannedAppointmentDate): ConfirmedLocalDateTimeRangeDTO => {
  const { activityCompletionActualDate, activityStartPlannedDateTimeRange, ddoPlannedAppointmentDateTimeRange } = props
  const appointmentDateChanged = isDiffRanges(ddoPlannedAppointmentDateTimeRange, activityStartPlannedDateTimeRange)

  if (!appointmentDateChanged) {
    return ddoPlannedAppointmentDateTimeRange
      ? {
          from: oc(ddoPlannedAppointmentDateTimeRange).from(),
          to: oc(ddoPlannedAppointmentDateTimeRange).to(),
          confirmed: Boolean(activityCompletionActualDate) || oc(ddoPlannedAppointmentDateTimeRange).confirmed(null)
        }
      : ddoPlannedAppointmentDateTimeRange
  }

  const hasDate = Boolean(oc(activityStartPlannedDateTimeRange).from() || oc(activityStartPlannedDateTimeRange).to())

  return hasDate
    ? {
        confirmed: true,
        from: oc(activityStartPlannedDateTimeRange).from(),
        to: oc(activityStartPlannedDateTimeRange).to()
      }
    : undefined
}

export default connect(
  (store: IStore, props: OwnProps) => {
    const equipmentId = oc(props).dispatchDeliveryOrder.equipmentId()

    return {
      equipment: equipmentId ? store.equipment[equipmentId] : null
    }
  },
  null
)(Activities)
