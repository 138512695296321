import * as React from 'react'
import { StyledColumn } from '../../styles'
import { Header } from '../common/header'
import {
  TransportationActivityViewDTO,
  SellSideQuoteDTO,
  ActivitiesViewDTO
} from '../../../../../../../../api/origin/business-logic'
import { ApplicableRatesBlock, AdditionalSurchargesBlock } from '../common/body'
import { MainRatesBlock } from './MainRatesBlock'
import { SellSideQuoteRateDTO } from '../../../../../../../../api/origin/qmp-service'
import { IDispatchDeliveryOrder } from '../../../../../../../common/dispatchDeliveryOrder/interfaces'
import { mainRateTypes, sortSurchargesByActivityNumber } from '../../../functions'
import { getRatesByFilters } from '../../../../../../../../services/DTO/rate/epics'
import { SurchargeDTO, MiscSurchargeDTO } from '../../types'
import { RateType } from '../../../../../../../common/QMP/interfaces'
import { isBusinessActivity } from '../../../../../../../../services/functions/test/isBusinessActivity'

type OwnProps = {
  activities: ActivitiesViewDTO
  totalAmount: number
  profit: number
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  transportationActivities: TransportationActivityViewDTO[]
  filledFiltersByDDO: any
  updateSellSideQuote: (sellSideQuote: SellSideQuoteDTO) => void
  openRatesLink: () => void
  openRateLink: (rateNumber: string) => void
  omitUnsuitableSurchargeTypes: SellSideQuoteRateDTO.TypeEnum[]
}

type StateProps = {}

const defaultSellSideQuote: SellSideQuoteDTO = {
  id: undefined,
  amount: 0,
  date: null,
  miscSurcharges: [],
  surcharges: []
}

type Props = OwnProps & StateProps

export const SSQColumn = (props: Props) => {
  const {
    activities,
    totalAmount,
    profit,
    dispatchDeliveryOrder,
    filledFiltersByDDO,
    transportationActivities,
    updateSellSideQuote,
    openRatesLink,
    openRateLink,
    omitUnsuitableSurchargeTypes
  } = props
  const [applicableRates, setApplicableRates] = React.useState([])
  const sellSideQuote = dispatchDeliveryOrder.sellSideQuote || defaultSellSideQuote
  const transportationBusinessActivities = (transportationActivities || []).filter(activity =>
    isBusinessActivity(activity)
  )
  const updateMisc = (miscSurcharges: MiscSurchargeDTO[]) => updateSellSideQuote({ ...sellSideQuote, miscSurcharges })
  const updateSurcharges = (surcharges: SurchargeDTO[]) =>
    updateSellSideQuote({ ...sellSideQuote, surcharges } as SellSideQuoteDTO)
  const updateSurchargeQuantity = (surchargeId: string, quantity: number) =>
    updateSurcharges(
      sellSideQuote.surcharges.map(surcharge =>
        surcharge.id === surchargeId ? { ...surcharge, quantity: quantity || 0 } : surcharge
      )
    )

  // >>> get applicable rates
  React.useEffect(() => {
    if (sellSideQuote.surcharges.length) {
      getRatesByFilters({
        rateType: RateType.ssq,
        filledFilters: { id: sellSideQuote.surcharges.map(({ rateId }) => rateId) }
      })
    }

    getRatesByFilters({
      rateType: RateType.ssq,
      filledFilters: { ...filledFiltersByDDO, quoteDate: sellSideQuote.date },
      omitRateTypes: omitUnsuitableSurchargeTypes
    }).then(sellSideQuoteRatesRequest => {
      if (sellSideQuoteRatesRequest.length) {
        setApplicableRates(sellSideQuoteRatesRequest)
      }
    })
  }, [])
  // <<<

  return (
    <StyledColumn>
      <Header quoteType={'SSQ'} profit={profit} total={totalAmount} />
      <MainRatesBlock
        surcharges={sellSideQuote.surcharges.filter(({ type }) => mainRateTypes.includes(type))}
        updateSurchargeQuantity={updateSurchargeQuantity}
        openRateNumber={openRateLink}
      />
      <AdditionalSurchargesBlock
        showActivityColumn={true}
        transportationActivities={transportationActivities}
        miscSurcharges={sortSurchargesByActivityNumber(sellSideQuote.miscSurcharges, transportationActivities)}
        availableActivitiesToAttach={transportationBusinessActivities}
        update={updateMisc}
      />
      <ApplicableRatesBlock
        multipleChoice={true}
        attachedSurcharges={sortSurchargesByActivityNumber(
          sellSideQuote.surcharges.filter(({ type }) => !mainRateTypes.includes(type)),
          transportationActivities
        )}
        applicableRates={applicableRates}
        transportationActivities={transportationActivities}
        surcharges={sellSideQuote.surcharges}
        updateSurcharges={updateSurcharges}
        updateSurchargeQuantity={updateSurchargeQuantity}
        openRateTab={() => openRatesLink()}
        openRateNumber={openRateLink}
      />
    </StyledColumn>
  )
}
