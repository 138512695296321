import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { DispatchDeliveryOrderTabContent } from '../styles'
import {
  CargoDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  EquipmentDTO,
  HazmatDTO,
  SteamShipLineDTO,
  SubClientViewDTO
} from '../../../../../../api/origin/business-logic/api'
import { connect } from 'react-redux'
import LeftBlock from './Left'
import CenterBlock from './Center'
import RightBlock from './Right'
import { oc } from 'ts-optchain'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { isNewObject } from '../../../../../../services/DTO'
import { ICustomerView } from '../../../../customer/interfaces'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../../../../api/api'
import { alertOnChangingMainStreetTurnPropsOfDO } from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { IDeliveryOrder } from '../../../../deliveryOrder/interfaces'
import { parseDTO } from '../../../../../../services/DTO/parseDTO'

type OwnProps = {
  actions: IGridItemActions
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

type DispatchProps = {
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  updateCustomer: (customer: ICustomerView, updateInitialState?: boolean) => void
  changeSubClient: (subClient?: SubClientViewDTO) => void
  updateSteamShipLine: (steamShipLine: SteamShipLineDTO) => void
  updateHazmat: (hazmat: HazmatDTO) => void
  updateEquipment: (value?: EquipmentDTO) => void
  updateCargo: (fieldName: keyof CargoDTO) => (value: string) => void
  checkTerminalIntegration: () => void
}

type Props = OwnProps & DispatchProps

const extraContainerStyles = {
  display: 'flex'
}

const General: React.SFC<Props> = ({
  dispatchDeliveryOrder,
  updateDispatchDeliveryOrderField,
  updateDispatchDeliveryOrderMultipleFields,
  updateDispatchDeliveryOrder,
  updateDeliveryOrder,
  updateSteamShipLine,
  updateCargo,
  updateCustomer,
  updateEquipment,
  updateHazmat,
  checkTerminalIntegration,
  actions
}) => {
  const sameProps = {
    dispatchDeliveryOrder,
    updateDeliveryOrder,
    updateDispatchDeliveryOrder,
    updateDispatchDeliveryOrderField,
    updateDispatchDeliveryOrderMultipleFields,
    checkTerminalIntegration,
    actions
  }

  return (
    <DispatchDeliveryOrderTabContent style={extraContainerStyles}>
      {dispatchDeliveryOrder.fullObject && (
        <>
          <LeftBlock {...sameProps} updateCustomer={updateCustomer} updateEquipment={updateEquipment} />
          <CenterBlock {...sameProps} />
          <RightBlock
            {...sameProps}
            updateCargo={updateCargo}
            checkTerminalIntegration={checkTerminalIntegration}
            updateHazmat={updateHazmat}
            updateSteamShipLine={updateSteamShipLine}
          />
        </>
      )}
    </DispatchDeliveryOrderTabContent>
  )
}

export default connect(
  null,
  (
    dispatch,
    {
      dispatchDeliveryOrder,
      actions: { modify, modifyParentObjectField, setFetching, cancel, isModified, initialObjectState }
    }: OwnProps
  ): DispatchProps => {
    return {
      updateDispatchDeliveryOrder: modify,
      updateDispatchDeliveryOrderField: fieldName => value => {
        const updatedDDO = { ...dispatchDeliveryOrder, [fieldName]: value }

        if (fieldName === 'loadType' && value === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK) {
          updatedDDO.deliveryStage = { ...oc(updatedDDO).deliveryStage({}), spentTimeSpan: undefined }
        }

        if (fieldName === 'hazmatIndicator') {
          updatedDDO.deliveryOrder = { ...updatedDDO.deliveryOrder, hazmatCutoffDate: null }
        }

        if (fieldName === 'autoIndicator') {
          updatedDDO.deliveryOrder = { ...updatedDDO.deliveryOrder, autoCutoffDate: null }
        }

        return modify(updatedDDO)
      },
      updateDispatchDeliveryOrderMultipleFields: fields =>
        modify({
          ...dispatchDeliveryOrder,
          ...fields
        }),
      updateDeliveryOrder: fieldName => value =>
        modifyParentObjectField('deliveryOrder')({ ...dispatchDeliveryOrder.deliveryOrder, [fieldName]: value }),
      updateCustomer: (customer, updateInitialState) =>
        modifyParentObjectField('deliveryOrder')(
          {
            ...dispatchDeliveryOrder.deliveryOrder,
            customer,
            customerId: oc(customer).id(null)
          },
          updateInitialState && customer
            ? { ...initialObjectState, deliveryOrder: { ...oc(initialObjectState).deliveryOrder({}), customer } }
            : undefined
        ),
      changeSubClient: subClient =>
        modifyParentObjectField('deliveryOrder')({
          ...dispatchDeliveryOrder.deliveryOrder,
          subClient,
          subClientId: oc(subClient).id(null)
        }),
      updateSteamShipLine: steamShipLine => {
        const updatedDDO = {
          ...dispatchDeliveryOrder,
          deliveryOrder: {
            ...dispatchDeliveryOrder.deliveryOrder,
            steamShipLine: steamShipLine ? ({ ...steamShipLine, businessPartnerId: null } as SteamShipLineDTO) : null,
            steamShipLineId: oc(steamShipLine).id(null)
          }
        }

        modify(updatedDDO)
        alertOnChangingMainStreetTurnPropsOfDO(
          updatedDDO.deliveryOrder as any,
          dispatchDeliveryOrder.deliveryOrder as IDeliveryOrder
        )
      },
      updateHazmat: hazmat =>
        modify({
          ...dispatchDeliveryOrder,
          hazmat,
          hazmatId: oc(hazmat).id()
        }),
      updateEquipment: equipment =>
        modify({
          ...dispatchDeliveryOrder,
          equipment,
          equipmentId: equipment && !isNewObject(equipment) ? equipment.id : null
        }),
      updateCargo: fieldName => value =>
        modifyParentObjectField('deliveryOrder')({
          ...dispatchDeliveryOrder.deliveryOrder,
          cargo: { ...dispatchDeliveryOrder.deliveryOrder.cargo, [fieldName]: value }
        }),
      checkTerminalIntegration: () => {
        setFetching(true)
        callAPI(dispatchDeliveryOrderAPI.checkTerminal, dispatchDeliveryOrder.id)
          .toPromise()
          .then(result => {
            parseDTO.dispatchDeliveryOrder({ ...result, forceUpdate: true })
          })
          .finally(() => {
            setFetching(false)
          })
      }
    }
  }
)(General)
