import { oc } from 'ts-optchain'
import { DocumentationActivityDTO, LocationViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import { isDropActivity } from './isDropActivity'
import { isUnsuccessfulActivity } from './isUnsuccessfulActivity'
import { isPickActivity } from './isPickActivity'
import { isInProgressActivity } from './isInProgressActivity'
import { getNextActivityRow } from '../get/getNextActivityRow'
import { ActivityRow, DocumentationActivityRow, TransportationActivityRow } from '../../DTO/activity/controller'

export const isBobtailableBusinessActivity = (props: {
  gotoActivity: TransportationActivityViewDTO
  businessActivity: TransportationActivityViewDTO
  activityRows: ActivityRow[]
  bobtailType?: 'drop' | 'pick'
  isVendorRequired?: boolean
}): boolean => {
  const { gotoActivity, businessActivity, bobtailType, activityRows, isVendorRequired } = props

  if (
    !(
      gotoActivity &&
      businessActivity &&
      businessActivity.stage === TransportationActivityViewDTO.StageEnum.DELIVERY &&
      oc(businessActivity).destination.type() !== LocationViewDTO.TypeEnum.CONTAINERYARD &&
      (!isVendorRequired || businessActivity.vendorId) &&
      !isUnsuccessfulActivity(gotoActivity) &&
      !isUnsuccessfulActivity(businessActivity)
    )
  ) {
    return false
  }

  if (bobtailType === 'pick') {
    return (
      !gotoActivity.bobtailFrom &&
      isPickActivity(businessActivity) &&
      !isInProgressActivity(gotoActivity) &&
      !isInProgressActivity(businessActivity)
    )
  }

  if (bobtailType === 'drop') {
    const nextActivityRow = getNextActivityRow(activityRows, businessActivity.id)
    const nextGotoActivity = oc(nextActivityRow as TransportationActivityRow).activityGroup.gotoActivity()
    const nextBusinessActivity = oc(nextActivityRow as TransportationActivityRow).activityGroup.businessActivity()
    const nextDocumentationActivity = oc(
      nextActivityRow as DocumentationActivityRow
    ).activityGroup.documentationActivity()

    return (
      isDropActivity(businessActivity) &&
      (!nextActivityRow ||
        (oc(nextDocumentationActivity).status() !== DocumentationActivityDTO.StatusEnum.APPROVED &&
          !isInProgressActivity(nextGotoActivity) &&
          !isInProgressActivity(nextBusinessActivity)))
    )
  }

  return true
}
