import { IPowerUnit } from '../../../components/common/powerUnit/interfaces'
import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects } from '../saveDTO'
import { oc } from 'ts-optchain'

export const getPowerUnitStatus = (powerUnit: IPowerUnit): TObjectStatusForSave => {
  const store = getStore().getState()
  const storePowerUnit = store.powerUnit[powerUnit.id]

  return {
    noStoreDataForUpdate: compareUpdateDates(powerUnit, storePowerUnit),
    needToSave: oc(powerUnit).fullObject() && !isEqualObjects(powerUnit, storePowerUnit)
  }
}
