import { headers } from '../../../components/common/dispatchDeliveryOrder/views/configGrid'
import { ddoDirectory } from './directory'
import { DispatchDeliveryOrderViewDTO } from '../../../api/origin/business-logic'

export const defaultDispatchDeliveryOrderTabSettings = {
  columns: headers,
  sorting: '-number',
  filter: [
    {
      column: 'status',
      value: Object.keys(ddoDirectory.status).filter(
        status =>
          ![
            DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED.toString(),
            DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED.toString(),
            DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED.toString()
          ].includes(status)
      )
    },
    {
      column: 'archived',
      value: 'false'
    }
    // {
    //   column: 'deliveryStage.plannedAppointmentDateTimeRange.confirmed',
    //   value: 'true'
    // }
  ]
}
