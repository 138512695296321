import * as React from 'react'
import General from '../Details/General'
import Credentials from '../Details/Credentials'
import Repairs from '../Details/Repairs'
import { IPowerUnit } from '../../interfaces'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.PowerUnit

export const PowerUnitPreview = {
  headings: [T.General, T.Credentials, T.Repairs],
  tabs: {
    [T.General]: (powerUnit: IPowerUnit, actions: IGridItemActions) => (
      <General powerUnit={powerUnit} actions={actions} />
    ),
    [T.Credentials]: (powerUnit: IPowerUnit, actions: IGridItemActions) => (
      <Credentials powerUnit={powerUnit} actions={actions} />
    ),
    [T.Repairs]: (powerUnit: IPowerUnit, actions: IGridItemActions) => (
      <Repairs powerUnit={powerUnit} actions={actions} />
    )
  },
  isValid: checkValidation.powerUnit
}
