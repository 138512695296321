import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { WidgetTable, RowTitleColumn } from '../../../../popups/Table'
import { TerminalIntegrationTable } from './styles'
import { WidgetContext } from '../../index'

export const Popover: React.FC<{
  terminalCode: string
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}> = ({ terminalCode, dispatchDeliveryOrder }) => {
  if (!dispatchDeliveryOrder.terminalMismatches.length) {
    const { closePopover } = React.useContext(WidgetContext)
    closePopover()
    return null
  }

  const hasDescription = dispatchDeliveryOrder.terminalMismatches.find(_ => _.systemValueDescription)

  const tableBody = dispatchDeliveryOrder.terminalMismatches.reduce((acc, mismath) => {
    const constructedArr = [
      <RowTitleColumn key={mismath.fieldName}>{mismath.fieldName}</RowTitleColumn>,
      mismath.systemValue,
      mismath.terminalValue
    ]
    if (hasDescription) {
      constructedArr.push(mismath.systemValueDescription)
    }
    acc.push(constructedArr)
    return acc
  }, [])

  return (
    <TerminalIntegrationTable>
      <WidgetTable
        header={
          hasDescription ? ['Mismatch', 'System', terminalCode, 'Description'] : ['Mismatch', 'System', terminalCode]
        }
        body={tableBody}
        columnWidthEvenly={true}
      />
    </TerminalIntegrationTable>
  )
}
