import { oc } from 'ts-optchain'
import { TabType } from '../../components/common/tabs/interfaces'
import { getStore } from '../../store/configureStore'
import { setTabViewingObjectTemporaryDataProps } from '../viewingObjects/actions'
import { IWebsocketData, WebsocketEvent } from './interfaces'
import { DriverReportDTO } from '../../api/origin/document-service'
import { getListState } from '../../store'
import { ListEntityType } from '../../store/reducers/lists/interfaces'

export const documentServiceWebsocket = (data: IWebsocketData) => {
  const { type, eventType, payload } = data

  if (type === 'DriverReportDTO') {
    const report = payload as DriverReportDTO
    const driverMapping = getListState()[ListEntityType.driver]
    const { dispatch, getState } = getStore()
    const store = getState()
    const driverTabs = store.tabs.main.filter(tab => tab.type === TabType.driver)
    const viewingObjects = store.viewingObjects

    driverTabs.forEach(tab => {
      const viewingObject = viewingObjects[tab.id]

      if (
        viewingObject &&
        oc(report).userId() &&
        oc(driverMapping)[viewingObject.id].authUserId() === report.userId &&
        oc(viewingObject).temporaryData.vendorAccountingReports()
      ) {
        const updatedVendorAccountingReports = { ...viewingObject.temporaryData.vendorAccountingReports }

        updatedVendorAccountingReports[report.type] = {
          ...(updatedVendorAccountingReports[report.type] || {}),
          [report.id]: report
        }

        dispatch(
          setTabViewingObjectTemporaryDataProps({
            tabId: tab.id,
            temporaryDataProps: {
              vendorAccountingReports: updatedVendorAccountingReports
            }
          })
        )
      }
    })
  }
}
