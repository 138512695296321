import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  PowerUnitDTO,
  PowerUnitRegistrationDTO,
  MaintenanceInspectionDTO
} from '../../../../api/origin/business-logic/'
import { addPowerUnitsToStore } from '../action'
import { IPowerUnitState, IPowerUnit } from '../interfaces'
import { newMaintenanceInspection } from '../../../../services/maintenanceInspectionService'

export const newPowerUnit: IPowerUnit = {
  fullObject: true,
  active: true,
  id: 'newPowerUnit',
  inactiveReason: null,
  availability: true,
  unavailabilityReason: null,
  powerUnitType: null,
  powerUnitSubType: '',
  truckType: null,
  year: null,
  make: '',
  model: '',
  vin: '',
  weight: null,
  weightUnit: PowerUnitDTO.WeightUnitEnum.LBS,
  specs: '',
  ownership: null,
  portPassRfid: false,
  rfid: '',
  licensePlate: '',
  registration: {
    registeredDate: null,
    expirationDate: null,
    weight: null,
    weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum.LBS,
    restrictions: null,
    stateId: null
  },
  dotSafetyInspection: {
    performedDate: null,
    expirationDate: null
  },
  insurance: {
    insuranceType: null,
    carrier: '',
    expirationDate: null,
    policyNumber: ''
  },
  maintenanceInspectionFrequency: null,
  maintenanceInspections: [newMaintenanceInspection()],
  driverIds: [],
  repairs: []
}

export const newMaintenance: MaintenanceInspectionDTO = {
  performedDate: null,
  expirationDate: null,
  document: null
}

const powerUnitState: IPowerUnitState = {}

const powerUnitReducer = reducerWithInitialState<IPowerUnitState>(powerUnitState)
  .case(addPowerUnitsToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default powerUnitReducer
