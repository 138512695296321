import * as React from 'react'
import { General } from '../Details/General'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { UserDTO } from '../../../../../../api/origin/user-service'

export const UsersPreview = {
  headings: ['General'],
  tabs: {
    General: (user: UserDTO, actions: IGridItemActions) => <General user={user} actions={actions} />
  },
  isValid: true
}
