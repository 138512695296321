import { IStore } from '../../../store/store.interface'
import { oc } from 'ts-optchain'
import { ICustomerView } from '../../../components/common/customer/interfaces'
import { ISubClient } from '../../../components/common/subClient/interfaces'
import { getStore } from '../../../store/configureStore'
import { assembleDTO } from './index'

type Props = {
  id: string
  store?: IStore
  simplify?: boolean
  customer?: ICustomerView
}

export const assembleSubClient = (props: Props): ISubClient => {
  const store = props.store || getStore().getState()
  const subClient = oc(store).subClient[props.id]()

  if (!subClient) {
    return null
  }

  return {
    ...subClient,
    businessPartner: assembleDTO.businessPartner({ store, id: subClient.businessPartnerId }),
    customer: props.customer || assembleDTO.customer({ store, id: subClient.customerId })
  }
}
