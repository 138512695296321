import * as React from 'react'
import { clone } from 'remeda'
import { INotificationFilters } from '../store/reducers/communicationHub/interfaces'
import { selectNotificationFilters } from '../store/select/notificationSelect'
import { useAppSelector } from './useAppSelector'
import { selectContacts } from '../store/select/contactSelect'
import { UserDTO, UserType } from '../api/origin/user-service'
import { useAppDispatch } from './useAppDispatch'
import { communicationHubActions } from '../store/reducers/communicationHub'

type Props = {}

export const useNotificationFilters = (props: Props) => {
  const dispatch = useAppDispatch()
  const storageFilters = useAppSelector(selectNotificationFilters)
  const usersMapping = useAppSelector(selectContacts)
  const [localFilters, setLocalFilters] = React.useState<INotificationFilters>(clone(storageFilters))
  const { drivers, operators } = React.useMemo(() => {
    const _drivers: UserDTO[] = []
    const _operators: UserDTO[] = []

    Object.values(usersMapping).forEach(user => {
      if (user.type === UserType.OPERATOR) {
        _operators.push(user)
      } else if (user.type === UserType.DRIVER) {
        _drivers.push(user)
      }
    })

    return {
      drivers: _drivers,
      operators: _operators
    }
  }, [usersMapping])

  const updateFilter = React.useCallback((key: keyof INotificationFilters, value: any) => {
    setLocalFilters(fs => ({ ...fs, [key]: value }))
  }, [])

  const saveFilters = () => {
    dispatch(communicationHubActions.setNotificationFilters(localFilters))
  }

  return {
    drivers,
    operators,
    usersMapping,
    filters: localFilters,
    updateFilter,
    saveFilters
  }
}
