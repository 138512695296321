import * as React from 'react'
import General from '../Details/General'
import Credentials from '../Details/Credentials'
import Repairs from '../Details/Repairs'
import { IEquipment } from '../../interfaces'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Equipment

export const EquipmentPreview = {
  headings: [T.General, T.Credentials, T.Repairs],
  tabs: {
    [T.General]: (equipment: IEquipment, actions: IGridItemActions) => (
      <General equipment={equipment} actions={actions} />
    ),
    [T.Credentials]: (equipment: IEquipment, actions: IGridItemActions) => (
      <Credentials equipment={equipment} actions={actions} />
    ),
    [T.Repairs]: (equipment: IEquipment, actions: IGridItemActions) => (
      <Repairs equipment={equipment} actions={actions} />
    )
  },
  isValid: checkValidation.equipment
}
