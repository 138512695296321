import * as React from 'react'
import { TableCell } from '../../../../common/grid/views/styles'
import { TabActionType } from '../../../../common/tabs/interfaces'
import { StreetTurnSelect } from './StreetTurnSelect'

type OwnProps = {
  unitId: string
  isSelected?: boolean
  isDisabledSelect?: boolean
  actionType?: TabActionType
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const SelectColumn = (props: Props) => {
  const { unitId, actionType, isSelected, isDisabledSelect } = props

  let select = null

  switch (actionType) {
    case TabActionType.streetTurn:
      select = <StreetTurnSelect unitId={unitId} isSelected={isSelected} isDisabledSelect={isDisabledSelect} />
      break
    default:
      select = <div />
  }

  return (
    <TableCell>
      <div style={extraColumnStyles}>{select}</div>
    </TableCell>
  )
}

const extraColumnStyles = { width: 20 }
