import { combineEpics } from 'redux-observable'
import { addBusinessPartnerToStore } from '../action'
import { BusinessPartnerViewDTO } from '../../../../api/origin/business-logic'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { getStore } from '../../../../store/configureStore'

export const tryAddBusinessPartnerToStore = (items: BusinessPartnerViewDTO | BusinessPartnerViewDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().businessPartner, items, addBusinessPartnerToStore, {
    legalAddress: undefined
  })
}

export const businessPartnerEpic = combineEpics()
