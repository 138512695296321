import * as React from 'react'
import { FileUploader } from './FileUploader'
import { FileDTO, FileVersionDTO } from '../../../../api/origin/document-service'
import { modifyTabViewingObject } from '../../../../services/viewingObjects/actions'
import { getStore } from '../../../../store/configureStore'
import { RichFileUploader } from './RichFileUploader'

type OwnProps = {
  uploadingFileId: string
  file: FileDTO | undefined
  onFileUploaded: (file: FileDTO | undefined, modifiedObject: any) => any
  onClearFile: () => void
  title?: string
  required?: boolean
  disabled?: boolean
  historyProps?: { entityId: string; type: FileVersionDTO.TypeEnum }
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const ViewingObjectFileUploader = React.memo((props: Props) => {
  const { title, required, disabled, uploadingFileId, onClearFile, historyProps } = props
  const activeTabId = React.useMemo(() => getStore().getState().tabs.activeMainTabId, [])

  const onChange = React.useCallback(
    (file: FileDTO | undefined) => {
      if (!file) {
        return onClearFile()
      }

      const { dispatch, getState } = getStore()
      const storeViewingObject = getState().viewingObjects[activeTabId]

      if (!(storeViewingObject && storeViewingObject.modifiedObject)) {
        return
      }

      try {
        dispatch(
          modifyTabViewingObject({
            tabId: activeTabId,
            modifiedObject: props.onFileUploaded(file, storeViewingObject.modifiedObject)
          })
        )
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error("File updating error. ViewingObject can't be build correctly")
      }
    },
    [props.onFileUploaded, onClearFile]
  )

  return historyProps ? (
    <RichFileUploader
      uploadingFileId={uploadingFileId}
      title={title}
      required={required}
      disabled={disabled}
      activeTabId={activeTabId}
      file={props.file}
      type={historyProps.type}
      entityId={historyProps.entityId}
      onChange={onChange}
    />
  ) : (
    <FileUploader
      uploadingFileId={uploadingFileId}
      title={title}
      required={required}
      disabled={disabled}
      activeTabId={activeTabId}
      file={props.file}
      onChange={onChange}
    />
  )
})
