import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import styled from 'styled-components'
import theme from '../../../../styles/theme'
import { IDriver } from '../../../common/drivers/interfaces'
import { FieldContainer } from '../FieldContainer'
import { Container, DropDown, FindButton, InputContainer, Item, Value } from './styles'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { ClearButton, StyledInput } from '../Input/styles'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectDrivers } from '../../../../store/select/driverSelect'
import { filterDrivers } from '../../../../services/functions/filter/filterDrivers'
import { sortDrivers } from '../../../../services/functions/sort/sortDrivers'
import { WindowPopover } from '../../windowPopover'
import { useDriverActivity } from '../../../../hooks/useDriverActivity'
import { ICalculatedDriverActivity } from '../../../../services/DTO/driverActivity/interfaces'

type Props = {
  selectedDriverId: string
  onChange: (driver: IDriver) => void
  title?: string
  placeholder?: string
  required?: boolean
  highlighted?: boolean
  disabled?: boolean
  inputContainerClasses?: string
  filterListResultFunction?: (driver: IDriver) => boolean
}

export const DriverSearch = (props: Props) => {
  const { disabled, selectedDriverId, onChange, filterListResultFunction } = props
  const inputRef = React.useRef(null)
  const { statePopover, togglePopover, wrapperRef, setPopoverState } = useOutsideClick({
    capture: false
  })
  const driverMapping = useAppSelector(selectDrivers)
  const drivers: IDriver[] = React.useMemo(() => Object.values(driverMapping), [driverMapping])
  const selectedDriver = selectedDriverId && driverMapping[selectedDriverId]
  const [trim, setTrim] = React.useState<string>(oc(selectedDriver).name(''))
  const calculatedSelectedDriverActivity = useDriverActivity({ driverIdOrAuthUserId: selectedDriverId })

  const selectDriver = React.useCallback(
    (driver: IDriver) => {
      onChange(driver)
      setTrim(oc(driver).name(''))
      setPopoverState(false)
    },
    [onChange, setPopoverState]
  )

  const clearDriver = () => selectDriver(undefined)
  const focusInputOnClick = disabled
    ? undefined
    : () => {
        const input = inputRef.current && inputRef.current.getElementsByTagName('INPUT')[0]

        if (input) {
          setTimeout(() => input.focus())
        }
      }

  let filteredDrivers = statePopover ? sortDrivers(filterDrivers({ trim, drivers, isCandidates: false })) : undefined

  if (filteredDrivers && filterListResultFunction) {
    filteredDrivers = filteredDrivers.filter(filterListResultFunction)
  }

  return (
    <FieldContainer title={props.title} required={props.required} highlighted={props.highlighted} disabled={disabled}>
      <Container className={'search-by-list-container'} ref={wrapperRef}>
        <InputContainer
          ref={inputRef}
          className={cn('input-container', {
            showOffline:
              (!statePopover || trim === oc(selectedDriver).name('')) && calculatedSelectedDriverActivity.showOffline,
            disabled,
            showOnline: selectedDriver && calculatedSelectedDriverActivity.showOnline,
            inactive: selectedDriver && calculatedSelectedDriverActivity.inactive,
            highlighted: props.required && !statePopover && !selectedDriverId
          })}
          title={oc(selectedDriver).name()}
        >
          <>
            <StyledInput
              type="text"
              disabled={disabled}
              onFocus={() => {
                setTrim(oc(selectedDriver).name(''))
                setPopoverState(true)
              }}
              placeholder={props.placeholder || 'Type here'}
              onBlur={() => {
                if (!trim && selectedDriverId) {
                  clearDriver()
                } else {
                  setTrim(oc(selectedDriver).name(''))
                }

                setPopoverState(false)
              }}
              value={statePopover ? trim : oc(selectedDriver).name('')}
              data-copy-value-on-mousedown={true}
              onChange={e => setTrim(e.target.value)}
            />
            {!statePopover && (
              <RenderStatusLabel
                driver={selectedDriver}
                calculatedDriverActivity={calculatedSelectedDriverActivity}
                style={{ alignSelf: 'center', paddingRight: 8, cursor: disabled ? undefined : 'pointer' }}
                onClick={focusInputOnClick}
              />
            )}
            {!disabled && (
              <>
                {(selectedDriverId || trim) && <ClearButton className={'mdi mdi-close-circle'} onClick={clearDriver} />}
                <FindButton
                  onClick={focusInputOnClick}
                  className={cn('mdi mdi-magnify', {
                    disable: trim
                  })}
                />
              </>
            )}
          </>
        </InputContainer>

        {statePopover && (
          <WindowPopover bindToRef={inputRef}>
            <DropDown key={filteredDrivers.length}>
              {filteredDrivers.length ? (
                filteredDrivers.map(driver => {
                  return <DropdownItem key={driver.id} driver={driver} onClick={() => selectDriver(driver)} />
                })
              ) : (
                <Item>Not found</Item>
              )}
            </DropDown>
          </WindowPopover>
        )}
      </Container>
    </FieldContainer>
  )
}

const DropdownItem = ({ driver, onClick }: { driver: IDriver; onClick: () => void }) => {
  const calculatedDriverActivity = useDriverActivity({ driverIdOrAuthUserId: oc(driver).authUserId() })

  return (
    <Item key={driver.id} onMouseDown={onClick}>
      <RenderIndicator driver={driver} calculatedDriverActivity={calculatedDriverActivity} />
      <div>{driver.name}</div>
      <RenderStatusLabel driver={driver} calculatedDriverActivity={calculatedDriverActivity} />
    </Item>
  )
}

const RenderIndicator = ({
  driver,
  calculatedDriverActivity
}: {
  driver: IDriver
  calculatedDriverActivity: ICalculatedDriverActivity
}) => {
  if (!driver) {
    return null
  }

  return (
    <StatusIndicator
      className={cn({
        online: calculatedDriverActivity.showOnline,
        offline: calculatedDriverActivity.showOffline
      })}
    />
  )
}

const RenderStatusLabel = ({
  driver,
  calculatedDriverActivity,
  style,
  onClick
}: {
  driver: IDriver
  calculatedDriverActivity: ICalculatedDriverActivity
  style?: React.CSSProperties
  onClick?: () => void
}) => {
  if (!driver) {
    return null
  }

  if (calculatedDriverActivity.inactive) {
    return (
      <InactiveLabel style={style} onClick={onClick}>
        inactive
      </InactiveLabel>
    )
  } else if (calculatedDriverActivity.showGeneratedLastActiveDate) {
    return (
      <OfflineTimeLabel style={style} onClick={onClick}>
        {calculatedDriverActivity.showGeneratedLastActiveDate}
      </OfflineTimeLabel>
    )
  }

  return null
}

const StatusIndicator = styled.div`
  flex-shrink: 0;
  align-self: center;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;

  &.online {
    background-color: #65d16c;
  }
  &.offline {
    background-color: #f5a85f;
  }
`
const StatusLabel = styled.div`
  flex-shrink: 0;
  height: 15px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  white-space: nowrap;
  font-size: 11px;
  margin-left: 10px;
  user-select: none;
`
const OfflineTimeLabel = styled(StatusLabel)`
  color: rgba(64, 73, 87, 0.7);
`
const InactiveLabel = styled(StatusLabel)`
  color: ${theme.colors.defaultRed};
`
