import { ITabState, ITabsStoreState, ITabSettings } from '../components/common/tabs/interfaces'
import { Action } from 'typescript-fsa'
// import { IUserState } from '../components/common/administration/users/interfaces'
import { IPowerUnitState } from '../components/common/powerUnit/interfaces'
import { IEquipmentState } from '../components/common/equipment/interfaces'
import { IContainerState } from '../components/common/containers/interfaces'
import { ILocationState } from '../components/common/location/interfaces'
import { ContactState } from '../components/common/contact/interfaces'
import { ICustomerState } from '../components/common/customer/interfaces'
import { ISteamShipLineState } from '../components/common/steamShipLine/interfaces'
import { ISubClientState } from '../components/common/subClient/interfaces'
import { IDispatchDeliveryOrderState } from '../components/common/dispatchDeliveryOrder/interfaces'
import { IDeliveryOrderState } from '../components/common/deliveryOrder/interfaces'
import { TMsgType } from '../components/UI/Modal/actions'
import { ContainerTypeState } from '../services/DTO/containerType/type'
import { StateTypeState } from '../services/stateService/type'
import { BusinessPartnerState } from '../components/common/businessPartner/types'
import { TModalButtonSettings } from '../components/UI/Modal'
import { IActivityState } from '../components/common/activity/interfaces'
import { ICustomerQuotesState } from '../services/DTO/customerQuote/interfaces'
import { UserDTO } from '../api/origin/user-service'
import { IBSQRatesState, ISSQRatesState } from '../services/DTO/rate/interfaces'
import { ISellSideQuoteState } from '../services/DTO/sellSideQuote/interfaces'
import { IBuySideQuoteState } from '../services/DTO/buySideQuote/interfaces'
import { IStreetTurnState } from '../services/DTO/streetTurn/interfaces'
import { ViewingObjectState } from '../services/viewingObjects/interfaces'
import { NotificationsState } from '../components/UI/Notification/interfaces'

export enum ApplicationContext {
  main = 'main',
  admin = 'admin'
}

export interface IModalState {
  visible: boolean
  message: string
  msgType: TMsgType | ''
  buttonSettings?: TModalButtonSettings
  onConfirm: () => void
  onCancel: () => void
}

export type IUiSettingsState = {
  [tabId: string]: ITabSettings
}

export interface IUiSettingsStoreState {
  settings: IUiSettingsSettingsStoreState
  userId: string
}

export interface IUiSettingsSettingsStoreState {
  activeMainTabId: string
  main: ITabState[]
  admin: ITabState[]
  version: number
}

export enum AuthorizationState {
  Initial = 'Initial',
  Authorizing = 'Authorizing',
  Authorized = 'Authorized',
  FailedWithAuth = 'FailedWithAuth',
  ServerError = 'ServerError'
}

export interface IAuthorizationState {
  state: AuthorizationState
}

export interface IActionsToDispatchAfterSuccessfulAuthorizationState extends Array<Action<any>> {}

export interface IStore {
  applicationContext: ApplicationContext
  actionsToDispatchAfterSuccessfulAuthorization: IActionsToDispatchAfterSuccessfulAuthorizationState
  authorization: IAuthorizationState
  viewingObjects: ViewingObjectState
  notifications: NotificationsState
  state: StateTypeState
  users: UserDTO[]
  powerUnit: IPowerUnitState
  equipment: IEquipmentState
  container: IContainerState
  streetTurn: IStreetTurnState
  ssqRates: ISSQRatesState
  bsqRates: IBSQRatesState
  sellSideQuotes: ISellSideQuoteState
  buySideQuotes: IBuySideQuoteState
  location: ILocationState
  customer: ICustomerState
  steamShipLine: ISteamShipLineState
  subClient: ISubClientState
  activity: IActivityState
  dispatchDeliveryOrder: IDispatchDeliveryOrderState
  deliveryOrder: IDeliveryOrderState
  containerType: ContainerTypeState
  contact: ContactState
  customerQuotes: ICustomerQuotesState
  businessPartner: BusinessPartnerState
  modal: IModalState
  tabs: ITabsStoreState
}
