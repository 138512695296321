import * as React from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../../../../hooks/useAppSelector'
import { selectDrivers } from '../../../../../../store/select/driverSelect'
import theme from '../../../../../../styles/theme'
import { DriverNameDTO, DriverViewDTO } from '../../../../../../api/api'
import { preventEvent } from '../../../../../../services/functions/basic'
import { Editing, Whitespace } from '../../../../../../services/keyboardService/keys'
import { requestDrivers } from '../../../../drivers/epics'
import { driverDirectory } from '../../../../../../services/DTO/driver/directory'
import { sortDrivers } from '../../../../../../services/functions/sort/sortDrivers'
import { filterDrivers } from '../../../../../../services/functions/filter/filterDrivers'

type Props = {
  selectedDrivers: DriverNameDTO[]
  onDriverAdd: (driver: DriverViewDTO) => void
  onDriverIdRemove: (id: string) => void
}

let candidateListStatus: 'NOT LOADED' | 'PENDING' | 'LOADED' = 'NOT LOADED'

export const MultiSelectDriver = (props: Props) => {
  const { onDriverAdd, onDriverIdRemove } = props
  const selectedDrivers = props.selectedDrivers || []
  const inputRef = React.useRef(null)
  const inputCopyRef = React.useRef(null)
  const [trim, setTrim] = React.useState('')
  const [inputWidth, setInputWidth] = React.useState(3)
  const [inputFocus, setInputFocus] = React.useState(false)
  const driverMapping = useAppSelector(selectDrivers)
  const drivers = React.useMemo(() => Object.values(driverMapping), [driverMapping])
  const availibleAddDriver = selectedDrivers.length < 2
  const showPopup = inputFocus && availibleAddDriver
  let matchedDrivers: DriverViewDTO[] = []

  React.useEffect(() => {
    if (candidateListStatus === 'NOT LOADED') {
      candidateListStatus = 'PENDING'
      requestDrivers({ filter: 'inactiveReason%%' + driverDirectory.sortedCandidateStatuses.join(',') })
        .then(() => (candidateListStatus = 'LOADED'))
        .catch(() => (candidateListStatus = 'NOT LOADED'))
    }
  }, [])

  React.useEffect(() => {
    if (inputCopyRef.current && inputRef.current) {
      setInputWidth(inputCopyRef.current.offsetWidth)
    }
  }, [trim])

  if (showPopup) {
    matchedDrivers = filterDrivers({ drivers, trim }).filter(
      ({ status }) =>
        status !== DriverViewDTO.StatusEnum.TERMINATED && status !== DriverViewDTO.StatusEnum.CANDIDATEREJECTED
    )

    if (selectedDrivers && selectedDrivers.length) {
      matchedDrivers = matchedDrivers.filter(({ id }) => selectedDrivers.every(_ => _.id !== id))
    }

    matchedDrivers = sortDrivers(matchedDrivers)
  }

  return (
    <>
      <Container>
        <InputArea onClick={() => inputRef.current && inputRef.current.focus()}>
          {selectedDrivers.length
            ? selectedDrivers.map(driver => {
                if (!(driver && driver.id && driver.name)) {
                  return
                }

                return (
                  <DriverBox key={driver.id}>
                    {driver.name}
                    <DeleteButton
                      className={'mdi mdi-close-circle'}
                      onClick={e => {
                        preventEvent(e)
                        onDriverIdRemove(driver.id)
                      }}
                    />
                  </DriverBox>
                )
              })
            : !trim && <PlaceHolder>Select</PlaceHolder>}
          <Input
            ref={inputRef}
            value={trim}
            style={{ width: inputWidth }}
            onChange={availibleAddDriver ? e => setTrim(e.target.value.replace('  ', ' ')) : undefined}
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
              setTrim('')
              setInputFocus(false)
            }}
            onKeyDown={e => {
              if (e.key === Editing.Backspace && trim.length === 0) {
                if (selectedDrivers.length) {
                  const lastDriver = selectedDrivers[selectedDrivers.length - 1]

                  if (lastDriver && lastDriver.id) {
                    onDriverIdRemove(lastDriver.id)
                  }
                }
              }

              if (e.key === Whitespace.Tab && matchedDrivers[0]) {
                preventEvent(e)
                onDriverAdd(matchedDrivers[0])
              }
            }}
          />
        </InputArea>

        {showPopup && (
          <DropDown
            onMouseDown={e => {
              preventEvent(e)
            }}
          >
            {matchedDrivers.length ? (
              matchedDrivers.map(driver => (
                <DropDownItem
                  key={driver.id}
                  children={driver.name}
                  onClick={() => {
                    inputRef.current.blur()
                    onDriverAdd(driver)
                  }}
                />
              ))
            ) : (
              <DropDownItem style={{ cursor: 'default' }} key={'###'} children={'No results found'} />
            )}
          </DropDown>
        )}
      </Container>
      <InputCopyBox ref={inputCopyRef} children={trim + 'i'} />
    </>
  )
}

const Container = styled.div`
  position: relative;
`

const InputArea = styled.div`
  min-height: 35px;
  max-height: 100px;
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 5px;
  background: #f5f6fa;
  padding: 5px 8px 3px;
  overflow: auto;

  &:before {
    width: 20px;
    display: block;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-size: 16px;
    cursor: pointer;
  }
`

const PlaceHolder = styled.div`
  height: 20px;
  position: absolute;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.3);
  padding: 3px 0;
  margin: 2px;
`

const DriverBox = styled.div`
  height: 20px;
  position: relative;
  max-width: calc(100% - 30px);
  background-color: ${theme.colors.basicBlueColor};
  border-radius: 5px;
  color: white;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px 25px 3px 6px;
  margin: 2px;
  user-select: none;
`
const DeleteButton = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

const Input = styled.input`
  width: 3px;
  height: 20px;
  display: block;
  font-size: 14px;
  background: none;
  border: none;
  margin: 2px;

  &:not(:focus)[value=''] {
    color: red;
  }
`

const InputCopyBox = styled.div`
  z-index: -999;
  min-width: 3px;
  position: fixed;
  top: -9999px;
  left: -9999px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
`
const DropDown = styled.div`
  max-height: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 2px solid ${theme.colors.basicBlueColor};
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  overflow: auto;
  padding: 4px 0;
`

const DropDownItem = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 12px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`
