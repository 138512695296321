import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import { ActivityDocumentStatus } from './interfaces'
import {
  callAPIWithErrorMessage,
  TransportationActivityViewDTO,
  troubleTicketDocumentAPI,
  TroubleTicketDTO
} from '../../../../../../../../../../../api/api'
import { FileCategory, IAttachment } from '../../../../../../../../../../../services/filesService/interfaces'
import {
  ActivityDocumentPopupView,
  IActivityDocumentPopup,
  IDispatchDeliveryOrder
} from '../../../../../../../../interfaces'
import { DocumentValidationDTO } from '../../../../../../../../../../../api/origin/document-service'
import { Overlay, Popup, Title } from '../styles'
import { GridSpinner } from '../../../../../../../../../../UI/Spinner/Spinner'
import { ActivityDocumentHeader } from './ActivityDocumentHeader'
import { ActivityTroubleTicketDocumentDescription } from './ActivityTroubleTicketDocumentDescription'
import { Attachments } from '../../../../../../../../../../CommunicationHub/views/Attachments/Attachments'
import { ActivityDocumentFileUploader } from './ActivityDocumentFileUploader'
import { ActivityDocumentComplitionDate } from './ActivityDocumentComplitionDate'
import { ActivityDocumentTextArea } from './ActivityDocumentTextArea'
import { dateService } from '../../../../../../../../../../../services/timeService'
import { ActivityDocumentActions } from './ActivityDocumentActions'
import { getAlerts, markAsReadChatMessage } from '../../../../../../../../../../CommunicationHub/epics'
import { IMessage } from '../../../../../../../../../../../store/reducers/communicationHub/interfaces'
import { isOwnChatMessage } from '../../../../../../../../../../../services/functions/test/isOwnChatMessage'
import { isMessageReadByUser } from '../../../../../../../../../../../services/functions/test/isMessageRead'
import { showErrorModalWindow } from '../../../../../../../../../../../store/reducers/modalWindow/functions'

type Props = {
  isActivityEditable: boolean
  businessActivity: TransportationActivityViewDTO
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  documentType: FileCategory.POD | FileCategory.TIR | undefined
  document: IAttachment | undefined
  documentMessage: IMessage | undefined
  activityDocumentPopupState: IActivityDocumentPopup
  updateActivityDocumentPopupState: (state: IActivityDocumentPopup) => void
  onCompletionDateUpdate: (completionActualDate: string) => void
}

export const ActivityDocument = (props: Props) => {
  const {
    isActivityEditable,
    businessActivity,
    documentType,
    document,
    documentMessage,
    dispatchDeliveryOrder,
    activityDocumentPopupState,
    updateActivityDocumentPopupState,
    onCompletionDateUpdate
  } = props
  const [fetching, setFetching] = React.useState(false)
  const activityDocumentPopupStateRef = React.useRef(activityDocumentPopupState)
  activityDocumentPopupStateRef.current = activityDocumentPopupState
  const {
    view,
    activityId,
    completionActualDate,
    uploadingFile,
    uploadedDocumentData,
    troubleTicketId,
    troubleTicket,
    troubleTicketDescription,
    troubleTicketNote,
    alertMessage,
    validatedManually
  } = activityDocumentPopupState
  const isTroubleTicket = Boolean(troubleTicket || view === ActivityDocumentPopupView.CREATETROUBLETICKET)
  let attachment: IAttachment = undefined
  let validationErrorMessages: string[] = undefined
  let activityDocumentStatus = ActivityDocumentStatus.NONE

  if (uploadingFile) {
    activityDocumentStatus = ActivityDocumentStatus.UPLOADING
    attachment = uploadingFile
  }
  if (uploadedDocumentData) {
    attachment = uploadedDocumentData.file
    validationErrorMessages = oc(uploadedDocumentData).validation.messages()
    activityDocumentStatus =
      oc(uploadedDocumentData).validation.status() === DocumentValidationDTO.StatusEnum.ACCEPTED
        ? ActivityDocumentStatus.VERIFICATIONACCEPTED
        : ActivityDocumentStatus.VERIFICATIONFAILD

    if (validatedManually) {
      activityDocumentStatus = ActivityDocumentStatus.VALIDATEDMANUALLY
    }
  } else if (troubleTicket) {
    if (oc(troubleTicket).files[0]()) {
      attachment = oc(troubleTicket).files[0]()
      validationErrorMessages = oc(troubleTicket).validation.messages()

      if (attachment) {
        if (validatedManually) {
          activityDocumentStatus = ActivityDocumentStatus.VALIDATEDMANUALLY
        } else if (oc(troubleTicket).validation.status() === DocumentValidationDTO.StatusEnum.ACCEPTED) {
          activityDocumentStatus = ActivityDocumentStatus.VERIFICATIONACCEPTED
        } else {
          activityDocumentStatus = ActivityDocumentStatus.VERIFICATIONFAILD
        }
      }
    }
  } else if (document && activityDocumentStatus !== ActivityDocumentStatus.UPLOADING) {
    attachment = document

    // @ts-ignore
    activityDocumentStatus = oc(documentMessage).metadata.validation.validatedManually()
      ? ActivityDocumentStatus.VALIDATEDMANUALLY
      : ActivityDocumentStatus.VERIFICATIONACCEPTED
  }

  React.useEffect(() => {
    if (troubleTicketId) {
      setFetching(true)
      callAPIWithErrorMessage(troubleTicketDocumentAPI.findById, troubleTicketId)
        .then((troubleTicketData: TroubleTicketDTO) => {
          getAlerts({ channelId: dispatchDeliveryOrder.id })
            .then(messages => {
              const message = (messages || []).find(m => oc(m).metadata.troubleTicketId() === troubleTicketId)

              if (message) {
                updateActivityDocumentPopupState({
                  ...activityDocumentPopupStateRef.current,
                  // @ts-ignore
                  validatedManually: oc(message).metadata.validation.validatedManually(),
                  troubleTicket: {
                    ...troubleTicketData,
                    files: message.attachments,
                    // @ts-ignore
                    metadata: oc(message).metadata.documentMetadata(),
                    // @ts-ignore
                    validation: oc(message).metadata.validation()
                  },
                  alertMessage: message
                })

                // if (!isOwnChatMessage(message) && !isMessageReadByUser(message)) {
                //   markAsReadChatMessage(message.id)
                // }
              }
            })
            .finally(() => setFetching(false))
        })
        .catch(() => setFetching(false))
    }
  }, [troubleTicketId])

  const onClosePopupClick = () => {
    if (
      activityDocumentStatus === ActivityDocumentStatus.UPLOADING ||
      uploadedDocumentData ||
      (view === ActivityDocumentPopupView.CREATEDOCUMENT &&
        businessActivity.completionActualDate !== activityDocumentPopupStateRef.current.completionActualDate)
    ) {
      showErrorModalWindow({
        width: 350,
        title: 'Discard unsaved changes?',
        buttons: [
          {
            label: 'No'
          },
          {
            label: 'Yes',
            onClick() {
              updateActivityDocumentPopupState(undefined)
            }
          }
        ]
      })
    } else {
      updateActivityDocumentPopupState(undefined)
    }
  }

  return (
    <Overlay style={{ zIndex: 2 }}>
      <Popup style={{ width: 430 }}>
        {fetching && <GridSpinner />}
        <ActivityDocumentHeader
          view={view}
          isTroubleTicket={isTroubleTicket}
          documentType={oc(troubleTicket).type() || documentType}
          onClosePopupClick={onClosePopupClick}
        />

        {view === ActivityDocumentPopupView.INFO && troubleTicket && (
          <>
            {troubleTicket.description && (
              <ActivityTroubleTicketDocumentDescription
                hasAttachment={Boolean(attachment)}
                description={troubleTicket.description}
              />
            )}
            {attachment && (
              <>
                <Attachments
                  attachments={troubleTicket.files}
                  numberOfElementsPerLine={2}
                  style={{ marginBottom: 15 }}
                />
                <BreakLine />
              </>
            )}
          </>
        )}

        {((view === ActivityDocumentPopupView.INFO && !troubleTicket) ||
          view === ActivityDocumentPopupView.CREATEDOCUMENT ||
          view === ActivityDocumentPopupView.CREATETROUBLETICKET ||
          view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) && (
          <ActivityDocumentFileUploader
            view={view}
            isActivityEditable={isActivityEditable}
            isTroubleTicket={isTroubleTicket}
            activityDocumentStatus={activityDocumentStatus}
            documentType={documentType}
            uploadingFileUrl={oc(uploadingFile).url()}
            file={attachment}
            validationErrorMessages={validationErrorMessages}
            metadata={{
              activityId,
              // @ts-ignore
              activityType: businessActivity.type,
              dispatchDeliveryOrderId: dispatchDeliveryOrder.id,
              dispatchDeliveryOrderNumber: dispatchDeliveryOrder.number,
              containerNumber: oc(dispatchDeliveryOrder).container.number(),
              chassisNumber: oc(dispatchDeliveryOrder).equipment.chassisNumber(),
              bookingNumber: oc(dispatchDeliveryOrder).deliveryOrder.bookingNumber(),
              billOfLadingNumber: oc(dispatchDeliveryOrder).deliveryOrder.billOfLadingNumber(),
              referenceNumber: oc(dispatchDeliveryOrder).deliveryOrder.cargo.referenceNumber()
            }}
            updateActivityDocumentState={(options: any) => {
              updateActivityDocumentPopupState({
                ...activityDocumentPopupStateRef.current,
                ...(options || {})
              })
            }}
            onValidateManually={() =>
              updateActivityDocumentPopupState({
                ...activityDocumentPopupStateRef.current,
                validatedManually: true
              })
            }
          />
        )}

        {(view === ActivityDocumentPopupView.CREATETROUBLETICKET ||
          view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL ||
          view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE ||
          view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED) && (
          <ActivityDocumentTextArea
            label={view === ActivityDocumentPopupView.CREATETROUBLETICKET ? 'Description' : 'Note'}
            placeholder={
              view === ActivityDocumentPopupView.CREATETROUBLETICKET
                ? 'Enter your description here'
                : 'Enter your note here'
            }
            offset={{
              top:
                view === ActivityDocumentPopupView.RESOLVETROUBLETICKETUNSUCCESSFUL ||
                view === ActivityDocumentPopupView.RESOLVETROUBLETICKETPROCEED
                  ? undefined
                  : 20,
              bottom: 12
            }}
            text={view === ActivityDocumentPopupView.CREATETROUBLETICKET ? troubleTicketDescription : troubleTicketNote}
            onTextUpdate={text => {
              updateActivityDocumentPopupState({
                ...activityDocumentPopupStateRef.current,
                ...(view === ActivityDocumentPopupView.CREATETROUBLETICKET
                  ? { troubleTicketDescription: text }
                  : { troubleTicketNote: text })
              })
            }}
            textareaHeight={view === ActivityDocumentPopupView.CREATETROUBLETICKET ? 155 : 100}
          />
        )}

        {((view === ActivityDocumentPopupView.INFO && !troubleTicket) ||
          view === ActivityDocumentPopupView.CREATEDOCUMENT ||
          view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE) && (
          <ActivityDocumentComplitionDate
            isEditable={isActivityEditable || view === ActivityDocumentPopupView.RESOLVETROUBLETICKETVALIDATE}
            completionActualDate={completionActualDate}
            onCompletionDateUpdate={date => {
              updateActivityDocumentPopupState({
                ...activityDocumentPopupStateRef.current,
                completionActualDate: date
              })
            }}
          />
        )}

        {troubleTicket && troubleTicket.date && (
          <>
            <Title style={{ paddingTop: 20 }}>Created by</Title>
            <CreatedBy className={'mdi mdi-account-circle'}>
              {troubleTicket.createdBy}
              <DateTime>{dateService.makeLabel(troubleTicket.date)}</DateTime>
            </CreatedBy>
          </>
        )}

        {!(view === ActivityDocumentPopupView.INFO && !troubleTicket) && (
          <ActivityDocumentActions
            view={view}
            attachment={attachment}
            troubleTicket={troubleTicket}
            businessActivity={businessActivity}
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            documentType={documentType}
            activityDocumentStatus={activityDocumentStatus}
            document={document}
            documentMessage={documentMessage}
            activityDocumentPopupState={activityDocumentPopupState}
            updateActivityDocumentPopupState={updateActivityDocumentPopupState}
            onCompletionDateUpdate={onCompletionDateUpdate}
            onClosePopupClick={onClosePopupClick}
            setFetching={setFetching}
          />
        )}
      </Popup>
    </Overlay>
  )
}

const BreakLine = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
`
const CreatedBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
  color: #445366;

  &:before {
    opacity: 0.7;
    font-size: 20px;
    margin-right: 5px;
  }
`
const DateTime = styled.span`
  opacity: 0.7;
  margin-left: 20px;
`
