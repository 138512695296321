import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import {
  CargoDTO,
  DeliveryOrderViewDTO,
  HazmatDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import { Window } from './preview'
import { Popover } from './popover'
import { schemaCargo } from '../../../../../services/DTO/deliveryOrder'

type OwnProps = {
  disabled?: boolean
  reefer: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDispatchDeliveryOrder: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => void
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  updateHazmat: (hazmat: HazmatDTO) => void
  updateCargo: (fieldName: keyof CargoDTO) => (value: string) => void
}

type Props = OwnProps

export type CargoType = {
  description: string
  weight: React.ReactText
  weightUnit: string | DispatchDeliveryOrderViewDTO.WeightUnitEnum
  referenceNumber: string
  overweight: boolean
}

const Cargo: React.SFC<Props> = ({
  disabled,
  reefer,
  dispatchDeliveryOrder,
  updateDispatchDeliveryOrder,
  updateDispatchDeliveryOrderMultipleFields,
  updateHazmat,
  updateCargo
}) => {
  const cargo = {
    description: oc(dispatchDeliveryOrder).deliveryOrder.cargo.description(''),
    weight: dispatchDeliveryOrder.weight || '',
    weightUnit: dispatchDeliveryOrder.weightUnit || '',
    referenceNumber: oc(dispatchDeliveryOrder).deliveryOrder.cargo.referenceNumber(''),
    overweight: dispatchDeliveryOrder.overweightIndicator
  }

  return (
    <Widget
      title={'Cargo'}
      highlighted={
        !schemaCargo.isValidSync(cargo) || (dispatchDeliveryOrder.hazmatIndicator && !dispatchDeliveryOrder.hazmat)
      }
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        cargo
      }}
      detailsProps={{
        disabled,
        reefer,
        cargo,
        updateCargo,
        dispatchDeliveryOrder,
        updateDispatchDeliveryOrder,
        updateDispatchDeliveryOrderMultipleFields,
        updateHazmat
      }}
    />
  )
}

export default Cargo
