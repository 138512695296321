import * as React from 'react'
import { BoldLine, Text, Title } from '../../../styles'
import styled from 'styled-components'
import cn from 'classnames'
import { transformMoney } from '../../../../Input'

export const Window: React.FC<{
  small?: boolean
  sellSideQuoteTotalAmount: number
  buySideQuoteTotalAmount: number
  profit: number
}> = ({ small, sellSideQuoteTotalAmount, buySideQuoteTotalAmount, profit }) => {
  return (
    <SPreview className={cn({ small })}>
      <SColumn>
        <Title rows={2} margin={{ bottom: small ? 4 : 10 }}>
          SSQ
        </Title>
        <div style={small ? { display: 'flex' } : undefined}>
          <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
            {transformMoney(sellSideQuoteTotalAmount)}
          </BoldLine>
          <Text className={'opacity uppercase'} rows={3} alignCenter={true}>
            {transformMoney(profit)}
          </Text>
        </div>
      </SColumn>
      <SColumn className={small ? undefined : 'with-border'}>
        <Title rows={2} margin={{ bottom: small ? 4 : 10 }}>
          BSQ
        </Title>
        <div style={small ? { display: 'flex' } : undefined}>
          <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
            {transformMoney(buySideQuoteTotalAmount)}
          </BoldLine>
        </div>
      </SColumn>
    </SPreview>
  )
}

const SColumn = styled.div`
  width: 50%;

  &.with-border {
    position: relative;
    padding-left: 15px;

    &:before {
      content: '';
      display: block;
      width: 1px;
      background-color: #e5e5e5;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
`
const SPreview = styled.div`
  display: flex;

  &.small {
    ${BoldLine} {
      display: inline-flex;
    }

    ${Text} {
      display: inline-block;
      font-size: 14px;
      margin-left: 8px;
    }
  }
`
