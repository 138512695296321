import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  setTabViewingObject,
  setTabViewingObjectTemporaryData,
  setTabViewingObjectTemporaryDataProps,
  deleteTabViewingObject,
  deleteTabsViewingObject,
  getViewingObjectFromSessionStorage,
  modifyTabViewingObject,
  setTabViewingObjectData
} from '../actions'
import * as R from 'remeda'
import { ViewingObjectState } from '../interfaces'
import { oc } from 'ts-optchain'

const defaultState: ViewingObjectState = {}

export const viewingObjectsReducer = reducerWithInitialState(defaultState)
  .case(getViewingObjectFromSessionStorage, (state, viewingObjects) => {
    return viewingObjects || {}
  })
  .case(setTabViewingObject, (state, { tabId, viewingObject }) => {
    return { ...state, [tabId]: viewingObject }
  })
  .case(setTabViewingObjectData, (state, { tabId, data }) => {
    const viewingObject = state[tabId]

    if (!viewingObject) {
      return state
    }

    return { ...state, [tabId]: { ...viewingObject, data } }
  })
  .case(modifyTabViewingObject, (state, props) => {
    const { tabId, modifiedObject, initialObjectState } = props
    const viewingObject = state[tabId]

    if (!viewingObject) {
      return state
    }

    return {
      ...state,
      [tabId]: {
        ...viewingObject,
        modifiedObject,
        ...('initialObjectState' in props
          ? { temporaryData: { ...oc(viewingObject).temporaryData({}), initialObjectState } }
          : {})
      }
    }
  })
  .case(setTabViewingObjectTemporaryData, (state, { tabId, temporaryData }) => {
    const viewingObject = state[tabId]

    if (!viewingObject) {
      return state
    }

    return { ...state, [tabId]: { ...viewingObject, temporaryData } }
  })
  .case(setTabViewingObjectTemporaryDataProps, (state, { tabId, temporaryDataProps }) => {
    const viewingObject = state[tabId]

    if (!viewingObject) {
      return state
    }

    return {
      ...state,
      [tabId]: {
        ...viewingObject,
        temporaryData: { ...oc(viewingObject).temporaryData({}), ...temporaryDataProps }
      }
    }
  })
  .case(deleteTabViewingObject, (state, { tabId }) => R.omit(state, [tabId]))
  .case(deleteTabsViewingObject, (state, { tabIds }) => R.omit(state, tabIds))
  .build()
