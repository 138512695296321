export enum NotificationType {
  alert = 'alert'
}

export type TNotification = {
  id: string
  type: NotificationType
  data: any
}

export type NotificationsState = TNotification[]
