import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'
import { containerLists } from '../../select/containerLists'

export const containerFilterSettings = {
  'Container #': {
    name: 'number',
    type: GridHeaderColumnType.string
  },
  'Active / Availability': {
    name: 'availability',
    type: GridHeaderColumnType.list,
    items: [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]
  },
  Type: {
    name: 'containerType.id',
    path: 'containerTypeId',
    type: GridHeaderColumnType.list,
    get items() {
      return containerLists.containerTypeNamesList
    }
  },
  'Max Cargo Weight': {
    name: 'weight',
    type: GridHeaderColumnType.number
  },
  Ownership: {
    name: 'ownership',
    type: GridHeaderColumnType.list,
    items: containerLists.ownership
  },
  SSL: {
    name: 'steamShipLine.name',
    type: GridHeaderColumnType.string
  }
}
