import styled from 'styled-components'
import theme from '../../../../../../../../../styles/theme'

export const SPreview = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  border-bottom: 1px solid #e5e5e5;
  color: #445366;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.rowHoverColor};
  }
`
export const SIcon = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.UNSUCCESSFUL {
    opacity: 0.7;
  }

  &.PICKUP {
    svg {
      fill: #7c7fcf;
    }
  }

  &.DELIVERY {
    svg {
      fill: #c179d1;
    }
  }

  &.RETURN {
    svg {
      fill: #7ccf81;
    }
  }
`
export const STitle = styled.div`
  min-width: 80px;
  font-weight: 500;
  font-size: 16px;
`
export const SDriver = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-left: 25px;
`
export const GrayText = styled.span`
  color: #bdbdbd;
`
export const SAmount = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
`
export const SOpenMarker = styled.div`
  font-size: 24px;
  color: #bdbdbd;
  margin: 0 10px;
`
export const ButtonDelete = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 100%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.1;
    cursor: default;
  }
`
