import * as React from 'react'
import { ControllerStateAndHelpers } from 'downshift'
import { Title } from '../../../styledComponents'

export const Label: React.FC<{ title?: string; required?: boolean; downshift: ControllerStateAndHelpers<any> }> = ({
  title,
  required,
  downshift: { getLabelProps }
}) =>
  title ? (
    <Title {...getLabelProps()} isRequired={required}>
      {title}
    </Title>
  ) : null
