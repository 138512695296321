import { callAPI, driverAPI, DriverNameDTO, driverReportAPI, callAPIWithErrorMessage } from '../../../../api/api'
import { DriverViewDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { parseDTO } from '../../../../services/DTO/parseDTO'
import { vendorTypes } from '../../../../constants/Vendor'
import { getDispatch } from '../../../../store'
import { listsActions } from '../../../../store/reducers/lists'
import { ListEntityType } from '../../../../store/reducers/lists/interfaces'
import { DriverReportDTO, DriverReportGenerationRequestDTO } from '../../../../api/origin/document-service'
import { dateService } from '../../../../services/timeService'

export const tryAddDriverToStore = (item: DriverViewDTO | DriverViewDTO[]) => {
  const drivers = Array.isArray(item) ? (item.length ? item : undefined) : undefined
  const driver = Array.isArray(item) ? undefined : item

  if (drivers || driver) {
    getDispatch()(
      listsActions.update({
        update: {
          [ListEntityType.driver]: drivers || [driver]
        }
      })
    )
  }
}

export const requestDriverById = (id: string): Promise<DriverViewDTO> => {
  return callAPI(driverAPI.findById, id)
    .toPromise()
    .then(driver => {
      parseDTO.driver(driver)
      tryAddDriverToStore(driver)
      return driver
    })
}

export const requestSimpleDrivers = async ({ sort, filter }: any): Promise<DriverViewDTO[]> => {
  return callAPI(driverAPI.simple, filter, sort)
    .toPromise()
    .then(drivers => {
      parseDTO.driver(drivers)
      return drivers
    })
    .catch(catchListErrors)
}

export const requestDrivers = async ({ sort, filter }: any): Promise<DriverViewDTO[]> => {
  return callAPI(driverAPI.findAll, filter, sort)
    .toPromise()
    .then(drivers => {
      parseDTO.driver(drivers)
      return drivers
    })
    .catch(catchListErrors)
}

export const findAllVendors = async (): Promise<DriverViewDTO[]> => {
  return requestDrivers({
    filter: `driverType%%${vendorTypes.join(',')}`
  })
}

export const findVendorByAuthUserId = async (authUserId: string): Promise<DriverViewDTO> => {
  const vendors = await requestDrivers({
    filter: `driverType%%${vendorTypes.join(',')};authUserId%%${authUserId}`
  })

  if (vendors && vendors.length) {
    return vendors[0]
  }
}

export const findDriversByTerm = async (term: string, filterFunction?: (driver: DriverNameDTO) => boolean) => {
  return callAPI(driverAPI.search, 999, term.trim())
    .toPromise()
    .then(list => {
      if (!filterFunction) {
        return list
      }

      return list.filter(filterFunction)
    })
}

export const createDriverReport = (
  reportType: DriverReportDTO.TypeEnum,
  driver: DriverViewDTO
): Promise<DriverReportDTO> => {
  if (!(driver && driver.authUserId)) {
    return
  }

  const report: DriverReportGenerationRequestDTO = {
    type: reportType,
    firstName: driver.firstName,
    lastName: driver.lastName,
    dateOfBirth: dateService.getStringDay(driver.birthday),
    email: driver.email,
    phone: driver.phone,
    licenseIssueStateId: driver.licenseIssueStateId,
    licenseNumber: driver.licenseNumber
  }

  return callAPIWithErrorMessage(driverReportAPI.generateReport, report, driver.authUserId)
}

export const requestDriverReport = (
  reportType: DriverReportDTO.TypeEnum,
  authUserId: string
): Promise<DriverReportDTO> => {
  // @ts-ignore
  return callAPIWithErrorMessage(driverReportAPI.getReport, reportType, authUserId)
}

export const requestDriverReports = (
  reportType: DriverReportDTO.TypeEnum,
  authUserId: string
): Promise<DriverReportDTO[]> => {
  // @ts-ignore
  return callAPI(driverReportAPI.getReportHistory, reportType, authUserId)
    .toPromise()
    .catch(() => [])
}
