import * as React from 'react'
import {
  DispatchDeliveryOrderViewDTO,
  ContainerViewDTO,
  LocationDTO,
  ConfirmedLocalDateTimeRangeDTO
} from '../../../../../api/api'
import { Column, Container, GridColumn } from '../../../../UI/GridSystem'
import { Input } from '../../../../UI/DataFields/Input'
import { ContainerSearch } from '../../../../UI/DataFields/SearchRequest'
import { FieldTemplate } from '../../../../UI/DataFields/Templates'
import { Checkbox } from '../../../../UI/Checkbox'
import { oc } from 'ts-optchain'
import WeightUnit from '../../../../UI/WeightUnit'
import { DeleteRow, DOStyledRow } from '../../../dispatchDeliveryOrder/views/Details/Activities/Table/styles'
import { isNewObject } from '../../../../../services/DTO'
import { LocationWidget } from '../../../../UI/Widget/widgets/location/simple'
import { ILocation } from '../../../location/interfaces'
import { Select } from '../../../../UI/DataFields/Select'
import cn from 'classnames'
import { alertOnChangingMainStreetTurnPropsOfDDO } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { Hazmat } from '../../../dispatchDeliveryOrder/views/Details/General/Hazmat'
import { HazmatSearch } from '../../../../UI/InputSearch'
import { DeliveryOrderContext } from '../context'
import { DateTimePicker } from '../../../../UI/DataFields/DateTimePicker/views'
import { isDDOStatusCancelled } from '../../../../../services/functions/test/isDDOStatusCancelled'

type OwnProps = {
  showHazmatColumn: boolean
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) => void
  updateDispatchDeliveryOrderMultipleFields: (fields: { [fieldName: string]: any }) => void
  deleteDispatchDeliveryOrder: () => void
  updateContainer: (container: ContainerViewDTO) => void
  updateLocation: (updatedDispatchDeliveryOrder: DispatchDeliveryOrderViewDTO, updatedLocation: LocationDTO) => void
}

type Props = OwnProps

export const DispatchDeliveryOrder = (props: Props) => {
  const {
    showHazmatColumn,
    dispatchDeliveryOrder,
    deleteDispatchDeliveryOrder,
    updateDispatchDeliveryOrder,
    updateDispatchDeliveryOrderMultipleFields,
    updateContainer,
    updateLocation
  } = props

  const {
    state: { deliveryOrder, isModifiedMode, isImport, isExport, isRepo }
  } = React.useContext(DeliveryOrderContext)
  const disallowRemoveRow = !isNewObject(dispatchDeliveryOrder)
  const disabled = isDDOStatusCancelled(dispatchDeliveryOrder.status)
  const updateDispatchDeliveryOrderField = (field: string) => (value: any) =>
    updateDispatchDeliveryOrder({ ...dispatchDeliveryOrder, [field]: value })

  const updatedStageLocation = (stage: 'pickupStage' | 'deliveryStage' | 'returnStage') => (location: ILocation) =>
    updateLocation(
      {
        ...dispatchDeliveryOrder,
        [stage]: { ...dispatchDeliveryOrder[stage], location, locationId: oc(location).id() }
      },
      location
    )

  return (
    <DOStyledRow data-id={'dispatch-delivery-order'} className={disabled ? 'canceled' : ''}>
      {/* Number # */}
      <Column columns={3} alignCenter={true} isFixed={true}>
        <div data-id={'ddo-number'}>{oc(dispatchDeliveryOrder).number('')}</div>
      </Column>
      {/*Equipment Type*/}
      <GridColumn columns={10} isFixed={true} data-id={'ddo-containerType'}>
        <FieldTemplate.ContainerTypeSearch
          disabled={disabled}
          required={true}
          containerType={dispatchDeliveryOrder.containerType}
          onChange={containerType => {
            const updatedDDO = {
              ...dispatchDeliveryOrder,
              containerType,
              containerTypeId: oc(containerType).id(),
              ...(dispatchDeliveryOrder.container
                ? dispatchDeliveryOrder.container.containerTypeId === oc(containerType).id()
                  ? {}
                  : { containerId: null, container: null }
                : {})
            }
            alertOnChangingMainStreetTurnPropsOfDDO(updatedDDO, dispatchDeliveryOrder)
            updateDispatchDeliveryOrder(updatedDDO)
          }}
        />
      </GridColumn>
      {(isRepo || isImport) && (
        <>
          {/* Container */}
          <GridColumn columns={9} isFixed={true} data-id={'ddo-container'}>
            <ContainerSearch
              disabled={disabled}
              allowCreate={true}
              required={isImport}
              value={dispatchDeliveryOrder.container}
              steamShipLineId={oc(deliveryOrder).steamShipLineId(null)}
              containerTypeId={dispatchDeliveryOrder.containerTypeId}
              onChange={updateContainer}
            />
          </GridColumn>
        </>
      )}
      {isImport && (
        <>
          {/* Seal */}
          <GridColumn columns={8} data-id={'ddo-sealNumber'}>
            <Input
              disabled={disabled}
              maxLength={20}
              value={dispatchDeliveryOrder.sealNumber}
              onChange={updateDispatchDeliveryOrderField('sealNumber')}
            />
          </GridColumn>
        </>
      )}
      {/*Pickup*/}
      <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-pickupStage-location'}>
        <LocationWidget
          isExternal={true}
          isShortName={true}
          isModifiedMode={isModifiedMode}
          disabled={disabled}
          location={dispatchDeliveryOrder.pickupStage.location as ILocation}
          changeLocation={updatedStageLocation('pickupStage')}
          updateLocation={updatedStageLocation('pickupStage')}
        />
      </GridColumn>
      {/*Delivery*/}
      {(isImport || isExport) && (
        <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-deliveryStage-location'}>
          <LocationWidget
            isExternal={true}
            isShortName={true}
            isModifiedMode={isModifiedMode}
            disabled={disabled}
            location={oc(dispatchDeliveryOrder).deliveryStage.location() as ILocation}
            changeLocation={updatedStageLocation('deliveryStage')}
            updateLocation={updatedStageLocation('deliveryStage')}
          />
        </GridColumn>
      )}
      {/*Return*/}
      <GridColumn columns={isExport ? 10 : 8} data-id={'ddo-returnStage-location'}>
        <LocationWidget
          isExternal={true}
          isShortName={true}
          isModifiedMode={isModifiedMode}
          disabled={disabled}
          location={dispatchDeliveryOrder.returnStage.location as ILocation}
          changeLocation={updatedStageLocation('returnStage')}
          updateLocation={updatedStageLocation('returnStage')}
        />
      </GridColumn>
      {/*Appointment Date*/}
      {(isImport || isExport) && (
        <GridColumn columns={11} isFixed={true} data-id={'ddo-deliveryStage-plannedAppointmentDateTimeRange'}>
          <DateTimePicker
            disabled={disabled}
            date={oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()}
            isRange={true}
            onChange={plannedAppointmentDateTimeRange => {
              const date = plannedAppointmentDateTimeRange as ConfirmedLocalDateTimeRangeDTO

              if (date && !oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange()) {
                date.confirmed = true
              }

              return updateDispatchDeliveryOrder({
                ...dispatchDeliveryOrder,
                deliveryStage: {
                  ...oc(dispatchDeliveryOrder).deliveryStage({}),
                  plannedAppointmentDateTimeRange: date
                }
              })
            }}
          />
        </GridColumn>
      )}
      {/*Cargo*/}
      <GridColumn columns={6} isFixed={true} data-id={'ddo-cargo'}>
        <Select
          multiselect={true}
          selectedValue={[
            dispatchDeliveryOrder.autoIndicator ? 'autoIndicator' : null,
            dispatchDeliveryOrder.hazmatIndicator ? 'hazmatIndicator' : null
          ].filter(Boolean)}
          list={[
            {
              label: 'Auto',
              value: 'autoIndicator'
            },
            {
              label: 'Hazmat',
              value: 'hazmatIndicator'
            }
          ]}
          onSelect={values => {
            const isAutoSelected = oc(values)([]).includes('autoIndicator')
            const isHazmatSelected = oc(values)([]).includes('hazmatIndicator')

            updateDispatchDeliveryOrder({
              ...dispatchDeliveryOrder,
              autoIndicator: isAutoSelected,
              hazmatIndicator: isHazmatSelected,
              hazmat: isHazmatSelected ? dispatchDeliveryOrder.hazmat : undefined,
              hazmatId: isHazmatSelected ? dispatchDeliveryOrder.hazmatId : undefined
            })
          }}
        />
      </GridColumn>
      {/*Hazmat*/}
      {showHazmatColumn && (
        <GridColumn className={'hazmat-column'} columns={10} isFixed={true} data-id={'ddo-hazmat'}>
          {dispatchDeliveryOrder.hazmatIndicator && (
            <HazmatSearch
              disabled={disabled}
              required={true}
              title="Hazmat Description"
              placeholder="Search by UN Code, Class of Description.."
              hazmat={dispatchDeliveryOrder.hazmat}
              onChange={hazmat =>
                updateDispatchDeliveryOrder({ ...dispatchDeliveryOrder, hazmatId: oc(hazmat).id(), hazmat })
              }
            />
          )}
        </GridColumn>
      )}
      {/*Weight*/}
      <GridColumn columns={9} isFixed={true} data-id={'ddo-weight'}>
        <Container rows={7}>
          <WeightUnit
            disabled={disabled}
            weightUnit={dispatchDeliveryOrder.weightUnit}
            weight={dispatchDeliveryOrder.weight}
            onChange={updateDispatchDeliveryOrderMultipleFields}
          />
        </Container>
      </GridColumn>
      {/* OW */}
      <GridColumn
        style={{ borderColor: 'transparent', paddingLeft: 0 }}
        columns={3}
        isFixed={true}
        alignCenter={true}
        data-id={'ddo-overweightIndicator'}
      >
        <Checkbox
          checked={dispatchDeliveryOrder.overweightIndicator}
          onChange={updateDispatchDeliveryOrderField('overweightIndicator')}
        />
        OW
      </GridColumn>

      <DeleteRow
        data-id={'ddo-button-delete'}
        className={cn('mdi mdi-delete', { disabled: disallowRemoveRow })}
        onClick={() => !disallowRemoveRow && deleteDispatchDeliveryOrder()}
      />
    </DOStyledRow>
  )
}
