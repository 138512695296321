import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { OngoingActivityGroupDTO } from '../../../../api/api'
import { SchedulerSortBy } from '../interfaces'
import { groupTitleWithoutDataMapping } from './generateGroupTitleWithoutData'
import { IDriver } from '../../drivers/interfaces'

export type TSortOngoingActivityGroups = {
  sortedListWithTargetData: Record<string, OngoingActivityGroupDTO[]>
  sortedListWithoutTargetData: OngoingActivityGroupDTO[]
}

export const sortOngoingActivityGroups = (sortBy: SchedulerSortBy, driverMapping: Record<string, IDriver>) => (
  ongoingActivityGroups: OngoingActivityGroupDTO[]
): TSortOngoingActivityGroups => {
  const defaultGroupTitleWithoutData = groupTitleWithoutDataMapping[sortBy] || 'Other'
  switch (sortBy) {
    case SchedulerSortBy.deliveryLocationCity: {
      const result: Record<string, OngoingActivityGroupDTO[]> = {}
      const groupItems: Record<string, Record<string, OngoingActivityGroupDTO[]>> = ongoingActivityGroups.reduce(
        (acc, curr) => {
          const city = oc(curr).deliveryActivity.destination.city(defaultGroupTitleWithoutData)
          const state = oc(curr).deliveryActivity.destination.state('')

          if (!acc[city]) {
            acc[city] = {}
          }

          if (!acc[city][state]) {
            acc[city][state] = []
          }

          acc[city][state].push(curr)

          return acc
        },
        {}
      )

      Object.keys(groupItems).forEach(city => {
        Object.keys(groupItems[city])
          .sort((stateA, stateB) => stateA.localeCompare(stateB))
          .forEach(state => {
            const key = [city, state].filter(Boolean).join(', ')
            result[key] = groupItems[city][state].sort(
              (a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate)
            )
          })
      })

      return {
        sortedListWithTargetData: R.omit(result, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: result[defaultGroupTitleWithoutData] || []
      }
    }
    case SchedulerSortBy.deliveryStageDriver: {
      const groupItems: Record<string, OngoingActivityGroupDTO[]> = ongoingActivityGroups.reduce((acc, curr) => {
        const driverName =
          oc(driverMapping[oc(curr).deliveryActivity.driver.id()]).name() || defaultGroupTitleWithoutData

        if (!acc[driverName]) {
          acc[driverName] = []
        }

        acc[driverName].push(curr)

        return acc
      }, {})

      Object.keys(groupItems).forEach(driver => {
        groupItems[driver].sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
      })

      return {
        sortedListWithTargetData: R.omit(groupItems, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: groupItems[defaultGroupTitleWithoutData] || []
      }
    }
    case SchedulerSortBy.driverAllStages: {
      const groupItems: Record<string, OngoingActivityGroupDTO[]> = ongoingActivityGroups.reduce(
        (acc, ongoingActivity) => {
          const driverName = ongoingActivity.activityGroupDriver

          if (!acc[driverName]) {
            acc[driverName] = []
          }

          acc[driverName].push(ongoingActivity)

          return acc
        },
        {}
      )

      Object.keys(groupItems).forEach(driver => {
        groupItems[driver].sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
      })

      return {
        sortedListWithTargetData: R.omit(groupItems, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: groupItems[defaultGroupTitleWithoutData] || []
      }
    }
    default:
      return {
        sortedListWithTargetData: {},
        sortedListWithoutTargetData: []
      }
  }
}
