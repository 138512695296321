import { getTextWithMentionIds } from './mentions'
import { oc } from 'ts-optchain'
import { v4 } from 'uuid'
import { convertToRaw, EditorState } from 'draft-js'
import { getStore } from '../../../store/configureStore'
import { IAttachment } from '../../../services/filesService/interfaces'
import { MessageType } from '../../../api/origin/communication-hub-service'
import { IMessage } from '../../../store/reducers/communicationHub/interfaces'
import { getUserState } from '../../../store'

type Props = {
  channelId: string
  type?: MessageType
  editorState?: EditorState
  attachments: IAttachment[]
  prevMessageState?: IMessage
}

export const generateChannelMessage = (props: Props): IMessage => {
  try {
    const { channelId, type, prevMessageState, editorState, attachments } = props
    const { text, mentionUserIds } = getTextWithMentionIds(
      convertToRaw((editorState || EditorState.createEmpty()).getCurrentContent())
    )
    const messageId = !prevMessageState ? v4() : prevMessageState.id

    let addMentionUserIds: string[] = []
    let removeMentionUserIds: string[] = []

    if (prevMessageState) {
      const prevMentionUserIds = oc(prevMessageState).mentionUserIds([])
      addMentionUserIds = mentionUserIds.filter(id => !prevMentionUserIds.includes(id))
      removeMentionUserIds = prevMentionUserIds.filter(id => !mentionUserIds.includes(id))
    } else if (mentionUserIds && mentionUserIds.length) {
      addMentionUserIds = mentionUserIds
    }

    return {
      ...(prevMessageState || {}),
      id: messageId,
      senderId: getUserState().id,
      type,
      text,
      attachments,
      mentionUserIds,
      updatedAt: undefined,
      channelId,
      metadata: prevMessageState
        ? prevMessageState.metadata
        : {
            ddo: {
              id: channelId,
              number: oc(getStore().getState()).dispatchDeliveryOrder[channelId].number()
            }
          },
      externalData: {
        localMessageId: !prevMessageState ? messageId : undefined,
        addMentionUserIds: addMentionUserIds && addMentionUserIds.length ? addMentionUserIds : undefined,
        removeMentionUserIds: removeMentionUserIds && removeMentionUserIds.length ? removeMentionUserIds : undefined
      }
    } as IMessage
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e)

    return null
  }
}
