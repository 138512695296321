import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { setNotificationsState, pushNotification, updateNotification, removeNotification } from '../actions'
import { NotificationsState } from '../interfaces'

const notificationsState: NotificationsState = []

export const notificationsReducer = reducerWithInitialState<NotificationsState>(notificationsState)
  .case(setNotificationsState, (store, state) => state)
  .case(pushNotification, (store, notification) => {
    return store.filter(_ => _.id !== notification.id).concat(notification)
  })
  .case(updateNotification, (store, notification) => {
    return store.filter(_ => _.id !== notification.id).concat(notification)
  })
  .case(removeNotification, (store, { id }) => store.filter(_ => _.id !== id))
  .build()
