import * as React from 'react'
import { StyledWeightContainer, StyledWeightUnit } from './styles'
import { DispatchDeliveryOrderViewDTO, PowerUnitRegistrationDTO } from '../../../api/origin/business-logic'
import { InputField } from '../DataFields/Input'
import { weightMask } from '../../../services/formService/formMasks'

export const WeightUnitEnum = {
  [PowerUnitRegistrationDTO.WeightUnitEnum.KGS]: 'kg',
  [PowerUnitRegistrationDTO.WeightUnitEnum.LBS]: 'lb'
}

type OwnProps = {
  onChange: (fields: { [fieldName: string]: any }) => void
  weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum | undefined | null
  weight: React.ReactText
  title?: string
  disabled?: boolean
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const kgLbFactor = 2.205

const convertUnit = (
  weight: React.ReactText,
  weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum | undefined | null,
  onChange: (fields: { [fieldName: string]: any }) => void
) => {
  if (weightUnit === PowerUnitRegistrationDTO.WeightUnitEnum.KGS) {
    if (typeof weight === 'string') {
      weight = weight.replace(',', '')
    }

    return onChange({
      weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum.LBS,
      weight: Math.round(Number(weight) * kgLbFactor).toString()
    })
  }
  if (weightUnit === PowerUnitRegistrationDTO.WeightUnitEnum.LBS) {
    if (typeof weight === 'string') {
      weight = weight.replace(',', '')
    }
    return onChange({
      weightUnit: PowerUnitRegistrationDTO.WeightUnitEnum.KGS,
      weight: Math.round(Number(weight) / kgLbFactor).toString()
    })
  }
}

const WeightUnit: React.SFC<Props> = ({ weight, weightUnit, onChange, title, disabled }) => {
  const selectedUnit = String(weightUnit)

  const changeUnit = () => {
    const unit = Object.keys(WeightUnitEnum).find((item: string) => item !== selectedUnit)
    return onChange({ weightUnit: unit })
  }
  return (
    <StyledWeightContainer>
      <InputField.ThousandSeparator
        disabled={disabled}
        title={title}
        maxLength={11}
        placeholder={'00000'}
        value={weight}
        onChange={value => onChange({ weight: value })}
      />
      {weightUnit ? (
        <StyledWeightUnit data-id={'ddo-weightUnit'} columns={1} rows={7} justifyCenter={true} onClick={changeUnit}>
          <div className={WeightUnitEnum[selectedUnit]}>
            {Object.keys(WeightUnitEnum).map((unit: string) => (
              <span key={unit}>{WeightUnitEnum[unit]}</span>
            ))}
          </div>
        </StyledWeightUnit>
      ) : null}
      <i
        className={`mdi mdi-arrow-left-right ${!weight ? 'not-active' : ''}`}
        title="convert"
        onClick={() => weight && convertUnit(weight, weightUnit, onChange)}
      />
    </StyledWeightContainer>
  )
}

export default WeightUnit
