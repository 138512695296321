import { RootState } from '..'
import { UserDTO } from '../../api/origin/user-service'

// SELECT ONE
export const selectContact = (id: string) => (state: RootState): UserDTO | undefined => {
  return state.lists.contact[id]
}

// SELECT MANY
export const selectContacts = (state: RootState): Record<string, UserDTO> => {
  return state.lists.contact
}
