import * as React from 'react'
import { DispatchDeliveryOrderTabContent } from '../styles'
import { Table } from './Table'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

const extraContainerStyles = {
  padding: '24px 0 0'
}

export const AuditTrail: React.SFC<{
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  actions: IGridItemActions
}> = props => {
  return (
    <DispatchDeliveryOrderTabContent style={extraContainerStyles}>
      <Table ddoId={props.dispatchDeliveryOrder.id} />
    </DispatchDeliveryOrderTabContent>
  )
}
