import { callAPI, LocationViewDTO, locationAPI, LocationGridItemDTO, LocationDTO } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { catchListErrors } from '../../../../services/tabs/functions'
import { addLocationsToStore } from '../action'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'
import { createFilterRequest } from '../../../../services/uiSettingsService/filter'

export const tryAddLocationToStore = (
  items: LocationViewDTO | LocationGridItemDTO | LocationViewDTO[] | LocationGridItemDTO[]
) => {
  correctInsertionObjectsIntoStorage(getStore().getState().location, items, addLocationsToStore)
}

export const requestLocationById = (id: string): Promise<LocationViewDTO> =>
  callAPI(locationAPI.findById, id)
    .toPromise()
    .then(location => {
      parseDTO.location(location)
      return location
    })

export const requestContainerYardLocations = async () => {
  return requestAllGridLocations({
    filter: createFilterRequest([{ column: 'type', value: LocationDTO.TypeEnum.CONTAINERYARD.toString() }])
  })
}

export const requestAllGridLocations = async ({ sort, filter }: any): Promise<LocationViewDTO[]> => {
  return callAPI(locationAPI.findAll, filter, sort)
    .toPromise()
    .then(locations => {
      parseDTO.location(locations)
      return locations as LocationViewDTO[]
    })
    .catch(catchListErrors)
}
