import * as React from 'react'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { connect } from 'react-redux'
import { Grid, GridCellProps } from 'react-virtualized'
import { TabActionType, TabType, TGridColumns } from '../../../tabs/interfaces'
import { TableRow } from '../styles'
import { ColumnTitle } from '../interfaces'
import { tabSessionStorage } from '../../../../../services/tabs/functions'
import { checkUpdatedGridItem } from '../../../../pages/GridItem/functions'
import { useApplicationSize } from '../../../../../hooks/useApplicationSize'
import { useGrid } from '../../../../../hooks/useGrid'
import { IStore } from '../../../../../store/store.interface'
import { NotificationsState, NotificationType } from '../../../../UI/Notification/interfaces'
import { TroubleTicketDTO } from '../../../../../api/origin/business-logic'
import { GridItem } from '../../../../pages/GridItem/GridItem'

type OwnProps = {
  tabType: TabType
  columnsSettings: TGridColumns
  viewingObjectId: string
  isModifiedMode: boolean
  gridItemIds: string[]
}

type StateProps = {
  notifications: NotificationsState | undefined
}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { columnsSettings, viewingObjectId, isModifiedMode, gridItemIds, notifications } = props
  const { applicationWidth, resize } = useApplicationSize()
  const {
    tabId,
    tabData,
    actionsType,
    tabType,
    ignoreWS,
    listRequestType,
    fetchGridItems,
    selectedIds,
    pinnedIds,
    disabledSelectIds
  } = useGrid()
  const [scrollPosition, setScrollPosition] = React.useState<{ top: number; left: number }>(
    tabSessionStorage.scrollPosition.getTabPosition(tabId)
  )
  const scrollLeftPosition = React.useRef(null)
  const hideUnselectedGridItems = oc(tabData).hideUnselectedGridItems(false)

  React.useEffect(() => {
    scrollGridItems(scrollPosition.left || 0)
    setScrollPosition({ top: undefined, left: undefined })
  }, [])

  const onScroll = React.useCallback(({ scrollLeft, scrollTop }: { scrollLeft: number; scrollTop: number }) => {
    if (scrollLeftPosition.current !== scrollLeft) {
      scrollLeftPosition.current = scrollLeft
      scrollGridItems(scrollLeft)
    }

    tabSessionStorage.scrollPosition.setTabPosition(tabId, {
      top: scrollTop,
      left: scrollLeft
    })
  }, [])

  const handleGridItemVersion = React.useCallback(
    (unit: any) => {
      checkUpdatedGridItem({
        tabId,
        listRequestType,
        tabType,
        unit,
        fetchGridItems
      })
    },
    [fetchGridItems, listRequestType, tabType]
  )

  const { renderPinnedIds, renderGridIds } = React.useMemo(() => {
    let _pinnedIds = pinnedIds
    const result: {
      renderPinnedIds: string[]
      renderGridIds: string[]
    } = {
      renderPinnedIds: [],
      renderGridIds: []
    }

    if (actionsType === TabActionType.streetTurn) {
      result.renderPinnedIds = pinnedIds || []
      result.renderGridIds = (gridItemIds || []).filter(id => !(pinnedIds || []).includes(id))

      return result
    }

    if (tabType === TabType.dispatchDeliveryOrder && !ignoreWS && notifications) {
      _pinnedIds = []

      notifications.forEach(notification => {
        if (
          notification.type === NotificationType.alert &&
          oc(notification).data.status() === TroubleTicketDTO.StatusEnum.NEW &&
          oc(notification).data.metadata.ddo.id()
        ) {
          _pinnedIds.push(notification.data.metadata.ddo.id)
        }
      })
    }

    if (gridItemIds) {
      gridItemIds.forEach(id => {
        if (id === viewingObjectId) {
          return
        } else if (_pinnedIds.includes(id)) {
          result.renderPinnedIds.push(id)
        } else if (selectedIds.includes(id)) {
          return
        } else {
          result.renderGridIds.push(id)
        }
      })
    }

    return result
  }, [pinnedIds, viewingObjectId, gridItemIds, oc(notifications).length(0)])

  const gridHeight = React.useMemo(() => {
    let height = window.innerHeight - 115

    if (viewingObjectId) {
      height -= viewingObjectHeight[tabType]
    }
    if (actionsType) {
      height -= 48
    }
    if (renderPinnedIds.length) {
      height -= renderPinnedIds.length * 40
    }

    return height
  }, [viewingObjectId, renderPinnedIds, resize])

  const totalGridWidth = React.useMemo(() => Math.max(calcRowWidth(columnsSettings), window.innerWidth), [
    columnsSettings
  ])

  const rowRenderer = (rowProps: GridCellProps & { id?: string; className?: string }) => {
    const { key, rowIndex, style, className } = rowProps
    const id = rowProps.id || renderGridIds[rowIndex]

    return (
      <TableRow
        key={key}
        className={cn(
          {
            table__row_dark: rowIndex % 2 === 0,
            table__row_selected: id === viewingObjectId,
            table__row_disabled: isModifiedMode,
            table__row_highlighted: pinnedIds.includes(id)
          },
          'table__row',
          className
        )}
        style={style}
      >
        <React.Fragment key={id}>
          <GridItem
            unitId={id}
            columnsSettings={columnsSettings}
            disable={isModifiedMode}
            tabId={tabId}
            tabType={tabType}
            handleGridItemVersion={handleGridItemVersion}
            isSelected={selectedIds.includes(id)}
            isDisabledSelect={disabledSelectIds.includes(id)}
            isPinned={pinnedIds.includes(id)}
            actionType={actionsType}
          />
        </React.Fragment>
      </TableRow>
    )
  }

  return (
    <>
      {renderPinnedIds.map((id, index) =>
        rowRenderer({
          className: 'scroll_item_with_grid',
          id,
          key: String(index),
          rowIndex: index + 1,
          style: { position: 'relative' },
          columnIndex: null,
          isScrolling: null,
          isVisible: null,
          parent: null
        })
      )}
      {!hideUnselectedGridItems && (
        <Grid
          scrollTop={scrollPosition.top}
          scrollLeft={scrollPosition.left}
          onScroll={onScroll}
          height={gridHeight}
          width={applicationWidth}
          columnCount={1}
          columnWidth={totalGridWidth}
          rowCount={renderGridIds.length}
          rowHeight={40}
          cellRenderer={rowRenderer}
        />
      )}
    </>
  )
}

export const TableBody = connect((store: IStore, { tabType }: OwnProps) => {
  return {
    notifications: tabType === TabType.dispatchDeliveryOrder ? store.notifications : undefined
  }
})(React.memo(Component))

const calcRowWidth = (columns: TGridColumns) => {
  if (!columns) {
    return window.innerWidth
  }

  return Object.values(columns).reduce(
    (acc: number, { width, active }: ColumnTitle) => (active ? acc + width : acc),
    40
  ) as number
}

// 40 (column) + content + 45 (actions)
export const viewingObjectHeight = {
  [TabType.dispatchDeliveryOrder]: 620,
  [TabType.powerUnit]: 435,
  [TabType.equipment]: 435,
  [TabType.container]: 200,
  [TabType.location]: 435,
  [TabType.driver]: 40 + 45 + 405,
  [TabType.customer]: 435,
  [TabType.steamShipLine]: 435,
  [TabType.subClient]: 435
}

export const copyToClipboard = (str: string) => {
  const textarea = document.createElement('textarea')
  textarea.value = str.trim()
  textarea.style.position = 'absolute'
  document.body.append(textarea)
  textarea.select()
  document.execCommand('copy')
  textarea.remove()
}

export const scrollGridItems = (scrollLeft: number) => {
  const scrollItemsWithGrid: HTMLCollectionOf<Element> = document.getElementsByClassName('scroll_item_with_grid')
  const viewingObjectColumnsRefs = document.getElementsByClassName('table__row-columns_expanded')

  const scrollItems = Array.from(scrollItemsWithGrid)
  scrollItems.forEach((element: any) => (element.style.left = `-${scrollLeft}px`))

  if (viewingObjectColumnsRefs[0]) {
    // @ts-ignore
    viewingObjectColumnsRefs[0].style.cssText = `position: relative; left: -${scrollLeft}px`
  }
}
