import * as React from 'react'
import { StyledHazmat } from './styles'
import { HazmatDTO } from '../../../../../../../api/origin/business-logic'
import { HazmatSearch } from '../../../../../../UI/InputSearch'

type Props = {
  disabled?: boolean
  hazmat: HazmatDTO
  updateHazmat: (hazmat: HazmatDTO) => void
}

export const Hazmat: React.FC<Props> = ({ hazmat, updateHazmat, disabled }) => (
  <StyledHazmat rows={20} padding={{ top: 35, left: 9, right: 9 }} margin={{ top: -18, left: -10, right: -10 }}>
    <HazmatSearch
      disabled={disabled}
      required={true}
      title="Hazmat Description"
      placeholder="Search by UN Code, Class of Description.."
      hazmat={hazmat}
      onChange={updateHazmat}
    />
  </StyledHazmat>
)
