import * as React from 'react'
import { connect } from 'react-redux'
import { IStore } from '../store/store.interface'
// @ts-ignore
import { debounce } from 'debounce'
import { ListRequestType, TabActionType, TabType, TTabData } from '../components/common/tabs/interfaces'
import { oc } from 'ts-optchain'
import { GridContext } from '../contexts/GridContext'

type OwnProps = {
  fetchGridItems(withoutSpinner?: boolean): Promise<any>
  setGridFetching(state: boolean): void
  children: any
}

type StateProps = {
  tabId: string
  enableEditing: boolean
  tabData: TTabData
  listRequestProps: any
  tabType: TabType
  ignoreWS: boolean
  actionsType: TabActionType
  listRequestType: ListRequestType
  detailsRequestType: TabType | ListRequestType
}

type Props = OwnProps & StateProps

const Component = (props: Props) => {
  const {
    fetchGridItems,
    setGridFetching,
    children,
    tabId,
    tabType,
    ignoreWS,
    tabData,
    listRequestType,
    actionsType,
    detailsRequestType,
    enableEditing,
    listRequestProps
  } = props

  const value = React.useMemo(
    () => ({
      fetchGridItems,
      setGridFetching,
      tabId,
      tabType,
      ignoreWS,
      tabData,
      listRequestType,
      actionsType,
      selectedIds: oc(tabData).selectedIds(defaultIdsArray),
      pinnedIds: oc(tabData).pinnedIds(defaultIdsArray),
      disabledSelectIds: oc(tabData).disabledSelectIds(defaultIdsArray),
      detailsRequestType,
      enableEditing,
      listRequestProps
    }),
    [
      fetchGridItems,
      setGridFetching,
      tabId,
      tabType,
      ignoreWS,
      tabData,
      listRequestType,
      actionsType,
      detailsRequestType,
      enableEditing,
      listRequestProps
    ]
  )

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>
}

export const GridProvider = connect(({ tabs: { activeMainTabId, main } }: IStore) => {
  const currentTab = main.find(({ id }) => activeMainTabId === id)

  return {
    tabId: oc(currentTab).id(),
    tabData: oc(currentTab).data() || defaultTabData,
    tabType: oc(currentTab).type(),
    ignoreWS: oc(currentTab).ignoreWS(),
    enableEditing: oc(currentTab).enableEditing(),
    actionsType: oc(currentTab).actionsType(),
    listRequestProps: oc(currentTab).listRequestProps(),
    listRequestType: oc(currentTab).listRequestType(),
    detailsRequestType: oc(currentTab).detailsRequestType()
  }
})(React.memo(Component))

const defaultTabData = {}
const defaultIdsArray: string[] = []
