import * as React from 'react'
import { TitleWithLink } from './'
import {
  TransportationActivityViewDTO,
  SellSideQuoteMiscSurchargeDTO
} from '../../../../../../../../../api/origin/business-logic'
import { additionalSurchargesTableHeaderProps } from './config'
import styled from 'styled-components'
import { BasicTable } from '../../../../../../../BasicTable'
import * as R from 'remeda'
import { createNewUnitId } from '../../../../../../../../../services/utils'
import { Select } from '../../../../../../../DataFields/Select'
import { quoteLists } from '../../../../../../../../../services/select/quoteLists'
import { Input, InputField } from '../../../../../../../DataFields/Input'
import { makeActivityColumn } from '../../functions'
import { MiscSurchargeDTO } from '../../../types'
import { HoursMinutes } from '../../../../../../../DataFields/Input/inputTypes/hoursMinutes'
import { calcSurcharge } from '../../../../../../../../common/QMP/views/functions'
import { oc } from 'ts-optchain'
import { SellSideQuoteRateDTO } from '../../../../../../../../../api/origin/qmp-service'
import { isBusinessActivity } from '../../../../../../../../../services/functions/test/isBusinessActivity'
import { isBobtailGotoActivity } from '../../../../../../../../../services/functions/test/isBobtailGotoActivity'
import { transformMoney } from '../../../../../../../Input'

const newMiscSurcharge = (): MiscSurchargeDTO => ({
  id: createNewUnitId(),
  calculationType: SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.FIXEDAMOUNT,
  quantity: 0,
  amount: 0,
  description: '',
  type: SellSideQuoteMiscSurchargeDTO.TypeEnum.GENERAL
})

type Props = {
  showActivityColumn: boolean
  miscSurcharges: MiscSurchargeDTO[]
  transportationActivities?: TransportationActivityViewDTO[]
  availableActivitiesToAttach?: TransportationActivityViewDTO[]
  update: (miscSurcharges: MiscSurchargeDTO[]) => void
}

const styles = {
  emptyGrid: undefined as any,
  filledGrid: { height: '100%', maxHeight: 275 }
}

export const AdditionalSurchargesBlock = (props: Props) => {
  const { showActivityColumn, miscSurcharges, update, transportationActivities, availableActivitiesToAttach } = props
  const transportationBusinessActivities =
    transportationActivities && transportationActivities.filter(activity => isBusinessActivity(activity))
  const bobtailTransportationBusinessActivities =
    transportationActivities && transportationActivities.filter(activity => isBobtailGotoActivity(activity))
  const addNewMiscSurcharge = () => update([newMiscSurcharge(), ...miscSurcharges])
  const removeMiscSurcharge = (id: string) => update(miscSurcharges.filter(r => r.id !== id))
  const updateMiscSurcharge = (rate: SellSideQuoteMiscSurchargeDTO) =>
    update(miscSurcharges.map(r => (r.id === rate.id ? rate : r)))
  const tableHeaderProps = showActivityColumn
    ? additionalSurchargesTableHeaderProps
    : additionalSurchargesTableHeaderProps.filter(_ => _.key !== 'Activity')
  const isMiscSurchargeBobtail = (miscSurcharge: MiscSurchargeDTO): boolean => {
    return oc(miscSurcharge).type() === SellSideQuoteMiscSurchargeDTO.TypeEnum.BOBTAIL
  }

  const RenderCalculationTypeColumn = ({ miscSurcharge }: { miscSurcharge: MiscSurchargeDTO }) => {
    const isBobtail = isMiscSurchargeBobtail(miscSurcharge)
    const calculationTypeList = isBobtail
      ? quoteLists.calculationType.filter(item => item.value === SellSideQuoteRateDTO.CalculationTypeEnum.FIXEDAMOUNT)
      : quoteLists.calculationType

    switch (miscSurcharge.calculationType) {
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERDAY:
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERMILE: {
        return (
          <div style={{ display: 'flex' }}>
            <StyledCalculationType>
              <Select
                disabled={isBobtail}
                selectedValue={miscSurcharge.calculationType}
                list={calculationTypeList}
                onSelect={value => {
                  return updateMiscSurcharge({
                    ...miscSurcharge,
                    calculationType: value,
                    quantity: 0,
                    amount: 0
                  })
                }}
              />
            </StyledCalculationType>
            <StyledQuantity>
              <InputField.Numeric
                placeholder={'000'}
                value={miscSurcharge.quantity}
                onChange={value => updateMiscSurcharge({ ...miscSurcharge, quantity: value || 0 })}
              />
            </StyledQuantity>
          </div>
        )
      }
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERHOUR:
        return (
          <div style={{ display: 'flex' }}>
            <StyledCalculationType>
              <Select
                disabled={isBobtail}
                selectedValue={miscSurcharge.calculationType}
                list={calculationTypeList}
                onSelect={value => {
                  return updateMiscSurcharge({
                    ...miscSurcharge,
                    calculationType: value,
                    quantity: 0,
                    amount: 0
                  })
                }}
              />
            </StyledCalculationType>
            <StyledMinutesQuantity>
              <HoursMinutes
                minutes={miscSurcharge.quantity}
                onChange={value => updateMiscSurcharge({ ...miscSurcharge, quantity: value || 0 })}
              />
            </StyledMinutesQuantity>
          </div>
        )
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.FIXEDAMOUNT:
      case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERCENTAGE:
      default: {
        return (
          <Select
            disabled={isBobtail}
            selectedValue={miscSurcharge.calculationType}
            list={calculationTypeList}
            onSelect={value =>
              updateMiscSurcharge({
                ...miscSurcharge,
                calculationType: value,
                quantity: 0,
                amount: 0
              })
            }
          />
        )
      }
    }
  }

  return (
    <StyledCustomerQuoteBlock
      style={
        miscSurcharges.length ? { ...styles.filledGrid, height: 55 + 50 * miscSurcharges.length } : styles.emptyGrid
      }
    >
      <TitleWithLink
        isBold={true}
        title={'Additional Surcharges'}
        link={{
          isAddButton: true,
          label: 'Add Misc',
          onClick: addNewMiscSurcharge
        }}
      />
      {Boolean(miscSurcharges.length) && (
        <BasicTable
          body={{
            styles: { overflowY: 'scroll' },
            rows: miscSurcharges.map(miscSurcharge => {
              return {
                key: miscSurcharge.id,
                columns: tableHeaderProps.map(columnProps => {
                  const column = R.clone(columnProps)
                  const updateField = (field: string) => (value: any) =>
                    updateMiscSurcharge({ ...miscSurcharge, [field]: value })

                  switch (columnProps.key) {
                    case 'Activity': {
                      const isBobtail = isMiscSurchargeBobtail(miscSurcharge)

                      column.value = makeActivityColumn({
                        disableActivityColumn: isBobtail,
                        rate: miscSurcharge,
                        activities: isBobtail
                          ? bobtailTransportationBusinessActivities
                          : transportationBusinessActivities,
                        availableActivitiesToAttach,
                        updatedActivityId: (rateId, value) => updateField('activityId')(value),
                        showIcon: true
                      })
                      break
                    }
                    case 'Calculation Type': {
                      column.value = (
                        <div style={{ display: 'flex' }}>
                          <div style={{ flexGrow: 1 }}>
                            <RenderCalculationTypeColumn miscSurcharge={miscSurcharge} />
                          </div>
                          <div style={{ width: 100, flexShrink: 0, paddingLeft: 8 }}>
                            {(() => {
                              switch (miscSurcharge.calculationType) {
                                case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERHOUR:
                                case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERDAY:
                                case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERMILE:
                                  return (
                                    <InputField.Money
                                      value={miscSurcharge.amount}
                                      onChange={value => updateField('amount')(value || 0)}
                                    />
                                  )
                                default:
                                  return null
                              }
                            })()}
                          </div>
                        </div>
                      )
                      break
                    }
                    case 'Description': {
                      column.value = (
                        <Input
                          required={true}
                          value={miscSurcharge.description}
                          onChange={updateField('description')}
                        />
                      )
                      break
                    }
                    case 'Amount': {
                      column.value = (
                        <TotalRateAmount>
                          {(() => {
                            switch (miscSurcharge.calculationType) {
                              case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERCENTAGE:
                                return (
                                  <InputField.Percentage
                                    value={miscSurcharge.amount}
                                    onChange={value => updateField('amount')(value || 0)}
                                  />
                                )
                              case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.FIXEDAMOUNT:
                                return (
                                  <InputField.Money
                                    isPositiveOrNegative={true}
                                    value={miscSurcharge.amount}
                                    onChange={value => updateField('amount')(value || 0)}
                                  />
                                )
                              case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERDAY:
                              case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERHOUR:
                              case SellSideQuoteMiscSurchargeDTO.CalculationTypeEnum.PERMILE:
                                return transformMoney(calcSurcharge(0, miscSurcharge, 0)) || ''
                              default:
                                return transformMoney(miscSurcharge.amount) || ''
                            }
                          })()}
                        </TotalRateAmount>
                      )
                      break
                    }
                    case 'Actions': {
                      column.value = (
                        <RemoveButton
                          className={'mdi mdi-minus-circle'}
                          onClick={() => removeMiscSurcharge(miscSurcharge.id)}
                        />
                      )
                      break
                    }
                    default: {
                      break
                    }
                  }

                  return column
                })
              }
            })
          }}
        />
      )}
    </StyledCustomerQuoteBlock>
  )
}

const StyledCustomerQuoteBlock = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 8px;
  margin-bottom: 8px;
`

const RemoveButton = styled.div`
  color: rgba(68, 83, 102, 0.3);
  font-size: 22px;
  cursor: pointer;

  &:hover {
    color: rgba(68, 83, 102, 1);
  }
`

const StyledCalculationType = styled.div`
  flex-grow: 1;
`
const StyledQuantity = styled.div`
  width: 60px;
  margin-left: 8px;
`
const StyledMinutesQuantity = styled.div`
  width: 130px;
  margin-left: 8px;
`
const TotalRateAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  font-weight: 500;
  padding: 0 5px;
  margin-left: auto;
`
