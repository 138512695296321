import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { tryToSave, makeSavePromiseWithCatch } from '../saveDTO'
import * as R from 'remeda'
import { isNewObject, replaceOldGridItemWithNew } from '../index'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { ICustomerView } from '../../../components/common/customer/interfaces'
import { ISubClient } from '../../../components/common/subClient/interfaces'
import { getBusinessPartnerSavePromise } from '../businessPartner/save'
import { getCustomerStatus } from './status'
import { callAPI, customerAPI, CustomerViewDTO } from '../../../api/api'
import { getSubClientSavePromise } from '../subClient/save'
import { getSubClientStatus } from '../subClient/status'
import { parseDTO } from '../parseDTO'

export const getCustomerSavePromise = (customer: ICustomerView, actions?: IModifyGridItemActions): Promise<any> => {
  const { needToSave } = getCustomerStatus(customer)

  const saveSubClientsPromise = (resultCustomer: CustomerViewDTO) =>
    Promise.all(
      customer.subClients.map(subClient =>
        getSubClientSavePromise({ ...subClient, customerId: resultCustomer.id } as ISubClient)
      )
    ).then(subClients => {
      const subClientIds = subClients.map(_ => _.id)
      const isEqualSubClientIds = R.equals(resultCustomer.subClientIds, subClientIds)
      if (!isEqualSubClientIds) {
        parseDTO.customer({
          ...resultCustomer,
          subClientIds,
          subClients: [],
          forceUpdate: true
        } as CustomerViewDTO)
      }

      if (Boolean(actions)) {
        if (isNewObject(customer)) {
          replaceOldGridItemWithNew(resultCustomer, actions.getUnitInfo)
        } else {
          actions.reset()
        }
      }
      return isEqualSubClientIds
        ? Promise.resolve(resultCustomer)
        : callAPI(
            customerAPI.update,
            R.omit({ ...resultCustomer, subClientIds }, ['subClients']),
            resultCustomer.id
          ).toPromise()
    })

  return getBusinessPartnerSavePromise(customer.businessPartner).then((businessPartner: BusinessPartner) => {
    const result = { ...customer, businessPartnerId: businessPartner.id }
    return needToSave
      ? (isNewObject(result)
          ? callAPI(customerAPI.create, {
              ...result,
              id: null
            })
          : callAPI(customerAPI.update, result, result.id)
        )
          .toPromise()
          .then(customerData => {
            parseDTO.customer(customerData)
            return saveSubClientsPromise(customerData)
          })
      : saveSubClientsPromise(result).then(() => {
          if (Boolean(actions)) {
            actions.reset()
          }
          return result
        })
  })
}

export const saveCustomer = (customer: ICustomerView, actions: IModifyGridItemActions) => {
  const subClientsInfo = customer.subClients.map(subClient => getSubClientStatus(subClient as ISubClient))
  const save = () => makeSavePromiseWithCatch(actions, getCustomerSavePromise(customer, actions))

  return tryToSave({
    condition:
      getCustomerStatus(customer).noStoreDataForUpdate && subClientsInfo.every(item => item.noStoreDataForUpdate),
    save: () => [actions.setFetching(true), save()],
    hideSpinner: () => actions.setFetching(false),
    cancel: () => {},
    discardChanges: () => actions.cancel()
  })
}
