import { IContainer } from '../../../components/common/containers/interfaces'
import { schemaContainerView } from '../../yupScheme'
import * as yup from 'yup'
import { debuggingMode } from '../../debug'

const businessLogicValidationContainer = schemaContainerView.shape({ steamShipLine: yup.object().nullable() })

export const checkContainerValidation = (container: IContainer): boolean => {
  if (debuggingMode.common) {
    businessLogicValidationContainer.validate(container).catch(e =>
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkContainerValidation', e)
    )
  }
  return businessLogicValidationContainer.isValidSync(container)
}
