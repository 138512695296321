import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { TransportationActivityDTO } from '../../../../../../../api/api'
import { IStore } from '../../../../../../../store/store.interface'
import { ColumnBoldText, ColumnThinText } from './MoveRowSpecs'
import { availibleActivityStatuses } from './MoveRowActivity'
import { transformMoney } from '../../../../../../UI/Input'

type OwnProps = {
  gotoActivityId: string
  businessActivityId: string
}

type StateProps = {
  businessActivityStatus: TransportationActivityDTO.StatusEnum
  gotoActivityBSQAmount: number
  businessActivityBSQAmount: number
}

type Props = OwnProps & StateProps

const Component = (props: Props) => {
  const { gotoActivityBSQAmount, businessActivityBSQAmount, businessActivityStatus } = props

  if (!availibleActivityStatuses.includes(businessActivityStatus)) {
    return null
  }

  let bsqAmount: number = undefined

  if (typeof gotoActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + gotoActivityBSQAmount
  }
  if (typeof businessActivityBSQAmount === 'number') {
    bsqAmount = (bsqAmount || 0) + businessActivityBSQAmount
  }

  return (
    <QuotesColumn data-copy-value-on-mousedown={true}>
      <ColumnBoldText>{transformMoney(bsqAmount) || '–'}</ColumnBoldText>
      <ColumnThinText>BSQ</ColumnThinText>
    </QuotesColumn>
  )
}

export const MoveRowQuoteColumn = connect(
  (
    { buySideQuotes, activity: { transportationActivities } }: IStore,
    { gotoActivityId, businessActivityId }: OwnProps
  ) => {
    const businessActivity = transportationActivities[businessActivityId]

    return {
      businessActivityStatus: businessActivity && businessActivity.status,
      gotoActivityBSQAmount: buySideQuotes[gotoActivityId] && buySideQuotes[gotoActivityId].amount,
      businessActivityBSQAmount: buySideQuotes[businessActivityId] && buySideQuotes[businessActivityId].amount
    }
  }
)(React.memo(Component))

export const QuotesColumn = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 6px;
`
