import * as React from 'react'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { Container } from './styles'
import DownloadWorkOrder from './DownloadWorkOrder'
import { UncancelDDO } from './UncancelDDO'
import { StreetTurn } from './StreetTurn'
import { TestDriverAssign } from './TestDriverAssign'
import { EntityChat } from './EntityChat'
import { generateDispatchDeliveryOrderChatChannel } from '../../../../../CommunicationHub/functions'
// import { DispatchDeliveryOrderViewDTO } from '../../../../../../api/origin/business-logic'
// import { oc } from 'ts-optchain'

export type CustomDDOHeader = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  isModified: boolean
  actions: IGridItemActions | undefined
}

export const CustomHeader = (props: CustomDDOHeader) => {
  const { dispatchDeliveryOrder, actions, isModified } = props

  const channel = React.useMemo(() => {
    return generateDispatchDeliveryOrderChatChannel(dispatchDeliveryOrder)
  }, [dispatchDeliveryOrder.id])

  return (
    <>
      <StreetTurn {...props} />
      <UncancelDDO {...props} />
      <TestDriverAssign {...props} />

      <Container>
        <DownloadWorkOrder dispatchDeliveryOrder={dispatchDeliveryOrder} />
        <EntityChat channel={channel} />
      </Container>
    </>
  )
}
