import * as React from 'react'
import Downshift, { StateChangeOptions } from 'downshift'
import cn from 'classnames'

import { callAPI, hazmatAPI, HazmatDTO } from '../../../../api/api'
import { Container } from '../../GridSystem'
import { Label, Input, Menu } from './Components'
import { useFetchList, useInputSearch } from './hooks'

const transformHazmat = (hazmat: HazmatDTO): string =>
  hazmat ? ('code' in hazmat ? hazmat.code + ', ' + hazmat.hazardClass + ' ' + hazmat.description : '') : ''

const transformHazmatForResult = (hazmat: HazmatDTO): { head: string; tail: string } => {
  const head = hazmat ? hazmat.code + ',' : ''
  const tail = hazmat ? hazmat.hazardClass + ' ' + hazmat.description : ''

  return { head, tail }
}

export const Hazmat: React.FC<{
  disabled?: boolean
  title?: string
  required?: boolean
  hazmat?: HazmatDTO
  placeholder?: string
  isTable?: boolean
  onChange: (value?: HazmatDTO) => void
}> = ({ title, hazmat, placeholder = 'Type here', required, onChange, isTable, disabled }) => {
  const name = transformHazmat(hazmat)

  const { value, setValue, setIsSearch, isSearch } = useInputSearch(name)

  const [isEdit, setIsEdit] = React.useState(false)
  const toggleIsEdit = () => setIsEdit(state => !state)

  const { list, isFetchingList, fetchList, isNotFound } = useFetchList({
    term: value,
    isSearch,
    func: (term: string) => callAPI(hazmatAPI.search, null, term).toPromise()
  })

  const onInputValueChange = (inputValue: string) => {
    if (disabled) {
      return
    }
    setIsSearch(true)

    setValue(inputValue)
    if (inputValue.length === 0) {
      onChange(undefined)
    }
  }

  const onHandleSelect = (selectedItem: HazmatDTO | null) => {
    if (disabled) {
      return
    }
    setIsSearch(false)
    toggleIsEdit()
    if (selectedItem) {
      setValue(transformHazmat(selectedItem))
      return onChange(selectedItem)
    }

    return onChange(undefined)
  }

  const onHandleStateChange = (options: StateChangeOptions<HazmatDTO>) => {
    if (disabled) {
      return
    }
    switch (options.type) {
      case Downshift.stateChangeTypes.changeInput:
        return onInputValueChange(options.inputValue)
      case Downshift.stateChangeTypes.mouseUp:
        return setValue(name)

      case Downshift.stateChangeTypes.keyDownEscape:
        setValue(name)
        onChange(undefined)
        break
      default:
        break
    }
  }

  return (
    <Downshift
      inputValue={value}
      onSelect={onHandleSelect}
      onStateChange={onHandleStateChange}
      itemToString={transformHazmat}
    >
      {downshift => {
        const cnInput = cn({
          required: required && !hazmat,
          'mdi mdi-menu-down': !isFetchingList,
          isTable,
          'mdi mdi-loading': isFetchingList
        })
        const cnMenu = cn({ isTable, open: downshift.isOpen })

        return (
          <Container {...downshift.getRootProps()} isGrid={true}>
            {!isTable && <Label downshift={downshift} title={title} required={required} />}
            <Input
              disabled={disabled}
              className={cnInput}
              placeholder={placeholder}
              isFetchingList={isFetchingList}
              isShowResult={true}
              isEdit={isEdit}
              toggleIsEdit={toggleIsEdit}
              downshift={downshift}
              fetchList={fetchList}
              transformForResult={transformHazmatForResult(hazmat)}
            />
            <Menu
              isFetchingList={isFetchingList}
              style={cnMenu}
              list={list}
              downshift={downshift}
              isNotFound={isNotFound}
              transform={transformHazmat}
            />
          </Container>
        )
      }}
    </Downshift>
  )
}
