import { BusinessPartnerViewDTO, CustomerViewDTO, SubClientDTO } from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddCustomerToStore } from '../../../components/common/customer/epics'
import { parseDTO } from '../parseDTO'

export const parseCustomers = (customers: CustomerViewDTO[]) => {
  const lists: { businessPartners: BusinessPartnerViewDTO[]; subClients: SubClientDTO[] } = {
    businessPartners: [],
    subClients: []
  }
  const clearedCustomers = customers.filter(Boolean).map(customer => {
    const { businessPartner, subClients } = customer
    if (businessPartner) {
      lists.businessPartners.push(businessPartner)
    }
    if (subClients) {
      lists.subClients.push(...subClients)
    }
    return R.omit(customer, ['businessPartner', 'subClients'])
  })

  parseDTO.businessPartner(lists.businessPartners)
  parseDTO.subClient(lists.subClients)
  tryAddCustomerToStore(clearedCustomers)
}
