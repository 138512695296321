import * as React from 'react'
import { ICustomerView } from '../../interfaces'
import { General } from '../Details/General'
import Contacts from '../Details/Contacts'
import SubClients from '../Details/SubClients'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { checkValidation } from '../../../../../services/DTO/validation'
import { GridItemTab } from '../../../../pages/GridItem/interfaces'

const T = GridItemTab.Customer

export const CustomerPreview = {
  headings: [T.General, T.Contacts, T.SubClients],
  tabs: {
    [T.General]: (customer: ICustomerView, actions: IGridItemActions) => (
      <General customer={customer} actions={actions} />
    ),
    [T.Contacts]: (customer: ICustomerView, actions: IGridItemActions, isModified: boolean) => (
      <Contacts customer={customer} actions={actions} isModified={isModified} />
    ),
    [T.SubClients]: (customer: ICustomerView, actions: IGridItemActions) => (
      <SubClients customer={customer} actions={actions} />
    )
  },
  isValid: checkValidation.customer
}
