import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { BusinessPartnerState, BusinessPartner } from '../types'
import nanoid = require('nanoid')
import { addBusinessPartnerToStore } from '../action'
import { defaultAddress } from '../../location/reducers'

export const createNewBusinessPartner = (id?: string): BusinessPartner => ({
  id: id || 'new' + nanoid(),
  fullObject: true,
  contactIds: [],
  contacts: [],
  legalAddress: defaultAddress(),
  legalName: '',
  locationIds: []
})

const businessPartnerState: BusinessPartnerState = {}

export const businessPartnerStateReducer = reducerWithInitialState(businessPartnerState)
  .case(addBusinessPartnerToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()
