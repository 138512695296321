import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { IDriver } from '../../../components/common/drivers/interfaces'
import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { tryToSave, makeObjectSavePromise, makeSavePromiseWithCatch } from '../saveDTO'
import { getDriverStatus, omitDriverProps } from './status'
import { DriverViewDTO, driverAPI } from '../../../api/api'
import { tryAddDriverToStore } from '../../../components/common/drivers/epics'
import { requestDeductionRemove, requestDeductionUpdate, requestDriverDeductions } from '../deduction/fetch'
import { requestVendorAccounting, requestVendorAccountingUpdate } from '../vendorAccounting/fetch'
import { getStore } from '../../../store/configureStore'
import { setTabViewingObjectTemporaryDataProps } from '../../viewingObjects/actions'
import { IDriverAccountingTabData } from '../../viewingObjects/interfaces'
import { sortDeductions } from '../../functions/sort/sortDeductions'
import { encrypt } from '../../Crypto/encrypt'
import { getListState } from '../../../store'
import { ListEntityType } from '../../../store/reducers/lists/interfaces'
import { isNewObject } from '..'
import { PaymentMethod } from '../../../api/origin/vendor-accounting-service'

export const getDriverStatusSavePromise = async (driver: IDriver, actions?: IModifyGridItemActions): Promise<any> => {
  let driverToSave = driver
  const { needToSave } = getDriverStatus(driverToSave)
  // @ts-ignore
  driverToSave = R.omit(driverToSave, omitDriverProps)
  const removeDeductionIds = driver.temporaryData && driver.temporaryData.removeDeductionIds
  const updatedDeductions = driver.temporaryData && driver.temporaryData.updatedDeductions
  const needRequestDeductions = Boolean(removeDeductionIds || updatedDeductions)
  const updatedVendorAccounting = driver.temporaryData && driver.temporaryData.updatedVendorAccounting

  const updateTemporaryDataProp = (prop: keyof IDriverAccountingTabData) => (value: any) => {
    const { dispatch, getState } = getStore()
    const store = getState()
    const tabId = store.tabs.activeMainTabId
    const driverAccountingTabData = oc(store).viewingObjects[tabId].temporaryData.driverAccountingTabData()

    if (!driverAccountingTabData) {
      return
    }

    dispatch(
      setTabViewingObjectTemporaryDataProps({
        tabId,
        temporaryDataProps: {
          driverAccountingTabData: { ...driverAccountingTabData, [prop]: value }
        }
      })
    )
  }

  const updateVendorAccounting = async () => {
    return updatedVendorAccounting ? requestVendorAccountingUpdate(updatedVendorAccounting) : Promise.resolve()
  }

  const removeDeductions = () => {
    return removeDeductionIds && removeDeductionIds.length
      ? Promise.all(removeDeductionIds.map(requestDeductionRemove))
      : Promise.resolve()
  }

  const updateDeductions = async () => {
    try {
      if (updatedDeductions && updatedDeductions.length) {
        for (const updatedDeduction of sortDeductions(updatedDeductions)) {
          await requestDeductionUpdate(updatedDeduction)
        }
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const updateDriver = async () => {
    const isSSNUpdated = Boolean(driverToSave.ssn)

    if (isSSNUpdated) {
      const encryptedSSN = await encrypt(driverToSave.ssn)

      if (encryptedSSN) {
        driverToSave.ssn = encryptedSSN
      }
    }

    return makeObjectSavePromise(needToSave, driverToSave, actions, driverAPI, tryAddDriverToStore)
  }

  // DRIVER
  return updateVendorAccounting().then(requestedVendorAccounting => {
    if (requestedVendorAccounting) {
      updateTemporaryDataProp('vendor')(requestedVendorAccounting)
    }

    return removeDeductions().then(() =>
      updateDeductions().then(() => {
        if (needRequestDeductions) {
          return requestDriverDeductions(driver.id).then(requestedDeductions => {
            updateTemporaryDataProp('deductions')(requestedDeductions)

            return updateDriver()
          })
        }

        return updateDriver()
      })
    )
  })
}

export const saveDriver = (driver: IDriver, actions: IModifyGridItemActions) => {
  const save = () => makeSavePromiseWithCatch(actions, getDriverStatusSavePromise(driver, actions))

  const condition = Boolean(getDriverStatus(driver).noStoreDataForUpdate)

  return tryToSave({
    condition,
    save: () => [actions.setFetching(true), save()],
    cancel: () => {},
    hideSpinner: () => actions.setFetching(false),
    discardChanges: () => actions.cancel()
  })
}
