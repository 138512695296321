import actionCreatorFactory, { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';
export const qmpAsyncActionCreator: ActionCreatorFactory = actionCreatorFactory('QMP/ASYNC');
import { FetchAPI, FetchArgs, ApiError, BuySideQuoteRateDTO, ConditionDTO, ConditionPropertyValueDTO, CustomerQuoteDTO, DuplicateError, PropertyDTO, RuleDTO, RuleSearchDTO, SellSideQuoteRateDTO, Serializable, SsqRateGroupDTO, DateISOString } from './api';
export interface IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostRequest { qMPOverwrite: boolean, dtoList: Array<BuySideQuoteRateDTO>, options?: any }
export type IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostSuccess = BuySideQuoteRateDTO[];
export type IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostError = any;
export const buySideQuoteRateBatchCreateBuySideQuoteRateUsingPostAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostRequest, IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostSuccess, IBuySideQuoteRateBatchCreateBuySideQuoteRateUsingPostError
>('BUYSIDEQUOTERATE_BATCHCREATEBUYSIDEQUOTERATEUSINGPOST');

export interface IBuySideQuoteRateCreateBuySideQuoteRateUsingPostRequest { qMPOverwrite: boolean, dto: BuySideQuoteRateDTO, options?: any }
export type IBuySideQuoteRateCreateBuySideQuoteRateUsingPostSuccess = BuySideQuoteRateDTO;
export type IBuySideQuoteRateCreateBuySideQuoteRateUsingPostError = any;
export const buySideQuoteRateCreateBuySideQuoteRateUsingPostAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateCreateBuySideQuoteRateUsingPostRequest, IBuySideQuoteRateCreateBuySideQuoteRateUsingPostSuccess, IBuySideQuoteRateCreateBuySideQuoteRateUsingPostError
>('BUYSIDEQUOTERATE_CREATEBUYSIDEQUOTERATEUSINGPOST');

export interface IBuySideQuoteRateExpireBuySideQuoteUsingPutRequest { id: string, options?: any }
export type IBuySideQuoteRateExpireBuySideQuoteUsingPutSuccess = BuySideQuoteRateDTO;
export type IBuySideQuoteRateExpireBuySideQuoteUsingPutError = any;
export const buySideQuoteRateExpireBuySideQuoteUsingPutAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateExpireBuySideQuoteUsingPutRequest, IBuySideQuoteRateExpireBuySideQuoteUsingPutSuccess, IBuySideQuoteRateExpireBuySideQuoteUsingPutError
>('BUYSIDEQUOTERATE_EXPIREBUYSIDEQUOTEUSINGPUT');

export interface IBuySideQuoteRateGetBuySideQuoteRateUsingGetRequest { id: string, options?: any }
export type IBuySideQuoteRateGetBuySideQuoteRateUsingGetSuccess = BuySideQuoteRateDTO;
export type IBuySideQuoteRateGetBuySideQuoteRateUsingGetError = any;
export const buySideQuoteRateGetBuySideQuoteRateUsingGetAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateGetBuySideQuoteRateUsingGetRequest, IBuySideQuoteRateGetBuySideQuoteRateUsingGetSuccess, IBuySideQuoteRateGetBuySideQuoteRateUsingGetError
>('BUYSIDEQUOTERATE_GETBUYSIDEQUOTERATEUSINGGET');

export interface IBuySideQuoteRateGetBuySideQuoteRatesUsingGetRequest { filter?: string, limit?: number, sort?: string, options?: any }
export type IBuySideQuoteRateGetBuySideQuoteRatesUsingGetSuccess = BuySideQuoteRateDTO[];
export type IBuySideQuoteRateGetBuySideQuoteRatesUsingGetError = any;
export const buySideQuoteRateGetBuySideQuoteRatesUsingGetAction = qmpAsyncActionCreator.async<
  IBuySideQuoteRateGetBuySideQuoteRatesUsingGetRequest, IBuySideQuoteRateGetBuySideQuoteRatesUsingGetSuccess, IBuySideQuoteRateGetBuySideQuoteRatesUsingGetError
>('BUYSIDEQUOTERATE_GETBUYSIDEQUOTERATESUSINGGET');

export interface ICustomerQuoteBatchCreateCustomerQuoteUsingPostRequest { qMPOverwrite: boolean, dtoList: Array<CustomerQuoteDTO>, options?: any }
export type ICustomerQuoteBatchCreateCustomerQuoteUsingPostSuccess = CustomerQuoteDTO[];
export type ICustomerQuoteBatchCreateCustomerQuoteUsingPostError = any;
export const customerQuoteBatchCreateCustomerQuoteUsingPostAction = qmpAsyncActionCreator.async<
  ICustomerQuoteBatchCreateCustomerQuoteUsingPostRequest, ICustomerQuoteBatchCreateCustomerQuoteUsingPostSuccess, ICustomerQuoteBatchCreateCustomerQuoteUsingPostError
>('CUSTOMERQUOTE_BATCHCREATECUSTOMERQUOTEUSINGPOST');

export interface ICustomerQuoteCreateCustomerQuoteUsingPostRequest { qMPOverwrite: boolean, dto: CustomerQuoteDTO, options?: any }
export type ICustomerQuoteCreateCustomerQuoteUsingPostSuccess = CustomerQuoteDTO;
export type ICustomerQuoteCreateCustomerQuoteUsingPostError = any;
export const customerQuoteCreateCustomerQuoteUsingPostAction = qmpAsyncActionCreator.async<
  ICustomerQuoteCreateCustomerQuoteUsingPostRequest, ICustomerQuoteCreateCustomerQuoteUsingPostSuccess, ICustomerQuoteCreateCustomerQuoteUsingPostError
>('CUSTOMERQUOTE_CREATECUSTOMERQUOTEUSINGPOST');

export interface ICustomerQuoteExpireCustomerQuotesUsingPutRequest { id: string, options?: any }
export type ICustomerQuoteExpireCustomerQuotesUsingPutSuccess = CustomerQuoteDTO;
export type ICustomerQuoteExpireCustomerQuotesUsingPutError = any;
export const customerQuoteExpireCustomerQuotesUsingPutAction = qmpAsyncActionCreator.async<
  ICustomerQuoteExpireCustomerQuotesUsingPutRequest, ICustomerQuoteExpireCustomerQuotesUsingPutSuccess, ICustomerQuoteExpireCustomerQuotesUsingPutError
>('CUSTOMERQUOTE_EXPIRECUSTOMERQUOTESUSINGPUT');

export interface ICustomerQuoteGetCustomerQuoteUsingGetRequest { id: string, options?: any }
export type ICustomerQuoteGetCustomerQuoteUsingGetSuccess = CustomerQuoteDTO;
export type ICustomerQuoteGetCustomerQuoteUsingGetError = any;
export const customerQuoteGetCustomerQuoteUsingGetAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetCustomerQuoteUsingGetRequest, ICustomerQuoteGetCustomerQuoteUsingGetSuccess, ICustomerQuoteGetCustomerQuoteUsingGetError
>('CUSTOMERQUOTE_GETCUSTOMERQUOTEUSINGGET');

export interface ICustomerQuoteGetCustomerQuotesUsingGetRequest { filter?: string, limit?: number, sort?: string, options?: any }
export type ICustomerQuoteGetCustomerQuotesUsingGetSuccess = CustomerQuoteDTO[];
export type ICustomerQuoteGetCustomerQuotesUsingGetError = any;
export const customerQuoteGetCustomerQuotesUsingGetAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetCustomerQuotesUsingGetRequest, ICustomerQuoteGetCustomerQuotesUsingGetSuccess, ICustomerQuoteGetCustomerQuotesUsingGetError
>('CUSTOMERQUOTE_GETCUSTOMERQUOTESUSINGGET');

export interface ICustomerQuoteGetRatesOfCustomerQuoteUsingGetRequest { id: string, options?: any }
export type ICustomerQuoteGetRatesOfCustomerQuoteUsingGetSuccess = SellSideQuoteRateDTO[];
export type ICustomerQuoteGetRatesOfCustomerQuoteUsingGetError = any;
export const customerQuoteGetRatesOfCustomerQuoteUsingGetAction = qmpAsyncActionCreator.async<
  ICustomerQuoteGetRatesOfCustomerQuoteUsingGetRequest, ICustomerQuoteGetRatesOfCustomerQuoteUsingGetSuccess, ICustomerQuoteGetRatesOfCustomerQuoteUsingGetError
>('CUSTOMERQUOTE_GETRATESOFCUSTOMERQUOTEUSINGGET');

export interface IIndexGetUsingGetRequest { options?: any }
export type IIndexGetUsingGetSuccess = any;
export type IIndexGetUsingGetError = any;
export const indexGetUsingGetAction = qmpAsyncActionCreator.async<
  IIndexGetUsingGetRequest, IIndexGetUsingGetSuccess, IIndexGetUsingGetError
>('INDEX_GETUSINGGET');

export interface IPropertyGetPropertiesUsingGetRequest { options?: any }
export type IPropertyGetPropertiesUsingGetSuccess = PropertyDTO[];
export type IPropertyGetPropertiesUsingGetError = any;
export const propertyGetPropertiesUsingGetAction = qmpAsyncActionCreator.async<
  IPropertyGetPropertiesUsingGetRequest, IPropertyGetPropertiesUsingGetSuccess, IPropertyGetPropertiesUsingGetError
>('PROPERTY_GETPROPERTIESUSINGGET');

export interface IRuleGetRuleUsingGetRequest { id: string, options?: any }
export type IRuleGetRuleUsingGetSuccess = RuleDTO;
export type IRuleGetRuleUsingGetError = any;
export const ruleGetRuleUsingGetAction = qmpAsyncActionCreator.async<
  IRuleGetRuleUsingGetRequest, IRuleGetRuleUsingGetSuccess, IRuleGetRuleUsingGetError
>('RULE_GETRULEUSINGGET');

export interface IRuleGetRulesUsingGetRequest { filter?: string, options?: any }
export type IRuleGetRulesUsingGetSuccess = RuleSearchDTO[];
export type IRuleGetRulesUsingGetError = any;
export const ruleGetRulesUsingGetAction = qmpAsyncActionCreator.async<
  IRuleGetRulesUsingGetRequest, IRuleGetRulesUsingGetSuccess, IRuleGetRulesUsingGetError
>('RULE_GETRULESUSINGGET');

export interface ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostRequest { qMPOverwrite: boolean, dtoList: Array<SellSideQuoteRateDTO>, options?: any }
export type ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostSuccess = SellSideQuoteRateDTO[];
export type ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostError = any;
export const sellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostRequest, ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostSuccess, ISellSideQuoteRateBatchCreateSellSideQuoteRateUsingPostError
>('SELLSIDEQUOTERATE_BATCHCREATESELLSIDEQUOTERATEUSINGPOST');

export interface ISellSideQuoteRateCreateSellSideQuoteRateUsingPostRequest { qMPOverwrite: boolean, dto: SellSideQuoteRateDTO, options?: any }
export type ISellSideQuoteRateCreateSellSideQuoteRateUsingPostSuccess = SellSideQuoteRateDTO;
export type ISellSideQuoteRateCreateSellSideQuoteRateUsingPostError = any;
export const sellSideQuoteRateCreateSellSideQuoteRateUsingPostAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateCreateSellSideQuoteRateUsingPostRequest, ISellSideQuoteRateCreateSellSideQuoteRateUsingPostSuccess, ISellSideQuoteRateCreateSellSideQuoteRateUsingPostError
>('SELLSIDEQUOTERATE_CREATESELLSIDEQUOTERATEUSINGPOST');

export interface ISellSideQuoteRateExpireSellSideQuoteUsingPutRequest { id: string, options?: any }
export type ISellSideQuoteRateExpireSellSideQuoteUsingPutSuccess = SellSideQuoteRateDTO;
export type ISellSideQuoteRateExpireSellSideQuoteUsingPutError = any;
export const sellSideQuoteRateExpireSellSideQuoteUsingPutAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateExpireSellSideQuoteUsingPutRequest, ISellSideQuoteRateExpireSellSideQuoteUsingPutSuccess, ISellSideQuoteRateExpireSellSideQuoteUsingPutError
>('SELLSIDEQUOTERATE_EXPIRESELLSIDEQUOTEUSINGPUT');

export interface ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetRequest { filter?: string, limit?: number, sort?: string, options?: any }
export type ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetSuccess = SsqRateGroupDTO[];
export type ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetError = any;
export const sellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetRequest, ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetSuccess, ISellSideQuoteRateGetSellSideQuoteRateGroupsUsingGetError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATEGROUPSUSINGGET');

export interface ISellSideQuoteRateGetSellSideQuoteRateUsingGetRequest { id: string, options?: any }
export type ISellSideQuoteRateGetSellSideQuoteRateUsingGetSuccess = SellSideQuoteRateDTO;
export type ISellSideQuoteRateGetSellSideQuoteRateUsingGetError = any;
export const sellSideQuoteRateGetSellSideQuoteRateUsingGetAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRateUsingGetRequest, ISellSideQuoteRateGetSellSideQuoteRateUsingGetSuccess, ISellSideQuoteRateGetSellSideQuoteRateUsingGetError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATEUSINGGET');

export interface ISellSideQuoteRateGetSellSideQuoteRatesUsingGetRequest { filter?: string, limit?: number, sort?: string, options?: any }
export type ISellSideQuoteRateGetSellSideQuoteRatesUsingGetSuccess = SellSideQuoteRateDTO[];
export type ISellSideQuoteRateGetSellSideQuoteRatesUsingGetError = any;
export const sellSideQuoteRateGetSellSideQuoteRatesUsingGetAction = qmpAsyncActionCreator.async<
  ISellSideQuoteRateGetSellSideQuoteRatesUsingGetRequest, ISellSideQuoteRateGetSellSideQuoteRatesUsingGetSuccess, ISellSideQuoteRateGetSellSideQuoteRatesUsingGetError
>('SELLSIDEQUOTERATE_GETSELLSIDEQUOTERATESUSINGGET');


