import * as React from 'react'
import { ContainerTypeDTO, ContainerViewDTO, DeliveryOrderViewDTO } from '../../../api/origin/business-logic'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { IGridItemActions } from '../../../contexts/GridItemContext'
import { oc } from 'ts-optchain'
import { containerFn, isChassisNumberRequired } from './functions'

type TCommonState = {
  isDDOValid: boolean
  isModifiedMode: boolean
  isImport: boolean
  isExport: boolean
  isRepo: boolean
  marks: {
    reefer: boolean
  }
  required: {
    chassisNumber: boolean
  }
}

type TCommonFunctions = {
  updateContainerType: (containerType: ContainerTypeDTO) => void
  updateContainer: (container: ContainerViewDTO) => void
}

type TDDOContext = {
  state: TCommonState
  functions: TCommonFunctions
  saveButton: any
}

type CommonDDOState = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  isModifiedMode: boolean
  isUnitValid: boolean
}
export const commonDDOState = (props: CommonDDOState): TCommonState => {
  const { dispatchDeliveryOrder, isModifiedMode, isUnitValid } = props

  return {
    isDDOValid: isUnitValid,
    isModifiedMode,
    isImport: dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT,
    isExport: dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT,
    isRepo: dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.REPOSITION,
    marks: {
      reefer: oc(dispatchDeliveryOrder)
        .containerType.name('')
        .toLowerCase()
        .includes('refriger')
    },
    required: {
      chassisNumber: isChassisNumberRequired(dispatchDeliveryOrder.activities)
    }
  }
}

export const commonDDOFunctions = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder,
  actions: IGridItemActions
): TCommonFunctions => {
  const { updateContainer, updateContainerType } = containerFn(dispatchDeliveryOrder, actions)
  return { updateContainer, updateContainerType }
}

export const DDOContext: React.Context<TDDOContext> = React.createContext({
  state: null,
  functions: null,
  saveButton: null
})
