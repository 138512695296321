import * as React from 'react'
import { oc } from 'ts-optchain'
import { SearchRequest } from './searchRequest'
import { assembleDTO } from '../../../../services/DTO/assemble'
import {
  callAPI,
  steamShipLineAPI,
  driverAPI,
  customerAPI,
  powerUnitAPI,
  dispatchDeliveryOrderAPI
} from '../../../../api/api'
import { transformPowerUnit } from '../../../../services/DTO/powerUnit/functions'
import { tryAddPowerUnitToStore } from '../../../common/powerUnit/epics'
import { tryAddDriverToStore } from '../../../common/drivers/epics'
import { parseDTO } from '../../../../services/DTO/parseDTO'
export { EquipmentSearch } from './equipment'
export { LocationSearch } from './location'
export { ContainerSearch } from './container'

export type TSearchField = {
  disabled?: boolean
  title?: string
  required?: boolean
  value?: any
  onChange: (value: any) => void
  placeholder?: string
  symbolsNumberToFetch?: number
  allowCreate?: boolean
  requestByEnterPress?: boolean
  filtering?: boolean
  focus?: boolean
  highlighted?: boolean
  filterListResultFunction?: (object: any) => boolean
}

export const DispatchDeliveryOrderSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    filtering={false}
    symbolsNumberToFetch={1}
    label={oc(props).value.number()}
    getList={term => callAPI(dispatchDeliveryOrderAPI.searchByNumber, term, 10).toPromise()}
    getDetails={item => Promise.resolve(item)}
    makeDropDownItem={response => ({ label: response.number, value: response })}
  />
)

export const SteamShipLineSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    label={props.value ? props.value.name : null}
    assembleObject={assembleDTO.steamShipLine}
    getList={term => callAPI(steamShipLineAPI.search, null, term).toPromise()}
    getDetails={id => callAPI(steamShipLineAPI.findById, id).toPromise()}
    addToStore={parseDTO.steamShipLine}
    makeDropDownItem={response => ({ label: response.name, value: response.id })}
  />
)

// export const DriverSearch = (props: TSearchField) => (
//   <SearchRequest
//     {...props}
//     // @ts-ignore
//     label={oc(props).value.name(null)}
//     getList={term => callAPI(driverAPI.search, null, term).toPromise()}
//     getDetails={id => callAPI(driverAPI.findById, id).toPromise()}
//     addToStore={tryAddDriverToStore}
//     makeDropDownItem={response => ({ label: response.name, value: response.id })}
//   />
// )

export const PowerUnitSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    label={transformPowerUnit(props.value)}
    getList={term => callAPI(powerUnitAPI.search, null, term).toPromise()}
    getDetails={id => callAPI(powerUnitAPI.findById, id).toPromise()}
    addToStore={tryAddPowerUnitToStore}
    makeDropDownItem={response => ({ label: transformPowerUnit(response), value: response.id })}
  />
)

export const CustomerSearch = (props: TSearchField) => (
  <SearchRequest
    {...props}
    label={props.value ? props.value.name : null}
    assembleObject={assembleDTO.customer}
    getList={term => callAPI(customerAPI.search, null, term).toPromise()}
    getDetails={id => callAPI(customerAPI.findById, id).toPromise()}
    addToStore={parseDTO.customer}
    makeDropDownItem={response => ({ label: response.name, value: response.id })}
  />
)

export const getValidationErrorMessage = (validation: boolean[], messageTypes: string[]): string =>
  validation.reduce((acc, curr, index) => {
    return !curr ? `${acc}${acc.length ? `\n` : ''}${messageTypes[index]}` : acc
  }, ``)
//
// export const SubClientSearch = (props: TSearchField & { customerId?: string }) => (
//   <SearchRequest
//     {...props}
//     label={props.value ? props.value.name : null}
//     assembleObject={assembleDTO.subClient}
//     getList={term => callAPI(subClientAPI.search, props.customerId, null, term).toPromise()}
//     getDetails={id => callAPI(subClientAPI.findById, id).toPromise()}
//     addToStore={subClientActions.addToList}
//     makeDropDownItem={response => ({ label: response.name, value: response.id })}
//   />
// )
//
// export const HazmatSearch = (props: TSearchField) => (
//   <SearchRequest
//     {...props}
//     label={transformHazmat(props.value)}
//     getList={term => callAPI(hazmatAPI.search, null, term).toPromise()}
//     getDetails={value => Promise.resolve(value)}
//     makeDropDownItem={response => ({ label: transformHazmat(response), value: response.id })}
//   />
// )
//
// const transformHazmat = (hazmat: HazmatDTO): string =>
//   hazmat ? (hazmat.code ? hazmat.code + ', ' + hazmat.hazardClass + ' ' + hazmat.description : '') : null
