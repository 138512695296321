import * as React from 'react'
import styled from '../../../styles/styledComponents'
import theme from '../../../styles/theme'
import { StyledAddNewTab } from './styledComponents'
import { Popover } from '../../UI/Dropdowns/Popover'
import { ITabStateOptions, TabType } from './interfaces'
import cn from 'classnames'
import { getStore } from '../../../store/configureStore'
import { getSchedulerTabs } from './selectors'

const TabList = styled.ul`
  max-height: none !important;
`

const Tab = styled.li`
  &:before {
    font-size: 14px !important;
    color: ${theme.colors.darkGreyColor} !important;
    margin-right: 5px;
  }

  &.disabled {
    cursor: default;
    color: #9e9e9e;

    &:before {
      color: #9e9e9e !important;
    }
  }
`

type Props = {
  tabTypes: ITabStateOptions[]
  selectTabType: (tabType: TabType) => void
}

type State = { open: boolean }

class AddNewTab extends React.Component<Props, State> {
  state = { open: false }

  togglePopover = (state = !this.state.open) => {
    this.setState({ open: state })
  }

  render() {
    const { tabTypes, selectTabType } = this.props
    const { open } = this.state
    const tabTypeList = tabTypes.filter(tab => tab.name !== 'Delivery Order')

    return (
      <>
        <Popover
          width={200}
          isOpen={open}
          changeState={this.togglePopover}
          button={<StyledAddNewTab className={'mdi mdi-plus'} onClick={() => this.togglePopover()} />}
        >
          <TabList className={'capitalize'}>
            {tabTypeList.map(tabType => {
              let disabled = false

              switch (tabType.callback) {
                case TabType.scheduler:
                  disabled = Boolean(getSchedulerTabs(getStore().getState()).length)
                  break
                default:
              }

              return (
                <Tab
                  className={cn(`mdi ${tabType.icon}`, { disabled })}
                  key={tabType.callback}
                  onClick={disabled ? undefined : () => selectTabType(tabType.callback)}
                >
                  {tabType.name}
                </Tab>
              )
            })}
          </TabList>
        </Popover>
      </>
    )
  }
}

export default AddNewTab
