import { BusinessPartnerViewDTO, SteamShipLineViewDTO } from '../../../api/origin/business-logic'
import * as R from 'remeda'
import { tryAddSteamShipLineToStore } from '../../../components/common/steamShipLine/epics'
import { parseDTO } from '../parseDTO'

export const parseSteamShipLines = (steamShipLines: SteamShipLineViewDTO[]) => {
  const lists: { businessPartners: BusinessPartnerViewDTO[] } = {
    businessPartners: []
  }

  const clearedSteamShipLines = steamShipLines.filter(Boolean).map(steamShipLine => {
    if (steamShipLine.fullObject && steamShipLine.businessPartner) {
      lists.businessPartners.push(steamShipLine.businessPartner)
      return R.omit(steamShipLine, ['businessPartner'])
    }
    return steamShipLine
  })

  parseDTO.businessPartner(lists.businessPartners)
  tryAddSteamShipLineToStore(clearedSteamShipLines)
}
