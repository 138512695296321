import * as React from 'react'
import { TabActionType, TabType, TGridColumns } from '../../common/tabs/interfaces'
import { GridItemDriver } from './GridItemDriver'
import { OldGridItem } from './OldGridItem'

type Props = {
  unitId: string
  columnsSettings: TGridColumns
  disable: boolean
  tabId: string
  tabType: TabType
  isSelected?: boolean
  isDisabledSelect?: boolean
  isPinned?: boolean
  actionType?: TabActionType
  handleGridItemVersion: (unit: any) => void
}

export const GridItem = (props: Props) => {
  const { tabType } = props

  switch (tabType) {
    case TabType.driver:
      return <GridItemDriver {...props} />
    default:
      return <OldGridItem {...props} />
  }
}
