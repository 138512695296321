import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { Window } from './preview'
import { Popover } from './popover'
import { IGridItemActions } from '../../../../../contexts/GridItemContext'
import { DeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'

type OwnProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  checkTerminalIntegration: () => void
  actions: IGridItemActions
}

export const TerminalIntegrationWidget: React.SFC<OwnProps> = ({
  dispatchDeliveryOrder,
  checkTerminalIntegration,
  actions
}) => {
  const terminalCode = getTerminalCode(dispatchDeliveryOrder).toUpperCase()
  return (
    <Widget
      isTableWidget={true}
      title={'Mismatches'}
      bottomActions={true}
      disable={!dispatchDeliveryOrder.terminalMismatches.length}
      extraPreviewStyle={extraPreviewStyle}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        terminalCode,
        checkTerminalIntegration,
        disable: actions.isModified,
        dispatchDeliveryOrder
      }}
      detailsProps={{
        terminalCode,
        dispatchDeliveryOrder
      }}
    />
  )
}

const getTerminalCode = (dispatchDeliveryOrder: IDispatchDeliveryOrder): string => {
  switch (oc(dispatchDeliveryOrder).deliveryOrder.type()) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return oc(dispatchDeliveryOrder as any).pickupStage.location.terminalIntegration.code('')
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
      return oc(dispatchDeliveryOrder as any).returnStage.location.terminalIntegration.code('')
    default:
      return ''
  }
}

const extraPreviewStyle = { height: 32, flex: '0 0 152px', padding: 0 }
