import * as React from 'react'
import { DispatchDeliveryOrder } from './dispatchDeliveryOrder'
import { Container, Column } from '../../../../UI/GridSystem'
import { AddRow } from '../../../dispatchDeliveryOrder/views/Details/Activities/styledComponents'
import { createNewDDO } from '../../reducers'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../../../api/api'
import { Columns } from '../../../dispatchDeliveryOrder/views/Details/Activities/Table/styles'
import { Title } from './styles'
import { oc } from 'ts-optchain'
import { isNewObject } from '../../../../../services/DTO'
import {
  alertOnChangingMainStreetTurnPropsOfDO,
  updateDDOContainer
} from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { correctDDOLocations, correctLocation } from '../../../../../services/DTO/deliveryOrder/functions'
import { ILocation } from '../../../location/interfaces'
import { DeliveryOrderContext } from '../context'
import { IDispatchDeliveryOrder } from '../../../dispatchDeliveryOrder/interfaces'
import { IContainer } from '../../../containers/interfaces'
import { parseDTO } from '../../../../../services/DTO/parseDTO'

type OwnProps = {}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const DispatchDeliveryOrders: React.SFC<Props> = () => {
  const {
    state: { deliveryOrder, isImport, isExport, isRepo },
    functions: { modifyDeliveryOrder, modifyDeliveryOrderField }
  } = React.useContext(DeliveryOrderContext)
  const [fetching, setFetching] = React.useState(true)
  const { dispatchDeliveryOrderIds = [], dispatchDeliveryOrders = [] } = deliveryOrder
  const sortedDispatchDeliveryOrders = [...dispatchDeliveryOrders].sort((a: any, b: any) => a.number - b.number)

  React.useEffect(() => {
    if (!isNewObject(deliveryOrder) && dispatchDeliveryOrderIds.length) {
      callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, 'deliveryOrder.id%%' + deliveryOrder.id)
        .toPromise()
        .then(receivedDDOs => {
          parseDTO.dispatchDeliveryOrder(receivedDDOs)
        })
        .finally(() => {
          setFetching(false)
        })
    } else {
      setFetching(false)
    }
  }, [])

  const showHazmatColumn = sortedDispatchDeliveryOrders.some(ddo => ddo.hazmatIndicator)

  return (
    <>
      <Title>{`Dispatch Delivery Orders ${dispatchDeliveryOrders.length}`}</Title>
      <Container isGrid={true} flexClear={true} padding={{ top: 20, left: 20, right: 20, bottom: 200 }}>
        {Boolean(dispatchDeliveryOrders.length) && (
          <>
            <Columns className={'darkBg'} style={{ paddingRight: 40, marginBottom: 10 }}>
              <Column style={{ paddingLeft: 10 }} columns={3} isFixed={true}>
                #
              </Column>
              <Column columns={10} className={'required'} isFixed={true}>
                {'Equipment'}
              </Column>
              {(isImport || isRepo) && (
                <Column className={isImport ? 'required' : ''} columns={9} isFixed={true}>
                  Container
                </Column>
              )}
              {isImport && <Column columns={8}>Seal</Column>}
              <Column columns={isExport ? 10 : 8}>Pickup</Column>
              {!isRepo && <Column columns={isExport ? 10 : 8}>Delivery</Column>}
              <Column columns={isExport ? 10 : 8}>Return</Column>
              {!isRepo && (
                <Column columns={11} isFixed={true}>
                  Appointment Date
                </Column>
              )}
              <Column columns={6} isFixed={true}>
                Cargo
              </Column>
              {showHazmatColumn && (
                <Column className={'required'} columns={10} isFixed={true}>
                  Hazmat
                </Column>
              )}
              <Column columns={12} isFixed={true}>
                Weight
              </Column>
            </Columns>

            <div id={'dispatch-delivery-order-list'}>
              {sortedDispatchDeliveryOrders.map(currentDDO => (
                <DispatchDeliveryOrder
                  key={currentDDO.id}
                  dispatchDeliveryOrder={currentDDO}
                  showHazmatColumn={showHazmatColumn}
                  deleteDispatchDeliveryOrder={() =>
                    modifyDeliveryOrderField('dispatchDeliveryOrders')(
                      dispatchDeliveryOrders.filter(ddo => ddo.id !== currentDDO.id)
                    )
                  }
                  updateDispatchDeliveryOrder={(updatedDDO: IDispatchDeliveryOrder) =>
                    modifyDeliveryOrderField('dispatchDeliveryOrders')(
                      deliveryOrder.dispatchDeliveryOrders.map(ddo => (ddo.id === updatedDDO.id ? updatedDDO : ddo))
                    )
                  }
                  updateDispatchDeliveryOrderMultipleFields={fields =>
                    modifyDeliveryOrderField('dispatchDeliveryOrders')(
                      deliveryOrder.dispatchDeliveryOrders.map(ddo =>
                        ddo.id === currentDDO.id ? { ...ddo, ...fields } : ddo
                      )
                    )
                  }
                  updateContainer={(container: IContainer) => {
                    const updatedDDO = {
                      ...deliveryOrder,
                      steamShipLineId: deliveryOrder.steamShipLineId || oc(container).steamShipLineId(),
                      steamShipLine: deliveryOrder.steamShipLine || oc(container).steamShipLine(),
                      dispatchDeliveryOrders: deliveryOrder.dispatchDeliveryOrders.map(ddo =>
                        currentDDO.id === ddo.id ? updateDDOContainer(container, ddo, null) : ddo
                      )
                    }

                    alertOnChangingMainStreetTurnPropsOfDO(updatedDDO, deliveryOrder)
                    return modifyDeliveryOrder(updatedDDO)
                  }}
                  updateLocation={(updatedDDO, location) => {
                    return modifyDeliveryOrder({
                      ...deliveryOrder,
                      pickupLocation: correctLocation(deliveryOrder.pickupLocation as ILocation, location),
                      deliveryLocation: correctLocation(deliveryOrder.deliveryLocation as ILocation, location),
                      returnLocation: correctLocation(deliveryOrder.returnLocation as ILocation, location),
                      dispatchDeliveryOrders: deliveryOrder.dispatchDeliveryOrders.map(ddo =>
                        ddo.id === updatedDDO.id ? updatedDDO : correctDDOLocations(location)(ddo)
                      )
                    })
                  }}
                />
              ))}
            </div>
          </>
        )}

        {deliveryOrder.type && (
          <AddRow
            id={'add-dispatch-delivery-order'}
            className={!dispatchDeliveryOrders.length && !fetching ? 'red' : 'darkBg'}
            rows={9}
            alignCenter={true}
            justifyCenter={true}
            margin={{ top: 20, bottom: 20 }}
            onClick={() =>
              modifyDeliveryOrderField('dispatchDeliveryOrders')([
                ...dispatchDeliveryOrders,
                createNewDDO(deliveryOrder)
              ])
            }
          >
            Add Dispatch Delivery Order
          </AddRow>
        )}
      </Container>
    </>
  )
}

export default DispatchDeliveryOrders
