import { SearchRequest } from '../searchRequest'
import { callAPI, equipmentAPI, EquipmentDTO } from '../../../../../api/api'
import * as React from 'react'
import { TSearchField } from '../'
import { newEquipment } from '../../../../common/equipment/reducers'
import { isNewObject } from '../../../../../services/DTO'
import * as R from 'remeda'
import { convertTimeMinusTimezoneOffsetToISOString } from '../../../DatePicker/Functions'
import { tryAddEquipmentToStore } from '../../../../common/equipment/epics'
import { checkEquipmentNumberValidation } from '../../../../../services/DTO/equipment'

export const EquipmentSearch = (props: TSearchField) => {
  const getListRequest = (term: string) => callAPI(equipmentAPI.search, null, term).toPromise()
  let getList = getListRequest

  if (props.allowCreate) {
    getList = (term: string) =>
      getListRequest(term).then(list => {
        if (!list.length) {
          return term.length
            ? [
                {
                  ...R.omit(
                    {
                      ...newEquipment,
                      equipmentType: EquipmentDTO.EquipmentTypeEnum.OCEANCONTAINERCHASSIS,
                      ownership: EquipmentDTO.OwnershipEnum.RENTED,
                      pickupDate: convertTimeMinusTimezoneOffsetToISOString(Date.now()),
                      availability: false,
                      unavailabilityReason: EquipmentDTO.UnavailabilityReasonEnum.ASSIGNED
                    },
                    ['model', 'make', 'insurance', 'vin', 'licensePlate', 'maintenanceInspections']
                  ),
                  chassisNumber: term
                }
              ]
            : []
        }
        return list
      })
  }

  return (
    <SearchRequest
      {...props}
      maxLength={11}
      toUpperCase={true}
      label={props.value ? props.value.chassisNumber : null}
      getList={getList}
      getDetails={value =>
        isNewObject(value) ? Promise.resolve(value) : callAPI(equipmentAPI.findById, value.id).toPromise()
      }
      addToStore={tryAddEquipmentToStore}
      makeDropDownItem={response => {
        if (isNewObject(response)) {
          const validation = [
            {
              valid: checkEquipmentNumberValidation(response.chassisNumber),
              errorMessage: '• Invalid number. 4 letters and 6 or 7 numbers allowed'
            }
          ]
            .filter(item => !item.valid)
            .map(item => item.errorMessage)
          const errorMessages = validation.join(`\n`)

          return {
            disabled: Boolean(errorMessages),
            className: 'create validation' + (errorMessages ? ' invalid' : ''),
            before: 'Create',
            after: errorMessages,
            label: response.chassisNumber,
            value: response
          }
        } else {
          return { label: response.chassisNumber, value: response }
        }
      }}
    />
  )
}
