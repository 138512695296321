import * as React from 'react'
import { InputField } from '../../../../../UI/DataFields/Input'
import { ISteamShipLine } from '../../../interfaces'
import { GridColumn, Container } from '../../../../../UI/GridSystem'
import theme from '../../../../../../styles/theme'
import { Delete } from '../../../../../UI/styledComponents'
import { FeeScheduleDTO } from '../../../../../../api/origin/business-logic/api'
import { Title, Date, ColumnTitles, Row, AddRow } from './styledComponents'

type Props = {
  ssl: ISteamShipLine
  createContainerDetentionPerDiemFeeSchedule: () => void
  deleteContainerDetentionPerDiemFeeSchedule: (id: string) => void
  changeContainerDetentionPerDiemFeeSchedule: (id: string, containerDetentionPerDiemFeeSchedule: FeeScheduleDTO) => void
}

const ContainerDetentionPerDiemFeeSchedule: React.SFC<Props> = ({
  ssl,
  createContainerDetentionPerDiemFeeSchedule,
  deleteContainerDetentionPerDiemFeeSchedule,
  changeContainerDetentionPerDiemFeeSchedule
}) => (
  <Container
    columns={28}
    maxColumns={40}
    flexBasis={'auto'}
    isGrid={true}
    background={theme.colors.lightGrayBackground}
    padding={{ top: 20, left: 20, right: 20 }}
  >
    <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
      Container Detention Per Diem Fee Schedule
    </Title>

    {ssl.containerDetentionPerDiemFeeSchedule && !!ssl.containerDetentionPerDiemFeeSchedule.length && (
      <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 20 }}>
        <Container columns={7}>From Day</Container>
        <Container columns={7}>To Day</Container>
        <Container columns={6}>Per Diem Cost. $</Container>
      </ColumnTitles>
    )}

    {ssl.containerDetentionPerDiemFeeSchedule.map(containerDetentionPerDiemFeeSchedule => (
      <Row rows={9} margin={{ bottom: 10 }} key={containerDetentionPerDiemFeeSchedule.id}>
        <Date columns={7}>
          <InputField.Numeric
            value={
              containerDetentionPerDiemFeeSchedule.fromDay === null ||
              containerDetentionPerDiemFeeSchedule.fromDay === undefined
                ? ''
                : String(containerDetentionPerDiemFeeSchedule.fromDay)
            }
            onChange={fromDay =>
              changeContainerDetentionPerDiemFeeSchedule(containerDetentionPerDiemFeeSchedule.id, {
                ...containerDetentionPerDiemFeeSchedule,
                fromDay
              })
            }
          />
        </Date>
        <Date columns={7}>
          <InputField.Numeric
            value={
              containerDetentionPerDiemFeeSchedule.toDay === null ||
              containerDetentionPerDiemFeeSchedule.toDay === undefined
                ? ''
                : String(containerDetentionPerDiemFeeSchedule.toDay)
            }
            onChange={toDay =>
              changeContainerDetentionPerDiemFeeSchedule(containerDetentionPerDiemFeeSchedule.id, {
                ...containerDetentionPerDiemFeeSchedule,
                toDay
              })
            }
          />
        </Date>
        <GridColumn columns={6}>
          <InputField.Money
            value={containerDetentionPerDiemFeeSchedule.cost}
            onChange={cost =>
              changeContainerDetentionPerDiemFeeSchedule(containerDetentionPerDiemFeeSchedule.id, {
                ...containerDetentionPerDiemFeeSchedule,
                cost
              })
            }
          />
        </GridColumn>
        <Delete
          className="mdi mdi-delete"
          columns={2}
          isFixed={true}
          onClick={() => deleteContainerDetentionPerDiemFeeSchedule(containerDetentionPerDiemFeeSchedule.id)}
        />
      </Row>
    ))}

    <AddRow
      rows={9}
      alignCenter={true}
      justifyCenter={true}
      margin={{ bottom: 20 }}
      onClick={() => createContainerDetentionPerDiemFeeSchedule()}
    >
      Add Fee
    </AddRow>
  </Container>
)

export default ContainerDetentionPerDiemFeeSchedule
