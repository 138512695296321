import { newPowerUnit } from '../../components/common/powerUnit/reducers'
import { newEquipment } from '../../components/common/equipment/reducers'
import { newContainer } from '../../components/common/containers/reducers'
import { newLocation } from '../../components/common/location/reducers'
import { createNewCustomer } from '../../components/common/customer/reducers'
import { newSteamShipLine } from '../../components/common/steamShipLine/reducers'
import { newSubClient } from '../../components/common/subClient/reducers'
import { TabType } from '../../components/common/tabs/interfaces'
import { createDriver } from '../createEntity/createDriver'

export const createEntity = (type: TabType) => {
  switch (type) {
    case TabType.location:
      return newLocation()
    case TabType.customer:
      return createNewCustomer()
    case TabType.driver:
      return createDriver()
    case TabType.steamShipLine:
      return newSteamShipLine
    case TabType.subClient:
      return newSubClient()
    case TabType.container:
      return newContainer
    case TabType.powerUnit:
      return newPowerUnit
    case TabType.equipment:
      return newEquipment
    default:
      return null
  }
}
