import * as React from 'react'
import { IGridItemActions } from '../../../../../../../contexts/GridItemContext'
import { UserDTO } from '../../../../../../../api/origin/user-service'

type OwnProps = { user: UserDTO; actions: IGridItemActions }

type DispatchProps = {}

type Props = DispatchProps & OwnProps

export const General = (props: Props) => {
  const { user, actions } = props
  return <div>User</div>
}
