import { getItemFromStorage, StorageKeys } from '../services/storageService/storageService'
import { UserDTO } from '../api/origin/user-service'
import { WEBSOCKET_URL, WEBSOCKET_PING_INTERVAL, WEBSOCKET_PING_TIMEOUT } from '../config'
import { handleReceivedChatMessage } from './CommunicationHub/functions/handleReceivedChatMessage'
import { WebsocketEvent, IWebsocketPingData, IWebsocketData, WebsocketService } from '../services/websocket/interfaces'
import { websocketRequestForDTOUpdate } from '../services/websocket'
import { correctDriverPosition } from '../services/functions/correctDriverPosition'
import { getDispatch } from '../store'
import { listsActions } from '../store/reducers/lists'
import { ListEntityType } from '../store/reducers/lists/interfaces'
import { documentServiceWebsocket } from '../services/websocket/documentServiceWebsocket'
import { handleReceivedNotification } from './CommunicationHub/functions/handleReceivedNotification'

class Sockets {
  ws: WebSocket = null
  user: any = null
  intervalId: any = null
  ping: number = new Date().getTime()

  public init(user: UserDTO | null) {
    this.user = user
    this.wsConnect()

    return true
  }

  public destroy() {
    return false
  }

  wsConnect = () => {
    if (!WEBSOCKET_URL) {
      // tslint:disable-next-line:no-console
      return console.error('WEBSOCKET_URL not exist')
    }
    const accessToken = getItemFromStorage(StorageKeys.AccessToken)
    this.ws = new WebSocket(WEBSOCKET_URL, accessToken)
    this.ws.onmessage = this.onMessage
    this.ws.onclose = this.onClose
    this.ws.onerror = this.onError

    clearInterval(this.intervalId)
    this.intervalId = setInterval(this.onPing, WEBSOCKET_PING_INTERVAL)
  }

  onPing = () => {
    const ts = new Date().getTime()

    if (ts - this.ping > WEBSOCKET_PING_TIMEOUT) {
      this.wsConnect()
    }
    if (this.ws.readyState === 1) {
      this.ws.send(JSON.stringify({ ping: ts }))
    }
  }

  onMessage = async (event: any) => {
    const body = event.data
    let data

    try {
      data = JSON.parse(body) as IWebsocketPingData | IWebsocketData
    } catch (error) {
      // tslint:disable-next-line:no-console
      return console.error(`Can't parse message`, body)
    }

    if ('ping' in data) {
      this.ping = new Date().getTime()
    } else if ('service' in data) {
      switch (data.service) {
        case WebsocketService.DELIVERY_ORDER:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.QMP:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.USER:
          if (data.payload) {
            getDispatch()(
              listsActions.update({
                update: {
                  [ListEntityType.contact]: [data.payload]
                }
              })
            )
          }

          break
        case WebsocketService.CHUB:
          if (data.payload) {
            if (data.type === 'NotificationDTO') {
              handleReceivedNotification(data.payload, data.eventType)
            } else {
              handleReceivedChatMessage(data.payload)
            }
          }

          break
        case WebsocketService.ROUTE:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: correctDriverPosition(data.payload),
                eventType: WebsocketEvent.UPDATED,
                DTOType: 'DRIVERPOSITION'
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse driverPosition`, error)
            }
          }

          break
        case WebsocketService.MOVE:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse MOVE`, error)
            }
          }

          break
        case WebsocketService.DOCUMENT:
          if (data.payload) {
            try {
              documentServiceWebsocket(data)
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse DOCUMENT`, error)
            }
          }

          break
        case WebsocketService.DISPATCH_VENDOR:
          if (data.payload) {
            try {
              websocketRequestForDTOUpdate({
                data: data.payload,
                eventType: data.eventType,
                DTOType: data.type
              })
            } catch (error) {
              // tslint:disable-next-line:no-console
              return console.error(`Can't parse MOVE`, error)
            }
          }

          break
        default:
      }
    }
  }

  onClose = () => {
    this.wsConnect()
  }

  onError = (error: any) => {
    // tslint:disable-next-line:no-console
    console.error('websocket error', error)
  }
}

export const WsHubSockets = new Sockets()
