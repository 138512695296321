import { oc } from 'ts-optchain'
import { getContainerTypeNameWithCode, getContainerTypesDropDownList } from '../../../../services/DTO/containerType'
import { SearchByList } from '../SearchByList'
import * as React from 'react'
import { ContainerTypeDTO } from '../../../../api/origin/business-logic'
import { containerLists } from '../../../../services/select/containerLists'

type Props = {
  disabled?: boolean
  title?: string
  onlyCode?: boolean
  required?: boolean
  placeholder?: string
  containerType: ContainerTypeDTO
  onChange: (container: ContainerTypeDTO) => void
}

export const ContainerTypeSearch = ({
  disabled,
  title,
  placeholder,
  onlyCode,
  required,
  containerType,
  onChange
}: Props) => (
  <SearchByList
    disabled={disabled}
    title={title}
    required={required}
    placeholder={placeholder}
    label={onlyCode ? oc(containerType).code('') : getContainerTypeNameWithCode(containerType)}
    dropDownList={getContainerTypesDropDownList(containerLists.containerTypes, true)}
    onChange={onChange}
  />
)
