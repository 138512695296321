import { callAPI, containerAPI } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { addContainersToStore } from '../action'
import { ContainerDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'

export const tryAddContainerToStore = (items: ContainerDTO | ContainerDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().container, items, addContainersToStore)
}

export const getContainerById = (id: string): Promise<ContainerDTO> =>
  callAPI(containerAPI.findById, id)
    .toPromise()
    .then(container => {
      parseDTO.container(container)
      return container
    })

export const findAllContainers = async ({ sort, filter }: any): Promise<ContainerDTO[]> => {
  return callAPI(containerAPI.findAll, filter, sort)
    .toPromise()
    .then(containers => {
      parseDTO.container(containers)
      return containers
    })
    .catch(catchListErrors)
}
