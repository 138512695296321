import * as React from 'react'
import { BoldLine, Text, Title } from '../../styles'
import { EquipmentPropsType } from './popover'
import styled from 'styled-components'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { getStreetTurnStatus } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderStreetTurnDTO } from '../../../../../api/origin/business-logic'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { openDDOinNewTab } from '../../../../../services/DTO/dispatchDeliveryOrder'

export const Window: React.FC<{
  equipmentProps: EquipmentPropsType
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}> = ({ equipmentProps, dispatchDeliveryOrder }) => {
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn: dispatchDeliveryOrder && dispatchDeliveryOrder.streetTurn,
    streetTurnCount: dispatchDeliveryOrder && dispatchDeliveryOrder.streetTurnCount
  })
  const streetTurnStatusColor = ddoDirectory.streetTurnStatusColor[streetTurnStatus]
  const isExportDDO = dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const streetTurn = dispatchDeliveryOrder.streetTurn

  return (
    <>
      <Title rows={2} margin={{ bottom: 10 }}>
        Equipment
      </Title>
      <BoldLine className={'letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
        {((equipmentProps.size && equipmentProps.size.code) || '') + ': ' + equipmentProps.containerNumber}
      </BoldLine>
      <Text className={'opacity'} rows={3} alignCenter={true}>
        CHASSIS: {equipmentProps.chassis}
      </Text>
      {Boolean(streetTurnStatus) && (
        <StyledStreetTurnLabel style={{ backgroundColor: streetTurnStatusColor }}>
          ST {ddoDirectory.streetTurnStatusLabel[streetTurnStatus]}
          {(streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED ||
            streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED) && (
            <StreetTurnDDOLink
              style={{ marginTop: 3 }}
              onClick={event =>
                openDDOinNewTab({
                  // @ts-ignore
                  event,
                  ddoNumber: isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber,
                  ddoId: isExportDDO ? streetTurn.ddoImportId : streetTurn.ddoExportId
                })
              }
            >
              DDO# {isExportDDO ? streetTurn.ddoImportNumber : streetTurn.ddoExportNumber}
            </StreetTurnDDOLink>
          )}
        </StyledStreetTurnLabel>
      )}
    </>
  )
}

const StyledStreetTurnLabel = styled.div`
  min-height: 20px;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 4px;
`

const StreetTurnDDOLink = styled.div`
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    color: #5599ff;
  }
`
