import * as React from 'react'
import { Widget } from '../../'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { ICustomerView } from '../../../../common/customer/interfaces'
import { Window } from './preview'
import { Popover } from './popover'
import { BusinessPartner } from '../../../../common/businessPartner/types'
import { TContact } from '../../../../common/contact/interfaces'
import { checkCustomerValidation } from '../../../../../services/DTO/customer'

type OwnProps = {
  disabled?: boolean
  customer: ICustomerView
  isModifiedMode?: boolean
  updateCustomer: (customer: ICustomerView, updateInitialState?: boolean) => void
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

const CustomerWidget: React.SFC<Props> = ({ customer, updateCustomer, isModifiedMode, disabled }) => {
  const businessPartner = oc(customer).businessPartner(null)
  const contacts = oc(businessPartner).contacts(null)
  const primaryContact = contacts ? contacts.find(item => item.primary) : null

  const updateBusinessPartner = (updatedBusinessPartner: BusinessPartner) =>
    updateCustomer({ ...customer, businessPartner: updatedBusinessPartner })

  const updatePrimaryContact = (updatedPrimaryContact: TContact) =>
    updateCustomer({
      ...customer,
      businessPartner: {
        ...businessPartner,
        contacts: primaryContact
          ? businessPartner.contacts.map(item => (item.id === updatedPrimaryContact.id ? updatedPrimaryContact : item))
          : [...businessPartner.contacts, updatedPrimaryContact]
      }
    })

  return (
    <Widget
      title={'Customer'}
      highlighted={!customer || (customer.fullObject && !checkCustomerValidation(customer, true))}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{
        title: 'Customer',
        value: oc(businessPartner).legalName('') || oc(customer).name('')
      }}
      detailsProps={{
        disabled,
        isModifiedMode,
        customer,
        businessPartner,
        primaryContact,
        updateCustomer,
        updateBusinessPartner,
        updatePrimaryContact
      }}
    />
  )
}

export default connect(
  null,
  null
)(CustomerWidget)
