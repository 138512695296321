import { IModifyGridItemActions } from '../../uiSettingsService/tabs'
import { makeObjectSavePromise } from '../saveDTO'
import { isNewObject } from '../index'
import { getBuySideQuoteStatus } from './status'
import { BuySideQuoteDTO, buySideQuoteAPI } from '../../../api/api'
import { tryAddBSQsToStore } from './epics'

export const getBuySideQuoteSavePromise = (
  buySideQuote: BuySideQuoteDTO,
  actions?: IModifyGridItemActions
): Promise<BuySideQuoteDTO> => {
  if (!buySideQuote || !buySideQuote.fullObject) {
    return Promise.resolve(null)
  }

  const transformedObjectForSaving: BuySideQuoteDTO = {
    ...buySideQuote,
    surcharges: buySideQuote.surcharges.map(surcharge =>
      isNewObject(surcharge) ? { ...surcharge, id: undefined } : surcharge
    ),
    miscSurcharges: buySideQuote.miscSurcharges.map(miscSurcharge =>
      isNewObject(miscSurcharge) ? { ...miscSurcharge, id: undefined } : miscSurcharge
    )
  }

  return makeObjectSavePromise(
    isNewObject(buySideQuote) || getBuySideQuoteStatus(buySideQuote).needToSave,
    transformedObjectForSaving,
    actions,
    buySideQuoteAPI,
    tryAddBSQsToStore
  ) as Promise<BuySideQuoteDTO>
}
