import * as R from 'remeda'
import { tryAddBSQRatesToStore, tryAddSSQRatesToStore } from '../DTO/rate/epics'
import { activityListCollector, ongoingActivityGroupsCollector } from '../DTO/activity/functions'
import { websocketCollector } from './collectors'
import { QMPAddObjectsToStoreAndTabs, QMPObjectType, QMPUpdateStoreObject } from './qmpActions'
import { tryAddSSQsToStore } from '../DTO/sellSideQuote/epics'
import { tryAddBSQsToStore } from '../DTO/buySideQuote/epics'
import { tryAddCustomerQuotesToStore } from '../DTO/customerQuote/epics'
import { tryAddStreetTurnsToStore } from '../DTO/streetTurn/epics'
import { debuggingMode, consoleLogStyles } from '../debug'
import { tryAddPowerUnitToStore } from '../../components/common/powerUnit/epics'
import { tryAddEquipmentToStore } from '../../components/common/equipment/epics'
import { tryAddContactToStore } from '../../components/common/contact/epics'
import { parseDTO } from '../DTO/parseDTO'
import { WSProps, WebsocketEvent } from './interfaces'
import { updateStoreObjectsWithTabsItemList } from './actions'
import { movesAction } from './movesActions'
import { getDispatch, getListState } from '../../store'
import { getStore } from '../../store/configureStore'
import { listsActions } from '../../store/reducers/lists'
import { ListEntityType } from '../../store/reducers/lists/interfaces'
import { oc } from 'ts-optchain'

const activityListWebsocket = activityListCollector()
const ongoingActivityGroupsWebsocket = ongoingActivityGroupsCollector()

const equipmentCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'equipment',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: tryAddEquipmentToStore
    })
})
const powerUnitCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'powerUnit',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: tryAddPowerUnitToStore
    })
})
const locationCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'location',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.location
    })
})
const contactCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'contact',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: tryAddContactToStore
    })
})
const businessPartnerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'businessPartner',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.businessPartner
    })
})
const driverCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'driver',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.driver,
      listState: getListState().driver
    })
})
const steamShipLineCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'steamShipLine',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.steamShipLine
    })
})
const containerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'container',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.container
    })
})
const subClientCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'subClient',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.subClient
    })
})
const customerCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'customer',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.customer
    })
})
const dispatchDeliveryOrderCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) =>
    updateStoreObjectsWithTabsItemList({
      objectType: 'dispatchDeliveryOrder',
      objectsToAdd: addItems,
      objectsToUpdate: updateItems,
      actionToUpdate: parseDTO.dispatchDeliveryOrder
    })
})
const deliveryOrderCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) => parseDTO.deliveryOrder(addItems.concat(updateItems))
})
const sellSideQuoteRateCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    QMPAddObjectsToStoreAndTabs(QMPObjectType.ssqRates, addItems, tryAddSSQRatesToStore)
    QMPUpdateStoreObject(QMPObjectType.ssqRates, updateItems, tryAddSSQRatesToStore)
  }
})
const buySideQuoteRateCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    QMPAddObjectsToStoreAndTabs(QMPObjectType.bsqRates, addItems, tryAddBSQRatesToStore)
    QMPUpdateStoreObject(QMPObjectType.bsqRates, updateItems, tryAddBSQRatesToStore)
  }
})
const customerQuoteCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    QMPAddObjectsToStoreAndTabs(QMPObjectType.customerQuotes, addItems, tryAddCustomerQuotesToStore)
    QMPUpdateStoreObject(QMPObjectType.customerQuotes, updateItems, tryAddCustomerQuotesToStore)
  }
})
const sellSideQuoteCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems, deleteItems }) =>
    tryAddSSQsToStore(addItems.concat(updateItems.map(item => R.omit(item, ['forceUpdate']))), deleteItems)
})
const buySideQuoteCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems, deleteItems }) =>
    tryAddBSQsToStore(addItems.concat(updateItems), deleteItems)
})
const streetTurnCollector = websocketCollector({
  delay: 3000,
  receivedObjectsAction: ({ addItems, updateItems }) => tryAddStreetTurnsToStore(addItems.concat(updateItems))
})
const moveCollector = websocketCollector({
  delay: 1000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: movesAction
})
// const moveItemCollector = websocketCollector({
//   delay: 1000,
//   doNotFilterLatestItems: true,
//   receivedObjectsAction: moveItemsAction
// })
const driverPositionCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    getDispatch()(
      listsActions.update({
        update: { [ListEntityType.driverPosition]: [...addItems, ...updateItems] }
      })
    )
  }
})
const driverActivityCollector = websocketCollector({
  delay: 3000,
  doNotFilterLatestItems: true,
  receivedObjectsAction: ({ addItems, updateItems }) => {
    getDispatch()(
      listsActions.update({
        update: { [ListEntityType.driverActivity]: [...addItems, ...updateItems] }
      })
    )
  }
})

export const websocketRequestForDTOUpdate = (props: WSProps): void => {
  const { eventType, DTOType } = props
  let data = props.data

  if (eventType === WebsocketEvent.DELETED) {
    if (typeof data === 'object' && oc(data as any).id()) {
      data = oc(data as any).id()
    }
  }

  logWS(props)

  switch (DTOType.toUpperCase().replace(/VIEW|DTO|-/g, '')) {
    case 'EQUIPMENT': {
      equipmentCollector(data, eventType)
      break
    }
    case 'POWERUNIT': {
      powerUnitCollector(data, eventType)
      break
    }
    case 'LOCATION': {
      locationCollector(data, eventType)
      break
    }
    case 'CONTACT': {
      contactCollector(data, eventType)
      break
    }
    case 'BUSINESSPARTNER': {
      businessPartnerCollector(data, eventType)
      break
    }
    case 'DRIVER': {
      driverCollector(data, eventType)
      break
    }
    case 'STEAMSHIPLINE': {
      steamShipLineCollector(data, eventType)
      break
    }
    case 'CONTAINER': {
      containerCollector(data, eventType)
      break
    }
    case 'SUBCLIENT': {
      subClientCollector(data, eventType)
      break
    }
    case 'CUSTOMER': {
      customerCollector(data, eventType)
      break
    }
    case 'DELIVERYORDER': {
      if (getStore().getState().deliveryOrder[data.id]) {
        deliveryOrderCollector(data, eventType)
      }
      break
    }
    case 'DISPATCHDELIVERYORDER': {
      dispatchDeliveryOrderCollector(data, eventType)
      break
    }
    case 'DOCUMENTATIONACTIVITY':
    case 'TRANSPORTATIONACTIVITY': {
      activityListWebsocket(data, eventType)
      break
    }
    case 'ONGOINGACTIVITYGROUP': {
      ongoingActivityGroupsWebsocket(data, eventType)
      break
    }
    case 'SELLSIDEQUOTERATE': {
      sellSideQuoteRateCollector(data, eventType)
      break
    }
    case 'BUYSIDEQUOTERATE': {
      buySideQuoteRateCollector(data, eventType)
      break
    }
    case 'SELLSIDEQUOTE': {
      sellSideQuoteCollector(data, eventType)
      break
    }
    case 'BUYSIDEQUOTE': {
      buySideQuoteCollector(data, eventType)
      break
    }
    case 'CUSTOMERQUOTE': {
      customerQuoteCollector(data, eventType)
      break
    }
    case 'DISPATCHDELIVERYORDERSTREETTURN': {
      streetTurnCollector(data, eventType)
      break
    }
    case 'ROUTEBUILDERMOVES': {
      moveCollector(data, eventType)
      break
    }
    // case 'ROUTEBUILDERMOVEITEMS': {
    //   moveItemCollector(data, eventType)
    //   break
    // }
    case 'DRIVERPOSITION': {
      driverPositionCollector(data, eventType)
      break
    }
    case 'DRIVERACTIVENESS': {
      data.updatedAt = new Date().getTime()
      driverActivityCollector(data, eventType)
      break
    }
    default:
      break
  }
}

const logWS = ({ eventType, DTOType, data }: WSProps) => {
  if (!debuggingMode.websoket) {
    return
  }

  let details = ''

  if (typeof data === 'object') {
    switch (DTOType.toUpperCase().replace(/VIEW|DTO/g, '')) {
      case 'DISPATCHDELIVERYORDER':
        details += ' DDO#: ' + data.number
        break
      case 'ONGOINGACTIVITYORDERGROUP':
        details += ' DDO#: ' + data.dispatchDeliveryOrderNumber
        details += ', ID: ' + data.id
        break
      default:
    }
  }

  // tslint:disable-next-line:no-console
  console.log(
    `%c[WS] %c${eventType} %c${DTOType} %c${details}`,
    consoleLogStyles.title,
    consoleLogStyles.websocketAction[eventType] || consoleLogStyles.websocketAction.DEFAULT,
    consoleLogStyles.type,
    consoleLogStyles.details
  )
  // tslint:disable-next-line:no-console
  console.log(data)
}
