import * as React from 'react'
import { BoldLine, Text, Title } from '../../styles'

export const Window: React.FC<{ text: string; bold: string }> = ({ text, bold }) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      Document
    </Title>
    <BoldLine className={'uppercase letterSpacing'} rows={3} alignCenter={true} margin={{ bottom: 5 }}>
      {bold}
    </BoldLine>
    <Text className={'opacity uppercase'} rows={3} alignCenter={true}>
      {text}
    </Text>
  </>
)
