import actionCreatorFactory, { ActionCreatorFactory } from 'typescript-fsa'
import { DispatchDeliveryOrderViewDTO } from '../../../../api/origin/business-logic'

const dispatchDeliveryOrder: ActionCreatorFactory = actionCreatorFactory('DispatchDeliveryOrder')
export const addDispatchDeliveryOrderToStore = dispatchDeliveryOrder<Record<string, DispatchDeliveryOrderViewDTO>>(
  'ADD_TO_STORAGE'
)
// export const addToList = dispatchDeliveryOrder<DispatchDeliveryOrderViewDTO[]>('ADD_TO_LIST')
// export const addToStore = dispatchDeliveryOrder<{ result: DispatchDeliveryOrderViewDTO[] }>('ADD_TO_STORE')
// export const addToListWithoutParse = dispatchDeliveryOrder<IDispatchDeliveryOrder[]>('ADD_TO_LIST_WO_PARSE')
// export const websocket = dispatchDeliveryOrder<DispatchDeliveryOrderViewDTO>('WEBSOCKET')
