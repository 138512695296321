import * as React from 'react'
import { DeductionDTO, Frequency, VendorDTO } from '../api/origin/vendor-accounting-service'
import { IDriver } from '../components/common/drivers/interfaces'

export type IDriverDeductionsContext = {
  isModifiedMode: boolean
  driver: IDriver
  vendorAccounting: VendorDTO
  deductions: DeductionDTO[]
  currentDeductions: DeductionDTO[]
  weeklyDeductions: DeductionDTO[]
  notWeeklyDeductions: DeductionDTO[]
  updatedDeductions: DeductionDTO[]
  removedDeductionIds: string[]

  showClosedDeductions: boolean
  toggleShowClosedDeductions: () => void

  setSpinnerState: (state: boolean) => void
  requestDriverDeductionsWithSpinner: () => void
  requestVendorAccounting: () => Promise<VendorDTO>

  createDeduction: (options?: Partial<DeductionDTO>) => void
  updateDeductionField: (deduction: DeductionDTO) => (prop: keyof DeductionDTO) => (value: any) => void
  removeDeductionById: (deductionId: string) => void

  refundEscrowDeduction: DeductionDTO
  setRefundEscrowDeduction: React.Dispatch<React.SetStateAction<DeductionDTO>>

  closeEscrowDeduction: DeductionDTO
  setCloseEscrowDeduction: React.Dispatch<React.SetStateAction<DeductionDTO>>

  payBackDeduction: DeductionDTO
  setPayBackDeduction: React.Dispatch<React.SetStateAction<DeductionDTO>>

  updateVendorAccountingField: (prop: keyof VendorDTO) => (value: any) => void

  showAccountingPopup: boolean
  setShowAccountingPopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const DriverDeductionsContext: React.Context<IDriverDeductionsContext> = React.createContext({
  isModifiedMode: null,
  driver: null,
  vendorAccounting: null,
  deductions: null,
  currentDeductions: null,
  weeklyDeductions: null,
  notWeeklyDeductions: null,
  updatedDeductions: null,
  removedDeductionIds: null,
  showClosedDeductions: null,
  toggleShowClosedDeductions: null,
  setSpinnerState: null,
  requestDriverDeductionsWithSpinner: null,
  requestVendorAccounting: null,
  createDeduction: null,
  updateDeductionField: null,
  removeDeductionById: null,
  payBackDeduction: null,
  setPayBackDeduction: null,
  refundEscrowDeduction: null,
  setRefundEscrowDeduction: null,
  closeEscrowDeduction: null,
  setCloseEscrowDeduction: null,
  updateVendorAccountingField: null,
  showAccountingPopup: null,
  setShowAccountingPopup: null
})
