import { NewRateDTO, RateType, SellSideQuoteRateGroupDTO } from '../../../../components/common/QMP/interfaces'
import { buySideQuoteRateAPI, callAPI, customerQuoteAPI, sellSideQuoteRateAPI } from '../../../../api/api'
import { createRateFilters } from '../../../../components/common/QMP/views/Tabs/Rate/functions'
import { BuySideQuoteRateDTO, CustomerQuoteDTO, SellSideQuoteRateDTO } from '../../../../api/origin/qmp-service'
import { getStore } from '../../../../store/configureStore'
import { catchDuplicatesError } from '../../../../components/common/QMP/views/functions'
import { correctInsertionObjectsIntoStorage } from '../../index'
import { SSQRateDTO, BSQRateDTO, RateDTO } from '../interfaces'
import { addBSQRatesToStore, addSSQRatesToStore } from '../actions'
import nanoid = require('nanoid')

export const tryAddSSQRatesToStore = (items: SSQRateDTO | SSQRateDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().ssqRates, items, addSSQRatesToStore)
}
export const tryAddBSQRatesToStore = (items: BSQRateDTO | BSQRateDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().bsqRates, items, addBSQRatesToStore)
}

export const getSellSideQuoteRateGroups = async (filters: any): Promise<SellSideQuoteRateGroupDTO[]> => {
  return callAPI(
    sellSideQuoteRateAPI.getSellSideQuoteRateGroups,
    createRateFilters(RateType.ssq, { ...filters, status: [CustomerQuoteDTO.StatusEnum.NEW] } as any, true)
  )
    .toPromise()
    .then(sellSideQuoteGroups => {
      const result = sellSideQuoteGroups.map(
        item =>
          ({
            ...item,
            id: nanoid(),
            number: item.rates.length === 1 ? item.rates[0].number : undefined
          } as SellSideQuoteRateGroupDTO)
      )

      if (result.length) {
        tryAddSSQRatesToStore(result)
      }

      return result
    })
}

export const getSellSideQuoteRatesOfCustomerQuote = async (
  customerQuote: CustomerQuoteDTO
): Promise<SellSideQuoteRateDTO[]> => {
  const { getState, dispatch } = getStore()
  const store = getState()
  let doRequest = false
  const storeSellSideQuoteRates = customerQuote.rateIds.map(rateId => {
    const rate = store.ssqRates[rateId]
    if (!rate) {
      doRequest = true
    }

    return rate
  })

  if (doRequest) {
    return callAPI(customerQuoteAPI.getRatesOfCustomerQuote, customerQuote.id)
      .toPromise()
      .then(sellSideQuoteRatesRequest => {
        tryAddSSQRatesToStore(sellSideQuoteRatesRequest)
        return sellSideQuoteRatesRequest
      })
  }

  return storeSellSideQuoteRates as SellSideQuoteRateDTO[]
}

export const getSellSideQuoteRateIds = (ids: string[]): Promise<SellSideQuoteRateDTO[]> => {
  return ids && ids.length
    ? callAPI(sellSideQuoteRateAPI.getSellSideQuoteRates, `id%%` + ids.join(','))
        .toPromise()
        .then(rates => {
          tryAddSSQRatesToStore(rates)
          return rates
        })
    : Promise.resolve([])
}

export const getBuySideQuoteRateIds = (ids: string[]): Promise<BuySideQuoteRateDTO[]> => {
  return ids && ids.length
    ? callAPI(buySideQuoteRateAPI.getBuySideQuoteRates, `id%%` + ids.join(','))
        .toPromise()
        .then(rates => {
          tryAddBSQRatesToStore(rates)
          return rates
        })
    : Promise.resolve([])
}

export const getSellSideQuoteRates = (
  filledFilters: any,
  omitIds?: string[],
  omitTypes?: SellSideQuoteRateDTO.TypeEnum[],
  isCustomerQuoteParent?: boolean
): Promise<SellSideQuoteRateDTO[]> => {
  return getRatesByFilters({
    rateType: RateType.ssq,
    filledFilters,
    omitRateIds: omitIds,
    omitRateTypes: omitTypes,
    isCustomerQuoteParent
  }) as any
}

export const getBuySideQuoteRates = (
  filledFilters: any,
  omitRateIds?: string[],
  omitRateTypes?: SellSideQuoteRateDTO.TypeEnum[],
  isCustomerQuoteParent?: boolean
): Promise<BuySideQuoteRateDTO[]> => {
  return getRatesByFilters({
    rateType: RateType.bsq,
    filledFilters,
    omitRateIds,
    omitRateTypes,
    isCustomerQuoteParent
  }) as any
}

export const getRatesByFilters = async (props: {
  rateType: RateType
  filledFilters: any
  requestParticularRateTypes?: (SellSideQuoteRateDTO.TypeEnum | BuySideQuoteRateDTO.TypeEnum)[]
  omitRateIds?: string[]
  omitRateTypes?: SellSideQuoteRateDTO.TypeEnum[]
  isCustomerQuoteParent?: boolean
}): Promise<RateDTO[]> => {
  const { rateType, filledFilters, omitRateIds, omitRateTypes, isCustomerQuoteParent } = props
  const idsToOmit = omitRateIds || []
  const typesForRequest =
    props.requestParticularRateTypes ||
    (omitRateTypes && omitRateTypes.length
      ? (rateType === RateType.ssq ? ssqRateTypes : bsqRateTypes).filter(type => !omitRateTypes.includes(type))
      : undefined)

  const correctFilters = typesForRequest ? { ...filledFilters, type: typesForRequest } : filledFilters
  const doRequest =
    rateType === RateType.ssq ? sellSideQuoteRateAPI.getSellSideQuoteRates : buySideQuoteRateAPI.getBuySideQuoteRates

  return callAPI(doRequest as any, createRateFilters(rateType, correctFilters as any, isCustomerQuoteParent))
    .toPromise()
    .then((rateListRequest: any) => {
      if (rateListRequest.length) {
        const addToList = rateType === RateType.ssq ? tryAddSSQRatesToStore : tryAddBSQRatesToStore
        addToList(rateListRequest)
      }

      if ((omitRateIds && omitRateIds.length) || (omitRateTypes && omitRateTypes.length)) {
        return rateListRequest.filter((rate: any) => !idsToOmit.includes(rate.id))
      }

      return rateListRequest
    })
}

export const doBatchRatesSaving = async (
  rateType: RateType,
  rates: NewRateDTO[],
  forceSaving?: boolean
): Promise<{ rejectedRates: NewRateDTO[]; resolvedRates: RateDTO[]; duplicateRates?: RateDTO[]; isError: boolean }> => {
  let isError = false
  let resolvedRates: any[] = []
  let rejectedRates: NewRateDTO[] = []
  let duplicateRates: RateDTO[] = []

  if (rates && rates.length) {
    const ratesForSaving = rates.map(rate => {
      const externalId = rate.id
      return { ...rate, id: undefined, externalId }
    })
    const request =
      rateType === RateType.ssq
        ? sellSideQuoteRateAPI.batchCreateSellSideQuoteRate
        : buySideQuoteRateAPI.batchCreateBuySideQuoteRate

    await callAPI(request as any, Boolean(forceSaving), ratesForSaving)
      .toPromise()
      .then((ratesResponse: any) => {
        resolvedRates = ratesResponse
      })
      .catch(async errors => {
        isError = true
        await catchDuplicatesError(rates, ids => getRatesByFilters({ rateType, filledFilters: { id: ids } }))(
          errors
        ).then(({ rejectedItems, duplicateItems }) => {
          rejectedRates = rejectedItems
          duplicateRates = duplicateItems
        })
      })
  }

  return { resolvedRates, rejectedRates, duplicateRates, isError }
}

const bsqRateTypes = [
  BuySideQuoteRateDTO.TypeEnum.ALLIN,
  BuySideQuoteRateDTO.TypeEnum.BASE,
  // BuySideQuoteRateDTO.TypeEnum.BOBTAIL,
  BuySideQuoteRateDTO.TypeEnum.CHASSIS,
  BuySideQuoteRateDTO.TypeEnum.CONGESTION,
  BuySideQuoteRateDTO.TypeEnum.DEDUCTION,
  BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER,
  BuySideQuoteRateDTO.TypeEnum.DEMURRAGE,
  BuySideQuoteRateDTO.TypeEnum.DETENTION,
  BuySideQuoteRateDTO.TypeEnum.DROPANDPICKUP,
  BuySideQuoteRateDTO.TypeEnum.DRYRUN,
  BuySideQuoteRateDTO.TypeEnum.FUEL,
  BuySideQuoteRateDTO.TypeEnum.HAZMAT,
  BuySideQuoteRateDTO.TypeEnum.LAYOVER,
  BuySideQuoteRateDTO.TypeEnum.OVERWEIGHT,
  BuySideQuoteRateDTO.TypeEnum.PERDIEM,
  BuySideQuoteRateDTO.TypeEnum.PREPULL,
  BuySideQuoteRateDTO.TypeEnum.REDELIVERY,
  BuySideQuoteRateDTO.TypeEnum.REEFER,
  BuySideQuoteRateDTO.TypeEnum.SCALE,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLE,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEHAZ,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEOW,
  BuySideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
  BuySideQuoteRateDTO.TypeEnum.STORAGE,
  BuySideQuoteRateDTO.TypeEnum.TANKER,
  BuySideQuoteRateDTO.TypeEnum.TOLLS
]
const ssqRateTypes = [
  SellSideQuoteRateDTO.TypeEnum.ALLIN,
  SellSideQuoteRateDTO.TypeEnum.BASE,
  SellSideQuoteRateDTO.TypeEnum.BOBTAIL,
  SellSideQuoteRateDTO.TypeEnum.CHASSIS,
  SellSideQuoteRateDTO.TypeEnum.CONGESTION,
  SellSideQuoteRateDTO.TypeEnum.DEDUCTION,
  SellSideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER,
  SellSideQuoteRateDTO.TypeEnum.DEMURRAGE,
  SellSideQuoteRateDTO.TypeEnum.DETENTION,
  SellSideQuoteRateDTO.TypeEnum.DROPANDPICKUP,
  SellSideQuoteRateDTO.TypeEnum.DRYRUN,
  SellSideQuoteRateDTO.TypeEnum.FUEL,
  SellSideQuoteRateDTO.TypeEnum.HAZMAT,
  SellSideQuoteRateDTO.TypeEnum.LAYOVER,
  SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT,
  SellSideQuoteRateDTO.TypeEnum.PERDIEM,
  SellSideQuoteRateDTO.TypeEnum.PREPULL,
  SellSideQuoteRateDTO.TypeEnum.REDELIVERY,
  SellSideQuoteRateDTO.TypeEnum.REEFER,
  SellSideQuoteRateDTO.TypeEnum.SCALE,
  SellSideQuoteRateDTO.TypeEnum.SHUTTLE,
  SellSideQuoteRateDTO.TypeEnum.SHUTTLEHAZ,
  SellSideQuoteRateDTO.TypeEnum.SHUTTLEOW,
  SellSideQuoteRateDTO.TypeEnum.SHUTTLEREEFER,
  SellSideQuoteRateDTO.TypeEnum.STORAGE,
  SellSideQuoteRateDTO.TypeEnum.TANKER,
  SellSideQuoteRateDTO.TypeEnum.TOLLS
]
