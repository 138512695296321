import * as React from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import { IStore, ApplicationContext } from '../../../store/store.interface'
import { Logo } from './Logo'
import { Account } from './Account'
import { CreateUnit } from './CreateUnit'
import { Collection } from './Collection'
import {
  Block,
  CollectionContainer,
  CollectionMenuButton,
  CollectionMenu,
  StyledHeader,
  TextBold
} from './styledComponents'
import { tabTypes } from '../../../services/uiSettingsService/tabs'
// import CommHub from '../../UI/CommHub'
import { Menu } from './Menu'
import { isAdmin } from '../../../services/debug'
import { getTabsLength } from '../tabs/selectors'
import { TabType } from '../tabs/interfaces'
import { ActionButton } from '../../UI/Buttons/ActionButton'
import { Accounting } from '../../pages/AccountingPage'
import { Notifications } from '../../UI/Notification/view'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { CommunicationHubButtons } from './CommunicationHubButtons'

const mainCollections = tabTypes.callbacks(ApplicationContext.main).filter(tabType => tabType !== TabType.deliveryOrder)
const adminCollections = tabTypes.callbacks(ApplicationContext.admin)

type StateProps = {
  applicationContext: ApplicationContext
  tabsLength: number
}

type DispatchProps = {}

type Props = StateProps & DispatchProps

export const Header = connect(
  (store: IStore): StateProps => ({
    applicationContext: store.applicationContext,
    tabsLength: getTabsLength(store)
  }),
  {}
)((props: Props) => {
  const { applicationContext, tabsLength } = props
  const [accountingPopup, setAccountingPopupState] = React.useState(false)
  const { wrapperRef, statePopover, togglePopover, setPopoverState } = useOutsideClick()

  const closeMenu = React.useCallback(() => setPopoverState(false), [setPopoverState])

  return (
    <>
      <StyledHeader>
        {isAdmin && <Menu />}

        <Block>
          <Logo />
          <TextBold>Intermodal</TextBold>
        </Block>

        <CollectionContainer ref={wrapperRef} className={cn({ active: statePopover })}>
          <CollectionMenuButton className={'mdi mdi-menu-down'} onClick={togglePopover}>
            Menu
          </CollectionMenuButton>
          <CollectionMenu>
            {(applicationContext === 'admin' ? adminCollections : mainCollections).map(tabType => (
              <Collection
                key={tabType.toString()}
                tabsLength={tabsLength}
                tabType={tabType}
                applicationContext={applicationContext}
                closeMenu={closeMenu}
              />
            ))}
          </CollectionMenu>
        </CollectionContainer>

        <ActionButton
          className={'header-accounting-button'}
          styles={{ height: 30, margin: 'auto 0 auto 12px' }}
          filled={true}
          onClick={() => setAccountingPopupState(true)}
        >
          Accounting
        </ActionButton>

        <Block style={{ marginLeft: 'auto' }}>
          {/*<SearchBar active={this.state.searchBar} toggleSearchBar={this.toggleSearchBar} />*/}
          <Notifications />
          <CreateUnit />
          <Account />
          <CommunicationHubButtons />
        </Block>
      </StyledHeader>
      {accountingPopup && <Accounting closePopup={() => setAccountingPopupState(false)} />}
    </>
  )
})
