import * as React from 'react'
import { ControllerStateAndHelpers } from 'downshift'
import { Menu as StyledMenu, Item, NotFound } from '../../styledComponents'

export const Menu: React.FC<{
  style: string
  downshift: ControllerStateAndHelpers<any>
  list: any[]
  transform: (element: any) => string
  isNotFound: boolean
  allowCreation?: boolean
  isFetchingList?: boolean
  createdItem?: any
  transformedValue?: string
}> = ({
  style,
  list,
  isFetchingList,
  transform,
  allowCreation,
  createdItem,
  transformedValue = '',
  isNotFound,
  downshift: { getMenuProps, getItemProps, isOpen, inputValue }
}) => {
  const isNotFoundList =
    list.filter(item =>
      transform(item)
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    ).length === 0

  const valid = transformedValue.length > 0 && transformedValue.length < 12
  // const isNot = inputValue.length >= 3 && (isNotFound || isNotFoundList)

  if (!isFetchingList && isNotFound && inputValue.length >= 3 && isNotFoundList && isOpen && allowCreation && valid) {
    return (
      <StyledMenu {...getMenuProps({ className: style })}>
        <Item className={'overflow'} {...getItemProps({ item: createdItem })}>
          <span>Create</span>
          {transformedValue}
        </Item>
      </StyledMenu>
    )
  }

  if (!isFetchingList && isNotFound && inputValue.length >= 3 && isNotFoundList && isOpen) {
    return (
      <StyledMenu {...getMenuProps({ className: style })}>
        <NotFound />
      </StyledMenu>
    )
  }

  return (
    <StyledMenu {...getMenuProps({ className: style })}>
      {isOpen
        ? list
            .filter(item =>
              transform(item)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            )
            .map((item, index) => (
              <Item
                key={item.id}
                {...getItemProps({
                  key: item.id,
                  index,
                  item
                })}
              >
                {transform(item)}
              </Item>
            ))
        : null}
    </StyledMenu>
  )
}
