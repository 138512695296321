import * as React from 'react'
import Input from '..'

interface IColumnWithEditProps {
  value?: string
  tabIndex?: number
  height?: number
  placeholder?: string
  disabled?: boolean
  isColumn?: boolean
  darkBackground?: boolean
  title?: string
  required?: boolean
  onChange(value: string): void
}

export const isValidZip = (value: string): boolean => {
  if (value === '' || value === null || value === undefined) {
    return true
  }
  if (value.length >= 5) {
    return true
  }
  return false
}

const Zip: React.SFC<IColumnWithEditProps> = props => (
  <Input
    {...props}
    valid={isValidZip(props.value)}
    textMask={[
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/,
      /(\d|-|\s)/
    ]}
  />
)

export default Zip
