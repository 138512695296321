import { getDispatchDeliveryOrderById } from '../../common/dispatchDeliveryOrder/epics'
import { requestLocationById } from '../../common/location/epics'
import { getEquipmentById } from '../../common/equipment/epics'
import { getCustomerById } from '../../common/customer/epics'
import { getSteamShipLineById } from '../../common/steamShipLine/epics'
import { getStreetTurnDispatchDeliveryOrderById } from '../../../services/DTO/streetTurn/epics'
import { ITabState, ListRequestType, TabType } from '../../common/tabs/interfaces'
// @ts-ignore
import { debounce } from 'debounce'
import { getStore } from '../../../store/configureStore'
import { oc } from 'ts-optchain'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../api/api'
import { parseDTO } from '../../../services/DTO/parseDTO'
import { getTabs } from '../../common/tabs/selectors'
import { isDDOStatusCancelled } from '../../../services/functions/test/isDDOStatusCancelled'
import { requestDriverById } from '../../common/drivers/epics'

export const getFullUnit = (type: TabType | ListRequestType, id: string): Promise<any> => {
  switch (type) {
    case TabType.dispatchDeliveryOrder:
      return getDispatchDeliveryOrderById(id)
    case TabType.location:
      return requestLocationById(id)
    case TabType.driver:
      return requestDriverById(id)
    case TabType.equipment:
      return getEquipmentById(id)
    case TabType.customer:
      return getCustomerById(id)
    case TabType.steamShipLine:
      return getSteamShipLineById(id)
    case ListRequestType.streetTurn:
      return getStreetTurnDispatchDeliveryOrderById(id)
    default:
      return Promise.resolve()
  }
}

type TCheckGridItemOnRerender = {
  tabId: string
  listRequestType: ListRequestType
  tabType: TabType
  unit: any
  fetchGridItems: (withoutSpinner?: boolean) => Promise<any>
}
export const checkUpdatedGridItem = (props: TCheckGridItemOnRerender) => {
  const { listRequestType, tabType } = props

  switch (listRequestType || tabType) {
    case ListRequestType.streetTurn: {
      checkStreetTurnGridItem(props)
      break
    }
    default:
      return
  }
}

const checkStreetTurnGridItem = debounce((props: TCheckGridItemOnRerender) => {
  const { tabId, fetchGridItems } = props
  const currentTab = getTabs(getStore().getState()).find(_ => _.id === tabId)

  if (!currentTab || !fetchGridItems || currentTab.data.streetTurnRequested) {
    return
  }

  const viewingObjectId = oc(getStore().getState()).viewingObjects[tabId].id()
  const requestFromDDOId = currentTab.listRequestProps.dispatchDeliveryOrderId
  const store = getStore().getState()
  const dispatchDeliveryOrderList = oc(currentTab)
    .gridItemIds([])
    .map(id => store.dispatchDeliveryOrder[id])
    .filter(Boolean)

  if (dispatchDeliveryOrderList.some(ddo => !ddo.streetTurn)) {
    fetchGridItems(true).then(() =>
      viewingObjectId && viewingObjectId !== requestFromDDOId
        ? getStreetTurnDispatchDeliveryOrderById(viewingObjectId)
        : null
    )
  }
}, 250)

export const calcEnableEditing = (unitType: TabType, unit: any, isModifiedMode: boolean): boolean => {
  switch (unitType) {
    case TabType.dispatchDeliveryOrder: {
      return isModifiedMode ? true : !isDDOStatusCancelled(unit.status)
    }
    default:
      return true
  }
}

export const actionOnUnitExpand = (type: TabType | ListRequestType, object: any) => {
  switch (type) {
    case TabType.dispatchDeliveryOrder: {
      const store = getStore().getState()

      if (
        oc(store)
          .deliveryOrder[object.deliveryOrderId].dispatchDeliveryOrderIds([])
          .some(id => !store.dispatchDeliveryOrder[id])
      ) {
        callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, 'deliveryOrder.id%%' + object.deliveryOrderId)
          .toPromise()
          .then(receivedDDOs => {
            parseDTO.dispatchDeliveryOrder(receivedDDOs)
          })
      }
      break
    }
    default:
  }
}
