import * as React from 'react'
import cn from 'classnames'
import ContextMenu from '../../UI/ContextMenu'
import Popover from '../../UI/Popover'
import { ITabState, TabType } from './interfaces'
import { Close, TextBlock, Img, StyledTab, Text, Input } from './styledComponents'

type ContextMenuAction = {
  title: string
  callToAction: (event: React.MouseEvent<Element>) => void
  separator: boolean
}

type Props = {
  isActive: boolean
  tab: ITabState
  icon: string
  removeTab: () => void
  handleTabClick: () => void
  saveTab: (tab: ITabState) => void
  setContaxtMenu: React.Dispatch<
    React.SetStateAction<{
      tab: ITabState
      position: {
        top: number
        left: number
      }
    }>
  >
}

type State = {
  edit: boolean
  tabToEdit: ITabState | any
}

class Tab extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      edit: false,
      tabToEdit: {}
    }
  }

  saveTab = (event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const updatedTab = {
      ...this.state.tabToEdit,
      title: this.state.tabToEdit.title.trim()
    }
    this.cancelEdit(event)
    if (!updatedTab.title) {
      return
    }

    this.props.saveTab(updatedTab)
  }

  removeTab = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.removeTab()
  }

  handleTabSubmit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.saveTab(event)
    } else if (event.key === 'Escape') {
      this.cancelEdit(event)
    }
  }

  editTab = (event: React.MouseEvent) => {
    const { tab } = this.props
    event.preventDefault()
    event.stopPropagation()
    if (tab.type !== TabType.deliveryOrder) {
      this.setState({
        tabToEdit: { ...tab },
        edit: true
      })
    }
  }

  onChangeTab = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      tabToEdit: { ...this.state.tabToEdit, title: event.target.value }
    })
  }

  cancelEdit = (event?: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.setState({
      tabToEdit: {},
      edit: false
    })
  }

  rightMouseClick = (event: React.MouseEvent) => {
    event.preventDefault()

    const position = { top: event.clientY, left: event.clientX }
    this.props.setContaxtMenu({ position, tab: this.props.tab })
  }

  render() {
    const { tab, icon, handleTabClick, isActive } = this.props
    const { edit } = this.state
    const tabClasses = cn('tab', {
      edit,
      active: isActive
    })

    return (
      tab &&
      tab.title && (
        <StyledTab
          data-id={tab.type + '-' + tab.id}
          style={edit ? { minWidth: 200 } : undefined}
          className={tabClasses}
          // @ts-ignore
          onContextMenu={this.rightMouseClick}
          onClick={() => {
            if (!isActive && !edit) {
              handleTabClick()
            }
          }}
        >
          <Img className={`mdi ${icon}`} />
          <TextBlock>
            <Text
              // @ts-ignore
              onDoubleClick={this.editTab}
              style={edit ? { visibility: 'hidden' } : undefined}
            >
              {tab.title}
            </Text>
          </TextBlock>

          {edit ? (
            <TextBlock>
              <Input
                type="text"
                autoFocus={true}
                value={this.state.tabToEdit.title}
                onChange={this.onChangeTab}
                // @ts-ignore
                onKeyDown={this.handleTabSubmit}
                // @ts-ignore
                onBlur={this.saveTab}
                onFocus={event => {
                  if (event.target && event.target.value) {
                    const temp_value = event.target.value
                    event.target.value = ''
                    event.target.value = temp_value
                  }
                }}
              />
            </TextBlock>
          ) : (
            <Close
              className="mdi mdi-close"
              // @ts-ignore
              onClick={this.removeTab}
            />
          )}
        </StyledTab>
      )
    )
  }
}

export default Tab
