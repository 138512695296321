import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'

export const steamShipLineFilterSettings = {
  Name: {
    name: 'businessPartner.legalName',
    type: GridHeaderColumnType.string
  },
  'Reference #': {
    name: 'referenceNumber',
    type: GridHeaderColumnType.string
  },
  'Free Detention Allowance': {
    name: 'freeDetentionAllowance',
    type: GridHeaderColumnType.number
  },
  'Primary Contact': {
    name: 'businessPartner.primaryContact.firstName',
    type: GridHeaderColumnType.string
  }
}
