import * as React from 'react'
import { oc } from 'ts-optchain'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../store/select/driverSelect'

type Props = {
  driverId: string
}

export const DriverColumn = (props: Props) => {
  const { driverId } = props
  const driverName = useAppSelector(selectDriverName(driverId))

  return <div>{driverName}</div>
}
