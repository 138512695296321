import { AddressDTO } from '../../../api/origin/business-logic'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { checkContactsValidation } from '../contact'
import { schemaAddress, schemaBusinessPartner } from '../../yupScheme'

export const checkAddressValidation = (address: AddressDTO, returnIfEmpty: boolean = true) =>
  address ? schemaAddress.isValidSync(address) : returnIfEmpty

export const checkBusinessPartnerValidation = (businessPartner: BusinessPartner) =>
  Boolean(checkContactsValidation(businessPartner.contacts) && schemaBusinessPartner.isValidSync(businessPartner))
