import * as React from 'react'
import styled from 'styled-components'
import { dispatchDeliveryOrderStatusIndex } from '../../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderViewDTO, OngoingActivityGroupDTO } from '../../../../../../../api/origin/business-logic'
import { activityDirectory } from '../../../../../../../services/DTO/activity/directory'
import { ddoDirectory } from '../../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { dateService } from '../../../../../../../services/timeService'
import { openDDOinNewTab } from '../../../../../../../services/DTO/dispatchDeliveryOrder'
import { checkWeatherContainerTypeIsReefer } from '../../../../../../../services/DTO/containerType/functions'
import { Column, Text, TextLine, TextLines, ThinText, DDOLink, Container } from './PreviewPhase'
import theme from '../../../../../../../styles/theme'
import cn from 'classnames'
import { transformMoney } from '../../../../../../UI/Input'

type Props = {
  isFavorite: boolean
  activityGroup: OngoingActivityGroupDTO
}

export const PreviewSpecs = React.memo((props: Props) => {
  const {
    isFavorite,
    activityGroup: {
      hazmatIndicator,
      overweightIndicator,
      containerTypeCode,
      daysOut,
      dispatchDeliveryOrderStatus,
      dispatchDeliveryOrderNumber,
      dispatchDeliveryOrderId,
      activityGroupDate,
      type,
      loadType,
      containerNumber,
      docNumber,
      steamShipLineName,
      customerName,
      chassisNumber,
      generalCutoffDate,
      lastFreeDateDemurrage,
      bsqAmount
    }
  } = props

  const RenderDaysOut = (
    <Column
      style={{
        color: daysOut && daysOut > 2 ? theme.colors.defaultRed : undefined,
        borderLeft: 'unset'
      }}
      data-copy-value-on-mousedown={true}
    >
      {daysOut ? (
        <TextLines>
          <Text>{daysOut}</Text>
          <ThinText>Day(s)</ThinText>
        </TextLines>
      ) : dispatchDeliveryOrderStatusIndex[dispatchDeliveryOrderStatus as any] <
        dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH] ? (
        <span style={{ color: theme.colors.defaultRed }}>N/A</span>
      ) : (
        <span style={{ fontSize: 20, margin: 'auto' }}>&mdash;</span>
      )}
    </Column>
  )

  const RenderDeliveryOrderType = (
    <Column style={{ fontWeight: 500 }}>{activityDirectory.deliveryOrderType[type]}</Column>
  )

  const RenderLoadType = (
    <Column data-copy-value-on-mousedown={true}>
      <TextLines style={{ overflow: 'unset' }}>
        <Text style={{ fontWeight: 500 }}>{ddoDirectory.loadType[loadType]}</Text>
        <SpecsIconsContainer>
          {checkWeatherContainerTypeIsReefer({ containerTypeCode }) && ddoDirectory.icons.refrigerator}
          {hazmatIndicator && ddoDirectory.icons.hazmat}
          {overweightIndicator && ddoDirectory.icons.overweight}
        </SpecsIconsContainer>
      </TextLines>
    </Column>
  )

  const RenderContainerNumber = (
    <Column data-copy-value-on-mousedown={Boolean(containerNumber)} data-copy-value={String(containerNumber)}>
      <TextLines>
        <LargeText>{containerNumber || <span style={{ fontSize: 20 }}>&mdash;</span>}</LargeText>
        {containerTypeCode && <LargeText>{containerTypeCode}</LargeText>}
      </TextLines>
    </Column>
  )

  const RenderDocumentNumber = (
    <Column data-copy-value-on-mousedown={Boolean(docNumber)} data-copy-value={String(docNumber)}>
      <TextLines>
        <LargeText>{docNumber || <span style={{ fontSize: 20 }}>&mdash;</span>}</LargeText>
        <ThinText>Booking/BL</ThinText>
      </TextLines>
    </Column>
  )

  const RenderSteamShipLine = (
    <Column data-copy-value-on-mousedown={Boolean(steamShipLineName)} data-copy-value={String(steamShipLineName)}>
      <TextLines>
        <LargeText>{steamShipLineName || <span style={{ fontSize: 20 }}>&mdash;</span>}</LargeText>
        <ThinText>SSL</ThinText>
      </TextLines>
    </Column>
  )

  const RenderCustomer = (
    <Column data-copy-value-on-mousedown={Boolean(customerName)} data-copy-value={String(customerName)}>
      <TextLines>
        <TextLine>{customerName || <span style={{ fontSize: 20 }}>&mdash;</span>}</TextLine>
        <ThinText>Customer</ThinText>
      </TextLines>
    </Column>
  )

  const RenderChassis = (
    <Column data-copy-value-on-mousedown={Boolean(chassisNumber)} data-copy-value={String(chassisNumber)}>
      <TextLines>
        <TextLine>{chassisNumber || <span style={{ fontSize: 20 }}>&mdash;</span>}</TextLine>
        <ThinText>Chassis</ThinText>
      </TextLines>
    </Column>
  )

  const RenderDate = (
    <Column data-id={`ddoNumber-${dispatchDeliveryOrderNumber} generalCutoffDate-${activityGroupDate}`}>
      {generalCutoffDate || lastFreeDateDemurrage ? (
        <TextLines>
          <Text>
            {generalCutoffDate ? dateService.makeLabel(generalCutoffDate, { hideTime: true, hideYear: true }) : ''}
            {lastFreeDateDemurrage
              ? dateService.makeLabel(lastFreeDateDemurrage, { hideTime: true, hideYear: true })
              : ''}
          </Text>
          <ThinText>
            {[generalCutoffDate ? 'Cut-off' : undefined, lastFreeDateDemurrage ? 'LFD' : undefined]
              .filter(Boolean)
              .join('') + ' Date'}
          </ThinText>
        </TextLines>
      ) : (
        <span style={{ fontSize: 20, margin: 'auto' }}>&mdash;</span>
      )}
    </Column>
  )

  const RenderAmount = (
    <Column>
      <TextLines>
        <Text>{bsqAmount ? transformMoney(bsqAmount) : <span style={{ fontSize: 20 }}>&mdash;</span>}</Text>
        <ThinText>BSQ</ThinText>
      </TextLines>
    </Column>
  )

  const RenderDDOLink = (
    <Column data-copy-value-on-mousedown={true} data-copy-value={String(dispatchDeliveryOrderNumber)}>
      <DDOLink
        onClick={event =>
          openDDOinNewTab({
            // @ts-ignore
            event,
            ddoNumber: dispatchDeliveryOrderNumber,
            ddoId: dispatchDeliveryOrderId
          })
        }
      >
        {dispatchDeliveryOrderNumber}
      </DDOLink>
    </Column>
  )

  return (
    <Specs className={cn({ isFavorite })}>
      {RenderDaysOut}
      {RenderDeliveryOrderType}
      {RenderLoadType}
      {RenderContainerNumber}
      {RenderDocumentNumber}
      {RenderSteamShipLine}
      {RenderCustomer}
      {RenderChassis}
      {RenderDate}
      {RenderAmount}
      {RenderDDOLink}
    </Specs>
  )
})

export const Specs = styled(Container)`
  grid-template-columns: 7% 5.8% 5.8% 14% 11.5% 6.6% 12.5% 12.5% 10.3% 8% 6%;

  ${Column}, &:after {
    background-color: white;
  }

  &.isFavorite ${Column}, &.isFavorite:after {
    background-color: #e3edfd;
  }
`

const LargeText = styled(TextLine)`
  font-size: 13px;
`
export const SpecsIconsContainer = styled(Text)`
  display: inline-grid;
  align-items: center;
  grid-template-columns: repeat(4, auto);
  grid-gap: 3px;
  font-size: 16px;
`
