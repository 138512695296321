import * as React from 'react'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { DispatchDeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { callAPI, dispatchDeliveryOrderAPI } from '../../../../../api/api'
import { IDispatchDeliveryOrder } from '../../interfaces'
import styled from '../../../../../styles/styledComponents'
import { WindowPopover } from '../../../../UI/windowPopover'
import { calcDispatchDeliveryOrderProps } from '../../../../../services/DTO/dispatchDeliveryOrder'

const statusIconName = {
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: 'mdi-checkbox-blank-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: 'mdi-checkbox-blank-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: 'mdi-checkbox-blank-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: 'mdi-alert-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED]: 'mdi-check-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY]: 'mdi-check-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP]: 'mdi-check-circle-outline',
  [DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]: 'mdi-cancel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]: 'mdi-cancel'
}

const statusMapping: Record<DispatchDeliveryOrderViewDTO.StatusEnum, string> = {
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEW]: 'New',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED]: 'New Accepted',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED]: 'New Rejected',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED]: 'Completed',
  [DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED]: 'Cancelled',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED]: 'Hold: On-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED]: 'Hold: Arrived',
  [DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED]: 'Hold: Off-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE]: 'Hold: Not on File',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED]: 'Released: On-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED]: 'Released: Arrived',
  [DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED]: 'Released: Off-vessel',
  [DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE]: 'Released: On File',
  [DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH]: 'Ready for Dispatch',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDPLANNED]: 'Dispatched: Planned',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDASSIGNED]: 'Dispatched: Assigned',
  [DispatchDeliveryOrderViewDTO.StatusEnum.DISPATCHEDCONFIRMED]: 'Dispatched: Confirmed',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSPICKUP]: 'In-process: Pick-Up',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDPICKUP]: 'Completed: Pick-Up',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSDELIVERY]: 'In-process: Delivery',
  [DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETEDDELIVERY]: 'Completed: Delivery',
  [DispatchDeliveryOrderViewDTO.StatusEnum.INPROCESSRETURN]: 'In-process: Return'
}

type Props = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}

export const StatusColumn = (props: Props) => {
  const { dispatchDeliveryOrder } = props
  const calculations = calcDispatchDeliveryOrderProps(dispatchDeliveryOrder)
  const wrapperRef = React.useRef(null)
  const [showDetails, setShowDetails] = React.useState(false)
  const [declinedVendorsNames, setDeclinedVendorsNames] = React.useState(calculations.declinedVendorsNames)
  const hasDetails = dispatchDeliveryOrder.fullObject
    ? Boolean(calculations.declinedVendorsNames)
    : dispatchDeliveryOrder.declinedVendorIndicator

  React.useEffect(() => {
    if (dispatchDeliveryOrder.fullObject) {
      setDeclinedVendorsNames(calculations.declinedVendorsNames)
    }
  }, [calculations.declinedVendorsNames])

  const onMouseEnter = () => {
    if (!hasDetails) {
      return
    }

    setShowDetails(true)

    if (!declinedVendorsNames) {
      callAPI(dispatchDeliveryOrderAPI.findDeclinedVendors, dispatchDeliveryOrder.id)
        .toPromise()
        .then(vendors => {
          const vendorsNames = vendors.map(vendor => vendor.name).join(', ')
          setDeclinedVendorsNames(vendorsNames)
        })
    }
  }

  const onMouseLeave = () => {
    if (!hasDetails) {
      return
    }

    setShowDetails(false)
  }

  return (
    <Container
      ref={wrapperRef}
      style={{ background: ddoDirectory.statusColor[calculations.ddoStatus] }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {dispatchDeliveryOrder.archived ? (
        <div className={'mdi mdi-archive'} />
      ) : (
        <span className={`mdi ${statusIconName[calculations.ddoStatus] || 'mdi-clock-outline'}`} />
      )}
      <p>{statusMapping[calculations.ddoStatus]}</p>
      <Space />
      {hasDetails && <div className={'mdi mdi-alert-octagon'} />}
      {dispatchDeliveryOrder.terminalMismatchIndicator && <MismatchIndicator className={'mdi mdi-alert-circle'} />}
      {showDetails && Boolean(declinedVendorsNames) && (
        <WindowPopover bindToRef={wrapperRef} isExternal={true}>
          <DeclinedVendorsDetails
            onClick={e => {
              e.stopPropagation()
            }}
          >
            Driver {declinedVendorsNames} refused to take an order.
          </DeclinedVendorsDetails>
        </WindowPopover>
      )}
    </Container>
  )
}

const MismatchIndicator = styled.div`
  font-size: 20px;
`
const Space = styled.div`
  flex-grow: 1;
`
const Container = styled.div`
  position: relative;
  height: 28px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-radius: 5px;
  padding: 0 5px;

  span {
    margin-right: 4px;
    font-size: 20px;
  }

  .mdi-archive,
  .mdi-alert-octagon {
    font-size: 22px;
  }
`
const DeclinedVendorsDetails = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 16px;
`
