import styled from 'styled-components'
import * as React from 'react'
import { oc } from 'ts-optchain'
import { toast } from 'react-toastify'
import { Input } from '../../../../UI/DataFields/Input'
import { Select } from '../../../../UI/DataFields/Select'
import { DriverActivenessStatus, IDriverActivityDTO } from '../../../../../services/DTO/driverActivity/interfaces'
import { ActionButton } from '../../../../UI/Buttons/ActionButton'
import { getDispatch } from '../../../../../store'
import { listsActions } from '../../../../../store/reducers/lists'
import { ListEntityType } from '../../../../../store/reducers/lists/interfaces'
import { DriverSearch } from '../../../../UI/DataFields/SearchByList/DriverSearch'
import { dateService } from '../../../../../services/timeService'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { selectDriversActivity } from '../../../../../store/select/driverActivitySelect'

let initialDriverId = ''
let initialAuthUserId = ''
let initialDriverStatus = DriverActivenessStatus.ACTIVE

export const TestDriverActivity = () => {
  const [driverId, setDriverId] = React.useState(initialDriverId)
  const [authUserId, setAuthUserId] = React.useState(initialAuthUserId)
  const [driverStatus, setDriverStatus] = React.useState(initialDriverStatus)
  const driverActivityMapping = useAppSelector(selectDriversActivity)

  return (
    <Container>
      <DriverSearch
        title={'Search Driver'}
        selectedDriverId={driverId}
        placeholder={'Driver Name'}
        onChange={driver => {
          if (driver && driver.authUserId) {
            initialDriverId = driver.id
            initialAuthUserId = driver.authUserId
            setDriverId(driver.id)
            setAuthUserId(driver.authUserId)
            setDriverStatus(oc(driverActivityMapping[driver.authUserId]).status(initialDriverStatus))
          } else {
            initialDriverId = ''
            initialAuthUserId = ''
            setDriverId(undefined)
            setAuthUserId(undefined)
            setDriverStatus(initialDriverStatus)
          }
        }}
      />
      <br />
      <Input
        title={'Driver Auth ID'}
        value={authUserId || ''}
        onChange={text => {
          initialDriverId = ''
          initialAuthUserId = text
          setDriverId(undefined)
          setAuthUserId(text)
        }}
      />
      <br />
      <Select
        title={'Change Driver Status'}
        list={[
          { label: 'Active', value: DriverActivenessStatus.ACTIVE },
          { label: 'Not Active', value: DriverActivenessStatus.NOT_ACTIVE }
        ]}
        selectedValue={driverStatus}
        onSelect={item => {
          initialDriverStatus = item
          setDriverStatus(item)
        }}
      />
      <br />
      <ActionButton
        filled={true}
        onClick={() => {
          getDispatch()(
            listsActions.update({
              update: {
                [ListEntityType.driverActivity]: [
                  {
                    id: authUserId,
                    status: driverStatus,
                    lastActiveDate: dateService.createStringDate.now,
                    updatedAt: new Date().getTime()
                  } as IDriverActivityDTO
                ]
              }
            })
          )

          toast.success(
            <>
              <i className={'mdi mdi-check'} />
              Driver status updated
            </>
          )
        }}
      >
        Change
      </ActionButton>
    </Container>
  )
}

const Container = styled.div`
  width: 400px;
  padding-left: 40px;
`
const DriverContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 300px 1fr;
`
