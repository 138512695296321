import { TContact } from '../../../components/common/contact/interfaces'
import { isNewObject } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects } from '../saveDTO'
import { callAPI, contactAPI } from '../../../api/api'
import * as R from 'remeda'
import { tryAddContactToStore } from '../../../components/common/contact/epics'

export const getContactSavePromise = (contact: TContact) => {
  const store = getStore().getState()
  const storeContact = store.contact[contact.id]

  return isNewObject(contact)
    ? callAPI(contactAPI.create, R.omit(contact, ['id'])).toPromise()
    : isEqualObjects(contact, storeContact)
    ? contact
    : callAPI(contactAPI.update, contact, contact.id).toPromise()
}

export const getContactsStatusSavePromise = (contacts: TContact[]): Promise<TContact[]> =>
  Promise.all((contacts || []).map(getContactSavePromise)).then(result => {
    tryAddContactToStore(result)
    return result
  })
