import { compareUpdateDates } from '../index'
import { getStore } from '../../../store/configureStore'
import { getBusinessPartnerStatus } from '../businessPartner/status'
import { isEqualObjectsWithoutBusinessPartner, omitUpdateInfo } from '../saveDTO'
import { ICustomerView } from '../../../components/common/customer/interfaces'
import { getObjectsDifference } from '../ConflictsOnSaving'
import { oc } from 'ts-optchain'

export const getCustomerStatus = (customer: ICustomerView) => {
  if (!customer || !customer.fullObject) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false,
      getDifference: undefined
    }
  }

  const store = getStore().getState()
  const storeCustomer = store.customer[customer.id]
  const businessPartnerInfo = getBusinessPartnerStatus(customer.businessPartner)

  const storeObject = omitUpdateInfo(storeCustomer)
  const checkObject = omitUpdateInfo(customer)

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(customer, storeCustomer) && businessPartnerInfo.noStoreDataForUpdate
    },
    get needToSave() {
      return (
        oc(checkObject).fullObject() && !isEqualObjectsWithoutBusinessPartner(checkObject, storeObject, ['subClients'])
      )
    },
    get getDifference() {
      return {
        ...getObjectsDifference({ oldObject: checkObject, newObject: storeObject }),
        businessPartner: getObjectsDifference({
          oldObject: omitUpdateInfo(customer.businessPartner),
          newObject: omitUpdateInfo(store.businessPartner[customer.businessPartner.id])
        })
      }
    }
  }
}
