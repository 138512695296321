import * as React from 'react'
import { authRedirect } from '../index'
import { Container, Info, Title, Button } from './styledComponents'

export const LogoutSuccess: React.SFC = () => (
  <Container>
    <Title>Logged Out</Title>
    <Info>You have logged out. Log in again?</Info>
    <Button onClick={() => authRedirect()}>Login</Button>
  </Container>
)
