import { compareUpdateDates, TObjectStatusForSave } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import { SellSideQuoteDTO } from '../../../api/origin/business-logic'
import { oc } from 'ts-optchain'

export const getSellSideQuoteStatus = (sellSideQuote: SellSideQuoteDTO): TObjectStatusForSave => {
  const store = getStore().getState()
  if (!sellSideQuote) {
    return {
      noStoreDataForUpdate: true,
      needToSave: false,
      getDifference: undefined
    }
  }

  const storeSellSideQuote = store.sellSideQuotes[sellSideQuote.id]

  if (!storeSellSideQuote) {
    return {
      noStoreDataForUpdate: true,
      needToSave: true,
      getDifference: undefined
    }
  }

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(sellSideQuote, storeSellSideQuote)
    },
    get needToSave() {
      return (
        oc(sellSideQuote).fullObject() &&
        !isEqualObjects(omitUpdateInfo(sellSideQuote), omitUpdateInfo(storeSellSideQuote))
      )
    }
  }
}
