import { callAPI, DeliveryOrderViewDTO, deliveryOrderAPI } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { addDeliveryOrderToStore } from '../action'
import { parseDTO } from '../../../../services/DTO/parseDTO'
import { createNewTab } from '../../tabs/actions'
import { TabType } from '../../tabs/interfaces'
import { newDeliveryOrder } from '../reducers'
import { setTabViewingObject } from '../../../../services/viewingObjects/actions'

export const tryAddDeliveryOrderToStore = (items: DeliveryOrderViewDTO | DeliveryOrderViewDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().deliveryOrder, items, addDeliveryOrderToStore, {
    dispatchDeliveryOrders: []
  })
}

export const createDeliveryOrderAndTab = () => {
  const { dispatch } = getStore()
  const deliveryOrder = newDeliveryOrder()

  dispatch(
    createNewTab({
      tabType: TabType.deliveryOrder,
      options: { storeTab: false }
    })
  )

  dispatch(
    setTabViewingObject({
      tabId: getStore().getState().tabs.activeMainTabId,
      viewingObject: { id: deliveryOrder.id, modifiedObject: deliveryOrder }
    })
  )
}

export const getDeliveryOrderById = (id: string): Promise<DeliveryOrderViewDTO> =>
  callAPI(deliveryOrderAPI.findById, id)
    .toPromise()
    .then(deliveryOrder => {
      parseDTO.deliveryOrder(deliveryOrder)
      return deliveryOrder
    })

export const findAllDeliveryOrders = async ({ sort, filter }: any): Promise<DeliveryOrderViewDTO[]> => {
  return callAPI(deliveryOrderAPI.findAll, filter, sort)
    .toPromise()
    .then(deliveryOrders => {
      parseDTO.deliveryOrder(deliveryOrders)
      return deliveryOrders
    })
}
