import { oc } from 'ts-optchain'
import { IStore } from '../../../store/store.interface'
import { DriverViewDTO } from '../../../api/origin/business-logic'
import { getNewStoreState, RootState } from '../../../store'

type Props = {
  id: string
  store?: IStore
  newStore?: RootState
  simplify?: boolean
}
export const assembleDriver = (props: Props): DriverViewDTO => {
  const newStore = props.newStore || getNewStoreState()
  const driver = oc(newStore).lists.driver[props.id]()

  if (!driver) {
    return null
  }

  return {
    ...driver,
    parent: oc(newStore).lists.driver[driver.parentId]() || driver.parent
  }
}
