import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserDTO } from '../../../api/origin/user-service'
import { unitLocalStorageService } from '../../../services/storageService/localStorage/LocalStorage'
import { convertAuthUserDataToUserDTO } from './functions'
import { IUserAuth } from './interfaces'

const initialState: UserDTO = null

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<IUserAuth>) => {
      const user = convertAuthUserDataToUserDTO(payload)

      try {
        if (user && user.id) {
          unitLocalStorageService({ user })
          // @ts-ignore
          // window.FS.identify(userData.id, {
          //   displayName: userData.name + (userData.nickname ? ` (${userData.nickname})` : ''),
          //   email: userData.email || userData.externalEmail
          // })
        }
      } catch (e) {}

      return user
    }
  }
})

export const userActions = userSlice.actions
export default userSlice.reducer
