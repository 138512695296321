import actionCreatorFactory from 'typescript-fsa'
import { ApplicationContext, IUiSettingsStoreState } from '../../../../store/store.interface'
import { ITabState, TabType, ITabSettings, TGridColumns } from '../interfaces'
import { IFilterItem } from '../../grid/views/header/column'

const actionCreator = actionCreatorFactory('TABS')

export const updateVisibleTabsNumber = actionCreator<number>('UPDATE_VISIBLE_TABS_NUMBER')
export const createNewTab = actionCreator<{
  tabType: TabType
  data?: any
  uiSettings?: ITabSettings
  options?: object
}>('CREATE_NEW')
export const removeAllTabsExcept = actionCreator<{ tab: ITabState; context: ApplicationContext }>(
  'REMOVE_All_BESIDES_THE_FIRST'
)
export const setTabFetching = actionCreator<{
  tabId: string
  fetching: boolean
  context?: ApplicationContext
  visited?: boolean
}>('SET_FETCHING')
export const updateTab = actionCreator<{ tab: ITabState; context?: ApplicationContext }>('UPDATE')
export const setTabData = actionCreator<{
  tabId: string
  tabData: any
  context?: ApplicationContext
  visited?: boolean
}>('SET_TAB_DATA')
export const updateTabData = actionCreator<{
  tabId: string
  options: any
  context?: ApplicationContext
  visited?: boolean
}>('UPDATE_TAB_DATA')
export const moveTab = actionCreator<{
  currentTab: ITabState
  insertBeforeTab: ITabState
  context: ApplicationContext
}>('MOVE')
export const duplicateTab = actionCreator<{ originalTab: ITabState; context: ApplicationContext }>('DUPLICATE')
export const removeTab = actionCreator<{ id: string; context?: ApplicationContext }>('REMOVE')
export const removeRightTabs = actionCreator<{ tab: ITabState; context: ApplicationContext }>('REMOVE_RIGHT')
export const getTabsFromSessionStorage = actionCreator<IUiSettingsStoreState>('UPDATE_TABS_FROM_UI_SETTINGS')
export const createDOTab = actionCreator<{ id: string }>('CREATE_DO')
export const openDOTab = actionCreator<{ id: string; deliveryOrderNumber: string; isNew?: boolean }>('OPEN_DO')
export const updateTabSettings = actionCreator<{
  id: string
  uiSettings?: ITabSettings
  context?: ApplicationContext
}>('UPDATE_TAB_SETTINGS')
export const updateTabFilters = actionCreator<{
  id: string
  filter?: IFilterItem[]
  context?: ApplicationContext
}>('UPDATE_TAB_FILTERS')
export const updateTabColumns = actionCreator<{
  id: string
  columns?: TGridColumns
  context?: ApplicationContext
}>('UPDATE_TAB_COLUMNS')
export const updateTabSorting = actionCreator<{
  id: string
  sorting?: any
  context?: ApplicationContext
}>('UPDATE_TAB_SORTING')
export const setActiveTabId = actionCreator<{ tabId: string; context?: ApplicationContext }>('SET_ACTIVE_TAB_ID')
export const updateTabGridItems = actionCreator<{
  context: ApplicationContext
  tabId: string
  idList: string[]
  data?: any
}>('UPDATE_TAB_GRID_ITEMS')
export const addCreatedGridItem = actionCreator<{
  unitId: string
  context: ApplicationContext
  tabId: string
}>('REPLACE_FAKE_GRID_ITEM_TO_EXISTING_ONE')

export const setFavoriteOngoingActivityState = actionCreator<{
  tabId: string
  ongoingActivityId: string
  favoriteState: boolean
}>('SET_ONGOING_ACTIVITY_STATE')
