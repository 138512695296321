import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ContactState, TContact } from '../interfaces'
import { createId } from '../../../../services/utils'
import { addContactToStore } from '../action'

export const newContact: TContact = {
  email: null,
  fax: null,
  firstName: null,
  lastName: null,
  mobile: null,
  phone: null,
  phone2: null,
  notes: null,
  primary: false
}

export const createNewContact = (isPrimaryContact?: boolean): TContact => ({
  ...newContact,
  id: createId(),
  primary: Boolean(isPrimaryContact)
})

const contactState: ContactState = {}

export const contactReducer = reducerWithInitialState<ContactState>(contactState)
  .case(addContactToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()
