import * as React from 'react'
import { TGridColumn } from '../../../../grid/views/interfaces'
import { TableCell } from '../../../../grid/views/styles'

const Column = (props: TGridColumn) => {
  const { className, value, columnName, style } = props

  switch (columnName) {
    default:
      return (
        <TableCell className={className} title={String(value)} style={style}>
          {value || ''}
        </TableCell>
      )
  }
}

export default Column
