import { TColumns } from '../../../grid/views/interfaces'
import { oc } from 'ts-optchain'
import { createBodyColumnsWithHeaderProps } from '../../../../../services/Grid'
import { UserDTO } from '../../../../../api/origin/user-service'

export const headers = {
  Name: { width: 160, active: true },
  Username: { width: 160, active: true },
  Email: { width: 160, active: true },
  'Last Login': { width: 60, active: true }
}

export const adaptRowValues = (unit: UserDTO): TColumns => {
  const columnValues = {
    Name: oc(unit).name(''),
    Username: oc(unit).username(''),
    Email: oc(unit).email(''),
    'Last Login': ''
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
