import { APPLIED_TIMEZONE } from '../../../config'
import { ConfirmedLocalDateTimeRangeDTO, DateTimeRangeDTO } from '../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import { isEqualDates } from '../../../services/DTO/dispatchDeliveryOrder/functions'

const msInMinutes = 60 * 1000

export const timeZoneOffset = {
  get applied() {
    return (
      Date.parse(new Date().toLocaleString('en-US', { timeZone: 'UTC' })) -
      Date.parse(new Date().toLocaleString('en-US', { timeZone: APPLIED_TIMEZONE }))
    )
  },
  get current() {
    return new Date().getTimezoneOffset() * msInMinutes
  },
  getApplied(date: number) {
    if (!date) {
      return this.applied
    }

    return (
      Date.parse(new Date(date).toLocaleString('en-US', { timeZone: 'UTC' })) -
      Date.parse(new Date(date).toLocaleString('en-US', { timeZone: APPLIED_TIMEZONE }))
    )
  },
  getCurrent(date: number) {
    if (!date) {
      return this.current
    }

    return new Date(date).getTimezoneOffset() * msInMinutes
  }
}

// time -> APPLIED_TIMEZONE time (NY time for now)
export const convertTimePlusTimezoneOffsetAndAppliedTimezone = (date: number) => {
  const tzOffset = timeZoneOffset.getApplied(date) - timeZoneOffset.getCurrent(date)

  return new Date(date + tzOffset)
}

export const convertTimeMinusTimezoneOffsetToISOString = (date: number) => {
  const tzOffset = timeZoneOffset.getApplied(date)
  return new Date(date - tzOffset).toISOString()
}
export const convertTimePlusTimezoneOffsetToISOString = (date: number) => {
  const tzOffset = timeZoneOffset.getApplied(date)
  return new Date(date + tzOffset).toISOString()
}

// UTC now
export const createISODateNow = (): string => new Date(Date.now()).toISOString()

export const isDiffRanges = (
  date1: DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO,
  date2: DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO
) => {
  return !isEqualDates(oc(date1).from(), oc(date2).from()) || !isEqualDates(oc(date1).to(), oc(date2).to())
}

export const getToday = () => {
  return convertTimePlusTimezoneOffsetAndAppliedTimezone(new Date().setHours(0, 0, 0, 0)).toISOString()
}
