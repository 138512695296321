import { TransportationActivityViewDTO } from '../../../api/api'
import { TransportationActivityRow } from '../../DTO/activity/controller'
import { TransportationActivityGroup } from '../../DTO/activity/interfaces'
import { isBobtailGotoActivity } from '../test/isBobtailGotoActivity'
import { generateTransportationActivity } from './generateTransportationActivity'

export const generateActivityRow = (activityGroupRow: TransportationActivityRow): TransportationActivityRow => {
  const activityGroup = activityGroupRow.activityGroup as TransportationActivityGroup
  const isBobtail = isBobtailGotoActivity(activityGroup.gotoActivity)
  const gotoActivity = generateTransportationActivity({
    dispatchDeliveryOrderId: activityGroup.gotoActivity.dispatchDeliveryOrderId,
    stage: activityGroup.gotoActivity.stage,
    options: {
      type: TransportationActivityViewDTO.TypeEnum.GOTO,
      comboType: activityGroup.businessActivity.comboType,
      destinationId: activityGroup.gotoActivity.destinationId,
      destination: activityGroup.gotoActivity.destination,
      startPlannedDateTimeRange: activityGroup.gotoActivity.startPlannedDateTimeRange
    }
  })
  const businessActivity = generateTransportationActivity({
    dispatchDeliveryOrderId: activityGroup.gotoActivity.dispatchDeliveryOrderId,
    stage: activityGroup.gotoActivity.stage,
    options: {
      type: activityGroup.businessActivity.type,
      comboType: activityGroup.businessActivity.comboType,
      groupId: gotoActivity.groupId,
      destinationId: activityGroup.businessActivity.destinationId,
      destination: activityGroup.businessActivity.destination,
      startPlannedDateTimeRange: activityGroup.businessActivity.startPlannedDateTimeRange
    }
  })

  if (isBobtail) {
    gotoActivity.type = TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO
    gotoActivity.vendorId = businessActivity.vendorId = activityGroup.gotoActivity.vendorId
    gotoActivity.vendor = businessActivity.vendor = activityGroup.gotoActivity.vendor
    gotoActivity.status = businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED
    gotoActivity.destinationId = businessActivity.destinationId = undefined
    gotoActivity.destination = businessActivity.destination = undefined
  }

  return { ...activityGroupRow, activityGroup: { gotoActivity, businessActivity }, id: gotoActivity.id }
}
