import { callAPI, powerUnitAPI } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { PowerUnitDTO } from '../../../../api/origin/business-logic'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { addPowerUnitsToStore } from '../action'

export const tryAddPowerUnitToStore = (items: PowerUnitDTO | PowerUnitDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().powerUnit, items, addPowerUnitsToStore)
}

export const getPowerUnitById = (id: string): Promise<PowerUnitDTO> =>
  callAPI(powerUnitAPI.findById, id)
    .toPromise()
    .then(powerUnit => {
      tryAddPowerUnitToStore(powerUnit)
      return powerUnit
    })

export const findAllPowerUnits = async ({ sort, filter }: any): Promise<PowerUnitDTO[]> => {
  return callAPI(powerUnitAPI.findAll, filter, sort)
    .toPromise()
    .then(powerUnits => {
      tryAddPowerUnitToStore(powerUnits)
      return powerUnits
    })
    .catch(catchListErrors)
}
