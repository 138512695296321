import * as React from 'react'
import styled from 'styled-components'
import { RouteBuilderMove } from './RouteBuilderMove'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { isDoubleMoveAvailableToDisconnect } from '../../functions/groupDoubleMoves'
import { useRouteBuilder } from '../../../../hooks/useRouteBuilder'
import { RouteBuilderIcons } from '../../../UI/icons/RouteBuilderIcons'
import { animateMoveReorder } from '../../functions/animateMoveReorder'
import { RouteBuilderToastMessage, RouteBuilderToastMessageType } from './RouteBuilderToastMessage'
import { getRouteBuilderMoveDate, useRouteBuilderNextToDayMove } from '../../../../hooks/useRouteBuilderNextToDayMove'
import { dateService } from '../../../../services/timeService'
import {
  IMoveDTO,
  MoveConnectionType,
  MoveStatus,
  RouteBuilderGridView
} from '../../../../store/reducers/communicationHub/interfaces'
import { TransportationActivityViewDTO } from '../../../../api/api'
import { isLastMoveItemBobtail } from '../../../../services/functions/test/isLastMoveItemBobtail'
import { runBlinkAnimation } from '../../../../services/functions/animation/runBlinkAnimation'
import { isMoveDisabled } from '../../../../services/functions/test/isMoveDisabled'

type OwnProps = {
  moves: IMoveDTO[]
  tabMoves: IMoveDTO[]
  isDoubleMove: boolean
  transportationActivityMapping: Record<string, TransportationActivityViewDTO>
  connectInDoubleMoves?: () => Promise<any>
  disconnectDoubleMove?: (move: IMoveDTO) => void
  moveUp?: () => void
  moveDown?: () => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const {
    moves,
    tabMoves,
    isDoubleMove,
    transportationActivityMapping,
    connectInDoubleMoves,
    disconnectDoubleMove,
    moveUp,
    moveDown
  } = props
  const containerRef = React.useRef<HTMLElement>(null)
  const [connectMovesError, setConnectMovesError] = React.useState<{
    date: string
    ddoId: string
    ddoNumber: number
    errorMessage?: string
  }>(null)
  const { view, activeTabDate } = useRouteBuilder()
  const {
    isNextDayMove,
    isPrevDayMove,
    showNextToDayMoveDetails,
    setShowNextToDayMoveDetails
  } = useRouteBuilderNextToDayMove({
    activeTabDate,
    move: moves.length === 1 && moves[0]
  })

  return (
    <>
      {isNextDayMove && (
        <ShowMoveButtonContainer style={showNextToDayMoveDetails ? undefined : { marginBottom: 100 }}>
          <ShowMoveButton
            className={cn('mdi', {
              'mdi-menu-up': showNextToDayMoveDetails,
              'mdi-menu-down': !showNextToDayMoveDetails
            })}
            onClick={() => setShowNextToDayMoveDetails(state => !state)}
          >
            {dateService.makeLabel(getRouteBuilderMoveDate(moves[0]), {
              hideTime: true,
              hideYear:
                new Date(getRouteBuilderMoveDate(moves[0])).getFullYear() === new Date(activeTabDate).getFullYear()
            })}
          </ShowMoveButton>
        </ShowMoveButtonContainer>
      )}
      {(isDoubleMove || !isNextDayMove || showNextToDayMoveDetails) && (
        // @ts-ignore
        <Container ref={containerRef}>
          <TimeLine
            className={cn({
              isDoubleMove: moves.some(
                move =>
                  move.prevConnectionType === MoveConnectionType.DOUBLEMOVE ||
                  move.nextConnectionType === MoveConnectionType.DOUBLEMOVE
              )
            })}
          >
            {moveUp && (
              <TimeLineArrow
                style={{ top: 30 }}
                onClick={() => {
                  animateMoveReorder({
                    element: containerRef.current,
                    direction: 'up',
                    onAnimationFinishCallback: moveUp
                  })
                }}
                children={RouteBuilderIcons.timeLineArrowUp}
              />
            )}
            {moveDown && (
              <TimeLineArrow
                style={{ bottom: 0 }}
                onClick={() => {
                  animateMoveReorder({
                    element: containerRef.current,
                    direction: 'down',
                    onAnimationFinishCallback: moveDown
                  })
                }}
                children={RouteBuilderIcons.timeLineArrowDown}
              />
            )}
            {connectInDoubleMoves && !connectMovesError && (
              <ConnectMovesButton
                onClick={() => {
                  connectInDoubleMoves().catch(error => {
                    if (!error.data) {
                      return
                    }

                    if (error && error.status === 409) {
                      const data = error.data.data

                      setConnectMovesError({
                        ...data,
                        date: data.date ? data.date + 'T08:00:00.000' : data.date
                      })
                    } else if (typeof error.data.message === 'string') {
                      setConnectMovesError({
                        date: undefined,
                        ddoId: undefined,
                        ddoNumber: undefined,
                        errorMessage: error.data.message
                      })
                    }
                  })
                }}
              >
                <span>Connect</span>
              </ConnectMovesButton>
            )}
          </TimeLine>

          {moves.map((move, index) => {
            const disable = isMoveDisabled(move)
            const tabMoveIndex = tabMoves.findIndex(_ => _.id === move.id)
            const hasThisDayLabel = tabMoves[tabMoveIndex - 1] && tabMoves[tabMoveIndex - 1]._isPrevDayMove
            const hasDayLabel = (isDoubleMove && move._isNextDayMove) || hasThisDayLabel

            return (
              <React.Fragment key={move.id}>
                {hasDayLabel && (
                  <LabelContainer>
                    <DayLabel
                      style={hasThisDayLabel ? { fontSize: 12, color: theme.colors.basicBlueColor } : undefined}
                    >
                      {hasThisDayLabel
                        ? 'This Day'
                        : dateService.makeLabel(getRouteBuilderMoveDate(move), {
                            hideTime: true,
                            hideYear:
                              new Date(getRouteBuilderMoveDate(move)).getFullYear() ===
                              new Date(activeTabDate).getFullYear()
                          })}
                    </DayLabel>
                  </LabelContainer>
                )}
                <RouteBuilderMoveGroupItemContainer
                  onClick={() => {
                    runBlinkAnimation({
                      elementClassName: 'd01652bd-637d-44e7-8f1c-25157b36bf6d',
                      extraStyles: {
                        borderRadius: '0'
                      }
                    })
                  }}
                  className={cn(
                    'route-builder-move-group',
                    'view__' + view,
                    move.items.map(item => `rb-item-${item.gotoActivityId} rb-item-${item.businessActivityId}`),
                    {
                      active: move.status === MoveStatus.INPROCESS,
                      isNextToDay: move._isNextDayMove || move._isPrevDayMove,
                      hasThisDayLabel,
                      hasDayLabel
                    }
                  )}
                >
                  {move.nextDoubleMoveId &&
                    isLastMoveItemBobtail({
                      move,
                      transportationActivityMapping,
                      requiredCombotype: TransportationActivityViewDTO.ComboTypeEnum.EMPTY
                    }) && <BobtailLabel className={'bobtail-label'} />}
                  {Boolean(move.prevDoubleMoveId) && isDoubleMoveAvailableToDisconnect(moves[index - 1], move) && (
                    <DisconnectMovesButton
                      onClick={() => {
                        showModalWindow({
                          width: 320,
                          title: 'Are you sure you want to disconnect moves?',
                          buttons: [
                            {
                              label: 'No'
                            },
                            {
                              label: 'Yes',
                              onClick: () => disconnectDoubleMove(move)
                            }
                          ]
                        })
                      }}
                      children={RouteBuilderIcons.doubleMoves}
                    />
                  )}
                  <RouteBuilderMove
                    move={move}
                    disable={disable}
                    enableDateMovement={
                      !disable && index === 0 && moves.every(({ plannedDateFixed }) => !plannedDateFixed)
                    }
                  />
                </RouteBuilderMoveGroupItemContainer>
              </React.Fragment>
            )
          })}
        </Container>
      )}
      {connectMovesError && (
        <ToastMessageContainer>
          <RouteBuilderToastMessage
            type={
              connectMovesError.errorMessage
                ? RouteBuilderToastMessageType.error
                : RouteBuilderToastMessageType.connectMovesError
            }
            meta={
              connectMovesError.errorMessage
                ? { message: connectMovesError.errorMessage }
                : { connectMovesErrorData: connectMovesError }
            }
            removeToastMessageAction={() => setConnectMovesError(null)}
          />
        </ToastMessageContainer>
      )}
    </>
  )
}

export const RouteBuilderMoveGroup = React.memo(Component)

const ToastMessageContainer = styled.div`
  padding: 10px 20px 10px 33px;
`

const TimeLine = styled.div`
  z-index: 1;
  width: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 25px;
  bottom: 12px;

  &.isDoubleMove {
    &:before {
      border-color: ${theme.colors.doubleMove};
    }
    &:after {
      background-color: ${theme.colors.doubleMove};
    }
  }

  &:before {
    content: '';
    flex-shrink: 0;
    display: block;
    width: 18px;
    height: 18px;
    border: 6px solid #afb8c3;
    border-radius: 50%;
    margin-bottom: 8px;
  }

  &:after {
    content: '';
    flex-grow: 1;
    display: block;
    width: 10px;
    background-color: #afb8c3;
    border-radius: 4px;
  }
`
const ConnectMovesButton = styled.div`
  z-index: -1;
  height: 69px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% - 22px);
  box-shadow: inset 0 0 0 2px #afb8c350;
  border-radius: 3px;
  color: #9ba8b8;
  font-weight: 500;
  font-size: 8px;
  text-transform: uppercase;
  padding: 28px 0;
  cursor: pointer;
  user-select: none;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 14px;
    display: block;
    position: absolute;
  }

  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  &:before,
  &:after,
  span {
    background-color: #fcfdff;
  }

  span {
    line-height: 20px;
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};
    box-shadow: inset 0 0 0 2px ${theme.colors.basicBlueColor};
  }
`
const TimeLineArrow = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
`

const DisconnectMovesButton = styled.div`
  z-index: 1;
  height: 24px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -10px;
  left: 60px;
  color: #9ca8b8;
  font-weight: 500;
  font-size: 8px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;

  &:after {
    content: 'Disconnect';
    margin-left: 7px;
  }

  path {
    stroke: #9ca8b8;
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};

    path {
      stroke: ${theme.colors.basicBlueColor};
    }
  }
`

export const Container = styled.div`
  position: relative;

  &.movement {
    transition: transform 0.5s;

    ${DisconnectMovesButton}, ${ConnectMovesButton}, ${TimeLineArrow} {
      display: none;
    }
  }
`
export const RouteBuilderMoveGroupItemContainer = styled.div`
  position: relative;
  display: flex;

  &.view__${RouteBuilderGridView.grid} {
    padding: 15px 25px 15px 43px;
    border-radius: 0 20px 20px 0;
  }

  &.view__${RouteBuilderGridView.list} {
    border-radius: 0 15px 15px 0;
    padding: 18px 0 18px 43px;

    &:before {
      content: '';
      height: 1px;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 10px;
      background-color: #e3e8ea;
    }
  }

  &.active {
    background-image: repeating-linear-gradient(-45deg, #fcfdff, #fcfdff 30px, #f5f6fc 30px, #f5f6fc 60px);
  }

  &.isNextToDay {
    .RouteBuilderMove {
      opacity: 0.7;
    }
  }

  &.hasDayLabel {
    padding-top: 0;

    ${DisconnectMovesButton} {
      top: -50px;
    }
  }

  &:empty {
    display: none;
  }
`
const LabelContainer = styled.div`
  z-index: 1;
  padding: 0 25px 0 36px;
  margin-top: 15px;
`
const DayLabel = styled.div`
  z-index: 1;
  height: 24px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #516176;
  text-transform: uppercase;
  margin-left: 20px;
  overflow: hidden;
  user-select: none;
`
const ShowMoveButton = styled(DayLabel)`
  transform: translate(-50%, -50%);
  align-items: center;
  flex-direction: row-reverse;
  font-size: 16px;
  padding: 0 20px;
  margin: 0;
  cursor: pointer;

  &:before {
    font-size: 18px;
  }
`
const ShowMoveButtonContainer = styled.div`
  position: relative;
  text-align: center;
  padding: 0 25px 0 calc(43px + 120px);
`

const BobtailLabel = styled.div`
  z-index: 1;
  height: 28px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% - 13px);
  left: 28px;
  text-transform: uppercase;
  color: ${theme.colors.defaultRed};
  background-color: #fcfdff;
  font-weight: 500;
  font-size: 8px;

  &::before {
    content: 'Bobtail';
  }
`
