import actionCreatorFactory, { ActionCreatorFactory } from 'typescript-fsa'
import { IViewingObject, ViewingObjectState } from '../interfaces'

const viewingObjects: ActionCreatorFactory = actionCreatorFactory('VIEWING_OBJECTS')

export const getViewingObjectFromSessionStorage = viewingObjects<ViewingObjectState>(
  'GET_VIEWING_OBJECTS_FROM_SESSION_STORAGE'
)
export const setTabViewingObject = viewingObjects<{ tabId: string; viewingObject: IViewingObject }>('SET_TAB_OBJECT')
export const setTabViewingObjectData = viewingObjects<{ tabId: string; data: any }>('SET_TAB_OBJECT_DATA')
export const modifyTabViewingObject = viewingObjects<{
  tabId: string
  modifiedObject: any
  initialObjectState?: any
}>('MODIFY_TAB_OBJECT')
export const setTabViewingObjectTemporaryData = viewingObjects<{ tabId: string; temporaryData: any }>(
  'SET_TAB_OBJECT_TEMPORARYDATA'
)
export const setTabViewingObjectTemporaryDataProps = viewingObjects<{ tabId: string; temporaryDataProps: object }>(
  'SET_TAB_OBJECT_TEMPORARYDATA_PROPS'
)
export const deleteTabViewingObject = viewingObjects<{ tabId: string }>('DELETE_TAB_DATA')
export const deleteTabsViewingObject = viewingObjects<{ tabIds: string[] }>('DELETE_TABS_DATA')
