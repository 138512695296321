import * as React from 'react'
import { Container } from '../../../../../UI/GridSystem'
import { ColumnTitles, AddRow } from './styledComponents'
import SubClient from './SubClient'
import { ICustomerView } from '../../../interfaces'
import * as R from 'remeda'
import { ISubClient } from '../../../../subClient/interfaces'
import { oc } from 'ts-optchain'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { newSubClient } from '../../../../subClient/reducers'
import { v4 } from 'uuid'

type OwnProps = {
  customer: ICustomerView
  actions: IGridItemActions
}

const extraContainerStyles = { overflow: 'auto scroll' }

const SubClients: React.FC<OwnProps> = ({ customer, actions }) => (
  <Container
    style={extraContainerStyles}
    isGrid={true}
    rows={70}
    background={'#f9f9f9'}
    padding={{ top: 20, left: 20, right: 20 }}
  >
    {Boolean(oc(customer).subClients([]).length) ? (
      <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 10 }}>
        <Container className={'required'} columns={8} margin={{ left: 20 }}>
          Name
        </Container>
        <Container className={'required'} columns={22}>
          Address
        </Container>
        <Container columns={9}>First Name</Container>
        <Container columns={9}>Last Name</Container>
        <Container columns={9}>Phone</Container>
        <Container columns={9}>Email</Container>
      </ColumnTitles>
    ) : null}

    {R.equals(customer.subClients, {})
      ? null
      : customer.subClients.map(subClient => (
          <SubClient
            key={subClient.id}
            subClient={subClient as ISubClient}
            customer={customer}
            actions={actions}
            deleteSubClient={id =>
              actions.modifyParentObjectField('subClients')(customer.subClients.filter(item => item.id !== id))
            }
          />
        ))}

    <AddRow
      rows={9}
      alignCenter={true}
      justifyCenter={true}
      margin={{ bottom: 20 }}
      onClick={() =>
        actions.modifyParentObjectField('subClients')([
          ...customer.subClients,
          {
            ...newSubClient('new' + v4()),
            customerId: customer.id
          }
        ])
      }
    >
      Add Subclient
    </AddRow>
  </Container>
)

export default SubClients
