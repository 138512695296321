import * as React from 'react'
import { Container, ColumnRow, Column } from '../../../GridSystem'
import { EditModeButton, WidgetActions } from '../../styles'
import { Select } from '../../../DataFields/Select'
import { AddressDTO, ContactDTO } from '../../../../../api/origin/business-logic'
import { InputField, Input } from '../../../DataFields/Input'
import { oc } from 'ts-optchain'
import { CustomerSearch } from '../../../DataFields/SearchRequest'
import { updateAddressObject } from '../location/functions'
import { ICustomerView } from '../../../../common/customer/interfaces'
import { BusinessPartner } from '../../../../common/businessPartner/types'
import { TContact } from '../../../../common/contact/interfaces'
import { createNewContact } from '../../../../common/contact/reducers'
import { checkCustomerValidation } from '../../../../../services/DTO/customer'
import { FieldTemplate } from '../../../DataFields/Templates'
import { customerLists } from '../../../../../services/select/customerLists'
import { getCustomerById } from '../../../../common/customer/epics'
import { GridSpinner } from '../../../Spinner/Spinner'
import { WidgetContext } from '../../index'
import { ActionButton } from '../../../Buttons/ActionButton'
import { assembleDTO } from '../../../../../services/DTO/assemble'

export const Popover: React.SFC<{
  disabled?: boolean
  customer: ICustomerView
  businessPartner: BusinessPartner
  primaryContact: TContact
  updateCustomer: (customer: ICustomerView, updateInitialState?: boolean) => void
  updateBusinessPartner: (businessPartner: BusinessPartner) => void
  updatePrimaryContact: (updatedPrimaryContact: TContact) => void
  isModifiedMode?: boolean
}> = ({
  disabled,
  updateCustomer,
  customer,
  businessPartner,
  primaryContact,
  updateBusinessPartner,
  updatePrimaryContact,
  isModifiedMode
}) => {
  const { closePopover } = React.useContext(WidgetContext)
  const [edit, setEdit] = React.useState(
    customer && customer.fullObject ? !checkCustomerValidation(customer, true) : false
  )
  const [fetching, setFetching] = React.useState(customer && !customer.fullObject)
  const address = oc(businessPartner).legalAddress(null)

  React.useEffect(() => {
    if (customer && !customer.fullObject) {
      getCustomerById(customer.id)
        .then(resolvedCustomer => {
          if (isModifiedMode) {
            updateCustomer(assembleDTO.customer({ store: undefined, id: resolvedCustomer.id }), true)
          }
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }, [])

  const updateCustomerField = (fieldName: keyof ICustomerView) => (value: any) =>
    updateCustomer({ ...customer, [fieldName]: value })

  const updateBusinessPartnerField = (fieldName: keyof BusinessPartner) => (value: any) =>
    updateBusinessPartner({ ...businessPartner, [fieldName]: value })

  const updateAddressField = (fieldName: keyof AddressDTO) => (value: any) =>
    updateBusinessPartner({ ...businessPartner, legalAddress: updateAddressObject(address)(fieldName)(value) })

  const updatePrimaryContactField = (field: keyof ContactDTO) => (value: any) =>
    updatePrimaryContact(
      primaryContact
        ? { ...primaryContact, [field]: value }
        : {
            ...createNewContact(true),
            [field]: value
          }
    )

  return (
    <>
      {fetching && <GridSpinner />}
      <Container columns={32} flexClear={true} isGrid={true}>
        <ColumnRow margin={{ bottom: 20 }}>
          <CustomerSearch
            disabled={disabled}
            title={'Find Customer'}
            required={true}
            value={customer}
            onChange={item => updateCustomer(item as ICustomerView)}
          />
        </ColumnRow>

        {customer && customer.fullObject && (
          <>
            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  required={true}
                  title={'Name'}
                  value={oc(businessPartner).legalName('')}
                  onChange={updateBusinessPartnerField('legalName')}
                />
              </Column>
              <Column>
                <Input
                  required={true}
                  disabled={!edit}
                  title={'Address'}
                  value={oc(address).street('')}
                  onChange={updateAddressField('street')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  title={'Company'}
                  value={oc(businessPartner).dbaName('')}
                  onChange={updateBusinessPartnerField('dbaName')}
                />
              </Column>
              <Column>
                <Column columns={5} margin={{ right: 10 }}>
                  <Input
                    disabled={!edit}
                    required={true}
                    title={'City'}
                    value={oc(address).city('')}
                    onChange={updateAddressField('city')}
                  />
                </Column>
                <Column style={{ fontSize: 14 }} columns={4} isFixed={true} margin={{ right: 10 }}>
                  <FieldTemplate.StateSearch
                    disabled={!edit}
                    required={true}
                    showCode={true}
                    title={'State'}
                    stateId={oc(address).stateId(null)}
                    onChange={updateAddressField('stateId')}
                  />
                </Column>
                <Column columns={5} isFixed={true}>
                  <InputField.Zip
                    disabled={!edit}
                    title={'ZIP'}
                    value={oc(address).postalCode('')}
                    onChange={updateAddressField('postalCode')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <Column rows={0.2} background={'#eff2f6'} margin={{ bottom: 15 }} />

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Select
                  disabled={!edit}
                  required={true}
                  title={'Net Terms Unit'}
                  selectedValue={oc(customer).netTermsUnit(null)}
                  list={customerLists.netTermsUnit}
                  onSelect={updateCustomerField('netTermsUnit')}
                />
              </Column>
              <Column>
                <Column margin={{ right: 10 }}>
                  <Input
                    disabled={!edit}
                    title={'First Name'}
                    value={oc(primaryContact).firstName('')}
                    onChange={updatePrimaryContactField('firstName')}
                  />
                </Column>
                <Column>
                  <Input
                    disabled={!edit}
                    title={'Last Name'}
                    value={oc(primaryContact).lastName('')}
                    onChange={updatePrimaryContactField('lastName')}
                  />
                </Column>
              </Column>
            </ColumnRow>

            <ColumnRow margin={{ bottom: 20 }}>
              <Column margin={{ right: 16 }}>
                <Select
                  disabled={!edit}
                  required={true}
                  title={'Net Terms'}
                  selectedValue={oc(customer).netTerms(null)}
                  list={customerLists.netTerms}
                  onSelect={updateCustomerField('netTerms')}
                />
              </Column>
              <Column>
                <InputField.Email
                  disabled={!edit}
                  title={'E-mail'}
                  value={oc(primaryContact).email('')}
                  onChange={updatePrimaryContactField('email')}
                />
              </Column>
            </ColumnRow>

            <ColumnRow>
              <Column margin={{ right: 16 }}>
                <Input
                  disabled={!edit}
                  title={'Note'}
                  placeholder={'Type note here...'}
                  value={oc(businessPartner).notes('')}
                  onChange={updateBusinessPartnerField('notes')}
                />
              </Column>
              <Column>
                <Column margin={{ right: 10 }}>
                  <InputField.PhoneNumber
                    disabled={!edit}
                    title={'Phone'}
                    value={oc(primaryContact).phone('')}
                    onChange={updatePrimaryContactField('phone')}
                  />
                </Column>
                <Column>
                  <InputField.PhoneNumber
                    disabled={!edit}
                    title={'Fax'}
                    value={oc(primaryContact).fax('')}
                    onChange={updatePrimaryContactField('fax')}
                  />
                </Column>
              </Column>
            </ColumnRow>
          </>
        )}
      </Container>

      <WidgetActions>
        {!disabled && !edit && customer && <EditModeButton onClick={() => setEdit(true)}>Edit Customer</EditModeButton>}
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}
