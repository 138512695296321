import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { addSteamShipLineToStore } from '../action'
import { ISteamShipLineState, ISteamShipLine } from '../interfaces'
import { FeeScheduleDTO } from '../../../../api/origin/business-logic/api'
import { createNewBusinessPartner } from '../../businessPartner/reducers'

export const newSteamShipLine: ISteamShipLine = {
  fullObject: true,
  id: 'newSteamShipLine',
  businessPartner: createNewBusinessPartner(),
  businessPartnerId: null,
  containerDetentionPerDiemFeeSchedule: [],
  freeDetentionAllowance: null,
  referenceNumber: null,
  scac: null
}

export const newContainerDetentionPerDiemFeeSchedule: FeeScheduleDTO = { toDay: null, fromDay: null, cost: null }

const steamShipState: ISteamShipLineState = {}

const steamShipReducer = reducerWithInitialState<ISteamShipLineState>(steamShipState)
  .case(addSteamShipLineToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default steamShipReducer
