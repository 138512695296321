import * as React from 'react'
import { Header } from './Header'
import { Body } from './Body'
import { BasicTableHeader, BasicTableBody } from './interfaces'
import styled from 'styled-components'

type Props = {
  styles?: any
  header?: BasicTableHeader
  body?: BasicTableBody
}

export const BasicTable = (props: Props) => {
  const { styles, header, body } = props
  return (
    <StyledTable style={styles}>
      {header && <Header columns={header.columns} styles={header.styles} />}
      {Boolean(body && body.rows.length) && <Body styles={body.styles} rows={body.rows} />}
    </StyledTable>
  )
}

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  flex-grow: 1;
  overflow-y: auto;
`
