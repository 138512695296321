import * as React from 'react'
import { oc } from 'ts-optchain'
import { Column, ColumnRow, Container, GridColumn } from '../../../../../UI/GridSystem'
import { CopyAddress, StyledCredentials, Title } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { Select } from '../../../../../UI/DataFields/Select'
import { DriverViewDTO } from '../../../../../../api/origin/business-logic'
import { IDriver } from '../../../interfaces'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { Date } from '../General/styledComponents'
import AddressField from '../../../../../UI/AddressField'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { Checkbox } from '../../../../../UI/Checkbox'
import { FieldContainer } from '../../../../../UI/DataFields/FieldContainer'
import { driverLists } from '../../../../../../services/select/driverLists'
import { DriverReport } from '../../../../../UI/DataFields/DriverReport/DriverReport'
import { useVendorAccountingReports } from '../../../../../../hooks/useVendorAccountingReports'
import { isAddressValid } from '../../../../../../services/functions/test/isAddressValid'
import { DriverReportDTO, FileVersionDTO } from '../../../../../../api/origin/document-service'
import { DriverReportFetchError } from '../../../../../UI/DataFields/DriverReport/DriverReportFetchError'
import { isDriverFieldRequired } from '../../../../../../services/functions/test/isDriverFieldRequired'

type OwnProps = { driver: IDriver; actions: IGridItemActions }
type DispatchProps = {}

type Props = DispatchProps & OwnProps

const Credentials = ({
  driver,
  actions: { modify, modifyParentObjectField, togglePreviewTab, setFetching, isModified }
}: Props) => {
  const { reports, fetchError, createReport, requestReports } = useVendorAccountingReports()

  React.useLayoutEffect(() => {
    if (
      driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR ||
      driver.driverType === DriverViewDTO.DriverTypeEnum.OUTSOURCE
    ) {
      togglePreviewTab('General')
    }
  }, [driver.driverType])

  if (
    driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR ||
    driver.driverType === DriverViewDTO.DriverTypeEnum.OUTSOURCE
  ) {
    return <StyledCredentials minRows={81} padding={{ top: 30, left: 60, right: 20 }} />
  }

  return (
    <StyledCredentials minRows={81} padding={{ top: 30, left: 60, right: 20 }}>
      <Column columns={9} isGrid={true} flexBasis={'auto'} padding={{ top: 45 }}>
        <ColumnRow margin={{ bottom: 25 }}>
          <FieldContainer title={'ELD'}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px' }}>
                <Checkbox
                  checked={Boolean(driver.eld)}
                  onChange={(eld: IDriver['eld']) => modify({ ...driver, eld, eldNumber: '' })}
                />
              </div>
              <div style={{ width: 'calc(100% - 30px)' }}>
                <Input
                  maxLength={255}
                  disabled={!driver.eld}
                  value={driver.eldNumber}
                  onChange={modifyParentObjectField('eldNumber')}
                />
              </div>
            </div>
          </FieldContainer>
        </ColumnRow>
        <ColumnRow margin={{ bottom: 25 }}>
          <Select
            title={'Milage Restrictions'}
            required={true}
            selectedValue={driver.millageRestriction}
            list={driverLists.millageRestriction}
            onSelect={millageRestriction => modify({ ...driver, millageRestriction })}
          />
        </ColumnRow>
      </Column>

      <Column columns={20} isGrid={true} flexBasis={'auto'}>
        <Title>License</Title>
        <ColumnRow margin={{ bottom: 25 }}>
          <GridColumn>
            <Input
              title={'Number'}
              required={true}
              maxLength={100}
              value={driver.licenseNumber}
              onChange={modifyParentObjectField('licenseNumber')}
            />
          </GridColumn>
          <Date margin={{ right: 20, left: 20 }}>
            <DateField
              title={'Issue Date'}
              required={true}
              date={driver.licenseIssueDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('licenseIssueDate')}
            />
          </Date>
          <Date>
            <DateField
              title={'Exp. Date'}
              required={true}
              date={driver.licenseExpDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('licenseExpDate')}
            />
          </Date>
        </ColumnRow>

        <ColumnRow margin={{ bottom: 25 }}>
          <CopyAddress
            className={!driver.mailingAddress ? 'disabled' : ''}
            onClick={() =>
              driver.mailingAddress
                ? modify({
                    ...driver,
                    licenseAddress: { ...driver.mailingAddress, id: null }
                  })
                : null
            }
          >
            Same as mailing address
          </CopyAddress>
          <AddressField
            required={isDriverFieldRequired(driver).licenseAddress}
            title={'Address Search'}
            highlighted={driver.licenseAddress ? !isAddressValid(driver.licenseAddress) : false}
            address={driver.licenseAddress}
            onChange={modifyParentObjectField('licenseAddress')}
          />
        </ColumnRow>

        <div style={{ display: 'grid', gridTemplateColumns: '5fr 4fr', gridGap: 20, marginBottom: 25 }}>
          <FieldTemplate.StateSearch
            required={true}
            title={'Issue State'}
            stateId={oc(driver).licenseIssueStateId(null)}
            onChange={(stateId: string) =>
              modify({
                ...driver,
                licenseIssueStateId: stateId
              })
            }
          />
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 20 }}>
            <ViewingObjectFileUploader
              required={isDriverFieldRequired(driver).licenseDocumentFile}
              uploadingFileId={driver.id + ' driver.licenseDocumentFile'}
              title={'Front'}
              file={driver.licenseDocumentFile}
              onFileUploaded={(licenseDocumentFile, modifiedObject) => ({ ...modifiedObject, licenseDocumentFile })}
              onClearFile={() => modifyParentObjectField('licenseDocumentFile')(undefined)}
              historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORLICENSE }}
            />
            <ViewingObjectFileUploader
              required={isDriverFieldRequired(driver).licenseBackDocumentFile}
              uploadingFileId={driver.id + ' driver.licenseBackDocumentFile'}
              title={'Back'}
              file={driver.licenseBackDocumentFile}
              onFileUploaded={(licenseBackDocumentFile, modifiedObject) => ({
                ...modifiedObject,
                licenseBackDocumentFile
              })}
              onClearFile={() => modifyParentObjectField('licenseBackDocumentFile')(undefined)}
              historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORLICENSEBACK }}
            />
          </div>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '5fr 4fr', gridGap: 20 }}>
          <Select
            title={'Endorsements'}
            buttonClear={true}
            selectedValue={driver.endorsement}
            multiselect={true}
            separatePopoverRows={true}
            list={driverLists.endorsement}
            onSelect={endorsement => modify({ ...driver, endorsement })}
          />
          <DateField
            title={'Endorsements Exp. Date'}
            date={driver.endorsementExpDate}
            dateType={DateTypes.DateISOString}
            placeholder={'MM/DD/YYYY'}
            onUpdate={modifyParentObjectField('endorsementExpDate')}
          />
        </div>
      </Column>

      <Column columns={11} isGrid={true} flexBasis={'auto'} padding={{ top: 45 }}>
        {fetchError ? (
          <DriverReportFetchError requestReports={() => requestReports({ spinner: true })} />
        ) : (
          [DriverReportDTO.TypeEnum.PSP, DriverReportDTO.TypeEnum.MVR].map(reportType => (
            <div key={reportType} style={{ marginBottom: 25 }}>
              <DriverReport
                disable={isModified || !driver.applicationDocumentFile}
                reportType={reportType}
                report={reports[reportType]}
                reportHistory={reports.history[reportType]}
                onGenerateReportClick={() => createReport(reportType)}
              />
            </div>
          ))
        )}
      </Column>

      <Column columns={18} isGrid={true} flexBasis={'auto'} padding={{ top: 45 }}>
        <ColumnRow margin={{ bottom: 25 }}>
          <Date>
            <DateField
              title={'Med. Exam. Exp. Date'}
              required={isDriverFieldRequired(driver).medicalExamCardExpDate}
              date={driver.medicalExamCardExpDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('medicalExamCardExpDate')}
            />
          </Date>
          <Container margin={{ left: 20 }}>
            <ViewingObjectFileUploader
              required={isDriverFieldRequired(driver).medicalExamCardDocumentFile}
              uploadingFileId={driver.id + ' driver.medicalExamCardDocumentFile'}
              title={'Med. Exam. Card Copy'}
              file={driver.medicalExamCardDocumentFile}
              onFileUploaded={(medicalExamCardDocumentFile, modifiedObject) => ({
                ...modifiedObject,
                medicalExamCardDocumentFile
              })}
              onClearFile={() => modifyParentObjectField('medicalExamCardDocumentFile')(undefined)}
              historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORMEDICALEXAM }}
            />
          </Container>
        </ColumnRow>
        <ColumnRow margin={{ bottom: 25 }}>
          <Date>
            <DateField
              title={'TWIC Exp. Date'}
              required={isDriverFieldRequired(driver).twicExpDate}
              date={driver.twicExpDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('twicExpDate')}
            />
          </Date>
          <Container margin={{ left: 20 }}>
            <ViewingObjectFileUploader
              required={isDriverFieldRequired(driver).twicDocumentFile}
              uploadingFileId={driver.id + ' driver.twicDocumentFile'}
              title={'TWIC Copy'}
              file={driver.twicDocumentFile}
              onFileUploaded={(twicDocumentFile, modifiedObject) => ({ ...modifiedObject, twicDocumentFile })}
              onClearFile={() => modifyParentObjectField('twicDocumentFile')(undefined)}
              historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORTWIC }}
            />
          </Container>
        </ColumnRow>
        <ColumnRow>
          <Date>
            <DateField
              required={isDriverFieldRequired(driver).portCredentialsExpDate}
              title={'Port Credentials Exp. Date'}
              date={driver.portCredentialsExpDate}
              dateType={DateTypes.DateISOString}
              placeholder={'MM/DD/YYYY'}
              onUpdate={modifyParentObjectField('portCredentialsExpDate')}
            />
          </Date>
          <Container margin={{ left: 20 }}>
            <ViewingObjectFileUploader
              required={isDriverFieldRequired(driver).portCredentialsDocumentFile}
              uploadingFileId={driver.id + ' driver.portCredentialsDocumentFile'}
              title={'Port Credentials Copy'}
              file={driver.portCredentialsDocumentFile}
              onFileUploaded={(portCredentialsDocumentFile, modifiedObject) => ({
                ...modifiedObject,
                portCredentialsDocumentFile
              })}
              onClearFile={() => modifyParentObjectField('portCredentialsDocumentFile')(undefined)}
              historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORPORT }}
            />
          </Container>
        </ColumnRow>
      </Column>
    </StyledCredentials>
  )
}

export default Credentials
