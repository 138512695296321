import * as React from 'react'
import { Widget } from '../../'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { DateISOString, DeliveryOrderViewDTO, SteamShipLineDTO } from '../../../../../api/origin/business-logic'
import { oc } from 'ts-optchain'
import { connect } from 'react-redux'
import { Window } from './preview'
import { Popover } from './popover'
import { isDeliveryOrderIdSteamShipLineRequiredByDDOs } from '../../../../../services/DTO/deliveryOrder'

type OwnProps = {
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDeliveryOrder: (fieldName: keyof DeliveryOrderViewDTO) => (value: any) => void
  updateSteamShipLine: (steamShipLine: SteamShipLineDTO) => void
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export type SteamShipLinePropsType = {
  name: string
  vesselName: string
  voyage: string
  date: DateISOString
}
const SteamShipLine: React.FC<Props> = props => {
  const {
    dispatchDeliveryOrder: { deliveryOrder },
    updateDeliveryOrder,
    updateSteamShipLine,
    disabled
  } = props
  const isExport = deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const steamShipLineIsRequired = isDeliveryOrderIdSteamShipLineRequiredByDDOs(deliveryOrder.id)(
    props.dispatchDeliveryOrder
  )
  const steamShipLineProps = {
    name: oc(deliveryOrder).steamShipLine.name(''),
    vesselName: oc(deliveryOrder).vesselName(''),
    voyage: oc(deliveryOrder).voyageNumber(''),
    date: deliveryOrder[isExport ? 'vesselDepartureDate' : 'vesselArrivalDate']
  }

  return (
    <Widget
      title={'SSL'}
      highlighted={!deliveryOrder.steamShipLineId && steamShipLineIsRequired}
      RenderPreview={Window}
      RenderDetails={Popover}
      previewProps={{ steamShipLineProps }}
      detailsProps={{
        steamShipLineIsRequired,
        disabled,
        updateSteamShipLine,
        deliveryOrder,
        steamShipLineProps,
        updateDeliveryOrder,
        isExport
      }}
    />
  )
}

export default connect(
  null,
  null
)(SteamShipLine)
