import * as React from 'react'
import { NotificationType, TNotification } from '../../interfaces'
import { TroubleTicketAlert } from './TroubleTicketAlert'
import { NotificationList } from '../styles'

type StateProps = {
  notification: TNotification
  removeNotification: () => void
}

type Props = StateProps

export const Notification = (props: Props) => {
  const { notification, removeNotification } = props

  switch (notification.type) {
    case NotificationType.alert:
      return <TroubleTicketAlert data={notification.data} removeNotification={removeNotification} />
    default:
      return null
  }
}
