import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { arrayMove } from 'react-sortable-hoc'
import {
  setTabFetching,
  createNewTab,
  duplicateTab,
  getTabsFromSessionStorage,
  moveTab,
  removeAllTabsExcept,
  removeRightTabs,
  removeTab,
  addCreatedGridItem,
  setActiveTabId,
  updateTab,
  updateTabData,
  updateTabGridItems,
  updateTabSettings,
  updateVisibleTabsNumber,
  updateTabSorting,
  updateTabColumns,
  updateTabFilters,
  setTabData,
  setFavoriteOngoingActivityState
} from '../actions'
import { ITabsStoreState, TabType } from '../interfaces'
import { createDefaultTab, pluralNamePerTabType, settingsPerTabType } from '../../../../services/uiSettingsService/tabs'
import { ApplicationContext } from '../../../../store/store.interface'
import { oc } from 'ts-optchain'
import { filteringStateByTabType, sortingStateByTabType } from '../../../../services/tabs/functions'
import nanoid = require('nanoid')
// tslint:disable:max-line-length

export const sectionInitialState: ITabsStoreState = {
  activeMainTabId: undefined,
  main: [],
  admin: []
}

export default reducerWithInitialState(sectionInitialState)
  .case(updateVisibleTabsNumber, (state, payload) => ({
    ...state,
    visibleTabsNumber: payload
  }))
  .case(setActiveTabId, (state, { tabId, context = ApplicationContext.main }) => ({
    ...state,
    [context === ApplicationContext.main ? 'activeMainTabId' : 'activeAdminTabId']: tabId
  }))
  .case(setTabFetching, (state, { tabId, fetching, context = ApplicationContext.main, visited }) => {
    return {
      ...state,
      [context]: state[context].map(tab =>
        tab.id === tabId
          ? { ...tab, spinner: fetching, visited: typeof visited === 'boolean' ? visited : tab.visited }
          : tab
      )
    }
  })
  .case(createNewTab, (state, { tabType, data, uiSettings, options = {} }) => {
    const context = tabType !== TabType.users ? ApplicationContext.main : ApplicationContext.admin
    let tabIndex = 1
    const tabs = state[context]
    tabs.forEach(tab => {
      if (tab.type === tabType) {
        tabIndex++
      }
    })
    const newTab = {
      ...createDefaultTab(),
      type: tabType,
      title: pluralNamePerTabType[tabType] + ' ' + tabIndex,
      id: nanoid(),
      uiSettings: uiSettings || settingsPerTabType[tabType],
      enableEditing: true,
      enableFiltering: filteringStateByTabType(tabType),
      enableSorting: sortingStateByTabType(tabType),
      data,
      ...options
    }

    return {
      ...state,
      activeMainTabId: newTab.id,
      [context]: tabs.concat(newTab)
    }
  })
  .case(updateTab, (state, { tab, context = ApplicationContext.main }) => {
    const tabs = state[context].map(oldTab => (oldTab.id === tab.id ? tab : oldTab))
    return {
      ...state,
      [context]: tabs
    }
  })
  .case(setTabData, (state, { tabId, tabData, context = ApplicationContext.main, visited }) => {
    const tabs = state[context].map(tab =>
      tab.id === tabId ? { ...tab, data: tabData, visited: typeof visited === 'boolean' ? visited : tab.visited } : tab
    )
    return {
      ...state,
      [context]: tabs
    }
  })
  .case(updateTabData, (state, { tabId, options, context = ApplicationContext.main, visited }) => {
    const tabs = state[context].map(tab =>
      tab.id === tabId
        ? {
            ...tab,
            data: { ...(tab.data || {}), ...options },
            visited: typeof visited === 'boolean' ? visited : tab.visited
          }
        : tab
    )
    return {
      ...state,
      [context]: tabs
    }
  })
  .case(moveTab, (state, { currentTab, insertBeforeTab, context }) => {
    const tabs = state[context]
    const currentTabIndex = tabs.findIndex(({ id }) => id === currentTab.id)
    const insertBeforeTabIndex = tabs.findIndex(({ id }) => id === insertBeforeTab.id)
    const updatedTabs = arrayMove(tabs, currentTabIndex, insertBeforeTabIndex)

    return {
      ...state,
      activeMainTabId: currentTab.id,
      [context]: updatedTabs
    }
  })
  .case(duplicateTab, (state, { originalTab, context }) => {
    let tabIndex = 1
    const tabs = state[context]
    tabs.forEach(tab => {
      if (tab.type === originalTab.type) {
        tabIndex++
      }
    })
    const duplicatedTab = {
      ...originalTab,
      title: pluralNamePerTabType[originalTab.type] + ' ' + tabIndex,
      id: nanoid()
    }

    return {
      ...state,
      activeMainTabId: duplicatedTab.id,
      [context]: tabs.concat(duplicatedTab)
    }
  })
  .case(removeTab, (state, { id, context = ApplicationContext.main }) => {
    const tabs = state[context]
    let newActiveTabId: string = state.activeMainTabId !== id ? state.activeMainTabId : undefined

    if (tabs.length === 1) {
      return state
    }

    const filteredTabs = tabs.filter((tab, index) => {
      if (tab.id === id) {
        if (!newActiveTabId) {
          newActiveTabId = oc(tabs[index - 1]).id()
        }

        return false
      }

      return true
    })

    if (!newActiveTabId) {
      newActiveTabId = filteredTabs[0].id
    }

    return { ...state, activeMainTabId: newActiveTabId, [context]: filteredTabs }
  })
  .case(removeAllTabsExcept, (state, { tab, context }) => {
    return {
      ...state,
      activeMainTabId: tab.id,
      [context]: [tab]
    }
  })
  .case(removeRightTabs, (state, { tab, context }) => {
    let targetTabFound = false
    const tabs = state[context].reduce((acc, currTab) => {
      if (currTab.id === tab.id) {
        targetTabFound = true
        acc.push(currTab)
      }

      if (!targetTabFound) {
        acc.push(currTab)
      }

      return acc
    }, [])

    return {
      ...state,
      activeMainTabId: tab.id,
      [context]: tabs
    }
  })
  .case(getTabsFromSessionStorage, (state, payload) => {
    return {
      ...state,
      main: oc(payload).settings.main([]),
      admin: oc(payload).settings.admin([]),
      activeMainTabId: oc(payload).settings.activeMainTabId()
    }
  })
  // addNewGridItem
  .case(updateTabSettings, (state, { id, uiSettings, context = ApplicationContext.main }) => {
    return { ...state, [context]: state[context].map(tab => (tab.id === id ? { ...tab, uiSettings } : tab)) }
  })
  .case(updateTabSorting, (state, { id, sorting, context = ApplicationContext.main }) => {
    return {
      ...state,
      [context]: state[context].map(tab =>
        tab.id === id ? { ...tab, uiSettings: { ...tab.uiSettings, sorting } } : tab
      )
    }
  })
  .case(updateTabFilters, (state, { id, filter, context = ApplicationContext.main }) => {
    return {
      ...state,
      [context]: state[context].map(tab =>
        tab.id === id ? { ...tab, uiSettings: { ...tab.uiSettings, filter } } : tab
      )
    }
  })
  .case(updateTabColumns, (state, { id, columns, context = ApplicationContext.main }) => {
    return {
      ...state,
      [context]: state[context].map(tab =>
        tab.id === id ? { ...tab, uiSettings: { ...tab.uiSettings, columns } } : tab
      )
    }
  })
  .case(updateTabGridItems, (state, { context, tabId, idList, data }) => {
    return {
      ...state,
      [context]: state[context].map(tab => {
        if (tab.id !== tabId) {
          return tab
        }

        return {
          ...tab,
          gridItemIds: idList,
          data: data || tab.data,
          visited: true
        }
      })
    }
  })
  .case(addCreatedGridItem, (state, { context, tabId, unitId }) => {
    const updatedTabs = state[context].map(tab => {
      if (tab.id === tabId) {
        const pushNewItem = !tab.gridItemIds.includes(unitId)

        return {
          ...tab,
          gridItemIds: pushNewItem ? [unitId, ...tab.gridItemIds] : tab.gridItemIds
        }
      }

      return tab
    })
    return {
      ...state,
      [context]: updatedTabs
    }
  })
  .case(setFavoriteOngoingActivityState, (state, props) => {
    const { tabId, ongoingActivityId, favoriteState } = props
    return {
      ...state,
      main: state.main.map(tab =>
        tab.id === tabId
          ? {
              ...tab,
              data: {
                ...(tab.data || {}),
                favoriteItemIds: { ...oc(tab).data.favoriteItemIds({}), [ongoingActivityId]: favoriteState }
              }
            }
          : tab
      )
    }
  })
  .build()
