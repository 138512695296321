import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IDispatchDeliveryOrderState } from '../interfaces'
import { addDispatchDeliveryOrderToStore } from '../action'

const dispatchDeliveryOrderState: IDispatchDeliveryOrderState = {}

const dispatchDeliveryOrderReducer = reducerWithInitialState<IDispatchDeliveryOrderState>(dispatchDeliveryOrderState)
  .case(addDispatchDeliveryOrderToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default dispatchDeliveryOrderReducer
