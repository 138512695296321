import * as React from 'react'
import styled from 'styled-components'
import { Column, Container, GridColumn } from '../../../../../../UI/GridSystem'
import { Input } from '../../../../../../UI/DataFields/Input'
import { LocationDTO } from '../../../../../../../api/origin/business-logic/api'
import { Select } from '../../../../../../UI/DataFields/Select'
import Toggle from '../../../../../../UI/Toggle'
import { locationLists } from '../../../../../../../services/select/locationLists'
import { Checkbox } from '../../../../../../UI/Radio'

const SwitchSearchVisibility = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;

  > span {
    margin-right: 10px;
  }
`

const TerminalIntegration = styled.div`
  display: flex;
`
const TerminalAlert = styled.div`
  font-size: 10px;
  color: #ff5e5e;
  .mdi {
    position: relative;
    top: 1px;
    font-size: 12px;
    color: #ff5e5e;
    margin-right: 16px;
  }
`

const StyledCompany = styled(Column)`
  .input-container,
  .select-container {
    background-color: #fff;
  }
`

const Company: React.SFC<{
  location: LocationDTO
  modifyParentObjectField: (fieldName: keyof LocationDTO) => (value: string) => void
  modify: (modifiedUnit: any) => void
}> = ({ location, modifyParentObjectField, modify }) => (
  <StyledCompany
    columns={17}
    maxColumns={25}
    isGrid={true}
    background={'#f9f9f9'}
    padding={{ top: 20, left: 60, right: 60 }}
    margin={{ right: 60 }}
  >
    <SwitchSearchVisibility>
      <span>Hide in search results</span>
      <Checkbox active={!location.active} onChange={active => modifyParentObjectField('active')(!active as any)} />
    </SwitchSearchVisibility>
    <Container rows={11} margin={{ bottom: 25 }}>
      <Input
        title={'Name'}
        maxLength={225}
        required={true}
        value={location.name}
        onChange={modifyParentObjectField('name')}
      />
    </Container>
    <Container rows={11} margin={{ bottom: 25 }}>
      <GridColumn padding={{ right: 10 }}>
        <Select
          title={'Type'}
          required={true}
          selectedValue={location.type}
          list={locationLists.type}
          onSelect={modifyParentObjectField('type')}
        />
      </GridColumn>
      <GridColumn>
        <Input title={'Code'} maxLength={6} value={location.code} onChange={modifyParentObjectField('code')} />
      </GridColumn>
    </Container>
    <Container rows={11} margin={{ bottom: 25 }}>
      <Input title={'Company'} maxLength={225} value={location.careOf} onChange={modifyParentObjectField('careOf')} />
    </Container>
    <Container rows={11} margin={{ bottom: 13 }}>
      <Input
        title={'Description'}
        maxLength={512}
        value={location.description}
        onChange={modifyParentObjectField('description')}
      />
    </Container>
    {location.terminalIntegration && (
      <TerminalIntegration>
        <Toggle
          checked={location.terminalIntegration.active}
          onChange={active =>
            modify({
              ...location,
              terminalIntegration: {
                ...location.terminalIntegration,
                active
              }
            })
          }
        />
        Terminal integration
        {location.terminalIntegration.fault && (
          <TerminalAlert>
            <i className={'mdi mdi-alert-circle'} /> Terminal Alert
          </TerminalAlert>
        )}
      </TerminalIntegration>
    )}
  </StyledCompany>
)

export default Company
