import { PreviewNavigation } from '../../../common/grid/views/styledComponents'
import * as React from 'react'
import cn from 'classnames'
import { whatIsIt } from '../../../../services/DTO/functions'

type TNavigation = {
  unit: any
  headings: string[] | ((unit: any) => string[])
  activeTab: string
  handleClick: (heading: string) => void
}

export const Navigation = ({ headings, activeTab, handleClick, unit }: TNavigation) => {
  const tabs = whatIsIt(headings).isFunction ? (headings as (unit: any) => string[])(unit) : (headings as string[])

  return (
    <PreviewNavigation>
      {tabs.map(heading => (
        <div key={heading} className={cn({ active: activeTab === heading })} onClick={() => handleClick(heading)}>
          {heading}
        </div>
      ))}
    </PreviewNavigation>
  )
}
