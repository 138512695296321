import * as React from 'react'
import { oc } from 'ts-optchain'
import { locationTypeIcons } from '../../../../../services/DTO/location'
import { TGridColumn } from '../../../grid/views/interfaces'
import { NoWrap, TableCell } from '../../../grid/views/styles'
import { StatusColumn } from './StatusColumn'
import { ContainerColumn } from './ContainerColumn'
import { DeliveryOrderNumberColumn } from './DeliveryOrderNumberColumn'
import { ShuttleLocationColumn } from './ShuttleLocationColumn'
import { AppDateConfirmedIndicator } from '../Details/General/Center'
import { DispatchDeliveryOrderColumn } from '../configGrid'
import { NotificationsColumn } from './NotificationsColumn'
import { DispatchDeliveryOrderNumberColumn } from './DispatchDeliveryOrderNumberColumn'
import { getStore } from '../../../../../store/configureStore'
import { DriverColumn } from './DriverColumn'
import theme from '../../../../../styles/theme'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'

const Column = (props: TGridColumn) => {
  const { value, columnName, className, style } = props

  switch (columnName) {
    case DispatchDeliveryOrderColumn.Notifications:
      return (
        <TableCell className={className} style={{ ...(style || {}), padding: 0 }}>
          <div style={{ display: 'flex', alignSelf: 'stretch' }}>
            <NotificationsColumn dispatchDeliveryOrder={value} />
          </div>
        </TableCell>
      )
    case 'DO #':
      return <DeliveryOrderNumberColumn {...props} />
    case 'Pickup':
    case 'Return': {
      const { location, isShuttle } = value
      const shortName = oc(location).shortName('')

      return (
        <TableCell
          className={className}
          title={shortName}
          style={{ ...style, ...(columnName === 'Pickup' ? pickupColumnStyles : returnColumnStyles) }}
          data-copy-value-on-mousedown={true}
        >
          <ShuttleLocationColumn location={location} isShuttle={isShuttle} />
        </TableCell>
      )
    }
    case 'Booking / BL': {
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <div>{value || ''}</div>
        </TableCell>
      )
    }
    case 'Status':
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <StatusColumn dispatchDeliveryOrder={value} />
        </TableCell>
      )
    case 'Type':
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <div style={{ fontWeight: 500, textTransform: 'uppercase', letterSpacing: '1.5px' }}>{value}</div>
        </TableCell>
      )
    case 'Pickup: Driver':
      return (
        <TableCell
          className={className}
          style={{ ...style, ...pickupColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <DriverColumn driverId={value} />
        </TableCell>
      )
    case DispatchDeliveryOrderColumn.PickUpPlanned:
      return (
        <TableCell
          className={className}
          data-copy-value-on-mousedown={true}
          style={{ ...style, ...pickupColumnStyles }}
        >
          <div>{value || ''}</div>
        </TableCell>
      )
    case 'Delivery: Driver':
      return (
        <TableCell
          className={className}
          style={{ ...style, ...deliveryColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <DriverColumn driverId={value} />
        </TableCell>
      )
    case 'Delivery: Activity':
    case 'Activity Planned':
    case 'Activity Completion':
      return (
        <TableCell
          className={className}
          style={{ ...style, ...deliveryColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <div>{value || ''}</div>
        </TableCell>
      )
    case 'Delivery': {
      const destination = value

      if (!destination) {
        return (
          <TableCell
            className={className}
            style={{ ...style, ...deliveryColumnStyles }}
            data-copy-value-on-mousedown={true}
          />
        )
      }

      const isInvalid = isLocationGEOValid(destination) === false
      const cityState = [
        oc(destination).address.city(),
        oc(destination).address.stateId() && oc(getStore().getState()).state[destination.address.stateId].code()
      ]
        .filter(Boolean)
        .join(', ')
      const zip = oc(destination).address.postalCode()
      const shortNameAddress = [oc(destination).shortName(), oc(destination).address.street()]
        .filter(Boolean)
        .join(', ')
      const zipShortNameAddress = [zip, shortNameAddress].filter(Boolean).join(', ')

      return (
        <TableCell
          className={className}
          style={{ ...style, ...deliveryColumnStyles, color: isInvalid ? theme.colors.defaultRed : undefined }}
          data-copy-value={oc(destination).longName()}
          data-copy-value-on-mousedown={true}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}
            className={locationTypeIcons[destination.type]}
          >
            <NoWrap style={{ marginLeft: 5, fontSize: 12 }}>
              {cityState && <span style={{ fontWeight: 500, fontSize: 14 }}>{cityState}</span>}
              {cityState && zipShortNameAddress && ', '}
              {zipShortNameAddress}
            </NoWrap>
          </div>
        </TableCell>
      )
    }
    case 'Appointment':
      return (
        <TableCell
          className={className}
          style={{ ...style, ...deliveryColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <div style={value.confirmed === false ? { color: '#ff5e5e' } : undefined}>{value.date || ''}</div>
        </TableCell>
      )
    case 'Appt Date Status':
      return (
        <TableCell className={className} style={{ ...style, ...deliveryColumnStyles }}>
          <div style={{ textAlign: 'center' }}>
            <AppDateConfirmedIndicator {...value} />
          </div>
        </TableCell>
      )
    case 'Return: Driver':
      return (
        <TableCell
          className={className}
          style={{ ...style, ...returnColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <DriverColumn driverId={value} />
        </TableCell>
      )
    case DispatchDeliveryOrderColumn.ReturnPlanned:
      return (
        <TableCell
          className={className}
          style={{ ...style, ...returnColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <div>{value || ''}</div>
        </TableCell>
      )
    case 'Days Out':
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <div style={Number(value) && Number(value) > 2 ? { color: '#ff5e5e' } : undefined}>{value || ''}</div>
        </TableCell>
      )
    case 'Chassis':
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <div>{value || ''}</div>
        </TableCell>
      )
    case 'DDO #':
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <DispatchDeliveryOrderNumberColumn dispatchDeliveryOrder={value} />
        </TableCell>
      )
    case 'Container':
      return <ContainerColumn className={className} dispatchDeliveryOrder={value} style={style} />
    default:
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <div>{value || ''}</div>
        </TableCell>
      )
  }
}

export default Column

const pickupColumnStyles = {
  background: 'rgba(124, 127, 207, 0.08)'
}
const deliveryColumnStyles = {
  background: 'rgba(193, 121, 209, 0.15)'
}
const returnColumnStyles = {
  background: 'rgba(124, 207, 129, 0.1)'
}
