import { ILocation } from '../interfaces'
import { oc } from 'ts-optchain'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { TColumns } from '../../grid/views/interfaces'
import { getPrimaryContactInfoLines } from '../../../../services/DTO/contact/functions'
import { locationDirectory } from '../../../../services/DTO/location/directory'
import { getStateInfo } from '../../../../services/addressService'

export const headers = {
  Name: { width: 160, active: true },
  Type: { width: 160, active: true },
  Code: { width: 100, active: true },
  Company: { width: 160, active: true },
  Address: { width: 260, active: true },
  City: { width: 120, active: true },
  State: { width: 80, active: true },
  ZIP: { width: 100, active: true },
  'PC First Name': { width: 130, active: true },
  'PC Last Name': { width: 130, active: true },
  'PC phone': { width: 120, active: true },
  'PC email': { width: 150, active: true }
}

export const adaptRowValues = (unit: ILocation): TColumns => {
  const primaryContactInfo = getPrimaryContactInfoLines(unit)

  const columnValues = {
    Name: oc(unit).name(''),
    Type: unit.type ? locationDirectory.type[unit.type] : '',
    Code: oc(unit).code(''),
    Company: oc(unit).careOf(''),
    Address:
      oc(unit).address.street('') + (oc(unit).address.street2(null) ? ', \n' : '') + oc(unit).address.street2(''),
    City: oc(unit).address.city(''),
    State: getStateInfo(oc(unit).address.stateId(null)).code,
    ZIP: oc(unit).address.postalCode(''),
    'PC First Name': primaryContactInfo.firstName,
    'PC Last Name': primaryContactInfo.lastName,
    'PC phone': primaryContactInfo.phones,
    'PC email': primaryContactInfo.email
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
