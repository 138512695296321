import * as React from 'react'
import { ColumnVisibilitySettings } from './visibilitySettings'
import { Header } from '../header'
import { TabType, TGridColumns } from '../../../tabs/interfaces'
import { updateTabColumns, updateTabFilters, updateTabSorting } from '../../../tabs/actions'
import { connect } from 'react-redux'
import { IStore } from '../../../../../store/store.interface'
import { getTabs } from '../../../tabs/selectors'
import { oc } from 'ts-optchain'
import { IFilterItem } from '../header/column'
import { filteringStateByTabType } from '../../../../../services/tabs/functions'

type OwnProps = {
  tabId: string
  disallowFilter?: boolean
  scrollable?: boolean
  scrollToZero: boolean
}

type StateProps = {
  tabType: TabType
  columns: TGridColumns
  filters: IFilterItem[]
  sorting: any
  enableFiltering: boolean
  enableSorting: boolean
}

type DispatchProps = {
  updateTabColumns?: typeof updateTabColumns
  updateTabFilters?: typeof updateTabFilters
  updateTabSorting?: typeof updateTabSorting
}

type Props = OwnProps & StateProps & DispatchProps

export const TableHeader = connect(
  (store: IStore, ownProps: OwnProps) => {
    const currentTab = getTabs(store).find(_ => _.id === ownProps.tabId)
    return {
      tabType: currentTab.type,
      columns: oc(currentTab).uiSettings.columns(),
      filters: oc(currentTab).uiSettings.filter(),
      sorting: oc(currentTab).uiSettings.sorting(),
      uiSettings: currentTab.uiSettings,
      enableFiltering: currentTab.enableFiltering,
      enableSorting: currentTab.enableSorting
    }
  },
  { updateTabColumns, updateTabFilters, updateTabSorting }
)((props: Props) => {
  const {
    disallowFilter,
    scrollable,
    scrollToZero,
    tabType,
    enableFiltering,
    enableSorting,
    tabId,
    columns,
    sorting,
    filters
  } = props

  const updateColumns = React.useCallback((updatedColumns: TGridColumns) => {
    return props.updateTabColumns({ id: tabId, columns: updatedColumns })
  }, [])

  const updateSorting = React.useCallback((updatedSorting: any) => {
    return props.updateTabSorting({ id: tabId, sorting: updatedSorting })
  }, [])

  const updateFilters = React.useCallback(
    (updatedFilters: IFilterItem[]) => {
      if (!filteringStateByTabType(tabType)) {
        return
      }

      if (!updatedFilters && !filters) {
        return
      }

      return props.updateTabFilters({ id: tabId, filter: updatedFilters ? updatedFilters : undefined })
    },
    [filters]
  )

  return (
    <>
      <ColumnVisibilitySettings columns={columns} updateColumns={updateColumns} />
      <Header
        disallowFilter={disallowFilter}
        scrollable={scrollable}
        scrollToZero={scrollToZero}
        sorting={sorting}
        filters={filters}
        columns={columns}
        updateColumns={updateColumns}
        updateSorting={updateSorting}
        updateFilters={updateFilters}
        tabType={tabType}
        withCheckbox={true}
        enableFiltering={enableFiltering}
        enableSorting={enableSorting}
      />
    </>
  )
})
