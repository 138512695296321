import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { SortableContainer, SortableContainerProps, SortableElement, SortEnd, arrayMove } from 'react-sortable-hoc'
import { FieldContainer } from '../FieldContainer'
import { Input } from '../Input/inputTypes/simple'
import theme from '../../../../styles/theme'
import { DragIcon } from '../../icons/DragIcon'
import { generateNotificationOption } from '../../../../services/functions/generate/generateNotificationOption'
import { NotificationOptionDTO } from '../../../../api/origin/communication-hub-service'

type Option = string | NotificationOptionDTO

export enum InputOptionType {
  Text = 'Text',
  Notification = 'Notification'
}

type Props = {
  options: Option[] | undefined
  required: boolean
  onChange: (options: Option[]) => void
  type: InputOptionType
  disableAddButton?: boolean
  title?: string
  style?: React.CSSProperties
}

export const InputOptions = (props: Props) => {
  const { title, required, style, type, disableAddButton, onChange } = props
  const containerRef = React.useRef(null)
  const defaultOptions = [type === InputOptionType.Notification ? generateNotificationOption() : '']
  const options = props.options && props.options.length ? props.options : defaultOptions
  const prevOptionsNumber = React.useRef(options.length)

  React.useEffect(() => {
    prevOptionsNumber.current = options.length
  }, [options.length])

  const onOptionChange = (optionIndex: number) => (text: string) => {
    const updatedOptions = options.map((prevOption, index) => {
      if (index === optionIndex) {
        if (type === InputOptionType.Notification) {
          return {
            ...(prevOption as NotificationOptionDTO),
            text
          }
        }

        return text
      }

      return prevOption
    })

    return onChange(updatedOptions)
  }

  const onAddButtonClick = () => {
    onChange(options.concat(generateNotificationOption()))
  }

  const onDeleteClick = (deleteIndex: number) => () => {
    const updatedOptions = options.filter((_, index) => deleteIndex !== index)

    return onChange(updatedOptions)
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const updatedOptions = arrayMove(options, oldIndex, newIndex)

    return onChange(updatedOptions)
  }

  const SortableItem = SortableElement(({ children }: any) => children)

  const SortableList: React.ComponentClass<{ items: any[] } & SortableContainerProps> = SortableContainer(
    ({ items }: { items: any[] } & SortableContainerProps) => (
      <div>
        {items.map((option, index) => {
          const isFirstItem = index === 0
          const isLastItem = index === items.length - 1
          const text = type === InputOptionType.Notification ? (option as NotificationOptionDTO).text : option
          const isEmptyText = !text
          const newInputFocus = isLastItem && prevOptionsNumber.current < items.length

          return (
            <SortableItem key={index} index={index}>
              <Option>
                <DragIcon style={{ marginRight: 8 }} />
                <Input
                  value={text}
                  onChange={onOptionChange(index)}
                  placeholder={'Enter option'}
                  maxLength={200}
                  setFocusOnInit={newInputFocus && isLastItem}
                  styleFieldContainer={{ flexShrink: 1 }}
                />
                {isFirstItem && isEmptyText ? (
                  <DeleteOptionButton style={{ width: 26, cursor: 'default' }} />
                ) : (
                  <DeleteOptionButton className={'mdi mdi-delete'} onClick={onDeleteClick(index)} />
                )}
              </Option>
            </SortableItem>
          )
        })}
      </div>
    )
  )

  return (
    <FieldContainer title={title} required={required} className={'input-options__container'} style={style}>
      <Conteiner ref={containerRef}>
        <SortableList
          items={options}
          onSortEnd={onSortEnd}
          axis={'y'}
          lockAxis={'y'}
          lockToContainerEdges={true}
          distance={10}
          helperContainer={containerRef.current}
          helperClass={'draggable-option'}
        />
      </Conteiner>
      <AddButton
        className={cn('mdi mdi-plus-circle-outline', { disabled: disableAddButton })}
        onClick={disableAddButton ? undefined : onAddButtonClick}
      >
        Add Option
      </AddButton>
    </FieldContainer>
  )
}

const Conteiner = styled.div``

const Option = styled.div`
  flex-shrink: 1;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 4px;

  &.draggable-option {
  }
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.basicBlueColor};
  margin-top: 15px;
  cursor: pointer;

  &:before {
    font-size: 24px;
    margin-right: 4px;
  }

  &.disabled {
    cursor: default;
    color: ${theme.colors.gray};
  }
`

const DeleteOptionButton = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  font-size: 18px;
  color: #e0e0e0;
  transition: color 0.25s;
  padding-left: 8px;
  cursor: pointer;

  &:hover {
    color: #445366;
  }
`
