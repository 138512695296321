import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import theme from '../../../../styles/theme'
import { useCreateNotification } from '../../../../hooks/useCreateNotification'
import { useModalWindow } from '../../../../hooks/useModalWindow'
import { Select } from '../../../UI/DataFields/Select'
import { Input } from '../../../UI/DataFields/Input'
import { InputOptionType, InputOptions } from '../../../UI/DataFields/InputOptions/InputOptions'
import { ActionButton } from '../../../UI/Buttons/ActionButton'
import { RichTextArea, richTextLineHeight, richTextOffset } from '../../../UI/DataFields/RichTextArea/RichTextArea'
import { useApplicationSizeLocaly } from '../../../../hooks/useApplicationSize'
import { notificationDirectory } from '../../../../services/DTO/notification/directory'
import { ApplicationFormDTO } from '../../../../api/origin/document-service'
import { driverDirectory } from '../../../../services/DTO/driver/directory'
import { GridItemSpinner } from '../../../UI/Spinner/Spinner'
import { NotificationDTO, NotificationOptionDTO } from '../../../../api/origin/communication-hub-service'
import { showSuccessModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { SelectDriverStatatus } from './Notification/SelectDriverStatatus'
import { getListState, getNotificationsState } from '../../../../store'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { NotificationFilter } from '../../../../store/reducers/communicationHub/interfaces'
import { testNitification } from '../../../../services/functions/test/testNitification'
import { ListEntityType } from '../../../../store/reducers/lists/interfaces'

export const CreateNotificationPopup = () => {
  const { closeModalWindow } = useModalWindow()
  const dispatch = useAppDispatch()
  const {
    fetch,
    errorMessage,
    availableCreateNotification,
    newNotification: { options, type, subject, driverStatuses },
    documents,
    selectedDocument,
    selectedDocumentDriverType,
    setSelectedDocument,
    setSelectedDocumentDriverType,
    richText,
    setNotificationType,
    setNotificationDriverStatus,
    setNotificationSubject,
    setRichText,
    setNotificationOptions,
    createNotification,
    openDocumentPriveiw,
    testNotificationProps: { showFiledMapping }
  } = useCreateNotification({
    createNotificationCallBack: (createdNotification: NotificationDTO) => {
      const storeNotificaitonFilters = getNotificationsState().filters
      const { isPass, testProps } = testNitification({
        notification: createdNotification,
        filters: storeNotificaitonFilters
      })
      const successNotificationTitle = 'Notification has been sent'
      let successNotificationText = undefined
      let hasMaxHeight = false

      if (
        createdNotification.type === NotificationDTO.TypeEnum.RESIGNDOCUMENT &&
        createdNotification.documentType === 'W9' &&
        oc(createdNotification).metadata.vendorIds([]).length
      ) {
        hasMaxHeight = true
        const vendorIds = createdNotification.metadata.vendorIds as string[]
        const driverMapping = getListState()[ListEntityType.driver]
        const driverNames = vendorIds.map(id => oc(driverMapping[id]).name()).filter(Boolean)
        successNotificationText = (
          <>
            <div style={{ fontWeight: 500, marginBottom: 10 }}>
              Please note the below vendors were not sent a request to re-sign the W9, this task must be done manually:
            </div>
            <div>{driverNames.join(', ')}</div>
          </>
        )
      }

      if (isPass) {
        showSuccessModalWindow({
          width: hasMaxHeight ? 600 : 400,
          title: successNotificationTitle,
          content: successNotificationText,
          hasMaxHeight
        })
      } else {
        showSuccessModalWindow({
          width: hasMaxHeight ? 600 : 500,
          title: successNotificationTitle,
          hasMaxHeight,
          content: (
            <>
              {successNotificationText}
              {Boolean(successNotificationText) && <br />}
              Type of the created notification does not match the configured filter or the active tab of the
              notification list. If you want to see the notification in the notification list, the current filter or the
              active tab will be reset
            </>
          ),
          buttons: [
            { label: 'Close', outline: true },
            {
              label: 'See in the List',
              onClick: () => {
                const resetFilters = {
                  ...storeNotificaitonFilters,
                  [NotificationFilter.status]: createdNotification.status
                }

                if (!testProps.type) {
                  resetFilters[NotificationFilter.type] = undefined
                }
                if (!testProps.sender) {
                  resetFilters[NotificationFilter.senderUserIds] = undefined
                }
                if (!testProps.dates) {
                  resetFilters[NotificationFilter.startDate] = undefined
                  resetFilters[NotificationFilter.endDate] = undefined
                }
                if (!testProps.driverStatuses) {
                  resetFilters[NotificationFilter.driverStatuses] = undefined
                }
                if (!testProps.recipientUsers) {
                  resetFilters[NotificationFilter.recipientUserIds] = undefined
                }
                if (!testProps.text) {
                  resetFilters[NotificationFilter.text] = undefined
                }

                dispatch(communicationHubActions.setNotificationFilters(resetFilters))
              }
            }
          ]
        })
      }
    }
  })
  const { resize } = useApplicationSizeLocaly({ rerenderOnResize: true })
  const [visibleExpandedRichInputLinesNumber, setVisibleExpandedRichInputLinesNumber] = React.useState<number>(4)

  React.useEffect(() => {
    if (showFiledMapping.richText) {
      const textAreaElement = document.querySelector('.RichTextArea__editor-container') as HTMLElement
      const modalWindowElement = document.querySelector('.ModalWindow') as HTMLElement

      if (textAreaElement && modalWindowElement) {
        const textAreaElementHeight = textAreaElement.offsetHeight || 0
        const modalWindowElementHeight = modalWindowElement.offsetHeight || 0
        const emptyAreaHeight = (window.innerHeight - (modalWindowElementHeight - textAreaElementHeight)) * 0.7
        const linesNumber = Math.floor((emptyAreaHeight - richTextOffset) / richTextLineHeight)

        setVisibleExpandedRichInputLinesNumber(linesNumber)
      }
    }
  }, [resize, showFiledMapping.richText, type, (options || []).length])

  return (
    <Container>
      {fetch && <GridItemSpinner />}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Select
        title={'Notification Type'}
        required={true}
        containerStyle={{ marginBottom: 24 }}
        list={[
          NotificationDTO.TypeEnum.SIMPLE,
          NotificationDTO.TypeEnum.ACKNOWLEDGMENT,
          NotificationDTO.TypeEnum.POLL,
          NotificationDTO.TypeEnum.RESIGNDOCUMENT
        ].map(value => ({ label: notificationDirectory.type[value], value }))}
        selectedValue={type}
        onSelect={setNotificationType}
      />
      {showFiledMapping.notifyDriverStatus && (
        <SelectDriverStatatus
          title={'Recipients'}
          required={true}
          containerStyle={{ marginBottom: 24 }}
          selected={driverStatuses}
          onChange={setNotificationDriverStatus}
        />
      )}
      {showFiledMapping.subject && (
        <Input
          title={'Subject'}
          requiredMark={true}
          styleFieldContainer={{ marginBottom: 24 }}
          maxLength={200}
          value={subject}
          onChange={setNotificationSubject}
        />
      )}
      {showFiledMapping.richText && (
        <RichTextArea
          title={'Text'}
          required={true}
          styleFieldContainer={{ marginBottom: 24 }}
          editorState={richText}
          onEditorChange={setRichText}
          visibleLinesNumber={4}
          visibleExpandedLinesNumber={visibleExpandedRichInputLinesNumber}
        />
      )}
      {showFiledMapping.document && (
        <DocumentsBlock>
          <Select
            title={'Document to Resign'}
            containerStyle={{ padding: '8px 0' }}
            list={documents.map(document => ({
              label: document.name,
              value: document.key
            }))}
            selectedValue={oc(selectedDocument).key()}
            onSelect={key => setSelectedDocument(documents.find(_ => _.key === key))}
          />
          <DocumentsPreviewBlock>
            <Select
              title={'Preview Document'}
              style={{ background: 'white' }}
              disabled={oc(selectedDocument).driverTypes([]).length < 2}
              list={oc(selectedDocument)
                .driverTypes([])
                .map(t => ({
                  label: driverDirectory.type[t],
                  value: t
                }))}
              selectedValue={selectedDocumentDriverType}
              onSelect={setSelectedDocumentDriverType}
            />
            {selectedDocument && (
              <>
                <DocumentPreviewLink
                  className={'mdi mdi-eye'}
                  onClick={() => openDocumentPriveiw(ApplicationFormDTO.LangEnum.EN)}
                >
                  <span>English</span>
                </DocumentPreviewLink>
                <DocumentPreviewLink
                  className={'mdi mdi-eye'}
                  onClick={() => openDocumentPriveiw(ApplicationFormDTO.LangEnum.ES)}
                >
                  <span>Spanish</span>
                </DocumentPreviewLink>
              </>
            )}
          </DocumentsPreviewBlock>
        </DocumentsBlock>
      )}
      {showFiledMapping.options && (
        <InputOptions
          title={'Poll Options'}
          required={true}
          disableAddButton={(options || []).length > 9}
          type={InputOptionType.Notification}
          options={options}
          onChange={_options => setNotificationOptions(_options as NotificationOptionDTO[])}
        />
      )}
      <Actions>
        <ActionButton round={true} onClick={closeModalWindow}>
          Cancel
        </ActionButton>
        <ActionButton round={true} filled={true} disabled={!availableCreateNotification} onClick={createNotification}>
          Send
        </ActionButton>
      </Actions>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${theme.colors.defaultRed};
  margin-bottom: 20px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`

const DocumentsBlock = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 12px;
`
const DocumentsPreviewBlock = styled.div`
  display: grid;
  grid-template-columns: 147px 1fr 1fr;
  column-gap: 16px;
  background-color: #f5f6fa;
  border-radius: 8px;
  padding: 8px 12px;
`

const DocumentPreviewLink = styled.div`
  align-self: flex-end;
  height: 35px;
  display: flex;
  align-items: center;
  color: ${theme.colors.basicBlueColor};
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
  user-select: none;

  span {
    text-decoration: underline;
  }

  &:before {
    font-size: 16px;
    text-decoration: none;
    margin-right: 4px;
  }

  &:hover {
    opacity: 0.8;
  }
`
