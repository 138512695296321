import * as React from 'react'
import * as R from 'remeda'
import { Column, GridColumn, ColumnRow } from '../../../UI/GridSystem'
import {
  DeliveryOrderViewDTO,
  CargoDTO,
  CustomerViewDTO,
  SteamShipLineDTO,
  DeliveryOrderDTO
} from '../../../../api/api'
import styled from 'styled-components'
import { Input } from '../../../UI/DataFields/Input'
import { CustomerSearch, SteamShipLineSearch } from '../../../UI/DataFields/SearchRequest'
import { Select } from '../../../UI/DataFields/Select'
import { DOIndicator } from '../../dispatchDeliveryOrder/views/Details/General/styles'
import { CheckFieldByRequest } from '../../../UI/CheckFieldByRequest'
import {
  isDeliveryOrderSteamShipLineRequiredByDDOs,
  isValidDescription,
  isValidPurchaseOrderNumber,
  isValidReferenceNumber,
  isValidVesselName,
  isValidVoyageNumber
} from '../../../../services/DTO/deliveryOrder'
import { OrderNumber } from './OrderNumber'
import DateField from '../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../UI/DatePicker/Interfaces'
import { oc } from 'ts-optchain'
import { ICustomerView } from '../../customer/interfaces'
import { deliveryOrderLists } from '../../../../services/select/deliveryOrderLists'
import { deliveryOrderDirectory } from '../../../../services/DTO/deliveryOrder/directory'
import { DeliveryOrderContext } from './context'
import { IDispatchDeliveryOrder } from '../../dispatchDeliveryOrder/interfaces'
import { alertOnChangingMainStreetTurnPropsOfDO } from '../../../../services/DTO/dispatchDeliveryOrder/functions'
import { regExpOnlyNumbersAndLetters } from '../../../../services/functions/regExp'
import { DateTimePicker } from '../../../UI/DataFields/DateTimePicker/views'

type OwnProps = {}

type DispatchProps = {}

const TypeSelecterContainer = styled(Column)`
  .selected {
    font-size: 18px;
  }
`

type Props = OwnProps & DispatchProps

export const LeftSide: React.FC<Props> = (props: Props) => {
  const {
    state: { deliveryOrder, isExport, isImport, isNew, isRepo },
    functions: { modifyDeliveryOrder, modifyDeliveryOrderField }
  } = React.useContext(DeliveryOrderContext)

  const updateSteamShipLine = (steamShipLine: SteamShipLineDTO) => {
    const updatedDO = {
      ...deliveryOrder,
      steamShipLine: steamShipLine,
      steamShipLineId: oc(steamShipLine).id()
    }

    alertOnChangingMainStreetTurnPropsOfDO(updatedDO, deliveryOrder)
    return modifyDeliveryOrder(updatedDO)
  }

  const updateCargo = (fieldName: keyof CargoDTO) => (value: string) =>
    modifyDeliveryOrderField('cargo')({ ...deliveryOrder.cargo, [fieldName]: value })

  const changeCustomer = (customer: CustomerViewDTO) =>
    modifyDeliveryOrder({
      ...deliveryOrder,
      customer,
      customerId: oc(customer).id(),
      subClientId: null,
      subClient: null
    })

  return (
    <GridColumn style={{ width: '100%' }} isGrid={true} flexClear={true}>
      <ColumnRow margin={{ bottom: 15 }}>
        <Column margin={{ right: 15 }}>
          <OrderNumber orderNumber={deliveryOrder.number} />
        </Column>
        {isNew ? (
          <TypeSelecterContainer margin={{ right: 15 }} id={'do-type'}>
            <Select
              title={'Type'}
              required={true}
              selectedValue={deliveryOrder.type}
              list={deliveryOrderLists.type}
              onSelect={type => {
                let updatedDO = R.clone(deliveryOrder)

                updatedDO.type = type
                updatedDO.loadType = updatedDO.loadType || DeliveryOrderDTO.LoadTypeEnum.LIVELOAD

                switch (type) {
                  case DeliveryOrderViewDTO.TypeEnum.IMPORT:
                    updatedDO.vesselDepartureDate = undefined
                    updatedDO.lastFreeDatePerDiem = undefined
                    updatedDO.bookingNumber = undefined
                    updatedDO.dispatchDeliveryOrders = oc(updatedDO)
                      .dispatchDeliveryOrders([])
                      .map((ddo: IDispatchDeliveryOrder) => {
                        const correctDDO = R.clone(ddo)
                        const isEmptyDeliveryStage = Object.keys(correctDDO.deliveryOrder || {}).length === 0
                        correctDDO.deliveryStage = isEmptyDeliveryStage
                          ? {
                              locationId: updatedDO.deliveryLocationId,
                              location: updatedDO.deliveryLocation,
                              plannedAppointmentDateTimeRange: updatedDO.appointmentDateTimeRange
                            }
                          : correctDDO.deliveryStage
                        return correctDDO
                      })
                    break
                  case DeliveryOrderViewDTO.TypeEnum.EXPORT:
                    updatedDO.billOfLadingNumber = undefined
                    updatedDO.vesselArrivalDate = undefined
                    updatedDO.lastFreeDateDemurrage = undefined
                    updatedDO.dispatchDeliveryOrders = oc(updatedDO)
                      .dispatchDeliveryOrders([])
                      .map((ddo: IDispatchDeliveryOrder) => {
                        const correctDDO = R.clone(ddo)
                        const isEmptyDeliveryStage = Object.keys(correctDDO.deliveryOrder || {}).length === 0
                        correctDDO.deliveryStage = isEmptyDeliveryStage
                          ? {
                              locationId: updatedDO.deliveryLocationId,
                              location: updatedDO.deliveryLocation,
                              plannedAppointmentDateTimeRange: updatedDO.appointmentDateTimeRange
                            }
                          : correctDDO.deliveryStage
                        return correctDDO
                      })
                    break
                  case DeliveryOrderViewDTO.TypeEnum.REPOSITION:
                    updatedDO = R.omit(updatedDO, [
                      'deliveryLocation',
                      'deliveryLocationId',
                      'loadType',
                      'generalCutoffDate',
                      'lastFreeDatePerDiem',
                      'billOfLadingNumber',
                      'appointmentDateTimeRange'
                    ])
                    updatedDO.equipmentFirstPickupDate = deliveryOrder.date
                    updatedDO.cargo.description = 'REPO'
                    updatedDO.cargo.referenceNumber = updatedDO.cargo.referenceNumber || updatedDO.bookingNumber
                    updatedDO.dispatchDeliveryOrders = oc(updatedDO)
                      .dispatchDeliveryOrders([])
                      .map((ddo: IDispatchDeliveryOrder) => {
                        const correctDDO = R.clone(ddo)
                        correctDDO.deliveryStage = {}
                        return R.omit(correctDDO, ['loadType'])
                      })
                    break
                  default:
                }

                return modifyDeliveryOrder(updatedDO)
              }}
            />
          </TypeSelecterContainer>
        ) : (
          <Column justifyCenter={true} padding={{ top: 22 }} margin={{ right: 15 }}>
            <DOIndicator id={'do-type'}>{deliveryOrderDirectory.type[deliveryOrder.type] || ''}</DOIndicator>
          </Column>
        )}
        <Column id={'do-date'}>
          <DateTimePicker
            title={'Delivery Order Date'}
            required={true}
            disabled={deliveryOrder.dateLocked}
            date={deliveryOrder.date}
            onChange={modifyDeliveryOrderField('date')}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 20 }}>
        <Column id={'do-customer'}>
          <CustomerSearch
            title={'Customer'}
            required={true}
            value={oc(deliveryOrder).customer(null) as ICustomerView}
            onChange={changeCustomer}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 40 }}>
        {isImport && (
          <Column maxColumns={10} margin={{ right: 15 }} id={'do-billOfLadingNumber'}>
            <Input
              required={true}
              title={'Bill of Lading #'}
              regExp={regExpOnlyNumbersAndLetters}
              highlighted={oc(deliveryOrder).billOfLadingNumber('').length < 4}
              value={deliveryOrder.billOfLadingNumber}
              onChange={modifyDeliveryOrderField('billOfLadingNumber')}
            />
          </Column>
        )}
        <Column maxColumns={10} id={'do-bookingNumber'}>
          <Input
            required={isExport || isRepo}
            title={'Booking #'}
            regExp={regExpOnlyNumbersAndLetters}
            value={deliveryOrder.bookingNumber}
            onChange={value => {
              const updatedDO = R.clone(deliveryOrder)

              updatedDO.bookingNumber = value

              if (isRepo) {
                updatedDO.cargo.referenceNumber = updatedDO.cargo.referenceNumber || updatedDO.bookingNumber
              }

              modifyDeliveryOrder(updatedDO)
            }}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 20 }}>
        <Column id={'do-cargo-description'}>
          <Input
            required={true}
            title={'Cargo Description'}
            valid={isValidDescription(deliveryOrder.cargo.description)}
            value={deliveryOrder.cargo.description}
            onChange={updateCargo('description')}
          />
        </Column>
      </ColumnRow>

      <ColumnRow margin={{ bottom: 40 }}>
        <Column margin={{ right: 15 }} id={'do-cargo-referenceNumber'}>
          <Input
            title={'Reference #'}
            required={true}
            regExp={regExpOnlyNumbersAndLetters}
            valid={isValidReferenceNumber(deliveryOrder.cargo.referenceNumber)}
            value={deliveryOrder.cargo.referenceNumber}
            onChange={updateCargo('referenceNumber')}
          />
          <CheckFieldByRequest
            currentDeliveryOrderId={deliveryOrder.id}
            value={deliveryOrder.cargo.referenceNumber}
            minLength={4}
          />
        </Column>
        <Column id={'do-cargo-purchaseOrderNumber'}>
          <Input
            title={'Purchase Order'}
            valid={isValidPurchaseOrderNumber(deliveryOrder.cargo.purchaseOrderNumber)}
            value={deliveryOrder.cargo.purchaseOrderNumber}
            onChange={updateCargo('purchaseOrderNumber')}
          />
        </Column>
      </ColumnRow>
      <ColumnRow margin={{ bottom: 35 }}>
        <Column margin={{ right: 15 }} id={'do-steamShipLine'}>
          <SteamShipLineSearch
            title={'SSL Name'}
            required={isDeliveryOrderSteamShipLineRequiredByDDOs(deliveryOrder)}
            value={oc(deliveryOrder).steamShipLine()}
            onChange={updateSteamShipLine}
          />
        </Column>
        <Column margin={{ right: 15 }} id={'do-vesselName'}>
          <Input
            title={'Vessel Name'}
            valid={isValidVesselName(deliveryOrder.vesselName)}
            value={deliveryOrder.vesselName}
            onChange={modifyDeliveryOrderField('vesselName')}
          />
        </Column>
        <Column id={'do-voyageNumber'}>
          <Input
            title={'Voyage  #'}
            valid={isValidVoyageNumber(deliveryOrder.voyageNumber)}
            value={deliveryOrder.voyageNumber}
            onChange={modifyDeliveryOrderField('voyageNumber')}
          />
        </Column>
      </ColumnRow>
    </GridColumn>
  )
}
