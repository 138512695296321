import { combineEpics } from 'redux-observable'
import {
  ActivitiesViewDTO,
  callAPI,
  dispatchDeliveryOrderAPI,
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderViewDTO,
  nonPlannedActivityAPI,
  TerminalNoteDTO,
  terminalNotesAPI
} from '../../../../api/api'
import { addDispatchDeliveryOrderToStore } from '../action'
import { getStore } from '../../../../store/configureStore'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'
import { localStorageService } from '../../../../services/storageService/localStorage/LocalStorage'
import { TabType } from '../../tabs/interfaces'
import { splitIntoMultipleRequestIds } from '../../../../api/requests/functions'
import { oc } from 'ts-optchain'

export const tryAddDispatchDeliveryOrderToStore = (
  items:
    | DispatchDeliveryOrderViewDTO
    | DispatchDeliveryOrderViewDTO[]
    | DispatchDeliveryOrderGridItemDTO
    | DispatchDeliveryOrderGridItemDTO[]
) => {
  correctInsertionObjectsIntoStorage(
    getStore().getState().dispatchDeliveryOrder,
    items,
    addDispatchDeliveryOrderToStore
  )
}

export const getDispatchDeliveryOrderById = (
  id: string,
  forceUpdate?: boolean
): Promise<DispatchDeliveryOrderViewDTO> => {
  return callAPI(dispatchDeliveryOrderAPI.findById, id)
    .toPromise()
    .then(dispatchDeliveryOrder => {
      // @ts-ignore
      dispatchDeliveryOrder.forceUpdate = forceUpdate
      parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrder)
      return dispatchDeliveryOrder
    })
}

export const findAllDispatchDeliveryOrders = (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderViewDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.findAll, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      if (pushToStore) {
        parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      }

      return dispatchDeliveryOrders
    })
}

export const requestDispatchDeliveryOrdersByIds = async (
  dispatchDeliveryOrderIds: string[],
  options?: { notFullObject?: boolean; notFullObjectWithActivities?: boolean }
): Promise<(DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[]> => {
  if (!(dispatchDeliveryOrderIds && dispatchDeliveryOrderIds.length)) {
    return Promise.resolve([])
  }

  try {
    const { notFullObject, notFullObjectWithActivities } = options || {}
    const arrayOfRequestedIdsArrays = splitIntoMultipleRequestIds(dispatchDeliveryOrderIds)
    const requestedDispatchDeliveryOrders: (DispatchDeliveryOrderViewDTO | DispatchDeliveryOrderGridItemDTO)[] = []

    if (arrayOfRequestedIdsArrays.length) {
      const dispatchDeliveryOrderRequests = notFullObjectWithActivities
        ? findAllGridDispatchDeliveryOrdersWithActivities
        : notFullObject
        ? findAllGridDispatchDeliveryOrders
        : findAllDispatchDeliveryOrders

      await Promise.all(
        arrayOfRequestedIdsArrays.map(ids =>
          dispatchDeliveryOrderRequests({ filter: 'id%%' + ids.join(',') }, false).then(ddos => {
            let items = ddos

            if (notFullObjectWithActivities) {
              const store = getStore().getState()

              items = items.map(ddo => {
                const storeDDO = store.dispatchDeliveryOrder[ddo.id]

                if (!(storeDDO && storeDDO.fullObject)) {
                  // @ts-ignore
                  ddo.forceUpdate = true
                  // @ts-ignore
                  ddo.temporaryData = { ...oc(storeDDO).temporaryData({}), hasMovesActivities: true }
                }

                return ddo
              })
            }

            requestedDispatchDeliveryOrders.push(...items)
          })
        )
      )

      parseDTO.dispatchDeliveryOrder(requestedDispatchDeliveryOrders)
      return requestedDispatchDeliveryOrders
    } else {
      return Promise.resolve([])
    }
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error)
  }
}

export const findAllGridDispatchDeliveryOrders = async (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      if (pushToStore) {
        parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      }

      return dispatchDeliveryOrders
    })
}

export const findAllGridDispatchDeliveryOrdersWithActivities = async (
  { sort, filter }: any,
  pushToStore: boolean = true
): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrdersWithActivities, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      if (pushToStore) {
        parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      }

      return dispatchDeliveryOrders
    })
}

export const findAllDroppedAtDeliveryLocation = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDroppedAtDeliveryLocation, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      return dispatchDeliveryOrders
    })
}
export const findAllDeliveryStageCompletedNoReturned = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDeliveryStageCompletedNoReturned, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      return dispatchDeliveryOrders
    })
}
export const findAllDroppedAtYard = async ({ sort, filter }: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getDroppedAtYard, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      return dispatchDeliveryOrders
    })
}
export const findAllPickedUpNoDelivery = async ({ sort, filter }: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(nonPlannedActivityAPI.getPickedUpNoDelivery, filter, sort)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      return dispatchDeliveryOrders
    })
}
export const findRecentDispatchDeliveryOrders = async ({
  sort,
  filter
}: any): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  const recentIds = localStorageService.getRecentForTabType(TabType.dispatchDeliveryOrder)
  const _filter = 'id%%' + recentIds.join(',') + (filter ? ';' + filter : '')
  return callAPI(dispatchDeliveryOrderAPI.getGridDispatchDeliveryOrders, _filter)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      const mapping = (dispatchDeliveryOrders || []).reduce((acc, curr) => {
        acc[curr.id] = curr

        return acc
      }, {})

      const sortedDispatchDeliveryOrders = recentIds.map(id => mapping[id]).filter(Boolean)

      parseDTO.dispatchDeliveryOrder(sortedDispatchDeliveryOrders)
      return sortedDispatchDeliveryOrders
    })
}

export const getActivitiesByDispatchDeliveryOrderId = (id: string): Promise<ActivitiesViewDTO> =>
  callAPI(dispatchDeliveryOrderAPI.findActivities, id)
    .toPromise()
    .then(activities => {
      parseDTO.activities(activities)
      return activities
    })

export const getDispatchDeliveryOrderNotes = (dispatchDeliveryOrderId: string): Promise<TerminalNoteDTO[]> =>
  callAPI(terminalNotesAPI.getTerminalNotes, dispatchDeliveryOrderId).toPromise()

export default combineEpics()
