import * as React from 'react'
import * as jwtDecode from 'jwt-decode'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import { AppContainer, IntermodalAppContainer } from './styles'
import { Header } from '../common/header/Header'
import { Tabs } from '../common/tabs/Tabs'
import Modal from '../UI/Modal'
import { AuthorizationState, IAuthorizationState, IStore } from '../../store/store.interface'
import { connect } from 'react-redux'
// import UsersGrid from './pages/administration/UsersGrid'
import { Router } from '../Router'
import { getItemFromStorage, StorageKeys } from '../../services/storageService/storageService'
import {
  authLoginWithRedirect,
  authProcessRedirect,
  logout
} from '../../services/authService/actions/authServiceActions'
import { UserType } from '../../api/origin/user-service'
import { LogoutSuccess } from '../../services/authService/view/logout-success'
import { Authorizing } from '../../services/authService/view/authorizing'
import { AuthError } from '../../services/authService/view/auth-error'
import { copyToClipboard } from '../common/grid/views/TableBody'
import { ModalWindow } from '../UI/ModalWindow/views'
import { Toasts } from '../UI/Toasts'
import { CommunicationHub } from '../CommunicationHub/views'
import { WsHubSockets } from '../WsHubSockets'
import { Hints } from '../Hints'
import { UploadingFilesProvider } from '../../providers/UploadingFilesProvider'
import { initSentry } from '../../services/sentry/sentry'
import { IUserAuth } from '../../store/reducers/user/interfaces'
import { convertAuthUserDataToUserDTO } from '../../store/reducers/user/functions'
import { useAppSelector } from '../../hooks/useAppSelector'
import {
  selectCommunictaionHubIsExpanded,
  selectCommunictaionHubIsVisible
} from '../../store/select/communicationHubSelect'
import { selectUser } from '../../store/select/userSelect'
import { userActions } from '../../store/reducers/user'
import { requestContainerTypes } from '../../services/DTO/containerType/epics'
import { requestDrivers, requestSimpleDrivers } from '../common/drivers/epics'
import { requestStates } from '../../services/stateService/epics'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { requestContainerYardLocations } from '../common/location/epics'
import { ModalWindowProvider } from '../../providers/ModalWindowProvider'
import { preventEvent } from '../../services/functions/basic'
import { requestDriversActvity } from '../../services/DTO/driverActivity/epics'

type StateProps = {
  authorization: IAuthorizationState
}

type DispatchProps = {
  authLoginWithRedirect: typeof authLoginWithRedirect
  authProcessRedirect: typeof authProcessRedirect
  logout: typeof logout
}

export const appVersion = '2.5.1'

type Props = StateProps & DispatchProps

const App = (props: Props) => {
  const { authorization } = props
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const isCommunicationHubVisible = useAppSelector(selectCommunictaionHubIsVisible)
  const isCommunicationHubExpanded = useAppSelector(selectCommunictaionHubIsExpanded)
  const [loadedCommonData, setLoadedCommonData] = React.useState(false)
  const isLogout = document.location.pathname === '/logout'
  const refreshToken = getItemFromStorage(StorageKeys.RefreshToken)
  const authIsReady = Boolean(authorization.state === AuthorizationState.Authorized && user)

  if (
    authorization.state === AuthorizationState.FailedWithAuth ||
    authorization.state === AuthorizationState.ServerError
  ) {
    return <AuthError authorizationState={props.authorization.state} />
  }

  if (isLogout) {
    return <LogoutSuccess />
  }

  React.useEffect(() => {
    copyButtonPressed = false
    copiedMessageItems = []

    const listenKeyDown = (event: any) => {
      const key = String(event.key).toUpperCase()

      if (listenCopyButtons.includes(key)) {
        copyButtonPressed = true
      }
    }
    const listenKeyUp = (event: any) => {
      const key = String(event.key).toUpperCase()

      if (listenCopyButtons.includes(key)) {
        finishCopyToClipboard()
      }
    }
    const checkVisibility = () => {
      if (document.visibilityState !== 'visible') {
        finishCopyToClipboard()
      }
    }

    document.addEventListener('keydown', listenKeyDown, true)
    document.addEventListener('keyup', listenKeyUp, true)
    document.addEventListener('click', multipleCopyInputValueOnMouseDown, true)
    document.addEventListener('visibilitychange', checkVisibility)
    initSentry()

    return () => {
      document.removeEventListener('keydown', listenKeyDown, true)
      document.removeEventListener('keyup', listenKeyUp, true)
      document.removeEventListener('click', multipleCopyInputValueOnMouseDown, true)
      document.removeEventListener('visibilitychange', checkVisibility)
    }
  }, [])

  React.useEffect(() => {
    if (oc(user).id()) {
      WsHubSockets.init(user)
    }
    return () => {
      WsHubSockets.destroy()
    }
  }, [oc(user).id()])

  React.useEffect(() => {
    if (!refreshToken) {
      const AuthCode = new URLSearchParams(document.location.search).get('code')
      const AuthState = new URLSearchParams(document.location.search).get('state')

      if (AuthCode && AuthState) {
        props.authProcessRedirect({ code: AuthCode, state: AuthState })
        history.replaceState(null, null, '/') // allow only root path
      } else {
        props.authLoginWithRedirect()
      }
    } else if (!user) {
      const storageAccessToken = getItemFromStorage(StorageKeys.AccessToken)

      if (!storageAccessToken) {
        props.authLoginWithRedirect()
      } else {
        const storageAccessTokenUser = jwtDecode(storageAccessToken) as IUserAuth
        const convertedUser = convertAuthUserDataToUserDTO(storageAccessTokenUser)

        if (convertedUser.type === UserType.OPERATOR) {
          dispatch(userActions.update(storageAccessTokenUser))
        } else {
          props.logout()
        }
      }
    }
  }, [refreshToken])

  React.useEffect(() => {
    if (authIsReady && !loadedCommonData) {
      Promise.all([
        requestContainerTypes(),
        requestStates(),
        requestSimpleDrivers({ sort: 'name' }),
        requestDriversActvity(),
        requestContainerYardLocations()
      ]).then(() => {
        setLoadedCommonData(true)
      })
    }
  }, [authIsReady])

  if (!(authIsReady && loadedCommonData)) {
    return <Authorizing />
  }

  history.replaceState(null, null, '/') // allow only root path
  // window.onbeforeunload = () => 'Your work will be lost!'

  return (
    <UploadingFilesProvider>
      <ModalWindowProvider>
        <AppContainer
          className={cn({
            communicationHubVisible: isCommunicationHubVisible,
            communicationHubHidden: !isCommunicationHubVisible,
            communicationHubExpanded: isCommunicationHubVisible && isCommunicationHubExpanded,
            communicationHubNotExpanded: !(isCommunicationHubVisible && isCommunicationHubExpanded)
          })}
        >
          <IntermodalAppContainer>
            <Header />
            <Tabs />
            <Router />
          </IntermodalAppContainer>

          <CommunicationHub />
        </AppContainer>

        <ModalWindow />
        <Modal />
        <Toasts />
        <Hints />
      </ModalWindowProvider>
    </UploadingFilesProvider>
  )
}

export default connect(
  ({ authorization }: IStore): StateProps => ({
    authorization
  }),
  {
    authLoginWithRedirect,
    authProcessRedirect,
    logout
  }
)(React.memo(App))

const listenCopyButtons = ['CTRL', 'META', 'CONTROL']
let copiedMessageItems: string[] = []
let copyButtonPressed: boolean = false

const addTextToCopy = (text: string) => {
  if (!text) {
    return
  }

  if (!copiedMessageItems) {
    copiedMessageItems = []
  }

  copiedMessageItems.push(text)
  copyToClipboard(copiedMessageItems.join(' \n'))
}

const finishCopyToClipboard = () => {
  copyButtonPressed = false

  if (copiedMessageItems.length) {
    copiedMessageItems = []
  }
}

const multipleCopyInputValueOnMouseDown = (event: any): void => {
  // use data-copy-value-on-mousedown={true} to copy inner text or input value (with event.ctrlKey || event.metaKey)
  // you can use data-copy-button={true} to copy on click (IGNORE event.ctrlKey || event.metaKey)
  // you can use data-copy-value={'text to copy'} to copy specified value

  const copyPressed = event.ctrlKey || event.metaKey
  const copyButtonElement: HTMLElement = event.target
    ? (event.target as HTMLElement).closest('[data-copy-button]')
    : undefined
  const element: HTMLElement =
    copyButtonElement ||
    (event.target ? (event.target as HTMLElement).closest('[data-copy-value-on-mousedown]') : undefined)

  const copyTextToClipboard = () => {
    if (!element) {
      return
    }

    const valueToCopy = element.dataset.copyValue || (element as HTMLInputElement).value || element.textContent.trim()
    if (!valueToCopy) {
      return
    }

    if (element.tagName === 'INPUT') {
      element.blur()
    }

    addTextToCopy(valueToCopy)

    // render popover
    const animationDuration = 1000
    const alertCopy = document.createElement('div')
    document.body.append(alertCopy)

    element.classList.add('blink')

    alertCopy.classList.add('alert-copy')
    alertCopy.style.cssText = `
    top: ${event.clientY}px;
    left: ${event.clientX + 1}px;
    animation: alertCopyAnimation ${animationDuration}ms forwards;
    `

    alertCopy.innerHTML = `<i class="alert-copy__icon mdi mdi-content-copy"></i>
    <span class="alert-copy__text">Copied to Clipboard</span>
    `
    setTimeout(() => {
      alertCopy.remove()
      element.classList.remove('blink')
    }, animationDuration)
  }

  if (copyButtonElement) {
    preventEvent(event)
    copyTextToClipboard()
    finishCopyToClipboard()
    return
  }

  if (copyPressed) {
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  switch (true) {
    case copyButtonPressed && !copyPressed:
      return finishCopyToClipboard()
    case copyPressed:
      copyButtonPressed = true
      break
    case !copyButtonPressed:
      return
    default:
  }

  copyTextToClipboard()
}

// const copyInputValueOnMouseDown = (event: any): void => {
//   // use data-copy-value-on-mousedown={true} to copy inner text or input value (with event.ctrlKey || event.metaKey)
//   // you can use data-copy-value={'text to copy'} to copy specified value
//
//   if (!event.ctrlKey && !event.metaKey) {
//     return
//   }
//
//   const element: HTMLElement = (event.target as HTMLElement).closest('[data-copy-value-on-mousedown]')
//   if (!element) {
//     return
//   }
//
//   const valueToCopy = element.dataset.copyValue || (element as HTMLInputElement).value || element.textContent.trim()
//   if (!valueToCopy) {
//     return
//   }
//
//   if (element.tagName === 'INPUT') {
//     element.blur()
//   }
//
//   event.preventDefault()
//   event.stopImmediatePropagation()
//   copyToClipboard(valueToCopy)
//
//   // render popover
//   const animationDuration = 1000
//   const alertCopy = document.createElement('div')
//   document.body.append(alertCopy)
//   console.log('event', event)
//
//   alertCopy.classList.add('alert-copy')
//   alertCopy.style.cssText = `
//   top: ${event.clientY}px;
//   left: ${event.clientX}px;
//   animation: alertCopyAnimation ${animationDuration}ms forwards;
//   `
//
//   alertCopy.innerHTML = `<i class="alert-copy__icon mdi mdi-content-copy"></i>
//   <span class="alert-copy__text">Copied to Clipboard</span>
//   `
//   setTimeout(() => {
//     alertCopy.remove()
//   }, animationDuration)
// }
