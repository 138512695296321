import * as React from 'react'
import { oc } from 'ts-optchain'
import Company from './company'
import { Address } from './address'
import PrimaryContact from '../../../../contact/views/PrimaryContact'
import { StyledGeneral } from './styledComponents'
import { ILocation } from '../../../interfaces'
import { connect } from 'react-redux'
import { AddressDTO } from '../../../../../../api/origin/business-logic'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

type OwnProps = {
  actions: IGridItemActions
  location: ILocation
}

type DispatchProps = {}

type Props = DispatchProps & OwnProps

const General: React.SFC<Props> = ({ location, actions }) => {
  const { isModified, modify, modifyParentObjectField, setFetching } = actions
  return (
    <StyledGeneral rows={70}>
      {location.fullObject && (
        <>
          <Company location={location} modifyParentObjectField={modifyParentObjectField} modify={modify} />
          {/*<StyledMap columns={13}>*/}
          {/*<Map*/}
          {/*position={*/}
          {/*location.latitude && location.longitude*/}
          {/*? { lat: location.latitude, lng: location.longitude }*/}
          {/*: undefined*/}
          {/*}*/}
          {/*/>*/}
          {/*</StyledMap>*/}
          <Address
            isModified={isModified}
            location={location}
            setFetching={setFetching}
            address={oc(location).address(null)}
            changeAddress={(field: keyof AddressDTO, value: any) =>
              modifyParentObjectField('address')({
                ...location.address,
                [field]: value
              })
            }
            selectLocationBySearch={(locationDetails: any) => (locationDetails ? modify(locationDetails) : null)}
          />
          <PrimaryContact
            padding={{ top: 20, left: 20, right: 60 }}
            titleStyles={{ margin: '25px 0 30px', fontWeight: 500 }}
            primaryContact={location.contacts ? location.contacts.find(item => item.primary) : null}
            contacts={location.contacts}
            modifyContacts={modifyParentObjectField('contacts')}
          />
        </>
      )}
    </StyledGeneral>
  )
}

export default connect(
  null,
  null
)(General)
