import * as React from 'react'
import { connect } from 'react-redux'
import { updateTabData } from '../components/common/tabs/actions'
import { IQMPContext, QMPContext } from '../contexts/QMPContext'
import { QMPTab, RateType } from '../components/common/QMP/interfaces'

type OwnProps = {
  tabId: string
  children?: any
}

type DispatchProps = {
  updateTabData: typeof updateTabData
}

type Props = OwnProps & DispatchProps

const Component = (props: Props) => {
  const { children, tabId } = props

  const updateQMPTabData = React.useCallback(options => props.updateTabData({ tabId, visited: true, options }), [])

  const value = React.useMemo<IQMPContext>(
    () => ({
      setActiveTab(activeTab) {
        updateQMPTabData({ activeTab })
      },
      setOpenedCustomerQuoteId(openedCustomerQuoteId) {
        updateQMPTabData({ openedCustomerQuoteId })
      },
      setFilters(filters) {
        updateQMPTabData({ filters })
      },
      setExtraData(extraData) {
        updateQMPTabData({ extraData })
      },
      setSSQIds(ssqIds) {
        updateQMPTabData({ ssqIds, ratesTabVisited: true })
      },
      setBSQIds(bsqIds) {
        updateQMPTabData({ bsqIds, ratesTabVisited: true })
      },
      setRateIds({ ssqIds, bsqIds }) {
        updateQMPTabData({ ssqIds, bsqIds, ratesTabVisited: true })
      },
      setCustomerQuoteIds(customerQuoteIds) {
        updateQMPTabData({ customerQuoteIds, customerQuotesTabVisited: true })
      },
      setNewSSQRates(newSSQRates) {
        updateQMPTabData({ newSSQRates })
      },
      setNewBSQRates(newBSQRates) {
        updateQMPTabData({ newBSQRates })
      },
      setNewRates(rateType, newRates) {
        updateQMPTabData(rateType === RateType.ssq ? { newSSQRates: newRates } : { newBSQRates: newRates })
      },
      setNewCustomerQuotes(newCustomerQuotes) {
        updateQMPTabData({ newCustomerQuotes, customerQuotesTabVisited: true })
      },
      cancelCustomerQuoteEditing() {
        updateQMPTabData({ newCustomerQuotes: null, newSSQRates: [], newBSQRates: [] })
      },
      updateQMPTabData
    }),
    []
  )

  React.useEffect(() => {
    value.setExtraData({
      [QMPTab.RATE]: {},
      [QMPTab.CUSTOMERQUOTE]: {},
      [QMPTab.RULES]: {}
    })
  }, [])

  // const value = React.useMemo((): IQMPContext => {
  //   const ratesData: IRateTab = tabData[QMPTab.RATE]
  //   const customerQuoteData: ICustomerQuoteTab = tabData[QMPTab.CUSTOMERQUOTE]
  //
  //   const updateRateData = (updatedRateData: any) => {
  //     updateData({
  //       [QMPTab.RATE]: {
  //         ...ratesData,
  //         updatedRateData
  //       }
  //     })
  //   }
  //   const updateCustomerQuoteData = (updatedCustomerQuoteData: any) => {
  //     updateData({
  //       [QMPTab.CUSTOMERQUOTE]: {
  //         ...customerQuoteData,
  //         updatedCustomerQuoteData
  //       }
  //     })
  //   }
  //
  //   return {
  //     isModifyMode: Boolean(ratesData.newRates.ssq || ratesData.newRates.bsq || customerQuoteData.newCustomerQuotes),
  //     activeTab: {
  //       state: tabData.activeTab,
  //       setState(activeTab) {
  //         updateData({ activeTab })
  //       }
  //     },
  //     filters: {
  //       state: {
  //         [QMPTab.RATE]: ratesData.filters,
  //         [QMPTab.CUSTOMERQUOTE]: customerQuoteData.filters,
  //         [QMPTab.RULES]: null
  //       },
  //       setFiltersState: (tabType, filters) => {
  //         if (tabType === QMPTab.RATE) {
  //           updateRateData({ filters })
  //         } else if (tabType === QMPTab.CUSTOMERQUOTE) {
  //           updateCustomerQuoteData({ filters })
  //         }
  //       }
  //     },
  //     rates: {
  //       visited: ratesData.visited,
  //       extraData: ratesData.extraData,
  //       ssqIdList: ratesData.listIds.ssq,
  //       bsqIdList: ratesData.listIds.bsq,
  //       setRates: (ssqList, bsqList) => {
  //         updateRateData({
  //           listIds: { ssq: ssqList.map(({ id }) => id), bsq: bsqList.map(({ id }) => id) },
  //           visited: true
  //         })
  //       },
  //       addRates: (type, list) => {
  //         updateRateData({
  //           listIds: {
  //             ...ratesData.listIds,
  //             [type]: list.map(_ => _.id).concat(ratesData.listIds[type] || [])
  //           }
  //         })
  //       },
  //       newRates: {
  //         state: ratesData.newRates,
  //         startEdit: (rateId, rateType) => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: ratesData.newRates[rateType].map(item =>
  //                 item.id === rateId ? { ...item, state: RowState.edit } : { ...item, state: RowState.new }
  //               )
  //             }
  //           })
  //         },
  //         cancelEdit: rateType => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: ratesData.newRates[rateType].map(item => ({ ...item, state: RowState.new }))
  //             }
  //           })
  //         },
  //         modify: (rate, rateType) => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: ratesData.newRates[rateType].map(item => (item.id === rate.id ? rate : item))
  //             }
  //           })
  //         },
  //         addRates: (rates, rateType) => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: (ratesData.newRates[rateType] || []).concat(rates as any[])
  //             }
  //           })
  //         },
  //         removeRate: (rateId, rateType) => {
  //           const rateList = ratesData.newRates[rateType].filter(item => item.id !== rateId)
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: rateList.length ? rateList : null
  //             }
  //           })
  //         },
  //         removeList: rateType => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: null
  //             }
  //           })
  //         },
  //         setList: (rates, rateType) => {
  //           updateRateData({
  //             newRates: {
  //               ...ratesData.newRates,
  //               [rateType]: rates && rates.length ? rates : null
  //             }
  //           })
  //         },
  //         clear: () => {
  //           updateRateData({
  //             newRates: {
  //               ssq: null,
  //               bsq: null
  //             }
  //           })
  //         }
  //       }
  //     },
  //     customerQuotes: {
  //       visited: customerQuoteData.visited,
  //       openedCustomerQuoteId: customerQuoteData.openedItemId,
  //       setOpenedCustomerQuote: (openedItemId, activeTab) => {
  //         updateCustomerQuoteData({ openedItemId: activeTab ? openedItemId : undefined })
  //       },
  //       customerQuoteIds: customerQuoteData.listIds,
  //       addCustomerQuoteIds: CQuoteIds => {
  //         if (!CQuoteIds) {
  //           return
  //         }
  //
  //         updateCustomerQuoteData({ listIds: CQuoteIds.concat(customerQuoteData.listIds || []) })
  //       },
  //       setCustomerQuotes: CQuotes => {
  //         if (!CQuotes) {
  //           return
  //         }
  //         updateCustomerQuoteData({ listIds: CQuotes.map(({ id }) => id), visited: true })
  //       },
  //       newCustomerQuotes: {
  //         state: customerQuoteData.newCustomerQuotes,
  //         set: customerQuote => {
  //           updateCustomerQuoteData({ openedItemId: null, newCustomerQuotes: customerQuote })
  //         },
  //         copy: customerQuote => {
  //           updateCustomerQuoteData({
  //             openedItemId: null,
  //             newCustomerQuotes: createCustomerQuote({
  //               ...customerQuote,
  //               customerId: [
  //                 {
  //                   label: oc(getStore().getState()).customer[customerQuote.customerId].name(),
  //                   value: customerQuote.customerId
  //                 }
  //               ],
  //               containerTypeId: [customerQuote.containerTypeId],
  //               loadType: [customerQuote.loadType],
  //               deliveryOrderType: [customerQuote.deliveryOrderType],
  //               effectiveDate: null
  //             } as any)
  //           })
  //         },
  //         modify: customerQuote => {
  //           updateCustomerQuoteData({
  //             newCustomerQuotes: calculateCustomerQuoteProps(customerQuote)
  //           })
  //         },
  //         clear: () => updateCustomerQuoteData({ openedItemId: null, newCustomerQuotes: null }),
  //         selectRate: (rate, rateType) => {
  //           const selectedRates: AnyRateDTO[] =
  //             customerQuoteData.newCustomerQuotes[customerQuoteSelectedRatesFieldName[rateType]] || []
  //           const isSelected = Boolean(selectedRates.find(({ id }) => id === rate.id))
  //           const updatedSelectedRates = isSelected
  //             ? selectedRates.filter(({ id }) => id !== rate.id)
  //             : (() => {
  //                 const extraRate = isNewObject(rate) ? { ...rate, state: RowState.new } : rate
  //                 switch (extraRate.type) {
  //                   case SellSideQuoteRateDTO.TypeEnum.BASE:
  //                   case SellSideQuoteRateDTO.TypeEnum.ALLIN: {
  //                     return [
  //                       extraRate,
  //                       ...selectedRates.filter(
  //                         r =>
  //                           r.type !== SellSideQuoteRateDTO.TypeEnum.BASE &&
  //                           r.type !== SellSideQuoteRateDTO.TypeEnum.ALLIN &&
  //                           (extraRate.type === SellSideQuoteRateDTO.TypeEnum.BASE ||
  //                             r.type !== SellSideQuoteRateDTO.TypeEnum.FUEL)
  //                       )
  //                     ]
  //                   }
  //                   case SellSideQuoteRateDTO.TypeEnum.FUEL: {
  //                     const filteredSelectedList = selectedRates.filter(
  //                       r =>
  //                         r.type !== SellSideQuoteRateDTO.TypeEnum.FUEL &&
  //                         r.type !== SellSideQuoteRateDTO.TypeEnum.ALLIN
  //                     )
  //
  //                     if (
  //                       filteredSelectedList[0] &&
  //                       filteredSelectedList[0].type === SellSideQuoteRateDTO.TypeEnum.BASE
  //                     ) {
  //                       const array = [...filteredSelectedList]
  //                       array.splice(1, 0, extraRate)
  //                       return array
  //                     } else {
  //                       return [extraRate, ...filteredSelectedList]
  //                     }
  //                   }
  //
  //                   default:
  //                     return selectedRates.filter(r => r.type !== extraRate.type).concat(extraRate)
  //                 }
  //               })()
  //
  //           updateCustomerQuoteData({
  //             newCustomerQuotes: calculateCustomerQuoteProps(
  //               customerQuoteData.newCustomerQuotes,
  //               rateType,
  //               updatedSelectedRates
  //             )
  //           })
  //         },
  //         setSelectedRates: (rates, rateType) => {
  //           updateCustomerQuoteData({
  //             newCustomerQuotes:
  //             calculateCustomerQuoteProps(customerQuoteData.newCustomerQuotes, rateType, rates || [])
  //           })
  //         },
  //         updateSelectedRate: (rate, rateType) => {
  //           const selectedRates: AnyRateDTO[] =
  //             customerQuoteData.newCustomerQuotes[customerQuoteSelectedRatesFieldName[rateType]] || []
  //           const updatedSelectedRates = selectedRates.map(existedRate =>
  //             existedRate.id === rate.id ? rate : existedRate
  //           )
  //           updateCustomerQuoteData({
  //             newCustomerQuotes: calculateCustomerQuoteProps(
  //               customerQuoteData.newCustomerQuotes,
  //               rateType,
  //               updatedSelectedRates
  //             )
  //           })
  //         },
  //         removeSelectedRate: (rateId, rateType) => {
  //           const selectedRates: AnyRateDTO[] =
  //             customerQuoteData.newCustomerQuotes[customerQuoteSelectedRatesFieldName[rateType]] || []
  //           const updatedSelectedRates = selectedRates.filter(({ id }) => id !== rateId)
  //           updateCustomerQuoteData({
  //             newCustomerQuotes: calculateCustomerQuoteProps(
  //               customerQuoteData.newCustomerQuotes,
  //               rateType,
  //               updatedSelectedRates
  //             )
  //           })
  //         }
  //       }
  //     }
  //   }
  // }, [tabData])

  return <QMPContext.Provider value={value}>{children}</QMPContext.Provider>
}

export const QMPProvider = connect(
  null,
  { updateTabData }
)(React.memo(Component))
