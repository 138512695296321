import * as React from 'react'
import * as yup from 'yup'
import { oc } from 'ts-optchain'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { DeliveryOrderViewDTO, DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../api/api'
import {
  schemaCustomerLazy,
  schemaDeliveryStage,
  schemaDispatchDeliveryOrderGridItem,
  schemaEquipmentLazy
} from '../../yupScheme'
import { isDeliveryOrderIdSteamShipLineRequiredByDDOs, schemaDeliveryOrderGridItem } from '../deliveryOrder'
import { checkActivity } from '../activity'
import { checkContactsValidation } from '../contact'
import {
  allowCalcDispatchDeliveryOrderStatus,
  calcDDOProps,
  findActivityWithWrongOrderOfPlannedDate,
  isChassisNumberRequired,
  isContainerRequiredForDDO
} from './functions'
import { debuggingMode } from '../../debug'
import { getStore } from '../../../store/configureStore'
import { createNewTab } from '../../../components/common/tabs/actions'
import { TabType } from '../../../components/common/tabs/interfaces'
// tslint:disable-next-line:max-line-length
import { headers as dispatchDeliveryOrderHeader } from '../../../components/common/dispatchDeliveryOrder/views/configGrid'
import { TransportationActivityGroup } from '../activity/interfaces'
import { setTabViewingObject } from '../../viewingObjects/actions'
import { getListState } from '../../../store'
import { isBobtailGotoActivity } from '../../functions/test/isBobtailGotoActivity'
import { isUnsuccessfulActivity } from '../../functions/test/isUnsuccessfulActivity'
import { isLoadTypeActitiesValid } from '../../functions/test/isLoadTypeActitiesValid'
import { isLocationGEOValid } from '../../functions/test/isLocationGEOValid'

const businessLogicValidationDispatchDeliveryOrder = schemaDispatchDeliveryOrderGridItem.shape({
  deliveryStage: yup
    .object()
    .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
      deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
        ? schema.nullable(true).notRequired()
        : schemaDeliveryStage
    ),
  containerTypeId: yup.string().required(),
  hazmatId: yup
    .string()
    .when('$isCargoTypeHazmat', (isCargoTypeHazmat: boolean, schema: yup.StringSchema) =>
      isCargoTypeHazmat ? schema.required() : schema.notRequired().nullable(true)
    ),
  containerId: yup
    .string()
    .when(
      ['$deliveryOrderType', '$dispatchDeliveryOrderStatus'],
      (
        deliveryOrderType: DeliveryOrderViewDTO.TypeEnum,
        dispatchDeliveryOrderStatus: DispatchDeliveryOrderViewDTO.StatusEnum,
        schema: yup.StringSchema
      ) =>
        isContainerRequiredForDDO(deliveryOrderType, dispatchDeliveryOrderStatus)
          ? schema.required()
          : schema.notRequired().nullable(true)
    ),
  equipment: yup
    .object()
    .when(
      ['$isChassisNumberRequired', '$hasChassisNumber'],
      (chassisNumberRequired: boolean, hasChassisNumber: boolean, schema: yup.StringSchema) =>
        chassisNumberRequired || hasChassisNumber ? schemaEquipmentLazy : schema.notRequired().nullable(true)
    ),
  deliveryOrder: schemaDeliveryOrderGridItem.shape({
    billOfLadingNumber: yup
      .string()
      .min(4)
      .max(64)
      .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT
          ? schema.required()
          : schema.nullable(true).notRequired()
      ),
    bookingNumber: yup
      .string()
      .min(4)
      .max(64)
      .when('$deliveryOrderType', (deliveryOrderType: DeliveryOrderViewDTO.TypeEnum, schema: yup.StringSchema) =>
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT ||
        deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
          ? schema.required()
          : schema.nullable(true).notRequired()
      ),
    customer: schemaCustomerLazy,
    generalCutoffDate: yup
      .string()
      .nullable(true)
      .notRequired()
  })
})

export const checkDispatchDeliveryOrderValidation = (dispatchDeliveryOrder: IDispatchDeliveryOrder): boolean => {
  const context = {
    dispatchDeliveryOrderStatus: dispatchDeliveryOrder.status,
    deliveryOrderType: dispatchDeliveryOrder.deliveryOrder.type,
    isCargoTypeHazmat: dispatchDeliveryOrder.hazmatIndicator,
    isChassisNumberRequired: isChassisNumberRequired(dispatchDeliveryOrder.activities),
    hasChassisNumber: Boolean(dispatchDeliveryOrder.equipmentId)
  }

  const steamShipLineValueIsCorrect = (): boolean => {
    return (
      Boolean(oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId()) ||
      !isDeliveryOrderIdSteamShipLineRequiredByDDOs(dispatchDeliveryOrder.deliveryOrderId)(dispatchDeliveryOrder)
    )
  }

  const validateTransportationActivities = (): boolean => {
    return Boolean(dispatchDeliveryOrder.activities.transportationActivities.every(checkActivity))
  }

  const validateSellSideQuote = (): boolean => {
    return oc(dispatchDeliveryOrder)
      .sellSideQuote.miscSurcharges([])
      .concat(
        ...oc(dispatchDeliveryOrder)
          .buySideQuotes([])
          .map(buySideQuote => oc(buySideQuote).miscSurcharges([]))
      )
      .every(miscSurcharge => miscSurcharge.description)
  }

  const validateLoadType = (): boolean => {
    const prevDispatchDeliveryOrderState = getStore().getState().dispatchDeliveryOrder[dispatchDeliveryOrder.id]

    return (
      dispatchDeliveryOrder.loadType !== prevDispatchDeliveryOrderState.loadType ||
      isLoadTypeActitiesValid(dispatchDeliveryOrder)
    )
  }

  const validateEquipment = (): boolean => {
    return Boolean(dispatchDeliveryOrder.equipment) || !isChassisNumberRequired(dispatchDeliveryOrder.activities)
  }

  const validateLocations = (): boolean => {
    const ddoLocations = [
      oc(dispatchDeliveryOrder).pickupStage.location(),
      oc(dispatchDeliveryOrder).deliveryStage.location(),
      oc(dispatchDeliveryOrder).returnStage.location()
    ].filter(Boolean)
    const activityLocations = oc(dispatchDeliveryOrder)
      .activities.transportationActivities([])
      .map(activity => activity.destination)
      .filter(Boolean)

    return [...ddoLocations, ...activityLocations].every(isLocationGEOValid)
  }

  // @ts-ignore
  const allContacts = [
    // @ts-ignore
    ...oc(dispatchDeliveryOrder).pickupStage.location.contacts([]),
    // @ts-ignore
    ...oc(dispatchDeliveryOrder).deliveryStage.location.contacts([]),
    // @ts-ignore
    ...oc(dispatchDeliveryOrder).returnStage.location.contacts([]),
    // @ts-ignore
    ...oc(dispatchDeliveryOrder).deliveryOrder.customer.businessPartner.contacts([])
  ]

  if (debuggingMode.common) {
    let validateDDO: any = true

    businessLogicValidationDispatchDeliveryOrder
      .validate(dispatchDeliveryOrder, { context })
      .catch(e => {
        // getStore().dispatch(
        //   addServerMessage({
        //     type: 'error',
        //     message: e.message
        //   })
        // )

        validateDDO = e
      })
      .finally(() => {
        let validationResult: any = {
          validateDDO,
          validateTransportationActivities: validateTransportationActivities(),
          validateLoadType: validateLoadType(),
          validateContacts: checkContactsValidation(allContacts),
          validateEquipment: validateEquipment(),
          validateSellSideQuote: validateSellSideQuote(),
          steamShipLineValueIsCorrect: steamShipLineValueIsCorrect(),
          validateLocations: validateLocations()
        }

        validationResult = Object.keys(validationResult).reduce((acc, currKey) => {
          if (validationResult[currKey] !== true) {
            acc[currKey] = validationResult[currKey]
          }
          return acc
        }, {})

        if (Object.keys(validationResult).length) {
          // tslint:disable-next-line:no-console
          console.error(`DDO#${dispatchDeliveryOrder.number || 'NEW'} validation error`, validationResult)
        }
      })
  }

  return (
    businessLogicValidationDispatchDeliveryOrder.isValidSync(dispatchDeliveryOrder, { context }) &&
    validateTransportationActivities() &&
    checkContactsValidation(allContacts) &&
    validateEquipment() &&
    validateSellSideQuote() &&
    steamShipLineValueIsCorrect() &&
    // validateLocations() &&
    !findActivityWithWrongOrderOfPlannedDate(dispatchDeliveryOrder.activities.transportationActivities) &&
    dispatchDeliveryOrder.activities.transportationActivities.every(activity =>
      (isBobtailGotoActivity(activity) && isUnsuccessfulActivity(activity)) ||
      (activity.type === TransportationActivityViewDTO.TypeEnum.OFFDUTY && !isUnsuccessfulActivity(activity))
        ? activity.destinationId
        : true
    )
  )
}

export const calcDispatchDeliveryOrderProps = (
  dispatchDeliveryOrder: IDispatchDeliveryOrder
): {
  ddoStatus: DispatchDeliveryOrderViewDTO.StatusEnum
  declinedVendorsNames: string
  currentActivityGroup: TransportationActivityGroup
} => {
  if (
    dispatchDeliveryOrder.fullObject &&
    (dispatchDeliveryOrder.activities.transportationActivities.length ||
      dispatchDeliveryOrder.activities.documentationActivities.length) &&
    allowCalcDispatchDeliveryOrderStatus(dispatchDeliveryOrder.status)
  ) {
    const { ddoStatus, declinedVendorIds, currentActivityGroup } = calcDDOProps(
      dispatchDeliveryOrder.activityGroups,
      dispatchDeliveryOrder
    )
    const storeDrivers = getListState().driver

    return {
      ddoStatus,
      declinedVendorsNames: declinedVendorIds
        .map(driverId => oc(storeDrivers[driverId]).name('Vendor Name'))
        .join(', '),
      currentActivityGroup
    }
  }

  return {
    ddoStatus: dispatchDeliveryOrder.status,
    declinedVendorsNames: '',
    currentActivityGroup: undefined
  }
}

export const openDDOinNewTab = ({
  ddoId,
  tabName,
  ddoNumber,
  event
}: {
  ddoId: string
  ddoNumber?: number | string
  tabName?: string
  event?: React.MouseEvent<HTMLSpanElement>
}) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const { dispatch } = getStore()

  const filter = []

  switch (true) {
    case Boolean(ddoNumber):
      filter.push({ column: 'number', value: String(ddoNumber) })
      break
    case Boolean(ddoId):
      filter.push({ column: 'id', value: ddoId })
      break
    default:
  }

  dispatch(
    createNewTab({
      tabType: TabType.dispatchDeliveryOrder,
      options: {
        title: tabName || 'DDO #' + ddoNumber,
        enableSorting: false,
        enableFiltering: false,
        uiSettings: {
          columns: { ...dispatchDeliveryOrderHeader },
          filter
        }
      }
    })
  )
  dispatch(setTabViewingObject({ tabId: getStore().getState().tabs.activeMainTabId, viewingObject: { id: ddoId } }))
}
