import * as React from 'react'
import * as R from 'remeda'
import { Highlight, ResizeButton, SortButton, StyledColumn } from './styledComponents'
import { combinedColumnFilterSettings } from '../../../../../../services/uiSettingsService/tabs'
import Input from '../../../../../UI/Input'
import { TableHeaderCell } from '../../styles'
import { Select } from '../../../../../UI/DataFields/Select'
import { Clear } from '../../../../../UI/Input/styledComponents'
import { oc } from 'ts-optchain'
import cn from 'classnames'
import DateField from '../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../UI/DatePicker/Interfaces'
import { TabType } from '../../../../tabs/interfaces'
import { FieldTemplate } from '../../../../../UI/DataFields/Templates'
import { DDOGridHeaderStatus } from '../../../../dispatchDeliveryOrder/views/HeaderColumn/Status'
import { resetTimeToZero } from '../../../../../../services/timeService/dateUtils'
import { ColumnTitle } from '../../interfaces'
import { DateTimePicker } from '../../../../../UI/DataFields/DateTimePicker/views'
// @ts-ignore
import { debounce } from 'debounce'
import { useRegExp } from '../../../../../../services/functions/regExp'
import { dateService } from '../../../../../../services/timeService'
// tslint:disable:max-line-length
import { DriverGridHeaderStatus } from '../../../../drivers/GridHeaderColumn/GridHeaderColumnStatus/GridHeaderColumnStatus'
import { SalesRepresentativeHeaderColumn } from '../../../../customer/views/HeaderColumn/SalesRepresentativeHeaderColumn'

export enum GridHeaderColumnType {
  date = 'date',
  number = 'number',
  string = 'string',
  state = 'state',
  multiSelectList = 'multiSelectList',
  list = 'list'
}

export type IFilterItemDate = {
  from?: string
  to?: string
}

export interface IFilterItem {
  column: string
  path?: string
  sort?: string
  combinationOfValuePaths?: string[]
  value: boolean | number | string | string[] | IFilterItemDate
  isEqualToValue?: boolean
  regExp?: RegExp
  newDateTimePicker?: boolean
}

type Props = {
  heading: string
  columnProps: ColumnTitle
  updateSorting: (columnName: string) => void
  updateFilters: (updatedFilters: IFilterItem[]) => void
  sorting?: string
  appliedFilters?: IFilterItem[]
  tabType?: TabType
  updateColumnSize: (columnHeading: string, width: number) => void
  enableFiltering: boolean
  enableSorting: boolean
  settings: any
}

const Column = (props: Props) => {
  const {
    heading,
    sorting,
    appliedFilters,
    updateSorting,
    tabType,
    updateFilters,
    updateColumnSize,
    enableFiltering,
    enableSorting,
    columnProps,
    settings
  } = props
  const resizeButtonRef = React.useRef(null)
  const columnRef = React.useRef(null)

  if (columnProps.headerWidth === 0) {
    return null
  }

  let diffX = 0
  let pageX = 0
  let curCol: any = undefined
  let curColWidth = 0

  const handleMouseDown = (e: any) => {
    curCol = columnRef.current
    pageX = e.pageX
    curColWidth = curCol.offsetWidth
    resizeButtonRef.current.classList.add('active')
    columnRef.current.parentElement.classList.add('primary')
    resizeButtonRef.current.style.left = pageX + 'px'
  }

  const handleMouseMove = (e: any) => {
    if (curCol) {
      diffX = e.pageX - pageX
      resizeButtonRef.current.style.left = e.pageX + 'px'
    }
  }

  const handleMouseUp = () => {
    if (curCol) {
      updateColumnSize(heading, curColWidth + diffX)
      resizeButtonRef.current.classList.remove('active')
      columnRef.current.parentElement.classList.remove('primary')
      resizeButtonRef.current.style.left = 'unset'
      diffX = 0
      curCol = undefined
      pageX = 0
      curColWidth = 0
    }
  }

  const columnSettings = R.omit(settings, ['items', 'disabled'])
  const disabled = settings.disabled
  const disableSorting = settings.disableSorting || enableSorting === false
  const column = columnSettings.name || 'column'
  const columnType = columnSettings.type || 'string'
  const path = columnSettings.path || column
  const columnItems = settings.items || ['']
  const columnFilter = appliedFilters ? appliedFilters.find(item => item.column === column) : null
  const columnValue = oc(columnFilter).value('')
  const dateValue: any = (columnFilter && columnType === 'date' && columnFilter.value) || {
    from: null,
    to: null
  }

  const updateFilter = (newValue: any, isEqualToValue?: boolean) => {
    let updatedFilter: IFilterItem[] = []
    let value = newValue

    if (columnType === GridHeaderColumnType.string) {
      value = (value || '')
        .replace(/\s+/g, ' ') // .replace(/\s+/g, ' ').replace(/[^\w\s]+/gi, '')
        .trim()

      if (settings.regExp) {
        value = useRegExp(settings.regExp, value)
      }
    } else if (columnType === GridHeaderColumnType.date) {
      value = newValue
        ? {
            from: newValue,
            to: columnSettings.newDateTimePicker
              ? dateService.convertStringDate(newValue).endDay
              : resetTimeToZero(newValue, true)
          }
        : newValue
    }

    if (appliedFilters) {
      const isFilterAlreadyExists = appliedFilters.some(item => item.column === column)
      updatedFilter = isFilterAlreadyExists
        ? appliedFilters.map(item => (item.column === column ? { ...item, value, isEqualToValue } : item))
        : appliedFilters.concat({ ...columnSettings, column, value, path, isEqualToValue })
    } else {
      updatedFilter = [{ ...columnSettings, column, value, path, isEqualToValue }]
    }

    updatedFilter = updatedFilter.filter(_ => {
      if (!_.value) {
        return false
      }

      const isObject = typeof _.value === 'object'
      const isArray = Array.isArray(_.value)
      const isDate = isObject && !isArray

      if (Array.isArray(_.value)) {
        return _.value.length
      }

      if (isDate) {
        const _date = _.value as { from: string; to: string }
        return _date.from || _date.to
      }

      return true
    })

    updateFilters(updatedFilter.length ? updatedFilter : null)
  }

  const updateFilterColumn = (columnName: string, newValue: any, fieldType: 'date' | 'string' | 'array') => {
    const clearAppliedFilters = (appliedFilters || []).filter(_ => _.column !== columnName)
    const removeAppliedFiltersColumn = () => {
      updateFilters(clearAppliedFilters.length ? clearAppliedFilters : null)
    }
    const updateAppliedFiltersColumn = () => {
      const _columnProps = oc(combinedColumnFilterSettings())[tabType][columnName]({ column: columnName })
      updateFilters(
        clearAppliedFilters.concat({
          ..._columnProps,
          value: newValue
        })
      )
    }

    switch (fieldType) {
      // TODO
      // case 'date': {
      //
      // }
      case 'string': {
        if (!newValue) {
          removeAppliedFiltersColumn()
          return
        }

        updateAppliedFiltersColumn()
        return
      }
      // TODO
      // case 'array': {
      //
      // }
      default: {
        break
      }
    }
  }

  const RenderColumn = () => {
    if (disabled || enableFiltering === false) {
      return <div style={{ padding: '0 10px' }}>{heading}</div>
    }

    switch (columnType) {
      case GridHeaderColumnType.date:
        return columnSettings.newDateTimePicker ? (
          <DateTimePicker
            hideTimeInput={true}
            placeholder={heading}
            date={oc(dateValue).from()}
            ignoreTabPress={true}
            onChange={updateFilter}
          />
        ) : (
          <DateField
            date={dateValue}
            small={false}
            showTime={false}
            dateType={DateTypes.DateISOString}
            placeholder={heading}
            onUpdate={debounce(updateFilter, 150, true)}
            textView={true}
          />
        )
      case GridHeaderColumnType.list:
        return (
          <>
            <Select
              placeholder={heading}
              selectedValue={columnValue}
              list={columnItems}
              onSelect={(newValue: any) => updateFilter(String(newValue))}
            />
            {Boolean((columnValue as any).length) && (
              <Clear className={'mdi mdi-close'} onClick={() => updateFilter('')} />
            )}
          </>
        )
      case GridHeaderColumnType.multiSelectList: {
        if (tabType === TabType.dispatchDeliveryOrder && heading === 'Status') {
          return (
            <DDOGridHeaderStatus
              placeholder={heading}
              selectedValues={columnValue as any}
              onStatusChange={updateFilter}
              archivedState={!oc((appliedFilters || []).find(_ => _.column === 'archived')).value()}
              toggleArchived={state => {
                return updateFilterColumn('archived', state ? undefined : String(state), 'string')
              }}
            />
          )
        }

        if (tabType === TabType.driver && heading === 'Active / Availability') {
          return (
            <DriverGridHeaderStatus placeholder={heading} filterItem={columnFilter} onStatusChange={updateFilter} />
          )
        }

        if (tabType === TabType.customer && heading === 'Sales Rep') {
          return (
            <SalesRepresentativeHeaderColumn placeholder={heading} filterItem={columnFilter} onChange={updateFilter} />
          )
        }

        return (
          <>
            <Select
              multiselect={true}
              placeholder={heading}
              selectedValue={columnValue}
              list={columnItems}
              onSelect={updateFilter}
              hidePopover={true}
            />
            {Boolean((columnValue as any).length) && (
              <Clear className={'mdi mdi-close'} onClick={() => updateFilter('')} />
            )}
          </>
        )
      }
      case GridHeaderColumnType.state:
        return (
          <FieldTemplate.StateSearch
            placeholder={heading}
            showCode={true}
            stateId={columnValue as string}
            onChange={updateFilter}
          />
        )
      case GridHeaderColumnType.number:
      case GridHeaderColumnType.string:
        return (
          <Input
            value={columnValue as string}
            placeholder={heading}
            isGridColumnTitle={true}
            onChange={newValue =>
              !columnValue && !newValue ? undefined : updateFilter(newValue ? String(newValue) : '')
            }
            height={30}
            isNumber={columnType === 'number'}
          />
        )
      default:
        return null
    }
  }

  return (
    <TableHeaderCell
      style={{
        width: columnProps.headerWidth || columnProps.width,
        minWidth: columnProps.minWidth,
        maxWidth: columnProps.maxWidth
      }}
    >
      <StyledColumn ref={columnRef} alignCenter={true}>
        {!disabled &&
          enableSorting !== false &&
          ((columnType === 'date' && (dateValue.from || dateValue.to)) || Boolean((columnValue as any).length)) && (
            <Highlight />
          )}
        <RenderColumn />

        {!disabled && !disableSorting && (
          <>
            <SortButton
              className={cn('mdi mdi-menu-up', {
                active: sorting === columnSettings.sort || sorting === columnSettings.name
              })}
              onClick={() => updateSorting(columnSettings.sort || columnSettings.name)}
            />
            <SortButton
              className={cn('mdi mdi-menu-down', {
                active: sorting === '-' + columnSettings.sort || sorting === '-' + columnSettings.name
              })}
              onClick={() => updateSorting('-' + (columnSettings.sort || columnSettings.name))}
            />
          </>
        )}

        {!columnProps.headerWidth && (
          <ResizeButton
            ref={resizeButtonRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseOut={handleMouseUp}
          />
        )}
      </StyledColumn>
    </TableHeaderCell>
  )
}

export default Column
