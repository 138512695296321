import * as React from 'react'
import { StyledColumnInfo, ColumnInfoAmount, ColumnInfoDate } from './styles'
import { DateTypes } from '../../../../../../../../../UI/DatePicker/Interfaces'
import DateField from '../../../../../../../../../UI/DatePicker/DateField'
import { StyledFieldContainer } from '../../../../../../Grid/styles'
import {
  convertISODateToDateWithHoursMins,
  resetTimeToZero
} from '../../../../../../../../../../services/timeService/dateUtils'
import { DateISOString } from '../../../../../../../../../../api/origin/business-logic'
import { checkExpirationDate, checkEffectiveDate } from '../../../../../../functions'
import { transformMoney } from '../../../../../../../../../UI/Input'

type Props = {
  disabled: boolean
  amount: number
  effectiveDate: string
  expirationDate: string
  updateCustomerQuoteField: (field: string, value: any) => void
}

export const ColumnInfo = (props: Props) => {
  const { amount, effectiveDate, expirationDate, disabled, updateCustomerQuoteField } = props
  return (
    <StyledColumnInfo className={'special-fields-container'}>
      <StyledFieldContainer style={extraFirstContainerStyles}>
        {disabled ? (
          <ColumnInfoDate>{convertISODateToDateWithHoursMins(effectiveDate, false, true)}</ColumnInfoDate>
        ) : (
          <DateField
            required={true}
            setDayStart={true}
            disabled={disabled}
            date={effectiveDate}
            dateType={DateTypes.DateISOString}
            placeholder={'Effective Date'}
            onUpdate={(date: DateISOString) =>
              updateCustomerQuoteField('effectiveDate', checkEffectiveDate(resetTimeToZero(date), expirationDate))
            }
          />
        )}
      </StyledFieldContainer>
      <StyledFieldContainer style={extraSecondContainerStyles}>
        {disabled ? (
          <ColumnInfoDate>{convertISODateToDateWithHoursMins(expirationDate, false, true)}</ColumnInfoDate>
        ) : (
          <DateField
            setDayEnd={true}
            required={true}
            date={expirationDate}
            dateType={DateTypes.DateISOString}
            placeholder={'Expiration Date'}
            onUpdate={(date: DateISOString) =>
              updateCustomerQuoteField('expirationDate', checkExpirationDate(date, effectiveDate))
            }
          />
        )}
      </StyledFieldContainer>
      <ColumnInfoAmount>{transformMoney(amount)}</ColumnInfoAmount>
    </StyledColumnInfo>
  )
}

const extraFirstContainerStyles = { width: 105, marginRight: 8 }
const extraSecondContainerStyles = { width: 105 }
