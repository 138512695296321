import * as React from 'react'
import { CustomDDOHeader } from './index'
import styled from 'styled-components'
import { debuggingMode } from '../../../../../../services/debug'
import { assignDriverPromise } from '../../../../../../services/DTO/dispatchDeliveryOrder/save'
import { showErrorModalWindow } from '../../../../../../store/reducers/modalWindow/functions'

export const TestDriverAssign = (props: CustomDDOHeader) => {
  if (!debuggingMode.driverAssign) {
    return null
  }

  const { dispatchDeliveryOrder } = props

  return (
    <Container>
      <Button
        onClick={() => {
          assignDriverPromise(dispatchDeliveryOrder)(dispatchDeliveryOrder).then(result => {
            if (result && Array.isArray(result) && result.length === 0) {
              showErrorModalWindow({
                title: 'There is no any driver with Assigned Driver Status'
              })
            }
          })
        }}
      >
        Driver Assign requests
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 0 15px;
  margin: 0 15px;
  user-select: none;
`

const Button = styled.div`
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  background-color: #4555d0;
  color: white;
  padding: 0 9px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:before {
    font-size: 18px;
    margin-right: 5px;
  }
`
