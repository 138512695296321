import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { addSubClientToStore } from '../action'
import { ISubClientState, ISubClient } from '../interfaces'
import { createNewBusinessPartner } from '../../businessPartner/reducers'

export const newSubClient = (id?: string): ISubClient => {
  const businessPartner = createNewBusinessPartner()
  return {
    fullObject: true,
    id: id || 'newSubClient',
    businessPartner,
    businessPartnerId: businessPartner.id,
    customerId: null
  }
}

const subClientState: ISubClientState = {}

const subClientReducer = reducerWithInitialState<ISubClientState>(subClientState)
  .case(addSubClientToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default subClientReducer
