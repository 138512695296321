import { callAPI, locationAPI } from '../../../../../api/api'
import { combineLocations } from '../../../../../services/DTO/location/functions'
import { newLocation } from '../../../../common/location/reducers'
import { SearchRequest } from '../searchRequest'
import { assembleDTO } from '../../../../../services/DTO/assemble'
import * as React from 'react'
import { TSearchField } from '../index'
import { locationControllerApi } from '../../../../../api/location'
import { parseDTO } from '../../../../../services/DTO/parseDTO'
import { locationTypeIcons } from '../../../../../services/DTO/location'
import theme from '../../../../../styles/theme'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'

export const LocationSearch = (
  props: TSearchField & {
    isShortName?: boolean
    includeGoogleSearch?: boolean
  }
) => {
  const getOwnDatabaseList = (term: string): Promise<any[]> => callAPI(locationAPI.search, null, term + '').toPromise()
  const getOwnDatabaseDetails = (location: any): Promise<any> => callAPI(locationAPI.findById, location.id).toPromise()
  let getList = getOwnDatabaseList
  let getDetails = getOwnDatabaseDetails

  if (props.includeGoogleSearch) {
    getList = (term: string) =>
      getOwnDatabaseList(term).then(list => {
        if (term && !list.length) {
          return locationControllerApi
            .searchLocation(term)
            .then((items: any) => (items ? items.map((_: any) => ({ ..._, googleResult: true })) : items))
            .catch(() => [{ create: true, description: term }])
        } else {
          return list
        }
      })

    getDetails = (location: any) => {
      if (location.googleResult) {
        return locationControllerApi
          .locationByPlaceId(location.placeId)
          .then(data => combineLocations(newLocation(), data))
      } else if (location.create) {
        return Promise.resolve(newLocation())
      } else {
        return getOwnDatabaseDetails(location)
      }
    }
  }

  return (
    <SearchRequest
      {...props}
      minWidth={300}
      requestByEnterPress={props.requestByEnterPress || props.includeGoogleSearch}
      filtering={false}
      label={
        props.value ? (props.isShortName ? props.value.shortName : props.value.longName) || props.value.name : null
      }
      assembleObject={assembleDTO.location}
      getList={getList}
      getDetails={getDetails}
      addToStore={parseDTO.location}
      makeDropDownItem={response =>
        response.googleResult || response.create
          ? {
              className: 'create' + (response.googleResult ? ' google' : ''),
              before: 'Create',
              label: response.description,
              value: response
            }
          : {
              componentBefore: response.type ? (
                <i
                  style={{
                    fontSize: 18,
                    marginRight: 5,
                    alignSelf: 'flex-start',
                    color: isLocationGEOValid(response) ? undefined : theme.colors.defaultRed
                  }}
                  className={'mdi ' + locationTypeIcons[response.type]}
                />
              ) : (
                undefined
              ),
              label: response.longName,
              value: response
            }
      }
    />
  )
}
