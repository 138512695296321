import * as React from 'react'
import { RouteBuilderContext } from '../contexts/RouteBuilderContext'
import { IMove, RouteBuilderGridView } from '../store/reducers/communicationHub/interfaces'
// tslint:disable:max-line-length
import { RouteBuilderToastMessageType } from '../components/CommunicationHub/views/RouteBuilderView/RouteBuilderToastMessage'
import { IDriver } from '../components/common/drivers/interfaces'
import { DriverViewDTO } from '../api/api'
import { testDriverStatus } from '../services/functions/test/testDriverStatus'

type OwnProps = {
  view: RouteBuilderGridView
  activeTabDate: string
  vendorId: string
  authUserId: string
  driver: IDriver
  setFetch: React.Dispatch<React.SetStateAction<boolean>>
  setRouteBuiderToastMessage: React.Dispatch<
    React.SetStateAction<{
      type: RouteBuilderToastMessageType
      message: string
    }>
  >
  getCurrentTabDayMoves(): Promise<IMove[]>
  children: any
}

type StateProps = {}

type Props = OwnProps & StateProps

const Component = (props: Props) => {
  const { children, getCurrentTabDayMoves, setFetch, vendorId, authUserId, activeTabDate, view, driver } = props
  const setMoveErrorMappingRef = React.useRef({})

  return (
    <RouteBuilderContext.Provider
      value={{
        view,
        isDriverAssignable:
          testDriverStatus(driver.status).isActive ||
          driver.status === DriverViewDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS,
        activeTabDate,
        vendorId,
        authUserId,
        getCurrentTabDayMoves,
        setFetch,
        setMoveErrorMappingRef,
        setRouteBuiderToastMessage: props.setRouteBuiderToastMessage
      }}
    >
      {children}
    </RouteBuilderContext.Provider>
  )
}

export const RouteBuilderProvider = React.memo(Component)
