import * as React from 'react'
import { Title, BoldLine } from '../../styles'

export const Window: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <>
    <Title rows={2} margin={{ bottom: 10 }}>
      {title}
    </Title>
    <BoldLine className={'inTwoLines'} rows={3} maxRows={7}>
      {value}
    </BoldLine>
  </>
)
