import { isNewObject } from '..'
import { callAPI, callAPIWithErrorMessage, deductionAPI, deductionTransactionControllerAPI } from '../../../api/api'
import {
  CreateDeductionDTO,
  CreateDeductionTransactionDTO,
  DeductionDTO,
  DeductionStatus,
  DeductionType
} from '../../../api/origin/vendor-accounting-service'

export const requestDriverDeduction = (deductionId: string): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerGetDeduction, deductionId)
}

export const requestDriverDeductions = (driverId: string): Promise<DeductionDTO[]> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerGetDeductionsByVendorId, driverId)
}

export const requestDeductionRemove = (deductionId: string): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerRemove, deductionId)
}

export const requestDeductionCreate = (deduction: DeductionDTO): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerCreate, {
    ...deduction,
    id: undefined,
    status: undefined,
    totalAmount: undefined
  })
}

export const requestDeductionUpdate = (deduction: DeductionDTO): Promise<DeductionDTO> => {
  if (isNewObject(deduction)) {
    return requestDeductionCreate(deduction)
  }

  return callAPIWithErrorMessage(deductionAPI.deductionControllerUpdate, deduction.id, {
    ...deduction,
    status: undefined,
    totalAmount: undefined
  })
}

export const requestCloseDeductionId = (deductionId: string): Promise<DeductionDTO> => {
  return callAPIWithErrorMessage(deductionAPI.deductionControllerClose, deductionId)
}

export const requsetDeductionTransactions = (deductionId: string) => {
  return callAPIWithErrorMessage(
    deductionTransactionControllerAPI.deductionTransactionControllerGetDeductionTransactionsByDeductionId,
    deductionId
  )
}

export const requestCreateDeductionTransaction = (
  deductionTransaction: CreateDeductionTransactionDTO
): Promise<CreateDeductionTransactionDTO> => {
  return callAPIWithErrorMessage(
    deductionTransactionControllerAPI.deductionTransactionControllerCreate,
    deductionTransaction
  )
}

export const requsetDudectionTransactionReport = (deductionId: string): Promise<any> => {
  return callAPI(deductionTransactionControllerAPI.deductionTransactionControllerGetReport, deductionId)
    .toPromise()
    .then(data => data.text())
}
