import { compareUpdateDates, isNewId, isNewObject } from '../index'
import { getStore } from '../../../store/configureStore'
import { isEqualObjects, omitUpdateInfo } from '../saveDTO'
import * as R from 'remeda'
import { IDeliveryOrder } from '../../../components/common/deliveryOrder/interfaces'
import { ILocation } from '../../../components/common/location/interfaces'
import { IDispatchDeliveryOrder } from '../../../components/common/dispatchDeliveryOrder/interfaces'
import { getLocationStatus } from '../location/status'
import { getUpdatedDispatchDeliveryOrderStatus } from '../dispatchDeliveryOrder/status'
import { getLocationSavePromise } from '../location/save'
import { callAPI, dispatchDeliveryOrderAPI, LocationDTO } from '../../../api/api'
import { cleanDispatchDeliveryOrder } from '../dispatchDeliveryOrder/functions'
import { oc } from 'ts-optchain'
import { parseDTO } from '../parseDTO'
import { getObjectsDifference } from '../ConflictsOnSaving'

export const omitDOFields: (keyof IDeliveryOrder)[] = [
  'customer',
  'pickupLocation',
  'deliveryLocation',
  'returnLocation',
  'steamShipLine',
  'dispatchDeliveryOrders'
]

export const getUpdatedDeliveryOrderStatus = (deliveryOrder: IDeliveryOrder) => {
  const store = getStore().getState()
  const storeDeliveryOrder = store.deliveryOrder[deliveryOrder.id]

  const getLightObjects = () => {
    const lightDO: any = R.omit(deliveryOrder, omitDOFields)
    const lightStoreDO: any = storeDeliveryOrder ? R.omit(storeDeliveryOrder, omitDOFields) : {}

    return {
      checkObject: omitUpdateInfo({ ...lightDO, cargo: omitUpdateInfo(lightDO.cargo) }),
      storeObject: omitUpdateInfo({
        ...lightStoreDO,
        cargo: omitUpdateInfo(lightStoreDO.cargo)
      })
    }
  }

  return {
    get noStoreDataForUpdate() {
      return compareUpdateDates(deliveryOrder, storeDeliveryOrder)
    },
    get needToSave() {
      const { checkObject, storeObject } = getLightObjects()
      return oc(checkObject).fullObject() && !isEqualObjects(checkObject, storeObject)
    },
    get getDifference() {
      const { checkObject, storeObject } = getLightObjects()
      return getObjectsDifference({ oldObject: checkObject, newObject: storeObject })
    }
  }
}

export const getDispatchDeliveryOrdersToSave = (
  dispatchDeliveryOrdersOfDeliveryOrder: IDispatchDeliveryOrder[]
): IDispatchDeliveryOrder[] => {
  const dispatchDeliveryOrdersToSave: IDispatchDeliveryOrder[] = []

  dispatchDeliveryOrdersOfDeliveryOrder.forEach(currentDDO => {
    if (getUpdatedDispatchDeliveryOrderStatus(currentDDO, true).needToSave) {
      dispatchDeliveryOrdersToSave.push(currentDDO)
    }
  })

  return dispatchDeliveryOrdersToSave
}

export const saveDispatchDeliveryOrders = async (dispatchDeliveryOrders: IDispatchDeliveryOrder[]) => {
  const dispatchDeliveryOrdersToUpdate = dispatchDeliveryOrders.map(ddo => {
    const cleanedDDO = cleanDispatchDeliveryOrder(ddo)

    return {
      ...cleanedDDO,
      id: isNewObject(cleanedDDO) ? undefined : cleanedDDO.id,
      pickupStage: { ...cleanedDDO.pickupStage },
      deliveryStage: { ...cleanedDDO.deliveryStage },
      returnStage: { ...cleanedDDO.returnStage }
    }
  })

  if (dispatchDeliveryOrdersToUpdate.length) {
    return callAPI(dispatchDeliveryOrderAPI.batchUpdate, dispatchDeliveryOrdersToUpdate)
      .toPromise()
      .then(resolvedDispatchDeliveryOrders => {
        parseDTO.dispatchDeliveryOrder(resolvedDispatchDeliveryOrders)

        return resolvedDispatchDeliveryOrders
      })
  }

  return null
}
