import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'

const WindowInner = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  user-select: none;
`

const Button = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  margin-right: 9px;
  cursor: not-allowed;

  &.not-disable {
    cursor: pointer;
  }

  .mdi {
    &.mdi-sync {
      color: #999;
    }
    &.mdi-sync-alert {
      color: #ff5e5e;
    }
  }

  &.not-disable:hover .mdi {
    color: ${theme.colors.basicBlueColor};
  }
`
const TerminalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TerminalCode = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #445366;
`
const TerminalMismatch = styled.div`
  color: #ff5e5e;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  margin-top: 1px;
`

export const Window: React.FC<{
  terminalCode: string
  checkTerminalIntegration: () => void
  disable: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
}> = ({ terminalCode, checkTerminalIntegration, disable, dispatchDeliveryOrder }) => (
  <WindowInner>
    <Button onClick={!disable ? checkTerminalIntegration : null} className={disable ? '' : 'not-disable'}>
      {!dispatchDeliveryOrder.terminalMismatches.length ? (
        <i className={'mdi mdi-sync'} />
      ) : (
        <i className={'mdi mdi-sync-alert'} />
      )}
    </Button>
    <TerminalInfo>
      <TerminalCode>{terminalCode}</TerminalCode>
      {Boolean(dispatchDeliveryOrder.terminalMismatches.length) && <TerminalMismatch>Mismatch</TerminalMismatch>}
    </TerminalInfo>
  </WindowInner>
)
