import { DispatchDeliveryOrderViewDTO, TransportationActivityViewDTO } from '../../../../../api/origin/business-logic'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import * as React from 'react'
import { oc } from 'ts-optchain'
import { Widget } from '../../'
import { Popover } from './popover'
import { Preview } from './preview'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import { calcDispatchDeliveryOrderProps } from '../../../../../services/DTO/dispatchDeliveryOrder'
import { showModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { ActivityGroup, TransportationActivityGroup } from '../../../../../services/DTO/activity/interfaces'
import { checkValidation } from '../../../../../services/DTO/validation'
import { isGotoActivity } from '../../../../../services/functions/test/isGotoActivity'
import { dispatchDeliveryOrderStatusIndex } from '../../../../../services/DTO/dispatchDeliveryOrder/functions'

type Props = {
  disabled?: boolean
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  activities: TransportationActivityViewDTO[]
  updateDispatchDeliveryOrderField: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateDispatchDeliveryOrder: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  saveModifiedUnitAnyway: (dispatchDeliveryOrder: IDispatchDeliveryOrder) => void
  saveButton: JSX.Element
}

const Activity: React.SFC<Props> = ({
  disabled,
  dispatchDeliveryOrder,
  saveButton,
  activities,
  updateDispatchDeliveryOrderField,
  saveModifiedUnitAnyway,
  updateDispatchDeliveryOrder
}) => {
  let currentActivity = undefined
  let calculatedDDOStatus = dispatchDeliveryOrder.status
  let activityTypeOrDDOStatus = ddoDirectory.status[calculatedDDOStatus]

  const { ddoStatus, currentActivityGroup } = React.useMemo(
    () => calcDispatchDeliveryOrderProps(dispatchDeliveryOrder),
    [dispatchDeliveryOrder]
  )
  calculatedDDOStatus = ddoStatus

  if (currentActivityGroup) {
    currentActivity = [currentActivityGroup.businessActivity, currentActivityGroup.gotoActivity].find(
      activity =>
        activity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        activity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
    )

    if (currentActivity) {
      activityTypeOrDDOStatus = isGotoActivity(currentActivity)
        ? activityDirectory.type[currentActivity.type].slice(0, -2) +
          activityDirectory.type[currentActivityGroup.businessActivity.type]
        : activityDirectory.type[currentActivity.type]
    }
  }

  const windowProps = {
    title: currentActivityGroup ? 'Current Activity' : 'DDO Status',
    activityTypeOrDDOStatus,
    activityVendor: oc(currentActivity).vendor.name(''),
    titleAndIconColor: currentActivity
      ? activityDirectory.stageColor[currentActivity.stage]
      : ddoDirectory.statusColor[calculatedDDOStatus]
  }

  const showCancelButton: boolean = React.useMemo(
    () =>
      calculatedDDOStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED &&
      (dispatchDeliveryOrderStatusIndex[calculatedDDOStatus] <=
        dispatchDeliveryOrderStatusIndex[DispatchDeliveryOrderViewDTO.StatusEnum.READYFORDISPATCH] ||
        oc(dispatchDeliveryOrder)
          .activities.transportationActivities([])
          .every(_ => _.status !== TransportationActivityViewDTO.StatusEnum.INPROCESS)) &&
      !dispatchDeliveryOrder.troubleTicketId,
    [dispatchDeliveryOrder]
  )

  return (
    <Widget
      title={windowProps.title}
      fixedWidth={true}
      bottomActions={
        showCancelButton
          ? [
              {
                text: 'Cancel DDO',
                onClick: () => {
                  if (!checkValidation.dispatchDeliveryOrder(dispatchDeliveryOrder)) {
                    return showModalWindow({
                      width: 320,
                      title: 'DDO is not valid',
                      content: 'Fill in all the fields correctly to save the DDO'
                    })
                  }

                  showModalWindow({
                    width: 320,
                    title: 'Are you sure you want to Cancel DDO?',
                    buttons: [
                      {
                        label: 'No'
                      },
                      {
                        label: 'Yes',
                        onClick: () => {
                          saveModifiedUnitAnyway(cancelDispatchDeliveryOrder(dispatchDeliveryOrder))
                        }
                      }
                    ]
                  })
                },
                style: { backgroundColor: '#ff5e5e', borderColor: '#ff5e5e' }
              }
            ]
          : true
      }
      RenderPreview={Preview}
      RenderDetails={Popover}
      previewProps={{
        windowProps,
        currentActivityStatus: oc(currentActivity).status()
      }}
      detailsProps={{
        disabled,
        status: calculatedDDOStatus,
        dispatchDeliveryOrder,
        windowProps,
        currentActivity,
        updateDispatchDeliveryOrderField,
        updateDispatchDeliveryOrder
      }}
    />
  )
}

export const cancelDispatchDeliveryOrder = (dispatchDeliveryOrder: IDispatchDeliveryOrder): IDispatchDeliveryOrder => {
  const adjustTransportationActivities = (activityGroups: ActivityGroup[]): TransportationActivityViewDTO[] => {
    const clearActivity = (activity: TransportationActivityViewDTO): TransportationActivityViewDTO => {
      return {
        ...activity,
        status: TransportationActivityViewDTO.StatusEnum.NEW,
        vendor: undefined,
        vendorId: undefined,
        startActualDate: undefined,
        completionActualDate: undefined
      }
    }

    const transportationGroups = (activityGroups.filter(
      group => 'gotoActivity' in group && 'businessActivity' in group
    ) as TransportationActivityGroup[]).map(group => {
      if (
        group.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL ||
        group.businessActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
      ) {
        return group
      }

      if (
        group.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
        group.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
      ) {
        return group
      }

      return {
        gotoActivity: clearActivity(group.gotoActivity),
        businessActivity: clearActivity(group.businessActivity)
      }
    })

    return transportationGroups.reduce((acc, curr) => {
      acc.push(curr.gotoActivity, curr.businessActivity)
      return acc
    }, [])
  }

  return {
    ...dispatchDeliveryOrder,
    status: DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED,
    activities: {
      documentationActivities: dispatchDeliveryOrder.activities.documentationActivities,
      transportationActivities: adjustTransportationActivities(dispatchDeliveryOrder.activityGroups)
    }
  }
}

export default Activity
