import {
  BuySideQuoteDTO,
  ContainerDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  DocumentationActivityDTO,
  SellSideQuoteDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { tryAddSSQsToStore } from '../sellSideQuote/epics'
import { tryAddBSQsToStore } from '../buySideQuote/epics'
import { oc } from 'ts-optchain'
import { tryAddActivitiesToStore } from '../../../components/common/activity/epics'
import { tryAddStreetTurnsToStore } from '../streetTurn/epics'
import { tryAddDispatchDeliveryOrderToStore } from '../../../components/common/dispatchDeliveryOrder/epics'
import { parseDTO } from '../parseDTO'

type DDOObjects = {
  container: ContainerDTO[]
  deliveryOrder: DeliveryOrderViewDTO[]
  transportationActivities: TransportationActivityViewDTO[]
  documentationActivities: DocumentationActivityDTO[]
  sellSideQuotes: SellSideQuoteDTO[]
  buySideQuotes: BuySideQuoteDTO[]
  streetTurn: DispatchDeliveryOrderStreetTurnDTO[]
}

export const parseDispatchDeliveryOrders = (dispatchDeliveryOrders: DispatchDeliveryOrderViewDTO[]): any => {
  const lists: DDOObjects = {
    container: [],
    deliveryOrder: [],
    transportationActivities: [],
    documentationActivities: [],
    sellSideQuotes: [],
    buySideQuotes: [],
    streetTurn: []
  }

  const clearedDDOs = dispatchDeliveryOrders.map(dispatchDeliveryOrder => {
    const { activities, container, deliveryOrder, buySideQuotes, sellSideQuote } = dispatchDeliveryOrder
    lists.container.push(container)
    lists.deliveryOrder.push(deliveryOrder)
    lists.transportationActivities.push(...oc(activities).transportationActivities([]))
    lists.documentationActivities.push(...oc(activities).documentationActivities([]))
    lists.buySideQuotes.push(...buySideQuotes)
    lists.sellSideQuotes.push(sellSideQuote)
    lists.streetTurn.push(dispatchDeliveryOrder.streetTurn)

    return {
      ...dispatchDeliveryOrder,
      deliveryOrder: undefined,
      container: undefined,
      buySideQuotes: undefined,
      sellSideQuote: undefined,
      activities: {
        transportationActivities: [],
        documentationActivities: []
      }
    }
  })

  // filter unique values & delete empty items
  Object.keys(lists).forEach(listType => {
    const uniqueIds: string[] = []
    lists[listType] = lists[listType].filter((item: any) => {
      if (item && !uniqueIds.includes(item.id)) {
        uniqueIds.push(item.id)
        return item
      }
    })
  })

  tryAddActivitiesToStore({
    transportationActivities: lists.transportationActivities,
    documentationActivities: lists.documentationActivities
  })
  tryAddStreetTurnsToStore(lists.streetTurn)
  tryAddSSQsToStore(lists.sellSideQuotes)
  tryAddBSQsToStore(lists.buySideQuotes)
  parseDTO.container(lists.container)
  parseDTO.deliveryOrder(lists.deliveryOrder)
  tryAddDispatchDeliveryOrderToStore(clearedDDOs)
}
