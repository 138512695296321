import * as React from 'react'
import { Details } from './Details'
import { Preview } from './Preview'
import { BuySideQuoteDTO } from '../../../../../../../../../api/origin/business-logic'
import { IDriver } from '../../../../../../../../common/drivers/interfaces'
import { BuySideQuote } from '../../../../../../../../../services/DTO/buySideQuote/interfaces'
import { RateDTO } from '../../../../../../../../../services/DTO/rate/interfaces'

type OwnProps = {
  isOpen: boolean
  amount: number
  applicableRates: RateDTO[]
  buySideQuote: BuySideQuote
  setOpenedBuySideQuoteId: (id: string) => void
  openRatesLink: (vendor?: { label: string; value: string }) => void
  openRateLink: (rateNumber: string) => void
  updateBuySideQuote: (buySideQuote: BuySideQuoteDTO) => void
  deleteBuySideQuote: () => void
}

type StateProps = {
  vendor?: IDriver
}

type Props = OwnProps & StateProps

export const Row = (props: Props) => {
  const {
    isOpen,
    amount,
    applicableRates,
    buySideQuote,
    setOpenedBuySideQuoteId,
    openRatesLink,
    openRateLink,
    updateBuySideQuote,
    deleteBuySideQuote
  } = props

  return (
    <>
      <Preview
        isOpen={isOpen}
        amount={amount}
        buySideQuote={buySideQuote}
        setOpenedBuySideQuoteId={setOpenedBuySideQuoteId}
        deleteBuySideQuote={deleteBuySideQuote}
      />
      {isOpen && (
        <Details
          buySideQuote={buySideQuote}
          applicableRates={applicableRates}
          openRatesLink={openRatesLink}
          openRateLink={openRateLink}
          updateBuySideQuote={updateBuySideQuote}
        />
      )}
    </>
  )
}
