import { ColumnTitle, TColumns } from '../../components/common/grid/views/interfaces'

export function createBodyColumnsWithHeaderProps(
  headerColumns: Record<string, ColumnTitle>,
  bodyColumns: Record<string, any>
): TColumns {
  return Object.keys(headerColumns).reduce(
    (acc, curr) => ({ ...acc, [curr]: { ...headerColumns[curr], value: bodyColumns[curr] } }),
    bodyColumns
  )
}
