import * as React from 'react'
import { SaveCancelButtons } from '../../../UI/Buttons/ActionsSaveCancel'
import UpdateInfo from '../../../UI/UpdateInfo'
import { IGridItemActions } from '../../../../contexts/GridItemContext'
import { TabType } from '../../../common/tabs/interfaces'
import styled from 'styled-components'

export type TUnitStatus = {
  unit: any
  unitType: TabType
  isModified: boolean
  isValid: boolean
  actions: IGridItemActions
}

type Props = TUnitStatus

export const UnitStatus: React.SFC<Props> = ({ unit, unitType, isModified, isValid, actions }) => {
  return (
    <>
      {isModified ? (
        <Container>
          <SaveCancelButtons onCancel={actions.cancel} onSave={actions.save} disableSave={!isValid} />
        </Container>
      ) : (
        unitType !== TabType.dispatchDeliveryOrder && (
          <Container>
            <UpdateInfo
              updatedAt={unit.updatedAt}
              updatedBy={unit.updatedBy}
              createdAt={unit.createdAt}
              createdBy={unit.createdBy}
            />
          </Container>
        )
      )}
    </>
  )
}

const Container = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-left: 20px;
`
