import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { OngoingActivityGroupDTO } from '../../../../../../../api/origin/business-logic'
import { IStore } from '../../../../../../../store/store.interface'
import { isShowDetailsOnClick, showDetailsOnClick } from '../../../../../../../services/debug'
import { Preview } from './Preview'
import { MessagesButton } from './MessagesButton'
import { SchedulerConstants } from '../../../../../../../constants/SchedulerConstants'
import { ViewingRowMode } from './ViewingRowMode'
import { setFavoriteOngoingActivityState } from '../../../../../tabs/actions'
import { setTabViewingObject } from '../../../../../../../services/viewingObjects/actions'
import { FavoritesButton } from './FavoritesButton'
import { useAppSelector } from '../../../../../../../hooks/useAppSelector'
import { selectRouteBuilderIncludesDDOId } from '../../../../../../../store/select/routeBuilderSelect'

type OwnProps = {
  activityGroup: OngoingActivityGroupDTO
  isMissed?: boolean
  isNowSection?: boolean
}

type StateProps = {
  tabId: string
  isFavorite: boolean | undefined
  isViewingCurrentObject: boolean
}

type DispatchProps = {
  setFavoriteOngoingActivityState: typeof setFavoriteOngoingActivityState
  setTabViewingObject: typeof setTabViewingObject
}

type Props = OwnProps & StateProps & DispatchProps

const Component = (props: Props) => {
  const { activityGroup, isMissed, isNowSection, tabId, isViewingCurrentObject } = props
  const isFavorite = props.isFavorite === undefined ? activityGroup.lfd || activityGroup.cutoff : props.isFavorite
  const presentsInRouteBuilder = useAppSelector(selectRouteBuilderIncludesDDOId(activityGroup.dispatchDeliveryOrderId))

  const openDetails = (event: any) => {
    // >>> Debugging
    if (isShowDetailsOnClick(event)) {
      showDetailsOnClick({
        title: 'Ongoing Activity Order Group',
        data: activityGroup,
        blink: { class: 'OngoingActivityOrderGroup', event }
      })
      return
    }
    // <<<

    props.setTabViewingObject({
      tabId,
      viewingObject: { id: activityGroup.id, data: activityGroup.dispatchDeliveryOrderId }
    })
  }

  return (
    <>
      <Container
        data-id={[
          `id-${activityGroup.id}`,
          `ddoNumber-${activityGroup.dispatchDeliveryOrderNumber}`,
          `activityGroupDate-${activityGroup.activityGroupDate}`,
          `highlight-${activityGroup.highlight}`,
          `presentsInRouteBuilder-${presentsInRouteBuilder}`
        ].join(' ')}
        className={cn('OngoingActivityOrderGroup', { presentsInRouteBuilder })}
      >
        <FavoritesButton
          isFavorite={isFavorite}
          lfd={activityGroup.lfd}
          cutoff={activityGroup.cutoff}
          onClick={() =>
            props.setFavoriteOngoingActivityState({
              tabId,
              ongoingActivityId: activityGroup.id,
              favoriteState: !isFavorite
            })
          }
        />
        <MessagesButton
          dispatchDeliveryOrderId={activityGroup.dispatchDeliveryOrderId}
          hasAlerts={Boolean(activityGroup.troubleTicketId)}
        />
        <Preview
          isFavorite={isFavorite}
          isMissed={isMissed}
          isNowSection={isNowSection}
          activityGroup={activityGroup}
          onClick={openDetails}
        />
        {/*Open details in Window*/}
      </Container>

      {isViewingCurrentObject && (
        <ViewingRowMode
          isFavorite={isFavorite}
          isMissed={isMissed}
          activityGroup={activityGroup}
          isNowSection={isNowSection}
          tabId={tabId}
        />
      )}
    </>
  )
}

// @ts-ignore
export const OngoingActivityRow = connect(
  (store: IStore, { activityGroup }: OwnProps) => {
    const tabId = store.tabs.activeMainTabId
    const tab = store.tabs.main.find(({ id }) => id === tabId)
    const { viewingObjects } = store
    const viewingObject = viewingObjects[tabId]

    return {
      tabId,
      isFavorite: oc(tab).data.favoriteItemIds({})[activityGroup.id],
      isViewingCurrentObject: Boolean(viewingObject && viewingObject.id === activityGroup.id)
    }
  },
  { setFavoriteOngoingActivityState, setTabViewingObject }
)(React.memo(Component))

const Container = styled.div`
  display: grid;
  grid-gap: ${SchedulerConstants.innerGap}px;
  grid-template-columns: 35px 35px 1fr;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &.presentsInRouteBuilder {
    box-shadow: 0 0 0 1px rgb(227, 232, 234), 0 0 0 3px #5cd8ff;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
