import { callAPI, containerTypeAPI } from '../../../api/api'
import { businessLogicApiActions } from '../../../api/businessLogicApi'
import { getStore } from '../../../store/configureStore'

export const requestContainerTypes = () => {
  return callAPI(containerTypeAPI.findAll)
    .toPromise()
    .then(containerTypes => {
      getStore().dispatch(
        businessLogicApiActions.containerTypeFindAllUsingGetAction.done({ result: containerTypes, params: {} })
      )
    })
}
