import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { Select } from '../../../../../../../../../UI/DataFields/Select'
import { TransportationActivityViewDTO } from '../../../../../../../../../../api/origin/business-logic'
import {
  ActivityGroupAction,
  DDOWorkingStatus,
  TransportationActivityRow
} from '../../../../../../../../../../services/DTO/activity/controller'
import { activityLists } from '../../../../../../../../../../services/select/activityLists'
import { TransportationActivityGroup } from '../../../../../../../../../../services/DTO/activity/interfaces'
import { ActivityColumn, DabbleColumn } from '../../../styles'
import { createISODateNow } from '../../../../../../../../../UI/DatePicker/Functions'
import { useTransportationActivityRow } from '../../../../../../../../../../hooks/useTransportationActivityRow'
import { isBobtailGotoActivity } from '../../../../../../../../../../services/functions/test/isBobtailGotoActivity'
import { getPreviousActivityRow } from '../../../../../../../../../../services/functions/get/getPreviousActivityRow'
// tslint:disable:max-line-length
import { getActivityDropBobtailGroup } from '../../../../../../../../../../services/functions/get/getActivityDropBobtailGroup'

type Props = {}

export const StatusActivityColumn = (props: Props) => {
  const {
    isDropActivity,
    isLinkedActivityToBobtail,
    linkedGotoActivity,
    isDropBobtail,
    isPickBobtail,
    disabled,
    transportationRow,
    updateActivityRow,
    isUnsuccessful,
    rowNumber,
    stage,
    stageActivityRows,
    dispatchDeliveryOrder
  } = useTransportationActivityRow()
  const isNoSyncBusinessActivityStatus = Boolean(
    isLinkedActivityToBobtail &&
      linkedGotoActivity &&
      (linkedGotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
        linkedGotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED)
  )
  const isLinkedBobtailGotoActivityInProcess =
    linkedGotoActivity && linkedGotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS
  const isLinkedBobtailGotoActivityCompleted =
    linkedGotoActivity && linkedGotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
  // AVAILABLE STATUSES
  let notAvailableStatuses = [TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED]
  const notAvailableForDefaultStatuses = [
    TransportationActivityViewDTO.StatusEnum.INPROCESS,
    TransportationActivityViewDTO.StatusEnum.COMPLETED,
    TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
  ]
  if (isUnsuccessful) {
    notAvailableStatuses = notAvailableForDefaultStatuses
  }
  if (
    transportationRow.activityGroup.gotoActivity.status !== TransportationActivityViewDTO.StatusEnum.INPROCESS &&
    transportationRow.activityGroup.gotoActivity.status !== TransportationActivityViewDTO.StatusEnum.COMPLETED
  ) {
    notAvailableStatuses.push(TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL)
  }

  // GET STATUS LISTS
  const fullStatuses = activityLists.status.filter(item => !notAvailableStatuses.includes(item.value))
  const planningStatuses = fullStatuses.filter(item =>
    [TransportationActivityViewDTO.StatusEnum.NEW, TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED].includes(
      item.value
    )
  )
  const defaultStatuses = fullStatuses.filter(item => !notAvailableForDefaultStatuses.includes(item.value))

  let gotoActivityStatuses = planningStatuses
  let businessActivityStatuses = planningStatuses

  if (transportationRow.permissions.ddoWorkingStatus === DDOWorkingStatus.working) {
    gotoActivityStatuses =
      transportationRow.permissions.fullAccess ||
      transportationRow.activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED ||
      transportationRow.activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
        ? fullStatuses
        : defaultStatuses

    businessActivityStatuses =
      (transportationRow.permissions.fullAccess &&
        transportationRow.activityGroup.gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED) ||
      transportationRow.activityGroup.businessActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
        ? fullStatuses
        : defaultStatuses
  }

  // CHANGE STATUS FUNCTION
  const updateStatus = (isGotoActivity: boolean) => (status: TransportationActivityViewDTO.StatusEnum) => {
    const modifiedActivityGroup: TransportationActivityGroup = R.clone(transportationRow.activityGroup)
    const { gotoActivity, businessActivity } = modifiedActivityGroup
    const dateTimeNow = createISODateNow()

    switch (status) {
      case TransportationActivityViewDTO.StatusEnum.NEW: {
        gotoActivity.status = businessActivity.status = status
        gotoActivity.vendor = businessActivity.vendor = gotoActivity.vendorId = businessActivity.vendorId = undefined
        gotoActivity.startActualDate = gotoActivity.completionActualDate = undefined
        businessActivity.startActualDate = businessActivity.completionActualDate = undefined

        if (isDropActivity) {
          const dropActivityBobtailRows = getActivityDropBobtailGroup({
            activity: transportationRow.activityGroup.gotoActivity,
            activities: dispatchDeliveryOrder.activities.transportationActivities
          })
            .map(group => stageActivityRows.find(row => row.id === oc(group).gotoActivity.id()))
            .filter(Boolean) as TransportationActivityRow[]

          if (dropActivityBobtailRows.length) {
            return updateActivityRow(
              [
                {
                  ...transportationRow,
                  activityGroup: modifiedActivityGroup
                }
              ].concat(
                dropActivityBobtailRows.map(row => ({
                  ...row,
                  activityGroup: {
                    ...row.activityGroup,
                    gotoActivity: {
                      ...row.activityGroup.gotoActivity,
                      status,
                      vendorId: undefined,
                      vendor: undefined,
                      startActualDate: undefined,
                      completionActualDate: undefined
                    },
                    businessActivity: {
                      ...row.activityGroup.businessActivity,
                      status,
                      vendorId: undefined,
                      vendor: undefined,
                      startActualDate: undefined,
                      completionActualDate: undefined
                    }
                  }
                }))
              ),
              ActivityGroupAction.severalUpdates
            )
          }
        }

        break
      }
      case TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED:
      case TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED:
      case TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED: {
        if (!isGotoActivity && isNoSyncBusinessActivityStatus) {
          businessActivity.status = status
          businessActivity.startActualDate = businessActivity.completionActualDate = undefined
        } else {
          gotoActivity.status = businessActivity.status = status
          gotoActivity.startActualDate = gotoActivity.completionActualDate = undefined
          businessActivity.startActualDate = businessActivity.completionActualDate = undefined
        }

        break
      }
      case TransportationActivityViewDTO.StatusEnum.INPROCESS: {
        if (isGotoActivity) {
          // goto activity
          gotoActivity.status = status
          gotoActivity.startActualDate = gotoActivity.startActualDate || dateTimeNow
          gotoActivity.completionActualDate = undefined

          if (isDropBobtail) {
            businessActivity.startActualDate = undefined
            businessActivity.completionActualDate = undefined
            businessActivity.status = TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
          }
        } else {
          // business activity
          businessActivity.status = status
          businessActivity.startActualDate = businessActivity.startActualDate || dateTimeNow
          businessActivity.completionActualDate = undefined
        }

        break
      }
      case TransportationActivityViewDTO.StatusEnum.COMPLETED: {
        if (isGotoActivity) {
          // goto Activity
          gotoActivity.status = status
          gotoActivity.startActualDate = gotoActivity.startActualDate || dateTimeNow
          gotoActivity.completionActualDate = gotoActivity.completionActualDate || dateTimeNow

          if (isDropBobtail) {
            businessActivity.status = status
            businessActivity.startActualDate = businessActivity.startActualDate || dateTimeNow
            businessActivity.completionActualDate = businessActivity.completionActualDate || dateTimeNow
          }
        } else {
          // business activity
          gotoActivity.status = status
          gotoActivity.startActualDate = gotoActivity.startActualDate || dateTimeNow
          gotoActivity.completionActualDate = gotoActivity.completionActualDate || dateTimeNow

          businessActivity.status = status
          businessActivity.startActualDate = businessActivity.startActualDate || dateTimeNow
          businessActivity.completionActualDate = businessActivity.completionActualDate || dateTimeNow
        }

        break
      }
      case TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL: {
        let allowDuplicate = true

        if (isGotoActivity) {
          // goto activity
          gotoActivity.status = status
          businessActivity.status = status
        } else {
          // business activity
          gotoActivity.status =
            gotoActivity.status === TransportationActivityViewDTO.StatusEnum.INPROCESS ||
            gotoActivity.status === TransportationActivityViewDTO.StatusEnum.COMPLETED
              ? TransportationActivityViewDTO.StatusEnum.COMPLETED
              : status
        }

        if (!isGotoActivity || isDropBobtail) {
          businessActivity.status = status
          gotoActivity.startActualDate = gotoActivity.startActualDate || dateTimeNow
          gotoActivity.completionActualDate = gotoActivity.completionActualDate || dateTimeNow
          businessActivity.startActualDate = businessActivity.startActualDate || dateTimeNow
          businessActivity.completionActualDate = businessActivity.completionActualDate || dateTimeNow
        }

        if (isDropBobtail) {
          const prevRow = getPreviousActivityRow(stageActivityRows, gotoActivity.id) as TransportationActivityRow

          if (prevRow && isBobtailGotoActivity(prevRow.activityGroup.gotoActivity)) {
            allowDuplicate = false
          }
        }

        updateActivityRow(
          { ...transportationRow, activityGroup: modifiedActivityGroup },
          allowDuplicate ? ActivityGroupAction.duplicate : ActivityGroupAction.update
        )

        if (isDropActivity) {
          const dropActivityBobtails = getActivityDropBobtailGroup({
            activity: transportationRow.activityGroup.gotoActivity,
            activities: dispatchDeliveryOrder.activities.transportationActivities
          })

          if (dropActivityBobtails.length) {
            const rowsToDelete = (stageActivityRows as TransportationActivityRow[])
              .filter(row => dropActivityBobtails.some(group => oc(group).gotoActivity.id() === row.id))
              .filter(Boolean)

            if (rowsToDelete.length) {
              setTimeout(() => updateActivityRow(rowsToDelete, ActivityGroupAction.remove))
            }
          }
        }

        return
      }
      default: {
        break
      }
    }

    updateActivityRow(
      {
        ...transportationRow,
        activityGroup: modifiedActivityGroup
      },
      ActivityGroupAction.update
    )
  }

  gotoActivityStatuses = isDropBobtail
    ? gotoActivityStatuses.filter(_ => {
        const omitStatuses = [TransportationActivityViewDTO.StatusEnum.NEW]

        return !omitStatuses.includes(_.value)
      })
    : (gotoActivityStatuses = gotoActivityStatuses.filter(
        _ => _.value !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
      ))

  if (isLinkedActivityToBobtail) {
    const availableStatuses = [
      TransportationActivityViewDTO.StatusEnum.DRIVERPLANNED,
      TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
      TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED
    ]

    if (isLinkedBobtailGotoActivityCompleted) {
      availableStatuses.push(
        TransportationActivityViewDTO.StatusEnum.INPROCESS,
        TransportationActivityViewDTO.StatusEnum.COMPLETED,
        TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
      )
    }

    businessActivityStatuses = activityLists.status.filter(_ => availableStatuses.includes(_.value))
  }

  if (
    isDropBobtail &&
    transportationRow.activityGroup.gotoActivity.comboType === TransportationActivityViewDTO.ComboTypeEnum.EMPTY &&
    !transportationRow.activityGroup.gotoActivity.destinationId
  ) {
    gotoActivityStatuses = []
    businessActivityStatuses = []
  }

  if (
    gotoActivityStatuses.length === 1 &&
    gotoActivityStatuses[0].value === transportationRow.activityGroup.gotoActivity.status
  ) {
    gotoActivityStatuses = []
  }

  if (
    businessActivityStatuses.length === 1 &&
    businessActivityStatuses[0].value === transportationRow.activityGroup.businessActivity.status
  ) {
    businessActivityStatuses = []
  }

  return (
    <DabbleColumn>
      <ActivityColumn
        data-id={[
          'go-to-activity-status',
          transportationRow.activityGroup.gotoActivity.status,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
        className={'border-bottom'}
      >
        {!isLinkedActivityToBobtail && (
          <Select
            disabled={
              !gotoActivityStatuses.length ||
              disabled ||
              ((transportationRow.activityGroup.businessActivity.status ===
                TransportationActivityViewDTO.StatusEnum.INPROCESS ||
                transportationRow.activityGroup.businessActivity.status ===
                  TransportationActivityViewDTO.StatusEnum.COMPLETED) &&
                !isDropBobtail) ||
              transportationRow.activityGroup.gotoActivity.status ===
                TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
            }
            list={gotoActivityStatuses}
            mapping={activityLists.status}
            selectedValue={transportationRow.activityGroup.gotoActivity.status}
            onSelect={updateStatus(true)}
          />
        )}
      </ActivityColumn>
      <ActivityColumn
        data-id={[
          'business-activity-status',
          transportationRow.activityGroup.businessActivity.status,
          'rowNumber-' + rowNumber,
          'stage-' + stage
        ].join(' ')}
      >
        {!isDropBobtail && (
          <Select
            disabled={
              !businessActivityStatuses.length ||
              isDropBobtail ||
              disabled ||
              transportationRow.activityGroup.businessActivity.status ===
                TransportationActivityViewDTO.StatusEnum.DRIVERREFUSED
            }
            list={businessActivityStatuses}
            mapping={activityLists.status}
            selectedValue={transportationRow.activityGroup.businessActivity.status}
            onSelect={updateStatus(false)}
          />
        )}
      </ActivityColumn>
    </DabbleColumn>
  )
}
