import { IUiSettingsSettingsStoreState } from '../../store/store.interface'
import * as R from 'remeda'
import { TabType } from '../../components/common/tabs/interfaces'
import { ViewingObjectState } from '../viewingObjects/interfaces'

export enum SessionStorage {
  tabs = 'tabs',
  tabsSettings = 'tabs-settings',
  viewingObjects = 'viewing-objects',
  tabScrollPosition = 'tab-scroll-position'
}

// TABS
const tabs = {
  clear: () => sessionStorage.removeItem(SessionStorage.tabs),
  set: (settings: IUiSettingsSettingsStoreState) =>
    sessionStorage.setItem(SessionStorage.tabs, JSON.stringify(settings)),
  get: (): IUiSettingsSettingsStoreState => JSON.parse(sessionStorage.getItem(SessionStorage.tabs))
}

// VIEWING OBJECTS
const GetViewingObjects = () => {
  return JSON.parse(sessionStorage.getItem(SessionStorage.viewingObjects))
}
const SetViewingObjects = (state?: ViewingObjectState) => {
  return sessionStorage.setItem(SessionStorage.viewingObjects, JSON.stringify(state || {}))
}

const viewingObjects = {
  clear: () => sessionStorage.removeItem(SessionStorage.viewingObjects),
  get: GetViewingObjects,
  set: SetViewingObjects
}

// TAB SCROLL POSITION
type TScrollPosition = { top: number; left: number }
type TTabScrollPosition = {
  [tabId: string]: TScrollPosition[]
}

const setTabScrollPosition = (settings: TTabScrollPosition) =>
  sessionStorage.setItem(SessionStorage.tabScrollPosition, JSON.stringify(settings))

const getTabScrollPosition = () => JSON.parse(sessionStorage.getItem(SessionStorage.tabScrollPosition))

const tabScrollPosition = {
  clear: () => sessionStorage.removeItem(SessionStorage.tabScrollPosition),
  set: setTabScrollPosition,
  get: getTabScrollPosition,
  removeTabPosition: (tabId: string) => {
    const storage = getTabScrollPosition()
    if (storage) {
      setTabScrollPosition(R.omit(storage, [tabId]))
    }
  },
  setTabPosition: (tabId: string, position: TScrollPosition) =>
    setTabScrollPosition({ ...(getTabScrollPosition() || {}), [tabId]: position }),
  getTabPosition: (tabId: string): TScrollPosition => {
    const storage = getTabScrollPosition()
    return (storage && storage[tabId]) || { top: 0, left: 0 }
  }
}

export const tabSessionStorage = {
  tabs,
  viewingObjects,
  scrollPosition: tabScrollPosition,
  clear: () => {
    viewingObjects.clear()
    tabScrollPosition.clear()
    tabs.clear()
  }
}

export const filteringStateByTabType = (tabType: TabType): boolean => {
  switch (tabType) {
    case TabType.dispatchDeliveryOrder:
    case TabType.container:
    case TabType.location:
    case TabType.customer:
    case TabType.steamShipLine:
    case TabType.driver:
      return true
    default:
      return false
  }
}

export const sortingStateByTabType = (tabType: TabType): boolean => {
  switch (tabType) {
    case TabType.dispatchDeliveryOrder:
    case TabType.container:
    case TabType.location:
    case TabType.customer:
    case TabType.steamShipLine:
      return true
    default:
      return false
  }
}

export const catchListErrors = (errors: any): any[] => {
  if (errors && errors.json) {
    errors.json()
  }

  return []
}
