import { deliveryOrderLists } from '../../select/deliveryOrderLists'
import { dispatchDeliveryOrderLists } from '../../select/dispatchDeliveryOrderLists'
import { DispatchDeliveryOrderColumn } from '../../../components/common/dispatchDeliveryOrder/views/configGrid'
import { regExpOnlyNumbersAndLetters } from '../../functions/regExp'
import { activityLists } from '../../select/activityLists'
import { GridHeaderColumnType } from '../../../components/common/grid/views/header/column'

export const dispatchDeliveryOrderFilterSettings = {
  [DispatchDeliveryOrderColumn.Notifications]: {
    name: 'number',
    type: GridHeaderColumnType.number,
    disabled: true
  },
  [DispatchDeliveryOrderColumn['DDO #']]: {
    name: 'number',
    type: GridHeaderColumnType.number
  },
  [DispatchDeliveryOrderColumn.ETA]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.vesselArrivalDate',
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn['DO #']]: {
    name: 'deliveryOrder.number',
    type: GridHeaderColumnType.number
  },
  [DispatchDeliveryOrderColumn.Status]: {
    name: 'status',
    type: GridHeaderColumnType.multiSelectList,
    items: dispatchDeliveryOrderLists.status
  },
  [DispatchDeliveryOrderColumn.Type]: {
    name: 'deliveryOrder.type',
    type: GridHeaderColumnType.list,
    items: deliveryOrderLists.type
  },
  [DispatchDeliveryOrderColumn.Equipment]: {
    name: 'containerType.code',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn.Container]: {
    name: 'container.number',
    type: GridHeaderColumnType.string,
    regExp: regExpOnlyNumbersAndLetters
  },
  [DispatchDeliveryOrderColumn.SSL]: {
    name: 'deliveryOrder.steamShipLine.name',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Load Type']]: {
    name: 'loadType',
    type: GridHeaderColumnType.list,
    items: dispatchDeliveryOrderLists.loadType
  },
  [DispatchDeliveryOrderColumn.Chassis]: {
    name: 'equipment.chassisNumber',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Booking / BL']]: {
    name: 'deliveryOrder.docNumberAndRefNumber',
    path: 'deliveryOrder.docNumber',
    type: GridHeaderColumnType.string,
    combinationOfValuePaths: ['deliveryOrder.docNumber', 'deliveryOrder.cargo.referenceNumber'],
    regExp: regExpOnlyNumbersAndLetters
  },
  [DispatchDeliveryOrderColumn.Customer]: {
    name: 'deliveryOrder.customer.name',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn.Pickup]: {
    name: 'pickupStage.location.longName',
    path: 'pickupStage.location.longName',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Pickup: Driver']]: {
    name: 'pickupStage.vendor.name',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn.Delivery]: {
    name: 'deliveryStage.activity.destination.longName',
    sort: 'deliveryStage.activity.destination.cityStateZip',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Delivery: Driver']]: {
    name: 'deliveryStage.activity.vendor.name',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Delivery: Activity']]: {
    name: 'deliveryStage.activity.type',
    items: activityLists.businessType,
    type: GridHeaderColumnType.list
  },
  [DispatchDeliveryOrderColumn['Activity Planned']]: {
    name: 'deliveryStage.activity.startPlannedDateTimeRange.dateFrom',
    path: 'deliveryStage.activity.startPlannedDateTimeRange.from',
    newDateTimePicker: true,
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn['Activity Completion']]: {
    name: 'deliveryStage.activity.completionActualDate',
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn.PickUpPlanned]: {
    name: 'pickupStage.plannedAppointmentDateTimeRange.dateFrom',
    path: 'pickupStage.plannedAppointmentDateTimeRange.from',
    type: GridHeaderColumnType.date,
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.Appointment]: {
    name: 'deliveryStage.appointmentDate',
    type: GridHeaderColumnType.date,
    combinationOfValuePaths: ['deliveryStage.appointmentDate', 'deliveryStage.pickDate'],
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.ReturnPlanned]: {
    name: 'returnStage.plannedAppointmentDateTimeRange.dateFrom',
    path: 'returnStage.plannedAppointmentDateTimeRange.from',
    type: GridHeaderColumnType.date,
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn['Appt Date Status']]: {
    name: 'deliveryStage.plannedAppointmentDateTimeRange.confirmed',
    type: GridHeaderColumnType.list,
    items: [
      {
        label: 'Confirmed',
        value: 'true'
      },
      {
        label: 'Not Confirmed',
        value: 'false'
      },
      {
        label: 'All',
        value: ''
      }
    ]
  },
  [DispatchDeliveryOrderColumn.Return]: {
    name: 'returnStage.location.longName',
    path: 'returnStage.location.longName',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Return: Driver']]: {
    name: 'returnStage.vendor.name',
    type: GridHeaderColumnType.string
  },
  [DispatchDeliveryOrderColumn['Days Out']]: {
    name: 'daysOut',
    type: GridHeaderColumnType.number
  },
  [DispatchDeliveryOrderColumn.Availability]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.equipmentFirstPickupDate',
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn['CutOff / LFD']]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.lastFreeDateAndGeneralCutoffDate',
    path: 'deliveryOrder.lastFreeDateDemurrage',
    combinationOfValuePaths: ['deliveryOrder.lastFreeDateDemurrage', 'deliveryOrder.generalCutoffDate'],
    type: GridHeaderColumnType.date
  },
  // [DispatchDeliveryOrderColumn.Cutoff]: {
  //   name: 'deliveryOrder.generalCutoffDate',
  //   type: GridHeaderColumnType.date,
  //   disabled: true
  // },
  [DispatchDeliveryOrderColumn['Per Diem Free by']]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.lastFreeDatePerDiem',
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn['First Receiving']]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.firstReceivingDate',
    type: GridHeaderColumnType.date
  },
  [DispatchDeliveryOrderColumn['DO created']]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.date',
    type: GridHeaderColumnType.date
  }
}
