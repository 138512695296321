import * as React from 'react'
import { IDeliveryOrder } from '../interfaces'

type TDeliveryOrderContext = {
  state: {
    deliveryOrder: IDeliveryOrder
    isModifiedMode: boolean
    isNew: boolean
    isImport: boolean
    isExport: boolean
    isRepo: boolean
  }
  functions: {
    modifyDeliveryOrder: (modifiedGridItem: any) => void
    modifyDeliveryOrderField: (field: keyof IDeliveryOrder) => (value: any) => void
  }
}

export const DeliveryOrderContext: React.Context<TDeliveryOrderContext> = React.createContext(null)
