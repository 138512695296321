import * as React from 'react'
import { AvailabilityColumn } from './AvailabilityColumn'
import { TGridColumn } from '../../../grid/views/interfaces'
import { NoWrap, TableCell } from '../../../grid/views/styles'

const Column = (props: TGridColumn) => {
  const { className, value, columnName, style } = props

  switch (columnName) {
    case 'Active / Availability':
      return (
        <TableCell style={{ ...style, overflow: 'visible' }} className={className}>
          <AvailabilityColumn unit={value} />
        </TableCell>
      )
    default:
      return (
        <TableCell className={className} title={String(value)} style={style}>
          <div>{value || ''}</div>
        </TableCell>
      )
  }
}

export default Column
