import * as React from 'react'
import { Container, GridColumn } from '../../../../UI/GridSystem'
import { Row } from '../styledComponents'
import { Delete } from '../../../../UI/styledComponents'
import { InputField, Input } from '../../../../UI/DataFields/Input'
import { Checkbox } from '../../../../UI/Checkbox'
import { connect } from 'react-redux'
import { TContact } from '../../interfaces'
import { PhoneValidationInput, EmailValidationInput } from '../../../../UI/Input/Validation'
import { oc } from 'ts-optchain'

type OwnProps = {
  contact: TContact
  modifyContact: (contact: TContact) => void
  togglePrimary: (isPrimary: boolean) => void
  deleteContact: () => void
}

type DispatchProps = {}

type StateProps = {}

type Props = OwnProps & DispatchProps & StateProps

const Contact = ({ contact, modifyContact, togglePrimary, deleteContact }: Props) => (
  <Row rows={9} margin={{ bottom: 10 }}>
    <Container columns={3} isFixed={true}>
      <GridColumn columns={2} isFixed={true} justifyCenter={true}>
        <Checkbox checked={oc(contact).primary(false)} onChange={togglePrimary} />
      </GridColumn>
    </Container>
    <Container columns={11} isFixed={true}>
      <Input
        placeholder={'First Name'}
        value={oc(contact).firstName('')}
        onChange={firstName => modifyContact({ ...contact, firstName })}
      />
    </Container>
    <Container columns={11} isFixed={true}>
      <Input
        placeholder={'Last Name'}
        value={oc(contact).lastName('')}
        onChange={lastName => modifyContact({ ...contact, lastName })}
      />
    </Container>
    <Container columns={9} isFixed={true}>
      <InputField.PhoneNumber
        placeholder={'Phone'}
        value={oc(contact).phone('')}
        onChange={phone => modifyContact({ ...contact, phone })}
      />
    </Container>
    <Container columns={9} isFixed={true}>
      <InputField.PhoneNumber
        placeholder={'Phone2'}
        value={oc(contact).phone2('')}
        onChange={phone2 => modifyContact({ ...contact, phone2 })}
      />
    </Container>
    <Container columns={11} isFixed={true}>
      <InputField.Email
        placeholder={'Email'}
        value={oc(contact).email('')}
        onChange={email => modifyContact({ ...contact, email })}
      />
    </Container>
    <Container columns={9} isFixed={true}>
      <InputField.PhoneNumber
        placeholder={'Fax'}
        value={oc(contact).fax('')}
        onChange={fax => modifyContact({ ...contact, fax })}
      />
    </Container>
    <Container columns={9}>{oc(contact).notes('')}</Container>
    <Delete className="mdi mdi-delete" columns={2} isFixed={true} onClick={() => deleteContact()} />
  </Row>
)

export default connect(
  null,
  null
)(Contact)
