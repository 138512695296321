import * as React from 'react'
import { Portal } from 'react-portal'
import { oc } from 'ts-optchain'
import {
  BottomSection,
  MiddleSection,
  StyledModalWindow,
  TopSection,
  WindowOverlay
} from '../../../../../UI/ModalWindow/views/styles'
import { useDriverDeductions } from '../../../../../../hooks/useDriverDeductions'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import { Input } from '../../../../../UI/DataFields/Input'
import { regExpOnlyNumbers } from '../../../../../../services/functions/regExp'
import { CloseIcon } from '../../../../../UI/icons/CloseIcon'
import { ViewingObjectFileUploader } from '../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { GridSpinner } from '../../../../../UI/Spinner/Spinner'
import { Select } from '../../../../../UI/DataFields/Select'
import { PaymentMethod } from '../../../../../../api/origin/vendor-accounting-service'
import { isDriverFieldRequired } from '../../../../../../services/functions/test/isDriverFieldRequired'

type Props = {}

export const BankingDetailsPopup = () => {
  const {
    driver,
    vendorAccounting,
    updateVendorAccountingField,
    setShowAccountingPopup,
    requestVendorAccounting
  } = useDriverDeductions()
  const [fetch, setFetch] = React.useState(false)

  React.useEffect(() => {
    let isExpiredData = false

    setFetch(true)

    requestVendorAccounting().finally(() => {
      if (!isExpiredData) {
        setFetch(false)
      }
    })

    return () => {
      isExpiredData = true
    }
  }, [])

  if (!vendorAccounting) {
    return null
  }

  const required = isDriverFieldRequired(driver).bankingDetails

  return (
    <Portal>
      <WindowOverlay>
        <StyledModalWindow style={{ minWidth: 350, maxWidth: 350, position: 'relative' }}>
          <TopSection>
            Banking Details <CloseIcon style={{ marginLeft: 'auto' }} onClick={() => setShowAccountingPopup(false)} />
          </TopSection>
          <MiddleSection style={{ position: 'relative' }}>
            {fetch && <GridSpinner />}
            <Select
              required={required}
              title={'Payment Method'}
              selectedValue={vendorAccounting.paymentMethod}
              list={[{ label: 'Check', value: PaymentMethod.CHECK }, { label: 'ACH', value: PaymentMethod.ACH }]}
              style={{ width: 230, margin: '0 0 16px 1px' }}
              onSelect={updateVendorAccountingField('paymentMethod')}
            />
            {vendorAccounting.paymentMethod === PaymentMethod.ACH && (
              <>
                <Input
                  required={required}
                  title={'Routing Code'}
                  regExp={regExpOnlyNumbers}
                  value={vendorAccounting.routingCode}
                  minLength={9}
                  maxLength={9}
                  onChange={updateVendorAccountingField('routingCode')}
                  styleFieldContainer={{ width: 230, margin: '0 0 16px 1px' }}
                />
                <Input
                  required={required}
                  title={'Account #'}
                  regExp={regExpOnlyNumbers}
                  value={vendorAccounting.accountNumber}
                  minLength={6}
                  maxLength={17}
                  onChange={updateVendorAccountingField('accountNumber')}
                  styleFieldContainer={{ width: 230, margin: '0 0 16px 1px' }}
                />
                <ViewingObjectFileUploader
                  required={required}
                  uploadingFileId={vendorAccounting.id + ' vendorAccounting.voidedCheck'}
                  title={'Copy of Voided Check'}
                  file={vendorAccounting.voidedCheck}
                  onFileUploaded={(voidedCheck, modifiedObject = driver.temporaryData.updatedVendorAccounting) => ({
                    ...modifiedObject,
                    temporaryData: {
                      ...oc(modifiedObject).temporaryData({}),
                      updatedVendorAccounting: {
                        ...oc(modifiedObject).temporaryData.updatedVendorAccounting({}),
                        voidedCheck
                      }
                    }
                  })}
                  onClearFile={() => updateVendorAccountingField('voidedCheck')(null)}
                />
              </>
            )}
          </MiddleSection>
          <BottomSection style={{ justifyContent: 'flex-end' }}>
            <ActionButton round={true} filled={true} onClick={() => setShowAccountingPopup(false)}>
              Ok
            </ActionButton>
          </BottomSection>
        </StyledModalWindow>
      </WindowOverlay>
    </Portal>
  )
}
