import { correctInsertionObjectsIntoStorage } from '../../index'
import { getStore } from '../../../../store/configureStore'
import { addBSQsToStore, deleteBSQIds } from '../actions'
import { BuySideQuoteDTO } from '../../../../api/origin/business-logic'

export const tryAddBSQsToStore = (items: BuySideQuoteDTO | BuySideQuoteDTO[], deleteIds?: string[]) => {
  const { getState, dispatch } = getStore()
  const buySideQuotes = getState().buySideQuotes
  correctInsertionObjectsIntoStorage(buySideQuotes, items, addBSQsToStore)

  if (deleteIds && deleteIds.some(id => buySideQuotes[id])) {
    dispatch(deleteBSQIds(deleteIds))
  }
}
