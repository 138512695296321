import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import {
  // ContainerTypeDTO,
  // DateISOString,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO
  // EquipmentDTO
} from '../../api/api'
import { assembleDTO } from '../../services/DTO/assemble'
import { compareDates } from '../../services/timeService/dateUtils'
import { IStore } from '../../store/store.interface'
// import { IContainer } from '../common/containers/interfaces'
import { getDispatchDeliveryOrderById } from '../common/dispatchDeliveryOrder/epics'
import { IDispatchDeliveryOrder } from '../common/dispatchDeliveryOrder/interfaces'
import { getEquipmentById } from '../common/equipment/epics'
// import { IEquipment } from '../common/equipment/interfaces'
import { FieldContainer } from '../UI/DataFields/FieldContainer'
import { Input } from '../UI/DataFields/Input'
import { ContainerSearch, EquipmentSearch } from '../UI/DataFields/SearchRequest'
import { FieldTemplate } from '../UI/DataFields/Templates'
import DateField from '../UI/DatePicker/DateField'
import { DateTypes } from '../UI/DatePicker/Interfaces'
import { showModalWindow, closeModalWindow } from '../../store/reducers/modalWindow/functions'
import { GridSpinner } from '../UI/Spinner/Spinner'
import { StreetTurnTable } from './StreetTurnTable'

type OwnProps = {
  dispatchDeliveryOrderId: string
  // modify?: {
  //   dispatchDeliveryOrder(fieldName: keyof IDispatchDeliveryOrder): (value: any) => void
  //   containerType(value?: ContainerTypeDTO): void
  //   container(container: IContainer): void
  //   equipment(equipment: IEquipment): void
  // }
  // isModifiedMode?: boolean
  // isChassisNumberRequired?: boolean
  // enableEditDDO?: boolean
  // enableStreetTurnActions?: boolean
}

type StateProps = {
  dispatchDeliveryOrder: IDispatchDeliveryOrder | undefined
}

type Props = OwnProps & StateProps

export const DispatchDeliveryOrderEquipmentPopupContent = connect(
  (store: IStore, { dispatchDeliveryOrderId }: OwnProps) => ({
    dispatchDeliveryOrder: assembleDTO.dispatchDeliveryOrder({ store, id: dispatchDeliveryOrderId })
  })
)(
  React.memo((props: Props) => {
    const { dispatchDeliveryOrderId, dispatchDeliveryOrder } = props
    const [fetching, setFetching] = React.useState(false)

    React.useEffect(() => {
      let mounted = true
      new Promise<DispatchDeliveryOrderViewDTO | IDispatchDeliveryOrder>(async resolve => {
        let resolvedDispatchDeliveryOrder = dispatchDeliveryOrder

        if (!dispatchDeliveryOrder) {
          if (mounted) {
            setFetching(true)
          }
          resolvedDispatchDeliveryOrder = await getDispatchDeliveryOrderById(dispatchDeliveryOrderId)
        }

        resolve(resolvedDispatchDeliveryOrder)
      })
        .then(async resolvedDispatchDeliveryOrder => {
          if (
            resolvedDispatchDeliveryOrder &&
            resolvedDispatchDeliveryOrder.equipment &&
            !resolvedDispatchDeliveryOrder.equipment.fullObject
          ) {
            if (mounted) {
              setFetching(true)
            }
            await getEquipmentById(equipment.id)
          }
        })
        .finally(() => {
          if (mounted) {
            setFetching(false)
          }
        })

      return () => {
        mounted = false
      }
    }, [])

    if (!dispatchDeliveryOrder) {
      return <GridSpinner />
    }

    const container = dispatchDeliveryOrder.container
    const equipment = dispatchDeliveryOrder.equipment
    const steamShipLineId = oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId()
    const size = dispatchDeliveryOrder.containerType
    // const containerNumber = oc(container).number()
    // const chassis = oc(equipment).chassisNumber()
    const days = oc(equipment).days()
    const pickupDate = oc(equipment).pickupDate()
    const returnDate = oc(equipment).returnDate()

    const isNewChassis = oc(equipment)
      .id('')
      .includes('newEquipment')

    const estimate = {
      calcDates: pickupDate && returnDate ? Math.ceil(Number(compareDates(pickupDate, returnDate, 'days'))) : '',
      placeholder: isNewChassis ? 'MM/DD/YYYY' : ' '
    }

    return (
      <>
        {fetching && <GridSpinner />}
        <Fields>
          <div>
            <FieldTemplate.ContainerTypeSearch
              disabled={true}
              title={'Container type'}
              required={true}
              containerType={size}
              onChange={undefined}
            />
          </div>
          <div>
            <ContainerSearch
              disabled={true}
              // allowCreate={true}
              required={dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT}
              title={'Container #'}
              placeholder={dispatchDeliveryOrder.containerNumberPlaceholder}
              onChange={undefined}
              value={container}
              steamShipLineId={steamShipLineId}
              containerTypeId={dispatchDeliveryOrder.containerTypeId}
            />
          </div>
          <div>
            <Input
              disabled={true}
              title={'Seal'}
              maxLength={20}
              value={dispatchDeliveryOrder.sealNumber}
              onChange={undefined}
            />
          </div>
          <div>
            <EquipmentSearch
              disabled={true}
              required={false}
              allowCreate={true}
              title={'Chassis #'}
              value={equipment}
              onChange={undefined}
            />
          </div>
          <div>
            <DateField
              showTime={true}
              disabled={true}
              title={'Chassis Pickup'}
              date={pickupDate}
              dateType={DateTypes.DateISOString}
              placeholder={estimate.placeholder}
              onUpdate={undefined}
            />
          </div>
          <div>
            <DateField
              showTime={true}
              disabled={true}
              title={'Chassis Return'}
              date={returnDate}
              dateType={DateTypes.DateISOString}
              placeholder={estimate.placeholder}
              onUpdate={undefined}
            />
          </div>
          <div>
            <FieldContainer wrapChild={true} title={'Chassis Days'} childrenStyle={{ height: 35, fontSize: 13 }}>
              {days || estimate.calcDates}
            </FieldContainer>
          </div>
        </Fields>

        {!dispatchDeliveryOrder.troubleTicketId && (
          <StreetTurnTable
            dispatchDeliveryOrder={dispatchDeliveryOrder}
            setFetching={setFetching}
            onLinkClick={closeModalWindow}
          />
        )}
      </>
    )
  })
)

export const showDispatchDeliveryOrderEquipmentPopup = (props: { dispatchDeliveryOrderId: string; title?: string }) => {
  const { dispatchDeliveryOrderId, title } = props

  showModalWindow({
    title: title || 'Equipment',
    content: <DispatchDeliveryOrderEquipmentPopupContent dispatchDeliveryOrderId={dispatchDeliveryOrderId} />,
    buttons: [
      {
        label: 'Close'
      }
    ]
  })
}

const Fields = styled.div`
  width: 1012px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;

  > div {
    width: 33%;
    padding: 0 16px;
    margin-bottom: 24px;
  }
`
