import { isNewObject } from '../index'
import { BusinessPartner } from '../../../components/common/businessPartner/types'
import { getStore } from '../../../store/configureStore'
import { combineContactIds } from '../saveDTO'
import * as R from 'remeda'
import { getContactsStatusSavePromise } from '../contact/save'
import { TModifyGridItem } from '../../uiSettingsService/tabs'
import { businessPartnerAPI, callAPI } from '../../../api/api'
import { TContact } from '../../../components/common/contact/interfaces'
import { getBusinessPartnerStatus } from './status'
import { oc } from 'ts-optchain'
import { parseDTO } from '../parseDTO'
import { processObject } from '../functions'

export const getBusinessPartnerSavePromise = (
  businessPartner: BusinessPartner,
  modifyGridItemProps?: TModifyGridItem
): Promise<BusinessPartner> => {
  if (!businessPartner) {
    return undefined
  }

  const { getState } = getStore()
  const storeBusinessPartner = getState().businessPartner[businessPartner.id]
  const { needToSave } = getBusinessPartnerStatus(businessPartner)

  return getContactsStatusSavePromise(businessPartner.contacts).then((contacts: TContact[]) => {
    const result: BusinessPartner = processObject({
      ...businessPartner,
      contactIds: combineContactIds(businessPartner.contactIds, contacts),
      primaryContactId: oc(contacts.find(_ => _.primary)).id()
    }).hardClean

    return (needToSave || !R.equals(storeBusinessPartner.contactIds, result.contactIds)
      ? (isNewObject(result)
          ? callAPI(businessPartnerAPI.create, R.omit(result, ['id', 'contacts', 'primaryContact']))
          : callAPI(businessPartnerAPI.update, R.omit(result, ['contacts', 'primaryContact']), result.id)
        ).toPromise()
      : Promise.resolve(result)
    ).then(data => {
      parseDTO.businessPartner(data)
      return data
    })
  })
}
