import * as React from 'react'
import cn from 'classnames'
import { TabType, TGridColumns } from '../../tabs/interfaces'
import { EmptyGrid, StyledGrid } from './styledComponents'
import { Table, TableRow } from './styles'
import { scrollGridItems, TableBody } from './TableBody'
import { GridSpinner } from '../../../UI/Spinner/Spinner'
import { FloatingAddButton } from '../../../UI/FloatingGridItems/FloatingAddButton'
import { FloatingGridItemsCounter } from '../../../UI/FloatingGridItems/FloatingGridItemsCounter'
import { TableHeader } from './TableHeader'
import { ViewingGridItem } from '../../../pages/GridItem/ViewingGridItem'
import { GridActions } from '../../../pages/GridActions'
import { tabSessionStorage } from '../../../../services/tabs/functions'
import { ApplicationSizeProvider } from '../../../../providers/ApplicationSizeProvider'
import { useGrid } from '../../../../hooks/useGrid'

type OwnProps = {
  gridItemIds: string[]
  viewingObjectId: string
  isModifiedMode: boolean
  isFetching: boolean
  columnsSettings: TGridColumns
  fetchGridItems: (withoutSpinner?: boolean) => void
}

type StateProps = {}
type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

const Grid = (props: Props) => {
  const gridRef = React.useRef(null)
  const { isFetching, fetchGridItems, viewingObjectId, isModifiedMode, gridItemIds, columnsSettings } = props
  const { tabType, tabId, actionsType, enableEditing, selectedIds, pinnedIds, disabledSelectIds } = useGrid()

  const gridLength = (gridItemIds && gridItemIds.length) || 0
  const isEmptyGrid = (!gridItemIds || !gridLength) && (!pinnedIds || !pinnedIds.length)
  const hideGrid = React.useMemo(() => viewingObjectId && isFullHeightExpandedGridItems(tabType), [viewingObjectId])

  React.useLayoutEffect(() => {
    if (!gridRef || !gridRef.current) {
      return
    }

    if (hideGrid) {
      scrollGridItems(0)
      gridRef.current.scrollTo(0, 0)
      return
    }

    if (isEmptyGrid) {
      const scrollLeft = tabSessionStorage.scrollPosition.getTabPosition(tabId).left
      scrollGridItems(0)
      gridRef.current.scrollTo(scrollLeft, 0)
      return
    }

    gridRef.current.scrollTo(0, 0)
  }, [isEmptyGrid, hideGrid])

  React.useLayoutEffect(() => {
    if (!gridRef || !gridRef.current) {
      return
    }

    if (viewingObjectId && !hideGrid) {
      scrollGridItems(tabSessionStorage.scrollPosition.getTabPosition(tabId).left)
    }
  }, [viewingObjectId])

  const showEmptyGridMessage = gridItemIds && isEmptyGrid && !isFetching
  const classNames = React.useMemo(
    () =>
      cn({
        emptyGrid: isEmptyGrid,
        scrollable: hideGrid
      }),
    [hideGrid, isEmptyGrid]
  )

  return (
    <StyledGrid ref={gridRef} className={classNames}>
      {Boolean(actionsType) && <GridActions actionsType={actionsType} fetchGridItems={fetchGridItems as any} />}

      {hideGrid && <TableHeader tabId={tabId} disallowFilter={true} scrollable={true} scrollToZero={false} />}

      {viewingObjectId && (
        <TableRow
          key={viewingObjectId}
          className={cn('table__row table__row_opened', { 'table__row_full-height': hideGrid })}
        >
          <ViewingGridItem
            columnsSettings={columnsSettings}
            tabType={tabType}
            tabId={tabId}
            enableEditing={enableEditing}
            isSelected={selectedIds.includes(viewingObjectId)}
            isDisabledSelect={disabledSelectIds.includes(viewingObjectId)}
            isPinned={pinnedIds.includes(viewingObjectId)}
            actionType={actionsType}
          />
        </TableRow>
      )}

      {!hideGrid && (
        <Table id={'grid'} className={'grid'}>
          <TableHeader tabId={tabId} scrollToZero={false} />
          {showEmptyGridMessage && <EmptyGrid>No results found</EmptyGrid>}
          {!isEmptyGrid && (
            <ApplicationSizeProvider rerenderOnResize={true}>
              <TableBody
                tabType={tabType}
                columnsSettings={columnsSettings}
                isModifiedMode={isModifiedMode}
                viewingObjectId={viewingObjectId}
                gridItemIds={gridItemIds}
              />
            </ApplicationSizeProvider>
          )}
        </Table>
      )}

      {!isModifiedMode && <FloatingAddButton tabId={tabId} unitType={tabType} />}
      {gridItemIds && <FloatingGridItemsCounter unitType={tabType} itemsNumber={gridItemIds.length} />}
      {isFetching && <GridSpinner />}
    </StyledGrid>
  )
}

const isFullHeightExpandedGridItems = (listType: TabType): boolean => {
  switch (listType) {
    case TabType.dispatchDeliveryOrder:
      return true
    default:
      return false
  }
}

export default Grid
