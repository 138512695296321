import * as React from 'react'
import { oc } from 'ts-optchain'
import { StyledColumns, VerticalSeparator } from '../styles'
import { SSQColumn } from './ssqColumn'
import { BSQColumn } from './bsqColumn'
import { IDispatchDeliveryOrder } from '../../../../../../common/dispatchDeliveryOrder/interfaces'
import { checkModifiedModeBeforeAction, fillQMPFiltersByDDOData, openRatesTab, bsqTypeGroups } from './functions'
import { BuySideQuoteRateDTO, SellSideQuoteRateDTO } from '../../../../../../../api/origin/qmp-service'
import {
  ActivitiesViewDTO,
  BuySideQuoteDTO,
  DateISOString,
  DeliveryOrderViewDTO,
  SellSideQuoteDTO,
  TransportationActivityViewDTO
} from '../../../../../../../api/origin/business-logic'
import { dateService } from '../../../../../../../services/timeService'
import { isNewId } from '../../../../../../../services/DTO'

type Props = {
  activities: ActivitiesViewDTO
  isModifiedMode: boolean
  profit: number
  sellSideQuoteTotalAmount: number
  buySideQuoteTotalAmount: number
  buySideQuotesAmount: Record<string, number>
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateSellSideQuote: (sellSideQuote: SellSideQuoteDTO) => void
  updateBuySideQuotes: (buySideQuotes: BuySideQuoteDTO[]) => void
  actionSave: () => void
}
export const Columns = (props: Props) => {
  const {
    activities,
    dispatchDeliveryOrder,
    updateSellSideQuote,
    updateBuySideQuotes,
    actionSave,
    isModifiedMode,
    buySideQuotesAmount,
    buySideQuoteTotalAmount,
    sellSideQuoteTotalAmount,
    profit
  } = props
  const transportationActivities = oc(dispatchDeliveryOrder)
    .activities.transportationActivities([])
    .filter(activity => !isNewId(activity.id))
  const filledFiltersByDDO = fillQMPFiltersByDDOData(dispatchDeliveryOrder)
  const filledFiltersByDDOForQMPTab = {
    ...filledFiltersByDDO,
    customerId: dispatchDeliveryOrder.deliveryOrder.customerId
      ? [
          {
            label:
              oc(dispatchDeliveryOrder as any).deliveryOrder.customer.businessPartner.legalName('') ||
              oc(dispatchDeliveryOrder).deliveryOrder.customer.name('name'),
            value: dispatchDeliveryOrder.deliveryOrder.customerId
          }
        ]
      : undefined
  }

  // >>> LINKS
  const checkPermissionToLeaveTab = checkModifiedModeBeforeAction(isModifiedMode, actionSave)
  const openRatesLink = (date: DateISOString) => (vendor?: { label: string; value: string }) => {
    return checkPermissionToLeaveTab(
      openRatesTab({
        filledFilters: {
          ...filledFiltersByDDOForQMPTab,
          status: [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED],
          vendorId: vendor ? [vendor] : undefined,
          loadType: oc(filledFiltersByDDOForQMPTab)
            .loadType([])
            .includes(SellSideQuoteRateDTO.LoadTypeEnum.DROPANDPICK)
            ? [
                ...filledFiltersByDDOForQMPTab.loadType,
                BuySideQuoteRateDTO.LoadTypeEnum.DROP,
                BuySideQuoteRateDTO.LoadTypeEnum.PICK
              ]
            : filledFiltersByDDOForQMPTab.loadType
        },
        extraData: {
          // effectiveDate: resetLocalDay(dispatchDeliveryOrder.deliveryOrder.date)
          effectiveDate: dateService.convertStringDate(date).startDay
        }
      })
    )
  }
  const openRateLink = (rateNumber: string) =>
    checkPermissionToLeaveTab(
      openRatesTab({
        filledFilters: {
          number: rateNumber,
          status: [SellSideQuoteRateDTO.StatusEnum.NEW, SellSideQuoteRateDTO.StatusEnum.EXPIRED]
        }
      })
    )
  // <<<

  const isReefer = oc(dispatchDeliveryOrder)
    .containerType.name('')
    .toLowerCase()
    .includes('refriger')
  const isNotReefer = oc(dispatchDeliveryOrder).containerType.name() && !isReefer

  const omitUnsuitableSurchargeTypes = [
    SellSideQuoteRateDTO.TypeEnum.BASE,
    SellSideQuoteRateDTO.TypeEnum.ALLIN,
    SellSideQuoteRateDTO.TypeEnum.FUEL
  ]

  if (
    transportationActivities.every(activity => activity.type !== TransportationActivityViewDTO.TypeEnum.BOBTAILGOTO)
  ) {
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.BOBTAIL)
  }
  if (!dispatchDeliveryOrder.hazmatIndicator) {
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.HAZMAT)
  }
  if (!dispatchDeliveryOrder.overweightIndicator) {
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.OVERWEIGHT)
  }
  if (!(dispatchDeliveryOrder.pickupStage.locationId || dispatchDeliveryOrder.returnStage.locationId)) {
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.TOLLS)
  }
  if (
    !(
      (dispatchDeliveryOrder.pickupStage.locationId &&
        dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT) ||
      (dispatchDeliveryOrder.returnStage.locationId &&
        dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.EXPORT)
    ) &&
    oc(dispatchDeliveryOrder)
      .buySideQuotes([])
      .some(quote => bsqTypeGroups.shuttle.includes(quote.type))
  ) {
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.PREPULL)
  }
  if ((dispatchDeliveryOrder as any).reeferIndicator === false || !isReefer) {
    // TODO use REEFER Indicator
    omitUnsuitableSurchargeTypes.push(SellSideQuoteRateDTO.TypeEnum.REEFER)
  }
  if (isReefer) {
    omitUnsuitableSurchargeTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTION)
  }
  if (isNotReefer) {
    omitUnsuitableSurchargeTypes.push(BuySideQuoteRateDTO.TypeEnum.DEDUCTIONREEFER)
  }

  return (
    <StyledColumns>
      <SSQColumn
        activities={activities}
        profit={profit}
        totalAmount={sellSideQuoteTotalAmount}
        dispatchDeliveryOrder={dispatchDeliveryOrder}
        filledFiltersByDDO={filledFiltersByDDO}
        transportationActivities={transportationActivities}
        updateSellSideQuote={updateSellSideQuote}
        openRatesLink={openRatesLink(oc(dispatchDeliveryOrder).sellSideQuote.date())}
        openRateLink={openRateLink}
        omitUnsuitableSurchargeTypes={omitUnsuitableSurchargeTypes}
      />
      <VerticalSeparator />
      <BSQColumn
        dispatchDeliveryOrder={dispatchDeliveryOrder}
        activities={activities}
        totalAmount={buySideQuoteTotalAmount}
        buySideQuotesAmount={buySideQuotesAmount}
        filledFiltersByDDO={filledFiltersByDDO}
        buySideQuoteList={dispatchDeliveryOrder.buySideQuotes || []}
        openRatesLink={openRatesLink}
        openRateLink={openRateLink}
        updateBuySideQuotes={updateBuySideQuotes}
        omitUnsuitableSurchargeTypes={omitUnsuitableSurchargeTypes}
      />
    </StyledColumns>
  )
}
