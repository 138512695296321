import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../../styles/theme'
import ReactTooltip from 'react-tooltip'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { communicationHubActions } from '../../../../store/reducers/communicationHub'
import { NotificationFilter } from '../../../../store/reducers/communicationHub/interfaces'
import { showModalWindow } from '../../../../store/reducers/modalWindow/functions'
import { NotificationsViewFiltersPopup } from './NotificationsViewFiltersPopup'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { selectNotificationFilters } from '../../../../store/select/notificationSelect'
import { NotificationDTO } from '../../../../api/origin/communication-hub-service'
import { notificationDirectory } from '../../../../services/DTO/notification/directory'
import { selectContacts } from '../../../../store/select/contactSelect'
// tslint:disable-next-line:max-line-length
import { generateInitialCommunicationHubState } from '../../../../store/reducers/communicationHub/functions/generateInitialCommunicationHubState'
import { NotificationTagHintId } from '../../../Hints/NotificationTagHint'
import { dateService } from '../../../../services/timeService'
import { driverNotificationStatuses } from '../../../../services/constants/driverStatuses'
import { driverDirectory } from '../../../../services/DTO/driver/directory'

export const NotificationsViewFilters = () => {
  const mountedRef = React.useRef(false)
  const dispatch = useAppDispatch()
  const filters = useAppSelector(selectNotificationFilters)
  const users = useAppSelector(selectContacts)
  const filterText = filters[NotificationFilter.text]
  const [searchTerm, setSearchTerm] = React.useState<string>(filterText)

  const removeFilter = (filter: NotificationFilter) => {
    let updateFilter: { filter: NotificationFilter; value: any }[] = [{ filter, value: undefined }]
    if ([NotificationFilter.startDate, NotificationFilter.endDate].includes(filter)) {
      updateFilter = [
        { filter: NotificationFilter.startDate, value: undefined },
        { filter: NotificationFilter.endDate, value: undefined }
      ]
    }

    dispatch(communicationHubActions.setNotificationFilter(updateFilter))

    ReactTooltip.hide()
  }

  React.useEffect(() => {
    if ((searchTerm || '') !== (filterText || '')) {
      setSearchTerm(filterText)
    }
  }, [filterText])

  React.useEffect(() => {
    if (!mountedRef.current) {
      return
    }

    const timer = setTimeout(() => {
      dispatch(communicationHubActions.setNotificationFilter([{ filter: NotificationFilter.text, value: searchTerm }]))
    }, 500)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [searchTerm])

  React.useEffect(() => {
    mountedRef.current = true
  }, [])

  const resetFilters = () => {
    const saveFilters = {
      [NotificationFilter.status]: filters[NotificationFilter.status],
      [NotificationFilter.text]: filters[NotificationFilter.text]
    }

    dispatch(
      communicationHubActions.setNotificationFilters({
        ...generateInitialCommunicationHubState().notifications.filters,
        ...saveFilters
      })
    )
    ReactTooltip.hide()
  }

  const onFilterButtonClick = React.useCallback(() => {
    showModalWindow({
      width: 600,
      title: 'Filter Notifications',
      closeButton: true,
      content: <NotificationsViewFiltersPopup />,
      buttons: [undefined]
    })
  }, [])

  const tags: { label: string; filterName: NotificationFilter }[] = React.useMemo(() => {
    const type = filters[NotificationFilter.type]
    const driverStatuses = filters[NotificationFilter.driverStatuses]
    const operatorIds = filters[NotificationFilter.senderUserIds]
    const driverIds = filters[NotificationFilter.recipientUserIds]
    const dates = {
      from: filters[NotificationFilter.startDate],
      to: filters[NotificationFilter.endDate]
    }
    const allDriverStatusesNumber = Object.values(driverNotificationStatuses).reduce(
      (acc, curr) => acc + curr.length,
      0
    )

    const typeTag = { label: notificationDirectory.type[type], filterName: NotificationFilter.type }
    const driverStatusesTag = {
      label:
        driverStatuses && driverStatuses.length
          ? 'Notify: ' +
            (allDriverStatusesNumber === driverStatuses.length
              ? 'All drivers'
              : driverStatuses.map(item => driverDirectory.status[item]).join(', '))
          : undefined,
      filterName: NotificationFilter.driverStatuses
    }
    const operatorsTag = {
      label:
        operatorIds && operatorIds.length
          ? 'Operators: ' +
            operatorIds
              .map(id => users[id])
              .filter(Boolean)
              .map(user => user.name)
              .join(', ')
          : undefined,
      filterName: NotificationFilter.senderUserIds
    }
    const driversTag = {
      label:
        driverIds && driverIds.length
          ? 'Drivers: ' +
            driverIds
              .map(id => users[id])
              .filter(Boolean)
              .map(user => user.name)
              .join(', ')
          : undefined,
      filterName: NotificationFilter.recipientUserIds
    }
    const datesTag = {
      label: '',
      filterName: NotificationFilter.startDate
    }

    if (dates && (dates.from || dates.to)) {
      if (dates.from && dates.to) {
        datesTag.label = [
          dateService.makeLabel(dates.from, { hideTime: true }),
          dateService.makeLabel(dates.to, { hideTime: true })
        ]
          .filter(Boolean)
          .join(' – ')
      } else if (dates.from) {
        datesTag.label = 'After ' + dateService.makeLabel(dates.from, { hideTime: true })
      } else if (dates.to) {
        datesTag.label = 'Before ' + dateService.makeLabel(dates.to, { hideTime: true })
      }
    }

    return [typeTag, driverStatusesTag, operatorsTag, driversTag, datesTag].filter(item => item.label)
  }, [filters, users])

  return (
    <Container>
      <FiltersBlock>
        <FiltersBlockHeader>
          <FilterButton className={'mdi mdi-tune'} onClick={onFilterButtonClick}>
            Filter
          </FilterButton>
          {Boolean(tags.length) && <ResetFiltersButton onClick={resetFilters}>Reset Filters</ResetFiltersButton>}
        </FiltersBlockHeader>

        {Boolean(tags.length) && (
          <Filters>
            {tags.map(tag => (
              <Filter key={tag.filterName} data-tip={tag.label} data-for={NotificationTagHintId}>
                {tag.label}
                <DeleteFilterButton className={'mdi mdi-close-circle'} onClick={() => removeFilter(tag.filterName)} />
              </Filter>
            ))}
          </Filters>
        )}
      </FiltersBlock>

      <SearchContainer>
        <Search
          value={searchTerm || ''}
          placeholder={'Search Notification by Subject or Text'}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {searchTerm ? (
          <SearchIcon className={'button mdi mdi-close-circle'} onClick={() => setSearchTerm(undefined)} />
        ) : (
          <SearchIcon className={'mdi mdi-magnify'} />
        )}

        {/* mdi-close-circle*/}
      </SearchContainer>

      <Tabs>
        {[
          NotificationDTO.StatusEnum.INPROGRESS,
          NotificationDTO.StatusEnum.COMPLETED,
          NotificationDTO.StatusEnum.DELETED
        ].map(status => {
          const active = filters[NotificationFilter.status] === status

          return (
            <Tab
              key={status}
              className={cn({ active })}
              onClick={
                active
                  ? undefined
                  : () =>
                      dispatch(
                        communicationHubActions.setNotificationFilter([
                          {
                            filter: NotificationFilter.status,
                            value: status
                          }
                        ])
                      )
              }
            >
              {notificationDirectory.status[status]}
            </Tab>
          )
        })}
      </Tabs>
    </Container>
  )
}

const Container = styled.div`
  background-color: #f5f6fa;
`

const FiltersBlock = styled.div`
  padding: 12px 16px;
`

const FiltersBlockHeader = styled.div`
  display: flex;
  align-items: center;
`

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  color: #445366;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  user-select: none;

  &:before {
    font-size: 20px;
    margin-right: 6px;
  }

  &:hover {
    color: ${theme.colors.basicBlueColor};
  }
`

const ResetFiltersButton = styled.div`
  font-size: 14px;
  color: ${theme.colors.basicBlueColor};
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
  user-select: none;
`

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px -3px -3px -3px;
`

const Filter = styled.div`
  position: relative;
  display: inline-block;
  background-color: #dadfff;
  border-radius: 20px;
  color: black;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 28px 5px 12px;
  margin: 3px;
  overflow: hidden;
  user-select: none;
`

const DeleteFilterButton = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: absolute;
  right: 6px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

const SearchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid white;
  padding: 9px 16px;
`

const SearchIcon = styled.i`
  color: #445366;
  font-size: 24px;
  position: absolute;
  right: 24px;

  &.button {
    font-size: 20px;
    cursor: pointer;

    &:not(:hover) {
      opacity: 0.5;
    }
  }
`

const Search = styled.input`
  flex-grow: 1;
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 1px solid rgba(198, 204, 227, 0.5);
  border-radius: 5px;
  color: black;
  padding: 0 36px 0 8px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }
`

const Tabs = styled.div`
  display: flex;
  padding: 0 16px;
`

const Tab = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  border-bottom: 2px solid transparent;
  padding: 3px 0 12px;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;

  &.active {
    color: ${theme.colors.basicBlueColor};
    border-color: ${theme.colors.basicBlueColor};
    cursor: default;
  }
`
