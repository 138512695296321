import { DriverViewDTO, OngoingActivityGroupDTO } from '../../../api/api'
import { IDriverPositionDTO } from '../../../services/DTO/driverPosition/interfaces'
import { UserDTO } from '../../../api/origin/user-service'
import { IMoveDTO } from '../communicationHub/interfaces'
import { IDriverActivityDTO } from '../../../services/DTO/driverActivity/interfaces'
import { RuleSearchDTO } from '../../../api/origin/qmp-service'

export enum ListEntityType {
  ongoingActivityDateStage = 'ongoingActivityDateStage',
  ongoingActivityDateStageNow = 'ongoingActivityDateStageNow',
  ongoingActivityDateDriver = 'ongoingActivityDateDriver',
  driverPosition = 'driverPosition',
  move = 'move',
  driver = 'driver',
  driverIdByAuthId = 'driverIdByAuthId',
  contact = 'contact',
  driverActivity = 'driverActivity',
  rule = 'rule'
}
interface IListEntity {
  [ListEntityType.ongoingActivityDateStage]: OngoingActivityGroupDTO
  [ListEntityType.ongoingActivityDateStageNow]: OngoingActivityGroupDTO
  [ListEntityType.ongoingActivityDateDriver]: OngoingActivityGroupDTO
  [ListEntityType.driverPosition]: IDriverPositionDTO
  [ListEntityType.move]: IMoveDTO
  [ListEntityType.driver]: DriverViewDTO
  [ListEntityType.driverIdByAuthId]: string // { id: string; value: string }
  [ListEntityType.contact]: UserDTO
  [ListEntityType.driverActivity]: IDriverActivityDTO
  [ListEntityType.rule]: RuleSearchDTO
}

export type IListsState = { [K in keyof IListEntity]: Record<string, IListEntity[K]> }
export interface IListsStateUpdate {
  update?: { [K in keyof IListEntity]?: IListEntity[K][] }
  delete?: { [K in keyof IListEntity]?: string[] }
}
