import * as React from 'react'
import { IconWithText, DarkButton, BlueButton, GreenButton } from './styles'
import { updateTabData, updateTab, removeTab } from '../../common/tabs/actions'
import { getStore } from '../../../store/configureStore'
import { toast } from 'react-toastify'
import { showModal, TMsgType } from '../../UI/Modal/actions'
import { AlertButtonColor } from '../../UI/Modal'
import {
  streetTurnRequests,
  getStreetTurnDispatchDeliveryOrderById,
  findAllStreetTurnDispatchDeliveryOrdersForGrid
} from '../../../services/DTO/streetTurn/epics'
import { oc } from 'ts-optchain'
import { DispatchDeliveryOrderGridItemDTO } from '../../../api/origin/business-logic'
import { getDispatchDeliveryOrderById } from '../../common/dispatchDeliveryOrder/epics'
import { getTabs } from '../../common/tabs/selectors'
import { useGrid } from '../../../hooks/useGrid'

type OwnProps = {
  fetchGridItems: (withoutSpinner?: boolean) => Promise<DispatchDeliveryOrderGridItemDTO[]>
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & StateProps & DispatchProps

export const StreetTurn = ({ fetchGridItems }: Props) => {
  const { tabId, tabData, listRequestProps, setGridFetching } = useGrid()
  const selectedIds: string[] = oc(tabData).selectedIds([])
  const hideUnselectedGridItems = oc(tabData).hideUnselectedGridItems(false)
  const { dispatch, getState } = getStore()
  const streetTurnRequested = tabData.streetTurnRequested
  const streetTurnConfirmed = tabData.streetTurnConfirmed
  const requestFromDDOId = listRequestProps.dispatchDeliveryOrderId
  const requestToDDOId = selectedIds.filter(id => id !== requestFromDDOId)[0]
  const ddoForStreetTurnRequest = getState().dispatchDeliveryOrder[requestToDDOId]

  React.useEffect(() => {
    if (hideUnselectedGridItems && oc(selectedIds).length() === 1) {
      dispatch(
        updateTabData({
          tabId,
          options: { hideUnselectedGridItems: false }
        })
      )
    }
  }, [oc(selectedIds).length()])

  const getDispatchDeliveryOrdersById = async () => {
    return Promise.all([requestFromDDOId, requestToDDOId].filter(Boolean).map(id => getDispatchDeliveryOrderById(id)))
  }

  const getMainDispatchDeliveryOrdersById = async () => {
    return getDispatchDeliveryOrderById(requestFromDDOId)
  }

  const getCurrentTab = React.useCallback(() => {
    return getTabs(getStore().getState()).find(_ => _.id === tabId)
  }, [])

  const requestStreetTurn = () => {
    const streetTurnId = oc(ddoForStreetTurnRequest).temporaryData.streetTurn.id()
    if (!streetTurnId) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not request Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    streetTurnRequests
      .submit({ streetTurnId })
      .then(getDispatchDeliveryOrdersById)
      .then(() => {
        const currentTab = getCurrentTab()
        dispatch(
          updateTab({
            tab: {
              ...currentTab,
              alwaysDoListRequestOnTabEnter: false,
              data: { ...currentTab.data, disabledSelectIds: selectedIds, streetTurnRequested: true },
              gridItemIds: []
            }
          })
        )
        toast.success(
          <>
            <i className={'mdi mdi-check'} />
            Street Turn Requested
          </>
        )
      })
      .catch(() => {
        setGridFetching(false)
        const closeTab = () => dispatch(removeTab({ id: tabId }))
        const refreshTab = () => {
          const currentTab = getCurrentTab()
          dispatch(
            updateTab({
              tab: {
                ...currentTab,
                data: {
                  ...currentTab.data,
                  selectedIds: [requestFromDDOId],
                  pinnedIds: [requestFromDDOId],
                  disabledSelectIds: [requestFromDDOId],
                  streetTurnRequested: false
                }
              }
            })
          )
          fetchGridItems()
        }

        dispatch(
          showModal({
            msgType: TMsgType.info,
            buttonSettings: {
              button1: {
                color: AlertButtonColor.yellow,
                label: 'Close Tab',
                action: closeTab
              },
              button2: {
                color: AlertButtonColor.blue,
                label: 'Refresh Tab',
                action: refreshTab
              }
            },
            message: 'Street Turn Request Error'
          })
        )
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  const confirmStreetTurnRequest = () => {
    const streetTurnIdToApprove = ddoForStreetTurnRequest.streetTurnId

    if (!streetTurnIdToApprove) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not confirm Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    streetTurnRequests
      .approve({ streetTurnId: streetTurnIdToApprove })
      .then(getDispatchDeliveryOrdersById)
      .then(() => {
        const currentTab = getCurrentTab()
        dispatch(
          updateTab({
            tab: {
              ...currentTab,
              alwaysDoListRequestOnTabEnter: false,
              data: { ...currentTab.data, streetTurnRequested: false, streetTurnConfirmed: true },
              gridItemIds: []
            }
          })
        )
        toast.success(
          <>
            <i className={'mdi mdi-check'} />
            Street Turn Confirmed
          </>
        )
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.log(error)
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  const cancelStreetTurnRequest = () => {
    const streetTurnIdToCancel = ddoForStreetTurnRequest.streetTurnId

    if (!streetTurnIdToCancel) {
      toast.error(
        <>
          <i className={'mdi mdi-close'} />
          <div>
            <div>Error</div>
            <div>Can not cancel Street Turn</div>
          </div>
        </>
      )
      // tslint:disable-next-line:no-console
      console.log('Error', ddoForStreetTurnRequest)
      return
    }

    setGridFetching(true)

    let gridItemIds: string[] = []

    streetTurnRequests
      .cancel({ streetTurnId: streetTurnIdToCancel })
      .then(() => {
        return findAllStreetTurnDispatchDeliveryOrdersForGrid({ dispatchDeliveryOrderId: requestFromDDOId })
      })
      .then(ddoList => {
        gridItemIds = ddoList.map(({ id }) => id)
      })
      .then(() => {
        const viewingObject = getStore().getState().viewingObjects[tabId]
        return Promise.all([
          getMainDispatchDeliveryOrdersById(),
          oc(viewingObject).id() && viewingObject.id !== requestFromDDOId
            ? getStreetTurnDispatchDeliveryOrderById(viewingObject.id)
            : undefined
        ])
      })
      .then(() => {
        const currentTab = getCurrentTab()
        dispatch(
          updateTab({
            tab: {
              ...currentTab,
              alwaysDoListRequestOnTabEnter: true,
              gridItemIds,
              data: {
                ...currentTab.data,
                selectedIds: [requestFromDDOId],
                pinnedIds: [requestFromDDOId],
                disabledSelectIds: [requestFromDDOId],
                streetTurnRequested: false,
                streetTurnConfirmed: false
              }
            }
          })
        )
      })
      .catch(error => {
        // tslint:disable-next-line:no-console
        console.log(error)
      })
      .finally(() => {
        setGridFetching(false)
      })
  }

  return (
    <>
      {!ddoForStreetTurnRequest && (
        <IconWithText className={'mdi mdi-information'}>Select one of possible matches</IconWithText>
      )}
      {ddoForStreetTurnRequest && !streetTurnRequested && !streetTurnConfirmed && (
        <BlueButton className={'mdi mdi-compare-horizontal'} onClick={requestStreetTurn}>
          Request Street Turn
        </BlueButton>
      )}
      {streetTurnRequested && (
        <GreenButton className={'mdi mdi-check-circle'} onClick={confirmStreetTurnRequest}>
          Confirm Street Turn
        </GreenButton>
      )}
      {streetTurnRequested && (
        <DarkButton className={'mdi mdi-close-circle'} onClick={cancelStreetTurnRequest}>
          Cancel Request
        </DarkButton>
      )}
      {streetTurnConfirmed && (
        <DarkButton className={'mdi mdi-close-circle'} onClick={cancelStreetTurnRequest}>
          Cancel Street Turn
        </DarkButton>
      )}
    </>
  )
}
