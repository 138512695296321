import * as React from 'react'
import { connect } from 'react-redux'
import { Container, ColumnRow } from '../../../../../UI/GridSystem'
import { ISubClient } from '../../../interfaces'
import { Title } from './styledComponents'
import { Input } from '../../../../../UI/DataFields/Input'
import { AddressDTO, CustomerViewDTO } from '../../../../../../api/origin/business-logic/api'
import AddressField from '../../../../../UI/AddressField'
import { CustomerSearch } from '../../../../../UI/DataFields/SearchRequest'
import { oc } from 'ts-optchain'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'

type OwnProps = {
  subClient: ISubClient
  actions: IGridItemActions
}

type DispatchProps = {}

type Props = OwnProps & DispatchProps

export const CustomerInfo = ({ subClient, actions: { modify, modifyParentObjectField } }: Props) => (
  <Container isGrid={true} margin={{ right: 40 }}>
    <Title>Customer Info</Title>
    <ColumnRow margin={{ bottom: 25 }}>
      <ColumnRow columns={20} flexBasis={'auto'} margin={{ right: 25 }}>
        <Input
          title={'Name'}
          required={true}
          value={subClient.businessPartner.legalName}
          onChange={(legalName: string) =>
            modifyParentObjectField('businessPartner')({ ...subClient.businessPartner, legalName })
          }
        />
      </ColumnRow>
      <ColumnRow columns={10} flexBasis={'auto'}>
        <CustomerSearch
          title={'Customer'}
          required={true}
          value={subClient.customer}
          onChange={(customer: CustomerViewDTO) => {
            modify({ ...subClient, customer, customerId: oc(customer).id() })
          }}
        />
      </ColumnRow>
    </ColumnRow>

    <ColumnRow>
      <ColumnRow columns={30} flexBasis={'auto'}>
        <AddressField
          title={'Address Search'}
          required={true}
          address={subClient.businessPartner.legalAddress}
          onChange={(legalAddress: AddressDTO) =>
            modifyParentObjectField('businessPartner')({ ...subClient.businessPartner, legalAddress })
          }
        />
      </ColumnRow>
    </ColumnRow>
  </Container>
)
