import { assembleBusinessPartner } from './businessPartner'
import { assembleLocation } from './location'
import { assembleSteamShipLine } from './steamShipLine'
import { assembleContainer } from './container'
import { assembleSubClient } from './subClient'
import { assembleCustomer } from './customer'
import { assembleDeliveryOrder } from './deliveryOrder'
import { assembleActivity } from './activity'
import { assembleDispatchDeliveryOrder } from './dispatchDeliveryOrder'
import { TabType } from '../../../components/common/tabs/interfaces'
import { IStore } from '../../../store/store.interface'
import { isNewId } from '../index'
import { createEntity } from '../createNewDTO'
import { assembleDriver } from './driver'
import { RootState } from '../../../store'

export const assembleDTO = {
  [TabType.driver]: assembleDriver,
  [TabType.location]: assembleLocation,
  [TabType.steamShipLine]: assembleSteamShipLine,
  [TabType.container]: assembleContainer,
  [TabType.customer]: assembleCustomer,
  [TabType.subClient]: assembleSubClient,
  [TabType.dispatchDeliveryOrder]: assembleDispatchDeliveryOrder,
  deliveryOrder: assembleDeliveryOrder,
  activity: assembleActivity,
  businessPartner: assembleBusinessPartner
}

export const getGridItem = ({
  store,
  newStore,
  tabType,
  id,
  simplify
}: {
  store: IStore
  tabType: TabType
  id: string
  newStore?: RootState
  simplify?: boolean
}) => {
  if (isNewId(id)) {
    return createEntity(tabType)
  }

  switch (tabType) {
    case TabType.driver:
    case TabType.location:
    case TabType.steamShipLine:
    case TabType.container:
    case TabType.customer:
    case TabType.subClient:
    case TabType.dispatchDeliveryOrder:
      return assembleDTO[tabType]({ store, id, newStore, simplify })
    default:
      return store[tabType][id]
  }
}
