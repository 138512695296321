import { oc } from 'ts-optchain'
import { ILocation } from '../../../components/common/location/interfaces'
import { debuggingMode } from '../../debug'
import { schemaLocationView } from '../../yupScheme'
import { isContactValid } from './isContactValid'

export const isLocationValid = (location: ILocation): boolean => {
  if (debuggingMode.common) {
    schemaLocationView.validate(location).catch(e => {
      // getStore().dispatch(
      //   addServerMessage({
      //     type: 'error',
      //     message: e.message
      //   })
      // )
      // tslint:disable-next-line:no-console
      console.log('checkLocationValidation', e)
    })
  }

  return (
    oc(location)
      .contacts([])
      .every(isContactValid) && schemaLocationView.isValidSync(location)
  )
}
