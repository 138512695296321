import Contact from './contact/'
import * as React from 'react'
import { Container } from '../../../UI/GridSystem'
import { ColumnTitles, AddRow } from './styledComponents'
import { TContact } from '../interfaces'
import { createNewContact } from '../reducers'
import { callAPI, contactAPI, ContactDTO } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { isNewId } from '../../../../services/DTO'
import { tryAddContactToStore } from '../epics'

type OwnProps = {
  isModified: boolean
  primaryContactId: string
  contactIds: string[]
  contacts: TContact[]
  modifyContacts: ({ contacts, contactIds }: { contacts: TContact[]; contactIds: string[] }) => void
  togglePreviewSpinner: (state: boolean) => void
}

type StateProps = {}

type DispatchProps = {}

type Props = OwnProps & DispatchProps & StateProps

const extraContainerStyles = { overflow: 'auto scroll' }

const ContactsPreviewTab: React.FC<Props> = ({
  isModified,
  primaryContactId,
  contactIds,
  contacts,
  modifyContacts,
  togglePreviewSpinner
}: Props) => {
  if (!contacts) {
    return null
  }

  const modify = (updatedContacts: TContact[], updatedContactIds: string[] = contactIds) =>
    modifyContacts({ contacts: updatedContacts, contactIds: updatedContactIds })

  React.useEffect(() => {
    if (contactIds.length) {
      const store = getStore().getState()
      const getContactIds = contactIds.reduce((acc, currId) => {
        if (!isNewId(currId) && !store.contact[currId]) {
          return [...acc, currId]
        }

        return acc
      }, [])

      if (getContactIds.length) {
        togglePreviewSpinner(true)

        Promise.all(getContactIds.map(id => callAPI(contactAPI.findById, id).toPromise())).then(receivedContacts => {
          tryAddContactToStore(receivedContacts)
          if (isModified) {
            modify([...(contacts || []), ...receivedContacts])
          }
          togglePreviewSpinner(false)
        })
      }
    }
  }, [contactIds])

  return (
    <Container
      style={extraContainerStyles}
      isGrid={true}
      background={'#f9f9f9'}
      rows={70}
      padding={{ top: 20, left: 20, right: 20 }}
    >
      {Boolean(contactIds.length || contacts.length) && (
        <ColumnTitles rows={4} padding={{ right: 40 }} margin={{ bottom: 10 }}>
          <Container columns={3} isFixed={true}>
            Primary
          </Container>
          <Container columns={11} isFixed={true}>
            First Name
          </Container>
          <Container columns={11} isFixed={true}>
            Last Name
          </Container>
          <Container columns={9} isFixed={true}>
            Phone
          </Container>
          <Container columns={9} isFixed={true}>
            Phone 2
          </Container>
          <Container columns={11} isFixed={true}>
            Email
          </Container>
          <Container columns={9} isFixed={true}>
            Fax
          </Container>
          <Container columns={9}>Notes</Container>
        </ColumnTitles>
      )}

      {Boolean(contacts) &&
        contacts.map(contact => (
          <Contact
            key={contact.id}
            contact={contact}
            modifyContact={(updatedContact: ContactDTO) =>
              modify(contacts.map(item => (item.id === updatedContact.id ? updatedContact : item)))
            }
            togglePrimary={(isPrimary: boolean) =>
              modify(contacts.map(item => ({ ...item, primary: item.id === contact.id ? isPrimary : false })))
            }
            deleteContact={() =>
              modify(
                contacts.filter(item => item.id !== contact.id),
                contactIds.filter(contactId => contactId !== contact.id)
              )
            }
          />
        ))}
      <AddRow
        rows={9}
        alignCenter={true}
        justifyCenter={true}
        margin={{ bottom: 20 }}
        onClick={() => {
          const createdContact = createNewContact()
          return modify([...(contacts || []), createdContact])
        }}
      >
        Add Contact
      </AddRow>
    </Container>
  )
}

export default ContactsPreviewTab
