import * as React from 'react'
import { IDispatchDeliveryOrder } from '../../../interfaces'
import { StreetTurnActionButton, StreetTurnOutlineActionButton, StreetTurnSection, StreetTurnButton } from './styles'
import { getStreetTurnStatus } from '../../../../../../services/DTO/dispatchDeliveryOrder/functions'
import { DispatchDeliveryOrderStreetTurnDTO, EquipmentDTO } from '../../../../../../api/origin/business-logic'
import { streetTurnRequests } from '../../../../../../services/DTO/streetTurn/epics'
import { oc } from 'ts-optchain'
import { Widget } from '../../../../../UI/Widget'
import { DDOContext } from '../../../../../../services/DTO/dispatchDeliveryOrder/context'
import { Popover as EquipmentPopover } from '../../../../../UI/Widget/widgets/equipment/popover'
import { isNewObject } from '../../../../../../services/DTO'
import { requestDispatchDeliveryOrdersByIds } from '../../../epics'
import { ddoDirectory } from '../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { CustomDDOHeader } from './index'

export const StreetTurn = (props: CustomDDOHeader) => {
  const { dispatchDeliveryOrder, isModified, actions } = props
  const streetTurnStatus = getStreetTurnStatus({
    streetTurn: oc(dispatchDeliveryOrder).streetTurn(),
    streetTurnCount: oc(dispatchDeliveryOrder).streetTurnCount()
  })
  const disable = actions.enableEditing === false

  return (
    <>
      {Boolean(streetTurnStatus && !dispatchDeliveryOrder.troubleTicketId) && (
        <StreetTurnSection>
          <StreetTurnWidget {...{ ...props, streetTurnStatus }} />
          {!isModified && !disable && (
            <StreetTurnActions
              streetTurn={dispatchDeliveryOrder.streetTurn}
              setFetching={actions && actions.setFetching}
            />
          )}
        </StreetTurnSection>
      )}
    </>
  )
}

export const StreetTurnWidget = ({
  dispatchDeliveryOrder,
  actions,
  isModified,
  streetTurnStatus
}: CustomDDOHeader & { streetTurnStatus: DispatchDeliveryOrderStreetTurnDTO.StatusEnum }) => {
  let disabledEditDDO = true
  let disabledStreetTurn = false
  let chassisNumber, updateContainerType, updateContainer

  if (actions) {
    disabledEditDDO = actions.enableEditing === false
    disabledStreetTurn = disabledEditDDO
    const ddoContextProps = React.useContext(DDOContext)
    chassisNumber = ddoContextProps.state.required.chassisNumber
    updateContainer = ddoContextProps.functions.updateContainer
    updateContainerType = ddoContextProps.functions.updateContainerType
  }

  const container = dispatchDeliveryOrder.container
  const equipment = dispatchDeliveryOrder.equipment

  const equipmentProps = {
    container,
    equipment: equipment as EquipmentDTO,
    steamShipLineId: oc(dispatchDeliveryOrder).deliveryOrder.steamShipLineId(null),
    size: dispatchDeliveryOrder.containerType,
    containerNumber: oc(container).number(''),
    chassis: oc(equipment).chassisNumber(''),
    days: oc(equipment).days(null),
    pickupDate: oc(equipment).pickupDate(null),
    returnDate: oc(equipment).returnDate(null)
  }

  return (
    <Widget
      title={'Equipment'}
      isTableWidget={true}
      RenderPreview={StreetTurnPreview}
      RenderDetails={EquipmentPopover}
      previewProps={{ streetTurnStatus, streetTurnCount: oc(dispatchDeliveryOrder).streetTurnCount(0) }}
      detailsProps={{
        disabledEditDDO,
        disabledStreetTurn,
        isModifiedMode: isModified,
        equipmentProps,
        updateContainerType,
        dispatchDeliveryOrder,
        updateDispatchDeliveryOrder: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) =>
          actions.modify({ ...dispatchDeliveryOrder, [fieldName]: value }),
        updateEquipment: (updatedEquipment: EquipmentDTO) =>
          actions.modify({
            ...dispatchDeliveryOrder,
            equipment: updatedEquipment,
            equipmentId: updatedEquipment && !isNewObject(updatedEquipment) ? updatedEquipment.id : null
          }),
        updateContainer,
        isChassisNumberRequired: chassisNumber
      }}
    />
  )
}

const StreetTurnPreview = ({
  streetTurnStatus,
  streetTurnCount
}: {
  streetTurnStatus: DispatchDeliveryOrderStreetTurnDTO.StatusEnum
  streetTurnCount: number
}) => {
  return (
    <StreetTurnButton
      className={'mdi mdi-shuffle-variant'}
      style={{ backgroundColor: ddoDirectory.streetTurnStatusColor[streetTurnStatus] }}
    >
      {streetTurnStatus === DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE && streetTurnCount}
      <span>ST {ddoDirectory.streetTurnStatusLabel[streetTurnStatus]}</span>
    </StreetTurnButton>
  )
}

export const StreetTurnActions = (props: {
  streetTurn: DispatchDeliveryOrderStreetTurnDTO
  setFetching: ((state: boolean) => void) | undefined
  isMinimizedButtons?: boolean
}) => {
  const { streetTurn, isMinimizedButtons, setFetching } = props
  const streetTurnStatus = oc(streetTurn).status()
  const ActionButton = isMinimizedButtons ? StreetTurnOutlineActionButton : StreetTurnActionButton

  const doFetch = (action: any) => () => {
    if (setFetching) {
      setFetching(true)
    }

    const { ddoImportId, ddoExportId } = streetTurn
    const streetTurnDDOs = [ddoImportId, ddoExportId].filter(Boolean)

    action({ streetTurnId: streetTurn.id })
      .then(() => requestDispatchDeliveryOrdersByIds(streetTurnDDOs))
      .finally(() => {
        if (setFetching) {
          setFetching(false)
        }
      })
  }

  switch (streetTurnStatus) {
    case DispatchDeliveryOrderStreetTurnDTO.StatusEnum.SUBMITTED:
      return (
        <>
          <ActionButton className={'approve mdi mdi-check'} onClick={doFetch(streetTurnRequests.approve)} />
          <ActionButton className={'reject mdi mdi-close'} onClick={doFetch(streetTurnRequests.reject)} />
          <ActionButton className={'cancel'} onClick={doFetch(streetTurnRequests.cancel)} />
        </>
      )
    case DispatchDeliveryOrderStreetTurnDTO.StatusEnum.APPROVED:
      return <ActionButton className={'cancel'} onClick={doFetch(streetTurnRequests.cancel)} />
    default:
      return null
  }
}
