import { DriverDTO } from '../../api/api'

export const groupedDriverStatuses = {
  Active: [
    DriverDTO.StatusEnum.AVAILABLE,
    DriverDTO.StatusEnum.NOTAVAILABLEASSIGNED,
    DriverDTO.StatusEnum.NOTAVAILABLEINUSE
  ],
  Inactive: [
    DriverDTO.StatusEnum.INACTIVEOUTOFDRIVINGHOURS,
    DriverDTO.StatusEnum.INACTIVEEXPIREDCREDENTIALS,
    DriverDTO.StatusEnum.INACTIVENODRUGTEST,
    DriverDTO.StatusEnum.INACTIVEDOTSTOP,
    DriverDTO.StatusEnum.INACTIVENOBIINSPECTION,
    DriverDTO.StatusEnum.INACTIVENOQUARTERLYINSPECTION,
    DriverDTO.StatusEnum.INACTIVEHOSVIOLATION,
    DriverDTO.StatusEnum.INACTIVENOPAPERLOG,
    DriverDTO.StatusEnum.POWERUNITISINACTIVE,
    DriverDTO.StatusEnum.TERMINATED
  ],
  Candidate: [
    DriverDTO.StatusEnum.CANDIDATEREJECTED,
    DriverDTO.StatusEnum.CANDIDATE,
    DriverDTO.StatusEnum.CANDIDATEREVIEW,
    DriverDTO.StatusEnum.CANDIDATEAPPROVED
  ]
}

export const driverNotificationStatuses = {
  ...groupedDriverStatuses,
  Inactive: groupedDriverStatuses.Inactive.filter(_ => _ !== DriverDTO.StatusEnum.TERMINATED)
}
