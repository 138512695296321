import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { addEquipmentsToStore } from '../action'
import { IEquipmentState, IEquipment } from '../interfaces'
import { EquipmentDTO } from '../../../../api/origin/business-logic'

export const newEquipment: IEquipment = {
  fullObject: true,
  active: true,
  id: 'newEquipment',
  inactiveReason: null,
  availability: true,
  unavailabilityReason: null,
  equipmentType: null,
  equipmentSubType: null,
  year: null,
  make: null,
  chassisNumber: null,
  model: null,
  vin: null,
  weight: null,
  weightUnit: EquipmentDTO.WeightUnitEnum.LBS,
  specs: null,
  ownership: null,
  licensePlate: null,
  registration: {
    registeredDate: null,
    expirationDate: null,
    stateId: null
  },
  dotSafetyInspection: {
    performedDate: null,
    expirationDate: null
  },
  insurance: {
    insuranceType: null,
    carrier: null,
    expirationDate: null,
    policyNumber: null
  },
  maintenanceInspectionFrequency: null,
  maintenanceInspections: [],
  repairs: []
}

const equipmentState: IEquipmentState = {}

const equipmentReducer = reducerWithInitialState<IEquipmentState>(equipmentState)
  .case(addEquipmentsToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default equipmentReducer
