import * as React from 'react'
import { Column, ColumnRow, Container, GridColumn } from '../../../../../UI/GridSystem'
import { ISteamShipLine } from '../../../interfaces'
import { Title } from './styledComponents'
import { InputField, Input } from '../../../../../UI/DataFields/Input'
import { IGridItemActions } from '../../../../../../contexts/GridItemContext'
import { schemaScac } from '../../../../../../services/yupScheme'

const SteamshipLineInfo: React.SFC<{
  ssl: ISteamShipLine
  actions: IGridItemActions
}> = ({ ssl, actions: { modify, modifyParentObjectField } }) => (
  <Column columns={15} isGrid={true} margin={{ right: 40 }}>
    <Title rows={5} alignCenter={true} padding={{ left: 10 }} margin={{ bottom: 15 }}>
      Steamship Line Info
    </Title>
    <Container rows={12} margin={{ bottom: 20 }}>
      <Input
        title={'Name'}
        required={true}
        value={ssl.businessPartner.legalName}
        onChange={(legalName: string) => modify({ ...ssl, businessPartner: { ...ssl.businessPartner, legalName } })}
      />
    </Container>

    <Container rows={12} margin={{ bottom: 20 }}>
      <Input
        title={'Reference #'}
        maxLength={64}
        value={ssl.referenceNumber}
        onChange={modifyParentObjectField('referenceNumber')}
      />
    </Container>
    <Container>
      <GridColumn columns={8} isGrid={true} padding={{ right: 10 }}>
        <ColumnRow margin={{ bottom: 25 }}>
          <InputField.ThousandSeparator
            title={'Free Detention Allowance'}
            value={ssl.freeDetentionAllowance}
            onChange={modifyParentObjectField('freeDetentionAllowance')}
          />
        </ColumnRow>
      </GridColumn>
      <GridColumn columns={8} isGrid={true}>
        <ColumnRow margin={{ bottom: 25 }}>
          <InputField.TextMask
            title={'SCAC'}
            maxLength={4}
            required={true}
            value={ssl.scac}
            valid={schemaScac.isValidSync(ssl.scac)}
            transformOnInputChange={value => (value ? value.toUpperCase() : value)}
            onChange={modifyParentObjectField('scac')}
            textMask={[/(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/, /(\d|[a-zA-Z])/]}
          />
        </ColumnRow>
      </GridColumn>
    </Container>
  </Column>
)

export default SteamshipLineInfo
