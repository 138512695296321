import { ContainerViewDTO } from '../../../../api/origin/business-logic/api'
import { oc } from 'ts-optchain'
import { TColumns } from '../../grid/views/interfaces'
import { createBodyColumnsWithHeaderProps } from '../../../../services/Grid'
import { containerDirectory } from '../../../../services/DTO/container/directory'

export const headers = {
  'Container #': { width: 160, active: true },
  'Active / Availability': { width: 260, active: true },
  Type: { width: 180, active: true },
  'Max Cargo Weight': { width: 160, active: true },
  Ownership: { width: 160, active: true },
  SSL: { width: 160, active: true }
}

export const adaptRowValues = (unit: ContainerViewDTO): TColumns => {
  const columnValues = {
    'Container #': unit.number,
    'Active / Availability': unit,
    Type: oc(unit).containerType.name(''),
    'Max Cargo Weight': unit.weight && unit.weightUnit ? unit.weight + ' ' + unit.weightUnit : '',
    Ownership: unit.ownership ? containerDirectory.ownership[unit.ownership] : '',
    SSL: oc(unit).steamShipLine.name('')
  }

  return createBodyColumnsWithHeaderProps(headers, columnValues)
}
