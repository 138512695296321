import { callAPI, stateAPI } from '../../api/api'
import { businessLogicApiActions } from '../../api/businessLogicApi'
import { getStore } from '../../store/configureStore'

export const requestStates = () => {
  return callAPI(stateAPI.findAll)
    .toPromise()
    .then(states => {
      getStore().dispatch(businessLogicApiActions.stateFindAllUsingGetAction.done({ result: states, params: {} }))
    })
}
