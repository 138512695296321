import * as React from 'react'
import { TextMask } from './textMask'
import styled from 'styled-components'

type Props = {
  title?: string
  minutes?: number
  onChange: (minutes: number) => void
}

export const HoursMinutes = (props: Props) => {
  const { title, minutes, onChange } = props

  let calculatedHours = 0
  let calculatedMinutes = 0

  if (minutes) {
    calculatedHours = Math.trunc(Number(minutes) / 60)
    calculatedMinutes = Number(minutes) % 60
  }

  const changeHours = (value: number) => {
    const parsedValue = Number(value || 0)
    const totalMinutes = parsedValue * 60 + calculatedMinutes

    onChange(totalMinutes)
  }

  const changeMinutes = (value: number) => {
    const parsedValue = Number(value || 0)
    const totalMinutes = calculatedHours * 60 + parsedValue

    onChange(totalMinutes)
  }

  return (
    <StyledHoursMinutes>
      <div>
        <TextMask
          title={title}
          placeholder={'HH'}
          textMask={[/[0-9]/, /[0-9]/]}
          value={calculatedHours || undefined}
          onChange={changeHours}
        />
      </div>
      <span>:</span>
      <div>
        <TextMask
          title={title}
          placeholder={'mm'}
          textMask={[/[0-5]/, /[0-9]/]}
          value={calculatedMinutes || undefined}
          onChange={changeMinutes}
        />
      </div>
    </StyledHoursMinutes>
  )
}

const StyledHoursMinutes = styled.div`
  display: flex;
  align-items: flex-end;

  > div {
    width: calc(50% - 4px);
  }

  > span {
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 2px;
  }
`
