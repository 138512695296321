import * as React from 'react'
import * as ReactDom from 'react-dom'
import { Whitespace } from '../../../services/keyboardService/keys'
import { Value, SInput, StyledInput, StyledCheckbox, TextArea, Clear } from './styledComponents'
import { Title } from '../styledComponents'
import { Container } from '../GridSystem'
import MaskedInput, { maskArray } from 'react-text-mask'
import cn from 'classnames'
// tslint:disable-next-line:no-var-requires
const ReactInputMask = require('react-input-mask')
import { transformPhoneNumber } from '../../../services/DTO/contact/functions'
// @ts-ignore
import { PositionProperty } from 'csstype'
import { Checkbox } from '../Checkbox'

export const transformAmount = (_value: string | number): string => {
  let result = ''
  const value = typeof _value === 'number' ? String(_value) : _value

  value.split('.').forEach((subString: string, index: number) => {
    if (index === 0) {
      result = subString
    }

    if (index === 1 && subString.length !== 0) {
      let doit = subString.slice(0, 2)
      if (doit.length === 2 && doit.slice(-1) === '0') {
        doit = doit.slice(0, -1)
      }

      if (doit !== '0') {
        result += '.' + doit
      }
    }
  })

  return result
}

export const transformMoney = (
  _amount: number | string,
  hideThousandSeparators?: boolean
): string | null | undefined => {
  if (!(typeof _amount === 'number' || typeof _amount === 'string')) {
    return undefined
  }

  try {
    const amount = Number(_amount)

    const transform = (money: number): string => {
      return hideThousandSeparators
        ? transformAmount(money.toFixed(2))
        : transformAmount(money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    }

    return (amount < 0 ? '-$ ' : '$ ') + transform(Math.abs(amount))
  } catch (e) {
    return undefined
  }
}

export const transformThousandSeparators = (value: string): string => {
  return value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
}

const moneyMask = ['$', ' ', /\d/, /\d/, /\d/, /\d/, /\d/]

export const passwordType = (value: string) => {
  return value.replace(/[\s\S]/g, '•')
}

const MaskedInputStyle = {
  display: 'block',
  width: '100%',
  border: 'none',
  background: 'none',
  padding: '0 10px',
  position: 'absolute' as PositionProperty,
  left: 0,
  top: 0
}

type Props = {
  value?: string | number | null | undefined
  valueOnBlurIfEmpty?: string | number | null | undefined
  tabIndex?: number
  maxLength?: number
  height?: number
  width?: number
  placeholder?: string
  mask?: string
  maskChar?: string
  textMask?: maskArray | ((value: string) => maskArray)
  disabled?: boolean
  noBorder?: boolean
  isColumn?: boolean
  isNumber?: boolean
  isHours?: boolean
  thousandSeparators?: boolean
  darkBackground?: boolean
  checkboxState?: boolean
  checkboxChange?: (state: boolean) => void
  title?: string
  required?: boolean
  money?: boolean
  allowZero?: boolean
  // https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#known-issues
  type?: 'text' | 'tel' | 'url' | 'password' | 'search'
  isTextArea?: boolean
  addClass?: string
  pattern?: string
  isUpperCase?: boolean
  onChange(value: any, tabKeyPressed?: boolean): void
  valid?: boolean
  onCopy?: React.ClipboardEventHandler
  isGridColumnTitle?: boolean
  className?: string
  inputClassName?: string
}

type State = {
  edit: boolean
  value: string
  valid: boolean
}

class Input extends React.Component<Props, State> {
  private _mounted = false
  private _valueContainer: any
  private _checkbox = this.props.checkboxState !== undefined
  private _isTextArea = !!this.props.isTextArea
  private _value: any
  private closeButton: boolean = !this._checkbox && !this._isTextArea

  state = {
    edit: false,
    value: !!this.props.value ? this.props.value.toString() : '',
    valid: true
  }

  render() {
    const {
      height,
      width,
      isColumn,
      required,
      title,
      checkboxState,
      tabIndex,
      disabled,
      darkBackground,
      valid = true,
      noBorder,
      className,
      isGridColumnTitle,
      addClass = '',
      isHours,
      inputClassName
    } = this.props

    const { value, edit } = this.state
    const classList = cn({
      highlight: !valid || (!edit && required && !this.props.value),
      'column-value': true,
      [addClass]: !!addClass,
      isGridColumnTitle,
      edit: this.state.edit || !this.props.value,
      'textArea noBorder': this._isTextArea,
      darkBackground,
      noBorder,
      isHours,
      isColumn,
      clearButton: this.closeButton,
      checkbox: this._checkbox && checkboxState,
      'checkbox isEmpty': this._checkbox && !checkboxState,
      isNotEmpty: String(this.props.value),
      isEmpty: !String(this.props.value)
    })

    return (
      <Container isGrid={true} columns={4} className={className}>
        {title && (
          <Title onClick={this.onLabelClick} isRequired={required}>
            {title}
          </Title>
        )}
        <StyledInput
          ref={(e: any) => (this._valueContainer = e)}
          tabIndex={tabIndex}
          onFocus={this.onFocus}
          className={classList}
          valid={valid}
          width={width}
          height={height || 35}
          isColumn={isColumn || false}
          disabled={disabled}
        >
          {this._checkbox && (
            <StyledCheckbox onClick={this.onCheckboxClick}>
              <Checkbox checked={checkboxState} />
            </StyledCheckbox>
          )}
          {this.state.edit ? (
            this.renderInputField()
          ) : (
            <Value
              className={inputClassName}
              ref={(e: any) => (this._value = e)}
              height={height || 35}
              onClick={this.onClick}
            >
              {this.resultValue()}
            </Value>
          )}
          {!disabled && !this._checkbox && (!!value || (!!this.props.value && !edit)) && !this._isTextArea && (
            <Clear className={'mdi mdi-close'} onClick={this.handleClear} />
          )}
        </StyledInput>
      </Container>
    )
  }

  renderInputField = () => {
    const {
      money,
      mask,
      textMask,
      maxLength,
      placeholder = 'Type here',
      height,
      pattern,
      type = 'text',
      onCopy
    } = this.props

    const { value } = this.state

    if (mask) {
      return (
        <ReactInputMask
          className={'reactInputMask'}
          type={type}
          autoFocus={true}
          mask={mask}
          value={value}
          maskChar={' '}
          style={MaskedInputStyle}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={placeholder || ''}
          onCopy={onCopy}
        />
      )
    }

    if (textMask) {
      return (
        // @ts-ignore
        <MaskedInput
          placeholder={placeholder || ''}
          mask={textMask || moneyMask}
          showMask={true}
          guide={false}
          value={value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleBlur}
          onCopy={onCopy}
          render={(ref: any, props) => (
            <SInput type="text" autoFocus={true} height={height || 35} ref={ref} pattern={pattern} {...props} />
          )}
        />
      )
    }

    if (this._isTextArea) {
      return (
        <TextArea
          autoFocus={true}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onChange={this.handleTextAreaChange}
          onBlur={this.handleBlur}
          value={value}
          placeholder={placeholder || ''}
        />
      )
    }

    return (
      <SInput
        className={this.props.inputClassName}
        type={type}
        autoFocus={true}
        onKeyDown={this.handleKeyDown}
        onKeyPress={this.handleKeyPress}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={value}
        placeholder={placeholder || ''}
        height={height || 35}
        pattern={pattern}
        maxLength={maxLength}
        onCopy={onCopy}
      />
    )
  }

  public componentDidMount() {
    this._mounted = true
    if (this.closeButton) {
      document.addEventListener('mousedown', this.outsideClickListener)
    }
    this.checkTextAreaHeight()
  }

  public componentWillUnmount() {
    this._mounted = false
    if (this.closeButton) {
      document.removeEventListener('mousedown', this.outsideClickListener)
    }
  }

  public componentDidUpdate() {
    this.checkTextAreaHeight()
  }

  resultValue = () => {
    const {
      money,
      allowZero,
      value,
      type,
      placeholder = 'Type here',
      checkboxState,
      isHours,
      thousandSeparators
    } = this.props

    if (money) {
      if (allowZero && (value === 0 || value === -0)) {
        return `$ 0`
      }
      if (value) {
        return `$ ${transformMoney(Number(value))}`
      } else {
        return <span>{placeholder || 'N/A'}</span>
      }
    }

    if (isHours) {
      return value ? <p>{value}</p> : <span>{placeholder}</span>
    }

    if (thousandSeparators) {
      return value ? <p>{transformThousandSeparators(String(value))}</p> : <span>{placeholder}</span>
    }

    if (this._checkbox && !checkboxState) {
      return null
    }

    if (type === 'tel' && value !== undefined && value !== null && String(value).length > 0) {
      return <>{transformPhoneNumber(String(value))}</>
    }

    if (type === 'password') {
      return String(value).length > 0 ? passwordType(String(value)) : '••••••'
    }

    return value || <span>{placeholder}</span>
  }

  outsideClickListener = (e: any) => {
    if (this.state.edit) {
      const box = ReactDom.findDOMNode(this._valueContainer) as HTMLDivElement
      if (!box.contains(e.target)) {
        this.handleBlur()
      }
    }
  }

  // allow any
  private trimValue = (value: any) => {
    if (value !== null && value !== undefined && typeof value === 'string') {
      value = value.trim()
    }
    return value
  }

  private handleClear = () => this.setState({ value: '' }, () => this.handleSubmit())

  private onFocus = () => {
    if (!this.props.disabled && (!this._checkbox || this.props.checkboxState)) {
      if (this.props.value) {
        return this.setState({ value: this.props.value.toString(), edit: true })
      } else if (this.props.valueOnBlurIfEmpty) {
        return this.setState({ value: this.props.valueOnBlurIfEmpty.toString(), edit: true })
      }
      this.setState({ value: '', edit: true })
    }
  }

  private onClick = () => {
    if (!this.props.disabled && (!this._checkbox || this.props.checkboxState)) {
      this.setState({ edit: true })
    }
  }

  private onCheckboxClick = () => {
    this.props.checkboxChange(!this.props.checkboxState)
  }

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.state.edit && e.key === Whitespace.Tab) {
      this.handleSubmit(e, true)
    }
  }

  private handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.state.edit && e.key === Whitespace.Enter) {
      if (this._isTextArea && e.shiftKey) {
      } else {
        e.preventDefault()
        e.stopPropagation()
        this.handleSubmit(e)
      }
    }
  }

  private checkTextAreaHeight = () => {
    if (
      this._isTextArea &&
      !this.state.edit &&
      this._value.scrollHeight > this._valueContainer.getBoundingClientRect().height
    ) {
      this._valueContainer.classList.remove('noBorder')
    }
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { isNumber, isHours, money, isUpperCase } = this.props

    const input = event.target
    let value = input.value
    const valid = input.checkValidity()

    if (isNumber) {
      value = value.replace(/\D/g, '')
    }

    if (isHours || money) {
      value = value.replace(/[.]+/g, '.')
    }

    if (isUpperCase) {
      value = value.toUpperCase()
    }

    if (this._mounted) {
      if (valid) {
        this.setState({ value: value, valid })
      } else {
        this.setState({ valid })
      }
    }
  }

  private handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target
    const value = input.value
    this.setState({ value })
  }

  private handleBlur = () => {
    this.handleSubmit()
  }

  private handleSubmit = (
    _e?: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    tabKeyPressed?: boolean
  ) => {
    let value: string | number = this.trimValue(this.state.value)

    if (this._mounted) {
      let propsValue
      if (this.props.isNumber) {
        value = this.state.value === '' ? null : Number(this.state.value)
        propsValue = this.props.value === '' ? null : Number(this.props.value)
      } else {
        propsValue = this.props.value === undefined ? '' : this.props.value
      }
      value = value === '' ? null : value

      if (value !== propsValue) {
        this.props.onChange(value, tabKeyPressed)
      }
    }

    this.setState({ edit: false })
  }

  private onLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    if (!this.props.disabled && this._mounted) {
      this.setState({
        edit: true
      })
    }
  }
}

export default Input
