import { callAPI, subClientAPI, SubClientViewDTO } from '../../../../api/api'
import { addSubClientToStore } from '../action'
import { getStore } from '../../../../store/configureStore'
import { catchListErrors } from '../../../../services/tabs/functions'
import { correctInsertionObjectsIntoStorage } from '../../../../services/DTO'
import { parseDTO } from '../../../../services/DTO/parseDTO'

export const tryAddSubClientToStore = (items: SubClientViewDTO | SubClientViewDTO[]) => {
  correctInsertionObjectsIntoStorage(getStore().getState().subClient, items, addSubClientToStore)
}

export const getSubClientById = (id: string): Promise<SubClientViewDTO> =>
  callAPI(subClientAPI.findById, id)
    .toPromise()
    .then(subClient => {
      parseDTO.subClient(subClient)
      return subClient
    })

export const findAllSubClients = async ({ sort, filter }: any): Promise<SubClientViewDTO[]> => {
  return callAPI(subClientAPI.findAll, filter, sort)
    .toPromise()
    .then(subClients => {
      parseDTO.subClient(subClients)
      return subClients
    })
    .catch(catchListErrors)
}
