import { GridColumn } from '../../UI/GridSystem'
import theme from '../../../styles/theme'
import styled from '../../../styles/styledComponents'

export const StyledMainMenu = styled(GridColumn)`
  z-index: ${theme.zIndex.header};
  position: absolute;
  height: calc(100vh - 50px);
  background: rgb(50, 62, 78);
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.28);
  color: white;
  user-select: none;

  a {
    color: white;
  }
`

export const Title = styled(GridColumn)`
  align-items: center;
  font-size: 15px;
  color: white;
  padding: 10px 0 0 40px;
  margin: 10px 0 10px;
  cursor: pointer;
  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`

export const MenuItem = styled(GridColumn)`
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding-left: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    font-weight: 400;
  }
`
