import {
  DispatchDeliveryOrderGridItemDTO,
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO
} from '../../../../api/origin/business-logic'
import { callAPIWithErrorMessage, callAPI, dispatchDeliveryOrderAPI, streetTurnAPI } from '../../../../api/api'
import { getStore } from '../../../../store/configureStore'
import { correctInsertionObjectsIntoStorage } from '../../index'
import { addToStore } from '../actions'
import { oc } from 'ts-optchain'
import { parseDTO } from '../../parseDTO'

export const tryAddStreetTurnsToStore = (
  items: DispatchDeliveryOrderStreetTurnDTO | DispatchDeliveryOrderStreetTurnDTO[]
) => {
  correctInsertionObjectsIntoStorage(getStore().getState().streetTurn, items, addToStore)
}

type TFindAllStreetTurnDispatchDeliveryOrders = {
  dispatchDeliveryOrderId: string
  saveToStore?: boolean
}
export const findAllStreetTurnDispatchDeliveryOrders = ({
  dispatchDeliveryOrderId,
  saveToStore = true
}: TFindAllStreetTurnDispatchDeliveryOrders): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(streetTurnAPI.findAll, dispatchDeliveryOrderId)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      if (saveToStore) {
        parseDTO.dispatchDeliveryOrder(dispatchDeliveryOrders)
      }
      return dispatchDeliveryOrders
    })
}

export const findAllStreetTurnDispatchDeliveryOrdersForGrid = ({
  dispatchDeliveryOrderId
}: TFindAllStreetTurnDispatchDeliveryOrders): Promise<DispatchDeliveryOrderGridItemDTO[]> => {
  return callAPI(streetTurnAPI.findAll, dispatchDeliveryOrderId)
    .toPromise()
    .then(dispatchDeliveryOrders => {
      const storeDispatchDeliveryOrderMapping = getStore().getState().dispatchDeliveryOrder

      tryAddStreetTurnsToStore(dispatchDeliveryOrders.map(({ streetTurn }) => streetTurn))
      parseDTO.dispatchDeliveryOrder(
        dispatchDeliveryOrders.map(ddo => {
          const storeDDO = storeDispatchDeliveryOrderMapping[ddo.id]
          return {
            ...(storeDDO || ddo),
            forceUpdate: true,
            temporaryData: {
              ...oc(storeDDO).temporaryData({}),
              streetTurn: ddo.streetTurn,
              streetTurnId: ddo.streetTurn.id
            }
          }
        })
      )
      return dispatchDeliveryOrders
    })
}

export const getStreetTurnDispatchDeliveryOrderById = (id: string): Promise<DispatchDeliveryOrderViewDTO> => {
  return callAPI(dispatchDeliveryOrderAPI.findById, id)
    .toPromise()
    .then(dispatchDeliveryOrder => {
      const { getState } = getStore()
      const store = getState()
      const storeDDOStreetTurn = oc(store).dispatchDeliveryOrder[id].streetTurn()

      parseDTO.dispatchDeliveryOrder(
        storeDDOStreetTurn
          ? { ...dispatchDeliveryOrder, streetTurn: { ...storeDDOStreetTurn, fullObject: false } }
          : dispatchDeliveryOrder
      )
      return dispatchDeliveryOrder
    })
}

type TStreetTurnRequest = {
  streetTurnId: string
  saveToStore?: boolean
}

export const streetTurnRequests = {
  submit: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.submit, streetTurnId).then(streetTurn => {
      if (saveToStore) {
        tryAddStreetTurnsToStore(streetTurn)
      }

      return streetTurn
    })
  },
  approve: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.approve, streetTurnId).then(streetTurn => {
      if (saveToStore) {
        tryAddStreetTurnsToStore(streetTurn)
      }

      return streetTurn
    })
  },
  cancel: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.cancel, streetTurnId)
  },
  reject: async ({ streetTurnId, saveToStore = true }: TStreetTurnRequest): Promise<any> => {
    return callAPIWithErrorMessage(streetTurnAPI.reject, streetTurnId)
  }
}
