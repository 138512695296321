import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { DateISOString, OngoingActivityDTO, TransportationActivityViewDTO } from '../../../../../../../../api/api'
import { activityDirectory } from '../../../../../../../../services/DTO/activity/directory'
import { dateService, TDateServiceMakeLabelOptions } from '../../../../../../../../services/timeService'
import { TCalcPhase } from '../../../../../functions'
import { Column, TextLine, TextLines } from '../PreviewPhase'
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector'
import { selectDriverPosition } from '../../../../../../../../store/select/driverPositionSelect'
import { getListState } from '../../../../../../../../store'
import { isGotoActivity } from '../../../../../../../../services/functions/test/isGotoActivity'
import { PlannedDate } from './PlannedDate'
import { LapseTimeColumn } from './LapseTimeColumn'
import { ETAColumn } from './ETAColumn'
import { SpecialColumn } from './SpecialCokumn'
import { MilesColumn } from './MilesColumn'

type Props = {
  activityGroupDate: DateISOString
  phase: TCalcPhase
  isCurrentPhase: boolean
  isDriverOnline: boolean
  isMissed?: boolean
  isNowSection?: boolean
  isExtraActivity?: boolean
  isTwoActivitiesView?: boolean
}

export const PreviewPhaseDateColumn = React.memo((props: Props) => {
  const {
    activityGroupDate,
    phase,
    isCurrentPhase,
    isDriverOnline,
    isMissed,
    isNowSection,
    isExtraActivity,
    isTwoActivitiesView
  } = props
  const { stage, isAssigned } = phase
  const activity = isExtraActivity ? phase.extraActivity : phase.activity
  const { startActualDate, completionActualDate, plannedDate, driver } = activity
  const actualDate = completionActualDate || startActualDate
  const vendorId = oc(driver).id()
  const authUserId = vendorId ? oc(getListState().driver[vendorId]).authUserId() : undefined
  const driverPosition = useAppSelector(selectDriverPosition(authUserId))
  const isGoto = isGotoActivity(activity)
  const isGotoCompleted = isGoto && activity.status === OngoingActivityDTO.StatusEnum.COMPLETED
  const isActive = phase.isActive || isGotoCompleted
  const hideETAMilesColumns = !(isGoto && !isGotoCompleted)
  const plannedDateFrom = oc(plannedDate).from()

  const getDateOptions = React.useCallback(
    (date: string, longSpace?: boolean): TDateServiceMakeLabelOptions => {
      return !isMissed && dateService.isTheSameDay(date, activityGroupDate)
        ? { onlyTime: true }
        : { hideYear: true, monthName: 'short', hideWeekday: true, shortSpace: !longSpace }
    },
    [isMissed, activityGroupDate]
  )

  if (isActive) {
    return (
      <OngoingDateColumn className={cn({ isNowSection, isTwoActivitiesView, isExtraActivity })}>
        {isNowSection ? (
          <>
            {isGotoCompleted ? (
              <>
                <PlannedDate
                  isCurrentPhase={isCurrentPhase}
                  deliveryPlannedAppointmentDateConfirmed={activity.deliveryPlannedAppointmentDateConfirmed}
                  isActive={isActive}
                  isTwoActivitiesView={isTwoActivitiesView}
                  isExtraActivity={isExtraActivity}
                  plannedDateFrom={plannedDateFrom}
                  plannedDate={plannedDate}
                  stage={stage}
                  getDateOptions={getDateOptions}
                />
                <LapseTimeColumn
                  startTime={isGotoCompleted ? completionActualDate : startActualDate}
                  color={activityDirectory.stageStrongColor[stage]}
                  isTwoActivitiesView={isTwoActivitiesView}
                />
                <div />
              </>
            ) : (
              <>
                <LapseTimeColumn
                  startTime={isGotoCompleted ? completionActualDate : startActualDate}
                  color={activityDirectory.stageStrongColor[stage]}
                  isTwoActivitiesView={isTwoActivitiesView}
                />
                <ETAColumn
                  isActive={isActive}
                  isAssigned={isAssigned}
                  isExtraActivity={isExtraActivity}
                  isTwoActivitiesView={isTwoActivitiesView}
                  hideETAMilesColumns={hideETAMilesColumns}
                  isDriverOnline={isDriverOnline}
                  authUserId={authUserId}
                  driverPosition={driverPosition}
                  duration={
                    driverPosition && driverPosition.activityId === activity.activityId && driverPosition.duration
                  }
                />
                <MilesColumn
                  isDriverOnline={isDriverOnline}
                  hideETAMilesColumns={hideETAMilesColumns}
                  isTwoActivitiesView={isTwoActivitiesView}
                  distance={
                    driverPosition && driverPosition.activityId === activity.activityId && driverPosition.distance
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            {plannedDateFrom && (
              <PlannedDate
                isCurrentPhase={isCurrentPhase}
                deliveryPlannedAppointmentDateConfirmed={activity.deliveryPlannedAppointmentDateConfirmed}
                isActive={isActive}
                isTwoActivitiesView={isTwoActivitiesView}
                isExtraActivity={isExtraActivity}
                plannedDateFrom={plannedDateFrom}
                plannedDate={plannedDate}
                stage={stage}
                getDateOptions={getDateOptions}
              />
            )}
            {!isGotoCompleted && (
              <SpecialColumn
                title={'START'}
                dataId={plannedDateFrom ? `startActualDate=${startActualDate}` : `phaseDate=${startActualDate}`}
                value={dateService.makeLabel(startActualDate, getDateOptions(startActualDate, true))}
                color={activityDirectory.stageStrongColor[stage]}
                isTwoActivitiesView={isTwoActivitiesView}
              />
            )}
            {/* {!plannedDateFrom && <Column />} */}
            <LapseTimeColumn
              startTime={isGotoCompleted ? completionActualDate : startActualDate}
              color={activityDirectory.stageStrongColor[stage]}
              isTwoActivitiesView={isTwoActivitiesView}
            />
            <ETAColumn
              isActive={isActive}
              isAssigned={isAssigned}
              isExtraActivity={isExtraActivity}
              isTwoActivitiesView={isTwoActivitiesView}
              hideETAMilesColumns={hideETAMilesColumns}
              isDriverOnline={isDriverOnline}
              authUserId={authUserId}
              driverPosition={driverPosition}
              duration={driverPosition && driverPosition.activityId === activity.activityId && driverPosition.duration}
            />
            <MilesColumn
              isDriverOnline={isDriverOnline}
              hideETAMilesColumns={hideETAMilesColumns}
              isTwoActivitiesView={isTwoActivitiesView}
              distance={driverPosition && driverPosition.activityId === activity.activityId && driverPosition.distance}
            />
          </>
        )}
      </OngoingDateColumn>
    )
  } else if (isTwoActivitiesView) {
    return (
      <OngoingDateColumn className={cn({ isNowSection, isTwoActivitiesView, isExtraActivity })}>
        {isExtraActivity ? (
          <>
            {isGotoCompleted ? (
              <>
                <PlannedDate
                  isCurrentPhase={isCurrentPhase}
                  deliveryPlannedAppointmentDateConfirmed={activity.deliveryPlannedAppointmentDateConfirmed}
                  isActive={isActive}
                  isTwoActivitiesView={isTwoActivitiesView}
                  isExtraActivity={isExtraActivity}
                  plannedDateFrom={plannedDateFrom}
                  plannedDate={plannedDate}
                  stage={stage}
                  getDateOptions={getDateOptions}
                />
                <LapseTimeColumn
                  startTime={isGotoCompleted ? completionActualDate : startActualDate}
                  color={activityDirectory.stageStrongColor[stage]}
                  isTwoActivitiesView={isTwoActivitiesView}
                />
                <div />
              </>
            ) : (
              <>
                <Column data-id={`phaseDateExtra=${startActualDate}`} style={{ fontSize: 11, fontWeight: 300 }}>
                  <span style={{ fontWeight: 500, marginRight: 4 }}>
                    {dateService.makeLabel(startActualDate, getDateOptions(startActualDate))}
                  </span>
                  Start
                </Column>
                <LapseTimeColumn
                  startTime={isGotoCompleted ? completionActualDate : startActualDate}
                  color={activityDirectory.stageStrongColor[stage]}
                  isTwoActivitiesView={isTwoActivitiesView}
                />
                <ETAColumn
                  isActive={isActive}
                  isAssigned={isAssigned}
                  isExtraActivity={isExtraActivity}
                  isTwoActivitiesView={isTwoActivitiesView}
                  hideETAMilesColumns={hideETAMilesColumns}
                  isDriverOnline={isDriverOnline}
                  authUserId={authUserId}
                  driverPosition={driverPosition}
                  duration={
                    driverPosition && driverPosition.activityId === activity.activityId && driverPosition.duration
                  }
                />
                <MilesColumn
                  isDriverOnline={isDriverOnline}
                  hideETAMilesColumns={hideETAMilesColumns}
                  isTwoActivitiesView={isTwoActivitiesView}
                  distance={
                    driverPosition && driverPosition.activityId === activity.activityId && driverPosition.distance
                  }
                />
              </>
            )}
          </>
        ) : (
          <>
            <Column data-id={`phaseDate=${plannedDateFrom}`}>
              <PlannedDate
                isCurrentPhase={isCurrentPhase}
                deliveryPlannedAppointmentDateConfirmed={activity.deliveryPlannedAppointmentDateConfirmed}
                isActive={isActive}
                isTwoActivitiesView={isTwoActivitiesView}
                isExtraActivity={isExtraActivity}
                plannedDateFrom={plannedDateFrom}
                plannedDate={plannedDate}
                stage={stage}
                getDateOptions={getDateOptions}
              />
            </Column>
            <Column
              data-id={`bottomActivityStatus=${activity.status}`}
              style={{
                fontSize: 11,
                color: activity.status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL ? 'red' : undefined
              }}
            >
              {activityDirectory.status[activity.status] || activityDirectory.documentationStatus[activity.status]}
            </Column>
          </>
        )}
      </OngoingDateColumn>
    )
  }

  if (!isCurrentPhase && !actualDate && !plannedDateFrom) {
    return (
      <Column style={{ borderLeft: 'unset' }}>
        <span style={{ margin: 'auto', fontSize: 20 }}>&mdash;</span>
      </Column>
    )
  }

  return (
    <Column data-id={`phaseDate=${actualDate || plannedDateFrom}`} style={{ borderLeft: 'unset' }}>
      <TextLines>
        <TextLine>
          {actualDate ? (
            <>
              <span style={{ fontWeight: 500 }}>{dateService.makeLabel(actualDate, getDateOptions(actualDate))}</span>
              <span style={{ marginLeft: 5, fontWeight: 300 }}>
                {activity.phase && activityDirectory.phases[activity.phase]}
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </TextLine>
        <TextLine>
          <PlannedDate
            isCurrentPhase={isCurrentPhase}
            deliveryPlannedAppointmentDateConfirmed={activity.deliveryPlannedAppointmentDateConfirmed}
            isActive={isActive}
            isTwoActivitiesView={isTwoActivitiesView}
            isExtraActivity={isExtraActivity}
            plannedDateFrom={plannedDateFrom}
            plannedDate={plannedDate}
            stage={stage}
            getDateOptions={getDateOptions}
          />
        </TextLine>
      </TextLines>
    </Column>
  )
})

const OngoingDateColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 22% 20.5% 18.5%;
  user-select: none;

  &.isNowSection {
    grid-template-columns: repeat(3, 1fr);
  }

  &.isTwoActivitiesView {
    grid-template-columns: 1fr 42%;

    &.isExtraActivity {
      grid-template-columns: 1fr 25% 22.5% 19.5%;
    }
  }
`
