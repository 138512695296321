import * as React from 'react'
import { WidgetActions } from '../../styles'
import DateField from '../../../DatePicker/DateField'
import { DateTypes } from '../../../DatePicker/Interfaces'
import { IDispatchDeliveryOrder } from '../../../../common/dispatchDeliveryOrder/interfaces'
import { ContainerTypeDTO, DateISOString, DeliveryOrderViewDTO } from '../../../../../api/origin/business-logic'
import { Input } from '../../../DataFields/Input'
import { oc } from 'ts-optchain'
import { EquipmentSearch, ContainerSearch } from '../../../DataFields/SearchRequest'
import { compareDates } from '../../../../../services/timeService/dateUtils'
import { IEquipment } from '../../../../common/equipment/interfaces'
import { IContainer } from '../../../../common/containers/interfaces'
import { FieldTemplate } from '../../../DataFields/Templates'
import { FieldContainer } from '../../../DataFields/FieldContainer'
import { getEquipmentById } from '../../../../common/equipment/epics'
import { GridSpinner } from '../../../Spinner/Spinner'
import { WidgetContext } from '../../index'
import styled from 'styled-components'
import { ActionButton } from '../../../Buttons/ActionButton'
import { StreetTurnTable } from '../../../../popups/StreetTurnTable'

export type EquipmentPropsType = {
  steamShipLineId: string
  size: ContainerTypeDTO
  containerNumber: string
  container: IContainer
  equipment: IEquipment
  chassis: string
  days: number
  pickupDate: DateISOString
  returnDate: DateISOString
}

export const Popover: React.FC<{
  disabledEditDDO: boolean
  disabledStreetTurn: boolean
  isModifiedMode: boolean
  isChassisNumberRequired: boolean
  equipmentProps: EquipmentPropsType
  updateContainerType: (value?: ContainerTypeDTO) => void
  dispatchDeliveryOrder: IDispatchDeliveryOrder
  updateDispatchDeliveryOrder: (fieldName: keyof IDispatchDeliveryOrder) => (value: any) => void
  updateContainer: (container: IContainer) => void
  updateEquipment: (equipment: IEquipment) => void
}> = ({
  disabledEditDDO,
  disabledStreetTurn,
  isModifiedMode,
  equipmentProps,
  updateContainerType,
  dispatchDeliveryOrder,
  updateEquipment,
  updateDispatchDeliveryOrder,
  updateContainer,
  isChassisNumberRequired
}) => {
  const { closePopover } = React.useContext(WidgetContext)
  const { equipment } = equipmentProps
  const [fetching, setFetching] = React.useState(equipment && !equipment.fullObject)

  React.useEffect(() => {
    if (equipment && !equipment.fullObject) {
      getEquipmentById(equipment.id)
        .then(resolvedEquipment => {
          if (isModifiedMode) {
            updateEquipment(resolvedEquipment)
          }
        })
        .finally(() => {
          setFetching(false)
        })
    }
  }, [])

  const isNewChassis = oc(equipmentProps)
    .equipment.id('')
    .includes('newEquipment')

  const estimate = {
    calcDates:
      equipmentProps.pickupDate && equipmentProps.returnDate
        ? Math.ceil(Number(compareDates(equipmentProps.pickupDate, equipmentProps.returnDate, 'days')))
        : '',
    placeholder: isNewChassis ? 'MM/DD/YYYY' : ' '
  }

  const updateEquipmentDate = (dateField: string) => (value: any) =>
    updateEquipment({
      ...equipment,
      [dateField]: value
    })

  return (
    <>
      {fetching && <GridSpinner />}
      <Fields>
        <div>
          <FieldTemplate.ContainerTypeSearch
            disabled={disabledEditDDO}
            title={'Container type'}
            required={true}
            containerType={equipmentProps.size}
            onChange={updateContainerType}
          />
        </div>
        <div>
          <ContainerSearch
            disabled={disabledEditDDO}
            allowCreate={true}
            required={dispatchDeliveryOrder.deliveryOrder.type === DeliveryOrderViewDTO.TypeEnum.IMPORT}
            title={'Container #'}
            placeholder={dispatchDeliveryOrder.containerNumberPlaceholder}
            onChange={updateContainer}
            value={equipmentProps.container}
            steamShipLineId={equipmentProps.steamShipLineId}
            containerTypeId={dispatchDeliveryOrder.containerTypeId}
          />
        </div>
        <div>
          <Input
            disabled={disabledEditDDO}
            title={'Seal'}
            maxLength={20}
            value={dispatchDeliveryOrder.sealNumber}
            onChange={updateDispatchDeliveryOrder('sealNumber')}
          />
        </div>
        <div>
          <EquipmentSearch
            disabled={disabledEditDDO}
            required={isChassisNumberRequired}
            allowCreate={true}
            title={'Chassis #'}
            value={oc(equipmentProps).equipment(null)}
            onChange={updateEquipment}
          />
        </div>
        <div>
          <DateField
            showTime={true}
            disabled={disabledEditDDO || !isNewChassis}
            title={'Chassis Pickup'}
            date={equipmentProps.pickupDate}
            dateType={DateTypes.DateISOString}
            placeholder={estimate.placeholder}
            onUpdate={(date: DateISOString) =>
              updateEquipmentDate('pickupDate')(compareDates(date, equipmentProps.returnDate) ? date : null)
            }
          />
        </div>
        <div>
          <DateField
            showTime={true}
            disabled={disabledEditDDO || !isNewChassis}
            title={'Chassis Return'}
            date={equipmentProps.returnDate}
            dateType={DateTypes.DateISOString}
            placeholder={estimate.placeholder}
            onUpdate={(date: DateISOString) =>
              updateEquipmentDate('returnDate')(compareDates(equipmentProps.pickupDate, date) ? date : null)
            }
          />
        </div>
        <div>
          <FieldContainer wrapChild={true} title={'Chassis Days'} childrenStyle={{ height: 35, fontSize: 13 }}>
            {equipmentProps.days || estimate.calcDates}
          </FieldContainer>
        </div>
      </Fields>

      {!disabledStreetTurn && !dispatchDeliveryOrder.troubleTicketId && (
        <StreetTurnTable
          dispatchDeliveryOrder={dispatchDeliveryOrder}
          hideStreetTurnActions={isModifiedMode}
          setFetching={setFetching}
        />
      )}

      <WidgetActions>
        <ActionButton filled={true} round={true} onClick={closePopover}>
          Ok
        </ActionButton>
      </WidgetActions>
    </>
  )
}

const Fields = styled.div`
  width: 1012px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;

  > div {
    width: 33%;
    padding: 0 16px;
    margin-bottom: 24px;
  }
`
