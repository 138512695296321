import { getStore } from '../../../store/configureStore'
import { generateDispatchDeliveryOrderChatChannel } from './index'
import { getDispatchDeliveryOrderChannels } from '../epics'
import { ChannelType } from '../../../store/reducers/communicationHub/interfaces'
import { getDispatch, getNewStoreState } from '../../../store'
import { communicationHubActions } from '../../../store/reducers/communicationHub'

export const createOrUpdateChatChannel = (channelId: string, channelType: ChannelType, updatedAt: string) => {
  const { dispatch, getState } = getStore()
  const store = getState()
  const { dispatchDeliveryOrder, deliveryOrder } = store
  const {
    communicationHub: { channels }
  } = getNewStoreState()

  if (channels[channelId]) {
    return
  }

  if (channelType === ChannelType.dispatchDeliveryOrder) {
    const ddo = dispatchDeliveryOrder[channelId]

    if (ddo) {
      getDispatch()(
        communicationHubActions.setChannel({
          channel: generateDispatchDeliveryOrderChatChannel({
            ...ddo,
            deliveryOrder: deliveryOrder[ddo.deliveryOrderId]
          }),
          channelType,
          updatedAt
        })
      )
    } else {
      getDispatchDeliveryOrderChannels(1, undefined, [channelId]).then(ddos => {
        if (!ddos || !ddos.length) {
          return
        }

        getDispatch()(
          communicationHubActions.setChannel({
            channel: generateDispatchDeliveryOrderChatChannel(ddos[0]),
            channelType,
            updatedAt
          })
        )
      })
    }
  }
}
