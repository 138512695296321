import * as React from 'react'
import styled from 'styled-components'
import { BuySideQuoteDTO } from '../../../../../../../../../api/origin/business-logic'
import { AdditionalSurchargesBlock, ApplicableRatesBlock } from '../../common/body'
import { MiscSurchargeDTO, SurchargeDTO } from '../../../types'
import { OwnBuySideQuoteSurcharges } from './OwnBuySideQuoteSurcharges'
import { RateDTO } from '../../../../../../../../../services/DTO/rate/interfaces'
import { BuySideQuote } from '../../../../../../../../../services/DTO/buySideQuote/interfaces'
import { useAppSelector } from '../../../../../../../../../hooks/useAppSelector'
import { selectDriverName } from '../../../../../../../../../store/select/driverSelect'
import { oc } from 'ts-optchain'

type Props = {
  buySideQuote: BuySideQuote
  applicableRates: RateDTO[]
  openRatesLink: (vendor?: { label: string; value: string }) => void
  openRateLink: (rateNumber: string) => void
  updateBuySideQuote: (buySideQuote: BuySideQuoteDTO) => void
}

export const Details = (props: Props) => {
  const { buySideQuote, applicableRates, openRatesLink, openRateLink, updateBuySideQuote } = props
  const activityVendorName = useAppSelector(selectDriverName(oc(buySideQuote).temporaryData.activityVendorId()))
  const ownBuySideQuoteSurcharges: SurchargeDTO[] = []
  const attachedSurcharges: SurchargeDTO[] = []
  buySideQuote.surcharges.forEach(surcharge => {
    if (surcharge.automatic) {
      ownBuySideQuoteSurcharges.push(surcharge)
    } else {
      attachedSurcharges.push(surcharge)
    }
  })
  const updateMisc = (miscSurcharges: MiscSurchargeDTO[]) => updateBuySideQuote({ ...buySideQuote, miscSurcharges })
  const updateSurcharges = (surcharges: SurchargeDTO[]) =>
    updateBuySideQuote({ ...buySideQuote, surcharges } as BuySideQuoteDTO)
  const updateSurchargeQuantity = (surchargeId: string, quantity: number) =>
    updateSurcharges(
      buySideQuote.surcharges.map(surcharge =>
        surcharge.id === surchargeId ? { ...surcharge, quantity: quantity || 0 } : surcharge
      )
    )

  return (
    <SDetails>
      <OwnBuySideQuoteSurcharges
        ownBuySideQuoteSurcharges={ownBuySideQuoteSurcharges}
        updateSurchargeQuantity={updateSurchargeQuantity}
        openRateNumber={openRateLink}
      />
      <AdditionalSurchargesBlock
        showActivityColumn={false}
        miscSurcharges={buySideQuote.miscSurcharges}
        update={updateMisc}
      />
      <ApplicableRatesBlock
        multipleChoice={false}
        disableActivityColumn={true}
        surcharges={buySideQuote.surcharges}
        attachedSurcharges={attachedSurcharges}
        applicableRates={applicableRates}
        updateSurcharges={updateSurcharges}
        updateSurchargeQuantity={updateSurchargeQuantity}
        openRateTab={() =>
          openRatesLink(buySideQuote.vendorId ? { label: activityVendorName, value: buySideQuote.vendorId } : undefined)
        }
        openRateNumber={openRateLink}
      />
    </SDetails>
  )
}

const SDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
