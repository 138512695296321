import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ICustomerState, ICustomerView } from '../interfaces'
import { createNewUnitId } from '../../../../services/utils'
import { createNewBusinessPartner } from '../../businessPartner/reducers'
import { addCustomersToStore } from '../action'

export const createNewCustomer = (id?: string): ICustomerView => {
  const businessPartner = createNewBusinessPartner()
  return {
    fullObject: true,
    id: id || createNewUnitId(),
    businessPartner,
    businessPartnerId: businessPartner.id,
    netTerms: null,
    netTermsUnit: null,
    subClientIds: [],
    subClients: []
  }
}

const customerState: ICustomerState = {}

const customerReducer = reducerWithInitialState<ICustomerState>(customerState)
  .case(addCustomersToStore, (store, newItems) => ({ ...store, ...newItems }))
  .build()

export default customerReducer
