import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import theme from '../../../styles/theme'
import { useTable } from '../../../hooks/useTable'
import { TableRow } from './TableRow'

type Props = {}

export const TableList = (props: Props) => {
  const {} = props
  const { listRef, list, styleList, styleButtonAdd, onButtonAddClick } = useTable()

  return (
    <Container className={'tableRows'} style={styleList} ref={listRef}>
      {list.map(item => (
        <TableRow key={item.id} item={item} />
      ))}
      {Boolean(onButtonAddClick) && <ButtonAdd style={styleButtonAdd} onClick={onButtonAddClick} />}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 12px;
  overflow-y: scroll;
`
const ButtonAdd = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px dashed #cacddc;
  color: #cacddc;
  font-size: 14px;
  cursor: pointer;
  position: sticky;
  bottom: 0;

  &::before {
    content: '+';
    font-size: 24px;
    font-weight: 300;
    margin-right: 5px;
  }
  &::after {
    content: 'Add Item';
    display: inline-block;
    padding-top: 2px;
  }

  &:hover {
    border-color: ${theme.colors.basicBlueColor};
    color: ${theme.colors.basicBlueColor};
  }
`
